import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import StepContainer from "./StepContainer";
import Product from "../../../../api/entities/Product";
import { OnboardingContext } from "../../../../store/OnboardingContext";
import { createPayment, getProducts } from "../../../../api/client";
import {
  MEMBER_STATUS_STANDARD,
  MEMBER_STATUS_TRIAL
} from "../../../../api/entities/UserProfile";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { AccountContext } from "../../../../store/AccountContext";
import BuyNowPurchase from "../../../Payment/Steps";
import { makeStyles } from "@material-ui/core/styles";
import useLogger from "../../../../hooks/useLogger";
import { isBrowser } from "react-device-detect";

const useStyles = makeStyles(theme => ({
  countdownBackground: {
    backgroundColor: "#292929",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "auto",
    height: "auto",
    padding: theme.spacing(6, 2),
    position: "relative",
    "@media (min-width:600px)": {
      padding: theme.spacing(5)
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(5)
    }
  },
  doorsOpeningContainer: {
    textAlign: "center"
  },
  mainTitle: {
    color: theme.palette.common.white,
    fontSize: "2.8rem",
    lineHeight: "2.6rem",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      fontSize: "3.2rem",
      lineHeight: "3.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "4.2rem",
      lineHeight: "4rem"
    }
  },
  subTitle: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontSize: "1.4rem",
    lineHeight: "1.5rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    textAlign: "center",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    }
  },
  bottomTitle: {
    color: theme.palette.common.white,
    fontSize: "1.2rem",
    lineHeight: "1.3rem",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4)
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(4),
      fontSize: "1.8rem",
      lineHeight: "2rem"
    }
  },
  notifyMeButton: {
    width: "100%",
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  bodyText: {
    color: theme.palette.common.white,
    textAlign: "center"
  },
  countdown: {
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    fontSize: "3rem",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "3.2rem",
      lineHeight: "3.4rem",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    },
    "@media (min-width:960px)": {
      fontSize: "4rem",
      lineHeight: "4.2rem",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  countdownContainer: {
    padding: theme.spacing(1, 5, 0, 5),
    "@media (min-width:600px)": {
      padding: theme.spacing(2, 4, 0, 4)
    }
  },
  countdownWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0)
    }
  },
  countdownLabelWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  countdownUnitWrapper: {
    textAlign: "center",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    padding: "4% 0",
    fontSize: "1.6rem",
    fontWeight: 600,
    color: "#eeeeee",
    maxWidth: "21.8%",
    margin: "0 1.6%",
    "@media (min-width:600px)": {
      padding: "3% 0",
      fontSize: "2rem",
      fontWeight: 600
    },
    "@media (min-width:960px)": {
      padding: "3% 0",
      fontSize: "2.4rem",
      fontWeight: 600
    }
  },
  countdownUnitFirstWrapper: {
    margin: 0
  },
  countdownUnitLabel: {
    fontSize: "0.7rem",
    fontWeight: 600,
    textAlign: "center",
    color: theme.palette.common.white,
    maxWidth: "21.8%",
    margin: "0 1.6%",
    "@media (min-width:600px)": {
      fontSize: "0.9rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem"
    }
  },
  background: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    },
    "@media (min-width:960px)": {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8)
    }
  },
  loading: {
    textAlign: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  header: {
    // @ts-ignore
    color: theme.palette.background.lightgray,
    fontSize: "2rem",
    marginBottom: theme.spacing(0),
    textAlign: "center",
    "@media (min-width:600px)": {
      fontSize: "2.8rem",
      lineHeight: "3rem"
    },
    "@media (min-width:960px)": {
      fontSize: "4.4rem",
      lineHeight: "4.6rem"
    }
  },
  subHeader: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontSize: "1rem",
    marginBottom: theme.spacing(3.5),
    textAlign: "center",
    fontWeight: 600,
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },
  largeTitle: {
    // @ts-ignore
    color: theme.palette.background.lightgray,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  episodeTitle: {
    // @ts-ignore
    color: theme.palette.background.upgradeAlt,
    marginBottom: theme.spacing(1),
    fontSize: "1.4rem"
  },
  descriptionText: {
    fontSize: "0.85rem",
    lineHeight: "1.1rem",
    // @ts-ignore
    color: theme.palette.common.darkgray,
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    }
  },
  detailsContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  detailsIcon: {
    display: "inline",
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginRight: "4px",
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem"
    }
  },
  detailsText: {
    display: "inline",
    // @ts-ignore
    color: theme.palette.common.darkgray,
    marginRight: theme.spacing(2),
    fontSize: "0.9rem"
  },
  bodyTextStrong: {
    fontSize: "1rem",
    fontWeight: 500,
    // @ts-ignore
    color: theme.palette.background.upgradeBG
  },
  marginSpacing: {
    margin: "20px 0"
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%"
  },
  player: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    "& video": {
      "&:focus": {
        outline: "none"
      }
    }
  },
  playIcon: {
    fontSize: "6rem",
    "@media (min-width:600px)": {
      fontSize: "7rem"
    },
    "@media (min-width:960px)": {
      fontSize: "8rem"
    }
  },
  smlTopDivider: {
    height: "2px",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLight,
    maxWidth: "100px",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  channelImage: {
    margin: "0 5% 0 15%",
    borderRadius: "50%",
    width: "80%",
    "@media (min-width:600px)": {
      margin: "0 5% 0 35%",
      borderRadius: "50%",
      width: "60%"
    },
    "@media (min-width:960px)": {
      margin: "0 5% 0 35%",
      borderRadius: "50%",
      width: "60%"
    }
  },
  countdownDivider: {
    height: "2px",
    width: "30%",
    backgroundColor: "#4a4a4a",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto"
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  creditsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  creditsTitle: {
    // @ts-ignore
    color: theme.palette.background.upgradeAlt
  },
  creditsDetail: {
    color: "#c3c3c3",
    margin: "4px 0",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.upgradeAlt
    }
  },
  tagsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  tagsTitle: {
    marginBottom: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.background.upgradeAlt
  },
  freeTrialBanner: {
    backgroundColor: "#444444"
  },
  fullEpisodeInfo: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    "&:hover": {
      cursor: "pointer"
    }
  },
  fullEpisodeButton: {
    padding: "0 !important",
    background: "none !important",
    border: "none !important",
    margin: "0 !important",
    "&:focus": {
      border: "none",
      outline: "none"
    }
  },
  fullEpisodeTitle: {
    color: theme.palette.common.white,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  fullEpisodeText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    fontSize: "1.6rem",
    fontWeight: 500,
    lineHeight: "1.8rem",
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  fullEpisodeDown: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontSize: "2.4rem"
  },
  shareEpisode: {
    textAlign: "center",
    "@media (min-width:600px)": {
      textAlign: "left"
    }
  },
  shareDivider: {
    height: "1px",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffGreen,
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      textAlign: "left",
      maxWidth: "100px",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  },
  shareText: {
    fontSize: "1rem",
    lineHeight: "1.1rem",
    marginBottom: theme.spacing(0),
    // @ts-ignore
    color: theme.palette.common.darkgray,
    "& span": {
      fontWeight: 500
    },
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(0),
      fontSize: "0.9rem",
      lineHeight: "1.1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    }
  },
  shareButton: {
    marginRight: theme.spacing(1),
    width: 46,
    "@media (min-width:600px)": {
      width: 34
    }
  },
  formBackground: {
    padding: theme.spacing(2),
    "@media (min-width:600px)": {
      padding: "5%"
    }
  },
  notifyTitle: {
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  notifySubTitle: {
    padding: theme.spacing(0, 1),
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    fontSize: "1rem",
    lineHeight: "1.1rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },
  phoneInput: {
    width: "inherit !important"
  },
  formFieldSet: {
    margin: theme.spacing(4, 0, 2, 0),
    "& label": {
      width: "auto",
      fontSize: "16px !important"
    },
    "& input": {
      padding: "14px 10px",
      fontSize: "1rem"
    },
    "& .react-tel-input .flag-dropdown:before": {
      fontSize: "19px !important",
      top: "-29px !important",
      content: "Mobile Number !important"
    },
    "& .react-tel-input .form-control": {
      fontSize: "16px !important"
    }
  },
  successIcon: {
    fontSize: "5rem"
  },
  successTitle: {
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    }
  },
  successMessage: {
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(0, 0, 2, 0),
    width: "100%",
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  tellFriend: {
    fontWeight: 500,
    margin: theme.spacing(2, 0, 1, 0)
  },
  closeHeader: {
    position: "absolute",
    top: "0px",
    right: "0px",
    padding: theme.spacing(1, 1.5)
  },
  closeHeaderIcon: {
    color: "#ddd"
  },
  tryBuyContainer: {
    // padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center"
  },
  tryButton: {
    width: "100%",
    margin: theme.spacing(2),
    // borderColor: "#e9e9e9",
    // color: "#e9e9e9",
    "&:hover": {
      // borderColor: "#fff",
      // color: "#fff",
      // transform: "scale(1.01)"
    },
    fontSize: "1.2rem"
  },
  buyButton: {
    width: "100%",
    margin: theme.spacing(2),
    // color: "#171717",
    "&:hover": {
      border: "2px solid",
      // borderColor: "#fff",
      // color: "#fff",
      transform: "scale(1.05)"
    },
    transition: "none",
    fontSize: "1.2rem"
  },
  smlText: {
    fontSize: "0.8rem",
    color: "#e9e9e9",
    marginTop: "10px",
    lineHeight: "1rem",
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      width: "70%"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      width: "55%"
    }
  },
  bottomBackground: {
    flexGrow: 1,
    backgroundColor: "#292929",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTop: "10px solid #202020",
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10)
    }
  },
  bodyTitle: {
    marginBottom: theme.spacing(2),
    fontSize: "2.6rem",
    lineHeight: "2.6rem",
    fontWeight: 500,
    // @ts-ignore
    color: theme.palette.common.ffNavyLight,
    textAlign: "center",
    marginTop: 0,
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      textAlign: "center",
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3.4rem",
      lineHeight: "3.4rem"
    }
  },
  bodySubTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: "1.2rem",
    fontWeight: 500,
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },
  bodySubTitleAlt: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: "1.2rem",
    fontWeight: 500,
    color: theme.palette.common.white,
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    },
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    }
  },
  bodyTextSml: {
    marginTop: theme.spacing(2),
    // @ts-ignore
    color: theme.palette.common.lightgray,
    fontSize: "0.7rem",
    lineHeight: "0.9rem"
  },
  smlDivider: {
    margin: theme.spacing(3, 2),
    width: "20%",
    height: "2px",
    backgroundColor: "#585858",
    "@media (min-width:600px)": {
      width: "30%",
      height: "2px",
      margin: theme.spacing(4, 2)
    }
  },
  listItem: {
    paddingTop: 0,
    textAlign: "center"
  },
  listItemIconContainer: {
    minWidth: "auto",
    marginRight: "20px"
  },
  listItemText: {
    fontSize: "1rem",
    textAlign: "center",
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "0.9rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    }
  },
  bottomChannelImage: {
    width: "94%",
    margin: "0 2%"
  },
  channelDesc: {
    padding: theme.spacing(0.5, 2),
    fontWeight: 500
  },
  subHeading: {
    // @ts-ignore
    color: theme.palette.background.white,
    // color: theme.palette.background.ffNavy,
    // textAlign: "left",
    fontSize: "1.6rem",
    lineHeight: "1.6rem",
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2rem",
      lineHeight: "2.2rem"
    }
  },
  iconStyle: {
    fontSize: "2.8rem",
    "@media (min-width:600px)": {
      fontSize: "2.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3.2rem"
    }
  },
  infoBox: {
    textAlign: "center",
    marginTop: theme.spacing(3)
  },
  primaryButton: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffGreen,
    width: "80%",
    margin: "12px 5%",
    fontSize: "1.1rem"
  },
  secondaryButton: {
    textDecoration: "underline",
    textDecorationThickness: "2px",
    boxShadow: "none",
    backgroundColor: "transparent",
    border: "none",
    // @ts-ignore
    color: theme.palette.common.white,
    transition: "none",
    margin: theme.spacing(0, 0, 0.5, 0),
    width: "100%",
    fontSize: "1.1rem",
    padding: theme.spacing(1, 6, 2, 6),
    "&:hover": {
      border: "none",
      backgroundColor: "transparent",
      textDecoration: "underline",
      textDecorationThickness: "2px",
      transition: "none",
      boxShadow: "none",
      // @ts-ignore
      color: theme.palette.background.ffGreen
    }
  }
}));

const SignUpStepPurchase = ({
  route
}: {
  route: "try" | "buy" | "trybuy" | "giftcard" | undefined;
}) => {
  const [buyOpen, setBuyOpen] = useState(false);
  const [availableProducts, setAvailableProducts] = useState<Product[]>();
  const { runWithLoader } = useContext(OnboardingContext);
  const { validateLogin } = useContext(AccountContext);
  const { logMetricEvent } = useLogger();
  const classes = useStyles();

  const trialProduct = useMemo(
    () =>
      availableProducts?.find(p => p.memberStatusId === MEMBER_STATUS_TRIAL),
    [availableProducts]
  );
  const standardProducts = useMemo(
    () =>
      availableProducts?.filter(
        p => p.memberStatusId === MEMBER_STATUS_STANDARD
      ),
    [availableProducts]
  );

  const takeTrial = useCallback(() => {
    if (!trialProduct) return;

    runWithLoader(async () => {
      await createPayment({ productId: trialProduct.id });
      logMetricEvent({
        type: "free-trial",
        payload: {}
      });
      await validateLogin();
    });
  }, [runWithLoader, trialProduct, validateLogin, logMetricEvent]);

  const buyNow = useCallback(() => {
    if (!standardProducts) return;
    setBuyOpen(true);
  }, [standardProducts]);

  useEffect(() => {
    runWithLoader(async () => {
      const products = await getProducts();
      setAvailableProducts(products.results);
    }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasTry = !!trialProduct;
  const hasBuy = standardProducts && standardProducts.length > 0;

  return (
    <StepContainer>
      <StepContainer.Body>
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          {!buyOpen && (
            <>
              <Grid item xs={12} sm={12} alignItems="center">
                <Box className={classes.infoBox}>
                  <Typography
                    variant="subtitle1"
                    className={classes.subHeading}
                  >
                    {route === "try" && hasTry
                      ? "You're Ready to Go"
                      : "Welcome to FarmFLiX"}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.bodyText}
                    align="center"
                    style={{
                      fontWeight: 400,
                      color: "#999",
                      lineHeight: "1.1rem",
                      marginBottom: "24px"
                    }}
                  >
                    {route === "try" && hasTry ? (
                      <>
                        You can now login anytime you want. <br />
                        {isBrowser ? "Click" : "Tap"} on &apos;Start Free
                        Trial&apos; to begin.
                      </>
                    ) : (
                      <>
                        Your FarmFLiX Account is all setup and ready to go.{" "}
                        {isBrowser ? "Click" : "Tap"} on &apos;View Membership
                        Plans&apos; to begin.
                      </>
                    )}
                  </Typography>
                </Box>
              </Grid>

              {hasTry && route === "try" && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={classes.tryBuyContainer}
                  alignItems="center"
                >
                  <Button
                    name="try"
                    onClick={takeTrial}
                    size="medium"
                    className={classes.primaryButton}
                  >
                    Start Free Trial
                  </Button>
                </Grid>
              )}
              {hasBuy && (route === "buy" || !hasTry) && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={classes.tryBuyContainer}
                  justify="center"
                >
                  <Button
                    name="buy"
                    onClick={buyNow}
                    size="medium"
                    className={classes.primaryButton}
                  >
                    View Membership Plans
                  </Button>
                </Grid>
              )}
            </>
          )}
          {buyOpen && (
            <BuyNowPurchase
              dark={true}
              title="Choose your Plan"
              showStepper={false}
            />
          )}
        </Grid>
      </StepContainer.Body>
      <StepContainer.Actions />
    </StepContainer>
  );
};

export default SignUpStepPurchase;
