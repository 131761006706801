import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Box, Typography, Hidden } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  "@keyframes scroll": {
    "0%": {
      transform: "translateX(0)"
    },
    "100%": {
      transform: "translateX(-50%)"
    }
  },
  marqueeContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    overflow: "hidden",
    minWidth: "0",
    animationName: "$scroll"
  },
  marqueeContent: {
    display: "table"
  },
  scroll: {
    animation: "scroll 40s linear infinite",
    animationName: "scroll"
  },
  marqueeItem: {
    display: "table-cell",
    transition: "all 0.2s ease"
  },
  backgroundContainer: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "auto",
    height: "auto",
    display: "flex",
    boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.7)"
  },
  mt30: {
    marginTop: "30px"
  },
  mb30: {
    marginBottom: "30px"
  },
  mt10: {
    marginTop: "10px"
  },
  mb10: {
    marginBottom: "10px"
  },
  smlText: {
    fontSize: "0.7rem",
    color: "#e9e9e9"
  }
}));

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <Paper className={classes.backgroundContainer + " banner-bg"} square>
      <Hidden xsDown>
        <Grid container align="center" spacing={0}>
          <Grid item xs={12} sm={12}>
            <Box m={14}>
              <Typography variant="h1">
                We Make
                <br />
                Agricultural Videos.
              </Typography>
              <Typography variant="body1" className={classes.mt10}>
                Dedicated to delivering the best agricultural content to the
                farming community.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid container align="center" spacing={0}>
          <Grid item xs={12} sm={12}>
            <Box my={8} mx={4}>
              <Typography variant="h1">
                We Make
                <br />
                Agricultural Videos.
              </Typography>
              <Typography variant="body1" className={classes.mt10}>
                Dedicated to delivering the best agricultural content to the
                farming community.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Hidden>
    </Paper>
  );
}
