import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress
} from "@material-ui/core";
import DefaultLayout from "../layouts/DefaultLayout";
import LoggedInLayout from "../layouts/LoggedInLayout";
import { AccountContext } from "../store/AccountContext";
import { PlayerContext } from "../store/PlayerContext";
import { Link, RouteComponentProps } from "react-router-dom";
import PreviewVideoRow from "./Video/PreviewVideoRow";
import { Player } from "./common/player";
import { Helmet } from "react-helmet";
import { getTitleAndChannelFromVideoName } from "../utils/strings";
import PreviewCTA from "./Home/PreviewCTA";

const useStyles = makeStyles(theme => ({
  background: {
    width: "100%",
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy
  },
  loading: {
    textAlign: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  largeTitle: {
    // @ts-ignore
    color: theme.palette.background.lightgray,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  episodeTitle: {
    // @ts-ignore
    color: theme.palette.background.upgradeAlt,
    marginBottom: theme.spacing(1),
    fontSize: "1.4rem"
  },
  bodyText: {
    color: "#bbb"
  },
  detailsContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  detailsIcon: {
    display: "inline",
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginRight: "4px",
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem"
    }
  },
  detailsText: {
    display: "inline",
    // @ts-ignore
    color: theme.palette.common.darkgray,
    marginRight: theme.spacing(2),
    fontSize: "0.9rem"
  },
  bodyTextStrong: {
    fontSize: "1rem",
    fontWeight: 500,
    // @ts-ignore
    color: theme.palette.background.upgradeBG
  },
  marginSpacing: {
    margin: "20px 0"
  }
}));

const PreviewVideo = (props: RouteComponentProps<{ id: string }>) => {
  const classes = useStyles();

  const { loggedIn } = useContext(AccountContext);

  const videoId = props.match.params.id;

  const {
    videoLoading,
    loadPreviewVideo,
    currentPreviewVideo,
    clearCurrentPreviewVideo
  } = useContext(PlayerContext);

  useEffect(() => {
    return () => {
      clearCurrentPreviewVideo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (videoId) {
      loadPreviewVideo(videoId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  if (videoLoading) {
    return (
      <DefaultLayout>
        <div className={`${classes.background} ${classes.loading}`}>
          <CircularProgress color="primary" />
        </div>
      </DefaultLayout>
    );
  }

  function getEpisodeName(name: string): string {
    const { title } = getTitleAndChannelFromVideoName(name);
    return title;
  }

  const Layout = loggedIn ? LoggedInLayout : DefaultLayout;

  return (
    <Layout>
      <div className={classes.background}>
        {currentPreviewVideo ? (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                {getEpisodeName(currentPreviewVideo.name)} | FarmFLiX.tv
              </title>
              <meta
                name="description"
                content={
                  currentPreviewVideo
                    ? currentPreviewVideo.description
                    : "FarmFLiX is an online video platform dedicated to delivering the best agricultural videos, using the latest technologies, to the farming community."
                }
              />
            </Helmet>
            <Container>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Player
                    userId={undefined}
                    video={currentPreviewVideo}
                    type="preview"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PreviewVideoRow video={currentPreviewVideo} />
                </Grid>
              </Grid>
            </Container>
            <PreviewCTA />
          </>
        ) : (
          <Container fixed>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} alignItems="center">
                <Box my={10}>
                  <Typography variant="h1" className={classes.largeTitle}>
                    404
                  </Typography>
                  <Typography variant="h3" color="primary">
                    Page Not Found
                  </Typography>
                  <Link to="/videos" style={{ textDecoration: "none" }}>
                    <Button size="small" className={classes.marginSpacing}>
                      Go to Dashboard
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </Layout>
  );
};

export default PreviewVideo;
