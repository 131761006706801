import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Hidden
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import appleappstore from "./../Modals/app-store-logo.png";
import googleplaystore from "./../Modals/google-play-logo.png";
import {
  isMobile,
  isTablet,
  isBrowser,
  isIOS,
  isAndroid
} from "react-device-detect";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "auto",
    display: "flex",
    height: "auto",

    "@media (min-width:600px)": {
      height: "auto"
    },
    "@media (min-width:769px)": {
      height: "auto"
    },
    "@media (min-width:960px)": {
      height: "100vh"
    },
    "@media (min-width:1280px)": {
      height: "80vh",
      maxHeight: "880px"
    }
  },
  centerBoxLeft: {
    position: "relative",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    marginTop: theme.spacing(5),
    "@media (min-width:600px)": {
      alignItems: "flex-start",
      height: "auto",
      marginTop: theme.spacing(5)
    },
    "@media (min-width:769px)": {
      height: "auto"
    },
    "@media (min-width:960px)": {
      height: "100vh",
      alignItems: "flex-start",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:1280px)": {
      height: "80vh",
      maxHeight: "880px"
    }
  },
  centerBoxRight: {
    position: "relative",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    "@media (min-width:600px)": {
      height: "auto",
      marginTop: theme.spacing(4)
    },
    "@media (min-width:769px)": {
      height: "auto"
    },
    "@media (min-width:960px)": {
      height: "100vh",
      alignItems: "flex-start",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:1280px)": {
      height: "80vh",
      maxHeight: "880px"
    }
  },
  titleOld: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      fontSize: "2rem",
      lineHeight: "2.2rem"
    },
    "@media (min-width:600px)": {
      fontSize: "6.4rem",
      lineHeight: "4.6rem",
      "& span": {
        fontSize: "4rem",
        lineHeight: "4.2rem"
      }
    },
    "@media (min-width:960px)": {}
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "4.2rem",
      lineHeight: "4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "80%"
    },
    "@media (min-width:960px)": {}
  },
  buttonSubText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginLeft: 0,
    "@media (min-width:600px)": {
      textAlign: "left",
      marginLeft: theme.spacing(5)
    },
    "@media (min-width:960px)": {}
  },
  appImage: {
    minHeight: "500px",
    maxHeight: "700px",
    marginBottom: theme.spacing(5),
    "@media (min-width:960px)": {
      marginBottom: theme.spacing(0)
    }
  },
  appImageHalf: {
    minHeight: "250px",
    maxHeight: "450px",
    marginBottom: theme.spacing(0),
    "@media (min-width:960px)": {
      marginBottom: theme.spacing(0)
    }
  },
  skinWrapper: {
    position: "relative"
  },
  dashboardScroller: {
    position: "absolute",
    top: "64px",
    left: "-1px",
    height: "365px",
    width: "258px",
    padding: "7% 7% 0 7%",
    overflow: "hidden",
    "@media (min-width:960px)": {
      width: "296px",
      top: "95px",
      left: "22px",
      padding: "0",
      height: "580px"
    },
    "@media (min-width:960px": {}
  },
  imageContainer: {
    height: "365px",
    "@media (min-width:600px)": {
      height: "515px"
    }
  },
  noUnderline: {
    textDecoration: "none"
  },
  appIcon: {
    marginRight: theme.spacing(2),
    "@media (min-width:769px)": {
      marginRight: theme.spacing(0)
    }
  },
  appIconGraphic: {
    width: "80px",
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      width: "110px",
      marginBottom: theme.spacing(1)
    }
  },
  appStoreLogo: {
    maxWidth: "140px",
    margin: "5px",
    textAlign: "center",
    "@media (min-width:600px)": {
      margin: "10px 5px",
      maxWidth: "130px"
    },
    "@media (min-width:960px)": {
      margin: "10px 5px",
      maxWidth: "160px"
    }
  },
  buttonContainer: {
    textAlign: "center",
    margin: theme.spacing(1, 0, 3, 0),
    padding: theme.spacing(1),
    "@media (min-width:600px)": {
      textAlign: "center"
    }
  },
  purchaseText: {
    letterSpacing: "-1px",
    fontSize: "1rem",
    lineHeight: "1.2rem",
    fontWeight: 500,
    textShadow: "-2px 2px 2px #171717",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: "#ddd"
  },
  downloadTextContainer: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    width: "100%",
    "@media (min-width:769px)": {
      textAlign: "left",
      width: "auto"
    }
  },
  downloadText: {
    letterSpacing: "-1px",
    fontSize: "1rem",
    lineHeight: "1.2rem",
    fontWeight: 400,
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(1),
    color: "#ddd"
  },
  appTitleContainer: {
    display: "flex",
    flexDirection: "row",
    "@media (min-width:769px)": {
      flexDirection: "column"
    }
  },
  installAppButton: {
    width: "90%",
    margin: "12px 5% 16px 5%",
    fontSize: "1.2rem",
    border: "2px solid #fff",
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    "&:hover": {
      border: "2px solid #99ca3c",
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      width: "auto",
      margin: "32px 0 16px 0",
      padding: theme.spacing(2, 6)
    }
  }
}));

const Trial = () => {
  const classes = useStyles();

  function AppLinks() {
    const classes = useStyles();

    if (isMobile || isTablet) {
      return (
        <>
          <Grid item xs={12} md={7} className={classes.centerBoxLeft}>
            <Box className={classes.appTitleContainer}>
              <Box className={classes.appIcon}>
                <img
                  src={
                    "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/app_logo_200_dark.jpg"
                  }
                  alt="FarmFLiX App Icon"
                  className={classes.appIconGraphic}
                />
              </Box>
              <Box>
                <Typography variant="h2" className={classes.title}>
                  Install The
                </Typography>
                <Typography variant="h2" className={classes.titleLarge}>
                  Free App
                </Typography>
              </Box>
            </Box>
            <Box className={classes.downloadTextContainer}>
              {/* <a
                href={"https://smart.link/6fa45jd155j1i"}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.installAppButton}
                >
                  INSTALL THE APP
                </Button>
              </a> */}

              {isIOS && (
                <>
                  <a
                    href="https://apps.apple.com/us/app/farmflix/id1490609587"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      size="large"
                      variant="outlined"
                      className={classes.installAppButton}
                    >
                      INSTALL THE APP
                    </Button>
                  </a>
                </>
              )}

              {isAndroid && (
                <>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.farmflix&hl=en_GB&gl=US"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      size="large"
                      variant="outlined"
                      className={classes.installAppButton}
                    >
                      INSTALL THE APP
                    </Button>
                  </a>
                </>
              )}
              <Typography variant="h4" className={classes.downloadText}>
                or download at:
              </Typography>
              <a
                href={"https://apps.apple.com/us/app/farmflix/id1490609587"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appleappstore}
                  className={classes.appStoreLogo}
                  alt="Apple App Store Graphic"
                />
              </a>
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.farmflix&hl=en_GB&gl=US"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googleplaystore}
                  className={classes.appStoreLogo}
                  alt="Google Play Store Graphic"
                />
              </a>
              <Typography variant="h4" className={classes.purchaseText}>
                Free Download. No Payment Required.
              </Typography>
            </Box>
          </Grid>
        </>
      );
    } else if (isBrowser) {
      return (
        <>
          <Grid item xs={12} md={7} className={classes.centerBoxLeft}>
            <Box className={classes.appTitleContainer}>
              <Box className={classes.appIcon}>
                <img
                  src={
                    "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/app_logo_200_dark.jpg"
                  }
                  alt="FarmFLiX App Icon"
                  className={classes.appIconGraphic}
                />
              </Box>
              <Box>
                <Typography variant="h2" className={classes.title}>
                  Install The
                </Typography>
                <Typography variant="h2" className={classes.titleLarge}>
                  Free App
                </Typography>
              </Box>
            </Box>
            <Box className={classes.downloadTextContainer}>
              <Typography variant="h4" className={classes.downloadText}>
                Download at:
              </Typography>
              <a
                href={"https://apps.apple.com/us/app/farmflix/id1490609587"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appleappstore}
                  className={classes.appStoreLogo}
                  alt="Apple App Store Graphic"
                />
              </a>
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.farmflix&hl=en_GB&gl=US"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googleplaystore}
                  className={classes.appStoreLogo}
                  alt="Google Play Store Graphic"
                />
              </a>
              <Typography variant="h4" className={classes.purchaseText}>
                Free Download. No Payment Required.
              </Typography>
            </Box>
          </Grid>
        </>
      );
    }
  }

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="lg" fixed>
          <Grid container>
            <AppLinks />
            {/* <Grid item xs={12} md={7} className={classes.centerBoxLeft}>
              <Box className={classes.appTitleContainer}>
                <Box className={classes.appIcon}>
                  <a
                    href={buildAppSmartLink()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={
                        "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/app_logo_200_dark.jpg"
                      }
                      alt="FarmFLiX App Icon"
                      className={classes.appIconGraphic}
                    />
                  </a>
                </Box>
                <Box>
                  <Typography variant="h2" className={classes.title}>
                    Install The
                  </Typography>
                  <Typography variant="h2" className={classes.titleLarge}>
                    Free App
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.downloadTextContainer}>
                <a
                  href={"https://smart.link/6fa45jd155j1i"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    className={classes.installAppButton}
                  >
                    INSTALL THE APP
                  </Button>
                </a>
                <Typography variant="h4" className={classes.downloadText}>
                  or download at:
                </Typography>
                <a
                  href={buildAppSmartLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appleappstore}
                    className={classes.appStoreLogo}
                    alt="Apple App Store Graphic"
                  />
                </a>
                <a
                  href={buildAppSmartLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={googleplaystore}
                    className={classes.appStoreLogo}
                    alt="Google Play Store Graphic"
                  />
                </a>
                <Typography variant="h4" className={classes.purchaseText}>
                  Free Download. No Payment Required.
                </Typography>
              </Box>
            </Grid> */}
            <Grid item xs={12} md={5} className={classes.centerBoxRight}>
              <div className={classes.skinWrapper}>
                {/* Visible SM, MD, L, XL */}
                <Hidden smDown>
                  <img
                    src={
                      "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/device_skin_100.png"
                    }
                    alt="FarmFLiX App"
                    className={classes.appImage}
                  />
                </Hidden>
                {/* Visible XS only */}
                <Hidden mdUp>
                  <img
                    src={
                      "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/device_skin_75.png"
                    }
                    alt="FarmFLiX App"
                    className={classes.appImageHalf}
                  />
                </Hidden>
                <div className={classes.dashboardScroller}>
                  <Carousel
                    useKeyboardArrows={true}
                    showArrows={false}
                    interval={3000}
                    showThumbs={false}
                    infiniteLoop={true}
                    showStatus={false}
                    autoPlay={true}
                    showIndicators={false}
                    emulateTouch={false}
                    swipeable={false}
                    transitionTime={500}
                  >
                    <Box className={classes.imageContainer}>
                      <img
                        src={
                          "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/1.png"
                        }
                        alt="App Dashboard"
                      />
                    </Box>
                    <Box className={classes.imageContainer}>
                      <img
                        src={
                          "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/2.png"
                        }
                        alt="App Currently Playing"
                      />
                    </Box>
                    <Box className={classes.imageContainer}>
                      <img
                        src={
                          "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/4.png"
                        }
                        alt="Clydes Farm Series"
                      />
                    </Box>
                  </Carousel>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Trial;
