import React, { useContext, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Home from "./Home";
import SignIn from "./SignIn";
import TrialSuccess from "./Onboarding/success/TrialSuccess";
import ForgotPassword from "./ForgotPassword";
import Dashboard from "./Dashboard";
import PlaylistOverview from "./PlaylistOverview";
import SeriesOverview from "./SeriesOverview";
import ChannelOverview from "./ChannelOverview";
import MyAccount from "./MyAccount";
import Success from "./Onboarding/success/Success";
import About from "./About";
import Sitemap from "./Sitemap";
import TermsConditions from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiePolicy from "./CookiePolicy";
import Help from "./Help";
import FarmFLiXApp from "./FarmFLiXApp";
import FarmFLiXBigScreen from "./FarmFLiXBigScreen";
import OnboardingReferral from "./Onboarding/Referral";
import OnboardingPublic from "./Onboarding/Public";
import OnboardingPublicBuy from "./Onboarding/BuyPublic";
import OnboardingGiftcard from "./Onboarding/Giftcard";
import GiftcardSuccess from "./Onboarding/success/GiftcardSuccess";

// Latest
import LatestMaster from "./LatestMaster";
import LatestMasterLegacy from "./LatestMasterLegacy";
import LatestPreview from "./LatestPreview";

import Video from "./Video";

// Legacy
import VideoLegacy from "./VideoLegacy";
import PreviewVideo from "./PreviewVideo";

import AgLife from "./AgLife";
import Straight6 from "./Straight6";
import Stockyards from "./Stockyards";
import TheLoft from "./TheLoft";
// import OnThePull from "./OnThePull";
import Category from "./Category";
import Blog from "./Blog";
import Post from "./Post";
import Careers from "./Careers";
import Search from "./Search";
import Contact from "./Contact";
import NotFound from "./NotFound";
import DoorsClosed from "./DoorsClosed";
import Inbox from "./Inbox";

// Series Overview
import ClydesFarm from "./ClydesFarm";
import CarsehallFarm from "./CarsehallFarm";

// Event Pass
import EventPass from "./EventPass";

// Watch History
import WatchHistory from "./WatchHistory";
import Favourites from "./Favourites";

// Member Survey
import MemberSurvey from "./MemberSurvey";
import MemberSurveyCompleted from "./MemberSurveyCompleted";

// Welcome Back
import WelcomeBack from "./WelcomeBack";

import AutoLogin from "./AutoLogin";
import { LoggerContext } from "../store/LoggerContext";
import FreeTrialLanding from "./FreeTrialLanding";
import PublicMarketingPreferences from "./PublicMarketingPreferences";
import OnThePull from "./OnThePull";
import { OnThePullLive } from "./OnThePull/OnThePullLive";

const FreeTrialLandingFB = () => <FreeTrialLanding platform={"fb"} />;
const FreeTrialLandingSC = () => <FreeTrialLanding platform={"sc"} />;
const FreeTrialLandingTT = () => <FreeTrialLanding platform={"tt"} />;
const FreeTrialLandingGA = () => <FreeTrialLanding platform={"ga"} />;

const Routes = () => {
  const location = useLocation();
  const { logScreenView } = useContext(LoggerContext);

  useEffect(() => {
    logScreenView(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/videos" component={Dashboard} />
      <Route path="/videos/:tag/:id" component={Video} />
      <Route path="/video-details/:id" component={Video} />
      <Route path="/legacy-videos/:id" component={VideoLegacy} />
      <Route
        path="/referral/:code([a-zA-Z0-9-_]{6})"
        component={OnboardingReferral}
      />
      <Route
        path="/unlock/:code([a-zA-Z0-9-_]{6})"
        component={OnboardingReferral}
      />
      <Route
        path="/usemycode/:code([a-zA-Z0-9-_]{16,})?"
        component={OnboardingGiftcard}
      />
      <Route
        path="/marketing-preferences/:unsubscribeToken([a-zA-Z0-9-_]{16,})?"
        component={PublicMarketingPreferences}
      />
      <Route path="/trybuy" render={() => <Redirect to="/try" />} />
      <Route path="/buy-now" render={() => <Redirect to="/buy" />} />
      <Route path="/try" component={OnboardingPublic} />
      <Route path="/buy" component={OnboardingPublicBuy} />
      <Route path="/video-details/:id" component={Video} />
      <Route path="/preview/:id" component={PreviewVideo} />
      <Route path="/latest" component={LatestMaster} />
      <Route path="/legacy/latest" component={LatestMasterLegacy} />
      <Route exact path="/latest-preview" component={LatestPreview} />
      <Route path="/channel/:id" component={ChannelOverview} />
      <Route path="/playlist/:id" component={PlaylistOverview} />
      <Route path="/myaccount" component={MyAccount} />
      <Route path="/login/auto" exact={true} component={AutoLogin} />
      <Route path="/login" component={SignIn} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/success" exact component={Success} />
      <Route path="/success/trial" exact component={TrialSuccess} />
      <Route path="/success/giftcard" exact component={GiftcardSuccess} />
      <Route path="/throwbackthursday" component={DoorsClosed} />
      <Route path="/about" component={About} />
      <Route path="/terms" component={TermsConditions} />
      <Route path="/sitemap" component={Sitemap} />
      <Route path="/careers" component={Careers} />
      <Route path="/search" component={Search} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/cookiepolicy" component={CookiePolicy} />
      <Route path="/contact" component={Contact} />
      <Route path="/inbox" component={Inbox} />
      {/* The Mammy Challenge */}
      <Route path="/themammychallenge" render={() => <Redirect to="/" />} />
      {/* Series Overview  */}
      <Route path="/series/:id" component={SeriesOverview} />
      {/* Watch History */}
      <Route path="/watch-history" component={WatchHistory} />
      {/* Favourites  */}
      <Route path="/favourites" component={Favourites} />
      <Route
        path="/shop"
        component={() => {
          window.location.href = "https://shop.farmflix.tv/";
          return null;
        }}
      />
      <Route path="/help" component={Help} />
      <Route path="/app" component={FarmFLiXApp} exact={true} />
      <Route path="/bigscreen" component={FarmFLiXBigScreen} exact={true} />
      <Route path="/farmflix-app" component={FarmFLiXApp} />
      <Route path="/aglife" component={AgLife} />
      <Route path="/straight6" component={Straight6} />
      <Route path="/stockyards" component={Stockyards} />
      <Route path="/theloft" component={TheLoft} />
      <Route path="/onthepull" exact component={OnThePull} />
      <Route
        path="/onthepull/:view(leaderboard|matches)"
        component={OnThePullLive}
      />

      <Route path="/category/:slug" component={Category} />
      <Route path="/blog/" component={Blog} />
      <Route path="/post/:id" component={Post} />
      {/* Open Period Landing Pages */}
      <Route exact path="/ga/free-trial" component={FreeTrialLandingGA} />
      <Route exact path="/sc/free-trial" component={FreeTrialLandingSC} />
      <Route exact path="/tt/free-trial" component={FreeTrialLandingTT} />
      <Route exact path="/fb/free-trial" component={FreeTrialLandingFB} />
      <Route exact path="/free-trial" component={FreeTrialLandingFB} />
      <Route path="/free-trial-app">
        <Redirect to="/app" />
      </Route>
      <Route path="/farming">
        <Redirect to="/app" />
      </Route>
      <Route path="/youtube">
        <Redirect to="/app" />
      </Route>
      {/* Influencers */}
      <Route path="/farmerphil">
        <Redirect to="/app" />
      </Route>
      <Route path="/meatteacher">
        <Redirect to="/app" />
      </Route>
      <Route path="/cowfarmerdan">
        <Redirect to="/app" />
      </Route>
      <Route path="/finwalsh">
        <Redirect to="/app" />
      </Route>
      <Route path="/sammylambert">
        <Redirect to="/app" />
      </Route>
      <Route path="/aedinquirke">
        <Redirect to="/app" />
      </Route>
      <Route path="/devonfarmgirl">
        <Redirect to="/app" />
      </Route>
      <Route path="/louisecrowley">
        <Redirect to="/app" />
      </Route>
      <Route path="/maighread_b">
        <Redirect to="/app" />
      </Route>
      <Route path="/teeg110">
        <Redirect to="/app" />
      </Route>
      <Route path="/thatfarmerdude">
        <Redirect to="/app" />
      </Route>
      <Route path="/wildwelshshepherdess">
        <Redirect to="/app" />
      </Route>
      <Route path="/gerry6420">
        <Redirect to="/app" />
      </Route>
      <Route path="/yorkshirefarmlad">
        <Redirect to="/app" />
      </Route>
      <Route path="/samonthefarm">
        <Redirect to="/app" />
      </Route>
      <Route path="/clydesfarm" component={ClydesFarm} />
      <Route path="/carsehallfarm" component={CarsehallFarm} />
      <Route path="/member-survey" component={MemberSurvey} />
      <Route
        path="/member-survey-completed"
        component={MemberSurveyCompleted}
      />
      <Route path="/eventpass" component={EventPass} />

      <Route exact path="/welcomeback" component={WelcomeBack} />
      <Route
        path="/undefined"
        exact
        component={() => <Redirect to="/latest" />}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
