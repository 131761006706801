import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo
} from "react";
import { AccountContext } from "./../../store/AccountContext";
import {
  Grid,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
  Hidden
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import usePromise from "react-use-promise";
import CircularProgress from "@material-ui/core/CircularProgress";
import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import amex from "payment-icons/min/flat/amex.svg";
import discover from "payment-icons/min/flat/discover.svg";
import CreateIcon from "@material-ui/icons/Create";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useToasts } from "react-toast-notifications";
import Countdown from "react-countdown";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Route } from "react-router-dom";
import MarketingPreferencesModal from "./MarketingPreferencesModal.js";
import { OverviewContext } from "../../store/OverviewContext";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import useLogger from "../../hooks/useLogger";
import { useStripe } from "@stripe/react-stripe-js";
import { MEMBER_STATUS_STANDARD } from "../../api/entities/UserProfile";
dayjs.extend(advancedFormat);

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },
  myAccountSection: {
    marginTop: theme.spacing(3)
  },
  tableText: {
    color: theme.palette.background.ffNavy,
    "& span": {
      fontWeight: 500
    }
  },
  tableTextLink: {
    "& a": {
      color: theme.palette.background.ffNavy,
      textDecoration: "underline",
      "&:hover": {
        color: theme.palette.background.ffGreen,
        cursor: "pointer"
      }
    }
  },
  sectionDivider: {
    height: "1px",
    backgroundColor: "#c1c1c1",
    margin: theme.spacing(3, 0),
    "@media (min-width:600px)": {
      margin: "2% 0"
    }
  },
  tabsContainer: {
    backgroundColor: theme.palette.background.dark
  },
  fullWidthTab: {
    minWidth: "100%",
    padding: "0 30px",
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.common.lightgray,
    textAlign: "left",
    borderBottom: "1px solid #313131",
    borderTop: "1px solid #313131"
  },
  selected: {
    color: theme.palette.common.white
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    width: "5px",
    left: "0px"
  },
  personIconBox: {
    color: "#4b4b4b",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  personIcon: {
    fontSize: "5rem"
  },
  alignLeft: {
    textAlign: "left",
    alignItems: "unset"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  xsTabsContainer: {
    backgroundColor: "#e8e8e8",
    padding: "16px 0 8px 0"
  },
  xsDropdown: {
    backgroundColor: theme.palette.background.dark,
    width: "100%",
    marginTop: 0
  },
  xsDropdownItem: {
    padding: "0",
    "&:hover": {
      backgroundColor: "#3c3b3b"
    },
    "&.Mui-selected": {
      backgroundColor: "#3c3b3b",
      "&:hover": {
        backgroundColor: "#3c3b3b"
      }
    }
  },
  whiteText: {
    color: theme.palette.common.white,
    fontWeight: "500",
    fontSize: "0.9rem",
    textTransform: "uppercase"
  },
  iconStyle: {
    minWidth: "40px"
  },
  carrotIcon: {
    color: "#fff",
    marginRight: "10px"
  },
  menuContainer: {
    borderRadius: "0px"
  },
  alertErrorTitle: {
    color: theme.palette.common.white
  },
  alertSuccessTitle: {
    color: "#4caf50"
  },
  alertWarningTitle: {
    color: theme.palette.common.white
  },
  headerBox: {
    padding: "2%",
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.upgradeBG
  },
  headerBoxTitle: {
    fontSize: "4.6rem",
    color: theme.palette.background.ffGreen,
    margin: "4% 2%"
  },
  autoRenewButton: {
    width: "100%",
    backgroundColor: theme.palette.background.upgradeBG,
    borderColor: theme.palette.background.upgradeBG,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "#171717",
      borderColor: "#171717",
      color: theme.palette.common.white
    },
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  cardExpiry: {
    fontSize: "0.85rem",
    fontWeight: "400 !important"
  },
  defaultPaymentMethodCardBrand: {
    width: "50px",
    maxWidth: "50px",
    paddingRight: theme.spacing(2.5)
  },
  accountStatusButton: {
    borderColor: "#fff",
    color: "#fff",
    "&:hover": {
      borderColor: "#fff",
      color: "#fff"
    }
  },
  topMembershipContainer: {
    padding: theme.spacing(3, 4),
    backgroundColor: "#e2e2e2",
    color: theme.palette.background.ffNavy,
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      padding: theme.spacing(4)
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(6, 4)
    }
  },
  standardStatus: {
    borderLeft: "10px solid #99ca3c"
  },
  errorStatus: {
    borderLeft: "10px solid #c53737"
  },
  warningStatus: {
    borderLeft: "10px solid #ff9800"
  },
  expiredStatus: {
    borderLeft: "10px solid #ababab"
  },
  statusMembershipContainer: {
    fontSize: "2rem",
    lineHeight: "2.4rem",
    color: theme.palette.background.ffNavy,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.4rem"
    }
  },
  darkBodyText: {
    color: theme.palette.background.ffNavy,
    "& span": {
      fontWeight: 500
    },
    "& a": {
      color: theme.palette.background.ffNavy
    }
  },
  link: {
    fontWeight: "500 !important",
    color: theme.palette.background.ffNavy,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.background.ffGreen
    }
  },
  buttonMembershipContainer: {
    textAlign: "left",
    "@media (min-width:600px)": {
      textAlign: "right"
    }
  },
  buttonMembershipContainerBox: {
    float: "none",
    "@media (min-width:600px)": {
      float: "right",
      width: "220px"
    }
  },
  buttonMembership: {
    width: "100%",
    margin: "12px 0% 0 0%",
    "@media (min-width:600px)": {
      textAlign: "center",
      width: "240px",
      fontSize: "1rem"
    }
  },
  darkButton: {
    margin: 0,
    width: "100%",
    borderColor: theme.palette.background.ffNavy,
    color: theme.palette.background.ffNavy,
    "&:hover": {
      borderColor: theme.palette.background.ffNavy,
      color: theme.palette.background.ffNavy
    },
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  bodyList: {
    paddingLeft: theme.spacing(3),
    fontSize: "1rem",
    fontFamily: "Roboto, Helvetica Neue ,Arial,sans-serif",
    fontWeight: "400",
    lineHeight: "1.2rem",
    "& span": {
      fontWeight: 500
    }
  },
  dialogButton: {
    width: "96%",
    margin: "10px 2% 5px 2%",
    "@media (min-width:600px)": {
      width: "48.2%",
      margin: "10px 5px"
    }
  },
  dialogButtonSml: {
    width: "96%",
    margin: "10px 2% 5px 2%",
    "@media (min-width:600px)": {
      width: "47.2%",
      margin: "10px 5px"
    }
  },
  autoRenewSwitch: {
    "& .MuiSwitch-root": {
      width: "74px",
      height: "44px"
    },
    "& .MuiSwitch-thumb": {
      width: "26px",
      height: "26px"
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(30px)"
    },
    "& .MuiSwitch-track": {
      borderRadius: "10px"
    }
  },
  countdownText: {
    marginTop: "20px",
    fontSize: "1.4rem",
    lineHeight: "2rem",
    fontWeight: "600 !important",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2.6rem"
    }
  },
  autoRenewDialog: {
    "& .MuiDialogTitle-root": {
      backgroundColor: "#171717"
    }
  },
  autoRenewDialogTitle: {
    "& .MuiTypography-root": {
      lineHeight: "1.6rem",
      color: "#eee"
    }
  },
  smlText: {
    textAlign: "center",
    fontSize: "0.8rem",
    color: theme.palette.background.ffNavy,
    marginTop: theme.spacing(1.5),
    textDecoration: "underline",
    "@media (min-width:600px)": {
      textAlign: "right"
    },
    "&:hover": {
      color: theme.palette.background.ffGreen,
      cursor: "pointer"
    }
  },
  ORText: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.8rem",
    textAlign: "center",
    margin: theme.spacing(1),
    "@media (min-width:600px)": {
      textAlign: "right"
    }
  },
  joinNowButton: {
    width: "80%",
    margin: "12px 10% 0 10%",
    "@media (min-width:600px)": {
      textAlign: "right",
      width: "auto"
    }
  },
  freeTrialButton: {
    color: theme.palette.background.ffNavy,
    fontSize: "1rem",
    textAlign: "center",
    margin: theme.spacing(2, 1, 1, 1),
    "@media (min-width:600px)": {
      textAlign: "right"
    },
    "& span": {
      fontWeight: 500,
      textDecoration: "underline"
    }
  },
  upgradeMembershipContainer: {
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.background.upgrade,
    color: "#fff",
    borderLeft: "10px solid #0f98d4",
    "@media (min-width:600px)": {
      padding: theme.spacing(4)
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(2, 4)
    }
  },
  upgradeMembershipTextContainer: {
    display: "flex",
    alignItems: "center"
  },
  upgradeMembershipText: {
    letterSpacing: "-0.5px"
  },
  upgradeMembershipButtonContainer: {
    textAlign: "center",
    "@media (min-width:600px)": {
      textAlign: "right"
    },
    "& a": {
      textDecoration: "none"
    }
  },
  upgradeMemberShipButton: {
    backgroundColor: "#0f98d4",
    color: "#fff",
    border: "2px solid #0f98d4",
    width: "100%",
    marginTop: theme.spacing(1),
    "@media (min-width:600px)": {
      textAlign: "right",
      width: "auto",
      marginTop: theme.spacing(0)
    },
    "&:hover": {
      backgroundColor: "#109edb",
      border: "2px solid #109edb"
    }
  }
}));

export default function Overview() {
  const classes = useStyles();
  const { addToast } = useToasts();
  const history = useHistory();
  const { logMetricEvent } = useLogger();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const stripe = useStripe();

  // Account Context
  const {
    updating,
    userProfile,
    getMembershipDetails,
    handleCancelMembership,
    handleResumeMembership,
    subscriptionProducts,
    getSubscriptionProducts,
    assignTrialMembership
  } = useContext(AccountContext);

  const { overview } = useContext(OverviewContext);

  useEffect(() => {
    getSubscriptionProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [reloadTrigger, setReloadTrigger] = useState(Date.now());
  const [membershipDetails, , state] = usePromise(getMembershipDetails, [
    reloadTrigger
  ]);

  const loading = state === "pending";

  const standardProduct = useMemo(() => {
    return subscriptionProducts?.results.find(
      p => p.memberStatusId === "standard"
    );
  }, [subscriptionProducts]);

  const trialProduct =
    subscriptionProducts &&
    subscriptionProducts?.results.find(p => p.memberStatusId === "trial");

  const takeTrial = useCallback(async () => {
    try {
      logMetricEvent({
        type: "free-trial",
        payload: {}
      });
      await assignTrialMembership();
    } catch (e) {
      addToast(e.toString(), {
        appearance: "error"
      });
    }
  }, [assignTrialMembership, addToast, logMetricEvent]);

  // eslint-disable-next-line
  const reload = () => setReloadTrigger(Date.now());

  // Auto Renew Logic
  // eslint-disable-next-line
  const [autoRenewChecked, setAutoRenewChecked] = useState();

  const [paymentFailedInfoOpen, setPaymentFailedInfoOpen] = useState(false);
  const [
    paymentAlreadyUpdatedInfoOpen,
    setPaymentAlreadyUpdatedInfoOpen
  ] = useState(false);
  const [turnOffAutoRenewOpen, setTurnOffAutoRenewOpen] = useState(false);
  const [turnOnAutoRenewOpen, setTurnOnAutoRenewOpen] = useState(false);

  const [paymentAuthStatus, setPaymentAuthStatus] = useState("");

  const handleAutoRenewSwitch = () => {
    if (!membershipDetails || !membershipDetails.hasStripeSubscription) {
      history.push("/myaccount/join-now");
      return;
    }

    if (
      membershipDetails &&
      !membershipDetails.defaultPaymentMethod &&
      membershipDetails.subscription.cancelAtPeriodEnd
    ) {
      history.push("/myaccount/add-payment-method-autorenew");
      return;
    }

    if (
      membershipDetails &&
      membershipDetails.hasStripeSubscription &&
      membershipDetails.subscription.cancelAtPeriodEnd
    ) {
      // Show Turn On Auto Renew Dialog (Modal)
      setTurnOnAutoRenewOpen(true);
    } else {
      // Show Turn Off Auto Renew Dialog (Modal)
      setTurnOffAutoRenewOpen(true);
    }
  };

  // Grand
  const handleTurnOffAutoRenew = async () => {
    try {
      await handleCancelMembership();
      // Add Toast - Success
      addToast("Auto Renew Turned Off", {
        appearance: "success"
      });
      setAutoRenewChecked(false);
      handleAutoRenewOffCloseDialog();
      reload();
    } catch (e) {
      addToast(e.toString(), {
        appearance: "error"
      });
    }
  };

  // Grand
  const handleTurnOnAutoRenew = async () => {
    try {
      await handleResumeMembership();
      addToast("Auto Renew Turned On.", {
        appearance: "success"
      });
      setAutoRenewChecked(true);
      handleAutoRenewOnCloseDialog();
      reload();
    } catch (e) {
      addToast(e.toString(), {
        appearance: "error"
      });
    }
  };

  const handleAutoRenewOffCloseDialog = () => {
    setTurnOffAutoRenewOpen(false);
  };

  const handleAutoRenewOnCloseDialog = () => {
    setTurnOnAutoRenewOpen(false);
  };

  const handlePaymentFailedCloseDialog = () => {
    setPaymentFailedInfoOpen(false);
  };

  const handlePaymentAlreadyUpdatedCloseDialog = () => {
    setPaymentAlreadyUpdatedInfoOpen(false);
  };

  const handlePaymentAuthCloseDialog = () => {
    reload();
    setPaymentAuthStatus({ dialogOpen: false });
  };

  const countdownRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
    props: { endingText, endedText }
  }) => {
    if (completed) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      return (
        <Typography variant="body1" className={classes.tableText}>
          {endedText}
        </Typography>
      );
    } else {
      // Render countdown
      return (
        <Typography
          variant="body1"
          className={classes.tableText}
          style={{ marginTop: "8px", fontWeight: 500 }}
        >
          {endingText} <br />
          <span className={classes.countdownText}>
            {days >= 1 && (
              <>
                {days.toString().length === 1 ? "" : ""}
                {days}
                <span>d</span>
                <span style={{ fontWeight: "bold", color: "#888" }}>{":"}</span>
              </>
            )}
            {hours.toString().length === 1 ? "0" : ""}
            {hours}
            <span>h</span>
            <span style={{ fontWeight: "bold", color: "#888" }}>{":"}</span>
            {minutes.toString().length === 1 ? "0" : ""}
            {minutes}
            <span>m</span>
            <span style={{ fontWeight: "bold", color: "#888" }}>{":"}</span>
            {seconds.toString().length === 1 ? "0" : ""}
            {seconds}
            <span>s</span>
          </span>
        </Typography>
      );
    }
  };

  const RenderAnnualUpgradeCountdown = ({ days, hours, minutes, seconds }) => {
    return (
      <span>
        <span>{days}d</span> :{" "}
        <span>
          {hours.toString().length === 1 ? "0" : ""}
          {hours}h
        </span>{" "}
        :{" "}
        <span>
          {minutes.toString().length === 1 ? "0" : ""}
          {minutes}m
        </span>{" "}
        :{" "}
        <span>
          {seconds.toString().length === 1 ? "0" : ""}
          {seconds}s
        </span>
      </span>
    );
  };

  function PrettyMembershipHeader(membershipDetails) {
    if (membershipDetails) {
      switch (membershipDetails.memberStatusId) {
        case "standard":
          if (membershipDetails.accountAction) {
            if (
              membershipDetails.accountAction.action === "authorize-payment"
            ) {
              return PrettyMembershipDetail(
                "Action Required",
                "Your FarmFLiX Membership Payment requires Authorization.",
                "Authorize your Payment Method to maintain your membership."
              );
            }
            if (
              membershipDetails.accountAction.action === "add-payment-method"
            ) {
              if (membershipDetails.defaultPaymentMethod) {
                return PrettyMembershipDetail(
                  "Payment Required",
                  "Your FarmFLiX Membership requires payment.",
                  "Update your Payment Method to start watching."
                );
              } else {
                return PrettyMembershipDetail(
                  "Payment Required",
                  "Your FarmFLiX Membership requires payment.",
                  "Add a Payment Method to start watching."
                );
              }
            }
          } else {
            return PrettyMembershipDetail(
              "Active",
              "Your FarmFLiX Membership is Active"
            );
          }
          break;
        case "standard-expired":
          if (membershipDetails.accountAction) {
            if (
              membershipDetails.accountAction.action === "authorize-payment"
            ) {
              return PrettyMembershipDetail(
                "Action Required",
                "Your FarmFLiX Membership Payment requires Authorization.",
                "Authorize your Payment Method to start watching."
              );
            } else if (
              membershipDetails.accountAction.action === "add-payment-method"
            ) {
              return PrettyMembershipDetail(
                "Payment Required",
                "Your FarmFLiX Membership requires payment",
                "Add a Payment Method to start watching."
              );
            }
          } else {
            return PrettyMembershipDetail(
              "Lapsed",
              "Your FarmFLiX Membership has Expired!",
              "Start watching again in two simple steps."
            );
          }
          break;
        case "standard-incomplete":
          return PrettyMembershipDetail(
            "Membership Incomplete",
            "Your FarmFLiX Membership is incomplete!",
            "Complete your membership in two simple steps."
          );
        case "trial":
          return PrettyMembershipDetail(
            "Trial",
            "Don't miss out! Join now to maintain your FarmFLiX Membership."
          );
        case "trial-expired":
          return PrettyMembershipDetail(
            "Trial Expired",
            "Your Free Trial has Expired!",
            "Start watching again in two simple steps."
          );
        case "locked":
          if (overview && overview.doors.open) {
            return PrettyMembershipDetail(
              "Membership Unlocked",
              "Your Membership is now unlocked"
            );
          } else {
            return PrettyMembershipDetail(
              "Membership Locked",
              "Your Membership is Locked"
            );
          }

        default:
      }
    }
  }

  const PrettyMembershipDetail = (title, subtitle, desc) => {
    return (
      <>
        <Typography variant="h4" className={classes.statusMembershipContainer}>
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="body1"
            className={classes.darkBodyText}
            style={{ marginBottom: "12px" }}
          >
            {subtitle}
          </Typography>
        )}
        {desc && (
          <>
            <Typography
              variant="body1"
              className={classes.darkBodyText}
              style={{ marginBottom: "12px" }}
            >
              <span>{desc}</span>
            </Typography>
          </>
        )}
      </>
    );
  };

  // Standard and

  // Move to Utils
  function PrettyProductType(productId) {
    if (productId) {
      switch (productId) {
        case "voucher-3-month":
          return "3 Month Giftcard";
        case "voucher-12-month":
          return "12 Month Giftcard";
        case "eventpass-1-month":
          return "1 Month Event Pass";
        case "annual":
        case "annual52":
        case "annual52-disc":
        case "annual60":
        case "annual60eur":
        case "annual60eur-disc":
        case "annual70eur":
        case "annual70usd":
        case "annual70usd-disc":
        case "annual85usd":
        case "annual75":
        case "annual90eur":
        case "annual95usd":
          return "Annual Membership";
        case "free-trial":
          return "Free Trial";
        case "monthly":
        case "monthly650":
        case "monthly699":
        case "monthly799":
        case "monthly799eur":
        case "monthly899eur":
        case "monthly999usd":
          return "Monthly Membership";
        case "legacy-one-off-payment":
          return "Full Membership";
        case "3-day-ppv":
        case "3-day-ppv-eur":
        case "3-day-ppv-usd":
          return "3 Day Pass";
        default:
      }
    }
  }

  function CurrencySymbol({ currency }) {
    switch (currency) {
      case "gbp":
        return "\u00A3";
      case "eur":
        return "\u20AC";
      case "usd":
        return "\u0024";
      default:
        return "\u00A3";
    }
  }

  const MemberStatusBox = props => {
    const attemptAuthentication = () => {
      console.log("Attempt Authentication...");

      if (membershipDetails && membershipDetails.accountAction) {
        stripe
          .confirmCardPayment(membershipDetails.accountAction.clientSecret, {
            payment_method: membershipDetails.accountAction.paymentMethodId
          })
          .then(result => {
            if (result.error) {
              console.log("Payment Auth failure...", result);
              setPaymentAuthStatus({
                dialogOpen: true,
                status: "auth-failure"
              });
              return;
            } else {
              if (result.paymentIntent.status === "succeeded") {
                console.log("Payment Auth success...");
                setPaymentAuthStatus({
                  dialogOpen: true,
                  status: "auth-success"
                });
                return;
              }
            }
          })
          .catch(error => {
            console.log(error);
            setPaymentAuthStatus({
              dialogOpen: true,
              status: "payment-failure"
            });
            return;
          });
      }
    };

    function UpgradeBanner() {
      /*
        Standard
        Monthly product only
        Doesn't matter about auto renew
        Limited time only
        Button to upgrade page
      */
      return (
        <>
          {overview && membershipDetails && (
            <>
              {membershipDetails.memberStatusId === MEMBER_STATUS_STANDARD &&
                membershipDetails.productId.includes("monthly") &&
                membershipDetails.hasStripeCustomer &&
                overview.doors.annualUpgradeOpen && (
                  <Grid
                    container
                    className={classes.upgradeMembershipContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={9}
                      className={classes.upgradeMembershipTextContainer}
                    >
                      <Typography
                        variant="body1"
                        className={classes.upgradeMembershipText}
                      >
                        <b>Save 30% -</b> Upgrade to an Annual Plan Now. Limited
                        Time Only. <br />
                        {overview.doors.annualUpgradeOpenUntil && (
                          <b style={{ fontSize: "1.2rem", marginTop: "6px" }}>
                            <Countdown
                              date={overview.doors.annualUpgradeOpenUntil}
                              renderer={RenderAnnualUpgradeCountdown}
                              zeroPadTime={1}
                            />
                          </b>
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={classes.upgradeMembershipButtonContainer}
                    >
                      <Link to="/myaccount/annual-upgrade">
                        <Button
                          size="small"
                          className={classes.upgradeMemberShipButton}
                        >
                          {userProfile.productAutoRenewing
                            ? "Upgrade"
                            : "Turn on Auto Renew"}
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                )}
            </>
          )}
        </>
      );
    }

    return (
      <>
        <UpgradeBanner />
        <Grid
          container
          className={`${classes.topMembershipContainer} ${
            membershipDetails.memberStatusId === "standard" &&
            !membershipDetails.accountAction
              ? classes.standardStatus
              : membershipDetails.memberStatusId === "standard" &&
                membershipDetails.accountAction // Payment Failed / Auth Required
              ? classes.errorStatus
              : membershipDetails.memberStatusId === "standard-expired" &&
                !membershipDetails.accountAction // Expired
              ? classes.expiredStatus
              : membershipDetails.memberStatusId === "standard-expired" &&
                membershipDetails.accountAction // Payment Failed / Auth Required
              ? classes.errorStatus
              : membershipDetails.memberStatusId === "standard-incomplete"
              ? classes.warningStatus
              : membershipDetails.memberStatusId === "trial"
              ? classes.standardStatus
              : membershipDetails.memberStatusId === "trial-expired"
              ? classes.warningStatus
              : membershipDetails.memberStatusId === "locked" &&
                overview &&
                !overview.doors.open
              ? classes.errorStatus
              : membershipDetails.memberStatusId === "locked" &&
                overview &&
                overview.doors.open
              ? classes.warningStatus
              : ""
          }`}
        >
          <Grid item xs={12} sm={8}>
            {/* Membershup Pretty Title */}
            {PrettyMembershipHeader(membershipDetails)}

            {/* Stripe Membership Current Plan  */}
            {membershipDetails.memberStatusId === "standard" && (
              <>
                {membershipDetails.hasStripeSubscription ? (
                  <>
                    {membershipDetails.subscription && (
                      <>
                        {!membershipDetails.subscription.cancelAtPeriodEnd ? (
                          <>
                            {membershipDetails.subscription.nextPayment && (
                              <Typography
                                variant="body1"
                                className={classes.darkBodyText}
                                style={{ marginBottom: "12px" }}
                              >
                                {membershipDetails.subscription.status ===
                                "past_due"
                                  ? "We'll try to collect payment again on: "
                                  : "Next Payment:"}
                                <span>
                                  {" "}
                                  {dayjs(
                                    membershipDetails.subscription.nextPayment
                                  ).format("Do MMMM YYYY")}
                                </span>
                              </Typography>
                            )}
                          </>
                        ) : (
                          <>
                            <Typography
                              variant="body1"
                              className={classes.darkBodyText}
                              style={{ marginTop: "12px" }}
                            >
                              Membership will expire on:{" "}
                              <span>
                                {dayjs(
                                  userProfile.subscriptionExpiryDate
                                ).format("Do MMMM YYYY")}
                              </span>
                            </Typography>
                          </>
                        )}
                      </>
                    )}
                    <Typography
                      variant="body1"
                      className={classes.darkBodyText}
                    >
                      Current Plan:{" "}
                      <span>
                        {PrettyProductType(membershipDetails.productId)}
                        {/* {!membershipDetails.subscription.collectionPaused && (
                          <>
                            {" / "}
                            <CurrencySymbol
                              currency={membershipDetails.currency}
                            />
                            {(
                              membershipDetails.subscription.cost / 100
                            ).toFixed(2)}{" "}
                          </>
                        )} */}
                      </span>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="body1"
                      className={classes.darkBodyText}
                    >
                      Current Plan:{" "}
                      <span>
                        {PrettyProductType(membershipDetails.productId)}
                      </span>
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.darkBodyText}
                      style={{ marginTop: "12px" }}
                    >
                      Membership will expire on:{" "}
                      <span>
                        {dayjs(userProfile.subscriptionExpiryDate).format(
                          "Do MMMM YYYY"
                        )}
                      </span>
                    </Typography>
                  </>
                )}
              </>
            )}

            {/* Handle Trial / Trial Expired Countdown(s) */}
            {membershipDetails.memberStatusId === "trial" && (
              <>
                <Countdown
                  date={Date.parse(userProfile.subscriptionExpiryDate)}
                  renderer={countdownRenderer}
                  endingText={
                    membershipDetails.memberStatusId === "trial"
                      ? "Your Free Trial ends in:"
                      : "We're giving you another chance to join FarmFLiX.  Don't miss out!"
                  }
                  endedText={
                    membershipDetails.memberStatusId === "trial"
                      ? "Your Free Trial has Ended."
                      : ""
                  }
                  zeroPadTime={2}
                  daysInHours={true}
                />
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            className={classes.buttonMembershipContainer}
          >
            {membershipDetails.memberStatusId === "standard" ||
            membershipDetails.memberStatusId === "standard-expired" ? (
              <>
                {membershipDetails.hasStripeCustomer ? (
                  <>
                    {membershipDetails.hasStripeSubscription ? (
                      <>
                        {membershipDetails.accountAction ? (
                          <>
                            {membershipDetails.accountAction.action ===
                              "authorize-payment" && (
                              <>
                                <Button
                                  onClick={attemptAuthentication}
                                  size="large"
                                  className={classes.buttonMembership}
                                >
                                  Complete Authentication
                                </Button>
                              </>
                            )}
                            {membershipDetails.accountAction.action ===
                              "add-payment-method" && (
                              <>
                                <Link
                                  to={
                                    membershipDetails.defaultPaymentMethod
                                      ? "/myaccount/payment-method/update"
                                      : "/myaccount/payment-method/add"
                                  }
                                  style={{ textDecoration: "none" }}
                                >
                                  <Button
                                    size="large"
                                    className={classes.buttonMembership}
                                  >
                                    {membershipDetails.defaultPaymentMethod
                                      ? "Update"
                                      : "Add"}{" "}
                                    Payment
                                  </Button>
                                </Link>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={handleAutoRenewSwitch}
                              className={classes.buttonMembership}
                            >
                              Turn{" "}
                              {membershipDetails.subscription.cancelAtPeriodEnd
                                ? "On"
                                : "Off"}{" "}
                              Auto Renew
                            </Button>
                          </>
                        )}
                      </>
                    ) : (
                      <Link
                        to="/myaccount/join-now"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          size="large"
                          className={classes.buttonMembership}
                        >
                          Join Now
                        </Button>
                      </Link>
                    )}
                  </>
                ) : (
                  <Button
                    onClick={handleAutoRenewSwitch}
                    className={classes.buttonMembership}
                  >
                    {membershipDetails.memberStatusId === "standard"
                      ? "Turn On Auto Renew"
                      : "Join Now"}
                  </Button>
                )}
              </>
            ) : membershipDetails.memberStatusId === "trial" ||
              membershipDetails.memberStatusId === "trial-expired" ? (
              <>
                {standardProduct && (
                  <Link
                    to="/myaccount/join-now"
                    style={{ textDecoration: "none" }}
                  >
                    <Button size="large" className={classes.buttonMembership}>
                      Join Now
                    </Button>
                  </Link>
                )}
              </>
            ) : membershipDetails.memberStatusId === "standard-incomplete" ? (
              <>
                {membershipDetails.hasStripeCustomer ? (
                  <>
                    {membershipDetails.hasStripeSubscription ? (
                      <>
                        {membershipDetails.accountAction ? (
                          <>
                            {membershipDetails.accountAction.action ===
                              "authorize-payment" && (
                              <>
                                <Button
                                  onClick={attemptAuthentication}
                                  size="large"
                                  className={classes.buttonMembership}
                                >
                                  Complete Authentication
                                </Button>
                              </>
                            )}
                            {membershipDetails.accountAction.action ===
                              "add-payment-method" && (
                              <>
                                <Link
                                  to="/myaccount/payment-method/update"
                                  style={{ textDecoration: "none" }}
                                >
                                  <Button
                                    size="large"
                                    className={classes.buttonMembership}
                                  >
                                    Update Payment
                                  </Button>
                                </Link>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={handleAutoRenewSwitch}
                              className={classes.buttonMembership}
                            >
                              Turn{" "}
                              {membershipDetails.subscription.cancelAtPeriodEnd
                                ? "On"
                                : "Off"}{" "}
                              Auto Renew
                            </Button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {standardProduct && (
                          <Link
                            to="/myaccount/join-now"
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              size="large"
                              className={classes.buttonMembership}
                            >
                              Join Now
                            </Button>
                          </Link>
                        )}
                        {trialProduct && (
                          <Button
                            onClick={takeTrial}
                            size="large"
                            variant="outlined"
                            className={classes.buttonMembership}
                          >
                            72H Free Trial
                          </Button>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      onClick={handleAutoRenewSwitch}
                      className={classes.buttonMembership}
                    >
                      {membershipDetails.memberStatusId === "standard"
                        ? "Turn On Auto Renew"
                        : "Join Now"}
                    </Button>
                    {trialProduct && (
                      <Button
                        onClick={takeTrial}
                        size="large"
                        variant="outlined"
                        className={classes.buttonMembership}
                      >
                        72H Free Trial
                      </Button>
                    )}
                  </>
                )}
              </>
            ) : membershipDetails.memberStatusId === "locked" ? (
              <>
                {overview && overview.doors.open ? (
                  <Link
                    to="/myaccount/join-now"
                    style={{ textDecoration: "none" }}
                  >
                    <Button size="large" className={classes.buttonMembership}>
                      Join Now
                    </Button>
                  </Link>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      {/* Turn Off Auto Renew */}
      {userProfile &&
        membershipDetails &&
        membershipDetails.subscription &&
        !membershipDetails.subscription.cancelAtPeriodEnd && (
          <>
            <Dialog
              fullScreen={fullScreen}
              onClose={handleAutoRenewOffCloseDialog}
              aria-labelledby="customized-dialog-title"
              open={turnOffAutoRenewOpen}
              className={classes.autoRenewDialog}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleAutoRenewOffCloseDialog}
                className={classes.autoRenewDialogTitle}
              >
                Turn Off Auto Renew
              </DialogTitle>
              <DialogContent dividers>
                <Typography variant="body1" className={classes.tableText}>
                  By turning off Auto Renew, you will:
                  <br />
                </Typography>
                <ul className={classes.bodyList}>
                  <li>
                    still have access to FarmFLiX for the remainder of your
                    billing period, to{" "}
                    <span>
                      {userProfile.subscriptionExpiryDate &&
                        new Intl.DateTimeFormat("en-GB", {
                          year: "numeric",
                          month: "long",
                          day: "2-digit"
                        }).format(new Date(userProfile.subscriptionExpiryDate))}
                    </span>
                    . After this period, you will no longer has access to
                    FarmFLiX content.
                  </li>
                  <li>
                    no longer be charged after your billing period has elapsed.
                  </li>
                </ul>
                <Typography variant="body1" className={classes.tableText}>
                  <span>Are you sure you wish to turn off Auto Renew?</span>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      onClick={handleTurnOffAutoRenew}
                      disabled={updating}
                      className={classes.dialogButton}
                    >
                      Yes, Turn Off
                    </Button>
                    <Button
                      onClick={handleAutoRenewOffCloseDialog}
                      className={classes.dialogButton}
                      variant="outlined"
                    >
                      No, Go Back
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </>
        )}

      {/* Turn On Auto Renew */}
      {userProfile &&
        membershipDetails &&
        membershipDetails.subscription &&
        membershipDetails.subscription.cancelAtPeriodEnd && (
          <>
            <Dialog
              fullScreen={fullScreen}
              onClose={handleAutoRenewOnCloseDialog}
              aria-labelledby="customized-dialog-title"
              open={turnOnAutoRenewOpen}
              className={classes.autoRenewDialog}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleAutoRenewOnCloseDialog}
                className={classes.autoRenewDialogTitle}
              >
                Turn On Auto Renew
              </DialogTitle>
              <DialogContent dividers>
                {membershipDetails.defaultPaymentMethod && (
                  <>
                    {membershipDetails.subscription.status !== "trialing" ? (
                      <>
                        <Typography
                          variant="body1"
                          className={classes.tableText}
                        >
                          By turning on Auto Renew, you&apos;ll be charged{" "}
                          <span>
                            <CurrencySymbol
                              currency={
                                membershipDetails.defaultPaymentMethod.currency
                              }
                            />
                            {(
                              membershipDetails.subscription.cost / 100
                            ).toFixed(2)}{" "}
                          </span>{" "}
                          automatically on&nbsp;
                          <span>
                            {dayjs(userProfile.subscriptionExpiryDate).format(
                              "Do MMMM YYYY"
                            )}
                          </span>
                          .
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body1" className={classes.tableText}>
                        By turning on Auto Renew, you will resume your{" "}
                        <span>
                          {PrettyProductType(membershipDetails.productId)}
                        </span>{" "}
                        on&nbsp;
                        <span>
                          {dayjs(userProfile.subscriptionExpiryDate).format(
                            "Do MMMM YYYY"
                          )}
                        </span>
                        .
                      </Typography>
                    )}
                  </>
                )}
                <br />
                <Typography variant="body1" className={classes.tableText}>
                  <span>Do you wish to enable Auto Renew?</span>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      onClick={handleTurnOnAutoRenew}
                      disabled={updating}
                      className={classes.dialogButton}
                    >
                      Yes, Turn On
                    </Button>
                    <Button
                      onClick={handleAutoRenewOnCloseDialog}
                      className={classes.dialogButton}
                      variant="outlined"
                    >
                      No, Go Back
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </>
        )}

      {/* Payment Failed Info Dialog */}
      <Dialog
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title"
        open={paymentFailedInfoOpen}
        className={classes.autoRenewDialog}
        onClose={handlePaymentFailedCloseDialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.autoRenewDialogTitle}
        >
          Why is my Membership On Hold?
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" className={classes.tableText}>
            Your FarmFLiX Membership is On Hold because payment could not be
            collected.
          </Typography>
          <br />
          <Typography variant="body1" className={classes.tableText}>
            This can happen for several reasons:
          </Typography>
          <ul>
            <li className={classes.tableText}>
              Your Payment Method may be lacking funds.
            </li>
            <li>Your Payment Method has expired or is no longer valid.</li>
            <li>
              Your Payment Method or Card Issuer did not approve the charge.
            </li>
          </ul>
          <Typography variant="body1" className={classes.tableText}>
            To maintain your FarmFLiX Membership,{" "}
            <Link
              className={classes.link}
              to="/myaccount/payment-method/update"
            >
              Update your Payment Method
            </Link>
            .
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <Button
                onClick={handlePaymentFailedCloseDialog}
                className={classes.dialogButtonSml}
                variant="outlined"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* I've already Updated my Payment Method Dialog */}
      <Dialog
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title"
        open={paymentAlreadyUpdatedInfoOpen}
        className={classes.autoRenewDialog}
        onClose={handlePaymentAlreadyUpdatedCloseDialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.autoRenewDialogTitle}
        >
          Membership Still On Hold?
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" className={classes.tableText}>
            Contact FarmFLiX Support Team at{" "}
            <a style={{ color: "#171717" }} href="mailto:info@farmflix.tv">
              info@farmflix.tv
            </a>{" "}
            for further details regarding your Membership Status.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <Button
                onClick={handlePaymentAlreadyUpdatedCloseDialog}
                className={classes.dialogButtonSml}
                variant="outlined"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Payment Authentication Modal */}
      <Dialog
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title"
        open={paymentAuthStatus.dialogOpen}
        className={classes.autoRenewDialog}
        onClose={handlePaymentAuthCloseDialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.autoRenewDialogTitle}
        >
          Payment Authentication
        </DialogTitle>
        <DialogContent dividers>
          {paymentAuthStatus.status === "auth-success" ? (
            <Typography variant="body1" className={classes.tableText}>
              Your payment has successfully been authenticated.
            </Typography>
          ) : paymentAuthStatus.status === "auth-failure" ? (
            <Typography variant="body1" className={classes.tableText}>
              We had problems authenticaing your payment. Please try again.
            </Typography>
          ) : paymentAuthStatus.status === "payment-failure" ? (
            <Typography variant="body1" className={classes.tableText}>
              We had problems processing your payment. Please try again.
            </Typography>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <Button
                onClick={handlePaymentAuthCloseDialog}
                className={classes.dialogButtonSml}
                variant="outlined"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Handle Loading */}
      {loading && (
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Box m={5} align="center">
              <CircularProgress variant="indeterminate"></CircularProgress>
            </Box>
          </Grid>
        </Grid>
      )}

      {!loading && userProfile && (
        <>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <>
                <Typography variant="h2" className={classes.myAccountSubTitle}>
                  Membership Details
                </Typography>
                {membershipDetails && (
                  <>
                    <MemberStatusBox />
                  </>
                )}
                <Divider className={classes.sectionDivider} />
                <Typography variant="h2" className={classes.myAccountSubTitle}>
                  Personal Details
                </Typography>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          className={classes.tableFirstChild}
                          align="left"
                        >
                          <Typography
                            variant="body1"
                            className={classes.tableText}
                          >
                            Name:{" "}
                            <span>
                              {userProfile.firstName} {userProfile.lastName}
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body1"
                            className={classes.tableTextLink}
                          >
                            <Link to="/myaccount/change-name">
                              <Hidden only="xs">Change Name</Hidden>
                              <Hidden smUp>
                                <CreateIcon />
                              </Hidden>
                            </Link>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.tableFirstChild}
                          align="left"
                        >
                          <Typography
                            variant="body1"
                            className={classes.tableText}
                          >
                            Email: <span>{userProfile.email}</span>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body1"
                            className={classes.tableTextLink}
                          >
                            <Link to="/myaccount/change-email">
                              <Hidden only="xs">Change Email Address</Hidden>
                              <Hidden smUp>
                                <CreateIcon />
                              </Hidden>
                            </Link>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.tableFirstChild}
                          align="left"
                        >
                          <Typography
                            variant="body1"
                            className={classes.tableText}
                          >
                            Password: <span>**********</span>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body1"
                            className={classes.tableTextLink}
                          >
                            <Link to="/myaccount/change-password">
                              <Hidden only="xs">Change Password</Hidden>
                              <Hidden smUp>
                                <CreateIcon />
                              </Hidden>
                            </Link>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.tableFirstChild}
                          align="left"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={classes.tableText}
                          >
                            Phone:{" "}
                            {userProfile.countryCode &&
                            userProfile.phoneNumber ? (
                              <span>
                                +{userProfile.countryCode}
                                {userProfile.phoneNumber}
                              </span>
                            ) : (
                              <span>Not provided</span>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={classes.tableTextLink}
                          >
                            {userProfile.countryCode &&
                            userProfile.phoneNumber ? (
                              <Link to="/myaccount/change-phone">
                                <Hidden only="xs">Change Phone</Hidden>
                                <Hidden smUp>
                                  <CreateIcon />
                                </Hidden>
                              </Link>
                            ) : (
                              <Link to="/myaccount/add-phone">
                                <Hidden only="xs">Add Phone</Hidden>
                                <Hidden smUp>
                                  <AddCircleIcon />
                                </Hidden>
                              </Link>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.tableFirstChild}
                          align="left"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={classes.tableText}
                          >
                            Marketing Preferences
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ borderBottom: "none" }}
                        >
                          <Typography
                            variant="body1"
                            className={classes.tableTextLink}
                          >
                            <Link to="/myaccount/overview/marketing-prefs">
                              <Hidden only="xs">
                                Change Marketing Preferences
                              </Hidden>
                              <Hidden smUp>
                                <CreateIcon />
                              </Hidden>
                            </Link>
                            <Route
                              path="/myaccount/overview/marketing-prefs"
                              component={MarketingPreferencesModal}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider className={classes.sectionDivider} />

                {/* ============================================================
                    =============== PAYMENT DETAILS + AUTO RENEW ===============
                    ============================================================
                */}
                {membershipDetails && membershipDetails.hasStripeCustomer && (
                  <>
                    {/* <Divider className={classes.sectionDivider} /> */}
                    <Typography
                      variant="h2"
                      className={classes.myAccountSubTitle}
                    >
                      Payment Details
                    </Typography>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell
                              className={classes.tableFirstChild}
                              align="left"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                {membershipDetails.defaultPaymentMethod ? (
                                  <>
                                    {membershipDetails.defaultPaymentMethod
                                      .cardBrand === "visa" && (
                                      <img
                                        alt={
                                          membershipDetails.defaultPaymentMethod
                                            .cardBrand
                                        }
                                        src={visa}
                                        className={
                                          classes.defaultPaymentMethodCardBrand
                                        }
                                      />
                                    )}
                                    {membershipDetails.defaultPaymentMethod
                                      .cardBrand === "mastercard" && (
                                      <img
                                        alt={
                                          membershipDetails.defaultPaymentMethod
                                            .cardBrand
                                        }
                                        src={mastercard}
                                        className={
                                          classes.defaultPaymentMethodCardBrand
                                        }
                                      />
                                    )}
                                    {membershipDetails.defaultPaymentMethod
                                      .cardBrand === "amex" && (
                                      <img
                                        alt={
                                          membershipDetails.defaultPaymentMethod
                                            .cardBrand
                                        }
                                        src={amex}
                                        className={
                                          classes.defaultPaymentMethodCardBrand
                                        }
                                      />
                                    )}
                                    {membershipDetails.defaultPaymentMethod
                                      .cardBrand === "discover" && (
                                      <img
                                        alt={
                                          membershipDetails.defaultPaymentMethod
                                            .cardBrand
                                        }
                                        src={discover}
                                        className={
                                          classes.defaultPaymentMethodCardBrand
                                        }
                                      />
                                    )}
                                    <Typography
                                      variant="body1"
                                      className={classes.tableText}
                                    >
                                      <span>
                                        **** **** ****{" "}
                                        {
                                          membershipDetails.defaultPaymentMethod
                                            .last4
                                        }
                                      </span>
                                      <br />
                                      <span className={classes.cardExpiry}>
                                        Exp:{" "}
                                        {
                                          membershipDetails.defaultPaymentMethod
                                            .exp.month
                                        }{" "}
                                        /{" "}
                                        {
                                          membershipDetails.defaultPaymentMethod
                                            .exp.year
                                        }
                                      </span>
                                    </Typography>
                                  </>
                                ) : (
                                  <Typography
                                    variant="body1"
                                    className={classes.tableText}
                                  >
                                    <span>No Payment Method</span>
                                  </Typography>
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                variant="body1"
                                className={classes.tableTextLink}
                              >
                                {membershipDetails.defaultPaymentMethod ? (
                                  <>
                                    <Link to="/myaccount/payment-method/update">
                                      <Hidden only="xs">Update</Hidden>
                                      <Hidden smUp>
                                        <CreateIcon />
                                      </Hidden>
                                    </Link>
                                    <Hidden only="xs">
                                      <span style={{ color: "#171717" }}>
                                        &nbsp; / &nbsp;
                                      </span>
                                    </Hidden>
                                    <Link to="/myaccount/payment-method/remove">
                                      <Hidden only="xs">Remove</Hidden>
                                      <Hidden smUp>
                                        <RemoveCircleIcon
                                          style={{
                                            marginLeft: "15px",
                                            color: "#c53737"
                                          }}
                                        />
                                      </Hidden>
                                    </Link>
                                  </>
                                ) : (
                                  <Link to="/myaccount/payment-method/add">
                                    <Hidden only="xs">
                                      Add Payment Method
                                    </Hidden>
                                    <Hidden smUp>
                                      <AddCircleIcon />
                                    </Hidden>
                                  </Link>
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            {membershipDetails.subscription &&
                              !membershipDetails.subscription
                                .cancelAtPeriodEnd && (
                                <>
                                  <TableCell align="left">
                                    {membershipDetails.subscription
                                      .nextPayment && (
                                      <Typography
                                        variant="body1"
                                        className={classes.tableText}
                                      >
                                        Next Payment:{" "}
                                        {/* <span> - SMALL BUG HERE - cost showing '0' when subscription in trialling
                                          <CurrencySymbol
                                            currency={
                                              membershipDetails.stripeCustomerCurrency
                                            }
                                          />
                                          {membershipDetails.subscription.cost}
                                        </span>{" "} */}
                                        <span>
                                          {dayjs(
                                            membershipDetails.subscription
                                              .nextPayment
                                          ).format("Do MMMM YYYY")}
                                        </span>
                                      </Typography>
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    <Typography
                                      variant="body1"
                                      className={classes.tableTextLink}
                                    >
                                      <Link to="/myaccount/payment-history">
                                        Payment History
                                      </Link>
                                    </Typography>
                                  </TableCell>
                                </>
                              )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
                {membershipDetails && (
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            className={classes.tableFirstChild}
                            align="left"
                          >
                            {(membershipDetails.memberStatusId === "standard" ||
                              membershipDetails.memberStatusId ===
                                "standard-expired") && (
                              <>
                                {membershipDetails.hasStripeCustomer ? (
                                  <>
                                    {membershipDetails.hasStripeSubscription ? (
                                      <Typography
                                        variant="body1"
                                        className={classes.tableText}
                                      >
                                        Auto Renew is currently{" "}
                                        <b>
                                          {!membershipDetails.subscription
                                            .cancelAtPeriodEnd
                                            ? "On"
                                            : "Off"}
                                        </b>
                                        .
                                      </Typography>
                                    ) : (
                                      <Typography
                                        variant="body1"
                                        className={classes.tableText}
                                      >
                                        Auto Renew is not set up.
                                      </Typography>
                                    )}
                                  </>
                                ) : (
                                  <Typography
                                    variant="body1"
                                    className={classes.tableText}
                                  >
                                    Auto Renew is not set up.
                                  </Typography>
                                )}
                              </>
                            )}

                            {/* Trial User */}
                            {(membershipDetails.memberStatusId === "trial" ||
                              membershipDetails.memberStatusId ===
                                "trial-expired") && (
                              <Typography
                                variant="body1"
                                className={classes.tableText}
                              >
                                Join Now and set up Auto Renew.
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {(membershipDetails.memberStatusId === "standard" ||
                              membershipDetails.memberStatusId ===
                                "standard-expired") && (
                              <>
                                {membershipDetails.hasStripeCustomer &&
                                membershipDetails.hasStripeSubscription ? (
                                  <FormControlLabel
                                    style={{ marginRight: "0px" }}
                                    className={classes.autoRenewSwitch}
                                    control={
                                      <Switch
                                        checked={
                                          !membershipDetails.subscription
                                            .cancelAtPeriodEnd
                                        }
                                        onChange={handleAutoRenewSwitch}
                                        name="checkedB"
                                        color="primary"
                                      />
                                    }
                                  />
                                ) : (
                                  <Typography
                                    variant="body1"
                                    className={classes.tableTextLink}
                                  >
                                    <Link to="/myaccount/join-now">
                                      Set up Auto Renew
                                    </Link>
                                  </Typography>
                                )}
                              </>
                            )}

                            {/* Trial User */}
                            {(membershipDetails.memberStatusId === "trial" ||
                              membershipDetails.memberStatusId ===
                                "trial-expired") && (
                              <Typography
                                variant="body1"
                                className={classes.tableTextLink}
                              >
                                <Link to="/myaccount/join-now">
                                  Set up Auto Renew
                                </Link>
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <Divider className={classes.sectionDivider} />

                <Typography variant="body2" className={classes.darkBodyText}>
                  <span>Need Help with your FarmFLiX Account?</span>
                </Typography>
                <Typography variant="body2" className={classes.darkBodyText}>
                  Use the FarmFLiX Help Widget to get in touch or email us
                  directly at:{" "}
                  <a href="mailto:info@farmflix.tv">info@farmflix.tv</a>
                </Typography>
              </>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
