import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Hidden
} from "@material-ui/core";
import logo from "../Header/nav-logo.svg";
import { scroller } from "react-scroll";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { Carousel } from "react-responsive-carousel";
import { OverviewContext } from "../../store/OverviewContext";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "auto",
    display: "flex",
    boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.8)",
    height: "auto",
    // @ts-ignore
    paddingBottom: theme.spacing(0),
    position: "relative",
    "@media (min-width:600px)": {
      height: "auto"
    },
    "@media (min-width:769px)": {
      height: "auto"
    },
    "@media (min-width:960px)": {
      height: "100vh"
    }
  },
  centerBoxLeft: {
    position: "relative",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(10),
    "@media (min-width:600px)": {
      height: "auto",
      marginTop: theme.spacing(10)
    },
    "@media (min-width:769px)": {
      height: "auto"
    },
    "@media (min-width:960px)": {
      height: "100vh",
      alignItems: "flex-start",
      marginTop: theme.spacing(0)
    }
  },
  centerBoxRight: {
    position: "relative",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    "@media (min-width:600px)": {
      height: "auto",
      marginTop: theme.spacing(4)
    },
    "@media (min-width:769px)": {
      height: "auto"
    },
    "@media (min-width:960px)": {
      height: "100vh",
      alignItems: "flex-start",
      marginTop: theme.spacing(0)
    }
  },
  logo: {
    width: "40%",
    "@media (min-width:600px)": {
      width: "200px"
    },
    "@media (min-width:960px)": {}
  },
  title: {
    marginTop: theme.spacing(3),
    letterSpacing: "-2px",
    fontSize: "5.4rem",
    lineHeight: "4.4rem",
    fontWeight: "600",
    textShadow: "-2px 2px 2px #171717",
    // color: "#e52d2d",
    "& span": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    },
    "@media (min-width:600px)": {
      fontSize: "7.4rem",
      lineHeight: "6.6rem",
      "& span": {
        fontSize: "4rem",
        lineHeight: "4.2rem"
      }
    },
    "@media (min-width:960px)": {}
  },
  subTitle: {
    // color: "#e52d2d",
    letterSpacing: "-2px",
    fontSize: "3rem",
    lineHeight: "3.2rem",
    fontWeight: "600",
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "4rem",
      lineHeight: "4.2rem"
    },
    "@media (min-width:960px)": {}
  },
  lockText: {
    color: "#e52d2d",
    textAlign: "left",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    marginTop: theme.spacing(1),
    marginLeft: 0,
    fontWeight: "600",
    "@media (min-width:600px)": {
      // width: "300px"
    },
    "@media (min-width:960px)": {}
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "350px"
    },
    "@media (min-width:960px)": {}
  },
  buttonSubText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    marginLeft: 0,
    "@media (min-width:600px)": {
      width: "350px"
    },
    "& span": {
      fontWeight: "600",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer"
      }
    }
  },
  countdown: {
    color: theme.palette.background.ffNavy,
    fontSize: "3rem",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "3.2rem",
      lineHeight: "3.4rem",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    },
    "@media (min-width:960px)": {
      fontSize: "4rem",
      lineHeight: "4.2rem",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0)
    }
  },
  countdownContainer: {
    padding: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(0)
    }
  },
  countdownWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  },
  countdownLabelWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  countdownUnitWrapper: {
    textAlign: "center",
    // backgroundColor: "rgba(5,5,5,0.9)",
    backgroundColor: "rgba(82,82,82,0.9)",
    padding: "2% 0",
    fontSize: "2.4rem",
    fontWeight: "700",
    color: "#eeeeee",
    maxWidth: "23%",
    margin: "0 0.7%",
    borderRadius: 5,
    "@media (min-width:600px)": {
      padding: "1.8% 0",
      fontSize: "2rem",
      fontWeight: "700",
      maxWidth: "100px"
    },
    "@media (min-width:960px)": {
      padding: "2% 0",
      fontSize: "2.2rem",
      fontWeight: "700"
    }
  },
  countdownUnitFirstWrapper: {
    margin: 0
  },
  countdownUnitLabel: {
    fontSize: "0.9rem",
    fontWeight: 600,
    textAlign: "center",
    color: theme.palette.common.white,
    maxWidth: "23%",
    margin: "0 0.7%",
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      maxWidth: "100px"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem"
    }
  },
  noUnderline: {
    textDecoration: "none"
  },
  loginBut: {
    position: "absolute",
    top: "15px",
    right: "10px",
    border: "2px solid #fff",
    color: "#fff",
    transition: "none",
    "&:hover": {
      border: "2px solid #ddd",
      color: "#ddd",
      transition: "none"
    },
    "@media (min-width:600px)": {
      right: "15px"
    }
  },
  appImage: {
    minHeight: "500px",
    maxHeight: "700px",
    marginBottom: theme.spacing(5),
    "@media (min-width:960px)": {
      marginBottom: theme.spacing(0)
    }
  },
  skinWrapper: {
    position: "relative",
    marginBottom: "-3px"
  },
  dashboardScroller: {
    position: "absolute",
    top: "64px",
    left: "-1px",
    height: "378px",
    width: "258px",
    padding: "7%",
    overflow: "hidden",
    "@media (min-width:600px)": {
      width: "296px",
      top: "95px",
      left: "22px",
      padding: "0",
      height: "580px"
    },
    "@media (min-width:960px)": {}
  },
  dashboardScrollerHalf: {
    position: "absolute",
    top: "63px",
    left: "-1px",
    height: "578px",
    width: "258px",
    padding: "7%",
    overflow: "scroll",
    "@media (min-width:600px)": {
      width: "296px",
      top: "95px",
      left: "22px",
      padding: "0",
      height: "580px"
    },
    "@media (min-width:960px)": {}
  },
  imageContainer: {
    height: "365px",
    "@media (min-width:600px)": {
      height: "515px"
    }
  }
}));

const Top = () => {
  const classes = useStyles();
  const { overview } = useContext(OverviewContext);

  function scrollToGuaranteeSection() {
    scroller.scrollTo("guarantee-section", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }

  const RenderCountdown = ({ days, hours, minutes, seconds }) => {
    return (
      <>
        <Typography variant="body1" className={classes.lockText}>
          Membership Locks In:
        </Typography>
        <Container className={classes.countdownContainer}>
          <Grid container className={classes.countdownWrapper}>
            {days > 0 && (
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                className={classes.countdownUnitWrapper}
              >
                <span>
                  {days.toString().length === 1 ? "0" : ""}
                  {days}
                </span>
              </Grid>
            )}
            <Grid
              item
              xs={days > 0 ? 3 : 4}
              sm={days > 0 ? 3 : 4}
              md={days > 0 ? 3 : 4}
              className={classes.countdownUnitWrapper}
            >
              <span>
                {hours.toString().length === 1 ? "0" : ""}
                {hours}
              </span>
            </Grid>
            <Grid
              item
              xs={days > 0 ? 3 : 4}
              sm={days > 0 ? 3 : 4}
              md={days > 0 ? 3 : 4}
              className={classes.countdownUnitWrapper}
            >
              <span>
                {minutes.toString().length === 1 ? "0" : ""}
                {minutes}
              </span>
            </Grid>
            <Grid
              item
              xs={days > 0 ? 3 : 4}
              sm={days > 0 ? 3 : 4}
              md={days > 0 ? 3 : 4}
              className={classes.countdownUnitWrapper}
            >
              <span>
                {seconds.toString().length === 1 ? "0" : ""}
                {seconds}
              </span>
            </Grid>
          </Grid>
          <Grid container className={classes.countdownLabelWrapper}>
            {days > 0 && (
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                className={classes.countdownUnitLabel}
              >
                {days > 0 ? "Days" : "Day"}
              </Grid>
            )}
            <Grid
              item
              xs={days > 0 ? 3 : 4}
              sm={days > 0 ? 3 : 4}
              md={days > 0 ? 3 : 4}
              className={classes.countdownUnitLabel}
            >
              Hours
            </Grid>
            <Grid
              item
              xs={days > 0 ? 3 : 4}
              sm={days > 0 ? 3 : 4}
              md={days > 0 ? 3 : 4}
              className={classes.countdownUnitLabel}
            >
              Mins
            </Grid>
            <Grid
              item
              xs={days > 0 ? 3 : 4}
              sm={days > 0 ? 3 : 4}
              md={days > 0 ? 3 : 4}
              className={classes.countdownUnitLabel}
            >
              Secs
            </Grid>
          </Grid>
        </Container>
        <Link to="/buy" style={{ textDecoration: "none" }}>
          <Button className={classes.button}>Join Now</Button>
        </Link>
        <Typography variant="body2" className={classes.buttonSubText}>
          Includes FarmFLiX Guarantee.{" "}
          <span onClick={scrollToGuaranteeSection}>What&apos;s this?</span>
        </Typography>
      </>
    );
  };

  return (
    <>
      {overview && (
        <>
          <div className={classes.background + " banner-bg"}>
            {/* Login button */}
            <Link to="/login" style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                className={classes.loginBut}
                size="small"
              >
                Log In
              </Button>
            </Link>
            <Container maxWidth="lg" fixed>
              <Grid container>
                <Grid item xs={12} md={7} className={classes.centerBoxLeft}>
                  <Box>
                    <Box>
                      <img
                        src={logo}
                        className={classes.logo}
                        alt="FarmFLiX Logo"
                      />
                      <Typography className={classes.title}>
                        Last Chance
                      </Typography>
                      <Typography className={classes.subTitle}>
                        To Join
                      </Typography>
                      <Countdown
                        date={Date.parse(overview.doors.until)}
                        renderer={RenderCountdown}
                        zeroPadTime={2}
                        daysInHours={true}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5} className={classes.centerBoxRight}>
                  <div className={classes.skinWrapper}>
                    {/* Visible SM, MD, L, XL */}
                    <Hidden xsDown>
                      <img
                        src={
                          "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/device_skin_100.png"
                        }
                        alt="FarmFLiX App"
                        className={classes.appImage}
                      />
                      <div className={classes.dashboardScroller}>
                        <Carousel
                          useKeyboardArrows={true}
                          showArrows={false}
                          interval={3000}
                          showThumbs={false}
                          infiniteLoop={true}
                          showStatus={false}
                          autoPlay={true}
                          showIndicators={false}
                          emulateTouch={false}
                          swipeable={false}
                          transitionTime={500}
                        >
                          <Box className={classes.imageContainer}>
                            <img
                              src={
                                "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/1.png"
                              }
                              alt="App Dashboard"
                            />
                          </Box>
                          <Box className={classes.imageContainer}>
                            <img
                              src={
                                "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/2.png"
                              }
                              alt="App Currently Playing"
                            />
                          </Box>
                          <Box className={classes.imageContainer}>
                            <img
                              src={
                                "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/4.png"
                              }
                              alt="Clydes Farm Series"
                            />
                          </Box>
                        </Carousel>
                      </div>
                    </Hidden>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default Top;
