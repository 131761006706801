import React, { useState, useContext } from "react";
import { AccountContext } from "../../store/AccountContext";
import {
  Button,
  Typography,
  Grid,
  Box,
  Radio,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import usePromise from "react-use-promise";
import { isBrowser } from "react-device-detect";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import amex from "payment-icons/min/flat/amex.svg";
import discover from "payment-icons/min/flat/discover.svg";
import useLogger from "../../hooks/useLogger";

const useStyles = makeStyles(theme => ({
  paymentContainer: {
    marginTop: theme.spacing(2),
    boxShadow: "none",
    textAlign: "left"
  },
  paymentContainerHeader: {
    color: theme.palette.background.ffNavy,
    fontWeight: "500",
    fontSize: "1.4rem",
    lineHeight: "1.4rem"
  },
  paymentButton: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  cardElementLabel: {
    color: "#656565",
    fontSize: "1rem",
    fontWeight: 500,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  },
  paymentInformationTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    marginBottom: "0px",
    fontWeight: 500,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    "@media (min-width:600px)": {
      marginBottom: "20px"
    }
  },
  cardElementContainer: {
    border: "1px solid #dadada",
    borderRadius: "5px",
    padding: "12px 10px",
    backgroundColor: "##fbfbfb"
  },
  cardInputError: {
    marginBottom: theme.spacing(2)
  },
  termsLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "#656565",
    fontSize: "0.8rem",
    lineHeight: "1rem",
    textAlign: "left",
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: "0.8rem",
      lineHeight: "1rem"
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: "0.8rem",
      lineHeight: "1rem"
    },
    "& a": {
      color: "#656565",
      fontWeight: 500
    }
  },
  responseText: {
    color: "black"
  },
  validationError: {
    margin: theme.spacing(2, 0)
  },
  cardLogo: {
    width: "35px",
    maxWidth: "35px",
    marginLeft: "2px"
  },
  cardPaymentContainer: {
    marginTop: theme.spacing(2)
  },
  inputLabel: {},
  // inputField: {
  //   padding: "14px",
  //   border: "1px solid #a5a5a5",
  //   margin: "4px 0 15px 0",
  //   fontSize: "16px",
  //   borderRadius: "5px"
  // },
  midPaymentCreds: {
    marginTop: "4px",
    "@media (min-width:600px)": {
      marginTop: 0
    }
  },
  bottomPaymentCreds: {
    marginBottom: 0
  },
  paymentInformation: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      marginBottom: 0
    }
  },
  defaultPaymentMethodContainer: {
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(1.2),
    marginBottom: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  defaultPaymentCardNumber: {
    textAlign: "left",
    color: theme.palette.background.ffNavy,
    fontWeight: 500
  },
  defaultPaymentMethodText: {
    textAlign: "left",
    color: theme.palette.background.ffNavy
  },
  defaultPaymentMethodExpDate: {
    color: theme.palette.background.ffNavy,
    fontWeight: 400,
    fontSize: "0.9rem"
  },
  defaultPaymentMethodCardBrand: {
    width: "50px",
    maxWidth: "50px",
    paddingRight: "9px",
    paddingTop: "6px"
  },
  defaultPaymentMethodChange: {
    color: theme.palette.background.ffGreen,
    fontWeight: 500,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  defaultPaymentMethodLabel: {
    color: theme.palette.background.ffNavy,
    textAlign: "left",
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    marginTop: "20px",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  },
  bodyText: {
    color: theme.palette.background.ffNavy,
    marginBottom: theme.spacing(2),
    "& span": {
      fontWeight: 500
    }
  },
  inputField: {
    padding: "12px",
    border: "1px solid #a5a5a5",
    backgroundColor: "#f7f7f7",
    margin: "4px 0 15px 0",
    fontSize: "16px",
    borderRadius: "5px"
  },
  inputFieldTight: {
    padding: "12px",
    border: "1px solid #a5a5a5",
    backgroundColor: "#f7f7f7",
    margin: "4px 0 8px 0",
    fontSize: "16px",
    borderRadius: "5px"
  },
  sectionDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "2% 0"
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "5% 0 2% 0"
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem"
  }
}));

const CardPaymentForm = ({ Subscription }) => {
  let renewalDate = new Date();

  if (Subscription.entitlementPeriodUnit === "Year") {
    renewalDate = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit"
    }).format(
      new Date(
        renewalDate.setFullYear(
          renewalDate.getFullYear() + Subscription.entitlementPeriodValue
        )
      )
    );
  } else if (Subscription.entitlementPeriodUnit === "Month") {
    renewalDate = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit"
    }).format(
      new Date(
        renewalDate.setMonth(
          renewalDate.getMonth() + Subscription.entitlementPeriodValue
        )
      )
    );
  } else if (Subscription.entitlementPeriodUnit === "Day") {
    renewalDate = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit"
    }).format(
      new Date(
        renewalDate.setMonth(
          renewalDate.getMonth() + Subscription.entitlementPeriodValue
        )
      )
    );
  }

  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const { logMetricEvent } = useLogger();

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        "::placeholder": {
          color: "#ccc"
        }
      },
      invalid: {
        color: "#b92020"
      }
    }
  };

  const CardForm = () => {
    return (
      <form
        className={classes.cardPaymentContainer}
        onSubmit={handleStripeSubmit}
      >
        <Divider className={classes.sectionDivider} />
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body1" className={classes.bodyText}>
            Please enter your card details below.
          </Typography>
          {paymentError && (
            <>
              {paymentErrorResponse !== "undefined" && (
                <Alert className={classes.validationError} severity="error">
                  {paymentErrorResponse}
                </Alert>
              )}
            </>
          )}
          {cardInputError && (
            <>
              {cardInputError !== "undefined" && (
                <Alert className={classes.validationError} severity="error">
                  {cardInputError}
                </Alert>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {/* Post cutover - split the CardElement down */}
          <label className={classes.cardElementLabel} htmlFor="cardNumber">
            Card Number
          </label>
          <CardNumberElement
            id="cardNumber"
            className={classes.inputField}
            onFocus={clearErrors}
            options={ELEMENT_OPTIONS}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} className={classes.midPaymentCreds}>
            <label className={classes.cardElementLabel} htmlFor="expiry">
              Card Expiration
            </label>
            <CardExpiryElement
              id="expiry"
              className={`${classes.inputField} ${classes.bottomPaymentCreds}`}
              onFocus={clearErrors}
              options={ELEMENT_OPTIONS}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <label className={classes.cardElementLabel} htmlFor="cvc">
              CVC
            </label>
            <CardCvcElement
              id="cvc"
              className={classes.inputField}
              onFocus={clearErrors}
              options={ELEMENT_OPTIONS}
            />
          </Grid>
        </Grid>
        <Typography variant="body1" className={classes.termsLabel}>
          By {isBrowser && <>clicking</>}
          {!isBrowser && <>tapping</>} on &apos;Start Membership&apos; button
          below, you agree to FarmFLiX&apos;s{" "}
          <Link to="/terms">Terms and Conditions</Link> and{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>. Your next membership
          payment will be taken on <b>{renewalDate}</b>. FarmFLiX will
          automatically track and charge your membership fee (
          <CurrencySymbol currency={Subscription.currency} />
          {Subscription.cost}/{Subscription.entitlementPeriodUnit}) until you
          cancel your membership.
        </Typography>
        <Grid item xs={12} align="center">
          <Button
            type="submit"
            className={classes.paymentButton}
            disabled={!stripe || !Subscription.id || submittingButton}
          >
            Start Membership
          </Button>
        </Grid>
      </form>
    );
  };

  const {
    createStandardSubscriptionStripe,
    paymentErrorResponse,
    paymentError,
    resetErrors,
    getMembershipDetails
  } = useContext(AccountContext);

  const [reloadTrigger, setReloadTrigger] = useState(Date.now());
  const [membershipDetails, , state] = usePromise(getMembershipDetails, [
    reloadTrigger
  ]);

  const loading = state === "pending";

  // eslint-disable-next-line
  const reload = () => setReloadTrigger(Date.now());

  const [cardInputError, setCardInputError] = useState(null);
  const [submittingButton, setSubmittingButton] = useState(false);

  const [selectedValue, setSelectedValue] = useState("default");

  const handleChange = event => {
    clearErrors();
    setSelectedValue(event.target.value);
  };

  const clearErrors = event => {
    setSubmittingButton(false);
    setCardInputError("");
    resetErrors();
  };

  function CurrencySymbol({ currency }) {
    switch (currency) {
      case "GBP":
        return "\u00A3";
      case "EUR":
        return "\u20AC";
      default:
        return "\u00A3";
    }
  }

  const onPaymentSuccess = product => payment => {
    logMetricEvent({
      type: "purchase",
      payload: {
        userId: payment.memberId,
        paymentId: payment.id,
        id: product.id || "",
        memberStatusId: product.memberStatusId || "",
        name: product.name || "",
        cost: parseFloat(product.cost || "0"),
        currency: product.currency || "",
        stripeReference: product.stripeReference || ""
      }
    });
  };

  // Stripe Membership Submission
  const startNewMembership = async () => {
    const productId = Subscription.id;

    // Passing "default" in to use the current payment method on the Stripe Account
    const stripePaymentToken = "default";

    await createStandardSubscriptionStripe(
      {
        productId,
        stripePaymentToken
      },
      onPaymentSuccess(Subscription)
    );
  };

  // Stripe (Card) Submission
  const handleStripeSubmit = async event => {
    // Block double click/tap on payment button
    setSubmittingButton(true);

    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    const token = await stripe.createToken(cardElement);

    if (payload.error) {
      setCardInputError(payload.error.message);
      setSubmittingButton(false);
      return;
    } else {
      setCardInputError(null);
      const productId = Subscription.id;
      const stripePaymentToken = token.token.id;

      // eslint-disable-next-line
      const paymentAttempt = await createStandardSubscriptionStripe({
          productId,
          stripePaymentToken
        },
        onPaymentSuccess(Subscription)
      );
    }
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} className={classes.paymentContainer}>
        <div className={classes.cardContainer}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.paymentInformation}
            >
              <Box display="flex">
                <Box style={{ flex: 1 }}>
                  <Typography
                    className={classes.paymentInformationTitle}
                    variant="body1"
                  >
                    Payment Information
                  </Typography>
                </Box>
                <Box style={{ textAlign: "right" }}>
                  <img className={classes.cardLogo} src={visa} alt="Visa" />
                  <img
                    className={classes.cardLogo}
                    src={mastercard}
                    alt="Mastercard"
                  />
                  <img
                    className={classes.cardLogo}
                    src={amex}
                    alt="American Express"
                  />
                  <img
                    className={classes.cardLogo}
                    src={discover}
                    alt="Discover"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {!loading && (
                <>
                  {membershipDetails && (
                    <>
                      {membershipDetails.cardBrand &&
                        membershipDetails.creditCard && (
                          <>
                            <Typography
                              variant="body1"
                              className={classes.bodyText}
                            >
                              Pay securely with your card or enter a new card.
                            </Typography>
                            {paymentError && (
                              <>
                                {paymentErrorResponse !== "undefined" && (
                                  <Alert
                                    className={classes.validationError}
                                    severity="error"
                                    variant="filled"
                                  >
                                    {paymentErrorResponse}
                                  </Alert>
                                )}
                              </>
                            )}

                            <Box
                              display="flex"
                              alignItems="center"
                              className={classes.defaultPaymentMethodContainer}
                            >
                              <Box>
                                <Radio
                                  color="primary"
                                  checked={selectedValue === "default"}
                                  onChange={handleChange}
                                  value="default"
                                  name="radio-button-demo"
                                  inputProps={{ "aria-label": "Default" }}
                                />
                              </Box>
                              <Box flex="1" alignItems="center">
                                <Typography
                                  variant="body1"
                                  className={classes.defaultPaymentCardNumber}
                                >
                                  **** **** **** {membershipDetails.creditCard}
                                  <br />
                                  <span
                                    className={
                                      classes.defaultPaymentMethodExpDate
                                    }
                                  >
                                    Exp: {membershipDetails.cardExpMonth} /{" "}
                                    {membershipDetails.cardExpYear}
                                  </span>
                                </Typography>
                              </Box>
                              <Box alignItems="center">
                                <Typography
                                  variant="body1"
                                  className={classes.defaultPaymentMethodChange}
                                >
                                  {membershipDetails.cardBrand === "visa" && (
                                    <img
                                      alt="Visa"
                                      src={visa}
                                      className={
                                        classes.defaultPaymentMethodCardBrand
                                      }
                                    />
                                  )}
                                  {membershipDetails.cardBrand ===
                                    "mastercard" && (
                                    <img
                                      alt="Mastercard"
                                      src={mastercard}
                                      className={
                                        classes.defaultPaymentMethodCardBrand
                                      }
                                    />
                                  )}
                                  {membershipDetails.cardBrand === "amex" && (
                                    <img
                                      alt="American Express"
                                      src={amex}
                                      className={
                                        classes.defaultPaymentMethodCardBrand
                                      }
                                    />
                                  )}
                                  {membershipDetails.cardBrand ===
                                    "discover" && (
                                    <img
                                      alt="Discover"
                                      src={discover}
                                      className={
                                        classes.defaultPaymentMethodCardBrand
                                      }
                                    />
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              className={classes.defaultPaymentMethodContainer}
                            >
                              <Box alignItems="center">
                                <Radio
                                  color="primary"
                                  checked={selectedValue === "new"}
                                  onChange={handleChange}
                                  value="new"
                                  name="radio-button-demo"
                                  inputProps={{ "aria-label": "New" }}
                                />
                              </Box>
                              <Box alignItems="center">
                                <Typography
                                  variant="body1"
                                  className={classes.defaultPaymentMethodText}
                                  style={{ padding: "10px 0" }}
                                >
                                  Use a new card
                                </Typography>
                              </Box>
                            </Box>
                            {selectedValue === "default" && (
                              <>
                                <Typography
                                  variant="body1"
                                  className={classes.termsLabel}
                                >
                                  By {isBrowser && <>clicking</>}
                                  {!isBrowser && <>tapping</>} on &apos;Start
                                  Membership&apos; button below, you agree to
                                  FarmFLiX&apos;s{" "}
                                  <Link to="/terms">Terms and Conditions</Link>{" "}
                                  and{" "}
                                  <Link to="/privacy-policy">
                                    Privacy Policy
                                  </Link>
                                  . Your next membership payment will be taken
                                  on <b>{renewalDate}</b>. FarmFLiX will
                                  automatically track and charge your membership
                                  fee (
                                  <CurrencySymbol
                                    currency={Subscription.currency}
                                  />
                                  {Subscription.cost}/
                                  {Subscription.entitlementPeriodUnit}) until
                                  you cancel your membership.
                                </Typography>
                                <Grid item xs={12} align="center">
                                  <Button
                                    onClick={startNewMembership}
                                    className={classes.paymentButton}
                                    disabled={
                                      !stripe ||
                                      !Subscription.id ||
                                      submittingButton
                                    }
                                  >
                                    Start Membership
                                  </Button>
                                </Grid>
                              </>
                            )}
                            {selectedValue === "new" && (
                              <>
                                <form
                                  className={classes.cardPaymentContainer}
                                  onSubmit={handleStripeSubmit}
                                >
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                      variant="body1"
                                      className={classes.bodyText}
                                    >
                                      Please enter your card details below.
                                    </Typography>
                                    {cardInputError && (
                                      <>
                                        {cardInputError !== "undefined" && (
                                          <Alert
                                            className={classes.validationError}
                                            severity="error"
                                            variant="filled"
                                          >
                                            {cardInputError}
                                          </Alert>
                                        )}
                                      </>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <label
                                      className={classes.cardElementLabel}
                                      htmlFor="cardNumber"
                                    >
                                      Card Number
                                    </label>
                                    <CardNumberElement
                                      id="cardNumber"
                                      className={classes.inputField}
                                      onFocus={clearErrors}
                                      options={ELEMENT_OPTIONS}
                                    />
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      className={classes.midPaymentCreds}
                                    >
                                      <label
                                        className={classes.cardElementLabel}
                                        htmlFor="expiry"
                                      >
                                        Card Expiration
                                      </label>
                                      <CardExpiryElement
                                        id="expiry"
                                        className={`${classes.inputField} ${classes.bottomPaymentCreds}`}
                                        onFocus={clearErrors}
                                        options={ELEMENT_OPTIONS}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                      <label
                                        className={classes.cardElementLabel}
                                        htmlFor="cvc"
                                      >
                                        CVC
                                      </label>
                                      <CardCvcElement
                                        id="cvc"
                                        className={classes.inputField}
                                        onFocus={clearErrors}
                                        options={ELEMENT_OPTIONS}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Typography
                                    variant="body1"
                                    className={classes.termsLabel}
                                  >
                                    By {isBrowser && <>clicking</>}
                                    {!isBrowser && <>tapping</>} on &apos;Start
                                    Membership&apos; button below, you agree to
                                    FarmFLiX&apos;s{" "}
                                    <Link to="/terms">
                                      Terms and Conditions
                                    </Link>{" "}
                                    and{" "}
                                    <Link to="/privacy-policy">
                                      Privacy Policy
                                    </Link>
                                    . Your next membership payment will be taken
                                    on <b>{renewalDate}</b>. FarmFLiX will
                                    automatically track and charge your
                                    membership fee (
                                    <CurrencySymbol
                                      currency={Subscription.currency}
                                    />
                                    {Subscription.cost}/
                                    {Subscription.entitlementPeriodUnit}) until
                                    you cancel your membership.
                                  </Typography>
                                  <Grid item xs={12} align="center">
                                    <Button
                                      type="submit"
                                      className={classes.paymentButton}
                                      disabled={
                                        !stripe ||
                                        !Subscription.id ||
                                        submittingButton
                                      }
                                    >
                                      Start Membership
                                    </Button>
                                  </Grid>
                                </form>
                              </>
                            )}
                          </>
                        )}
                      {!membershipDetails.cardBrand &&
                        !membershipDetails.creditCard && (
                          <>
                            <form onSubmit={handleStripeSubmit}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                  variant="body1"
                                  className={classes.bodyText}
                                >
                                  Please enter your card details below.
                                </Typography>
                                {paymentError && (
                                  <>
                                    {paymentErrorResponse !== "undefined" && (
                                      <Alert
                                        className={classes.validationError}
                                        severity="error"
                                      >
                                        {paymentErrorResponse}
                                      </Alert>
                                    )}
                                  </>
                                )}
                                {cardInputError && (
                                  <>
                                    {cardInputError !== "undefined" && (
                                      <Alert
                                        className={classes.validationError}
                                        severity="error"
                                      >
                                        {cardInputError}
                                      </Alert>
                                    )}
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={12} md={12}>
                                {/* Post cutover - split the CardElement down */}
                                <label
                                  className={classes.cardElementLabel}
                                  htmlFor="cardNumber"
                                >
                                  Card Number
                                </label>
                                <CardNumberElement
                                  id="cardNumber"
                                  className={classes.inputField}
                                  onFocus={clearErrors}
                                  options={ELEMENT_OPTIONS}
                                />
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  className={classes.midPaymentCreds}
                                >
                                  <label
                                    className={classes.cardElementLabel}
                                    htmlFor="expiry"
                                  >
                                    Card Expiration
                                  </label>
                                  <CardExpiryElement
                                    id="expiry"
                                    className={`${classes.inputField} ${classes.bottomPaymentCreds}`}
                                    onFocus={clearErrors}
                                    options={ELEMENT_OPTIONS}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                  <label
                                    className={classes.cardElementLabel}
                                    htmlFor="cvc"
                                  >
                                    CVC
                                  </label>
                                  <CardCvcElement
                                    id="cvc"
                                    className={classes.inputField}
                                    onFocus={clearErrors}
                                    options={ELEMENT_OPTIONS}
                                  />
                                </Grid>
                              </Grid>
                              <Typography
                                variant="body1"
                                className={classes.termsLabel}
                              >
                                By clicking/tapping on &apos;Start
                                Membership&apos; button below, you agree to
                                FarmFLiX&apos;s{" "}
                                <Link to="/terms">Terms and Conditions</Link>{" "}
                                and{" "}
                                <Link to="/privacy-policy">Privacy Policy</Link>
                                . Your next membership payment will be taken on{" "}
                                <b>{renewalDate}</b>. FarmFLiX will
                                automatically track and charge your membership
                                fee (
                                <CurrencySymbol
                                  currency={Subscription.currency}
                                />
                                {Subscription.cost}/
                                {Subscription.entitlementPeriodUnit}) until you
                                cancel your membership.
                              </Typography>
                              <Grid item xs={12} align="center">
                                <Button
                                  type="submit"
                                  className={classes.paymentButton}
                                  disabled={
                                    !stripe ||
                                    !Subscription.id ||
                                    submittingButton
                                  }
                                >
                                  Start Membership
                                </Button>
                              </Grid>
                            </form>
                          </>
                        )}
                    </>
                  )}
                  {!membershipDetails && (
                    <>
                      <CardForm />
                    </>
                  )}
                </>
              )}
              {loading && (
                <>
                  <Box m={5} align="center">
                    <CircularProgress variant="indeterminate"></CircularProgress>
                  </Box>
                </>
              )}
              <Divider className={classes.footerDivider} />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default CardPaymentForm;
