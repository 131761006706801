import React, { useContext } from "react";
import { VideoSearchResult, VideoType } from "@booyaltd/core";
import { PlayerContext } from "../../store/PlayerContext";
import { OverviewContext } from "../../store/OverviewContext";
import dayjs from "dayjs";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import VideoRow from "../Video/VideoRow";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  },
  continueWatchingHeading: {
    fontWeight: 500,
    // @ts-ignore
    color: theme.palette.background.ffGreen
  }
}));

const ContinueWatching = () => {
  const classes = useStyles();
  const store = useContext(PlayerContext);
  const { overview } = useContext(OverviewContext);

  let video: VideoSearchResult | undefined = dayjs(
    overview?.continueWatching?.progressTime
  ).isAfter(dayjs().subtract(3, "day"))
    ? overview?.continueWatching
    : undefined;

  if (store.lastVideo) {
    video = store.lastVideo;
  }

  if (!video || video.type === VideoType.Other) {
    return null;
  }

  const progress = store.localVideoProgresses[video.id] || video.progress || 0;
  if (progress * 1000 > video.duration * 0.95) {
    return null;
  }

  return (
    <div className={classes.background}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              className={classes.continueWatchingHeading}
            >
              Continue Watching
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <VideoRow idx={0} video={video} listType={"continue-watching"} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContinueWatching;
