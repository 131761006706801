import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccountContext } from "../../store/AccountContext";

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(https://farmflix-images-test.s3-eu-west-1.amazonaws.com/freetrial_bg.jpg)`,
    boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.8)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "scroll",
    width: "100%",
    height: "75vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (min-width:600px)": {
      height: "500px"
    },
    "@media (min-width:960px)": {
      height: "550px"
    }
  },
  username: {
    color: theme.palette.common.white,
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    textAlign: "left",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      fontWeight: 600
    },
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  header: {
    color: theme.palette.common.white,
    fontSize: "3.2rem",
    lineHeight: "2.8rem",
    textAlign: "left",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(1),
    "& span": {
      fontWeight: 600
    },
    "@media (min-width:600px)": {
      fontSize: "3.6rem",
      lineHeight: "3.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "4.6rem",
      lineHeight: "4.4rem"
    }
  },
  subHeader: {
    color: "#bbb",
    fontSize: "1.3rem",
    lineHeight: "1.4rem",
    fontWeight: "500",
    textAlign: "left",
    width: "90%",
    textShadow: "-2px 2px 2px #171717",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.3rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.6rem"
    }
  },
  bodyText: {
    color: "#eee",
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "0.8rem",
      lineHeight: "1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "0.8rem",
      lineHeight: "1rem"
    }
  },
  joinNowButton: {
    fontSize: "1.2rem",
    textAlign: "left",
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer"
    },
    "@media (min-width:600px)": {
      textAlign: "center"
    }
  },
  bottomHeader: {
    color: theme.palette.common.white,
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    letterSpacing: "-1px",
    textAlign: "left",
    fontWeight: 500,
    marginTop: theme.spacing(1),
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      fontWeight: 600
    },
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.2rem",
      lineHeight: "2.2rem"
    }
  },
  bottomText: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    "& span": {
      color: "#c1c1c1",
      fontWeight: 500,
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer"
      }
    }
  },
  countdown: {
    color: theme.palette.background.ffNavy,
    fontSize: "3rem",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "3.2rem",
      lineHeight: "3.4rem",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    },
    "@media (min-width:960px)": {
      fontSize: "4rem",
      lineHeight: "4.2rem",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0)
    }
  },
  countdownContainer: {
    padding: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(0)
    }
  },
  countdownWrapper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1)
    }
  },
  countdownLabelWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  countdownUnitWrapper: {
    textAlign: "center",
    backgroundColor: "rgba(5,5,5,0.7)",
    padding: "2% 0",
    fontSize: "2rem",
    fontWeight: "600",
    color: "#eeeeee",
    maxWidth: "80px",
    margin: "0 0.7%",
    borderRadius: 5,
    "&:first-child": {
      margin: "0 0.7% 0 0"
    },
    "@media (min-width:600px)": {
      padding: "1.8% 0",
      fontSize: "2rem",
      fontWeight: "600"
    },
    "@media (min-width:960px)": {
      padding: "1% 0",
      fontSize: "2.2rem",
      fontWeight: "600"
    }
  },
  countdownUnitFirstWrapper: {
    margin: 0
  },
  countdownUnitLabel: {
    fontSize: "0.9rem",
    fontWeight: 600,
    textAlign: "center",
    color: theme.palette.common.white,
    maxWidth: "80px",
    margin: "0 0.7%",
    "&:first-child": {
      margin: "0 0.7% 0 0"
    },
    "@media (min-width:600px)": {
      fontSize: "0.9rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem"
    }
  }
}));

export default function TrialSubscriptionWindow() {
  const classes = useStyles();

  const { userProfile } = useContext(AccountContext);

  // const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     validateLogin();
  //     return null;
  //   } else {
  //     // Render countdown
  //     return (
  //       <Container className={classes.countdownContainer}>
  //         <Grid container className={classes.countdownWrapper}>
  //           {days >= 1 && (
  //             <Grid
  //               item
  //               xs={3}
  //               sm={3}
  //               md={3}
  //               className={classes.countdownUnitWrapper}
  //             >
  //               <span>
  //                 {days.toString().length === 1 ? "0" : ""}
  //                 {days}
  //               </span>
  //             </Grid>
  //           )}
  //           <Grid
  //             item
  //             xs={3}
  //             sm={3}
  //             md={3}
  //             className={classes.countdownUnitWrapper}
  //           >
  //             <span>
  //               {hours.toString().length === 1 ? "0" : ""}
  //               {hours}
  //             </span>
  //           </Grid>
  //           <Grid
  //             item
  //             xs={3}
  //             sm={3}
  //             md={3}
  //             className={classes.countdownUnitWrapper}
  //           >
  //             <span>
  //               {minutes.toString().length === 1 ? "0" : ""}
  //               {minutes}
  //             </span>
  //           </Grid>
  //           <Grid
  //             item
  //             xs={3}
  //             sm={3}
  //             md={3}
  //             className={classes.countdownUnitWrapper}
  //           >
  //             <span>
  //               {seconds.toString().length === 1 ? "0" : ""}
  //               {seconds}
  //             </span>
  //           </Grid>
  //         </Grid>
  //         <Grid container className={classes.countdownLabelWrapper}>
  //           {days >= 1 && (
  //             <Grid
  //               item
  //               xs={3}
  //               sm={3}
  //               md={3}
  //               className={classes.countdownUnitLabel}
  //             >
  //               Day{days > 1 ? "s" : ""}
  //             </Grid>
  //           )}
  //           <Grid
  //             item
  //             xs={3}
  //             sm={3}
  //             md={3}
  //             className={classes.countdownUnitLabel}
  //           >
  //             Hours
  //           </Grid>
  //           <Grid
  //             item
  //             xs={3}
  //             sm={3}
  //             md={3}
  //             className={classes.countdownUnitLabel}
  //           >
  //             Mins
  //           </Grid>
  //           <Grid
  //             item
  //             xs={3}
  //             sm={3}
  //             md={3}
  //             className={classes.countdownUnitLabel}
  //           >
  //             Secs
  //           </Grid>
  //         </Grid>
  //       </Container>
  //     );
  //   }
  // };

  return (
    <div>
      {userProfile && (
        <>
          <div className={classes.background}>
            <Container fixedcomponent="main" maxWidth="lg">
              <div className={classes.box}>
                <Grid container>
                  <Grid item xs={12} sm={10}>
                    <Typography
                      component="h1"
                      variant="h1"
                      className={classes.header}
                    >
                      Enjoyed Your Trial?
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h2"
                      className={classes.subHeader}
                    >
                      Want More? Join Now in Two Simple Steps.
                    </Typography>
                    {/* <Typography
                      component="h2"
                      variant="h2"
                      className={classes.subHeader}
                    >
                      {userProfile.firstName}, your free trial is now over and
                      this means it’s time to decide if you’re onboard or not!
                    </Typography> */}

                    {/* <Typography variant="body1" className={classes.bottomText}>
                      FarmFLiX has a Locked window for the months of February
                      through November. We&apos;re only Open for New members
                      once a year BUT because you&apos;ve only just discovered
                      us Your Luck is in... You&apos;ve got 24 hours to decide
                      if you want join us on this years journey to have access
                      to all the new weekly episodes before your account is
                      Locked until Black Friday!
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h2"
                      className={classes.subHeader}
                    >
                      <Hidden xsDown>
                        The clock is ticking... Don&apos;t Miss Out!
                      </Hidden> */}

                    {/* Visible XS only */}
                    {/* <Hidden smUp>
                        The clock is ticking...
                        <br />
                        Don&apos;t Miss Out!
                      </Hidden>
                    </Typography> */}
                    {/* <Countdown
                      date={Date.parse(userProfile.subscriptionExpiryDate)}
                      renderer={countdownRenderer}
                      zeroPadTime={2}
                      daysInHours={true}
                    /> */}
                    <Link
                      to="/myaccount/join-now"
                      style={{ textDecoration: "none" }}
                    >
                      <Button size="large" className={classes.joinNowButton}>
                        Join Now
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
        </>
      )}
    </div>
  );
}
