import React, { ChangeEvent, useCallback } from "react";
import CountryDropdown, { Country } from "./PhoneInput/CountryDropdown";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputAdornment,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(1),
    borderColor: theme.palette.primary.main,
    "& button": {
      borderColor: theme.palette.primary.main
    }
  },
  formField: {
    "& p": {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.common.white
    },
    "& input": {
      backgroundColor: "#444",
      color: "#fff",
      borderColor: "#444"
    }
  }
}));

type TwoStepPhoneInputProps = {
  onChange: (countryCode: string, phoneNumber: string) => void;
  disabled?: boolean;
};

const TwoStepPhoneInput = ({
  onChange: onChangeRaw,
  disabled
}: TwoStepPhoneInputProps) => {
  const classes = useStyles();
  const [phone, setPhone] = React.useState<string>("");
  const [country, setCountry] = React.useState<Country>();

  const onChangePhone = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const phone = e.target.value.replace(/[^0-9]/g, "");
      if (country) {
        setPhone(phone);
        onChangeRaw(country.countryCode, phone);
      } else {
        onChangeRaw("", "");
      }
    },
    [onChangeRaw, country]
  );

  const onChangeCountryDropdown = (country?: Country) => {
    setCountry(country);

    if (!country) {
      setPhone("");
      onChangeRaw("", "");
    }
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      {country ? (
        <>
          <Alert
            icon={false}
            variant="outlined"
            severity="success"
            className={classes.alert}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => onChangeCountryDropdown(undefined)}
              >
                Change
              </Button>
            }
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="row"
              alignItems="center"
            >
              <img
                style={{ marginRight: "10px" }}
                loading="lazy"
                width="30"
                src={`https://flagcdn.com/w40/${country.iso2}.png`}
                alt=""
              />
              <Typography variant="body2">{country.name}</Typography>
            </Box>
          </Alert>

          <FormControl className={classes.formField}>
            <Input
              id="mobileNumber"
              name="mobileNumber"
              required={true}
              autoFocus={true}
              autoComplete="off"
              placeholder="Mobile Number"
              aria-label="Mobile Number"
              disabled={disabled}
              onChange={onChangePhone}
              value={phone}
              startAdornment={
                <InputAdornment position="start">
                  <Typography variant="body2">
                    +{country.countryCode}
                  </Typography>
                </InputAdornment>
              }
            />
          </FormControl>
        </>
      ) : (
        <CountryDropdown onChange={onChangeCountryDropdown} />
      )}
    </Box>
  );
};

export default TwoStepPhoneInput;
