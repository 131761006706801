import React, { useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import HeaderInternal from "./Header/HeaderInternal";
import { AccountContext } from "../store/AccountContext";

import { Helmet } from "react-helmet";

// Styles
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4)
  },
  heading: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    marginBottom: "20px",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    }
  },
  subHeading: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    marginBottom: "20px",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    }
  },
  bodytext: {
    color: theme.palette.background.ffNavy,
    marginBottom: theme.spacing(2)
  }
}));

const PrivacyPolicy = props => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { loggedIn } = useContext(AccountContext);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Privacy Policy</title>
        <meta name="description" content="Please read our Privacy Policy." />
      </Helmet>
      <>
        {loggedIn && <HeaderInternal />}
        {!loggedIn && <Header />}
      </>
      <Container fixed>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Container fixedcomponent="main" maxWidth="md">
              <div className={classes.paper}>
                <Typography
                  component="h1"
                  variant="h1"
                  className={classes.heading}
                >
                  Privacy Policy
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  FarmFLiX is delivered by Booya Ltd. (&quot;FarmFliX,&quot;
                  &quot;us,&quot; &quot;we,&quot; or &quot;our&quot;) is
                  committed to protecting and respecting your privacy in
                  connection with your use of our website, www.farmflix.tv (the
                  &quot;Website(s)&quot;), applications (&quot;Apps&quot;) and
                  other products, services and features thereof (the Website,
                  the Apps and such other products, services and features are
                  collectively referred to herein as the &quot;Product&quot; or
                  &quot;Products&quot;, which may be updated from time-to-time
                  at the sole discretion of FarmFliX). This privacy policy
                  (&quot;Privacy Policy&quot;) and any other documents referred
                  to herein set forth the basis on which any personal data we
                  collect from you, or that you provide to us, in connection
                  with the Products will be processed by us. Please read the
                  following carefully to understand our practices regarding your
                  personal data and how we will collect, use and disclose your
                  personal data. If you have an unresolved privacy or data use
                  concern that we have not addressed satisfactorily, please
                  contact our third party dispute resolution provider (free of
                  charge) at http://go.adr.org/privacyshield.html.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>1. Data we may collect</b>
                  <br />
                  We may collect and process the following data about you:
                  Personal data including, for example, your name, e-mail
                  address, password, and in certain instances, telephone number,
                  data about your usage of the Products and data collected by
                  tracking technologies as further described in section 4 below
                  that may identify you as an individual or allow online contact
                  with you as an individual.
                  <br />
                  <br />
                  FarmFliX does not collect or process credit or debit card
                  (&quot;Payment Card&quot;) data. Apple and Google collect
                  Payment Card data with respect to in-app purchases made
                  through the Apps, and our payment processor collects Payment
                  Card data with respect to purchases made through the Website.
                  Such payment processors generally provide us with some limited
                  data related to you, such as a unique, anonymous token that
                  enables you to make additional purchases using the data
                  they’ve stored, and your__ card’s type, expiration date,
                  billing address, and the last four digits of your card number.
                  <br />
                  <br />
                  For individuals using the Products in connection with a
                  Community client account as described in section 6, business
                  data such as your company name, and company email address to
                  the extent that you or your Community provides such data.
                  <br />
                  <br />
                  Facebook profile information, such as name, email address, and
                  Facebook ID, if you choose to log in to the Products through
                  Facebook.
                  <br />
                  <br />
                  Device information such as operating system version, device
                  type, and system performance data.
                  <br />
                  <br />
                  Data collected via tracking technologies, as fully described
                  in section 4.
                  <br />
                  <br />
                  If you choose to invite a &quot;Buddy&quot; to use the
                  Products using our Buddy system, we will ask you for their
                  name and email address. We will use this data to invite him or
                  her to join the Products. We store this data for the sole
                  purpose of sending invitations and tracking the success of our
                  Buddy program. If your Buddy would like us to delete his or
                  her data, they can do so by opting-out as described in each
                  invitation or by contacting us at help@farmflix.tv.
                  <br />
                  <br />
                  Any failure to complete that information or any response
                  FarmFliX considers abnormal may result in FarmFliX refusing
                  (or being unable) to process your request. You agree that the
                  personal data you provide about yourself or subsidiary account
                  holders or &quot;Buddies&quot; to FarmFLiX will be current,
                  accurate, complete and unequivocal.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>2. Confidentiality and security</b>
                  <br />
                  The security of your personal data is important to us. We
                  follow generally accepted standards to protect the personal
                  data submitted to us, both during transmission and once it is
                  received. If you have any questions about the security of your
                  personal data, you can contact us at help@farmflix.tv. Except
                  as described under the &quot;Disclosure of Your Data&quot;
                  section below, we do not provide your personal data to any
                  third party without your specific consent, as defined by
                  applicable law.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>3. Newsletters or other electronic communications</b>
                  <br />
                  If you sign up to receive promotional materials from us via
                  email and/or (push notifications) we will use the data you
                  give us to provide the communications you have requested. If
                  you inform us that you wish to cancel email promotional
                  materials by selecting &quot;unsubscribe&quot; at the bottom
                  of such communication or by emailing us at help@farmflix.tv,
                  we will remove you from our mailing list. If you no longer
                  wish to receive push notifications, you may turn them off at
                  the device level.
                  <br />
                  <br />
                  If you provide your phone number to us directly or through a
                  third-party for the specific purpose of receiving an SMS
                  message with a link to our Apps, you will receive such SMS
                  message (the &quot;SMS Service&quot;). Standard text message
                  rates will apply.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>4. Tracking technologies</b>
                  <br />
                  FarmFLiX and our analytics partners use technologies such as
                  cookies, beacons, tags, and scripts to enable a service to
                  recognize your device so you don&apos;t have to provide the
                  same data several times during one task, to recognize that you
                  may have already given a username and password so you
                  don&apos;t need to do it for every web page requested, and to
                  measure how people are using the Products.
                  <br />
                  <br />
                  We use local storage, such as HTML5, to store content data and
                  preferences. Third parties with whom we partner to provide
                  certain features on the Products also use HTML5 to collect and
                  store data. Various browsers may offer their own management
                  tools for removing HTML5.
                  <br />
                  <br />
                  We partner with third parties, such as Facebook and Google, to
                  manage our advertising of the Products on other sites or
                  platforms as well as across your other devices based on your
                  past visits to our Website. Our third party partners may use
                  technologies such as cookies to gather data about your
                  activities within the Products to deliver such advertising to
                  you, such as retargeting ads. We are not always able to
                  respond to do-not-track signals. For more data about
                  interest-based ads, including how to opt-out of having your
                  web-browsing data used for behavioral advertising purposes,
                  please visit www.aboutads.info/choices. Please note that this
                  does not opt you out of being served ads. You may continue to
                  receive generic ads on these third party platforms. You may
                  also opt out of receiving ads across devices by adjusting your
                  ad preference in your Google account.
                  <br />
                  <br />
                  We use third party trackers to let us know when users have
                  visited the Products by &quot;clicking-through&quot; our
                  sponsored advertising or content hosted on third party
                  platforms. The Products use Google Analytics code to gather
                  statistical data. Google Analytics sets cookies to help us
                  accurately estimate the number of visitors to the Products and
                  the volumes of usage of the Products. This is done to ensure
                  that the Products are available when you want them and are
                  fast. For more data on how Google Analytics processes this
                  data, visit www.google.com/analytics.
                  <br />
                  <br />
                  We will acquire consent from you in order to use such trackers
                  to the extent required by applicable law.
                  <br />
                  <br />
                  For more information on the types of cookies used by FarmFliX
                  and your ability to restrict the behavior of cookies or
                  disable them in your browser’s settings, please refer to the
                  FarmFLiX Cookie Policy.
                  <br />
                  <br />
                  MOBILE ANALYTICS
                  <br />
                  We use mobile analytics software to allow us to better
                  understand the functionality of our mobile software on your
                  phone. This software may record data such as how often you
                  engage with the Products, the events that occur within the
                  Products, aggregated usage and performance data, and where the
                  Applications were downloaded from. We may link the data we
                  store within the analytics software to any personal data you
                  submit within the mobile application.
                  <br />
                  <br />
                  LOG FILES
                  <br />
                  As true of most websites, we gather certain data and
                  automatically and store it in log files. This data may include
                  Internet Protocol (IP) addresses, browser type, internet
                  service provider (ISP), referring/exit pages, operating
                  system, date/time stamp, and/or clickstream data.
                  <br />
                  <br />
                  If you receive the HTML-formatted version of our email
                  newsletter, your opening of the newsletter is notified to us
                  and saved. Your clicks on links in the newsletter are also
                  saved. These and the open statistics are used in aggregate
                  form to give us an indication of the popularity of the content
                  and to help us make decisions about future content and
                  formatting.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>5. Where we store your personal data</b>
                  <br />
                  All data you provide to us through the Products is stored on
                  our secure servers located in the U.K. Any payment
                  transactions will be encrypted using SSL technology; all
                  payment data is stored with our payment processor and is never
                  stored on FarmFliX servers. Where we have given you (or where
                  you have chosen) a password which enables you to access
                  certain parts of the Products, you are responsible for keeping
                  this password confidential. We ask you not to share a password
                  with anyone, and suggest that your change your password
                  frequently.
                  <br />
                  <br />
                  Unfortunately, the transmission of data via the Internet is
                  not completely secure. Although we will do our best to protect
                  your personal data, we cannot guarantee the security of your
                  data transmitted to the Products; any transmission is at your
                  own risk. Once we have received your data, we will use strict
                  procedures and security features to try to prevent
                  unauthorized access.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>6. Corporate and other community sharing</b>
                  <br />
                  There is interest by large farming communities (magazines,
                  manufacturers, retailers, farming government agencies, etc.)
                  (&quot;Communities&quot;) to introduce the Products to their
                  members. If you have registered to use the Products through a
                  code or other registration credential furnished by a Community
                  (a &quot;Community Subscription&quot;), the Community will
                  have access to your email address, the date you registered to
                  use the Products, and the date on which you last used the
                  Products. The Community will also have access to your
                  Community&apos;s aggregated and anonymized general usage data.
                  <br />
                  <br />
                  Your Community may also have access to your usage data on an
                  individual basis, but only if you have provided appropriate
                  consent under applicable law for such sharing. For example,
                  many Communities are offering incentive programs based on
                  members&apos; participation, and desire to better understand
                  how their members are viewing the content.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>7. Single sign-on</b>
                  <br />
                  You may be able to log-in to our Products using sign-in
                  services such as Facebook Connect or an Open ID provider.
                  These services will authenticate your identity, provide you
                  the option to share certain personal data (such as your name
                  and email address) with us, and to pre-populate our sign-up
                  form. Services like Facebook Connect give you to the option to
                  post data about your activities on our Products or to your
                  profile page to share with others within your network.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>8. Your data rights</b>
                  <br />
                  As you may know, a recent European Union law called the
                  General Data Protection Regulation (&quot;GDPR&quot;) give
                  certain rights to applicable individuals in relation to their
                  personal data. Accordingly, we have implemented transparency
                  and access controls to help such users, including residents of
                  the EU, Switzerland, and the United Kingdom exercise those
                  rights. As required under applicable law, the rights afforded
                  to you are:
                  <br />
                  <br />
                  A right of access: you have the right to obtain (i)
                  confirmation as to whether personal data concerning you are
                  processed or not and, if processed, to obtain (ii) access to
                  such data and a copy thereof. We will in future provide an
                  easy-to-view snapshot of such data via the &quot;My Data&quot;
                  tab in the Products, currently you can get this from
                  help@farmflix.tv
                  <br />
                  <br />
                  A right to rectification: you have the right to obtain the
                  rectification of any inaccurate personal data concerning you.
                  You also have the right to have incomplete personal data
                  completed, including by means of providing a supplementary
                  statement.
                  <br />
                  <br />
                  A right to erasure: in some cases, you have the right to
                  obtain the erasure of personal data concerning you. Upon
                  request, FarmFliX will permanently and irrevocably anonymize
                  your data such that it can never be reconstructed to identify
                  you as an individual. However, this is not an absolute right
                  and FarmFliX may have legal or legitimate grounds for keeping
                  such data.
                  <br />
                  <br />
                  A right to restriction of processing: in some cases, you have
                  the right to obtain restriction of the processing of your
                  personal data.
                  <br />
                  <br />
                  A right to data portability: you have the right to receive the
                  personal data concerning you which you have provided to
                  FarmFliX, in a structured, commonly used and machine-readable
                  format, and you have the right to transmit those data to
                  another controller without hindrance from FarmFliX. This right
                  only applies when the processing of your personal data is
                  based on your consent or on a contract and such processing is
                  carried out by automated means.
                  <br />
                  <br />
                  A right to object to processing: you have the right to object
                  at any time, on grounds relating to your particular situation,
                  to processing of personal data concerning you when such
                  processing is based on the legitimate interest of FarmFliX.
                  FarmFliX may, however, invoke compelling legitimate grounds
                  for continued processing. When your personal data is processed
                  for direct marketing purposes, you have the right to object at
                  any time to the processing of such data. You may, in
                  particular, exercise that right by clicking on the
                  &quot;unsubscribe&quot; link provided at the bottom of any
                  messages received, or by managing your privacy preferences by
                  logging in to your FarmFliX account.
                  <br />
                  <br />
                  A right to lodge a complaint with the competent supervisory
                  authority: you have the right to contact the supervisory
                  authority to complain about FarmFliX personal data protection
                  practices.
                  <br />
                  <br />
                  A right to give instructions concerning the use of your data
                  after your death: as required by applicable law, you may have
                  the right to give FarmFliX instructions concerning the use of
                  your personal data after your death. To exercise one or more
                  of these rights, you can email help@farmflix.tv
                  <br />
                  <br />
                  You may access your personal data to modify or update at any
                  time via an online account, or by emailing help@farmflix.tv
                  <br />
                  <br />
                  We will respond to your request in a reasonable timeframe in
                  accordance with applicable law.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>9. Uses made of the data</b>
                  <br />
                  We use information held about you in the following ways:
                  <ul>
                    <li>To provide you with the Products.</li>
                    <li>
                      To answer your questions or requests for information or
                      handle your complaints.
                    </li>
                    <li>
                      To ensure that content provided by the Products is
                      presented in the most effective manner for you and for
                      your computer or other device.
                    </li>
                    <li>
                      To provide you with promotional communications, such as
                      email, to the extent that you have provided consent to
                      receive such communications under applicable law.
                    </li>
                    <li>
                      To carry out our obligations arising from any agreements
                      entered into between you and us.
                    </li>
                    <li>
                      To allow you to participate in interactive features of the
                      Products, when you choose to do so.
                    </li>
                    <li>
                      To notify you about updates or changes to Product features
                      and content.
                    </li>
                    <li>
                      To understand your broad, non-specific geographic location
                      to help us identify groups of users by general geographic
                      market (such as zip code, state or country).
                    </li>
                    <li>
                      To account for applicable sales taxes based on zip codes
                      provided to our payment processors for purchases made
                      through the Website.
                    </li>
                    <li>
                      To inform your Community about your registration and use
                      of the Products as described under Corporate and Other
                      Community Sharing in section 6 above.
                    </li>
                    <li>
                      To serve our advertisements to you through third party
                      platforms, such as Facebook or Google, on other sites and
                      apps or across your devices, to the extent that you have
                      provided consent for such uses under applicable law.
                    </li>
                    <li>
                      To provide you with the SMS Service to the extent that you
                      have provided consent for such practices under applicable
                      law.
                    </li>
                    <li>
                      We may also ask you to complete surveys that we use for
                      research purposes, although you do not have to respond to
                      them.
                    </li>
                    <li>To manage your payments and orders.</li>
                    <li>
                      If you are an existing customer, we will only contact you
                      by electronic means (e-mail or in-Product communication)
                      with information about products and services similar to
                      those which were the subject of a previous sale to you,
                      except if you opt-out to such communications.
                    </li>
                  </ul>
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>10. Legal bases of personal data processing</b>
                  <br />
                  In accordance with GDPR, FarmFLiX provides the following
                  information regarding its Article 6 legal bases for personal
                  data processing:
                  <ul>
                    <li>
                      The performance of the contract (the FarmFliX Terms &amp;
                      Conditions) between you and FarmFliX for the data
                      processing relating to your use of FarmFliX Products
                      (including your orders and payments);
                    </li>
                    <li>
                      FarmFliX‘s legitimate interest, more specifically:
                      <ul>
                        <li>
                          Our business interest in providing you with emails and
                          push notifications for timely introductory materials
                          and information about your FarmFLiX account, our
                          Product features and updates, and offers to upgrade
                          your free account to a subscription account.
                        </li>
                        <li>
                          Our financial interest in providing you with
                          promotional offers and/or discounts with respect to
                          paid subscriptions and special offerings.
                        </li>
                        <li>
                          Our business interest in offering you particularized
                          or adapted content based on your usage of the
                          Products.
                        </li>
                        <li>
                          Our business interest in collecting data regarding
                          your general usage activities for the purpose of
                          improving our FarmFliX user experience.
                        </li>
                        <li>
                          Our business interest in requesting that you partake
                          in Product surveys in order to better understand your
                          needs and expectations.
                        </li>
                        <li>
                          Our financial interest in providing you the
                          opportunity to purchase FarmFliX subscriptions for the
                          benefit of a third party through the FarmFliX
                          &quot;gifting&quot; program.
                        </li>
                        <li>
                          Our business interest in providing you the opportunity
                          to invite a &quot;Buddy&quot; to FarmFliX and share
                          information about your FarmFliX activities with your
                          connected &quot;Buddies&quot;.
                        </li>
                        <li>
                          Our business interest in providing you with customer
                          service communications regarding your account,
                          questions about our content offerings or your
                          mindfulness practice, or any other matters directed to
                          customer service staff, in order to have clear and
                          easy communication with you and to respond to all your
                          requests.
                        </li>
                        <li>
                          Our business interest in collecting data related to
                          unplanned downtime or errors in the Products.
                        </li>
                        <li>
                          Our business interest in complying with our legal
                          obligations, such as maintaining accurate financial
                          records.
                        </li>
                        <li>
                          Our business interest in verifying your eligibility in
                          a Community and providing limited reporting to such
                          Community, as further described in section 6.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>11. Disclosure of your data</b>
                  <br />
                  We may disclose your personal data to any member of our group,
                  which means our subsidiaries, our ultimate holding company and
                  its subsidiaries.
                  <br />
                  <br />
                  We may also disclose your personal data to third parties as
                  follows:
                  <ul>
                    <li>
                      In some circumstances, based on your specific requests, we
                      may need to disclose your personal data to a third party
                      so that it can provide a service you have requested from
                      such party, or fulfill a request for data from such party.
                      An example of this is the SMS Service.
                    </li>
                    <li>
                      In some circumstances, we may disclose the personal data
                      that you have provided to FarmFliX to a third party that
                      offers and/or provides goods or services complementary to
                      our own for the purpose of enhancing our users’
                      experiences by offering you integrated or complementary
                      functionality, complementary services or bundled pricing
                      options.
                    </li>
                    <li>
                      If FarmFliX‘s service providers (like hosting, IT
                      maintenance, market analytics, and payment service
                      providers) require this data to provide services to
                      FarmFliX. FarmFliX requires each of its service providers
                      to agree to maintain the confidentiality and security of
                      your personal data.
                    </li>
                    <li>
                      In the event that we sell or buy any business or assets,
                      in which case we may disclose your personal data to the
                      prospective seller or buyer of such business or assets.
                    </li>
                    <li>
                      If FarmFliX or substantially all of our assets are
                      acquired by a third party, in which case personal data
                      held by us about our customers will be one of the
                      transferred assets.
                    </li>
                    <li>
                      If we are under a duty to disclose or share your personal
                      data in order to comply with any legal obligation such as
                      to comply with a subpoena, bankruptcy proceedings, similar
                      legal process, or in order to enforce or apply our
                      agreements with you; or to protect the rights, property,
                      or safety of FarmFliX, our customers, or others. This
                      includes exchanging data with other companies and
                      organizations for the purposes of fraud protection and
                      credit risk reduction.
                    </li>
                    <li>
                      With your Community, if your subscription is a Community
                      Subscription, as described under Corporate and Other
                      Community Sharing above.
                    </li>
                    <li>
                      With third parties, such as Facebook, in order to serve
                      FarmFliX advertisements on such third party platforms, to
                      the extent that you have consented to such practices under
                      applicable law.
                    </li>
                  </ul>
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>12. Data retention</b>
                  <br />
                  The retention periods applied by FarmFliX comply with
                  applicable legislation in effect on the date hereof, namely:
                  <ul>
                    <li>
                      For data relating to your account: such data will not be
                      retained beyond your request that your account be deleted.
                    </li>
                    <li>
                      For transactional data relating to your purchases: such
                      data is kept for the entire period of the contractual
                      relationship, then in accordance with legal obligations
                      and applicable statute of limitation periods. Please note
                      that this data does not include Payment Card information,
                      which is processed by our third-party payment processors,
                      and not FarmFliX.
                    </li>
                    <li>
                      For data collected based on your consent to receive our
                      marketing communications: we will use such data until you
                      withdraw consent or applicable law requires that such data
                      is no longer used.
                    </li>
                    <li>
                      When your data are collected in the context of
                      requests/queries: such data are kept for the period
                      necessary to process and reply to such requests or
                      queries.
                    </li>
                    <li>
                      When cookies or other trackers are placed on your
                      terminal, they are kept for a period of 12 months.
                    </li>
                    <li>
                      Other data will be kept as long as necessary for the
                      purposes pursued and in compliance with our legal
                      obligations, including the applicable statute of
                      limitations.
                    </li>
                  </ul>
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>13. Withdrawal of consent for processing activities</b>
                  <br />
                  To the extent that you have provided appropriate consent under
                  applicable law to certain processing activities, such consent
                  can be withdrawn at any time by emailing help@farmflix.tv
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>14. Links to third party sites</b>
                  <br />
                  The Products may, from time to time, contain links to and from
                  the Products of our partner networks, advertisers and
                  affiliates. If you follow a link to any of these external
                  websites, please note that these websites have their own
                  privacy policies and that we do not accept any responsibility
                  or liability for these websites or their policies. Please
                  check these policies before you submit any personal data to
                  these external websites.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>15. Social media widgets</b>
                  <br />
                  Our Products include social media features, such as the
                  Facebook Like button, and widgets, such as the &quot;Share
                  This&quot; button, or interactive mini-programs. These
                  features may collect your Internet protocol address, which
                  page you are visiting on or Products, and may set a cookie to
                  enable the feature to function properly. Social media features
                  and widgets are hosted by a third party or hosted directly on
                  our Products. Your interactions with these features are
                  governed by the privacy statement of the company providing it.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>16. Use of FarmFLiX by minors</b>
                  <br />
                  You must be 18 years of age, or the age of majority in your
                  province, territory or country, to sign up as a registered
                  user of the Products. Individuals under the age of 18, or the
                  applicable age of majority, may utilize the Products only with
                  the involvement and consent of a parent or legal guardian,
                  under such person&apos;s account.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>17. Data posted on forums</b>
                  <br />
                  FarmFliX users may have the ability to post content to one or
                  more FarmFliX forums. All such users may request and obtain
                  removal of such posted content by contacting FarmFliX at
                  help@farmflix.tv and specifically identifying the content to
                  be removed. Please be advised that any such removal does not
                  ensure complete or comprehensive removal of all traces of the
                  content posted on the FarmFliX forum(s).
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>18. Changes to our privacy policy</b>
                  <br />
                  We may update this Privacy Policy to reflect changes to our
                  data practices. If we make any material changes we will notify
                  you by in-Product message, email (sent to the e-mail address
                  specified in your account) or by means of a notice in the
                  Products prior to the change becoming effective. We encourage
                  you to periodically review this page for the latest data on
                  our privacy practices.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>19. Contact and EEA Registerd Agent</b>
                  <br />
                  Questions, comments and requests regarding this privacy policy
                  are welcomed and should be addressed to help@farmflix.tv.
                  <br />
                  <br />
                  FarmFliX’s physical address is 33 Ballynoe Road, Antrim, BT41
                  2QX
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>20. Transmission to other countries</b>
                  <br />
                  The subsidiaries, service providers or other third parties
                  listed above to whom FarmFliX may disclose your personal data
                  may be domiciled abroad, and in particular outside the
                  European Union, Switzerland, and the United Kingdom.
                  <br />
                  <br />
                  In such case, FarmFliX will require them to take, in
                  accordance with applicable legislation, all organizational and
                  technical measures that permit ensuring an adequate level of
                  protection of your personal data, such the use of Standard
                  Contractual Clauses approved by the European Commission and/or
                  ensuring certification with the EU-U.S. Privacy Shield and
                  Swiss-U.S. Privacy Shield Frameworks.
                  <br />
                  <br />
                  Moreover, we participate in and have certified our compliance
                  with the EU-U.S. Privacy Shield and Swiss-U.S. Privacy Shield
                  Frameworks (https://www.privacyshield.gov/). FarmFliX is
                  committed to subjecting all personal data received from
                  European Union (EU) member countries, Switzerland, and the
                  United Kingdom, in reliance on the Privacy Shield Framework,
                  to the Framework’s applicable Principles. To learn more about
                  the Privacy Shield Framework, visit
                  https://www.privacyshield.gov/list
                  <br />
                  <br />
                  FarmFliX is responsible for the processing of personal data it
                  receives, under the Privacy Shield Framework, and subsequent
                  transfers to a third party acting as an agent on its behalf.
                  FarmFliX complies with the Privacy Shield Principles for all
                  onward transfers of personal data from the EU, Switzerland,
                  and the United Kingdom, including the onward transfer
                  liability provisions.
                  <br />
                  <br />
                  For personal data covered by Privacy Shield, you have the
                  ability to opt-out of whether such personal data is disclosed
                  to a third-party (apart from service providers) or is to be
                  used for a purpose that is materially different for the
                  purpose for which it was originally collected or subsequently
                  authorized. You can contact FarmFliX at help@farmflix.tv in
                  order to make such choices.
                  <br />
                  <br />
                  Under certain conditions, more fully described on the Privacy
                  Shield website
                  https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint,
                  you may invoke binding arbitration when other dispute
                  resolution procedures have been exhausted.
                </Typography>
                <br />
                <br />
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>CCPA ADDENDUM</b>
                  <br />
                  Effective Date: January 1, 2020 Last Reviewed On: December 20,
                  2019
                  <br />
                  <br />
                  This California Consumer Privacy Act (&quot;CCPA&quot;)
                  Addendum for California residents supplements the information
                  contained in the above Privacy Policy and applies solely to
                  all visitors, users and others who reside in the State of
                  California. We adopt this CCPA Addendum to comply with the
                  CCPA, and any terms defined in the CCPA have the same meaning
                  when used in this Addendum.
                  <br />
                  <br />
                  <u>1. INFORMATION WE MAY COLLECT FROM YOU</u>
                  <br />
                  Our Products collect information that identifies, relates to,
                  describes, references, is capable of being associated with, or
                  could reasonably be linked, directly or indirectly, with a
                  particular consumer or device (&quot;personal
                  information&quot;). In particular, the Products have collected
                  the following categories of personal information from
                  consumers within the last twelve (12) months:
                  <br />
                  <br />
                  CATEGORY: Identifiers
                  <br />
                  EXAMPLES: First and last name, email address, Internet
                  Protocol address, online identifiers.
                  <br />
                  COLLECTED: YES
                  <br />
                  <br />
                  CATEGORY: Personal information categories listed in the
                  California Customer Records statute (Cal. Civ. Code §
                  1798.80(e)).
                  <br />
                  EXAMPLES: First and last name.
                  <br />
                  COLLECTED: YES
                  <br />
                  <br />
                  CATEGORY: Internet or other similar network activity.
                  <br />
                  EXAMPLES: Browsing history or information on a consumer’s
                  interaction with the Products or our advertisements on
                  third-party platforms.
                  <br />
                  COLLECTED: YES
                  <br />
                  <br />
                  The specific types of personal information we collect are
                  described more fully in section 1 of the Privacy Policy.
                  <br />
                  <br />
                  Personal information does not include: (1) deidentified or
                  aggregated consumer information; (2) publicly available
                  information from government records; or (3) information
                  excluded from the CCPA’s scope.
                  <br />
                  <br />
                  We obtain the categories of personal information listed above
                  from the following categories of sources:
                  <ul>
                    <li>
                      Directly from you. For example, from forms you complete
                      when registering for the Products.
                    </li>
                    <li>
                      Indirectly from you. For example, from observing your
                      actions on our Products.
                    </li>
                    <li>
                      Directly from your Community, if you have a Community
                      Subscription that is authenticated through the Community’s
                      use of eligibility files as described in section 6 of the
                      Privacy Policy.
                    </li>
                  </ul>
                  <br />
                  <br />
                  <u>2. USE OF PERSONAL INFORMATION</u>
                  <br />
                  We may use or disclose the personal information we collect for
                  the purposes described in section 9 of the Privacy Policy.
                  <br />
                  <br />
                  <u>3. SHARING PERSONAL INFORMATION</u>
                  <br />
                  We may disclose your personal information to third parties for
                  a business purpose. When we disclose personal information for
                  a business purpose, we enter a contract that describes the
                  purpose and requires the recipient to both keep that personal
                  information confidential and not to use it for any purpose
                  except performing the contract.
                  <br />
                  <br />
                  In the preceding twelve (12) months, we have disclosed the
                  following categories of personal information for a business
                  purpose:
                  <ul>
                    <li>Category 1: Identifiers</li>
                    <li>Category 2: Personal Information</li>
                    <li>Category 3: Internet activity</li>
                  </ul>
                  <br />
                  <br />
                  Specific examples of such sharing activities can be found in
                  sections 6 and 11 of the Privacy Policy.
                  <br />
                  <br />
                  <u>4. YOUR RIGHTS AND CHOICES</u>
                  <br />
                  Access to Specific Information and Data Portability Rights
                  <br />
                  <br />
                  The CCPA provides consumers (California residents) with
                  specific rights regarding their personal information. This
                  section describes your CCPA rights and explains how to
                  exercise those rights.
                  <br />
                  <br />
                  You have the right to request that we disclose certain
                  information to you about our collection and use of your
                  personal information over the past 12 months. Once we receive
                  and confirm your verifiable consumer request, we will disclose
                  to you:
                  <ul>
                    <li>
                      The categories of personal information we collected about
                      you.
                    </li>
                    <li>
                      The categories of sources for the personal information we
                      collected about you.
                    </li>
                    <li>
                      Our business or commercial purpose for collecting that
                      personal information.
                    </li>
                    <li>
                      The categories of third parties with whom we share that
                      personal information.
                    </li>
                    <li>
                      The categories of personal information we share with third
                      parties.
                    </li>
                    <li>
                      The specific pieces of personal information we collected
                      about you (also called a data portability request).
                    </li>
                  </ul>
                  <br />
                  <br />
                  Deletion Request Rights
                  <br />
                  You have the right to request that we delete any of your
                  personal information that we collected from you and retained,
                  subject to certain exceptions. Once we receive and confirm
                  your verifiable consumer request, we will permanently and
                  irrevocably anonymize your personal information, unless an
                  exception applies.
                  <br />
                  <br />
                  We may deny your deletion request if retaining the information
                  is necessary for us or our service provider(s) to:
                  <ul>
                    <li>
                      Complete the transaction for which we collected the
                      personal information, provide a good or service that you
                      requested, take actions reasonably anticipated within the
                      context of our ongoing business relationship with you, or
                      otherwise perform our contract with you.
                    </li>
                    <li>
                      Detect security incidents, protect against malicious,
                      deceptive, fraudulent, or illegal activity, or prosecute
                      those responsible for such activities.
                    </li>
                    <li>
                      Debug products to identify and repair errors that impair
                      existing intended functionality.
                    </li>
                    <li>
                      Exercise free speech, ensure the right of another consumer
                      to exercise their free speech rights, or exercise another
                      right provided for by law.
                    </li>
                    <li>
                      Comply with the California Electronic Communications
                      Privacy Act (Cal. Penal Code § 1546 et. seq.).
                    </li>
                    <li>
                      Enable solely internal uses that are reasonably aligned
                      with consumer expectations based on your relationship with
                      us.
                    </li>
                    <li>Comply with a legal obligation.</li>
                    <li>
                      Make other internal and lawful uses of that information
                      that are compatible with the context in which you provided
                      it.
                    </li>
                  </ul>
                  <br />
                  <br />
                  Exercising Access, Data Portability, and Deletion Rights
                  <br />
                  To exercise the access, data portability, and deletion rights
                  described above, please submit a verifiable consumer request
                  to us by emailing us at help@farmflix.com
                  <br />
                  <br />
                  Only you, or someone legally authorized to act on your behalf,
                  may make a verifiable consumer request related to your
                  personal information.
                  <br />
                  <br />
                  You may only make a verifiable consumer request for access or
                  data portability twice within a 12-month period. The
                  verifiable consumer request must:
                  <ul>
                    <li>
                      Provide sufficient information that allows us to
                      reasonably verify you are the person about whom we
                      collected personal information or an authorized
                      representative.
                    </li>
                    <li>
                      Describe your request with sufficient detail that allows
                      us to properly understand, evaluate, and respond to it.
                    </li>
                    <li>
                      We cannot respond to your request or provide you with
                      personal information if we cannot verify your identity or
                      authority to make the request and confirm the personal
                      information relates to you. We will only use personal
                      information provided in a verifiable consumer request to
                      verify the requestor’s identity or authority to make the
                      request.
                    </li>
                  </ul>
                  <br />
                  <br />
                  Response Timing and Format
                  <br />
                  Upon receiving a request for access, portability, or deletion,
                  we will confirm receipt of the request within 10 days and
                  provide information about our verification process and how we
                  will process the request.
                  <br />
                  <br />
                  We endeavor to respond to a verifiable consumer request within
                  forty-five (45) days of its receipt. If we require more time,
                  we will inform you of the reason and extension period in
                  writing.
                  <br />
                  <br />
                  We will deliver our written response to the email address
                  associated with the account for account holders, and to the
                  email address provided with the request submission for
                  non-account holders.
                  <br />
                  <br />
                  Any disclosures we provide will only cover the 12-month period
                  preceding the verifiable consumer request’s receipt. The
                  response we provide will also explain the reasons we cannot
                  comply with a request, if applicable. For data portability
                  requests, we will select a format to provide your personal
                  information that is readily readable and useable.
                  <br />
                  <br />
                  We do not charge a fee to process or respond to your
                  verifiable consumer request unless it is excessive,
                  repetitive, or manifestly unfounded. If we determine that the
                  request warrants a fee, we will tell you why we made that
                  decision and provide you with a cost estimate before
                  completing your request.
                  <br />
                  <br />
                  <u>5. COOKIE-BASED MARKETING OPT-OUT</u>
                  <br />
                  Like most companies, we partner with third parties, such as
                  Facebook and Google, to manage our marketing of FarmFLiX on
                  other platforms, where such advertising is based on your past
                  visits to our Products. These third party partners may use
                  technologies, such as cookies, to gather information about
                  your activities on the Products to deliver such advertising to
                  you when you visit their platforms. For instance, if you visit
                  www.farmflix.tv, a cookie may be attached to your browser in
                  the form of the Facebook Pixel that allows FarmFliX to deliver
                  advertising to you on the Facebook platform.
                  <br />
                  <br />
                  You can opt-out of the above-referenced cookie-based
                  retargeting by visiting the FarmFliX Cookie Policy and
                  clicking &quot;opt-out of marketing / analytics cookies&quot;
                  at the bottom of the page. Doing so will erase all current
                  marketing cookies and disable the placement of future
                  marketing cookies.
                  <br />
                  <br />
                  We do NOT use cookies so that third parties can serve ads to
                  you about their own products or services.
                  <br />
                  <br />
                  We do NOT sell or rent your personal information to any third
                  parties for their own advertising or marketing purposes.
                  <br />
                  <br />
                  <u>6. NON-DISCRIMINATION</u>
                  <br />
                  We will not discriminate against you for exercising any of
                  your CCPA rights. Unless permitted by the CCPA, we will not:
                  <ul>
                    <li>Deny you goods or services.</li>
                    <li>
                      Charge you different prices or rates for goods or
                      services, including through granting discounts or other
                      benefits, or imposing penalties.
                    </li>
                    <li>
                      Provide you a different level or quality of goods or
                      services.
                    </li>
                    <li>
                      Suggest that you may receive a different price or rate for
                      goods or services or a different level or quality of goods
                      or services.
                    </li>
                  </ul>
                  <br />
                  <br />
                  However, we may offer you certain financial incentives
                  permitted by the CCPA that can result in different prices,
                  rates, or quality levels. Any CCPA-permitted financial
                  incentive we offer will reasonably relate to your personal
                  information’s value and contain written terms that describe
                  the program’s material aspects. Participation in a financial
                  incentive program requires your prior opt in consent, which
                  you may revoke at any time.
                  <br />
                  <br />
                  <u>7. CHANGES TO OUR CCPA ADDENDUM</u>
                  <br />
                  We reserve the right to amend this CCPA Addendum at our
                  discretion and at any time. When we make changes to this CCPA
                  Addendum, we will post the updated notice on the Website and
                  update the effective date. Your continued use of our Products
                  following the posting of changes constitutes your acceptance
                  of such changes.
                  <br />
                  <br />
                  <u>8. CONTACT INFORMATION</u>
                  <br />
                  If you have any questions or comments about this CCPA
                  Addendum, the ways in which we collect and use your
                  information described above and in the Privacy Policy, your
                  choices and rights regarding such use, or wish to exercise
                  your rights under California law, please do not hesitate to
                  contact us at:
                  <ul>
                    <li>Email: help@farmflix.tv</li>
                    <li>
                      Postal Address: FarmFLiX Ltd, 33 Ballynoe Road, Antrim,
                      BT41 2QX
                    </li>
                  </ul>
                </Typography>
              </div>
              <Typography
                component="p"
                variant="body2"
                className={classes.bodytext}
              >
                <b>Last Updated</b>: 16 April, 2020
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
