import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import HeaderInternal from "./Header/HeaderInternal";
import { AccountContext } from "../store/AccountContext";

import { Helmet } from "react-helmet";

// Styles
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4)
  },
  heading: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    marginBottom: "20px",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    }
  },
  subHeading: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    marginBottom: "20px",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    }
  },
  bodytext: {
    color: theme.palette.background.ffNavy,
    marginBottom: "10px"
  }
}));

const Straight6 = props => {
  const classes = useStyles();

  const { loggedIn } = useContext(AccountContext);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Straight6</title>
        <meta
          name="description"
          content="Latest Straight6 Episodes, Merchandise and News from FarmFLiX."
        />
      </Helmet>
      <>
        {loggedIn && <HeaderInternal />}
        {!loggedIn && <Header />}
      </>
      <Container fixed>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Container fixedcomponent="main" maxWidth="md">
              <div className={classes.paper}>
                <Typography
                  component="h1"
                  variant="h1"
                  className={classes.heading}
                >
                  Straight6
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.bodytext}
                >
                  Coming soon.
                </Typography>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default Straight6;
