import DeviceDetails from "../api/requests/DeviceDetails";
import { UNIQUE_ID_STORAGE_KEY } from "../store/AccountContext";
const PLATFORM_WEB = "web";

// Snippet taken from: https://stackoverflow.com/a/2117523
const generateUuid = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getUniqueId = (): string => {
  let id = localStorage.getItem(UNIQUE_ID_STORAGE_KEY);
  if (!id) {
    id = generateUuid();
    localStorage.setItem(UNIQUE_ID_STORAGE_KEY, id);
  }

  return id;
};

// Snippet from here: https://stackoverflow.com/a/5918791
const getBrowserVersion = (): string => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null)
      return tem
        .slice(1)
        .join(" ")
        .replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
};

export const buildDeviceDetails = async (): Promise<DeviceDetails> => {
  return {
    id: getUniqueId(),
    platform: PLATFORM_WEB,
    platformVersion: getBrowserVersion(),
    ip: "123.123.123.123"
  } as DeviceDetails;
};
