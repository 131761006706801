import React, { useContext, useEffect } from "react";
import { OverviewContext } from "../store/OverviewContext";
import { AccountContext } from "../store/AccountContext";
import { PlayerContext } from "../store/PlayerContext";
import {
  Grid,
  Container,
  Typography,
  Button,
  Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HeaderInternal from "./Header/HeaderInternal";
import Footer from "./Footer/Footer";
import { useHistory } from "react-router-dom";
import { Player } from "./common/player";
import { getTitleAndChannelFromVideoName } from "../utils/strings";
import PriceRise from "./Banners/PriceRise";
import { Link } from "react-router-dom";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(4, 0)
  },
  topTitle: {
    fontWeight: 600,
    fontSize: "1.2rem",
    lineHeight: "1.4rem",
    marginTop: 0,
    textAlign: "center",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem",
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      letterSpacing: "-1px",
      fontSize: "1.6rem",
      lineHeight: "1.6rem"
    }
  },
  topSubTitle: {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.2rem",
    letterSpacing: "-1px",
    marginTop: 0,
    color: "#eee",
    textAlign: "center",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem",
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem"
    }
  },
  episodeTitle: {
    fontSize: "2.6rem",
    lineHeight: "2.8rem",
    letterSpacing: "-1px",
    fontWeight: 600,
    marginTop: 0,
    textAlign: "center",
    color: theme.palette.background.ffGreen,
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "4.2rem",
      lineHeight: "4.4rem",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      fontSize: "5rem",
      lineHeight: "5.4rem",
      letterSpacing: "-1px"
    }
  },
  episodeNumber: {
    display: "inline",
    // @ts-ignore
    marginRight: theme.spacing(2),
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    fontWeight: 500,
    color: "#bbb",
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.1rem"
    }
  },
  episodeMetaDetailsContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    }
  },
  episodeDetailsIcon: {
    display: "inline",
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginRight: "4px",
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem"
    }
  },
  episodeDetailsText: {
    display: "inline",
    // @ts-ignore
    color: "#888",
    marginRight: theme.spacing(2),
    fontSize: "0.8rem",
    lineHeight: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.2rem"
    }
  },
  episodeDescription: {
    // @ts-ignore
    color: theme.palette.common.darkgray,
    fontSize: "0.9rem",
    lineHeight: "1rem",
    borderBottom: "1px solid #292929",
    paddingBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      borderBottom: "none"
    }
  },
  membershipContainer: {
    backgroundColor: theme.palette.background.ffNavyLighter
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      fontSize: "4.2rem",
      lineHeight: "4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },
  open: {
    // @ts-ignore
    color: theme.palette.background.ffGreen
  },
  closed: {
    // @ts-ignore
    color: theme.palette.error.main
  },
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "300px"
    }
  },
  secondaryButton: {
    marginTop: theme.spacing(4),
    color: theme.palette.common.white,
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    border: "2px solid #fff",
    marginLeft: 0,
    marginRight: 0,
    "&:hover": {
      border: "2px solid #99ca3c",
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      width: "300px"
    }
  },
  textContainer: {
    margin: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      margin: theme.spacing(1, 0)
    },
    "@media (min-width:960px)": {
      margin: theme.spacing(3, 0)
    }
  },
  featureTitle: {
    letterSpacing: "-1px",
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    fontWeight: 500,
    textShadow: "-1px 1px 1px #171717",
    marginBottom: theme.spacing(0.5),
    color: "#fff",
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      letterSpacing: "-1px",
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
      fontWeight: 500,
      textShadow: "-2px 2px 2px #171717",
      marginBottom: theme.spacing(1)
    }
  },
  featureDesc: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.1rem",
    "& a": {
      color: "#e9e9e9",
      "&:hover": {
        // @ts-ignore
        color: theme.palette.background.ffGreen
      }
    },
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.3rem"
    },
    "@media (min-width:960px)": {}
  },
  buttonSubText: {
    fontSize: "0.9rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    "@media (min-width:600px)": {
      width: "auto",
      textAlign: "center",
      marginLeft: 0
    },
    "@media (min-width:960px)": {
      width: "300px"
    }
  },
  divider: {
    height: "5px",
    backgroundColor: "#292929",
    marginBottom: theme.spacing(4),
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(2)
    }
  },
  left: {
    marginBottom: "32px !important",
    paddingBottom: "32px !important",
    borderBottom: "5px solid #292929",
    borderRight: "0",
    "@media (min-width:960px)": {
      borderBottom: "0",
      borderRight: "5px solid #292929",
      marginBottom: theme.spacing(0)
    }
  },
  right: {
    padding: "8px !important",
    "@media (min-width:960px)": {
      padding: "8px 8px 8px 24px !important"
    }
  },
  containerAlignment: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    "@media (min-width:960px)": {
      textAlign: "left"
    }
  }
}));

export default function MyAccount() {
  const classes = useStyles();
  const history = useHistory();
  const { loading, loggedIn, userProfile } = useContext(AccountContext);
  const { overview } = useContext(OverviewContext);

  const { loadSingleVideo, currentVideo, clearCurrentVideo } = useContext(
    PlayerContext
  );

  if (!loading && !loggedIn) {
    history.push("/login");
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (overview?.freeDailyContentEpisode?.slug) {
      loadSingleVideo(overview?.freeDailyContentEpisode.slug);
    }
    console.log("CURRENT VIDEO", currentVideo);
    // eslint-disable-next-line
  }, [overview?.freeDailyContentEpisode]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    return () => {
      clearCurrentVideo();
    };
    // eslint-disable-next-line
  }, []);

  function VideoName(video) {
    const { title } = getTitleAndChannelFromVideoName(
      currentVideo && currentVideo.name
    );

    return (
      <>
        <Typography variant="h4" className={classes.episodeTitle}>
          {title}
        </Typography>
      </>
    );
  }

  return (
    <>
      <HeaderInternal />
      {overview &&
        userProfile &&
        overview.doors.annualUpgradeOpen &&
        userProfile.memberStatusId !== "standard" && (
          <PriceRise
            disableButton
            title="Beat the Price Rise"
            subTitle="Lock in the current price before they increase on 5th December 2022"
            showCountdown
          />
        )}
      <div className={classes.background}>
        {overview && userProfile && (
          <>
            <Container maxWidth="lg" fixed>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h2" className={classes.topTitle}>
                    Welcome back {userProfile.firstName},
                  </Typography>
                </Grid>
                {currentVideo && (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h2" className={classes.topSubTitle}>
                        Today&apos;s free episode is:
                      </Typography>
                      <VideoName video={currentVideo} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Player video={currentVideo} />
                    </Grid>
                  </>
                )}
              </Grid>
            </Container>
            <Container maxWidth="lg" fixed>
              <Divider className={classes.divider} />

              <Grid
                container
                spacing={2}
                className={classes.containerAlignment}
              >
                <Grid item xs={12} sm={12} md={6} className={classes.left}>
                  {overview && (
                    <>
                      {overview.doors?.open && (
                        <>
                          <Typography variant="h2" className={classes.title}>
                            Membership is now
                          </Typography>
                          <Typography
                            variant="h2"
                            className={classes.titleLarge}
                          >
                            <span className={classes.open}>Open</span>
                          </Typography>
                          <Link
                            to="/myaccount/join-now"
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              disableRipple
                              size="large"
                              className={classes.button}
                            >
                              Join Now
                            </Button>
                          </Link>
                          <Typography
                            variant="body1"
                            className={classes.buttonSubText}
                          >
                            <b>Don&apos;t Miss Out!</b>
                            <br />
                            Last chance to join this season!
                          </Typography>
                        </>
                      )}

                      {overview.doors?.open && !overview.doors?.trialsOpen && (
                        <>
                          <Typography variant="h2" className={classes.title}>
                            Membership is now
                          </Typography>
                          <Typography
                            variant="h2"
                            className={classes.titleLarge}
                          >
                            <span className={classes.open}>Open</span>
                          </Typography>
                          <Link to="/buy" style={{ textDecoration: "none" }}>
                            <Button
                              disableRipple
                              size="large"
                              className={classes.button}
                            >
                              Join Now
                            </Button>
                          </Link>
                          <Typography
                            variant="body1"
                            className={classes.buttonSubText}
                          >
                            <b>Don&apos;t Miss Out!</b>
                            <br />
                            Now&apos;s the time to join this season!
                          </Typography>
                        </>
                      )}
                    </>
                  )}

                  {/* {overview && overview.doors ? (
                  overview.doors.open ? (
                    <>
                      <Typography variant="h2" className={classes.titleLarge}>
                        <span className={classes.open}>Open</span>
                      </Typography>
                      <Typography variant="h3" className={classes.featureTitle}>
                        Now&apos;s Your Chance.
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.featureDesc}
                      >
                        Take a Free Trial To Experience FarmFLiX
                      </Typography>
                      <TryButton
                        label="Take Free Trial"
                        size="large"
                        className={classes.button}
                      />
                      <Typography
                        variant="body1"
                        className={classes.buttonSubText}
                      >
                        No Payment Required for Free Trial
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="h2" className={classes.titleLarge}>
                        <span className={classes.closed}>Closed</span>
                      </Typography>
                      <Typography variant="h3" className={classes.featureTitle}>
                        We&apos;re not taking on new members at present.
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.featureDesc}
                      >
                        Want to get notified when we open again?
                      </Typography>
                      <TryButton
                        label="Yes, Notify Me"
                        size="large"
                        className={classes.button}
                      />
                    </>
                  )
                ) : null} */}
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.right}>
                  <Typography variant="h2" className={classes.title}>
                    Got a
                  </Typography>
                  <Typography variant="h2" className={classes.titleLarge}>
                    Giftcard?
                  </Typography>
                  <Typography variant="h3" className={classes.featureTitle}>
                    Get Started in Two Simple Steps
                  </Typography>
                  {overview && overview.doors?.open && (
                    <Typography variant="body1" className={classes.featureDesc}>
                      Don&apos;t have a Giftcard? Get one{" "}
                      <a
                        href="https://shop.farmflix.tv/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </a>
                    </Typography>
                  )}
                  <Link to="/usemycode" style={{ textDecoration: "none" }}>
                    <Button
                      variant="outlined"
                      className={classes.secondaryButton}
                    >
                      Redeem Giftcard
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
