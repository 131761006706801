import React, { useContext } from "react";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Footer/Footer";
import HeaderInternal from "./Header/HeaderInternal";
import Header from "./Header/Header";
import { AccountContext } from "../store/AccountContext";
import { OverviewContext } from "../store/OverviewContext";
import { Redirect } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import TryButton from "./Onboarding/TryButton";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

const useStyles = makeStyles(theme => ({
  doorsClosedContainer: {
    textAlign: "center"
  },
  background: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.ffNavyLighter,
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    },
    "@media (min-width:960px)": {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8)
    }
  },

  loading: {
    textAlign: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  header: {
    color: theme.palette.background.ffGreen,
    fontSize: "2.4rem",
    lineHeight: "2.2rem",
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
    textAlign: "left",
    fontWeight: 700,
    letterSpacing: "-1px",
    "@media (min-width:600px)": {
      textAlign: "center",
      padding: theme.spacing(0, 3),
      marginBottom: theme.spacing(4),
      fontSize: "1.8rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3.4rem",
      lineHeight: "3.4rem"
    }
  },
  subHeader: {
    color: theme.palette.common.white,
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
    textAlign: "left",
    fontWeight: 500,
    "@media (min-width:600px)": {
      textAlign: "center",
      padding: theme.spacing(0, 3),
      marginBottom: theme.spacing(4),
      fontSize: "1.8rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2rem",
      lineHeight: "2rem"
    }
  },
  bodyText: {
    color: theme.palette.common.upgradeAlt,
    textAlign: "left",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: 500,
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      textAlign: "center"
    }
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "300px"
    },
    "@media (min-width:960px)": {
      width: "300px"
    }
  },

  shareEpisode: {
    marginTop: theme.spacing(2),
    textAlign: "center"
  },

  smlDivider: {
    margin: theme.spacing(3, 2, 3, 2),
    width: "20%",
    height: "2px",
    backgroundColor: "#585858",
    "@media (min-width:600px)": {
      width: "30%",
      height: "2px",
      margin: theme.spacing(4, 2, 4, 2)
    }
  },
  youtubeIcon: {
    width: "2em",
    height: "2em"
  },

  fbIconStyle: {
    fontSize: "2rem",
    padding: "10px",
    backgroundColor: "#3b5998",
    borderRadius: "50%",
    margin: "10px",
    color: theme.palette.common.lightgray,
    cursor: "pointer"
  },
  insIconStyle: {
    fontSize: "2rem",
    padding: "10px",
    backgroundColor: "#C13584",
    borderRadius: "50%",
    margin: "10px",
    color: theme.palette.common.lightgray,
    cursor: "pointer"
  },
  ytIconStyle: {
    fontSize: "2rem",
    padding: "10px",
    backgroundColor: "#e81919",
    borderRadius: "50%",
    margin: "10px",
    color: theme.palette.common.lightgray,
    cursor: "pointer"
  }
}));

const DoorsClosed = () => {
  const classes = useStyles();

  const { loading, loggedIn, userProfile } = useContext(AccountContext);
  const { overview } = useContext(OverviewContext);

  if (!loading && loggedIn) {
    if (
      userProfile.memberStatus === "trial" ||
      userProfile.memberStatus === "standard"
    ) {
      return <Redirect to="/videos" />;
    } else {
      return <Redirect to="/myaccount/overview" />;
    }
  }

  return (
    <>
      <div>
        <>
          {loggedIn && <HeaderInternal />}
          {!loggedIn && <Header />}
        </>
        <>
          {overview && (
            <div className={classes.background}>
              <Container
                fixed
                fixedcomponent="main"
                maxWidth="md"
                className={classes.doorsClosedContainer}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} align="center">
                    {overview.doors?.open ? (
                      <>
                        <Typography variant="h2" className={classes.header}>
                          Last chance to get FarmFLiX before Christmas{" "}
                          {dayjs(overview.doors?.until).format("YYYY")}
                        </Typography>
                        <Typography variant="h2" className={classes.subHeader}>
                          The FarmFLiX Membership Doors close midnight on{" "}
                          {dayjs(overview.doors?.until).format("dddd Do MMMM")}.
                        </Typography>
                        <Divider className={classes.smlDivider} />
                        <Link to="/buy" style={{ textDecoration: "none" }}>
                          <Button
                            disableRipple
                            size="large"
                            className={classes.button}
                            style={{ marginTop: "0px", marginBottom: "12px" }}
                          >
                            Join Now
                          </Button>
                        </Link>
                        <Typography
                          variant="body1"
                          className={classes.buttonSubText}
                        >
                          <b>Don&apos;t Miss Out!</b>
                          <br />
                          Last chance to join this season!
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="h2" className={classes.header}>
                          Membership is Locked until Christmas{" "}
                          {dayjs(overview.doors?.until).format("YYYY")}
                        </Typography>
                        <Typography variant="h2" className={classes.subHeader}>
                          The FarmFLiX Membership Doors closed on 23th January
                          2022.
                        </Typography>
                        <Divider className={classes.smlDivider} />
                        <Typography
                          variant="body1"
                          className={classes.bodyText}
                          style={{ marginBottom: "16px" }}
                        >
                          Notify me when the Doors Open again?
                        </Typography>
                        <TryButton
                          label="Yes, Notify Me"
                          size="large"
                          className={classes.button}
                        />
                      </>
                    )}

                    <Divider className={classes.smlDivider} />
                    <Typography
                      variant="body1"
                      className={classes.bodyText}
                      style={{
                        marginBottom: "12px",
                        color: "#999",
                        textAlign: "center"
                      }}
                    >
                      Find FarmFLiX on:
                    </Typography>
                    <a
                      href={"https://en-gb.facebook.com/farmflix/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon className={classes.fbIconStyle} />
                    </a>
                    <a
                      href={"https://www.instagram.com/farmflix/?hl=en"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon className={classes.insIconStyle} />
                    </a>
                    <a
                      href={"https://www.youtube.com/farmflix"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <YouTubeIcon className={classes.ytIconStyle} />
                    </a>
                  </Grid>
                </Grid>
              </Container>
            </div>
          )}
        </>
        <Footer />
      </div>
    </>
  );
};

export default DoorsClosed;
