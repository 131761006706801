import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AccountContext } from "../../store/AccountContext";
import { Redirect, useLocation } from "react-router-dom";
import SignUpForm from "../Onboarding/SignUp/SignUpForm";
import { APPLICATION_SOURCE } from "@booyaltd/core";
import {
  MEMBER_STATUS_STANDARD,
  MEMBER_STATUS_TRIAL
} from "../../api/entities/UserProfile";
import { Element } from "react-scroll";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "auto",
    display: "flex",
    height: "80vh",
    // @ts-ignore
    marginBottom: theme.spacing(4),

    "@media (min-width:600px)": {
      height: "auto"
    },
    "@media (min-width:769px)": {
      height: "auto"
    },
    "@media (min-width:960px)": {
      height: "auto"
    }
  }
}));

const Stepper = () => {
  const classes = useStyles();
  const location = useLocation();
  const { loggedIn, userProfile } = useContext(AccountContext);

  return (
    <>
      <Element name="onboarding-form">
        <div className={classes.background}>
          {loggedIn &&
          (userProfile?.memberStatus === MEMBER_STATUS_STANDARD ||
            userProfile?.memberStatus === MEMBER_STATUS_TRIAL) ? (
            <Redirect to="/videos" />
          ) : null}

          <SignUpForm
            source={APPLICATION_SOURCE.PUBLIC}
            page={location.pathname}
            title="Take Free Trial"
            background={false}
            route="try"
          />
        </div>
      </Element>
    </>
  );
};

export default Stepper;
