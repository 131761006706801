import React, { useMemo } from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { PlaylistCatalogResponse } from "@booyaltd/core";
import { PlayCircleOutline } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  background: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    padding: 0,
    paddingTop: 10,
    paddingBottom: 10
  },
  fullWidthItemContainer: {
    flex: 1,
    width: "100%",
    height: "auto",
    display: "block",
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    zIndex: 1
  },
  fullWidthImage: {
    width: "100%",
    height: "auto",
    aspectRatio: `${16 / 9}`
  },
  fullWidthItemContainerBottomGradient: {
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 40%, rgba(23,23,23,0.85) 100%)",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  fullWidthItemDetails: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
    textAlign: "left",
    "@media (min-width:600px)": {
      bottom: "30px",
      left: "25px",
      textAlign: "left"
    },
    "@media (min-width:960px)": {
      bottom: "30px",
      left: "25px",
      textAlign: "left"
    }
  },
  fullWidthItemTitle: {
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(0.2),
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3.4rem",
      lineHeight: "3.4rem",
      marginBottom: theme.spacing(1)
    }
  },
  fullWidthItemDesc: {
    fontSize: "0.9rem",
    lineHeight: "1rem",
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    color: "#c5c5c5",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem"
    }
  },
  playButtonWrapper: {
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    justifyItems: "center",
    alignItems: "center"
  },
  playButton: {
    fontSize: "4rem",
    color: "white",
    opacity: 0.9,
    "@media (min-width:600px)": {
      fontSize: "5.5rem"
    },
    "@media (min-width:960px)": {
      fontSize: "7rem"
    }
  }
}));

type VerticalPlaylistSectionProps = {
  playlists: PlaylistCatalogResponse[];
};

const SingleVideoPlaylistSection = ({
  playlists
}: VerticalPlaylistSectionProps) => {
  const classes = useStyles();
  const playlist = useMemo(
    () => (playlists && playlists.length > 0 ? playlists[0] : undefined),
    [playlists]
  );
  const video = useMemo(
    () =>
      playlist && playlist.videos && playlist.videos.length > 0
        ? playlist.videos[0]
        : undefined,
    [playlist]
  );

  if (!playlist || !video || !video.thumbnail) {
    return null;
  }

  return (
    <div className={classes.background}>
      <Container>
        <Link to={"/video-details/" + video.slug}>
          <Box className={classes.fullWidthItemContainer}>
            <img
              src={video.thumbnail}
              className={classes.fullWidthImage}
              alt="Video Thumbnail"
            />
            <div className={classes.fullWidthItemContainerBottomGradient}>
              <div className={classes.fullWidthItemDetails}>
                <Typography className={classes.fullWidthItemTitle} variant="h4">
                  {video.name}
                </Typography>
                <Typography className={classes.fullWidthItemDesc}>
                  {video.description}
                </Typography>
              </div>
              <Box className={classes.playButtonWrapper}>
                <PlayCircleOutline
                  className={classes.playButton}
                  fontSize="large"
                />
              </Box>
            </div>
          </Box>
        </Link>
      </Container>
    </div>
  );
};

export default SingleVideoPlaylistSection;
