import React from "react";
import { Fab, makeStyles } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { SupportModal } from "./SupportModal";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

export const SupportFabButton = () => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  return (
    <>
      <Fab
        color="primary"
        variant="extended"
        className={classes.fab}
        onClick={() => setOpen(true)}
      >
        <HelpOutline className={classes.icon} /> Help
      </Fab>
      {open && <SupportModal onClose={() => setOpen(false)} />}
    </>
  );
};
