import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import LoggedInLayout from "../layouts/LoggedInLayout";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import { Helmet } from "react-helmet";
import useVideoTypeSearch from "../hooks/useVideoTypeSearch";
import CircularProgress from "@material-ui/core/CircularProgress";
import VideoRow from "./Video/VideoRow";
import SortIcon from "@material-ui/icons/Sort";
import { VideoSearchParamIsReady, VideoSort, VideoType } from "@booyaltd/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import LoadMoreButton from "./Search/LoadMoreButton";

const useStyles = makeStyles(theme => ({
  backgroundSection: {
    height: "300px",
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "800px"
    }
  },
  headerSection: {
    margin: theme.spacing(3, 0, 3, 0),
    "@media (min-width:600px)": {
      margin: theme.spacing(4, 0, 3, 0)
    },
    "@media (min-width:800px)": {
      margin: theme.spacing(8, 0, 2, 0)
    }
  },
  favouriteIcon: {
    color: "#f13849",
    fontSize: "2rem",
    marginRight: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "2.5rem",
      marginRight: theme.spacing(1)
    },
    "@media (min-width:800px)": {
      fontSize: "3rem",
      marginRight: theme.spacing(2)
    }
  },
  formControl: {
    "& label": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    }
  },
  sortDropdown: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    color: "white",
    "& .MuiInput-input": {
      border: "none",
      padding: theme.spacing(1, 4, 1, 1)
    }
  }
}));

const Favourites = () => {
  const classes = useStyles();

  const [sortOrder, setSortOrder] = useState<VideoSort>(
    VideoSort.FavouritedDate
  );

  const {
    searchResponse,
    searchLoading,
    updateSearch,
    canLoadMore,
    loadMore
  } = useVideoTypeSearch({
    type: VideoType.Episode,
    isReady: VideoSearchParamIsReady.Master,
    favourited: true,
    sort: VideoSort.FavouritedDate,
    includes: ["watched", "favourited", "progress"]
  });

  const handleSortOrderChange: SelectInputProps["onChange"] = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setSortOrder(event.target.value as VideoSort);
    updateSearch({
      sort: event.target.value as VideoSort
    });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | My Favourites</title>
        <meta name="description" content="Your FarmFLiX Favourites" />
      </Helmet>
      <LoggedInLayout>
        <Container>
          <Grid container className={classes.headerSection}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <FavoriteRoundedIcon className={classes.favouriteIcon} />
                <Typography variant="h1">Favourites</Typography>
              </Box>
              <Box>
                <SortIcon
                  color="primary"
                  style={{ marginTop: "6px", marginRight: "6px" }}
                />
                <FormControl className={classes.formControl}>
                  <Select
                    className={classes.sortDropdown}
                    onChange={handleSortOrderChange}
                    value={sortOrder}
                  >
                    <MenuItem value={VideoSort.FavouritedDate}>
                      Favourite Date
                    </MenuItem>
                    <MenuItem value={VideoSort.PublishDate}>
                      Publish Date
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          {/* Videos Total > 0 */}
          <Grid item xs={12}>
            {searchLoading && !searchResponse?.total ? (
              <CircularProgress />
            ) : (searchResponse?.total || 0) > 0 ? (
              <>
                {searchResponse?.results.map((video, idx) => (
                  <VideoRow
                    key={video.id}
                    idx={idx}
                    video={video}
                    listType={"playlist"}
                  />
                ))}
                <LoadMoreButton
                  canLoadMore={canLoadMore}
                  loading={searchLoading}
                  loadMore={loadMore}
                />
              </>
            ) : (
              <Box mt={5} alignSelf="center">
                <Typography variant="h4">
                  You haven&apos;t added any epiosdes to My Favourites yet!
                </Typography>
              </Box>
            )}
          </Grid>
        </Container>
      </LoggedInLayout>
    </div>
  );
};

export default Favourites;
