import React from "react";
import { Timer } from "@material-ui/icons";
import { Box, Typography } from "@material-ui/core";

export const RunTime = ({
  runTime,
  penalty
}: {
  runTime: number | null;
  penalty: number | null;
}) => {
  if (!runTime) {
    return null;
  }

  return (
    <span>
      {parseFloat(String(runTime)).toFixed(2)}s
      {penalty ? (
        <span className="penalty"> (+{parseInt(String(penalty))})</span>
      ) : null}
    </span>
  );
};

export const RunTimeIcon = ({
  runTime,
  penalty
}: {
  runTime: number | null;
  penalty: number | null;
}) => {
  if (!runTime) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifySelf="center"
      justifyContent="center"
      mt={1}
      color="white"
    >
      <Timer />
      <Typography style={{ marginLeft: "2px" }}>
        <RunTime runTime={runTime} penalty={penalty} />
      </Typography>
    </Box>
  );
};
