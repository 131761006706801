import React, { useEffect, useMemo } from "react";
import Header from "../Header/Header";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Container, Paper, Typography } from "@material-ui/core";
import { initializeApp } from "firebase/app";
import { Database, getDatabase, onValue, ref } from "firebase/database";
import classnames from "classnames";
import { useStyles } from "./styles";
import { Competitor, ComputedCompetitor } from "./types";
import sortBy from "lodash/sortBy";
import { CompetitorLeaderboard } from "./CompetitorLeaderboard";
import { ChevronRight } from "@material-ui/icons";
import { Matchups } from "./Matchups";

const firebaseConfig = {
  databaseURL:
    "https://onthepull-d7dbb-default-rtdb.europe-west1.firebasedatabase.app/"
};

const availableClasses = [
  { id: 1, name: "Class 1", description: "" },
  { id: 2, name: "Class 2", description: "" },
  { id: 3, name: "Class 3", description: "" },
  { id: 4, name: "Class 4", description: "" },
  { id: 5, name: "Class 5", description: "" }
];

export const OnThePullLive = () => {
  const classes = useStyles();
  const [competitors, setCompetitors] = React.useState<Competitor[]>();
  const [selectedClass, setSelectedClass] = React.useState<
    keyof typeof availableClasses
  >(1);
  const params = useParams<{ view: "matches" | "leaderboard" }>();
  const selectedView = params.view || "leaderboard";

  const filteredCompetitors = useMemo(() => {
    if (!competitors) {
      return [];
    }

    return (
      competitors
        .filter(competitor => competitor.class === selectedClass)
        .map<ComputedCompetitor>(competitor => {
          const run1Time = competitor.run1Time
            ? parseFloat(String(competitor.run1Time)) +
              parseFloat(String(competitor.run1Penalty))
            : 0;

          const run2Time = competitor.run2Time
            ? parseFloat(String(competitor.run2Time)) +
              parseFloat(String(competitor.run2Penalty))
            : 0;

          const semiTotal = competitor.semiTime
            ? parseFloat(String(competitor.semiTime)) +
              parseFloat(String(competitor.semiPenalty))
            : 0;

          const finalTotal = competitor.finalTime
            ? parseFloat(String(competitor.finalTime)) +
              parseFloat(String(competitor.finalPenalty))
            : 0;

          return {
            ...competitor,
            runTotal:
              run1Time && run2Time
                ? Math.min(run1Time, run2Time)
                : run1Time || run2Time || null,
            semiTotal,
            finalTotal
          };
        })
        // Sort finalists by final time, then semi time, then run total if greater than 0, then by name
        .sort((a, b) => {
          if (a.finalTotal && b.finalTotal) {
            return a.finalTotal - b.finalTotal;
          } else if (a.finalTotal) {
            return -1;
          } else if (b.finalTotal) {
            return 1;
          } else if (a.semiTotal && b.semiTotal) {
            return a.semiTotal - b.semiTotal;
          } else if (a.semiTotal) {
            return -1;
          } else if (b.semiTotal) {
            return 1;
          } else if (a.runTotal && b.runTotal) {
            return a.runTotal - b.runTotal;
          } else if (a.runTotal) {
            return -1;
          } else if (b.runTotal) {
            return 1;
          } else {
            return 0;
          }
        })
    );
  }, [competitors, selectedClass]);

  // Pairs competitors based on the `matchUp` field using lodash
  const matchedCompetitors = useMemo(() => {
    if (!filteredCompetitors?.length) {
      return [];
    }

    const competitorsWithMatchups = sortBy(
      filteredCompetitors.filter(competitor => !!competitor.matchUp),
      "matchUp"
    );

    return competitorsWithMatchups.reduce<
      [ComputedCompetitor, ComputedCompetitor | null][]
    >((acc, competitor) => {
      const existingMatchup = acc.find(
        ([existingMatch]) => existingMatch?.matchUp === competitor.matchUp
      );

      if (existingMatchup) {
        existingMatchup[1] = competitor;
      } else {
        acc.push([competitor, null]);
      }

      return acc;
    }, []);
  }, [filteredCompetitors]);

  // Pairs competitors based on the `semiMatchup` field using lodash
  const matchedSemiCompetitors = useMemo(() => {
    const semiCompetitors = filteredCompetitors.filter(
      competitor => !!competitor.semiMatchUp
    );
    if (!semiCompetitors?.length) {
      return [];
    }

    const competitorsWithMatchups = sortBy(semiCompetitors, "semiMatchUp");

    return competitorsWithMatchups.reduce<
      [ComputedCompetitor, ComputedCompetitor | null][]
    >((acc, competitor) => {
      const existingMatchup = acc.find(
        ([existingMatch]) =>
          existingMatch?.semiMatchUp === competitor.semiMatchUp
      );

      if (existingMatchup) {
        existingMatchup[1] = competitor;
      } else {
        acc.push([competitor, null]);
      }

      return acc;
    }, []);
  }, [filteredCompetitors]);

  // Top semi finalists based on `semiTotal` field
  const matchedFinalCompetitors = useMemo(() => {
    const completedSemiFinalists = filteredCompetitors?.filter(
      competitor => !!competitor.semiTotal
    );

    if (completedSemiFinalists?.length < 3) {
      return [];
    }

    const sortedSemiCompetitors = sortBy(completedSemiFinalists, "semiTotal");
    return [
      // Show first place when 3/4 finalists are done, and second place when all semi finalists have completed
      [
        sortedSemiCompetitors[0],
        sortedSemiCompetitors?.length > 3 ? sortedSemiCompetitors[1] : null
      ]
    ] as [ComputedCompetitor, ComputedCompetitor | null][];
  }, [filteredCompetitors]);

  const database: Database = useMemo(() => {
    const app = initializeApp(firebaseConfig, "onthepull");
    return getDatabase(app);
  }, []);

  useEffect(() => {
    const competitorsRef = ref(database, "competitors");
    onValue(competitorsRef, snapshot => {
      const data = snapshot.val();

      setCompetitors(data);
    });
  }, [database]);

  return (
    <div>
      <Header />
      <div className={classes.background}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FarmFLiX.tv | #OnThePull23 Event Information</title>
          <meta name="description" content="#OnThePull23 Event Information" />
        </Helmet>
        <section>
          <Paper
            className={classnames(
              classes.headerContainer,
              " banner-bg",
              classes.pageSection
            )}
          >
            <Container
              maxWidth="md"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "space-evenly",
                alignItems: "center",
                position: "relative"
              }}
            >
              <Typography variant="h1" className={classes.mainTitle}>
                FarmFliX #OnThePull23
              </Typography>

              {selectedView === "leaderboard" ? (
                <Link to="/onthepull/matches" className={classes.link}>
                  <Button className={classes.viewButton}>
                    See Matches <ChevronRight />
                  </Button>
                </Link>
              ) : (
                <Link to="/onthepull/leaderboard" className={classes.link}>
                  <Button className={classes.viewButton}>
                    See Leaderboard <ChevronRight />
                  </Button>
                </Link>
              )}
            </Container>
          </Paper>
        </section>

        <section>
          <Container
            maxWidth="md"
            className={classnames(classes.pageSection, classes.mainSection)}
          >
            <Box className={classes.buttonsContainer}>
              <Box className={classes.classButtonsContainer}>
                {availableClasses.map(classData => {
                  const isActive = selectedClass === classData.id;
                  return (
                    <Button
                      key={classData.id}
                      onClick={() => setSelectedClass(classData.id)}
                      variant="outlined"
                      className={classnames(
                        classes.classButton,
                        isActive ? classes.classButtonSelected : null
                      )}
                    >
                      {classData.name}
                    </Button>
                  );
                })}
              </Box>
            </Box>

            {selectedView === "leaderboard" && filteredCompetitors ? (
              <>
                <Typography variant="h2" className={classes.mainTitle}>
                  Leaderboard
                </Typography>
                <CompetitorLeaderboard competitors={filteredCompetitors} />
              </>
            ) : null}

            {selectedView === "matches" && matchedCompetitors.length ? (
              <>
                {matchedFinalCompetitors.length ? (
                  <>
                    <Typography variant="h2" className={classes.mainTitle}>
                      Final
                    </Typography>
                    <Matchups
                      matchedCompetitors={matchedFinalCompetitors}
                      displayType="final"
                    />
                  </>
                ) : null}
                {matchedSemiCompetitors.length ? (
                  <>
                    <Typography variant="h2" className={classes.mainTitle}>
                      Semi Final
                    </Typography>
                    <Matchups
                      matchedCompetitors={matchedSemiCompetitors}
                      displayType="semi"
                    />
                  </>
                ) : null}
                <Typography variant="h2" className={classes.mainTitle}>
                  Heats
                </Typography>
                <Matchups
                  matchedCompetitors={matchedCompetitors}
                  displayType="run"
                />
              </>
            ) : null}
          </Container>
        </section>
      </div>
    </div>
  );
};
