import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, IconButton } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { Link } from "react-router-dom";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { getTitleAndChannelFromVideoName } from "../../utils/strings";
import { PlaylistRowProps } from "./PlaylistsSection";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import { MEMBER_STATUS_TRIAL } from "@booyaltd/core";

// @ts-ignore
import ItemsCarousel from "react-items-carousel";
import VideoProgress from "../Video/VideoProgress";
import { PlayerContext } from "../../store/PlayerContext";

const useStyles = makeStyles(theme => ({
  episodeContainer: {
    position: "relative",
    "&:hover": {
      cursor: "pointer"
    }
  },
  episodeContainerBottomGradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(33,33,33,1) 90%)",
    height: "50%",
    width: "100%"
  },
  episodeContainerBottomGradientHover: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.4) 10%, rgba(23,23,23,1) 100%)",
    height: "100%",
    width: "100%"
  },
  episodeDetails: {
    position: "absolute",
    bottom: "10%",
    left: "15px"
  },
  chevron: {
    // @ts-ignore
    color: theme.palette.common.lightgray,
    cursor: "pointer",
    padding: "10px",
    fontSize: "1.4rem",
    borderRadius: "50%",
    backgroundColor: "rgba(2,2,2,0.5)",
    "&:hover": {
      color: theme.palette.common.white
    }
  },
  episodeChannel: {
    borderRadius: "50%",
    width: "50px"
  },
  episodeTitle: {
    marginTop: theme.spacing(0.8),
    fontSize: "1.1rem",
    paddingRight: "10px",
    // @ts-ignore
    color: theme.palette.common.upgrade,
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.3rem",
      lineHeight: "1.2rem"
    }
  },
  episodeNumber: {
    marginTop: theme.spacing(0.5),
    fontSize: "1.1rem",
    marginBottom: "2px",
    paddingRight: "10px",
    // @ts-ignore
    color: theme.palette.common.lightgray,
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "0.9rem",
      lineHeight: "1rem"
    }
  },
  "@global": {
    "@keyframes fadeInFromBottom": {
      from: {
        transform: "translate3d(0,15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    }
  },
  episodeDetailsActiveBottom: {
    animation: "fadeInFromBottom .3s ease-in-out",
    position: "absolute",
    bottom: "25px",
    right: "5px"
  },
  episodeDetailsActivePlayIconButton: {
    fontSize: "1.2rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        // @ts-ignore
        color: theme.palette.background.ffGreen
      }
    }
  },
  iconButton: {
    width: "2rem",
    height: "2rem"
  },
  episodeContainerBottomGradientWatched: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.4) 10%, rgba(23,23,23,1) 100%)",
    height: "100%",
    width: "100%"
  },
  episodeWatchedLabel: {
    position: "absolute",
    top: "5px",
    right: "0px",
    backgroundColor: "rgba(23,23,23,0.4)",
    color: "#fff",
    padding: theme.spacing(0.7),
    display: "flex"
  },
  episodeWatchedLabelText: {
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  episodeWatchedLabelIcon: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginRight: theme.spacing(1)
  }
}));

const PlaylistRowCarousel = ({
  playlist,
  channels,
  memberStatusId
}: PlaylistRowProps) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [hoverIdx, setHoverIdx] = useState<number>();
  const player = useContext(PlayerContext);

  const onEpisodeEnter = (index: number) => {
    setHoverIdx(index);
  };

  const onEpisodeLeave = () => {
    setHoverIdx(undefined);
  };

  const chevronWidth = 0;

  const classes = useStyles();

  return (
    <>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={3}
        slidesToScroll={3}
        gutter={10}
        leftChevron={
          <div>
            <ArrowBackIosRoundedIcon className={classes.chevron} />
          </div>
        }
        rightChevron={
          <div>
            <ArrowForwardIosRoundedIcon className={classes.chevron} />
          </div>
        }
        chevronWidth={chevronWidth}
      >
        {playlist.videos.map((episode, index) => {
          const showMembersOnlyBanner =
            memberStatusId === MEMBER_STATUS_TRIAL &&
            episode.restrictedFromMemberStatuses?.includes(MEMBER_STATUS_TRIAL);
          const {
            title,
            episode: episodeNumber
          } = getTitleAndChannelFromVideoName(episode.name);
          const channel = channels.find(
            c => episode.channelId && c.id === episode.channelId
          );

          return (
            <Link
              key={episode.id}
              to={"video-details/" + episode.slug}
              style={{ textDecoration: "none" }}
            >
              <Box
                className={classes.episodeContainer}
                onMouseEnter={e => onEpisodeEnter(index)}
                onMouseLeave={() => onEpisodeLeave()}
              >
                <div
                  className={
                    hoverIdx === index
                      ? classes.episodeContainerBottomGradientHover
                      : episode.watched
                      ? classes.episodeContainerBottomGradientWatched
                      : classes.episodeContainerBottomGradient
                  }
                />
                <img
                  src={episode.thumbnailThumb}
                  style={{ width: "100%" }}
                  alt={episode.name}
                />

                {showMembersOnlyBanner ? (
                  <div className={classes.episodeWatchedLabel}>
                    <LockRoundedIcon
                      className={classes.episodeWatchedLabelIcon}
                    />
                    <Typography
                      variant="body2"
                      className={classes.episodeWatchedLabelText}
                    >
                      Members Only
                    </Typography>
                  </div>
                ) : episode.watched ? (
                  <div className={classes.episodeWatchedLabel}>
                    <CheckCircleRoundedIcon
                      className={classes.episodeWatchedLabelIcon}
                    />
                    <Typography
                      variant="body2"
                      className={classes.episodeWatchedLabelText}
                    >
                      Watched
                    </Typography>
                  </div>
                ) : null}

                <VideoProgress
                  durationMs={episode.duration}
                  progress={episode.progress}
                  localProgress={player.localVideoProgresses[episode.id]}
                />

                <Box className={classes.episodeDetails}>
                  {channel && channel.logoImageUrl && (
                    <img
                      className={classes.episodeChannel}
                      src={channel.logoImageUrl}
                      alt=""
                    />
                  )}
                  {title && (
                    <Typography
                      className={classes.episodeTitle}
                      variant="body1"
                    >
                      {title}
                    </Typography>
                  )}
                  {episodeNumber && (
                    <Typography
                      className={classes.episodeNumber}
                      variant="body2"
                    >
                      Ep.{episodeNumber.replace("Ep", "")}
                    </Typography>
                  )}
                </Box>

                {hoverIdx === index && (
                  <>
                    <Box className={classes.episodeDetailsActiveBottom}>
                      <IconButton
                        aria-label="Play Episode"
                        className={classes.episodeDetailsActivePlayIconButton}
                      >
                        <PlayArrowIcon className={classes.iconButton} />
                      </IconButton>
                    </Box>
                  </>
                )}
              </Box>
            </Link>
          );
        })}
      </ItemsCarousel>
    </>
  );
};

export default PlaylistRowCarousel;
