import React from "react";
import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid
} from "@material-ui/core";

// Filters
import RegionFilter from "./RegionFilter";
import ChannelFilter from "./ChannelFilter";
import YearFilter from "./YearFilter";
import SortFilter from "./SortFilter";

import useStyles from "./styles";
import CloseSearchIconButton from "./trigger/CloseSearchIconButton";
import SearchAutocompleteInput from "./SearchAutocompleteInput";
import SearchResults from "./SearchResults";
import FavouritedFilter from "./FavouritedFilter";
import WatchedFilter from "./WatchedFilter";

const SearchModal = () => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open
      disableBackdropClick
      aria-labelledby="form-dialog-title"
      className={classes.searchDialogContainer}
    >
      <DialogTitle id="form-dialog-title" className={classes.searchDialogTitle}>
        Search FarmFLiX
        <CloseSearchIconButton />
      </DialogTitle>
      <DialogContent className={classes.searchDialogContent}>
        <Container>
          <Grid
            container
            style={{ textAlign: "left" }}
            className={classes.searchAutoCompleteContainer}
          >
            <Grid item xs={12} sm={12}>
              <SearchAutocompleteInput />
            </Grid>
          </Grid>
          <Grid container className={classes.filtersContainer}>
            <Grid item xs={12} className={classes.filtersList}>
              <RegionFilter />
              <ChannelFilter />
              <YearFilter />
              <FavouritedFilter />
              <WatchedFilter />
              <SortFilter />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "12px" }}>
              <SearchResults />
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default SearchModal;
