import React, { useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import HeaderInternal from "./Header/HeaderInternal";
import { AccountContext } from "../store/AccountContext";
import {
  Divider,
  Container,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Helmet } from "react-helmet";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(4, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(6, 0)
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(8, 0)
    }
  },
  backgroundAlt: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(4, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(6, 0)
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(8, 0)
    }
  },
  appContainer: {
    paddingTop: theme.spacing(6)
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },

  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    lineHeight: "2.2rem",
    fontSize: "2.2rem",
    fontWeight: "700",
    letterSpacing: "-2px",
    padding: "0 30px",
    // color: theme.palette.background.ffNavyLighter,
    "@media (min-width:600px)": {
      fontSize: "2.8rem",
      lineHeight: "2.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3.6rem",
      lineHeight: "3.8rem"
    }
  },

  titleSubHeading: {
    textAlign: "center",
    fontSize: "1.2rem",
    color: "#9b9b9b",
    fontWeight: "500",
    letterSpacing: "-1px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },

  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    lineHeight: "1.8rem",
    fontSize: "2rem",
    fontWeight: "700",
    letterSpacing: "-2px",
    padding: "0 30px",
    color: "#fff",
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3.0rem",
      lineHeight: "3.2rem"
    }
  },
  thirdtitle: {
    textAlign: "center",
    fontSize: "1.3rem",
    fontWeight: "600",
    letterSpacing: "-1px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    }
  },
  installHeading: {
    textAlign: "left",
    fontSize: "1.2rem",
    color: "#fff",
    fontWeight: "500",
    letterSpacing: "-1px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },
  tvAppImage: {
    width: "100%"
  },
  amazonAppstore: {
    maxWidth: "160px",
    marginBottom: theme.spacing(2)
  },
  fireTvLogo: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
    maxWidth: "150px",
    marginBottom: theme.spacing(4)
  },
  appIconLogo: {
    marginTop: theme.spacing(2),
    borderRadius: "10px",
    maxWidth: "80px",
    marginBottom: theme.spacing(4)
  },
  alignCenter: {
    textAlign: "center"
  },
  smlDivider: {
    height: "2px",
    backgroundColor: "#bbb",
    maxWidth: "200px",
    margin: "0 auto"
  },
  helpSection: {
    textAlign: "center",
    marginTop: theme.spacing(0)
  },
  accordionContainer: {
    marginTop: theme.spacing(3)
  },
  accordionEntry: {
    //@ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    borderRadius: "0 !important",
    padding: theme.spacing(0.5, 0),
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      padding: theme.spacing(1, 0)
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: theme.spacing(0, 1.5)
    }
  },
  accordionEntryTitle: {
    textAlign: "left",
    fontSize: "1rem",
    lineHeight: "1.1rem",
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem"
    }
  },
  accordionEntryText: {
    textAlign: "left",
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    "& a": {
      color: "#eee"
    },
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    }
  },
  accordionEntryExpand: {
    // @ts-ignore
    fill: theme.palette.background.ffGreen,
    fontSize: "2.2rem",
    padding: 0
  }
}));

const FarmFLiXBigScreen = props => {
  const classes = useStyles();

  const { loggedIn } = useContext(AccountContext);

  const faqs = [
    {
      id: 1,
      title: "What is Amazon Fire TV?",
      body:
        "Amazon Fire TV is a video streaming device created by Amazon — it connects to a television or monitor and lets you watch TV shows and movies streamed over apps like Netflix, Hulu, Amazon Prime, and thousands of others. Fire TV works like other media players (such as Roku, Google Chromecast, and the Apple TV). It's a device that connects to a TV or monitor via an HDMI port and behaves sort of like the TV's operating system. It has its own home page and library of free and subscription-based streaming apps that you can use to watch videos and listen to music. In that way, Fire TV turns any ordinary TV into a 'smart' TV — or can be used as a replacement for the OS built into a smart TV."
    },
    {
      id: 2,
      title: "Where do I purchase an Amazon Fire TV device?",
      body: (
        <Fragment>
          You can purchase any of the Fire TV devices on Amazon&apos;s website.
          Click{" "}
          <a
            href="https://www.amazon.co.uk/Fire-TV/b?ie=UTF8&node=5157838031"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
          to view the range of devices.
        </Fragment>
      )
    },
    {
      id: 3,
      title: "Will the FarmFLiX TV App work on the cheapest Fire TV device?",
      body:
        "Yes, it will.  We use this model of the Fire TV device to develop the FarmFLiX TV App."
    },
    {
      id: 4,
      title: "I've bought a Fire TV device, how do I use it with my TV?",
      body: (
        <Fragment>
          It&apos;s dead easy to use. Amazon have provided clear documentation
          to help you set up your Fire TV device. You can find instructions for
          each of the devices{" "}
          <a
            href="https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=201348270"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
          .
        </Fragment>
      )
    },
    {
      id: 5,
      title: "How do I get the FarmFLiX TV App on my Fire TV device?",
      body: (
        <Fragment>
          Simple! Once you have your Fire TV device setup, navigate to the Fire
          TV App Store and search for &apos;farmflix&apos;. Look out for the
          icon below.
          <br />
          <img
            src="https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/tvapp_icon.png"
            alt="FarmFLiX App Logo"
            className={classes.appIconLogo}
            style={{ marginBottom: 0 }}
          />
        </Fragment>
      )
    },
    {
      id: 6,
      title:
        "Does the FarmFLiX TV App work the same as the website and the Mobile App?",
      body: (
        <Fragment>
          Yes, you simply log into the TV App with your normal FarmFLiX email
          &amp; password. Alternatively, we made it so easy that you can log in
          with your FarmFLiX mobile app too.
        </Fragment>
      )
    }
  ];

  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | The FarmFLiX Fire TV Stick App is Here!</title>
        <meta
          name="description"
          content="Get the FarmFLiX Fire TV Stick App Now, available on the App Store and Google Play Store.  Download Now for Free."
        />
      </Helmet>
      <>
        {loggedIn && <HeaderInternal />}
        {!loggedIn && <Header />}
      </>
      <div className={classes.background}>
        <Container fixed fixedcomponent="main" maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.contentWrapper}
              display="flex"
            >
              <Grid container className={classes.alignCenter}>
                <Grid item xs={12}>
                  <Typography
                    component="h1"
                    variant="h1"
                    className={classes.title}
                  >
                    Watch FarmFLiX on the Big Screen
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    className={classes.titleSubHeading}
                  >
                    Now available on
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <img
                    src="https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/firetv_logo.png"
                    alt="Amazon Fire TV Logo"
                    className={classes.fireTvLogo}
                  />
                </Grid>
                <Grid item xs={12}>
                  <img
                    src="https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/tv_app.png"
                    alt="FarmFLiX on the Big Screen"
                    className={classes.tvAppImage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <a
                    href="https://www.amazon.co.uk/dp/B09SM6NFBV"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/amazon_appstore.png"
                      alt="FarmFLiX Fire TV App Logo"
                      className={classes.amazonAppstore}
                    />
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ marginBottom: "12px" }}>
                    Search <b>&apos;farmflix&apos;</b> on the Amazon Fire TV App
                    Store
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.backgroundAlt}>
        <Container fixed fixedcomponent="main" maxWidth="md">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.subtitle}>
                What is a Amazon Fire TV Stick?
              </Typography>
              <img
                src="https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/firetv_stick.jpg"
                alt="FarmFLiX Fire TV Stick"
                style={{ width: "80%", margin: "0 10%" }}
              />
              <Typography variant="body1" className={classes.alignCenter}>
                Amazon Fire TV is a video streaming device created by Amazon —
                it connects to a television or monitor and lets you watch TV
                shows and movies streamed over apps like Netflix, Hulu, Amazon
                Prime, and thousands of others.
                <br />
                <b>And FarmFLiX is now in this mix too!</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "36px" }}>
            <Grid item xs={12} sm={6}>
              <Divider className={classes.smlDivider} />
              <Typography variant="h3" className={classes.thirdtitle}>
                Where do I get one?
              </Typography>
              <Typography variant="body1" className={classes.alignCenter}>
                You can buy an Amazon Fire TV Stick directly from Amazon or
                other indepenet retailers.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Divider className={classes.smlDivider} />
              <Typography variant="h3" className={classes.thirdtitle}>
                How much are they?
              </Typography>
              <Typography variant="body1" className={classes.alignCenter}>
                Prices start from &pound;29.99
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/*<div className={classes.background}>
        <Container fixed fixedcomponent="main" maxWidth="md">
          <Grid container>
            <Grid item xs={12} className={classes.alignLeft}>
              <Typography variant="h3" className={classes.subtitle}>
                How do set my Fire TV up?
              </Typography>
              <Typography
                className={classes.installHeading}
                style={{ textAlign: "center" }}
              >
                Set up Video / Instructions
              </Typography>
               <Typography className={classes.installHeading}>
                2. Plug the USB Micro cable into the power adapter.
              </Typography>
              <Typography className={classes.installHeading}>
                3. Plug the other end into into the Fire TV Stick.
              </Typography>
              <Typography className={classes.installHeading}>
                4. Plug the Fire TV Stick into an HDMI port in your TV.
              </Typography> 
            </Grid>
          </Grid>
        </Container>
      </div>*/}
      <div className={classes.background}>
        <Container fixed fixedcomponent="main" maxWidth="md">
          <Grid container className={classes.helpSection}>
            <Grid item xs={12}>
              <Typography
                component="h3"
                variant="h3"
                className={classes.thirdtitle}
              >
                Need Help? Got a Question?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {faqs.map((q, i) => (
                <Accordion className={classes.accordionEntry} key={i}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        className={classes.accordionEntryExpand}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.accordionEntryTitle}>
                      {q.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.accordionEntryText}>
                      {q.body}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default FarmFLiXBigScreen;
