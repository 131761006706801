import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Box, Divider } from "@material-ui/core";
import grow_logos from "./grow_logos.png";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1
  },
  quoteText: {
    fontSize: "1.4rem",
    lineHeight: "1.2rem",
    color: theme.palette.common.dark
  },
  quoteName: {
    marginTop: theme.spacing(2),
    fontSize: "1.2rem",
    fontWeight: "600",
    color: theme.palette.common.dark
  },
  smlText: {
    marginTop: theme.spacing(2),
    fontSize: "0.7rem",
    fontWeight: "300",
    lineHeight: "0.9rem",
    color: theme.palette.common.dark
  },
  topLine: {
    backgroundColor: theme.palette.background.ffGreen,
    width: "20%",
    marginBottom: theme.spacing(4)
  },
  growLogo: {
    maxWidth: "400px",
    width: "100%"
  }
}));

export default function FundedBy() {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <Container fixed>
        <Grid container align="center" spacing={0}>
          <Grid item xs={12} sm={12}>
            <Box my={8} mx={4} align="center">
              <Divider className={classes.topLine} />
              <img src={grow_logos} className={classes.growLogo} alt="" />
              <Typography variant="h3" className={classes.smlText}>
                This website was developed with support from Priority 6 (LEADER)
                of the Northern Ireland Rural Development Programme 2014-2020 by
                the Department of Agriculture, Environment and Rural Affairs and
                the European Agricultural Fund for Rural Development: Europe
                investing in rural areas.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
