import React, { useContext } from "react";
import { AccountContext } from "./../../store/AccountContext";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useToasts } from "react-toast-notifications";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  bodyText: {
    color: theme.palette.background.ffNavy,
    "& span": {
      fontWeight: 500
    }
  },
  bodyList: {
    fontSize: "1rem",
    fontFamily: "Roboto, Helvetica Neue ,Arial,sans-serif",
    fontWeight: "400",
    lineHeight: "1.2rem",
    "& span": {
      fontWeight: 500
    }
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    "& span": {
      fontWeight: 500
    }
  },
  formContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "5% 0 2% 0"
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    color: theme.palette.background.ffNavy,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    marginLeft: 0,
    "@media (min-width:600px)": {
      width: "auto"
    }
  }
}));

export default function AutoRenewOff() {
  const classes = useStyles();
  const { addToast } = useToasts();

  // *** To handle future dates ***

  // Account Context
  const {
    handleCancelMembership,
    userProfile,
    updating,
    validateLogin
  } = useContext(AccountContext);

  const handleTurnOffAutoRenew = async () => {
    try {
      const cancelMembership = handleCancelMembership();

      if (cancelMembership) {
        // Add Toast - Success
        addToast("Auto Renew Disabled", {
          appearance: "success"
        });
        validateLogin();
      } else {
        addToast("Error - there was a problem", {
          appearance: "error"
        });
      }
    } catch (e) {
      addToast(e.toString(), {
        appearance: "error"
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumbContainer}
        >
          <Link className={classes.activeBreadcrumb} to="/myaccount/overview">
            Overview
          </Link>
          <Typography className={classes.disabledBreadcrumb}>
            Turn Off Auto Renew
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h2" className={classes.myAccountSubTitle}>
          Turn Off Auto Renew
        </Typography>
      </Grid>
      <Grid container className={classes.formContainer}>
        {!updating && (
          <>
            {userProfile.productAutoRenewing && (
              <>
                <Grid item xs={12} sm={10} md={10}>
                  <Typography variant="body1" className={classes.bodyText}>
                    By turning off Auto Renew, you will:
                    <br />
                  </Typography>
                  <ul className={classes.bodyList}>
                    <li>
                      still have access to FarmFLiX for the remainder of your
                      billing period, to{" "}
                      <span>
                        {userProfile.subscriptionExpiryDate &&
                          new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit"
                          }).format(
                            new Date(userProfile.subscriptionExpiryDate)
                          )}
                      </span>
                      . After this period, you will no longer has access to
                      FarmFLiX content.
                    </li>
                    <li>
                      no longer be charged after your billing period has
                      elapsed.
                    </li>
                  </ul>
                  <br />
                  <Typography variant="body1" className={classes.bodyText}>
                    <span>
                      Are you sure you wish to turn off Auto Renew on your
                      FarmFLiX Membership?
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    onClick={handleTurnOffAutoRenew}
                    className={classes.button}
                  >
                    Yes, Turn Off Auto Renew
                  </Button>
                  <Link
                    to="/myaccount/overview"
                    style={{ textDecoration: "none" }}
                  >
                    <Button variant="outlined" className={classes.button}>
                      No, What Am I Thinking!
                    </Button>
                  </Link>
                </Grid>
              </>
            )}
            {!userProfile.productAutoRenewing && (
              <>
                <Grid item xs={12} sm={10} md={10}>
                  <Typography variant="body1" className={classes.bodyText}>
                    Your Auto Renew has been successfully disabled.
                    <br />
                  </Typography>
                  <Link
                    to="/myaccount/overview"
                    style={{ textDecoration: "none" }}
                  >
                    <Button className={classes.button}>
                      Go to Account Overview
                    </Button>
                  </Link>
                </Grid>
              </>
            )}
          </>
        )}
        {updating && (
          <Box m={5} align="center">
            <CircularProgress variant="indeterminate"></CircularProgress>
            <Typography variant="body2" className={classes.bodyTextSml}>
              <br />
              Updating... Please do not refresh your browser.
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
