import React, { useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MemberSurveyContext } from "../../store/MemberSurveyContext";
import {
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Typography,
  Divider
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    // @ts-ignore
  },
  stepHeading: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    color: theme.palette.background.ffNavyLighter,
    fontWeight: 600,
    // textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    }
  },
  divider: {
    height: "1px",
    margin: "6px 0 12px 0",
    backgroundColor: "#b7b7b7"
  },
  formStep: {
    marginTop: "24px"
  },
  formQuestion: {
    marginBottom: "36px",
    "& .MuiFormControl-root": {
      width: "100%"
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem",
      marginBottom: "18px"
    },
    "& .MuiTypography-body1": {
      color: "#3d3d3d"
    }
  },
  formGroup: {
    "& .MuiFormControl-root label": {
      marginBottom: "6px"
    },
    "& .MuiTypography-body1": {
      marginBottom: "6px"
    }
  },
  buttonContainer: {
    margin: "24px 0"
  },
  stepButton: {
    border: "none",
    "&:hover": {
      border: "none",
      backgroundColor: "#99ca3c"
    }
  },
  stepButtonBack: {
    backgroundColor: "#fff",
    border: "2px solid #171717",
    "&:hover": {
      backgroundColor: "#fafafa",
      border: "2px solid #171717"
    }
  },
  topBump: {
    marginTop: "24px"
  }
}));

export default function SixthStep() {
  const classes = useStyles();

  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant
  } = useContext(MemberSurveyContext);
  const {
    appInstalled,
    appReliable,
    appEasyToUse,
    improveAppSuggestions,
    appCasting,
    appCastingReliable,
    appCastingEasyToUse,
    triedTvApp,
    triedTvAppReliable,
    triedTvAppEasyToUse,
    improveTvAppSuggestions,
    usedSearch,
    usedSearchResults,
    improveSearchSuggestions,
    useFavourites
  } = formValues;

  const isError = useCallback(
    () =>
      Object.keys({}).some(
        name =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues]
  );

  return (
    <>
      <Grid container spacing={2} className={classes.formStep}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.stepHeading}>
            The App
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        {/* Installed The App */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Have you installed the FarmFLiX app on your mobile?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="appInstalled"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Yes"
                checked={appInstalled.value === "Yes"}
                control={<Radio />}
                label="Yes"
                required={true}
              />
              <FormControlLabel
                value="No"
                checked={appInstalled.value === "No"}
                control={<Radio />}
                label="No"
                required={true}
              />
            </RadioGroup>
          </FormControl>

          {appInstalled.value === "Yes" && (
            <>
              {/* Is it reliable? */}
              <Grid item xs={12} sm={12} className={classes.topBump}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Is it reliable? (doesn’t crash)
                  </FormLabel>
                  <RadioGroup
                    onChange={handleChange}
                    name="appReliable"
                    className={classes.formGroup}
                  >
                    <FormControlLabel
                      value="Yes"
                      checked={appReliable.value === "Yes"}
                      control={<Radio />}
                      label="Yes"
                      required={true}
                    />
                    <FormControlLabel
                      value="No"
                      checked={appReliable.value === "No"}
                      control={<Radio />}
                      label="No"
                      required={true}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Easy to use? */}
              <Grid item xs={12} sm={12} className={classes.topBump}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Is it easy to use?
                  </FormLabel>
                  <RadioGroup
                    onChange={handleChange}
                    name="appEasyToUse"
                    className={classes.formGroup}
                  >
                    <FormControlLabel
                      value="Yes"
                      checked={appEasyToUse.value === "Yes"}
                      control={<Radio />}
                      label="Yes"
                      required={true}
                    />
                    <FormControlLabel
                      value="No"
                      checked={appEasyToUse.value === "No"}
                      control={<Radio />}
                      label="No"
                      required={true}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Suggestions for Improvement */}
              <Grid item xs={12} sm={12} className={classes.topBump}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Any suggestions to improve it?
                  </FormLabel>
                  <TextField
                    variant={variant}
                    fullWidth
                    name="improveAppSuggestions"
                    placeholder=""
                    value={improveAppSuggestions.value}
                    onChange={handleChange}
                    error={!!improveAppSuggestions.error}
                    helperText={improveAppSuggestions.error}
                    required={improveAppSuggestions.required}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>

        {/* Casting */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Have you tried casting FarmFLiX to your TV?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="appCasting"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Yes"
                checked={appCasting.value === "Yes"}
                control={<Radio />}
                label="Yes"
                required={true}
              />
              <FormControlLabel
                value="No"
                checked={appCasting.value === "No"}
                control={<Radio />}
                label="No"
                required={true}
              />
            </RadioGroup>
          </FormControl>

          {appCasting.value === "Yes" && (
            <>
              {/* Is it reliable? */}
              <Grid item xs={12} sm={12} className={classes.topBump}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Is it reliable? (doesn’t crash)
                  </FormLabel>
                  <RadioGroup
                    onChange={handleChange}
                    name="appCastingReliable"
                    className={classes.formGroup}
                  >
                    <FormControlLabel
                      value="Yes"
                      checked={appCastingReliable.value === "Yes"}
                      control={<Radio />}
                      label="Yes"
                      required={true}
                    />
                    <FormControlLabel
                      value="No"
                      checked={appCastingReliable.value === "No"}
                      control={<Radio />}
                      label="No"
                      required={true}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Easy to use? */}
              <Grid item xs={12} sm={12} className={classes.topBump}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Is it easy to use?
                  </FormLabel>
                  <RadioGroup
                    onChange={handleChange}
                    name="appCastingEasyToUse"
                    className={classes.formGroup}
                  >
                    <FormControlLabel
                      value="Yes"
                      checked={appCastingEasyToUse.value === "Yes"}
                      control={<Radio />}
                      label="Yes"
                      required={true}
                    />
                    <FormControlLabel
                      value="No"
                      checked={appCastingEasyToUse.value === "No"}
                      control={<Radio />}
                      label="No"
                      required={true}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>

        {/* TV App */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Have you tried our TV app for the FireStick / Android TV’s?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="triedTvApp"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Yes"
                checked={triedTvApp.value === "Yes"}
                control={<Radio />}
                label="Yes"
                required={true}
              />
              <FormControlLabel
                value="No"
                checked={triedTvApp.value === "No"}
                control={<Radio />}
                label="No"
                required={true}
              />
            </RadioGroup>
          </FormControl>

          {triedTvApp.value === "Yes" && (
            <>
              {/* Is it reliable? */}
              <Grid item xs={12} sm={12} className={classes.topBump}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Is it reliable? (doesn’t crash)
                  </FormLabel>
                  <RadioGroup
                    onChange={handleChange}
                    name="triedTvAppReliable"
                    className={classes.formGroup}
                  >
                    <FormControlLabel
                      value="Yes"
                      checked={triedTvAppReliable.value === "Yes"}
                      control={<Radio />}
                      label="Yes"
                      required={true}
                    />
                    <FormControlLabel
                      value="No"
                      checked={triedTvAppReliable.value === "No"}
                      control={<Radio />}
                      label="No"
                      required={true}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Easy to use? */}
              <Grid item xs={12} sm={12} className={classes.topBump}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Is it easy to use?
                  </FormLabel>
                  <RadioGroup
                    onChange={handleChange}
                    name="triedTvAppEasyToUse"
                    className={classes.formGroup}
                  >
                    <FormControlLabel
                      value="Yes"
                      checked={triedTvAppEasyToUse.value === "Yes"}
                      control={<Radio />}
                      label="Yes"
                      required={true}
                    />
                    <FormControlLabel
                      value="No"
                      checked={triedTvAppEasyToUse.value === "No"}
                      control={<Radio />}
                      label="No"
                      required={true}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/* Suggestions for Improvement */}
              <Grid item xs={12} sm={12} className={classes.topBump}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Any suggestions to improve it?
                  </FormLabel>
                  <TextField
                    variant={variant}
                    fullWidth
                    name="improveTvAppSuggestions"
                    placeholder=""
                    value={improveTvAppSuggestions.value}
                    onChange={handleChange}
                    error={!!improveTvAppSuggestions.error}
                    helperText={improveTvAppSuggestions.error}
                    required={improveTvAppSuggestions.required}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>

        {/* Search Engine */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Have you used our episode search engine and search filters?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="usedSearch"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Yes"
                checked={usedSearch.value === "Yes"}
                control={<Radio />}
                label="Yes"
                required={true}
              />
              <FormControlLabel
                value="No"
                checked={usedSearch.value === "No"}
                control={<Radio />}
                label="No"
                required={true}
              />
            </RadioGroup>
          </FormControl>

          {usedSearch.value === "Yes" && (
            <>
              {/* Results return what's expected? */}
              <Grid item xs={12} sm={12} className={classes.topBump}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Does it return results you expect?
                  </FormLabel>
                  <RadioGroup
                    onChange={handleChange}
                    name="usedSearchResults"
                    className={classes.formGroup}
                  >
                    <FormControlLabel
                      value="Yes"
                      checked={usedSearchResults.value === "Yes"}
                      control={<Radio />}
                      label="Yes"
                      required={true}
                    />
                    <FormControlLabel
                      value="No"
                      checked={usedSearchResults.value === "No"}
                      control={<Radio />}
                      label="No"
                      required={true}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Suggestions for Improvement */}
              <Grid item xs={12} sm={12} className={classes.topBump}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Any suggestions to improve it?
                  </FormLabel>
                  <TextField
                    variant={variant}
                    fullWidth
                    name="improveSearchSuggestions"
                    placeholder=""
                    value={improveSearchSuggestions.value}
                    onChange={handleChange}
                    error={!!improveSearchSuggestions.error}
                    helperText={improveSearchSuggestions.error}
                    required={improveSearchSuggestions.required}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>

        {/* Favourites */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              We use the favourites list as good feedback to influence our
              production team - have you used the favourites feature to make
              sure we know what you like?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="useFavourites"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Yes"
                checked={useFavourites.value === "Yes"}
                control={<Radio />}
                label="Yes"
                required={true}
              />
              <FormControlLabel
                value="No"
                checked={useFavourites.value === "No"}
                control={<Radio />}
                label="No"
                required={true}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.buttonContainer}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 3
          }}
        >
          <Button onClick={handleBack} className={classes.stepButtonBack}>
            Back
          </Button>
          <Button
            className={classes.stepButton}
            variant="contained"
            disabled={isError()}
            color="primary"
            onClick={!isError() ? handleNext : () => null}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </>
  );
}
