import React, { useEffect } from "react";
import { MarketingOptOutPrefsResponse } from "@booyaltd/core";
import {
  getMarketingOptOutPrefs,
  updateMarketingOptOutPrefs
} from "../../api/client";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4)
  },
  title: {},
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  body: {
    marginBottom: theme.spacing(2)
  },
  note: {
    marginBottom: theme.spacing(2)
  },
  checkbox: {},
  checkboxLabel: {},
  buttonContainer: {
    justifyContent: "center",
    textAlign: "center"
  },
  messageContainer: {
    justifyContent: "center",
    textAlign: "center"
  },
  button: {
    alignSelf: "center",
    justifySelf: "center"
  }
}));

const useEmbeddedStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4)
  },
  title: {
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0)
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2)
  },
  body: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2)
  },
  note: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2)
  },
  checkbox: {},
  checkboxLabel: {
    color: theme.palette.text.secondary
  },
  buttonContainer: {
    justifyContent: "center",
    textAlign: "center"
  },
  messageContainer: {
    justifyContent: "center",
    textAlign: "center"
  },
  button: {
    marginBottom: theme.spacing(2),
    alignSelf: "center",
    justifySelf: "center"
  }
}));

type MarketingPreferencesFormProps = {
  unsubscribeToken: string | undefined;
  embedded: boolean;
  onClose?: () => void;
};

const MarketingPreferencesForm = ({
  unsubscribeToken,
  embedded,
  onClose
}: MarketingPreferencesFormProps) => {
  const classes = (embedded ? useEmbeddedStyles : useStyles)();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [saved, setSaved] = React.useState(false);

  const [originalPrefs, setOriginalPrefs] = React.useState<
    MarketingOptOutPrefsResponse
  >();
  const [updatedPrefs, setUpdatedPrefs] = React.useState<
    MarketingOptOutPrefsResponse
  >();

  useEffect(() => {
    setUpdatedPrefs(originalPrefs);
  }, [originalPrefs]);

  useEffect(() => {
    if (unsubscribeToken) {
      setSaved(false);
      setLoading(true);
      setOriginalPrefs(undefined);
      setError(undefined);
      getMarketingOptOutPrefs(unsubscribeToken)
        .then(setOriginalPrefs)
        .catch(() => {
          setError("Invalid token provided");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [unsubscribeToken]);

  const onClickEmailPref = () => {
    if (!updatedPrefs) return;

    setUpdatedPrefs({
      ...updatedPrefs,
      emailMarketingRejected: !updatedPrefs.emailMarketingRejected
    });
  };
  const onClickSmsPref = () => {
    if (!updatedPrefs) return;

    setUpdatedPrefs({
      ...updatedPrefs,
      smsMarketingRejected: !updatedPrefs.smsMarketingRejected
    });
  };

  const onClickSave = () => {
    if (!updatedPrefs || !unsubscribeToken) {
      return;
    }

    setLoading(true);
    setError(undefined);

    updateMarketingOptOutPrefs(unsubscribeToken, updatedPrefs)
      .then(result => {
        setOriginalPrefs(result);
        setSaved(true);
        if (onClose) {
          onClose();
        }
      })
      .catch(() => {
        setError("Error saving preferences");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container maxWidth="sm" className={classes.container}>
        <Grid container spacing={3}>
          {error ? (
            <>
              <Grid item xs={12} className={classes.messageContainer}>
                <Typography variant="h3" className={classes.title}>
                  Oops!
                </Typography>

                <Typography variant="body1" className={classes.body}>
                  Looks like the link you are using is invalid, please check
                  your email for the correct link, or contact support if this
                  problem continues.
                </Typography>
              </Grid>
            </>
          ) : saved ? (
            <>
              <Grid item xs={12} className={classes.messageContainer}>
                <Typography variant="h3" className={classes.title}>
                  Thank You
                </Typography>

                <Typography variant="subtitle1">
                  Your preferences have been saved.
                </Typography>
              </Grid>
            </>
          ) : updatedPrefs ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h3" className={classes.title}>
                  Marketing Preferences
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.subtitle}>
                  SMS
                </Typography>

                <Typography variant="body1" className={classes.body}>
                  Check this box if want to receive SMS messages from us with
                  marketing content
                </Typography>

                <Typography variant="body1" className={classes.note}>
                  Note: We will still send you transactional SMS messages, such
                  as login codes and billing notifications.
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!updatedPrefs.smsMarketingRejected}
                      name="smsMarketingRejected"
                      disabled={loading}
                      onClick={onClickSmsPref}
                    />
                  }
                  label={
                    <Typography className={classes.checkboxLabel}>
                      I want to receive SMS messages
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.subtitle}>
                  Email
                </Typography>
                <Typography variant="body1" className={classes.body}>
                  Check this box if want to receive emails from us to notify you
                  of our latest content and other marketing content
                </Typography>
                <Typography variant="body1" className={classes.note}>
                  Note: We will still send you transactional emails, such as
                  password reset emails and billing notifications.
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!updatedPrefs.emailMarketingRejected}
                      name={"emailMarketingRejected"}
                      disabled={loading}
                      onClick={onClickEmailPref}
                    />
                  }
                  label={
                    <Typography className={classes.checkboxLabel}>
                      I want to receive Email
                    </Typography>
                  }
                />
              </Grid>
            </>
          ) : null}

          <Grid item xs={12} className={classes.buttonContainer}>
            {updatedPrefs && !saved && !error ? (
              <Button
                onClick={onClickSave}
                disabled={loading}
                className={classes.button}
              >
                Save Marketing Preferences
              </Button>
            ) : null}
            {onClose ? (
              <Button
                onClick={onClose}
                disabled={loading}
                className={classes.button}
              >
                Close
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MarketingPreferencesForm;
