import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";
import React, { useCallback, useRef } from "react";
import CountryData from "./CountryData.js";

const useStyles = makeStyles(theme => ({
  control: {
    backgroundColor: "#666",
    borderRadius: "5px"
  },
  select: {
    // backgroundColor: "#666",
    color: "white",
    // fontWeight: 500,
    "& li": {
      // fontSize: "0.95rem",
      // padding: theme.spacing(1, 2),
      "&:hover": {
        // backgroundColor: "#555"
      }
    }
  },
  menuItem: {
    backgroundColor: "#444"
  }
}));

type Props = { onChange: (country: Country | undefined) => void };

export type Country = { name: string; countryCode: string; iso2: string };

const CountryDropdown = ({ onChange: onChangeRaw }: Props) => {
  const classes = useStyles();
  const { preferredCountries, onlyCountries } = useRef(
    new CountryData(
      false,
      true,
      "",
      [],
      ["gb", "ie", "us", "au", "nz", "ca"],
      ["je"],
      [],
      null,
      null,
      null,
      {},
      "+",
      "... ... ... ... ..",
      false
    )
  ).current;

  const countries: Country[] = preferredCountries.concat(onlyCountries);

  const onChange = useCallback(
    (iso2: string | unknown) => {
      onChangeRaw(countries.find(c => c.iso2 === iso2));
    },
    [countries, onChangeRaw]
  );

  const renderItem = useCallback(
    (country: Country) => (
      <MenuItem
        key={country.iso2}
        value={country.iso2}
        className={classes.menuItem}
      >
        <img
          style={{ marginRight: "10px" }}
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${country.iso2.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${country.iso2.toLowerCase()}.png 2x`}
          alt=""
        />
        {` ${country.name} (+${country.countryCode})`}
      </MenuItem>
    ),
    [classes.menuItem]
  );

  return (
    <FormControl fullWidth className={classes.control}>
      <Select
        labelId="select-country"
        displayEmpty={true}
        renderValue={(value: unknown) =>
          value ? (value as string) : "Select Country"
        }
        className={classes.select}
        fullWidth={true}
        onChange={(e, country) => {
          console.log("Selected Country", country);
          onChange(e.target?.value as string | undefined);
        }}
      >
        {countries.map(renderItem)}
      </Select>
    </FormControl>
  );
};

export default CountryDropdown;
