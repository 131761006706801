import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  Grid,
  Box,
  CircularProgress,
  Button
} from "@material-ui/core";
import LoggedInLayout from "../layouts/LoggedInLayout";
import HistoryRoundedIcon from "@material-ui/icons/HistoryRounded";
import { Helmet } from "react-helmet";
import useVideoTypeSearch from "../hooks/useVideoTypeSearch";
import {
  VideoSearchParamIsReady,
  VideoSearchResult,
  VideoSort,
  VideoType
} from "@booyaltd/core";
import dayjs from "dayjs";
import VideoRow from "./Video/VideoRow";
import { Link } from "react-router-dom";
import LoadMoreButton from "./Search/LoadMoreButton";

const useStyles = makeStyles(theme => ({
  backgroundSection: {
    height: "300px",
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "800px"
    }
  },
  headerSection: {
    margin: theme.spacing(3, 0, 3, 0),
    "@media (min-width:600px)": {
      margin: theme.spacing(4, 0, 3, 0)
    },
    "@media (min-width:800px)": {
      margin: theme.spacing(8, 0, 2, 0)
    }
  },
  historyIcon: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontSize: "3rem",
    marginRight: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "3.4rem",
      marginRight: theme.spacing(1)
    },
    "@media (min-width:800px)": {
      fontSize: "4rem",
      marginRight: theme.spacing(2)
    }
  },
  listSection: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  dateHeading: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontWeight: 500,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  link: {
    textDecoration: "none"
  },
  episodeDetails: {
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      padding: theme.spacing(2)
    }
  }
}));

type Sections = {
  thisWeek: VideoSearchResult[];
  lastWeek: VideoSearchResult[];
  lastMonth: VideoSearchResult[];
  older: VideoSearchResult[];
};

type WatchHistorySectionProps = { title: string; videos: VideoSearchResult[] };

const WatchHistorySection = ({ title, videos }: WatchHistorySectionProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.dateHeading}>
          {title}
        </Typography>
      </Grid>
      {videos.map((video, idx) => (
        <VideoRow idx={idx} key={video.id} video={video} listType="playlist" />
      ))}
    </>
  );
};

const WatchHistory = () => {
  const classes = useStyles();

  const {
    searchResponse,
    searchLoading,
    canLoadMore,
    loadMore
  } = useVideoTypeSearch({
    type: VideoType.Episode,
    isReady: VideoSearchParamIsReady.Master,
    watched: true,
    sort: VideoSort.FavouritedDate,
    includes: ["watched", "favourited", "progress"]
  });
  const sections = useMemo(() => {
    const now = dayjs(Date.now());
    const thisWeek = dayjs(now).subtract(1, "week");
    const lastWeek = dayjs(now).subtract(2, "week");
    const lastMonth = dayjs(now).subtract(1, "month");
    return searchResponse?.results?.reduce<Sections>(
      (sections: Sections, video: VideoSearchResult): Sections => {
        const watched = dayjs(video.watched);
        if (watched.isAfter(thisWeek)) {
          sections.thisWeek.push(video);
        } else if (watched.isAfter(lastWeek)) {
          sections.lastWeek.push(video);
        } else if (watched.isAfter(lastMonth)) {
          sections.lastMonth.push(video);
        } else {
          sections.older.push(video);
        }

        return sections;
      },
      { thisWeek: [], lastWeek: [], lastMonth: [], older: [] }
    );
  }, [searchResponse]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Watch History</title>
        <meta name="description" content="Your FarmFLiX Watch History" />
      </Helmet>
      <LoggedInLayout>
        <Container>
          <Grid container className={classes.headerSection}>
            <Grid item xs={12} sm={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap"
                }}
              >
                <HistoryRoundedIcon className={classes.historyIcon} />
                <Typography variant="h1">Watch History</Typography>
              </div>
            </Grid>
          </Grid>

          {searchLoading ? (
            <Grid item xs={12} alignItems="center" alignContent="center">
              <Box mt={5}>
                <CircularProgress />
              </Box>
            </Grid>
          ) : null}

          {/* Error State */}
          {/* <Grid item xs={12} align="center">
              <Box mt={5}>
                <WarningRoundedIcon color="primary" style={{ fontSize: 50 }} />
                <Typography variant="h4">
                  Error loading your Watch History.
                </Typography>
              </Box>
            </Grid> */}

          {/* Videos Total = 0 */}
          {!searchResponse?.total ? (
            <Grid item xs={12} alignContent="center">
              <Box mt={5}>
                <Typography variant="h4">
                  You haven&apos;t watched anything yet.
                </Typography>
                <Link to="/videos" className={classes.link}>
                  <Button size="large" className={classes.button}>
                    Watch Now!
                  </Button>
                </Link>
              </Box>
            </Grid>
          ) : (
            <Grid container className={classes.listSection}>
              {sections && sections.thisWeek.length > 0 ? (
                <WatchHistorySection
                  title="This Week"
                  videos={sections.thisWeek}
                />
              ) : null}
              {sections && sections.lastWeek.length > 0 ? (
                <WatchHistorySection
                  title="Last Week"
                  videos={sections.lastWeek}
                />
              ) : null}
              {sections && sections.lastMonth.length > 0 ? (
                <WatchHistorySection
                  title="This Month"
                  videos={sections.lastWeek}
                />
              ) : null}
              {sections && sections.older.length > 0 ? (
                <WatchHistorySection
                  title="More Than a Month"
                  videos={sections.older}
                />
              ) : null}

              <LoadMoreButton
                canLoadMore={canLoadMore}
                loading={searchLoading}
                loadMore={loadMore}
              />
            </Grid>
          )}
        </Container>
      </LoggedInLayout>
    </div>
  );
};

export default WatchHistory;
