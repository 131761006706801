import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Typography
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Form } from "react-final-form";
import Alert from "@material-ui/lab/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { createInterestApplication } from "../../api/client";

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  findOutMoreButton: {
    textAlign: "center",
    backgroundColor: "inherit",
    borderColor: "transparent"
  },
  dialogIcon: {
    margin: "0 auto",
    fontSize: "4rem",
    // @ts-ignore
    fill: theme.palette.background.ffGreen
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(0, 0, 2, 0),
    width: "100%",
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  dialogSubmit: {
    margin: theme.spacing(0, 0, 3, 0),
    width: "100%"
  },
  phoneInput: {
    width: "inherit !important"
  },
  formFieldSet: {
    margin: theme.spacing(0, 0, 1, 0),
    "& label": {
      width: "auto",
      fontSize: "26px"
    },
    "& input": {
      padding: "14px 10px",
      fontSize: "1rem"
    },
    "& .react-tel-input .flag-dropdown:before": {
      fontSize: "15px !important",
      top: "-29px !important",
      content: "'' !important",
      color: "#0000008a !important",
      display: "none"
    },
    "& .react-tel-input .form-control": {
      fontSize: "16px !important"
    }
  },
  submitLoader: {
    height: "22px !important",
    width: "22px !important",
    color: theme.palette.common.white
  },
  dialogTextContainer: {
    marginBottom: 0,
    color: "#171717"
  },
  dialogText: {
    color: "#0000008a",
    textAlign: "center",
    marginBottom: "12px"
  },
  referralAlert: {
    backgroundColor: "#e4e4e4",
    color: "#636363",
    "& a": {
      fontWeight: 600,
      color: "#636363"
    }
  }
}));

const DoorsClosedNotifyMe = (props: {
  open: boolean;
  type: "notify" | "trial";
  closed: () => void;
}) => {
  const classes = useStyles();
  const [error, setError] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submit = () => {
    if (!countryCode || !phoneNumber) {
      setError("Whoops! You haven't provided a phone number.");
      return;
    }

    setSuccess(false);
    setLoading(true);

    createInterestApplication({
      countryCode,
      phoneNumber
    })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError("Oops! Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (value: string, { dialCode }: { dialCode: string }) => {
    setError(undefined);
    setPhoneNumber(value.substring(dialCode.length).replace(/^0+/, ""));
    setCountryCode(dialCode);
  };

  return (
    <Dialog
      maxWidth={"xs"}
      open={props.open}
      onClose={props.closed}
      aria-labelledby="max-width-dialog-title"
    >
      <MuiDialogTitle
        id="customized-dialog-title"
        disableTypography
        className={classes.root}
      >
        {props.closed ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.closed}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <DialogContent>
        {success && (
          <>
            <DialogContentText style={{ textAlign: "center" }}>
              <CheckCircleIcon className={classes.dialogIcon} />
            </DialogContentText>
            <DialogContentText style={{ textAlign: "center" }}>
              <b>Your details have been saved</b>
            </DialogContentText>
            <DialogContentText style={{ textAlign: "center" }}>
              We&apos;ll notify you when the Membership Doors Open.
            </DialogContentText>
          </>
        )}
        {!success && (
          <>
            <DialogContentText style={{ textAlign: "center" }}>
              <NotificationsActiveIcon className={classes.dialogIcon} />
            </DialogContentText>
            <DialogContentText className={classes.dialogTextContainer}>
              {props.type === "trial" ? (
                <Typography variant="body2" className={classes.dialogText}>
                  We&apos;re not taking on new members at present as we&apos;re
                  focussing on what we do best - out and about capturing great
                  content.
                </Typography>
              ) : (
                ""
              )}
              <Typography
                variant="body1"
                className={classes.dialogText}
                style={{ marginBottom: "6px" }}
              >
                <b>Notify me when the Doors are Open</b>
              </Typography>
            </DialogContentText>
            <Grid container>
              <Grid item xs={12}>
                <Form onSubmit={submit}>
                  {props => (
                    <form onSubmit={props.handleSubmit}>
                      <div className={classes.form}>
                        {error && (
                          <Alert
                            variant="filled"
                            severity="error"
                            style={{ margin: "10px 0" }}
                          >
                            {error}
                          </Alert>
                        )}
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={classes.formFieldSet}
                          >
                            <PhoneInput
                              inputClass={classes.phoneInput}
                              inputProps={{
                                name: "mobileNumber",
                                required: true
                              }}
                              enableLongNumbers={true}
                              countryCodeEditable={false}
                              autoFormat={false}
                              enableTerritories={true}
                              country="gb"
                              placeholder="Mobile Number"
                              aria-label="Mobile Number"
                              preferredCountries={[
                                "gb",
                                "ie",
                                "us",
                                "au",
                                "nz"
                              ]}
                              excludeCountries={["je"]}
                              onChange={onChange}
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Button
                              type="submit"
                              size="large"
                              fullWidth
                              className={classes.dialogSubmit}
                              disabled={loading}
                            >
                              {!loading && <>Submit</>}
                              {loading && (
                                <>
                                  <CircularProgress
                                    className={classes.submitLoader}
                                  />
                                </>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  )}
                </Form>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DoorsClosedNotifyMe;
