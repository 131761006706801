import React from "react";
import { PlayerProps } from "./types";
import { OtherPlayer } from "./OtherPlayer";
import BrightcovePlayer from "./BrightcovePlayer";

export const Player = (props: PlayerProps) => {
  const provider =
    props.type === "master"
      ? props.video.masterProvider
      : props.video.previewProvider;

  if (provider === "brightcove") {
    return <BrightcovePlayer {...props} />;
  }

  return <OtherPlayer {...props} />;
};
