import React from "react";
import { AccountProvider } from "./AccountContext";
import { OverviewProvider } from "./OverviewContext";
import { PlayerProvider } from "./PlayerContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_CLIENT_SECRET } from "../env";
import { LoggerProvider } from "./LoggerContext";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_CLIENT_SECRET);

// eslint-disable-next-line
const Store = ({ children }: StoreProps) => (
  // @ts-ignore
  <Elements stripe={stripePromise} hidePostalCode={true}>
    <AccountProvider>
      <LoggerProvider>
        <PlayerProvider>
          <OverviewProvider>{children}</OverviewProvider>
        </PlayerProvider>
      </LoggerProvider>
    </AccountProvider>
  </Elements>
);

interface StoreProps {
  children?: any;
}

export default Store;
