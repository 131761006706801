import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { OverviewContext } from "../store/OverviewContext";
import { Link } from "react-router-dom";
import logo from "./Header/nav-logo-internal.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import LoggedInLayout from "../layouts/LoggedInLayout";
// import ReactPlayerLoader from "@brightcove/react-player-loader";
// import { Player } from "./common/player";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from "../store/LoggerContext";
// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.ffNavyLighter
  },
  notificationsContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  notificationsGridContainer: {
    padding: theme.spacing(2, 0)
  },
  notificationsHeading: {
    color: theme.palette.common.white,
    fontSize: "2.2rem",
    textAlign: "left",
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    },
    "& a": {
      color: theme.palette.common.white,
      textDecoration: "none"
    }
  },
  notificationsSubTitle: {
    color: theme.palette.common.white,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  sectionDivider: {
    height: "1px",
    backgroundColor: "#444"
  },
  notificationWrapper: {
    borderBottom: "1px solid #444",
    padding: theme.spacing(1),
    position: "relative",
    "& a": {
      textDecoration: "none"
    },
    "&:hover": {
      backgroundColor: "#444444",
      cursor: "pointer",
      textDecoration: "none"
    }
  },
  notificationTitle: {
    fontSize: "1.1rem",
    lineHeight: "1.3rem",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    color: theme.palette.common.white
  },
  notificationDesc: {
    fontSize: "0.9rem",
    lineHeight: "1rem",
    marginBottom: theme.spacing(1),
    color: "#e9e9e9"
  },
  notificationDate: {
    fontSize: "0.8rem",
    color: "#8e8e8e",
    marginBottom: theme.spacing(1)
    // "& span":{
    //   textDecoration: "underline"
    // }
  },
  notificationImagePlaceholder: {
    backgroundColor: theme.palette.background.ffNavy,
    textAlign: "center",
    padding: theme.spacing(2.5, 1, 2.5, 1),
    width: "44px",
    height: "20px",
    "& img": {
      width: "100%"
    }
  },
  notificationUnreadIcon: {
    color: theme.palette.background.ffGreen,
    fontWeight: 600,
    margin: theme.spacing(0.5, 1, 0, 0)
  },
  notificationLink: {
    color: theme.palette.background.ffGreen,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  singleNotificationImage: {
    width: "100%",
    height: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  singleNotificationTitle: {
    fontSize: "1.5rem",
    lineHeight: "1.7rem",
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.common.white
  },
  singleNotificationDesc: {
    fontSize: "1rem",
    lineHeight: "1.1rem",
    marginBottom: theme.spacing(1),
    color: "#e9e9e9"
  },
  singleNotificationDate: {
    fontSize: "0.8rem",
    color: "#8e8e8e",
    marginBottom: theme.spacing(1),
    "& span": {
      textDecoration: "underline"
    }
  },
  singleNotificationVideoContainer: {
    margin: theme.spacing(2, 0)
  }
}));

export default function Inbox() {
  const classes = useStyles();

  // State
  const [showSingleNotification, setShowSingleNotification] = useState(false);
  const [singleNotification, setSingleNotification] = useState(null);

  const {
    overview,
    notificationsRead,
    isNewNotification,
    loading
  } = useContext(OverviewContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    return notificationsRead;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowSingleNotification = notification => {
    setShowSingleNotification(true);
    setSingleNotification(notification);

    try {
      if (notification.clickEvent) {
        console.log("Log event", notification.clickEvent);
        logEvent(getAnalytics(firebaseApp), notification.clickEvent);
      }
    } catch (e) {
      console.error("Error logging notification click to firebase");
    }
  };

  const handleShowNotificationsList = () => {
    setSingleNotification(null);
    setShowSingleNotification(false);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Notification Centre</title>
        <meta name="description" content="" />
      </Helmet>
      <LoggedInLayout>
        <div className={classes.background}>
          <Container
            fixed
            fixedcomponent="main"
            maxWidth="sm"
            className={classes.notificationsContainer}
          >
            <Grid container className={classes.notificationsGridContainer}>
              <Grid item xs={12}>
                <Typography
                  variant="h1"
                  className={classes.notificationsHeading}
                >
                  Notifications
                </Typography>
                <Divider
                  className={classes.sectionDivider}
                  style={{ marginBottom: "20px" }}
                />
              </Grid>
              {!loading && (
                <>
                  {!showSingleNotification && (
                    <>
                      {overview &&
                        overview.notifications &&
                        overview.notifications.length === 0 && (
                          <>
                            <Grid item xs={12}>
                              <Typography className={classes.notificationTitle}>
                                No New Notifications
                              </Typography>
                            </Grid>
                          </>
                        )}
                      {overview &&
                        overview.notifications &&
                        overview.notifications.length > 0 && (
                          <>
                            {overview.notifications.map(
                              (notification, index) => (
                                <Grid
                                  key={notification.id}
                                  item
                                  xs={12}
                                  className={classes.notificationWrapper}
                                >
                                  <Grid container>
                                    <Grid item xs={12}>
                                      {/* Start of Links */}

                                      {/* No Link URL and No Video ID attached */}
                                      {!notification.linkUrl &&
                                        !notification.videoReferenceId && (
                                          <div style={{ width: "100%" }}>
                                            <Box
                                              display="flex"
                                              onClick={() =>
                                                handleShowSingleNotification(
                                                  notification
                                                )
                                              }
                                            >
                                              <Box p={0.5}>
                                                {notification.imageUrl && (
                                                  <img
                                                    src={notification.imageUrl}
                                                    alt=""
                                                    style={{
                                                      width: "60px",
                                                      height: "60px",
                                                      objectFit: "cover"
                                                    }}
                                                  />
                                                )}
                                                {!notification.imageUrl && (
                                                  <div
                                                    className={
                                                      classes.notificationImagePlaceholder
                                                    }
                                                  >
                                                    <img src={logo} alt="" />
                                                  </div>
                                                )}
                                              </Box>
                                              <Box
                                                style={{
                                                  padding: "8px 16px 8px 8px"
                                                }}
                                                flexGrow={1}
                                              >
                                                <Typography
                                                  className={
                                                    classes.notificationTitle
                                                  }
                                                >
                                                  {notification.title}
                                                </Typography>
                                                <Typography
                                                  className={
                                                    classes.notificationDesc
                                                  }
                                                >
                                                  {notification.message}
                                                </Typography>
                                                <Typography
                                                  className={
                                                    classes.notificationDate
                                                  }
                                                >
                                                  <b>
                                                    {notification.activeDate &&
                                                      new Intl.DateTimeFormat(
                                                        "en-GB",
                                                        {
                                                          year: "numeric",
                                                          month: "long",
                                                          day: "2-digit"
                                                        }
                                                      ).format(
                                                        new Date(
                                                          notification.activeDate
                                                        )
                                                      )}
                                                  </b>{" "}
                                                  by <span>FarmFLiX</span>
                                                </Typography>
                                              </Box>
                                              {isNewNotification(
                                                notification
                                              ) && (
                                                <Box p={0.5}>
                                                  <Typography
                                                    variant="body2"
                                                    className={
                                                      classes.notificationUnreadIcon
                                                    }
                                                  >
                                                    NEW
                                                  </Typography>
                                                </Box>
                                              )}
                                            </Box>
                                          </div>
                                        )}

                                      {/* External Link - No Video ID */}
                                      {notification.linkUrl &&
                                        !notification.videoReferenceId && (
                                          <a
                                            href={notification.linkUrl}
                                            className={classes.notificationLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <div style={{ width: "100%" }}>
                                              <Box display="flex">
                                                <Box p={0.5}>
                                                  {notification.imageUrl && (
                                                    <img
                                                      src={
                                                        notification.imageUrl
                                                      }
                                                      alt=""
                                                      style={{
                                                        width: "60px",
                                                        height: "60px",
                                                        objectFit: "cover"
                                                      }}
                                                    />
                                                  )}
                                                  {!notification.imageUrl && (
                                                    <div
                                                      className={
                                                        classes.notificationImagePlaceholder
                                                      }
                                                    >
                                                      <img src={logo} alt="" />
                                                    </div>
                                                  )}
                                                </Box>
                                                <Box
                                                  style={{
                                                    padding: "8px 16px 8px 8px"
                                                  }}
                                                  flexGrow={1}
                                                >
                                                  <Typography
                                                    className={
                                                      classes.notificationTitle
                                                    }
                                                  >
                                                    {notification.title}
                                                  </Typography>
                                                  <Typography
                                                    className={
                                                      classes.notificationDesc
                                                    }
                                                  >
                                                    {notification.message}
                                                  </Typography>
                                                  <Typography
                                                    className={
                                                      classes.notificationDate
                                                    }
                                                  >
                                                    <b>
                                                      {notification.activeDate &&
                                                        new Intl.DateTimeFormat(
                                                          "en-GB",
                                                          {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "2-digit"
                                                          }
                                                        ).format(
                                                          new Date(
                                                            notification.activeDate
                                                          )
                                                        )}
                                                    </b>{" "}
                                                    by <span>FarmFLiX</span>
                                                  </Typography>
                                                </Box>
                                                {isNewNotification(
                                                  notification
                                                ) && (
                                                  <Box p={0.5}>
                                                    <Typography
                                                      variant="body2"
                                                      className={
                                                        classes.notificationUnreadIcon
                                                      }
                                                    >
                                                      NEW
                                                    </Typography>
                                                  </Box>
                                                )}
                                              </Box>
                                            </div>
                                          </a>
                                        )}
                                      {/* Video ID - No External Link */}
                                      {notification.videoReferenceId &&
                                        !notification.linkUrl && (
                                          <Link
                                            to={
                                              "/videos/" +
                                              notification.tag +
                                              "/" +
                                              notification.videoReferenceId
                                            }
                                            className={classes.notificationLink}
                                          >
                                            <div style={{ width: "100%" }}>
                                              <Box display="flex">
                                                <Box p={0.5}>
                                                  {notification.imageUrl && (
                                                    <img
                                                      src={
                                                        notification.imageUrl
                                                      }
                                                      alt=""
                                                      style={{
                                                        width: "60px",
                                                        height: "60px",
                                                        objectFit: "cover"
                                                      }}
                                                    />
                                                  )}
                                                  {!notification.imageUrl && (
                                                    <div
                                                      className={
                                                        classes.notificationImagePlaceholder
                                                      }
                                                    >
                                                      <img src={logo} alt="" />
                                                    </div>
                                                  )}
                                                </Box>
                                                <Box
                                                  style={{
                                                    padding: "8px 16px 8px 8px"
                                                  }}
                                                  flexGrow={1}
                                                >
                                                  <Typography
                                                    className={
                                                      classes.notificationTitle
                                                    }
                                                  >
                                                    {notification.title}
                                                  </Typography>
                                                  <Typography
                                                    className={
                                                      classes.notificationDesc
                                                    }
                                                  >
                                                    {notification.message}
                                                  </Typography>
                                                  <Typography
                                                    className={
                                                      classes.notificationDate
                                                    }
                                                  >
                                                    <b>
                                                      {notification.activeDate &&
                                                        new Intl.DateTimeFormat(
                                                          "en-GB",
                                                          {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "2-digit"
                                                          }
                                                        ).format(
                                                          new Date(
                                                            notification.activeDate
                                                          )
                                                        )}
                                                    </b>{" "}
                                                    by <span>FarmFLiX</span>
                                                  </Typography>
                                                </Box>
                                                {isNewNotification(
                                                  notification
                                                ) && (
                                                  <Box p={0.5}>
                                                    <Typography
                                                      variant="body2"
                                                      className={
                                                        classes.notificationUnreadIcon
                                                      }
                                                    >
                                                      NEW
                                                    </Typography>
                                                  </Box>
                                                )}
                                              </Box>
                                            </div>
                                          </Link>
                                        )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </>
                        )}
                      {overview && !overview.notifications && (
                        <Typography className={classes.notificationTitle}>
                          Loading...
                        </Typography>
                      )}
                    </>
                  )}
                  {showSingleNotification && singleNotification && (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.notificationLink}
                        onClick={handleShowNotificationsList}
                      >
                        &lt; Back
                      </Typography>
                      <Typography className={classes.singleNotificationTitle}>
                        {singleNotification.title}
                      </Typography>
                      {singleNotification.imageUrl &&
                        !singleNotification.video && (
                          <img
                            src={singleNotification.imageUrl}
                            alt=""
                            className={classes.singleNotificationImage}
                          />
                        )}
                      {/* {singleNotification.video &&
                        singleNotification.video.masterProviderId && (
                          <div
                            className={classes.singleNotificationVideoContainer}
                          >
                            {JSON.stringify(singleNotification.video)}
                            <Player
                              userId={undefined}
                              video={singleNotification.video}
                              type="preview"
                            />
                          </div>
                        )} */}
                      <Typography className={classes.singleNotificationDesc}>
                        {singleNotification.message}
                      </Typography>
                      <Typography className={classes.singleNotificationDate}>
                        <b>
                          {singleNotification.activeDate &&
                            new Intl.DateTimeFormat("en-GB", {
                              year: "numeric",
                              month: "long",
                              day: "2-digit"
                            }).format(new Date(singleNotification.activeDate))}
                        </b>
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
              {loading && (
                <Grid item xs={12} align="center">
                  <CircularProgress color="primary" />
                </Grid>
              )}
            </Grid>
          </Container>
        </div>
      </LoggedInLayout>
    </div>
  );
}
