import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Input,
  Typography,
  Grid,
  Tooltip,
  Hidden
} from "@material-ui/core";
import { getReferralLink, getRecentReferralLinks } from "../../api/client";
import ReferralLink, {
  RecentReferralLink
} from "../../api/entities/ReferralLink";
import { FileCopy } from "@material-ui/icons";
import { useToasts } from "react-toast-notifications";
import { Alert } from "@material-ui/lab";
import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import { isBrowser } from "react-device-detect";
import useStyles from "./styles";

const ShareSteps = () => {
  const classes = useStyles();
  const ref = useRef<HTMLInputElement>();
  const [error, setError] = useState<ReferralLink>();

  const [referralLinks, setReferralLinks] = useState<RecentReferralLink[]>();
  const [referralLinksLoading, setReferralLinksLoading] = useState(false);

  const [referralLink, setReferralLink] = useState<ReferralLink>();
  const [referralLinkLoading, setReferralLinkLoading] = useState(false);
  const { addToast } = useToasts();

  const baseShareUrl = `${
    window.location.href.split(window.location.pathname, 2)[0]
  }/unlock/`;

  const shareUrl = useMemo(
    () =>
      `${window.location.href.split(window.location.pathname, 2)[0]}/unlock/${
        referralLink?.id
      }`,
    [referralLink]
  );

  const shareMessage = useMemo(
    () =>
      `Click this link to unlock the back door and sign up for FarmFLiX! ${shareUrl}`,
    [shareUrl]
  );

  const existingShareUrl = useMemo(
    () =>
      `${window.location.href.split(window.location.pathname, 2)[0]}/unlock/${
        referralLinks ? referralLinks[0]?.id : undefined
      }`,
    [referralLinks]
  );

  const existingShareMessage = useMemo(
    () =>
      `Click this link to unlock the back door and sign up for FarmFLiX! ${existingShareUrl}`,
    [existingShareUrl]
  );

  useEffect(() => {
    if (!referralLinks && !referralLinksLoading && !error) {
      setReferralLinksLoading(true);
      getRecentReferralLinks()
        .then(data => {
          setReferralLinks(data);
          console.log(referralLinks);
        })
        .catch(e => setError(e.toString()))
        .finally(() => setReferralLinksLoading(false));
    }
  }, [referralLinks, error, referralLinksLoading]);

  const generateLink = () => {
    if (!referralLink && !referralLinkLoading && !error) {
      setReferralLinkLoading(true);
      getReferralLink()
        .then(link => {
          setReferralLink(link);
        })
        .catch(e => setError(e.toString()))
        .finally(() => setReferralLinkLoading(false));
    }
  };

  const handleClickCopy = () => {
    if (!ref.current) return;
    console.log(ref);

    ref.current.select();
    document.execCommand("copy");

    addToast("Copied to clipboard", {
      appearance: "success"
    });
  };

  const GenerateReferralLink = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.dialogContentText}>
            The <strong>Doors are Closed</strong> right now for new members, but
            if you know someone looking in on the action,{" "}
            {isBrowser && <>click</>}
            {!isBrowser && <>tap</>} the button below to unlock the back door to
            sign up to FarmFLiX!
          </Typography>
          <Button onClick={generateLink} className={classes.generateButton}>
            Unlock my mate Now
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ShareCurrentReferralLink = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.dialogContentText}>
            Share the link below to open the back door into FarmFLiX.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9} md={9}>
          <Input
            fullWidth
            name="referralLink"
            className={classes.shareInput}
            inputRef={ref}
            type="text"
            value={existingShareUrl}
            readOnly
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Box style={{ display: "inline-flex" }}>
            <Tooltip title="Copy link" placement="bottom">
              <Button
                name="copyReferralLink"
                color="primary"
                variant="outlined"
                className={classes.shareInputCopy}
                onClick={handleClickCopy}
              >
                <FileCopy />
              </Button>
            </Tooltip>
            {/** @ts-ignore */}
            <Tooltip title="Share on WhatsApp" placement="top">
              <WhatsappShareButton url={existingShareMessage}>
                <WhatsappIcon round size={40} style={{ margin: "0 4px" }} />
              </WhatsappShareButton>
            </Tooltip>

            {/** @ts-ignore */}
            <Tooltip title="Share on Facebook" placement="top">
              <FacebookMessengerShareButton
                url={shareUrl}
                // @ts-ignore
                quote={existingShareMessage}
              >
                <FacebookMessengerIcon size={40} round />
              </FacebookMessengerShareButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const ShareNewReferralLink = (url: any) => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.dialogContentText}>
              Share the link below to open the back door into FarmFLiX.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={9}>
            <Input
              fullWidth
              name="referralLink"
              className={classes.shareInput}
              inputRef={ref}
              type="text"
              value={url.url}
              readOnly
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Box style={{ display: "inline-flex" }}>
              <Tooltip title="Copy link" placement="bottom">
                <Button
                  name="copyReferralLink"
                  color="primary"
                  variant="outlined"
                  className={classes.shareInputCopy}
                  onClick={handleClickCopy}
                >
                  <FileCopy />
                </Button>
              </Tooltip>
              {/** @ts-ignore */}
              <Tooltip title="Share on WhatsApp" placement="top">
                <WhatsappShareButton url={shareMessage}>
                  <WhatsappIcon round size={40} style={{ margin: "0 2px" }} />
                </WhatsappShareButton>
              </Tooltip>

              {/** @ts-ignore */}
              <Tooltip title="Share on Facebook" placement="top">
                <FacebookMessengerShareButton
                  url={url.url}
                  // @ts-ignore
                  quote={shareMessage}
                >
                  <FacebookMessengerIcon size={40} round />
                </FacebookMessengerShareButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const RedeemedReferrals = (referral: any) => {
    return (
      <Hidden xsDown>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9} md={9}>
            <Input
              fullWidth
              name="referralLink"
              className={classes.shareInput}
              type="text"
              value={baseShareUrl + referral.referral.id}
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} style={{ textAlign: "left" }}>
            <Typography
              variant="body2"
              className={classes.dialogContentText}
              style={{ marginTop: "10px" }}
            >
              Redeemed by:
              <br />
              <span style={{ fontWeight: 500 }}>
                {referral.referral.redeemedByUserFirstName}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
    );
  };

  return (
    <>
      {!error && (
        <>
          {referralLinksLoading && (
            <CircularProgress className={classes.loadingSpinner} size={30} />
          )}
          {referralLinks && (
            <>
              {referralLinks.length === 0 && (
                <>
                  <Box className={classes.shareLinksContainer}>
                    {!referralLink && !referralLinkLoading && (
                      <GenerateReferralLink />
                    )}
                    {referralLink && <ShareNewReferralLink url={shareUrl} />}
                    {referralLinkLoading && (
                      <CircularProgress
                        className={classes.loadingSpinner}
                        size={30}
                      />
                    )}
                  </Box>
                </>
              )}

              {referralLinks.length > 0 && (
                <>
                  {referralLinks.length === 1 && (
                    <>
                      {referralLinks[0].redeemed && (
                        <>
                          {!referralLink && !referralLinkLoading && (
                            <GenerateReferralLink />
                          )}
                          {referralLink && (
                            <ShareNewReferralLink url={shareUrl} />
                          )}
                          {referralLinkLoading && (
                            <CircularProgress
                              className={classes.loadingSpinner}
                              size={30}
                            />
                          )}
                          <RedeemedReferrals referral={referralLinks[0]} />
                        </>
                      )}
                      {!referralLinks[0].redeemed && (
                        <ShareCurrentReferralLink />
                      )}
                    </>
                  )}
                  {referralLinks.length > 1 && (
                    <>
                      {referralLinks.every(x => x.redeemed === true) &&
                        !referralLink &&
                        !referralLinkLoading && (
                          <>
                            <GenerateReferralLink />
                          </>
                        )}
                      {error && (
                        <Alert severity="error" className={classes.errorAlert}>
                          {error}
                        </Alert>
                      )}
                      {!error && (
                        <>
                          {referralLink && (
                            <ShareNewReferralLink url={shareUrl} />
                          )}
                          {referralLinkLoading && (
                            <CircularProgress
                              className={classes.loadingSpinner}
                              size={30}
                            />
                          )}
                        </>
                      )}

                      {referralLinks.map((u, i) =>
                        u.redeemed ? (
                          <ShareCurrentReferralLink />
                        ) : (
                          <RedeemedReferrals referral={u} />
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ShareSteps;
