import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../../store/AccountContext";
import { Countdown } from "../common/Countdown";

import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
  Divider,
  Select,
  MenuItem
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import CardPaymentForm from "./CardPaymentForm";
import usePromise from "react-use-promise";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import { useHistory, useLocation } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import { currencySymbol } from "../../utils/strings";
import querystring from "query-string";
import dayjs from "dayjs";

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: props => (props.dark ? "transparent" : "#e8e8e8"),
    display: "flex",
    textAlign: "center"
  },
  stepWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6)
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6)
    }
  },
  stepTitle: {
    color: props =>
      props.dark
        ? theme.palette.common.white
        : theme.palette.background.ffNavyLighter,
    fontSize: "1.5rem",
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2rem",
      lineHeight: "2.2rem"
    }
  },
  stepSubTitle: {
    marginBottom: theme.spacing(2),
    color: props =>
      props.dark ? "#d9d9d9" : theme.palette.background.ffNavyLighter,
    textAlign: "left"
  },
  stepText: {
    marginBottom: theme.spacing(2),
    color: props =>
      props.dark ? "#fff" : theme.palette.background.ffNavyLighter,
    textAlign: "left"
  },
  stepTextBack: {
    marginBottom: theme.spacing(2),
    color: props =>
      props.dark ? "#fff" : theme.palette.background.ffNavyLighter,
    textAlign: "right",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  subscriptionType: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 500,
    color: props =>
      props.dark ? "#d9d9d9" : theme.palette.background.ffNavyLighter,
    textAlign: "center"
  },
  currencySelector: {
    backgroundColor: props => (props.dark ? "#525252" : "#dadada"),
    color: props => (props.dark ? "#fff" : "#000"),
    textAlign: "left",
    marginBottom: theme.spacing(1),
    outline: "none",
    fontWeight: 500,
    "& .MuiInputBase-input": {
      border: 0
    }
  },
  subscriptionContainer: {
    color: props => (props.dark ? "#fff" : theme.palette.background.ffNavy),
    backgroundColor: props => (props.dark ? "#3a3a3a" : "#dadada"),
    padding: theme.spacing(2),
    display: "inline-block",
    textAlign: "left",
    width: "100%",
    flexBasis: "unset",
    margin: theme.spacing(1, 0),
    "&:hover": {
      backgroundColor: props => (props.dark ? "#484848" : "#e2e2e2"),
      cursor: "pointer"
    }
  },
  selectedSubscriptionContainer: {
    color: theme.palette.common.white,
    backgroundColor: props => (props.dark ? "#666666" : "#f9f9f9"),
    padding: theme.spacing(2),
    display: "inline-block",
    width: "100%",
    textAlign: "left",
    margin: theme.spacing(1, 0),
    "&:hover": {
      backgroundColor: props => (props.dark ? "#666666" : "#f9f9f9"),
      cursor: "pointer"
    }
  },
  selectButton: {
    backgroundColor: props =>
      props.dark
        ? theme.palette.background.ffNavyLighter
        : theme.palette.background.ffNavyLighter,
    border: "2px solid #292929",
    color: theme.palette.common.white,
    padding: "8px 14px",
    fontSize: "0.8rem",
    lineHeight: "1.2rem",
    marginRight: 0,
    "&:hover": {
      border: props => (props.dark ? "2px solid #292929" : "2px solid #171717"),
      backgroundColor: props =>
        props.dark
          ? theme.palette.background.ffNavyLighter
          : theme.palette.background.ffNavyLighter,
      color: theme.palette.common.white
    },
    "& svg": {
      fill: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "0.9rem"
    }
  },
  selectButtonUnchecked: {
    transition: "none",
    backgroundColor: props => (props.dark ? "transparent" : "#ddd"),
    border: props => (props.dark ? "2px solid #eee" : "2px solid #171717"),
    color: props => (props.dark ? "#eee" : "#171717"),
    padding: "8px 14px",
    fontSize: "0.8rem",
    lineHeight: "1.2rem",
    marginRight: 0,
    "&:hover": {
      border: props => (props.dark ? "2px solid #eee" : "2px solid #171717"),
      backgroundColor: props => (props.dark ? "transparent" : "#ddd")
    },
    "@media (min-width:600px)": {
      fontSize: "0.9rem"
    }
  },
  subscriptionPrice: {
    letterSpacing: "-1px",
    fontWeight: 600,
    color: props =>
      props.dark ? "#eee" : theme.palette.background.ffNavyLighter,
    marginTop: theme.spacing(1)
  },
  discountedSubscriptionOriginalCost: {
    textDecoration: "line-through",
    color: "#888",
    fontSize: "1.6rem",
    lineHeight: "1.8rem",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.4rem",
      lineHeight: "3.6rem"
    }
  },
  discountedSubscriptionNewCost: {
    marginLeft: "6px",
    color: props => (props.dark ? "#eee" : theme.palette.background.upgrade),
    fontSize: "1.6rem",
    lineHeight: "1.8rem",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.4rem",
      lineHeight: "3.6rem"
    }
  },
  discountedSubscriptionTerm: {
    fontWeight: 500,
    fontSize: "0.9rem",
    color: props => (props.dark ? "#eee" : theme.palette.background.upgrade)
  },
  subscriptionCost: {
    fontSize: "1.6rem",
    lineHeight: "1.8rem",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.4rem",
      lineHeight: "3.6rem"
    }
  },
  subscriptionTerm: {
    fontWeight: 500,
    fontSize: "0.9rem"
  },
  subscriptionName: {
    color: props =>
      props.dark
        ? theme.palette.background.ffGreen
        : theme.palette.background.ffNavyLighter,
    fontSize: "1.4rem",
    lineHeight: "1.6rem",
    fontWeight: 500,
    textAlign: "left",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2rem",
      lineHeight: "2.2rem"
    }
  },
  bestValue: {
    backgroundColor: theme.palette.background.upgrade,
    color: theme.palette.common.white,
    fontSize: "0.5rem",
    fontWeight: 600,
    padding: theme.spacing(0.3, 1),
    marginLeft: theme.spacing(1),
    height: "16px",
    marginTop: "2px",
    "@media (min-width:600px)": {
      fontSize: "0.7rem",
      padding: theme.spacing(0.5, 1),
      height: "18px",
      marginTop: "2px"
    },
    "@media (min-width:960px)": {
      marginTop: "4px"
    }
  },
  subscriptionDescription: {
    color: props => (props.dark ? "#eee" : theme.palette.background.upgradeAlt),
    fontSize: "0.9rem",
    lineHeight: "1rem",
    fontWeight: "400",
    textAlign: "left",
    margin: theme.spacing(0.5, 0, 1, 0),
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.1rem",
      margin: theme.spacing(0.5, 0, 1, 0)
    }
  },
  infoText: {
    color: props => (props.dark ? "#eee" : theme.palette.background.upgradeAlt),
    textAlign: "left",
    fontSize: "0.8rem",
    lineHeight: "0.9rem",
    marginTop: theme.spacing(1),
    "& span": {
      fontWeight: 500
    }
  },
  continueButton: {
    marginTop: theme.spacing(3),
    width: "100%",
    marginLeft: 0,
    "@media (min-width:600px)": {
      width: "auto",
      padding: theme.spacing(1.5, 5)
    }
  },
  smlDivider: {
    backgroundColor: "#dadada",
    height: "2px",
    width: "50%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1.5)
  },
  center: {
    textAlign: "center"
  },
  selectedMembershipContainer: {
    marginBottom: theme.spacing(4)
  },
  selectedMembership: {
    zIndex: "-100000",
    backgroundColor: props =>
      props.dark ? "#666666" : theme.palette.common.white,
    padding: theme.spacing(3, 2)
  },
  selectedMembershipTitle: {
    textAlign: "left",
    color: props =>
      props.dark
        ? theme.palette.background.ffGreen
        : theme.palette.background.ffNavy,
    fontWeight: 500,
    fontSize: "1.6rem"
  },
  selectedMembershipPrice: {
    color: props => (props.dark ? "#eee" : theme.palette.background.ffNavy),
    fontWeight: 400,
    fontSize: "1rem"
  },
  selectedMembershipTerm: {
    color: props => (props.dark ? "#eee" : theme.palette.background.ffNavy),
    fontWeight: 400,
    fontSize: "0.8rem"
  },
  selectedMembershipLabel: {
    color: props => (props.dark ? "#eee" : theme.palette.background.ffNavy),
    textAlign: "left",
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    marginTop: "20px",
    marginBottom: "4px",
    fontWeight: 500,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  },
  selectedMembershipBodyText: {
    color: props =>
      props.dark ? "#d9d9d9" : theme.palette.background.upgradeAlt,
    marginBottom: theme.spacing(1.5),
    fontSize: "0.9rem",
    lineHeight: "1rem",
    textAlign: "left"
  },
  responseBox: {
    textAlign: "center",
    padding: "10%"
  },
  responseIcon: {
    width: "4rem",
    height: "4rem",
    "@media (min-width:600px)": {
      width: "5rem",
      height: "5rem"
    }
  },
  divider: {
    width: "50%",
    margin: "10% 25% 10% 25%",
    height: "1px",
    backgroundColor: "#a5a5a5",
    "@media (min-width:600px)": {
      margin: "5% 25% 10px 25%"
    },
    "@media (min-width:960px)": {
      margin: "5% 25% 10px 25%"
    }
  },
  responseTitle: {
    color: props =>
      props.dark
        ? theme.palette.common.upgrade
        : theme.palette.background.ffNavyLighter,
    fontSize: "2rem",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  responseSubTitle: {
    color: props =>
      props.dark
        ? theme.palette.common.upgrade
        : theme.palette.background.ffNavyLighter,
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(3),
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  responseBodytext: {
    marginBottom: theme.spacing(2),
    color: props =>
      props.dark
        ? theme.palette.common.upgrade
        : theme.palette.background.ffNavy,
    textAlign: "left"
  },
  responseHelp: {
    color: props =>
      props.dark
        ? theme.palette.common.upgrade
        : theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    textAlign: "center",
    "& a": {
      color: props =>
        props.dark
          ? theme.palette.common.white
          : theme.palette.background.ffNavy
    }
  },
  orContainer: {
    display: "flex",
    alignItems: "center"
  },
  orBorder: {
    borderBottom: "1px solid #c1c1c1",
    width: "100%"
  },
  orContent: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: "0.9rem",
    color: theme.palette.background.ffNavy
  },
  changeButton: {
    color: props => (props.dark ? "#d9d9d9" : theme.palette.background.ffNavy),
    border: props => (props.dark ? "2px solid #d9d9d9" : "2px solid #171717"),
    "&:hover": {
      color: props =>
        props.dark ? "#d9d9d9" : theme.palette.background.ffNavy,
      border: props => (props.dark ? "2px solid #d9d9d9" : "2px solid #171717")
    }
  },
  loadingBox: {
    marginTop: theme.spacing(2)
  },
  loadingText: {
    marginBottom: theme.spacing(2),
    color: props =>
      props.dark
        ? theme.palette.common.upgrade
        : theme.palette.background.ffNavy,
    textAlign: "center",
    marginTop: theme.spacing(2)
  },
  discountCountdownLabel: {
    color: props => (props.dark ? "#fff" : "#555"),
    fontWeight: 500,
    marginRight: "4px",
    fontSize: "1rem",
    lineHeight: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem"
    }
  },
  discountCountdownText: {
    color: "red",
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.2rem",
    marginTop: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem"
    }
  }
}));

const Steps = props => {
  const {
    getSubscriptionProducts,
    subscriptionProducts,
    resetErrors,
    userProfile,
    getMembershipDetails
  } = useContext(AccountContext);

  const classes = useStyles(props);
  const history = useHistory();
  const { search } = useLocation();
  const [qs, setQs] = useState({ app: false, product: undefined });

  //Redirect Standard Users and Standard Expired w/ Auto Renew Enabled from this page
  if (userProfile) {
    if (
      userProfile.memberStatus === "standard" &&
      userProfile.productAutoRenewing
    ) {
      history.push("/videos");
    } else if (
      userProfile.memberStatus === "standard-expired" &&
      userProfile.productAutoRenewing
    ) {
      history.push("/myaccount/overview");
    }
  }

  useEffect(() => {
    getSubscriptionProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSubscriptionProducts =
    subscriptionProducts &&
    subscriptionProducts?.results.find(
      p => p.memberStatusId === "standard" && p.isSubscription
    );

  const isPPVProducts =
    subscriptionProducts &&
    subscriptionProducts?.results.find(
      p => p.memberStatusId === "standard" && !p.isSubscription
    );

  // Subscription State Management
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedSubscriptionID, setSelectedSubscriptionID] = useState("");
  // eslint-disable-next-line
  const [currency, setCurrency] = useState();
  // eslint-disable-next-line
  const [reloadTrigger, setReloadTrigger] = useState(Date.now());

  const [membershipDetails, , state] = usePromise(getMembershipDetails, [
    reloadTrigger
  ]);

  // eslint-disable-next-line
  const loading = state === "pending";

  const handleMembershipChange = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (membershipDetails && membershipDetails.currency) {
      setCurrency(membershipDetails.currency.toUpperCase());
    } else {
      setCurrency("GBP");
    }
  }, [membershipDetails]);

  /* ==============================================
  =========== Subscription Handlers ===============
  ============================================== */

  // Handle Currenct Change
  const handleCurrencyChange = event => {
    setSelectedSubscription("");
    setSelectedSubscriptionID("");
    setCurrency(event.target.value);
  };

  function onHandleSubscription(subscription) {
    setSelectedSubscription(subscription);
    setSelectedSubscriptionID(subscription.id);
  }

  /* ==============================================
  ============= Payment Handlers ==================
  ============================================== */

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    resetErrors();
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1Subscriptions />;
      case 1:
        return <Step2Payment />;
      default:
        return "Unknown step";
    }
  }

  // Step 1 - Show Membership Plans
  function Step1Subscriptions() {
    return (
      <>
        {membershipDetails && (
          <>
            <Container
              fixed
              fixedcomponent="main"
              maxWidth="sm"
              style={{ padding: "0" }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.stepWrapper}
                >
                  {props.showStepper !== false && (
                    <Typography variant="body2" className={classes.stepText}>
                      Step <b>1</b> of <b>2</b>
                    </Typography>
                  )}

                  <Typography variant="h2" className={classes.stepTitle}>
                    Choose your Membership
                  </Typography>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        className={classes.stepSubTitle}
                      >
                        Select a FarmFLiX Membership.
                      </Typography>
                    </Grid>
                    <Grid item xs={4} align="right">
                      {!membershipDetails.stripeCustomerId && (
                        <Select
                          id="currency"
                          name="currency"
                          value={currency}
                          onChange={handleCurrencyChange}
                          className={classes.currencySelector}
                        >
                          <MenuItem value={"GBP"}>{`GBP (£)`}</MenuItem>
                          <MenuItem value={"EUR"}>{`EUR (€)`}</MenuItem>
                          <MenuItem value={"USD"}>{`USD ($)`}</MenuItem>
                        </Select>
                      )}
                    </Grid>
                  </Grid>
                  {subscriptionProducts && (
                    <div>
                      <>
                        {isPPVProducts && (
                          <>
                            {subscriptionProducts.results
                              .filter(
                                s =>
                                  s.currency === currency &&
                                  s.cost > 0 &&
                                  !s.isSubscription
                              )
                              .map((subscription, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  key={index}
                                  className={
                                    subscription.id === selectedSubscriptionID
                                      ? classes.selectedSubscriptionContainer
                                      : classes.subscriptionContainer
                                  }
                                  onClick={() =>
                                    onHandleSubscription(subscription)
                                  }
                                >
                                  <Grid container>
                                    <Grid item xs={7}>
                                      <Box style={{ display: "flex" }}>
                                        <Typography
                                          className={classes.subscriptionName}
                                        >
                                          {subscription.name}
                                        </Typography>

                                        {(subscription.id === "annual60" ||
                                          subscription.id === "annual70eur" ||
                                          subscription.id ===
                                            "annual85usd") && (
                                          <Typography
                                            className={classes.bestValue}
                                          >
                                            SAVE 20%
                                          </Typography>
                                        )}
                                      </Box>
                                      <Typography
                                        className={
                                          classes.subscriptionDescription
                                        }
                                      >
                                        {subscription.description}
                                      </Typography>

                                      {/* Price */}
                                      <Typography
                                        className={classes.subscriptionPrice}
                                        variant="body1"
                                      >
                                        <span
                                          className={classes.subscriptionCost}
                                        >
                                          {currencySymbol(
                                            subscription.currency
                                          )}
                                          {subscription.cost}
                                        </span>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={5} align="right">
                                      {subscription.id ===
                                      selectedSubscriptionID ? (
                                        <Button
                                          name={subscription.id}
                                          disableRipple={true}
                                          className={classes.selectButton}
                                          startIcon={<CheckCircleIcon />}
                                        >
                                          SELECTED
                                        </Button>
                                      ) : (
                                        <Button
                                          name={subscription.id}
                                          disableRipple={true}
                                          disableFocusRipple={true}
                                          className={
                                            classes.selectButtonUnchecked
                                          }
                                          startIcon={
                                            <RadioButtonUncheckedOutlinedIcon />
                                          }
                                        >
                                          SELECT
                                        </Button>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                          </>
                        )}
                        {isSubscriptionProducts && isPPVProducts && (
                          <Box className={classes.orContainer}>
                            <Box className={classes.orBorder} />
                            <span className={classes.orContent}>OR</span>
                            <Box className={classes.orBorder} />
                          </Box>
                        )}
                        {isSubscriptionProducts && (
                          <>
                            {subscriptionProducts.results
                              .filter(
                                s =>
                                  s.currency === currency &&
                                  s.cost > 0 &&
                                  s.isSubscription
                              )
                              .map((subscription, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  key={index}
                                  className={
                                    subscription.id === selectedSubscriptionID
                                      ? classes.selectedSubscriptionContainer
                                      : classes.subscriptionContainer
                                  }
                                  onClick={() =>
                                    onHandleSubscription(subscription)
                                  }
                                >
                                  <Grid container>
                                    <Grid item xs={7}>
                                      <Box style={{ display: "flex" }}>
                                        <Typography
                                          className={classes.subscriptionName}
                                        >
                                          {subscription.name}
                                        </Typography>

                                        {(subscription.id === "annual60" ||
                                          subscription.id === "annual70eur" ||
                                          subscription.id ===
                                            "annual85usd") && (
                                          <Typography
                                            className={classes.bestValue}
                                          >
                                            SAVE 30%
                                          </Typography>
                                        )}
                                      </Box>
                                      <Typography
                                        className={
                                          classes.subscriptionDescription
                                        }
                                      >
                                        {subscription.description}
                                      </Typography>

                                      {/* Price */}
                                      <Typography
                                        className={classes.subscriptionPrice}
                                        variant="body1"
                                      >
                                        {subscription.discounted && (
                                          <>
                                            <span
                                              className={
                                                classes.discountedSubscriptionOriginalCost
                                              }
                                            >
                                              {currencySymbol(
                                                subscription.currency
                                              )}
                                              {parseFloat(
                                                subscription.discountedOrigCost
                                              ).toFixed(2)}
                                            </span>
                                            <span
                                              className={
                                                classes.discountedSubscriptionNewCost
                                              }
                                            >
                                              {currencySymbol(
                                                subscription.currency
                                              )}
                                              {subscription.cost}
                                            </span>
                                            <span
                                              className={
                                                classes.discountedSubscriptionTerm
                                              }
                                            >
                                              /
                                              {
                                                subscription.entitlementPeriodUnit
                                              }
                                            </span>
                                          </>
                                        )}
                                        {!subscription.discounted && (
                                          <>
                                            <span
                                              className={
                                                classes.subscriptionCost
                                              }
                                            >
                                              {currencySymbol(
                                                subscription.currency
                                              )}
                                              {subscription.cost}
                                            </span>
                                            <span
                                              className={
                                                classes.subscriptionTerm
                                              }
                                            >
                                              /
                                              {
                                                subscription.entitlementPeriodUnit
                                              }
                                            </span>
                                          </>
                                        )}
                                      </Typography>
                                      {subscription.discounted &&
                                        subscription.availableUntil && (
                                          <Box
                                            display="flex"
                                            className={
                                              classes.discountCountdownText
                                            }
                                          >
                                            <Typography
                                              className={
                                                classes.discountCountdownLabel
                                              }
                                            >
                                              Ends in:
                                            </Typography>
                                            <Countdown
                                              date={dayjs(
                                                subscription.availableUntil
                                              ).toDate()}
                                              zeroPadTime={2}
                                              daysInHours={true}
                                            />
                                          </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={5} align="right">
                                      {subscription.id ===
                                      selectedSubscriptionID ? (
                                        <Button
                                          name={subscription.id}
                                          disableRipple={true}
                                          className={classes.selectButton}
                                          startIcon={<CheckCircleIcon />}
                                        >
                                          SELECTED
                                        </Button>
                                      ) : (
                                        <Button
                                          name={subscription.id}
                                          disableRipple={true}
                                          disableFocusRipple={true}
                                          className={
                                            classes.selectButtonUnchecked
                                          }
                                          startIcon={
                                            <RadioButtonUncheckedOutlinedIcon />
                                          }
                                        >
                                          SELECT
                                        </Button>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                          </>
                        )}
                      </>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="body2"
                          className={classes.infoText}
                        >
                          All Memberships offer unlimited access to FarmFLiX,
                          and up to 3 devices at concurrent times. HD (720p),
                          Full HD (1080p) availability subject to your internet
                          service and device capability.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        {activeStep === 0 && (
                          <Button
                            name="continue"
                            size="large"
                            fullwidth="true"
                            onClick={handleNext}
                            className={classes.continueButton}
                            disabled={!selectedSubscriptionID}
                          >
                            Continue
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Divider className={classes.smlDivider} />
                        <Typography
                          variant="body2"
                          className={classes.infoText}
                        >
                          <span>We offer the FarmFLiX Guarantee</span>. If you
                          don&apos;t love FarmFLiX in the first month,
                          we&apos;ll refund your order, no questions asked.
                        </Typography>
                      </Grid>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </>
    );
  }

  function Step2Payment() {
    const [paymentResponse, setPaymentResponse] = useState();

    const tryAgain = () => {
      setPaymentResponse("");
      setActiveStep(0);
    };

    const ResponseBox = props => {
      if (!paymentResponse || paymentResponse === undefined) {
        console.log("Should be no status set", paymentResponse);
        return;
      }

      return (
        <>
          <Box className={classes.responseBox}>
            {props.paymentStatus === "pending" && (
              <CircularProgress color="primary" />
            )}
            {props.paymentStatus === "failure" && (
              <CancelIcon className={classes.responseIcon} color="error" />
            )}
            {props.paymentStatus === "timeout" && (
              <ErrorIcon
                className={classes.responseIcon}
                style={{ fill: "#ff9f00" }}
              />
            )}
            {props.paymentStatus === "success" && (
              <CheckCircleIcon
                className={classes.responseIcon}
                color="primary"
              />
            )}
            <Typography
              variant="h2"
              className={classes.stepTitle}
              style={{ textAlign: "center" }}
            >
              {props.paymentStatusTitle}
            </Typography>
            <Typography
              variant="h3"
              className={classes.responseSubTitle}
              style={{ textAlign: "center" }}
            >
              {props.paymentStatusSubTitle}
            </Typography>
            {props.paymentStatusDesc && (
              <Typography
                variant="body2"
                className={classes.responseBodytext}
                style={{ textAlign: "center" }}
              >
                {props.paymentStatusDesc}
              </Typography>
            )}
            {props.tryAgain && (
              <>
                <Button size="large" onClick={tryAgain}>
                  Try Again
                </Button>
                <Divider className={classes.divider} />
                <Typography variant="body2" className={classes.responseHelp}>
                  <b>Need help?</b> Contact us using the &apos;Help&apos; Widget
                  or email us at{" "}
                  <a href="mailto:info@farmflix.tv">info.farmflix.tv</a>
                </Typography>
              </>
            )}
            {props.paymentStatus === "success" && (
              <a
                href={qs.app ? "farmflix://" : "/videos"}
                style={{ textDecoration: "none" }}
              >
                <Button size="large" name="get-started">
                  Get Started
                </Button>
              </a>
            )}
          </Box>
        </>
      );
    };

    return (
      <>
        {paymentResponse && (
          <ResponseBox
            paymentStatus={paymentResponse.status}
            paymentStatusTitle={paymentResponse.title}
            paymentStatusSubTitle={paymentResponse.subTitle}
            paymentStatusDesc={paymentResponse.description}
            tryAgain={paymentResponse.tryAgain}
          />
        )}
        <Container
          fixed
          fixedcomponent="main"
          maxWidth="sm"
          style={{
            padding: 0,
            display: paymentResponse ? "none" : "block"
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} className={classes.stepWrapper}>
              {props.showStepper !== false && (
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography
                      variant="body2"
                      className={classes.stepText}
                      style={{ marginBottom: 0 }}
                    >
                      Step <b>2</b> of <b>2</b>{" "}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      style={{ marginBottom: 0 }}
                      className={classes.stepTextBack}
                      onClick={handleMembershipChange}
                    >
                      &lt; Go Back to Memberships
                    </Typography>
                  </Box>
                </Box>
              )}
              <Typography
                variant="body2"
                className={classes.responseBodytext}
              ></Typography>
              <Typography variant="h2" className={classes.stepTitle}>
                Pay with Credit/Debit Card
              </Typography>
              {/* {!paymentPending && ( */}
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={classes.selectedMembershipContainer}
                >
                  <Typography
                    className={classes.selectedMembershipLabel}
                    variant="body1"
                  >
                    Membership
                  </Typography>
                  <Typography className={classes.selectedMembershipBodyText}>
                    Check your selected subscription before continuing with
                    payment.
                  </Typography>
                  <Box display="flex" className={classes.selectedMembership}>
                    <Box flexGrow={1}>
                      <Typography
                        variant="body1"
                        className={classes.selectedMembershipTitle}
                      >
                        {selectedSubscription.name}
                        <br />
                        <span className={classes.selectedMembershipPrice}>
                          {currencySymbol(selectedSubscription.currency)}
                          {selectedSubscription.cost}
                        </span>
                        {selectedSubscription.isSubscription && (
                          <span className={classes.selectedMembershipTerm}>
                            /{selectedSubscription.entitlementPeriodUnit}
                          </span>
                        )}
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        size="small"
                        className={classes.changeButton}
                        onClick={handleMembershipChange}
                      >
                        Change
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <CardPaymentForm
                    Subscription={selectedSubscription}
                    dark={props.dark}
                    handlePaymentResponse={setPaymentResponse}
                  />
                </Grid>
              </>
              {/* )} */}
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }

  useEffect(() => {
    if (search) {
      const { app, product } = querystring.parse(search);
      setQs({
        app: app ? parseInt(app) === 1 : false,
        product: product ? product : undefined
      });
    }
  }, [search]);

  useEffect(() => {
    if (subscriptionProducts && qs.product && subscriptionProducts.results) {
      const selectedProduct = subscriptionProducts.results.find(
        product =>
          product.id === qs.product &&
          (!membershipDetails ||
            (membershipDetails.currency &&
              membershipDetails.currency.toLowerCase() ===
                product.currency.toLowerCase()))
      );

      if (selectedProduct) {
        onHandleSubscription(selectedProduct);
        setActiveStep(1);
      } else {
        setSelectedSubscription("");
        setSelectedSubscriptionID("");
        setActiveStep(0);
      }
    }
  }, [qs, subscriptionProducts, membershipDetails]);

  return (
    <>
      <div className={classes.background}>
        <Container>
          {loading ? (
            <Box className={classes.loadingBox}>
              <CircularProgress color="primary" />
              <Typography variant="body1" className={classes.loadingText}>
                Loading Memberships...
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                {getStepContent(activeStep)}
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
    </>
  );
};

export default Steps;
