export const MEMBER_STATUS_STANDARD = "standard";
export const MEMBER_STATUS_STANDARD_EXPIRED = "standard-expired";
export const MEMBER_STATUS_TRIAL = "trial";
export const MEMBER_STATUS_TRIAL_EXPIRED = "trial-expired";
export const MEMBER_STATUS_LOCKED = "locked";
export const MEMBER_STATUS_STANDARD_INCOMPLETE = "standard-incomplete";

export default interface UserProfile {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  emailRejected: boolean;
  countryCode: string;
  phoneNumber: string;
  isPremium: boolean;
  termsAccepted: boolean;
  termsAcceptedDate: Date | undefined;
  segments: string[] | null;
  memberStatus: string;
  memberStatusId?: string;
  subscriptionExpiryDate?: Date;
  productId?: string;
  productAutoRenewing?: boolean;
  activeDevices: number;
  inactiveDevices: number;
  lifetimeValue: number;
  dateFirstSuccessfulPayment?: Date;
  dateLastSuccessfulPayment?: Date;
  failedPaymentsSinceLastSuccessfulPayment?: number;
  dateLastFailedPayment?: Date;
  dateAppFirstLoggedIn?: Date;
  dateWebFirstLoggedIn?: Date;
  dateAppLastLoggedIn?: Date;
  dateWebLastLoggedIn?: Date;
  dateLastSeen?: Date;
  created: Date;
  updated: Date;
  isLocked: boolean;
}
