import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Hidden,
  Button
} from "@material-ui/core";
import { Link } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";

import appleappstore from "./../Modals/app-store-logo.png";
import googleplaystore from "./../Modals/google-play-logo.png";

import CookieConsent from "react-cookie-consent";

import { isMobile, isTablet, isBrowser } from "react-device-detect";
import { ChevronRight } from "@material-ui/icons";
import { SupportFabButton } from "../Support/SupportFabButton";

const useStyles = makeStyles(theme => ({
  footer: {
    display: "flex",
    backgroundColor: theme.palette.background.ffNavy,
    borderTop: `10px solid ${theme.palette.background.ffNavyLight}`,
    paddingBottom: "20px"
  },
  footerInner: {
    alignSelf: "flex-end"
  },
  smlText: {
    fontSize: "0.7rem",
    margin: "10px 0",
    color: theme.palette.common.darkgray
  },
  inlineList: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    color: "#eee",
    a: {
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  inlineDisplay: {
    display: "inline-block",
    padding: "0",
    "& a": {
      textDecorationColor: theme.palette.common.darkgray
    }
  },
  subHeading: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: "1rem"
  },
  iconStyle: {
    fontSize: "2rem",
    padding: "10px",
    backgroundColor: theme.palette.background.ffNavyLight,
    borderRadius: "50%",
    margin: "10px",
    color: theme.palette.common.lightgray,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  appStoreLogo: {
    width: "90%",
    maxWidth: "140px",
    "@media (min-width:600px)": {
      width: "90%",
      maxWidth: "140px"
    },
    "@media (min-width:960px)": {
      width: "70%",
      maxWidth: "120px"
    }
  },
  moreInfoButton: {
    float: "center",
    marginTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "3px",
    paddingBottom: "3px"
  }
}));

export default function Footer() {
  const classes = useStyles();

  function AppLinks() {
    const classes = useStyles();

    if (isMobile || isTablet) {
      return (
        <>
          <List dense>
            <ListItem disableGutters>
              <a
                href={
                  "https://apps.apple.com/us/app/farmflix/id1490609587?ls=1"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appleappstore}
                  className={classes.appStoreLogo}
                  alt="Apple App Store Graphic"
                />
              </a>
            </ListItem>
            <ListItem disableGutters>
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.farmflix&hl=en"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googleplaystore}
                  className={classes.appStoreLogo}
                  alt="Google Play Store Graphic"
                />
              </a>
            </ListItem>
            {/* <ListItem disableGutters>
              <Link to="/farmflix-app" style={{ textDecoration: "none" }}>
                <ListItemText primary="About the App" />
              </Link>
            </ListItem> */}
          </List>
        </>
      );
    } else if (isBrowser) {
      return (
        <>
          <List dense>
            <ListItem disableGutters>
              <ListItemText primary="Available on:" />
            </ListItem>
            <ListItem disableGutters>
              <img
                src={appleappstore}
                className={classes.appStoreLogo}
                alt="Apple App Store Graphic"
              />
            </ListItem>
            <ListItem disableGutters>
              <img
                src={googleplaystore}
                className={classes.appStoreLogo}
                alt="Google Play Store Graphic"
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary="Search 'FarmFLiX'" />
            </ListItem>
          </List>
        </>
      );
    }
  }

  return (
    <div className={classes.footer}>
      <Container className={classes.footerInner}>
        <Grid container>
          <Grid item xs={6} sm={6} md={3}>
            <Box m={4}>
              <Typography variant="h6" className={classes.subHeading}>
                Get the App
              </Typography>
              <AppLinks />
            </Box>
            <Box m={4}>
              <Typography variant="h6" className={classes.subHeading}>
                #onThePull
              </Typography>
              <Typography variant="body2">
                View information about the event, live leaderboard and more.
              </Typography>

              <Link to="/onthepull" className={classes.link}>
                <Button className={classes.moreInfoButton}>
                  More info
                  <ChevronRight />
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Box m={4}>
              <Typography variant="h6" className={classes.subHeading}>
                About Us
              </Typography>
              <List dense>
                <ListItem disableGutters>
                  <Link to="/about" style={{ textDecoration: "none" }}>
                    <ListItemText primary="About FarmFLiX" />
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link to="/careers" style={{ textDecoration: "none" }}>
                    <ListItemText primary="Careers" />
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <a
                    href="https://shop.farmflix.tv"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary="The Shop" />
                  </a>
                </ListItem>
                <ListItem disableGutters>
                  <Link to="/help" style={{ textDecoration: "none" }}>
                    <ListItemText primary="Help" />
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link to="/usemycode" style={{ textDecoration: "none" }}>
                    <ListItemText primary="Redeem Giftcard" />
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Link to="/contact" style={{ textDecoration: "none" }}>
                    <ListItemText primary="Contact Us" />
                  </Link>
                </ListItem>
                {/* <ListItem disableGutters>
                  <ListItemText primary="Accounts &amp; Billing" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText primary="Plans &amp; Pricing" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText primary="Ways to Watch" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText primary="Speed Test" />
                </ListItem> */}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box m={4}>
              <Typography variant="h6" className={classes.subHeading}>
                Find Us On
              </Typography>
              <a
                href={"https://en-gb.facebook.com/farmflix/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon className={classes.iconStyle} />
              </a>
              <a
                href={"https://www.instagram.com/farmflix/?hl=en"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon className={classes.iconStyle} />
              </a>
              <a
                href={"https://www.youtube.com/farmflix"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTubeIcon className={classes.iconStyle} />
              </a>
              <Typography variant="body2">
                If you require help/assistance or are a content creator and
                would like to speak to us, please get in touch at{" "}
                <b>info@farmflix.tv</b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs={12} sm={10}>
          <Box mx={4} my={2}>
            <Hidden smUp>
              <Grid item xs={8}>
                <List dense className={classes.inlineList}>
                  <ListItem disableGutters className={classes.inlineDisplay}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Link to="/terms" style={{ textDecoration: "none" }}>
                          <Typography className={classes.smlText}>
                            Terms &amp; Conditions
                          </Typography>
                        </Link>
                      }
                    />
                  </ListItem>
                  {/* <ListItem disableGutters className={classes.inlineDisplay}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Link to="/sitemap" style={{ textDecoration: "none" }}>
                          <Typography className={classes.smlText}>
                            Sitemap
                          </Typography>
                        </Link>
                      }
                    />
                  </ListItem> */}
                </List>
              </Grid>
              <Grid item xs={8}>
                <List dense className={classes.inlineList}>
                  <ListItem disableGutters className={classes.inlineDisplay}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Link
                          to="/privacy-policy"
                          style={{ textDecoration: "none" }}
                        >
                          <Typography className={classes.smlText}>
                            Privacy Policy
                          </Typography>
                        </Link>
                      }
                    />
                  </ListItem>
                  {/* <ListItem disableGutters className={classes.inlineDisplay}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Link
                          to="/cookiepolicy"
                          style={{ textDecoration: "none" }}
                        >
                          <Typography className={classes.smlText}>
                            Cookie Policy
                          </Typography>
                        </Link>
                      }
                    />
                  </ListItem> */}
                </List>
              </Grid>
              <Grid item xs={12}>
                <List dense className={classes.inlineList}>
                  <ListItem disableGutters className={classes.inlineDisplay}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography className={classes.smlText}>
                          &copy; 2022 FarmFLiX.tv
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
            </Hidden>
            <Hidden xsDown>
              <List dense className={classes.inlineList}>
                <ListItem disableGutters className={classes.inlineDisplay}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.smlText}>
                        &copy; 2022 FarmFLiX.tv
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem disableGutters className={classes.inlineDisplay}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Link to="/terms" style={{ textDecoration: "none" }}>
                        <Typography className={classes.smlText}>
                          Terms &amp; Conditions
                        </Typography>
                      </Link>
                    }
                  />
                </ListItem>
                <ListItem disableGutters className={classes.inlineDisplay}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Link
                        to="/privacy-policy"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography className={classes.smlText}>
                          Privacy Policy
                        </Typography>
                      </Link>
                    }
                  />
                </ListItem>
                {/* <ListItem disableGutters className={classes.inlineDisplay}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Link
                        to="/cookiepolicy"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography className={classes.smlText}>
                          Cookie Policy
                        </Typography>
                      </Link>
                    }
                  />
                </ListItem>
                <ListItem disableGutters className={classes.inlineDisplay}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Link to="/sitemap" style={{ textDecoration: "none" }}>
                        <Typography className={classes.smlText}>
                          Sitemap
                        </Typography>
                      </Link>
                    }
                  />
                </ListItem> */}
              </List>
            </Hidden>
          </Box>
        </Grid>
      </Container>
      <CookieConsent
        location="bottom"
        buttonText="Accept Cookies"
        cookieName="farmflix.tv"
        style={{
          background: "#1ab9e8",
          color: "#fff",
          fontSize: "0.9rem",
          zIndex: "1200000"
        }}
        buttonStyle={{
          fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"],
          background: "#2bc4f1",
          fontSize: "0.8rem",
          fontWeight: "500",
          color: "#fff",
          borderRadius: "25px",
          border: "2px solid #13b8ea",
          textTransform: "uppercase",
          padding: "6px 20px"
        }}
        expires={150}
      >
        We use cookies to personalise your experience. By continuing to use
        FarmFLiX.tv, you agree to our use of cookies.
      </CookieConsent>
      <SupportFabButton />
    </div>
  );
}
