import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Helmet } from "react-helmet";
import { FacebookMessengerIcon, WhatsappIcon } from "react-share";
import DefaultLayout from "../layouts/DefaultLayout";

// Styles
const useStyles = makeStyles(theme => ({
  backgroundAlt: {
    flexGrow: 1,
    backgroundColor: "#292929",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderTop: "10px solid #202020",
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    }
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4)
  },
  header: {
    color: theme.palette.background.lightgray,
    fontSize: "2.8rem",
    lineHeight: "2.6rem",
    marginBottom: theme.spacing(2),
    textAlign: "center",
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(4),
      fontSize: "2.8rem",
      lineHeight: "3rem"
    },
    "@media (min-width:960px)": {
      fontSize: "4.2rem",
      lineHeight: "4.4rem"
    }
  },
  subHeading: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    marginBottom: "20px",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    }
  },
  bodytext: {
    color: theme.palette.common.white,
    marginBottom: "10px",
    fontSize: "1.1rem",
    "& a": {
      color: theme.palette.common.white
    }
  },
  panelHeading: {
    fontSize: "0.9rem",
    color: theme.palette.background.ffNavy
  },
  panelBody: {
    fontSize: "0.9rem",
    color: theme.palette.background.ffNavy
  },
  shareButton: {
    marginRight: theme.spacing(1),
    width: 56,
    "@media (min-width:600px)": {
      width: 140
    }
  },
  shareText: {
    fontSize: "1.2rem",
    display: "block",
    color: theme.palette.common.white,
    fontWeight: 500,
    marginTop: theme.spacing(1)
  },
  contactWrapper: {
    margin: theme.spacing(2, 0, 4, 0)
  }
}));

const Contact = props => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Contact</title>
        <meta
          name="description"
          content="Need to get in touch with FarmFLiX, email us at info@farmflix.tv"
        />
      </Helmet>
      <div className={classes.backgroundAlt}>
        <Container fixed fixedcomponent="main" maxWidth="sm">
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Container fixedcomponent="main" maxWidth="md">
                <div className={classes.paper}>
                  <Grid container>
                    <Grid item xs={12} align="center">
                      <Typography
                        component="h1"
                        variant="h1"
                        className={classes.header}
                      >
                        Get In Touch
                      </Typography>
                      <Typography
                        component="p"
                        variant="body1"
                        className={classes.bodytext}
                      >
                        You can get in touch with FarmFLiX on:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      align="center"
                      className={classes.contactWrapper}
                    >
                      <a
                        href="https://m.me/farmflix"
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <FacebookMessengerIcon
                          round
                          className={classes.shareButton}
                        />
                        <br />
                        <span className={classes.shareText}>
                          Facebook Messenger
                        </span>
                      </a>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      align="center"
                      className={classes.contactWrapper}
                    >
                      <a
                        href="https://wa.me/447552383300"
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <WhatsappIcon round className={classes.shareButton} />
                        <br />
                        <span className={classes.shareText}>WhatsApp</span>
                      </a>
                    </Grid>
                    <Grid item xs={12} align="center">
                      <Typography
                        component="p"
                        variant="body1"
                        className={classes.bodytext}
                      >
                        Or alternatively, email us at{" "}
                        <a href="mailto:info@farmflix.tv">
                          <b>info@farmflix.tv</b>
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </div>
    </DefaultLayout>
  );
};

export default Contact;
