import React, { useState, useContext } from "react";
import {
  Popover,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Typography
} from "@material-ui/core";
import { OverviewContext } from "../../store/OverviewContext";
import { SearchContext } from "./SearchContainer";

import WidgetsIcon from "@material-ui/icons/Widgets";

import useStyles from "./styles";

const ChannelFilter = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "channel-popover" : undefined;

  const handleFilterChange = (e: any, channelId: string) => {
    updateSearch({
      channelId: channelId === "all" ? undefined : channelId
    });
  };

  const { overview } = useContext(OverviewContext);
  const { search, updateSearch } = useContext(SearchContext);

  const selectedChannel = search?.channelId
    ? overview?.channels?.find(c => c.id === search.channelId)
    : undefined;

  return (
    <>
      <Chip
        className={classes.filterChip}
        label={
          <>
            <Box display="flex" alignItems="center">
              <WidgetsIcon
                className={
                  selectedChannel
                    ? classes.filterChipIconActive
                    : classes.filterChipIcon
                }
              />
              <Typography className={classes.filterChipLabel}>
                {selectedChannel ? selectedChannel.name : "Channels"}
              </Typography>
            </Box>
          </>
        }
        aria-describedby={id}
        onClick={handleFilterOpen}
      />

      <Popover
        classes={{ paper: classes.popOverContainer }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <RadioGroup
          aria-label="channel_filter"
          name="channelFilter"
          value={search?.channelId || "all"}
          onChange={handleFilterChange}
        >
          <FormControlLabel
            classes={{ label: classes.filterLabel }}
            value="all"
            control={<Radio color="primary" />}
            label="All"
          />
          {/* Get Regions Here  */}
          {overview && overview.channels && (
            <>
              {overview.channels.map(channel => (
                <FormControlLabel
                  key={channel.id}
                  classes={{ label: classes.filterLabel }}
                  value={channel.id}
                  control={<Radio color="primary" />}
                  label={channel.name}
                />
              ))}
            </>
          )}
        </RadioGroup>
      </Popover>
    </>
  );
};

export default ChannelFilter;
