import React, { useState, useContext, useEffect, useCallback } from "react";
import { AccountContext } from "./../../store/AccountContext";
import { OverviewContext } from "./../../store/OverviewContext";
import {
  Grid,
  Typography,
  Button,
  Container,
  Box,
  Divider,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import usePromise from "react-use-promise";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import amex from "payment-icons/min/flat/amex.svg";
import discover from "payment-icons/min/flat/discover.svg";
import { useToasts } from "react-toast-notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useStripe } from "@stripe/react-stripe-js";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "center",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  myAccountThirdTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(3),
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },
  bodyText: {
    marginBottom: theme.spacing(2),
    color: theme.palette.background.ffNavy,
    "& span": {
      color: "#676767",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer"
      }
    }
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    "& span": {
      fontWeight: "500"
    }
  },
  historyContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    color: theme.palette.background.ffNavy,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  },
  currentPlanLabel: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.ffGreen,
    fontWeight: 600,
    color: "#fff",
    textAlign: "center",
    borderRadius: "10px 10px 0 0",
    "@media (min-width:600px)": {
      padding: theme.spacing(1)
    }
  },
  promoLabel: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.upgrade,
    fontWeight: 600,
    color: "#fff",
    textAlign: "center",
    borderRadius: "10px 10px 0 0",
    "@media (min-width:600px)": {
      padding: theme.spacing(1)
    }
  },
  membershipOptionsContainer: {
    marginBottom: theme.spacing(2),
    width: "100%"
  },
  membershipPlanContainer: {
    // padding: theme.spacing(3, 4),
    backgroundColor: "#ddd"
  },
  membershipPlanContainerInnerDisabled: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#fafafa",
    minHeight: "100px",
    padding: theme.spacing(2, 3),
    border: "2px solid #fafafa",
    textAlign: "center",
    "@media (min-width:600px)": {
      padding: theme.spacing(3, 4)
    }
  },
  membershipPlanContainerMid: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(3, 1),
    minHeight: "100px",
    textAlign: "center",
    "@media (min-width:600px)": {
      padding: theme.spacing(5, 4)
    }
  },
  membershipPlanContainerInner: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#fff",
    minHeight: "100px",
    padding: theme.spacing(2, 3),
    border: "2px solid #fff",
    textAlign: "center",
    "@media (min-width:600px)": {
      padding: theme.spacing(3, 4)
    }
  },
  productDetails: {
    display: "flex",
    flexGrow: 1,
    fontWeight: 500,
    fontSize: "1.1rem",
    color: theme.palette.background.ffNavy,
    alignContent: "center",
    alignItems: "center"
  },
  productTitle: {
    fontWeight: 500,
    fontSize: "1.1rem",
    color: theme.palette.background.ffNavy,
    alignContent: "center",
    alignItems: "center",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem"
    }
  },
  productCost: {
    fontWeight: 600,
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    color: theme.palette.background.ffNavy,
    alignItems: "center",
    "& span": {
      fontSize: "1rem",
      lineHeight: "1rem",
      fontWeight: "normal"
    },
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      "& span": {
        fontSize: "0.9rem"
      }
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem",
      "& span": {
        fontSize: "0.9rem"
      }
    }
  },
  closeDialogButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  cardContainer: {
    background: "#ddd",
    display: "flex",
    padding: theme.spacing(1.5, 2)
  },
  cardExpiry: {
    fontSize: "0.85rem",
    fontWeight: "400 !important"
  },
  defaultPaymentMethodCardBrand: {
    width: "50px",
    maxWidth: "50px",
    paddingRight: theme.spacing(2.5)
  },
  divider: {
    height: "1px",
    margin: theme.spacing(2, 0, 2, 0),
    backgroundColor: "#ddd"
  },
  confirmContainer: {
    backgroundColor: "#ddd",
    padding: theme.spacing(2),
    "@media (min-width:600px)": {
      padding: theme.spacing(2, 1.5)
    }
  },
  termsLabel: {
    color: theme.palette.background.ffNavyLighter,
    fontSize: "0.8rem",
    lineHeight: "1rem",
    textAlign: "left",
    "@media (min-width:600px)": {
      fontSize: "0.8rem",
      lineHeight: "1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "0.8rem",
      lineHeight: "1rem"
    },
    "& a": {
      color: theme.palette.background.ffNavyLighter,
      fontWeight: 500
    }
  },
  removeUnderline: {
    textDecoration: "none"
  },
  responseBox: {
    textAlign: "center",
    padding: "10%"
  },
  responseIcon: {
    width: "4rem",
    height: "4rem",
    "@media (min-width:600px)": {
      width: "5rem",
      height: "5rem"
    }
  },
  responseHelp: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    lineHeight: "0.9rem",
    textAlign: "center",
    "& a": {
      color: theme.palette.background.ffNavy
    }
  }
}));

export default function PaymentHistory() {
  const classes = useStyles();
  const stripe = useStripe();
  const { addToast } = useToasts();
  const [currency, setCurrency] = useState();

  // Account Context
  const {
    getMembershipDetails,
    getUpgradePreview,
    upgradePreviewDetails,
    updating,
    handleUpgradeMembership,
    resetErrors,
    subscriptionProducts,
    getSubscriptionProducts
  } = useContext(AccountContext);

  const { overview } = useContext(OverviewContext);

  useEffect(() => {
    getSubscriptionProducts();
    return () => resetErrors();
    // eslint-disable-next-line
  }, []);

  const standardProduct =
    subscriptionProducts &&
    subscriptionProducts?.results.find(p => p.memberStatusId === "standard");

  console.log(standardProduct);

  const [reloadTrigger, setReloadTrigger] = useState(Date.now());
  const [membershipDetails, , state] = usePromise(getMembershipDetails, [
    reloadTrigger
  ]);

  const memberShipDetailsLoading = state === "pending";

  useEffect(() => {
    if (membershipDetails && membershipDetails.currency) {
      setCurrency(membershipDetails.currency.toUpperCase());
    } else {
      setCurrency("GBP");
    }
  }, [membershipDetails]);

  const [submittingButton, setSubmittingButton] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [polling, setPolling] = useState(false);
  // const [annualUpgradeSubmitted, setAnnualUpgradeSubmitted] = useState(false);
  const [annualUpgradeSuccess, setAnnualUpgradeSuccess] = useState(false);
  const [annualUpgradeFailure, setAnnualUpgradeFailure] = useState(false);

  // eslint-disable-next-line
  const reload = () => setReloadTrigger(Date.now());

  function CurrencySymbol({ currency }) {
    switch (currency) {
      case "gbp":
        return "\u00A3";
      case "eur":
        return "\u20AC";
      case "usd":
        return "\u0024";
      default:
        return "\u00A3";
    }
  }

  // Handle Upgrade Membership
  const upgradeMembership = useCallback(async () => {
    try {
      setPolling(true);
      handleUpgradeMembership();

      setTimeout(statusPoller, 5000);
    } catch (e) {
      addToast(e.toString(), {
        appearance: "error"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, handleUpgradeMembership]);

  const statusPoller = async () => {
    // Documentation
    // https://stripe.com/docs/billing/subscriptions/fixed-price#manage-payment-authentication

    setPolling(true);
    setReloadTrigger(Date.now());

    console.log("Starting poll...");

    // Get the Latest Payment
    const latestPayment = async () => {
      const memberDetails = await getMembershipDetails();
      const paymentDetails = memberDetails.payments[0];
      return paymentDetails;
    };

    const LP = await latestPayment();

    // If the latest payment entry is failure
    if (LP.status === "failure") {
      const latestInvoice = JSON.parse(LP.paymentProviderRawPayload);

      console.log("in fail");
      // Reason is 'Action Required', then the payment requires action
      if (latestInvoice.payment_intent.status === "requires_action") {
        console.log("Action Required...");

        const clientSecret = latestInvoice.payment_intent.client_secret;
        const paymentMethod = latestInvoice.payment_intent.payment_method;

        // Pass client_ secret and payment_intent to confirmCardPayment (SCA Modal)
        return stripe
          .confirmCardPayment(clientSecret, {
            payment_method: paymentMethod
          })
          .then(result => {
            if (result.error) {
              console.log("Payment Auth failure...", result);
              setPolling(false);
              setAnnualUpgradeFailure(true);

              return;
            } else {
              if (result.paymentIntent.status === "succeeded") {
                console.log("Payment Auth success...");
                setPolling(false);
                setAnnualUpgradeSuccess(true);
                return;
              }
            }
          })
          .catch(error => {
            setPolling(false);
            setAnnualUpgradeFailure(true);
            return;
          });
      }

      console.log("Success...");

      setPolling(false);
      setAnnualUpgradeFailure(true);
      return;
    }
    // If the latest payment entry is success, show success message
    else if (LP.status === "success") {
      console.log("Payment Success - No Auth Required.");
      setPolling(false);
      setAnnualUpgradeSuccess(true);
      return;
    }
  };

  const handleTermsChecked = event => {
    setTermsChecked(event.target.checked);
    if (termsChecked) {
      setSubmittingButton(false);
    } else {
      setSubmittingButton(true);
    }
    // setSubmittingButton(true);
  };

  useEffect(() => {
    getUpgradePreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Renewal Date config
  let renewalDate = new Date();

  renewalDate = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit"
  }).format(new Date(renewalDate.setFullYear(renewalDate.getFullYear() + 1)));

  return (
    // wrap a check around this to ensure the user is on a monthly plan and that the upgrade option is available
    <>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbContainer}
          >
            <Link className={classes.activeBreadcrumb} to="/myaccount/overview">
              Overview
            </Link>
            <Typography className={classes.disabledBreadcrumb}>
              Annual Upgrade
            </Typography>
          </Breadcrumbs>
        </Grid>

        {membershipDetails && !upgradePreviewDetails && (
          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12} align="center">
                <Typography variant="h2" className={classes.myAccountSubTitle}>
                  Upgrade Not Available
                </Typography>
                <Typography variant="body1" className={classes.bodyText}>
                  The Annual Upgrade is not available. Return to &apos;My
                  Account&apos;.
                </Typography>
                <Link
                  to="/myaccount/overview"
                  className={classes.removeUnderline}
                >
                  <Button size="large">My Account</Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        )}
        {overview &&
          overview.doors.annualUpgradeOpen &&
          membershipDetails &&
          membershipDetails.hasStripeCustomer &&
          membershipDetails.productId.includes("monthly") && (
            <>
              {updating && polling && (
                <div
                  className={classes.background}
                  style={{ textAlign: "center" }}
                >
                  <CircularProgress color="primary" />
                  <Typography
                    variant="body1"
                    className={classes.bodyText}
                    style={{ marginTop: "12px" }}
                  >
                    <b>Updating...</b>
                    <br />
                    Do not refresh the page or close your browser
                  </Typography>
                </div>
              )}

              {membershipDetails &&
                upgradePreviewDetails &&
                !updating &&
                !polling && (
                  <Container maxWidth="sm">
                    {!annualUpgradeSuccess && !annualUpgradeFailure && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              variant="h2"
                              className={classes.myAccountSubTitle}
                            >
                              Upgrade to Annual Plan
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography className={classes.currentPlanLabel}>
                              Current Plan
                            </Typography>
                            <Box
                              className={
                                classes.membershipPlanContainerInnerDisabled
                              }
                            >
                              <Typography className={classes.productTitle}>
                                Monthly
                                <br />
                                Membership
                              </Typography>
                              <br />
                              <Typography className={classes.productCost}>
                                <CurrencySymbol
                                  currency={membershipDetails.currency}
                                />
                                {membershipDetails.subscription.cost / 100}
                                <br />
                                <span>per month</span>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box className={classes.membershipPlanContainerMid}>
                              <ArrowForwardIcon />
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography className={classes.promoLabel}>
                              Save 30%
                            </Typography>
                            <Box
                              className={classes.membershipPlanContainerInner}
                            >
                              <Typography className={classes.productTitle}>
                                Annual
                                <br />
                                Membership
                              </Typography>
                              <br />
                              {subscriptionProducts.results
                                .filter(
                                  subscription =>
                                    subscription.currency === currency &&
                                    subscription.cost > 0 &&
                                    subscription.entitlementPeriodUnit ===
                                      "Year"
                                )
                                .map((subscription, index) => (
                                  <>
                                    <Typography className={classes.productCost}>
                                      <CurrencySymbol
                                        currency={membershipDetails.currency}
                                      />
                                      {subscription.cost}
                                      <br />
                                      <span>per year</span>
                                    </Typography>
                                  </>
                                ))}
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider className={classes.divider} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              className={classes.bodyText}
                            >
                              By upgrading to an Annual FarmFLiX Membership,
                              your billing will be adjusted. As you are
                              currently in a plan, we have calculated the
                              difference of{" "}
                              <b>
                                <CurrencySymbol
                                  currency={upgradePreviewDetails.currency}
                                />
                                {(
                                  upgradePreviewDetails.amount_due / 100
                                ).toFixed(2)}
                              </b>{" "}
                              to upgrade to the Annual Plan.{" "}
                            </Typography>
                            {membershipDetails.defaultPaymentMethod ? (
                              <>
                                <Typography
                                  variant="body2"
                                  className={classes.bodyText}
                                >
                                  If you wish to proceed, we will attempt to
                                  bill{" "}
                                  <b>
                                    {" "}
                                    <CurrencySymbol
                                      currency={upgradePreviewDetails.currency}
                                    />
                                    {(
                                      upgradePreviewDetails.amount_due / 100
                                    ).toFixed(2)}
                                  </b>{" "}
                                  to your current saved payment method:
                                </Typography>
                                <Box className={classes.cardContainer}>
                                  <Box>
                                    {membershipDetails.defaultPaymentMethod
                                      .cardBrand === "visa" && (
                                      <img
                                        alt={
                                          membershipDetails.defaultPaymentMethod
                                            .cardBrand
                                        }
                                        src={visa}
                                        className={
                                          classes.defaultPaymentMethodCardBrand
                                        }
                                      />
                                    )}
                                    {membershipDetails.defaultPaymentMethod
                                      .cardBrand === "mastercard" && (
                                      <img
                                        alt={
                                          membershipDetails.defaultPaymentMethod
                                            .cardBrand
                                        }
                                        src={mastercard}
                                        className={
                                          classes.defaultPaymentMethodCardBrand
                                        }
                                      />
                                    )}
                                    {membershipDetails.defaultPaymentMethod
                                      .cardBrand === "amex" && (
                                      <img
                                        alt={
                                          membershipDetails.defaultPaymentMethod
                                            .cardBrand
                                        }
                                        src={amex}
                                        className={
                                          classes.defaultPaymentMethodCardBrand
                                        }
                                      />
                                    )}
                                    {membershipDetails.defaultPaymentMethod
                                      .cardBrand === "discover" && (
                                      <img
                                        alt={
                                          membershipDetails.defaultPaymentMethod
                                            .cardBrand
                                        }
                                        src={discover}
                                        className={
                                          classes.defaultPaymentMethodCardBrand
                                        }
                                      />
                                    )}
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="body1"
                                      className={classes.bodyTextSml}
                                    >
                                      <span>
                                        **** **** ****{" "}
                                        {
                                          membershipDetails.defaultPaymentMethod
                                            .last4
                                        }
                                      </span>
                                      <br />
                                      <span className={classes.cardExpiry}>
                                        Exp:{" "}
                                        {
                                          membershipDetails.defaultPaymentMethod
                                            .exp.month
                                        }{" "}
                                        /{" "}
                                        {
                                          membershipDetails.defaultPaymentMethod
                                            .exp.year
                                        }
                                      </span>
                                    </Typography>
                                  </Box>
                                </Box>
                              </>
                            ) : (
                              <Typography
                                variant="body1"
                                className={classes.bodyText}
                              >
                                You have no saved payment method. Set up now.
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.confirmContainer}
                          >
                            <Box
                              display="flex"
                              className={classes.termsContainer}
                            >
                              <Box>
                                <Checkbox
                                  color="primary"
                                  style={{ color: "#99ca3c" }}
                                  onChange={handleTermsChecked}
                                  inputProps={{
                                    name: "terms",
                                    "aria-label": "primary checkbox"
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  variant="body2"
                                  className={classes.termsLabel}
                                >
                                  I agree to FarmFLiX&apos;s{" "}
                                  <Link to="/terms">Terms and Conditions</Link>{" "}
                                  and{" "}
                                  <Link to="/privacy-policy">
                                    Privacy Policy
                                  </Link>
                                  .
                                  <>
                                    Your next membership payment will be taken
                                    on <b>{renewalDate}</b>. FarmFLiX will
                                    automatically track and charge your
                                    membership fee{" "}
                                    <b>
                                      (
                                      {subscriptionProducts.results
                                        .filter(
                                          subscription =>
                                            subscription.currency ===
                                              currency &&
                                            subscription.cost > 0 &&
                                            subscription.entitlementPeriodUnit ===
                                              "Year"
                                        )
                                        .map((subscription, index) => (
                                          <>
                                            <CurrencySymbol
                                              currency={
                                                membershipDetails.currency
                                              }
                                            />
                                            {subscription.cost}
                                          </>
                                        ))}
                                      /Year)
                                    </b>{" "}
                                    until you cancel your membership.
                                  </>
                                </Typography>
                              </Box>
                            </Box>
                            <Grid item xs={12} sm={12} md={12} align="center">
                              <Button
                                size="large"
                                style={{ marginTop: "18px" }}
                                onClick={upgradeMembership}
                                disabled={!submittingButton}
                              >
                                Upgrade to Annual
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {annualUpgradeSuccess && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box className={classes.responseBox}>
                              <CheckCircleIcon
                                className={classes.responseIcon}
                                color="primary"
                              />
                              <Typography
                                component="h2"
                                variant="h2"
                                className={classes.myAccountSubTitle}
                              >
                                Membership Upgraded!
                              </Typography>
                              <Typography
                                variant="h3"
                                className={classes.myAccountThirdTitle}
                                style={{ textAlign: "center" }}
                              >
                                Payment Successfully Processed!
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.bodyText}
                                style={{ textAlign: "center" }}
                              >
                                Thank you for your payment. Your FarmFLiX
                                Membership has been upgraded.
                              </Typography>
                              <Link
                                to="/videos"
                                style={{ textDecoration: "none" }}
                              >
                                <Button size="large">Watch Now</Button>
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {annualUpgradeFailure && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box className={classes.responseBox}>
                              <CheckCircleIcon
                                className={classes.responseIcon}
                                color="primary"
                              />
                              <Typography
                                component="h2"
                                variant="h2"
                                className={classes.myAccountSubTitle}
                              >
                                Payment Failed
                              </Typography>
                              <Typography
                                variant="h3"
                                className={classes.myAccountThirdTitle}
                                style={{ textAlign: "center" }}
                              >
                                We were unable to charge your card for your
                                FarmFLiX Membership.
                              </Typography>
                              <Typography
                                variant="body1"
                                className={classes.bodyText}
                                style={{ textAlign: "center" }}
                              >
                                Please get in touch with FarmFLiX Support.
                              </Typography>
                              <Link
                                to="/myaccount/overview"
                                style={{ textDecoration: "none" }}
                              >
                                <Button size="large">My Account</Button>
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Container>
                )}
              {memberShipDetailsLoading && (
                <div
                  className={classes.background}
                  style={{ textAlign: "center" }}
                >
                  <CircularProgress color="primary" />
                  <Typography
                    variant="body1"
                    className={classes.bodyText}
                    style={{ marginTop: "12px" }}
                  >
                    Loading...
                  </Typography>
                </div>
              )}
            </>
          )}
        {overview && !overview.doors.annualUpgradeOpen && (
          <>
            <Container maxWidth="sm">
              <Grid container>
                <Grid item xs={12} align="center">
                  <Typography
                    variant="h2"
                    className={classes.myAccountSubTitle}
                  >
                    Upgrade Not Available
                  </Typography>
                  <Typography variant="body1" className={classes.bodyText}>
                    The Annual Upgrade is no longer available. Return to
                    &apos;My Account&apos;.
                  </Typography>
                  <Link
                    to="/myaccount/overview"
                    className={classes.removeUnderline}
                  >
                    <Button size="large">My Account</Button>
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </Grid>
    </>
  );
}
