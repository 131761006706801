import React, { useContext, useEffect } from "react";
import { OverviewContext } from "../store/OverviewContext";
import { AccountContext } from "../store/AccountContext";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import DefaultLayout from "../layouts/DefaultLayout";
import CircularProgress from "@material-ui/core/CircularProgress";
import PreviewCTA from "./Home/PreviewCTA";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import PreviewVideoRow from "./Video/PreviewVideoRow";
// import DoorsClosingCountdown from "./Banners/DoorsClosingCountdown";
import { Player } from "./common/player";
import { PlayerContext } from "../store/PlayerContext";

const useStyles = makeStyles(theme => ({
  background: {
    width: "100%",
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy
  },
  loading: {
    textAlign: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  }
}));

const LatestPreview = () => {
  const { overview } = useContext(OverviewContext);
  const { loggedIn } = useContext(AccountContext);
  const classes = useStyles();
  const {
    currentPreviewVideo,
    loadPreviewVideo,
    clearCurrentPreviewVideo
  } = useContext(PlayerContext);

  useEffect(() => {
    return () => {
      clearCurrentPreviewVideo();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!overview?.latest) {
      return;
    }

    const latest = Array.isArray(overview.latest)
      ? overview.latest[0]
      : overview.latest;

    if (latest?.slug) {
      loadPreviewVideo(latest.slug);
    }
    // eslint-disable-next-line
  }, [overview?.latest]);

  if (loggedIn) {
    return <Redirect to="/latest" />;
  }

  return (
    <DefaultLayout>
      {overview && currentPreviewVideo ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>FarmFLiX.tv | Preview of this Week&apos;s Episode</title>
            <meta
              name="description"
              content="View a quick peek at this week's episode.  Want to view the Full Episode? Take a Free 72H Trial Now!"
            />
          </Helmet>
          {/* {overview && overview.doors?.open && (
            <div style={{ width: "100%" }}>
              <DoorsClosingCountdown />
            </div>
          )} */}
          <div className={classes.background}>
            <Container>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Player
                    userId={undefined}
                    video={currentPreviewVideo}
                    type="preview"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PreviewVideoRow video={currentPreviewVideo} />
                </Grid>
              </Grid>
            </Container>
            <PreviewCTA />
          </div>
        </>
      ) : (
        <div className={`${classes.background} ${classes.loading}`}>
          <CircularProgress color="primary" />
        </div>
      )}
    </DefaultLayout>
  );
};

export default LatestPreview;
