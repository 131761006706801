import React, { useContext, useEffect, useCallback } from "react";
import { Typography, Container, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccountContext } from "../../store/AccountContext";
import { OverviewContext } from "../../store/OverviewContext";
import { Link } from "react-router-dom";
import BackdropLoader from "../common/BackdropLoader";
import { useToasts } from "react-toast-notifications";
import Countdown from "react-countdown";
import useLogger from "../../hooks/useLogger";
import dayjs from "dayjs";
// import DoorsClosingCountdown from "../Banners/DoorsClosingCountdown";
// import { MEMBER_STATUS_STANDARD } from "@booyaltd/core";

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(https://farmflix-images-test.s3-eu-west-1.amazonaws.com/freetrial_bg.jpg)`,
    boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.8)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "scroll",
    width: "100%",
    height: "75vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (min-width:600px)": {
      height: "500px"
    },
    "@media (min-width:960px)": {
      height: "550px"
    }
  },
  username: {
    color: theme.palette.common.white,
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    textAlign: "left",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      fontWeight: 600
    },
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  header: {
    color: theme.palette.common.white,
    fontSize: "3.2rem",
    lineHeight: "3rem",
    textAlign: "left",
    fontWeight: 500,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      fontWeight: 600
    },
    "@media (min-width:600px)": {
      fontSize: "3.6rem",
      lineHeight: "3.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "4.6rem",
      lineHeight: "4.4rem"
    }
  },
  subHeader: {
    color: "#bbb",
    fontSize: "1.3rem",
    lineHeight: "1.4rem",
    fontWeight: "500",
    textAlign: "left",
    width: "90%",
    textShadow: "-2px 2px 2px #171717",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.3rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.6rem"
    }
  },
  bodyText: {
    // textShadow: "-1px 1px 1px #171717",
    color: "#eee",
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "0.8rem",
      lineHeight: "1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "0.8rem",
      lineHeight: "1rem"
    }
  },
  ORText: {
    fontSize: "0.8rem",
    width: "150px",
    textAlign: "left",
    margin: theme.spacing(1),
    "@media (min-width:600px)": {
      textAlign: "center"
    }
  },
  joinNowButton: {
    fontSize: "1.2rem",
    width: "150px",
    textAlign: "left",
    margin: theme.spacing(1),
    fontWeight: 500,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    },
    "@media (min-width:600px)": {
      textAlign: "center"
    }
  },
  bottomText: {
    marginTop: theme.spacing(2)
  },
  countdown: {
    color: theme.palette.background.ffNavy,
    fontSize: "3rem",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "3.2rem",
      lineHeight: "3.4rem",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    },
    "@media (min-width:960px)": {
      fontSize: "4rem",
      lineHeight: "4.2rem",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0)
    }
  },
  countdownContainer: {
    padding: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(0)
    }
  },
  countdownWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  },
  countdownLabelWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  countdownUnitWrapper: {
    textAlign: "center",
    backgroundColor: "rgba(5,5,5,0.7)",
    padding: "2% 0",
    fontSize: "2rem",
    fontWeight: "600",
    color: "#eeeeee",
    maxWidth: "80px",
    margin: "0 0.7%",
    borderRadius: 5,
    "@media (min-width:600px)": {
      padding: "1.8% 0",
      fontSize: "2rem",
      fontWeight: "600"
    },
    "@media (min-width:960px)": {
      padding: "2% 0",
      fontSize: "2.2rem",
      fontWeight: "600"
    }
  },
  countdownUnitFirstWrapper: {
    margin: 0
  },
  countdownUnitLabel: {
    fontSize: "0.9rem",
    fontWeight: 600,
    textAlign: "center",
    color: theme.palette.common.white,
    maxWidth: "80px",
    margin: "0 0.7%",
    "@media (min-width:600px)": {
      fontSize: "0.9rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem"
    }
  },
  originalPrice: {
    width: "fit-content",
    color: "#ddd",
    letterSpacing: "-1px",
    fontSize: "1.4rem",
    lineHeight: "1.6rem",
    textAlign: "left",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontWeight: 600,
      fontSize: "1.3rem",
      lineHeight: "1.4rem",
      marginBottom: theme.spacing(0.5)
    },
    "@media (min-width:960px)": {
      marginBottom: theme.spacing(1.5),
      fontSize: "1.7rem",
      lineHeight: "1.4rem"
    }
  },
  discountPrice: {
    color: theme.palette.background.ffGreen,
    fontSize: "2.8rem",
    lineHeight: "2.2rem",
    letterSpacing: "-1px",
    textAlign: "left",
    fontWeight: 600,
    textShadow: "-1px 1px 1px #171717",
    marginBottom: theme.spacing(2),
    width: "fit-content",
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "2.4rem"
    }
  }
}));

const CTABanner = props => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const { logMetricEvent } = useLogger();

  const {
    userProfile,
    subscriptionProducts,
    getSubscriptionProducts,
    assignTrialMembership,
    loading
  } = useContext(AccountContext);

  const { overview } = useContext(OverviewContext);

  useEffect(() => {
    getSubscriptionProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const standardProduct =
    subscriptionProducts &&
    subscriptionProducts?.results.find(p => p.memberStatusId === "standard");

  const trialProduct =
    subscriptionProducts &&
    subscriptionProducts?.results.find(p => p.memberStatusId === "trial");

  const discountedStandardProduct =
    subscriptionProducts &&
    subscriptionProducts?.results.find(
      p => p.memberStatusId === "standard" && p.discounted
    );

  const takeTrial = useCallback(async () => {
    try {
      logMetricEvent({
        type: "free-trial",
        payload: {}
      });
      await assignTrialMembership();
    } catch (e) {
      addToast(e.toString(), {
        appearance: "error"
      });
    }
  }, [assignTrialMembership, addToast, logMetricEvent]);

  const RenderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <React.Fragment />;
    }
    return (
      <Container className={classes.countdownContainer}>
        <Grid container className={classes.countdownWrapper}>
          {days > 0 ? (
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              className={classes.countdownUnitWrapper}
            >
              <span>
                {days.toString().length === 1 ? "0" : ""}
                {days}
              </span>
            </Grid>
          ) : null}
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            className={classes.countdownUnitWrapper}
          >
            <span>
              {hours.toString().length === 1 ? "0" : ""}
              {hours}
            </span>
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            className={classes.countdownUnitWrapper}
          >
            <span>
              {minutes.toString().length === 1 ? "0" : ""}
              {minutes}
            </span>
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            className={classes.countdownUnitWrapper}
          >
            <span>
              {seconds.toString().length === 1 ? "0" : ""}
              {seconds}
            </span>
          </Grid>
        </Grid>
        <Grid container className={classes.countdownLabelWrapper}>
          {days > 0 ? (
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              className={classes.countdownUnitLabel}
            >
              Days
            </Grid>
          ) : null}
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            className={classes.countdownUnitLabel}
          >
            Hours
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            className={classes.countdownUnitLabel}
          >
            Mins
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            className={classes.countdownUnitLabel}
          >
            Secs
          </Grid>
        </Grid>
      </Container>
    );
  };

  const BuildCTA = props => {
    return (
      <>
        <Typography component="h1" variant="h1" className={classes.header}>
          {props.title}
        </Typography>
        <Typography component="h2" variant="h2" className={classes.subHeader}>
          {props.subtitle}
        </Typography>
        {standardProduct && (
          <Link to="/myaccount/join-now" style={{ textDecoration: "none" }}>
            <Button name="Join Now" size="large">
              Join Now
            </Button>
          </Link>
        )}
        {trialProduct && standardProduct && (
          <>
            <Typography className={classes.ORText}>or</Typography>
          </>
        )}
        {trialProduct && (
          <Typography className={classes.joinNowButton} onClick={takeTrial}>
            72H Free Trial
          </Typography>
        )}
      </>
    );
  };

  const BuildCountdownCTA = props => {
    const formatter = new Intl.NumberFormat("en-GB", {
      currency: discountedStandardProduct?.currency,
      style: "currency"
    });
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h1"
              className={classes.header}
              style={{ color: "#fff", letterSpacing: "-2px", fontWeight: 600 }}
            >
              Limited Time Discount
            </Typography>
            <Typography
              component="h2"
              variant="h2"
              className={classes.originalPrice}
            >
              {" "}
              Was{" "}
              {formatter.format(discountedStandardProduct?.discountedOrigCost)}
            </Typography>
            <Typography
              component="h2"
              variant="h2"
              className={classes.discountPrice}
            >
              {" "}
              Now {formatter.format(discountedStandardProduct?.cost)}
            </Typography>
          </Grid>
        </Grid>
        {discountedStandardProduct.availableUntil ? (
          <Grid container>
            <Grid item xs={12} sm={8} md={6}>
              <Countdown
                date={dayjs(discountedStandardProduct.availableUntil).toDate()}
                renderer={RenderCountdown}
                zeroPadTime={2}
                daysInHours={true}
              />
            </Grid>
          </Grid>
        ) : null}
        {standardProduct && (
          <>
            <Link to="/myaccount/join-now" style={{ textDecoration: "none" }}>
              <Button name="Join Now" size="large">
                Join Now
              </Button>
            </Link>
            <Typography variant="body2" className={classes.bottomText}>
              <b>Don&apos;t Miss Out!</b>
            </Typography>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {loading && <BackdropLoader />}

      {userProfile && overview && (
        <>
          {/* Join Now Countdown should be displayed to:
          - Standard with Auto Renew Off
          - Standard Expired with Auto Renew Off
          - Trial
          - Trial Expired
          - Locked
          - Standard Incomplete

          Price Increase should be displayed to:
          - Standard with Auto Renew Off
          - Standard Expired with Auto Renew Off
          - Trial
          - Trial Expired
          - Locked
          - Standard Incomplete
 */}

          {/* {!discountedStandardProduct &&
            userProfile.memberStatusId !== MEMBER_STATUS_STANDARD &&
            !overview.doors.annualUpgradeOpen && (
              <>
                <DoorsClosingCountdown title="You Can Join Now" disableButton />
              </>
            )}

          {!discountedStandardProduct &&
            userProfile.memberStatusId === MEMBER_STATUS_STANDARD &&
            !userProfile.productAutoRenewing &&
            !overview.doors.annualUpgradeOpen && (
              <>
                <DoorsClosingCountdown
                  title="Don't Miss Out! Enable Auto Renew Now"
                  disableButton
                />
              </>
            )} */}

          <div className={classes.background}>
            <Container fixedcomponent="main" maxWidth="lg">
              <div className={classes.box}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Typography className={classes.username}>
                      {userProfile.firstName},{" "}
                    </Typography>
                    {/*
                      Switch the message around the subscripiton status
                      standard-expired - It's Time to Upgrade
                      UPDATE
                      trial-expired w/ Discount Product -  Show Countdown
                      trial-expired wo/ Discount Product -  Enjoyed Your Trial
                      standard-incomplete - Complete Your Membership
                      locked - Your Membership is Unlocked
                    */}

                    {discountedStandardProduct &&
                      [
                        "trial-expired",
                        "standard-incomplete",
                        "standard-expired",
                        "locked"
                      ].includes(userProfile.memberStatus) && (
                        <>
                          <BuildCountdownCTA
                            product={discountedStandardProduct}
                          />
                        </>
                      )}

                    {!discountedStandardProduct &&
                      userProfile.memberStatus === "standard-expired" && (
                        <>
                          <BuildCTA
                            title="Jump Back In Again"
                            subtitle="Get going again in Two Simple Steps."
                          />
                        </>
                      )}

                    {/* CHECKED */}
                    {!discountedStandardProduct &&
                      userProfile.memberStatus === "trial-expired" && (
                        <>
                          <BuildCTA
                            title="Enjoyed Your Trial?"
                            subtitle="Want More? Join Now in Two Simple Steps."
                          />
                        </>
                      )}

                    {/* CHECKED */}
                    {!discountedStandardProduct &&
                      userProfile.memberStatus === "standard-incomplete" && (
                        <>
                          <BuildCTA
                            title="Complete Your Membership"
                            subtitle="Join Now in Two Simple Steps."
                          />
                        </>
                      )}
                    {/* CHECKED */}
                    {!discountedStandardProduct &&
                      userProfile.memberStatus === "locked" && (
                        <>
                          <BuildCTA
                            title="We're Open!"
                            subtitle="Join Now in Two Simple Steps"
                          />
                        </>
                      )}
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default CTABanner;
