import React, { useContext, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container } from "@material-ui/core";
import LatestLayout from "../layouts/LatestLayout";
import ReactPlayer from "react-player";
import { OverviewContext } from "../store/OverviewContext";
import { AccountContext } from "../store/AccountContext";
import { PlayerContext } from "../store/PlayerContext";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import DefaultLayout from "../layouts/DefaultLayout";
import CircularProgress from "@material-ui/core/CircularProgress";
import VideoPlayerDetails from "./Video/VideoPlayerDetails";
import PromoPoster from "./Dashboard/PromoPoster";

const useStyles = makeStyles(theme => ({
  background: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(4)
  },
  loading: {
    textAlign: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  marginSpacing: {
    margin: "20px 0"
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%"
  },
  player: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    "& video": {
      "&:focus": {
        outline: "none"
      }
    }
  },
  playIcon: {
    fontSize: "6rem",
    "@media (min-width:600px)": {
      fontSize: "7rem"
    },
    "@media (min-width:960px)": {
      fontSize: "8rem"
    }
  },
  upgradeBannerBackground: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.upgrade,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: "center"
  },
  upgradeTitle: {
    marginBottom: theme.spacing(1),
    fontSize: "1.8rem",
    fontWeight: "500",
    color: theme.palette.common.white
  },
  upgradeSubTitle: {
    marginBottom: theme.spacing(2),
    fontSize: "1.4rem",
    fontWeight: "400",
    color: theme.palette.background.white
  },
  upgradeBodyText: {
    marginBottom: theme.spacing(2),
    color: theme.palette.background.white,
    fontWeight: 300,
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    }
  },
  channelImage: {
    margin: "0 5% 0 15%",
    borderRadius: "50%",
    width: "80%",
    "@media (min-width:600px)": {
      margin: "0 5% 0 35%",
      borderRadius: "50%",
      width: "60%"
    },
    "@media (min-width:960px)": {
      margin: "0 5% 0 35%",
      borderRadius: "50%",
      width: "60%"
    }
  },
  relatedVideoList: {},
  relatedVideoListTitle: {
    marginBottom: "10px",
    color: theme.palette.common.lightgray
  },
  creditsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  creditsTitle: {
    color: theme.palette.background.upgradeAlt
  },
  creditsDetail: {
    color: "#c3c3c3",
    margin: "4px 0",
    "& span": {
      color: theme.palette.background.upgradeAlt
    }
  },
  tagsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  tagsTitle: {
    marginBottom: theme.spacing(1),
    color: theme.palette.background.upgradeAlt
  },
  legacyText: {
    fontSize: "0.8rem",
    lineHeight: "0.8rem",
    color: theme.palette.background.upgradeAlt
  },
  legacyLink: {
    color: theme.palette.background.ffGreen
  }
}));

const LatestMasterLegacy = props => {
  const { overview } = useContext(OverviewContext);
  const { loggedIn, loading } = useContext(AccountContext);

  const {
    loadSingleVideo,
    currentVideo,
    currentVideoSource,
    clearCurrentVideo,
    onVideoProgress
  } = useContext(PlayerContext);

  let videoId = "";

  if (overview && overview.latest) {
    videoId = overview.latest.slug;
  }

  useEffect(() => {
    return () => {
      clearCurrentVideo();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (videoId) {
      loadSingleVideo(videoId);
    }
    // eslint-disable-next-line
  }, [videoId]);

  // Add video progress logs
  const addProgress = event => {
    console.log("log");
    onVideoProgress({ playbackPosition: Math.floor(event.playedSeconds) });
  };

  const player = useRef();

  const classes = useStyles();

  if (loading && !loggedIn) {
    return (
      <DefaultLayout>
        <div className={`${classes.background} ${classes.loading}`}>
          <CircularProgress color="primary" />
        </div>
      </DefaultLayout>
    );
  }

  return (
    <div>
      <LatestLayout>
        {overview && currentVideo && (
          <>
            <div className={classes.background}>
              <Container>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.playerWrapper}
                  >
                    <ReactPlayer
                      playing
                      ref={player}
                      className={classes.player}
                      url={currentVideoSource}
                      onProgress={addProgress}
                      progressInterval={5000}
                      controls={true}
                      width="100%"
                      height="100%"
                      light={currentVideo.poster}
                      playIcon={
                        <PlayCircleFilledIcon
                          color="primary"
                          className={classes.playIcon}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <VideoPlayerDetails video={currentVideo} />
                  </Grid>
                </Grid>
              </Container>
            </div>
            {overview && overview.promo && (
              <div className={classes.background}>
                <PromoPoster promo={overview.promo} />
              </div>
            )}
          </>
        )}
      </LatestLayout>
    </div>
  );
};

export default LatestMasterLegacy;
