import React, { useContext } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import { Field, Form } from "react-final-form";
import Alert from "@material-ui/lab/Alert";
import { IUserMediaContentType, IUserMediaItemType } from "@booyaltd/core";
import { composeValidators, isEmail, isRequired } from "../../utils/validators";
import { AccountContext } from "../../store/AccountContext";
import classnames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { sendSupportTicket } from "../../api/client";
import ImageUploaderField from "../MediaUpload/ImageUploaderField";
const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    // Hide scrollbar for Chrome, Safari and Opera
    "&::-webkit-scrollbar": {
      display: "none"
    },
    // Hide scrollbar for IE, Edge and Firefox
    "-ms-overflow-style": "none",
    scrollbarWidth: "none"
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    textAlign: "left",
    color: theme.palette.common.white
  },
  textField: {
    "& .MuiFormLabel-root": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
      color: theme.palette.common.white
    }
  },
  dialogIcon: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontSize: "8rem"
  },
  dialogText: {
    color: theme.palette.common.white,
    margin: theme.spacing(1, 0)
  },
  dialogTextLarge: {
    fontSize: "1.5rem",
    fontWeight: 600
  },
  dialogTextContainer: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1)
  },
  form: {},
  formFieldSet: {
    // padding: "4px 0 !important",
    // width: "100%",
    // position: "relative",
    "& input": {
      background: "#fff",
      marginBottom: theme.spacing(2)
    },
    "& textarea": {
      background: "#fff",
      padding: theme.spacing(1)
    }
  },
  dialogSubmit: {},
  submitLoader: {},
  circularProgress: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1)
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: theme.palette.common.white
  },
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    marginTop: theme.spacing(1),
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    // @ts-ignore
    // backgroundColor: theme.palette.background.ffNavyLighter,
    backgroundColor: "#353535",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer"
  },
  active: {
    borderColor: theme.palette.action.active
  },
  accept: {
    borderColor: "#00e676"
  },
  reject: {
    borderColor: "#ff1744"
  }
}));

export type SupportModalProps = {
  onClose: () => void;
};

type FormValues = {
  name: string;
  email: string;
  subject: string;
  message: string;
  mediaId?: string;
};

export const SupportModal = ({ onClose }: SupportModalProps) => {
  const classes = useStyles();
  const { userProfile, loggedIn } = useContext(AccountContext);
  const [sent, setSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    setError("");

    const request = {
      name: values.name,
      email: values.email,
      subject: values.subject,
      message: values.message,
      mediaId: values.mediaId
    };

    try {
      await sendSupportTicket(request);
      setSent(true);
    } catch (e) {
      setError("Something went wrong. Please try again later.");
    }
    setLoading(false);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={true}
      fullWidth
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      classes={{ paper: classes.root }}
    >
      <MuiDialogTitle
        id="customized-dialog-title"
        disableTypography
        className={classes.title}
      >
        {!sent ? "Contact Support" : "Success"}
      </MuiDialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {sent ? (
          <>
            <DialogContentText style={{ textAlign: "center" }}>
              <CheckCircleIcon className={classes.dialogIcon} />
            </DialogContentText>
            <div
              style={{ textAlign: "center" }}
              className={classes.dialogTextContainer}
            >
              <Typography
                className={classnames(
                  classes.dialogText,
                  classes.dialogTextLarge
                )}
              >
                {"We've received your message!"}
              </Typography>

              <Typography className={classes.dialogText}>
                We will respond to you by email as soon as possible.
              </Typography>
            </div>
          </>
        ) : (
          <>
            <div className={classes.dialogTextContainer}>
              <Typography className={classes.dialogText}>
                Please fill out the form below to contact support. We will
                respond to you using the email address below.
              </Typography>
            </div>
            <Grid container>
              <Grid item xs={12}>
                <Form
                  onSubmit={onSubmit}
                  initialValues={{
                    name: userProfile
                      ? `${userProfile.firstName} ${userProfile.lastName}`
                      : "",
                    email: userProfile?.email || "",
                    subject: "",
                    message: ""
                  }}
                  validateOnBlur={false}
                >
                  {props => (
                    <form onSubmit={props.handleSubmit}>
                      <div className={classes.form}>
                        {error && (
                          <Alert
                            variant="filled"
                            severity="error"
                            style={{ margin: "10px 0" }}
                          >
                            {error}
                          </Alert>
                        )}
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={classes.formFieldSet}
                          >
                            <Field name="name" validate={isRequired}>
                              {({ input, meta }) => (
                                <TextField
                                  className={classes.textField}
                                  fullWidth
                                  {...input}
                                  disabled={loggedIn}
                                  type="text"
                                  label="Name"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  error={meta.error && meta.touched}
                                  helperText={meta.touched ? meta.error : ""}
                                />
                              )}
                            </Field>
                            <Field
                              name="email"
                              validate={composeValidators(isRequired, isEmail)}
                            >
                              {({ input, meta }) => (
                                <TextField
                                  className={classes.textField}
                                  fullWidth
                                  {...input}
                                  disabled={loggedIn}
                                  type="email"
                                  label="Email"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  error={meta.error && meta.touched}
                                  helperText={meta.touched ? meta.error : ""}
                                />
                              )}
                            </Field>

                            <Field name="subject" validate={isRequired}>
                              {({ input, meta }) => (
                                <TextField
                                  className={classes.textField}
                                  fullWidth
                                  {...input}
                                  type="text"
                                  label="Subject"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  error={meta.error && meta.touched}
                                  helperText={meta.touched ? meta.error : ""}
                                />
                              )}
                            </Field>

                            <Field name="message" validate={isRequired}>
                              {({ input, meta }) => (
                                <TextField
                                  className={classes.textField}
                                  fullWidth
                                  {...input}
                                  type="text"
                                  rows={3}
                                  rowsMax={5}
                                  multiline={true}
                                  label="Message"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.touched
                                      ? meta.error
                                      : "Describe the issue you are having in as much detail as possible."
                                  }
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Field name="mediaId">
                              {({ meta }) => (
                                <ImageUploaderField
                                  error={
                                    props.submitFailed && !props.submitting
                                      ? meta.error
                                      : undefined
                                  }
                                  type={IUserMediaItemType.Document}
                                  contentType={[
                                    IUserMediaContentType.PNG,
                                    IUserMediaContentType.JPEG
                                  ]}
                                  onChange={mediaId => {
                                    props.form.change("mediaId", mediaId);
                                  }}
                                  public={true}
                                  dropzoneText="Attach an image (optional)"
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Button
                              type="submit"
                              size="large"
                              fullWidth
                              className={classes.dialogSubmit}
                              disabled={loading}
                            >
                              {!loading && <>Submit</>}
                              {loading && (
                                <>
                                  Submitting{" "}
                                  <CircularProgress
                                    size={20}
                                    className={classes.circularProgress}
                                  />
                                </>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  )}
                </Form>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
