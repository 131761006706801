import React, { createContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  acceptTerms as apiAcceptTerms,
  API_EVENT_UNAUTHORIZED,
  apiEventEmitter,
  getMe as apiGetMe,
  loginWithPhone as apiLoginWithPhone,
  loginWithUsernamePassword as apiLoginWithUsernamePassword,
  register,
  createPayment as apiCreatePayment,
  logout as apiLogout,
  sendFirebaseToken as apiSendFirebaseToken,
  updateMe,
  verifyPhone as apiVerifyPhone,
  getCode,
  getProducts,
  getMembershipDetails as apiGetMembershipDetails,
  updateStripeCard,
  cancelMembership,
  resumeMembership,
  retrieveUpgradePreview,
  removeSavedPaymentMethod,
  generatePasswordReset,
  confirmPasswordReset as apiConfirmPasswordReset,
  storeLockedMemberNotifyPreferences as apiStoreLockedMemberNotifyPreferences,
  loginWithAutoLoginToken as apiLoginWithAutoLoginToken,
  UpdateStripeCardResponse,
  upgradeMembership
} from "../api/client";

import UserProfile from "../api/entities/UserProfile";
import AuthTokenResponse from "../api/responses/AuthTokenResponse";
import ProductsResponse from "../api/responses/ProductsResponse";
import UpdateUserRequest from "../api/requests/UpdateUserRequest";
import SignUpTrialRequest from "../api/requests/SignUpTrialRequest";
import UpdatePhoneRequest from "../api/requests/UpdatePhoneRequest";
import { useStripe } from "@stripe/react-stripe-js";
import CancelSubscriptionRequest from "../api/requests/CancelMembershipRequest";
import ConfirmPasswordResetRequest from "../api/requests/ConfirmPasswordResetRequest";
import StoreLockedMemberNotifyRequest from "../api/requests/StoreLockedMemberNotifyRequest";
import CreatePaymentRequest from "../api/requests/CreatePaymentRequest";
import { buildDeviceDetails, getUniqueId } from "../utils/device";
import { DuplicateEmailError, ValidationError } from "../api/errors";
import EventEmitter from "eventemitter3";
import { MembershipDetails, Payment } from "@booyaltd/core";

export const LOGIN_EVENT = "login-event";

export const loginEventEmitter = new EventEmitter();

export type SendMethod = "sms" | "whatsapp";

export interface IAccountContext {
  loggedIn: boolean;
  isLocked: boolean;
  loginError: string | undefined;
  loading: boolean;
  validated: boolean;
  waitingOnVerificationCode: boolean;
  userProfile?: UserProfile;

  storeAuthResponse(response: AuthTokenResponse): Promise<any>;

  loginWithUsernamePassword(userName: string, password: string): Promise<any>;

  loginWithPhone(
    countryCallingCode: string,
    phone: string,
    method: SendMethod
  ): Promise<any>;

  loginWithPhoneVerificationToken(token: string): Promise<any>;

  loginWithAutoLoginToken(token: string): Promise<any>;

  logout(): void;

  validateLogin(): Promise<UserProfile | null>;

  acceptTerms(): Promise<boolean>;

  sendFirebaseToken(token: string): Promise<boolean>;

  resetLoginWithPhone(): void;

  resetErrors(): void;

  resetRetryPaymentErrors(): void;

  update(req: UpdateUserRequest): Promise<boolean>;

  getVerificationCode(req: UpdatePhoneRequest): void;

  sendPasswordResetEmail(email: string): Promise<void>;

  confirmPasswordReset(req: ConfirmPasswordResetRequest): Promise<void>;

  updating: boolean;
  updateError: string | undefined;

  signUpTrial(req: SignUpTrialRequest): Promise<any>;

  signUpTrialVerificationToken(code: string): Promise<any>;

  assignTrialMembership(code: string): Promise<any>;

  getMembershipDetails(): Promise<MembershipDetails>;

  handleCardSubmit(paymentMethodId: string): Promise<UpdateStripeCardResponse>;

  handleRetryPaymentStatus(currentFailedPaymentId: string): Promise<any>;

  handleCancelMembership(id: string): void;

  handleResumeMembership(id: string): void;

  getUpgradePreview(id: string): Promise<any>;

  handleUpgradeMembership(id: string): void;

  handleRemovePaymentMethod(id: string): void;

  updateCard(value: boolean): void;

  updatingCard: boolean;

  getSubscriptionProducts(): Promise<any>;

  createStandardSubscriptionStripe(
    req: CreatePaymentRequest,
    onPaymentSuccess?: (payment: Payment) => void
  ): Promise<any>;

  subscriptionProducts?: ProductsResponse;
  paymentError: boolean;
  paymentErrorResponse: string | undefined;

  retryPaymentStatus: string | undefined;

  setRetryPaymentStatus(status: string): void;

  retryPaymentFailureResponse: string | undefined;

  loginPhoneCountryCode: string | undefined;
  loginPhoneNumber: string | undefined;

  upgradePreviewDetails?: any | undefined;

  notifyLockedMember(req: StoreLockedMemberNotifyRequest): Promise<any>;
}

export const AUTH_TOKEN_STORAGE_KEY = "ff-at";
export const REFRESH_TOKEN_STORAGE_KEY = "ff-rt";
export const UNIQUE_ID_STORAGE_KEY = "ff-uid";

const AccountContext = createContext<IAccountContext>({
  loggedIn: false
} as IAccountContext);

const AccountProvider = (props: any) => {
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [loggedIn, setLoggedIn] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState<string>();
  const [loginError, setLoginError] = useState<string>();
  const [paymentError, setPaymentError] = useState(false);
  // eslint-disable-next-line
  const [paymentErrorResponse, setPaymentErrorResponse] = useState<string>();

  const [subscriptionProducts, setSubscriptionProducts] = useState<
    ProductsResponse
  >();
  const [upgradePreviewDetails, setUpgradePreviewDetails] = useState<any>();
  const [waitingOnVerificationCode, setWaitingOnVerificationCode] = useState(
    false
  );

  const [updatingCard, setUpdatingCard] = useState(false);
  const [loginPhoneCountryCode, setLoginPhoneCountryCode] = useState<string>();
  const [loginPhoneNumber, setLoginPhoneNumber] = useState<string>();

  // Retry Payment
  const [retryPaymentStatus, setRetryPaymentStatus] = useState("");
  const [
    retryPaymentFailureResponse,
    setRetryPaymentFailureResponse
  ] = useState<string>();

  const stripe = useStripe();
  const history = useHistory();

  const storeAuthResponse = async (response: AuthTokenResponse) => {
    localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, response.authToken);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, response.refreshToken);
  };

  const loginWithUsernamePassword = async (
    username: string,
    password: string
  ) => {
    resetErrors();
    await logout();

    try {
      setLoading(true);
      const response = await apiLoginWithUsernamePassword({
        username,
        password,
        device: await buildDeviceDetails()
      });
      await storeAuthResponse(response);
      const userProfile = await validateLogin();
      loginEventEmitter.emit(LOGIN_EVENT, "email", userProfile);
      // window.location.reload();
    } catch (e) {
      setLoginError(
        typeof e === "string" || e instanceof ValidationError
          ? e.toString()
          : "Invalid email / password"
      );
      await logout();
    } finally {
      setLoading(false);
    }
  };

  const createStandardSubscriptionStripe = async (
    { productId, stripePaymentToken }: CreatePaymentRequest,
    onPaymentSuccess?: (payment: Payment) => void
  ) => {
    try {
      // Create subscription for the member
      await apiCreatePayment({
        productId: productId,
        stripePaymentToken: stripePaymentToken
      });
      // If card error = 400
      // If card successfull = 402
    } catch (e) {
      if (typeof e === "string" || e instanceof ValidationError) {
        return { status: "payment_error", message: e.toString() };
      } else {
        return {
          status: "payment_pending",
          // @ts-ignore
          message: e.payment.paymentProviderRawPayload
        };
      }
    } finally {
    }
  };

  /* ===================================
  ======= END Trial Applications =======
  =================================== */

  const loginWithPhone = async (
    countryCode: string,
    phone: string,
    method: SendMethod
  ) => {
    resetErrors();
    await logout();

    setLoginPhoneCountryCode(countryCode);
    setLoginPhoneNumber(phone);

    try {
      setLoading(true);
      const success = await apiLoginWithPhone({ countryCode, phone, method });
      if (!success) {
        throw new Error("Unauthorized");
      }

      setWaitingOnVerificationCode(true);
    } catch (e) {
      setLoginError(
        typeof e === "string" || e instanceof ValidationError
          ? e.toString()
          : "Invalid phone number"
      );
      await logout();
    } finally {
      setLoading(false);
    }
  };

  const notifyLockedMember = async (req: StoreLockedMemberNotifyRequest) => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await apiStoreLockedMemberNotifyPreferences({
        userId: req.userId,
        notificationPreference: req.notificationPreference
      });
    } catch (e) {
      console.log("Error:" + e);
    } finally {
      setLoading(false);
    }
  };

  const loginWithPhoneVerificationToken = async (code: string) => {
    resetErrors();
    await logout();

    try {
      setLoading(true);
      const response = await apiVerifyPhone({
        code,
        device: await buildDeviceDetails()
      });
      await storeAuthResponse(response);
      const userProfile = await validateLogin();
      loginEventEmitter.emit(LOGIN_EVENT, "phone", userProfile);
      setLoginPhoneCountryCode("");
      setLoginPhoneNumber("");
    } catch (e) {
      setLoginError(
        typeof e === "string" || e instanceof ValidationError
          ? e.toString()
          : "Invalid verification code"
      );
      await logout();
    } finally {
      setLoading(false);
    }
  };

  const loginWithAutoLoginToken = async (token: string) => {
    resetErrors();
    await logout();

    try {
      setLoading(true);
      const response = await apiLoginWithAutoLoginToken({
        token,
        device: await buildDeviceDetails()
      });
      await storeAuthResponse(response);
      const userProfile = await validateLogin();
      loginEventEmitter.emit(LOGIN_EVENT, "auto", userProfile);
    } catch (e) {
      await logout();
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const logout = async (registerLogoutWithApi = false) => {
    const deviceId = getUniqueId();

    try {
      if (
        registerLogoutWithApi &&
        localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)
      ) {
        localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
        await apiLogout({ deviceId });
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoggedIn(false);
      setLoading(false);
      // setWaitingOnVerificationCode(f alse);
    }

    localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
  };

  const validateLogin = async (): Promise<UserProfile | null> => {
    if (!localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)) {
      setLoggedIn(false);
      setValidated(true);
      return null;
    }

    setLoading(true);

    try {
      const user = await apiGetMe();
      setUserProfile(user);
      setLoggedIn(true);

      return user;
    } catch (e) {
      await logout();
    } finally {
      setLoading(false);
      setValidated(true);
    }

    return null;
  };

  const acceptTerms = async () => {
    setLoading(true);
    if (userProfile) {
      try {
        const success = await apiAcceptTerms();
        setUserProfile({ ...userProfile, termsAccepted: true });
        return success;
      } catch (e) {
        await logout();
      } finally {
        setLoading(false);
      }
    }

    return false;
  };

  const getVerificationCode = async (req: UpdatePhoneRequest) => {
    setLoading(true);
    setUpdateError(undefined);
    setWaitingOnVerificationCode(false);

    try {
      await getCode({
        countryCode: req.countryCode,
        phoneNumber: req.phoneNumber
      });

      setWaitingOnVerificationCode(true);
    } catch (e) {
      console.log(e);
      setUpdateError(e._error);
    } finally {
      setLoading(false);
    }
  };

  const update = async (req: UpdateUserRequest): Promise<boolean> => {
    setUpdateError(undefined);

    if (!userProfile) {
      setUpdateError("Unauthorised");
      return false;
    }

    try {
      setUpdating(true);
      const updatedUserProfile = await updateMe({
        id: userProfile.id,
        ...req
      });
      setUserProfile(updatedUserProfile);
      setWaitingOnVerificationCode(false);
      return true;
    } catch (e) {
      if (
        e.toString().includes("User already exists with given phone number")
      ) {
        setUpdateError(
          "Another account already exists with the given phone number. Please contact FarmFLiX Support to resolve this issue."
        );
      } else if (
        e.toString().includes("User already exists with given email address")
      ) {
        throw new DuplicateEmailError();
      } else {
        setUpdateError(e.toString());
      }

      throw e;
    } finally {
      setUpdating(false);
    }
  };

  const sendFirebaseToken = async (token: string) => {
    if (userProfile) {
      const deviceId = getUniqueId();
      try {
        const success = await apiSendFirebaseToken({
          deviceId,
          userId: userProfile.id,
          token
        });
        return success;
      } catch (e) {
        console.log("Failed to send Firebase Token: ", e);
      }
    }
    return false;
  };

  const onApiUnauthorizedEvent = (e: string | ValidationError<any>) => {
    if (typeof e === "string" || e instanceof ValidationError) {
      setLoginError(e.toString());
    }

    logout();
  };

  const resetLoginWithPhone = () => {
    setWaitingOnVerificationCode(false);
    resetErrors();
  };

  const resetErrors = () => {
    setLoading(false);
    setLoginError(undefined);
    setUpdateError(undefined);
    setPaymentError(false);
  };

  const resetRetryPaymentErrors = () => {
    setRetryPaymentStatus("");
    setRetryPaymentFailureResponse("");
  };

  const updateCard = setUpdatingCard;

  const getMembershipDetails = async (): Promise<MembershipDetails> => {
    return apiGetMembershipDetails();
  };

  const handleRetryPaymentStatus = async (lastFailedPaymentID: string) => {
    try {
      setRetryPaymentStatus("pending");

      const latestPayment = async () => {
        const memberDetails = await getMembershipDetails();
        const paymentDetails = memberDetails.payments[0];
        return paymentDetails;
      };

      // Define retryAttempts
      let retryAttempts = 0;

      const PaymentCallback = async () => {
        const LP = await latestPayment(); // Latest payment entry

        if (LP) {
          // Latest payment id is equal to the last failed payment entry, poll again
          if (LP.id === lastFailedPaymentID) {
            // Increment retry attemps
            retryAttempts = retryAttempts + 1;
            // If retryAttempts reach 5, provide unable to process feedback
            if (retryAttempts === 5) {
              return setRetryPaymentStatus("unable-process");
            }
            // Call again
            setTimeout(PaymentCallback, retryAttempts * 2000);
            // Latest payment id is not equal to the last failed payment entry
          } else if (LP.id !== lastFailedPaymentID) {
            // Latest payment entry status is failure, show error message w/ try again, else show success message
            if (LP.status === "failure") {
              setRetryPaymentFailureResponse(LP.failureReason);
              return setRetryPaymentStatus("failure");
            } else if (LP.status === "success") {
              return setRetryPaymentStatus("success");
            }
          }
        }
      };

      await PaymentCallback();
    } catch (e) {
      setRetryPaymentStatus("");
      console.log("Error: " + e);
    }
  };

  const handleCardSubmit = async (
    paymentMethod: string
  ): Promise<UpdateStripeCardResponse> => {
    setUpdateError(undefined);

    if (!stripe) {
      return {
        success: false,
        error: "Stripe is not initialized"
      };
    }

    setUpdating(true);
    setUpdatingCard(true);

    try {
      const response = await updateStripeCard({
        id: paymentMethod
      });

      console.log("updateStripeCard response: ", response);

      if (!response.success) {
        setUpdateError(
          response.error.toString() || "Error updating card details"
        );
      }

      return response;
    } catch (e) {
      setUpdateError(e.toString() || "Error updating card details");

      return {
        success: false,
        error: e.toString() || "Error updating card details"
      };
    } finally {
      setUpdating(false);
      setUpdatingCard(false);
    }
  };

  const handleCancelMembership = async (userId: string) => {
    setUpdateError(undefined);

    if (!stripe) {
      return;
    }

    try {
      setUpdating(true);
      await cancelMembership({
        id: userId
      } as CancelSubscriptionRequest);

      await new Promise(resolve => setTimeout(resolve, 3000));
      await validateLogin();
    } catch (e) {
      setUpdateError(
        "Whoops! Something went wrong - please contact FarmFLiX Support."
      );
      throw new Error(
        "Whoops! Something went wrong - please contact FarmFLiX Support."
      );
    } finally {
      setUpdating(false);
    }
  };

  const handleResumeMembership = async (userId: string) => {
    setUpdateError(undefined);

    if (!stripe) {
      return;
    }

    try {
      setUpdating(true);
      await resumeMembership({
        id: userId
      } as CancelSubscriptionRequest);

      await new Promise(resolve => setTimeout(resolve, 3000));
      await validateLogin();
    } catch (e) {
      setUpdateError(
        "Whoops! Something went wrong - please contact FarmFLiX Support."
      );
      throw new Error(
        "Whoops! Something went wrong - please contact FarmFLiX Support."
      );
    } finally {
      setUpdating(false);
    }
  };

  const getUpgradePreview = useCallback(async (userId: string) => {
    const previewResponse = await retrieveUpgradePreview({
      id: userId
    } as CancelSubscriptionRequest);

    if (!previewResponse) {
      console.log("Invoice not found");
      return;
    }

    setUpgradePreviewDetails(previewResponse);
  }, []);

  const handleUpgradeMembership = async (userId: string) => {
    setUpdateError(undefined);

    if (!stripe) {
      return;
    }

    try {
      setUpdating(true);
      console.log("Handle Upgrade Membership...");
      await upgradeMembership({
        id: userId
      } as CancelSubscriptionRequest);

      await new Promise(resolve => setTimeout(resolve, 3000));
    } catch (e) {
      setUpdateError(
        "Whoops! Something went wrong - please contact FarmFLiX Support."
      );
      // throw new Error(
      //   "Whoops! Something went wrong - please contact FarmFLiX Support."
      // );
    } finally {
      setUpdating(false);
    }
  };

  const handleRemovePaymentMethod = async (userId: string) => {
    setUpdateError(undefined);

    if (!stripe) {
      return;
    }

    try {
      setUpdating(true);
      const removePaymentMethod = await removeSavedPaymentMethod({
        id: userId
      } as CancelSubscriptionRequest);

      if (!removePaymentMethod) {
        setUpdating(false);
        throw new Error(
          "Can't reume subscription at this time - please contact FarmFLiX Support"
        );
      }

      return removePaymentMethod;
    } catch (e) {
      setUpdateError(
        "Whoops! Something went wrong - please contact FarmFLiX Support."
      );
    } finally {
      setUpdating(false);
    }
  };

  if (!loading && !validated) {
    validateLogin();
  }

  // Subscription Products
  const getSubscriptionProducts = useCallback(async () => {
    const subscriptionProductResponse = await getProducts();

    if (!subscriptionProductResponse) {
      console.log("Subscription products not found");
      return;
    }

    setSubscriptionProducts(subscriptionProductResponse);
  }, []);

  const sendPasswordResetEmail = useCallback(async (email: string) => {
    return generatePasswordReset(email);
  }, []);

  const confirmPasswordReset = useCallback(
    async (req: ConfirmPasswordResetRequest) => {
      await apiConfirmPasswordReset(req);
    },
    []
  );

  /* Legacy 72H Trial Sign Up */
  const signUpTrial = async ({
    firstName,
    lastName,
    email,
    countryCode,
    phoneNumber,
    password
  }: SignUpTrialRequest) => {
    try {
      setLoading(true);

      setLoginPhoneCountryCode(countryCode);
      setLoginPhoneNumber(phoneNumber);

      const success = await register({
        firstName,
        lastName,
        email,
        countryCode,
        phoneNumber,
        password
      });
      if (!success) {
        throw new Error("Can't register at this time");
      } else {
        const phone = phoneNumber;
        const loginSuccess = await apiLoginWithPhone({
          countryCode,
          phone,
          method: "sms"
        });
        if (!loginSuccess) {
          throw new Error("Can't sign in at this time");
        }

        setWaitingOnVerificationCode(true);
      }
    } catch (e) {
      let registerError;
      if (e.toString().includes("User already exists")) {
        registerError =
          "We already know your email address or phone number.  Try logging in instead, and use the reset password option if you can’t remember your password.";
      }
      setLoginError(
        typeof registerError === "string" ? e : "Something went wrong!"
      );
      await logout();
    } finally {
      setLoading(false);
    }
  };

  const assignTrialMembership = async () => {
    try {
      setLoading(true);

      const products = await getProducts();

      const freeTrialProduct = products.results.find(
        product => product.memberStatusId === "trial"
      );

      if (!freeTrialProduct) {
        throw new Error("Free Trial not available at present.");
      }

      // Create the subscription entitlement for the member
      const createTrialSubscription = await apiCreatePayment({
        productId: freeTrialProduct.id
      });

      if (!createTrialSubscription) {
        throw new Error("Cannot create a Free Trial at present.");
      }

      // Validate SignIn
      const userProfile = await validateLogin();

      // Ensure userProfile is not locked or memberStatus is not set to locked
      if (userProfile) {
        if (userProfile.isLocked || userProfile.memberStatus === "locked") {
          throw new Error("Can't create an account at this time");
        }
      }

      history.push("/success/trial");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const signUpTrialVerificationToken = async (code: string) => {
    resetErrors();
    await logout();

    try {
      setLoading(true);
      const response = await apiVerifyPhone({
        code,
        device: await buildDeviceDetails()
      });
      await storeAuthResponse(response);

      const products = await getProducts();

      const freeTrialProduct = products.results.find(
        product => product.memberStatusId === "trial"
      );

      if (!freeTrialProduct) {
        throw new Error("Free Trial not available at present.");
      }

      // Create the subscription entitlement for the member
      const createTrialSubscription = await apiCreatePayment({
        productId: freeTrialProduct.id
      });

      if (!createTrialSubscription) {
        throw new Error("Cannot create a Free Trial at present.");
      }

      // Validate SignIn
      const userProfile = await validateLogin();
      loginEventEmitter.emit(LOGIN_EVENT, "signup", userProfile);

      // Ensure userProfile is not locked or memberStatus is not set to locked
      if (userProfile) {
        if (userProfile.isLocked || userProfile.memberStatus === "locked") {
          throw new Error("Can't create an account at this time");
        }

        setLoginPhoneCountryCode("");
        setLoginPhoneNumber("");
      }

      history.push("/trialsuccess");
    } catch (e) {
      setLoginError(
        typeof e === "string" || e instanceof ValidationError
          ? e.toString()
          : "Invalid verification code"
      );
      await logout();
    } finally {
      setLoading(false);
    }
  };

  apiEventEmitter.off(API_EVENT_UNAUTHORIZED);
  apiEventEmitter.on(API_EVENT_UNAUTHORIZED, onApiUnauthorizedEvent);

  const context: IAccountContext = {
    storeAuthResponse,
    createStandardSubscriptionStripe,
    userProfile,
    loggedIn,
    isLocked: userProfile ? userProfile.isLocked : false,
    loading,
    loginError,
    waitingOnVerificationCode,
    loginWithUsernamePassword,
    loginWithPhone,
    loginWithPhoneVerificationToken,
    loginWithAutoLoginToken,
    logout: () => {
      logout(true);
    },
    validateLogin,
    validated,
    acceptTerms,
    sendFirebaseToken,
    resetLoginWithPhone,
    resetErrors,
    update,
    getVerificationCode,
    updateError,
    updating,
    signUpTrial,
    signUpTrialVerificationToken,
    assignTrialMembership,
    getMembershipDetails,
    handleCardSubmit,
    handleRetryPaymentStatus,
    handleCancelMembership,
    handleResumeMembership,
    getUpgradePreview,
    upgradePreviewDetails,
    handleUpgradeMembership,
    handleRemovePaymentMethod,
    updateCard,
    updatingCard,
    paymentError,
    paymentErrorResponse,

    getSubscriptionProducts,
    subscriptionProducts,
    sendPasswordResetEmail,
    confirmPasswordReset,
    loginPhoneCountryCode,
    loginPhoneNumber,

    setRetryPaymentStatus,
    retryPaymentStatus,
    retryPaymentFailureResponse,

    resetRetryPaymentErrors,

    notifyLockedMember
  };

  return (
    <AccountContext.Provider value={context}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountProvider };
