import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../../store/AccountContext";
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
  Divider,
  Select,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MembershipCardForm from "./MembershipCardForm";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import usePromise from "react-use-promise";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    textAlign: "center"
  },
  upgradeHeader: {
    backgroundColor: theme.palette.background.ffGreen,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  upgradeTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2rem",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  subtitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  thirdTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(3),
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  bodytext: {
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    lineHeight: "1rem",
    textAlign: "left",
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "0.9rem",
      lineHeight: "1.1rem"
    }
  },
  bodytextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.7rem",
    lineHeight: "0.9rem",
    textAlign: "left"
  },
  subscriptionWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  },
  subscriptionContainer: {
    color: theme.palette.background.ffNavy,
    backgroundColor: "#dadada",
    padding: theme.spacing(2),
    display: "inline-block",
    textAlign: "left",
    width: "100%",
    flexBasis: "unset",
    margin: theme.spacing(1, 0),
    "&:hover": {
      backgroundColor: "#e2e2e2",
      cursor: "pointer"
    }
  },
  selectedSubscriptionContainer: {
    color: theme.palette.common.white,
    backgroundColor: "#f9f9f9",
    padding: theme.spacing(2),
    display: "inline-block",
    width: "100%",
    textAlign: "left",
    margin: theme.spacing(1, 0),
    "&:hover": {
      backgroundColor: "#f9f9f9",
      cursor: "pointer"
    },
    "& $subscriptionPrice": {
      fontWeight: 600,
      color: theme.palette.background.ffNavy
    },
    "& $subscriptionName": {
      color: theme.palette.background.ffNavy
    },
    "& $subscriptionDescription": {
      color: theme.palette.background.ffNavy
    }
  },
  selectButton: {
    backgroundColor: theme.palette.background.ffGreen,
    border: "2px solid #99ca3c",
    color: theme.palette.background.ffNavy,
    padding: "10px 14px",
    fontSize: "0.8rem",
    lineHeight: "1.2rem",
    "&:hover": {
      backgroundColor: theme.palette.background.ffGreen,
      border: "2px solid #99ca3c",
      color: theme.palette.background.ffNavy
    },
    "@media (min-width:600px)": {
      fontSize: "0.9rem"
    }
  },
  selectButtonUnchecked: {
    transition: "none",
    backgroundColor: "#ddd",
    border: "2px solid #171717",
    color: "#171717",
    padding: "10px 14px",
    fontSize: "0.8rem",
    lineHeight: "1.2rem",
    "&:hover": {
      border: "2px solid #171717"
    },
    "@media (min-width:600px)": {
      fontSize: "0.9rem"
    }
  },
  subscriptionPrice: {
    fontWeight: 600,
    color: theme.palette.background.ffNavy
  },
  subscriptionCost: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    lineHeight: "1.6rem",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.4rem",
      lineHeight: "3.6rem"
    }
  },
  subscriptionTerm: {
    fontWeight: 400
  },
  subscriptionLogo: {
    maxWidth: "120px"
  },
  subscriptionHeading: {
    textTransform: "uppercase",
    fontSize: "0.6rem",
    color: "#ddd"
  },
  subscriptionName: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    fontWeight: 500,
    textAlign: "left",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    }
  },
  subscriptionDescription: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    lineHeight: "1rem",
    fontWeight: "400",
    textAlign: "left",
    margin: "10px 0 12px 0",
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1rem"
    }
  },
  cardContainer: {
    border: "1px solid #ccc",
    padding: "15px"
  },
  selectedSubscription: {
    backgroundColor: "#99ca3c",
    border: "2px solid #99ca3c",
    color: theme.palette.background.ffNavy,
    "&:hover": {
      backgroundColor: "#99ca3c",
      border: "2px solid #99ca3c",
      color: "#fff"
    }
  },
  continueButton: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  smlDivider: {
    backgroundColor: "#c1c1c1",
    height: "1px",
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  center: {
    textAlign: "center"
  },
  giftcardRedeem: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  cardInputError: {
    marginBottom: theme.spacing(2)
  },
  step2SelectedSubscriptionContainer: {
    textAlign: "center",
    marginBottom: "40px"
  },
  step2SelectedSubscription: {
    backgroundColor: "#e6e6e6",
    margin: "0 3% 20px 3%",
    maxWidth: "100%",
    textAlign: "center",
    borderBottom: "5px solid #99ca3c",
    padding: theme.spacing(2),
    "@media (min-width:600px)": {
      maxWidth: "80%",
      margin: "0 10% 20px 10%"
    },
    "@media (min-width:960px)": {
      maxWidth: "80%",
      margin: "0 10% 20px 10%"
    }
  },
  changeMembership: {
    textDecoration: "underline",
    color: theme.palette.background.ffNavy,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.background.ffGreen
    }
  },
  paymentSuccessWrapper: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  },
  paymentSuccessTick: {
    color: theme.palette.background.ffGreen,
    fontSize: "6rem"
  },
  progressButtonContainer: {
    marginBottom: theme.spacing(6)
  },
  membershipBox: {
    margin: "5px",
    minHeight: "150px",
    padding: "15%",
    backgroundColor: theme.palette.background.ffGreen
  },
  paymentMethodSelection: {
    padding: theme.spacing(2),
    textAlign: "left",
    border: "1px solid #eee",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#eee"
    }
  },
  paymentMethodSelectionDisabled: {
    padding: theme.spacing(2),
    textAlign: "left",
    border: "1px solid #eee",
    marginBottom: theme.spacing(1),
    opacity: 0.2
  },
  paymentMethodSelector: {
    color: theme.palette.background.ffNavy
  },
  cardLogo: {
    maxHeight: "18px"
  },
  paypalLogo: {
    maxHeight: "18px"
  },
  selectedMembership: {
    zIndex: "-100000",
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(1.5)
  },
  selectedMembershipTitle: {
    textAlign: "left",
    color: theme.palette.background.ffNavy,
    fontWeight: 500
  },
  selectedMembershipPrice: {
    color: theme.palette.background.ffNavy,
    fontWeight: 400,
    fontSize: "0.9rem"
  },
  selectedMembershipTerm: {
    color: theme.palette.background.ffNavy,
    fontWeight: 400,
    fontSize: "selectedMem0.9rem"
  },
  selectedMembershipChange: {
    color: theme.palette.background.ffGreen,
    fontWeight: 500,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  selectedMembershipLabel: {
    color: theme.palette.background.ffNavy,
    textAlign: "left",
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    marginTop: "20px",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  },
  changePaymentMethod: {
    textAlign: "left",
    color: theme.palette.background.ffGreen,
    fontWeight: 500,
    textDecoration: "underline",
    marginTop: theme.spacing(3),
    "&:hover": {
      cursor: "pointer"
    }
  },
  responseBox: {
    textAlign: "center",
    padding: "10%"
  },
  responseIcon: {
    width: "4rem",
    height: "4rem",
    "@media (min-width:600px)": {
      width: "5rem",
      height: "5rem"
    }
  },
  divider: {
    width: "50%",
    margin: "10% 25% 10% 25%",
    height: "1px",
    backgroundColor: "#c1c1c1",
    "@media (min-width:600px)": {
      margin: "5% 25% 10px 25%"
    },
    "@media (min-width:960px)": {
      margin: "5% 25% 10px 25%"
    }
  },
  responseHelp: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    lineHeight: "0.9rem",
    textAlign: "center",
    "& a": {
      color: theme.palette.background.ffNavy
    }
  }
}));

const UpgradeFormNew = props => {
  const {
    getSubscriptionProducts,
    subscriptionProducts,
    paymentPending,
    paymentFailure,
    paymentSuccess,
    paymentUnableProcess,
    resetErrors,
    resetPaymentErrors,
    getMembershipDetails
  } = useContext(AccountContext);

  const classes = useStyles();

  useEffect(() => {
    getSubscriptionProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Subscription State Management
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedSubscriptionID, setSelectedSubscriptionID] = useState("");
  // eslint-disable-next-line
  const [currency, setCurrency] = useState();
  // eslint-disable-next-line
  const [reloadTrigger, setReloadTrigger] = useState(Date.now());

  const [membershipDetails, , state] = usePromise(getMembershipDetails, [
    reloadTrigger
  ]);

  // eslint-disable-next-line
  const loading = state === "pending";

  const handleMembershipChange = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (membershipDetails && membershipDetails.currency) {
      setCurrency(membershipDetails.currency.toUpperCase());
    } else {
      setCurrency("GBP");
    }
  }, [membershipDetails]);

  /* ==============================================
  =========== Subscription Handlers ===============
  ============================================== */

  // Handle Currency Change
  const handleCurrencyChange = event => {
    setSelectedSubscription("");
    setSelectedSubscriptionID("");
    setCurrency(event.target.value);
  };

  function onHandleSubscription(subscription) {
    setSelectedSubscription(subscription);
    setSelectedSubscriptionID(subscription.id);
  }

  function CurrencySymbol({ currency }) {
    switch (currency) {
      case "GBP":
        return "\u00A3";
      case "EUR":
        return "\u20AC";
      default:
        return "\u00A3";
    }
  }

  /* ==============================================
  =========== Payment Method Handlers =============
  ============================================== */

  // const handleCreditCardSelected = () => {
  //   setPaymentMethodSelected(true);
  //   setPaymentMethodSelectedPayPal(false);
  //   setPaymentMethodSelectedCreditCard(true);
  // };

  // const handlePayPalSelected = () => {
  //   if (selectedSubscription.paypalReference === "annual52") {
  //     // setPaymentMethodSelected(true);
  //     setPaymentMethodSelectedCreditCard(false);
  //     setPaymentMethodSelectedPayPal(true);
  //   } else {
  //     alert("PayPal Payment not available for FarmFLiX Monthly Subscription.");
  //   }
  // };

  /* ==============================================
  ============= Payment Handlers ==================
  ============================================== */

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  // const steps = getSteps();

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNext = () => {
    resetErrors();
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const tryAgain = () => {
    resetPaymentErrors();
    setActiveStep(0);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1Subscriptions />;
      case 1:
        return (
          <>
            <Step2Payment />
          </>
        );
      default:
        return "Unknown step";
    }
  }

  // Step 1 - Show Membership Plans
  function Step1Subscriptions() {
    return (
      <>
        {membershipDetails && (
          <>
            <Container style={{ padding: 0 }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={6}
                  className={classes.subscriptionWrapper}
                >
                  <Typography variant="body2" className={classes.bodytext}>
                    Step <b>1</b> of <b>2</b>
                  </Typography>
                  {membershipDetails.stripeCustomerId && (
                    <Typography variant="h2" className={classes.subtitle}>
                      Choose your Membership
                    </Typography>
                  )}
                  {!membershipDetails.stripeCustomerId && (
                    <Typography variant="h2" className={classes.subtitle}>
                      Choose your Currency
                    </Typography>
                  )}
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography variant="body1" className={classes.bodytext}>
                        Select a FarmFLiX Membership.
                      </Typography>
                    </Grid>
                    <Grid item xs={4} align="right">
                      {!membershipDetails.stripeCustomerId && (
                        <Select
                          value={currency}
                          onChange={handleCurrencyChange}
                          className={classes.currencySelect}
                        >
                          <MenuItem value={"GBP"}>
                            <b>&#xa3; </b> GBP
                          </MenuItem>
                          <MenuItem value={"EUR"}>
                            <b>&#8364; </b>EUR
                          </MenuItem>
                          <MenuItem value={"USD"}>
                            <b>&#36; </b> USD
                          </MenuItem>
                        </Select>
                      )}
                    </Grid>
                  </Grid>
                  {subscriptionProducts && (
                    <Grid container className={classes.subscriptionWrapper}>
                      {subscriptionProducts.results
                        .filter(
                          subscription =>
                            subscription.currency === currency &&
                            subscription.cost > 0
                        )
                        .map((subscription, index) => (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            key={index}
                            className={
                              subscription.id === selectedSubscriptionID
                                ? classes.selectedSubscriptionContainer
                                : classes.subscriptionContainer
                            }
                            onClick={() => onHandleSubscription(subscription)}
                          >
                            <Grid container>
                              <Grid item xs={7}>
                                <Typography
                                  className={classes.subscriptionName}
                                >
                                  {subscription.name}
                                </Typography>
                                <Typography
                                  className={classes.subscriptionDescription}
                                >
                                  {subscription.description}
                                </Typography>

                                {/* Price */}
                                <Typography
                                  className={classes.subscriptionPrice}
                                  variant="body1"
                                >
                                  <span className={classes.subscriptionCost}>
                                    <CurrencySymbol
                                      currency={subscription.currency}
                                    />
                                    {subscription.cost}
                                  </span>
                                  <span className={classes.subscriptionTerm}>
                                    /{subscription.entitlementPeriodUnit}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={5} align="right">
                                {subscription.id === selectedSubscriptionID ? (
                                  <Button
                                    color="primary"
                                    disableRipple={true}
                                    className={classes.selectButton}
                                    startIcon={<CheckCircleIcon />}
                                  >
                                    SELECTED
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    className={classes.selectButtonUnchecked}
                                    startIcon={
                                      <RadioButtonUncheckedOutlinedIcon />
                                    }
                                  >
                                    SELECT
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      <Grid item xs={12} sm={12} md={12}>
                        {activeStep === 0 && (
                          <Button
                            fullwidth="true"
                            onClick={handleNext}
                            className={classes.continueButton}
                            disabled={!selectedSubscription}
                          >
                            Continue To Payment
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </>
    );
  }

  function Step2Payment() {
    return (
      <>
        <Container style={{ padding: 0 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              className={classes.subscriptionWrapper}
            >
              <Typography variant="body2" className={classes.bodytext}>
                Step <b>2</b> of <b>2</b>
              </Typography>
              <Typography variant="h2" className={classes.subtitle}>
                Pay with Credit/Debit Card
              </Typography>
              {!paymentPending && (
                <>
                  <Typography
                    className={classes.selectedMembershipLabel}
                    variant="body1"
                  >
                    Membership Information
                  </Typography>
                  <Box display="flex" className={classes.selectedMembership}>
                    <Box flexGrow={1}>
                      <Typography
                        variant="body1"
                        className={classes.selectedMembershipTitle}
                      >
                        {selectedSubscription.name}
                        <br />
                        <span className={classes.selectedMembershipPrice}>
                          <CurrencySymbol
                            currency={selectedSubscription.currency}
                          />
                          {selectedSubscription.cost}
                        </span>
                        <span className={classes.selectedMembershipTerm}>
                          /{selectedSubscription.entitlementPeriodUnit}
                        </span>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="body1"
                        className={classes.selectedMembershipChange}
                        onClick={handleMembershipChange}
                      >
                        Change
                      </Typography>
                    </Box>
                  </Box>
                  <Divider className={classes.smlDivider} />
                  <MembershipCardForm Subscription={selectedSubscription} />
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }

  return (
    <div>
      <Container style={{ padding: 0 }}>
        <Grid container spacing={2} className={classes.paper}>
          <Grid item xs={12} sm={12} md={12}>
            {!paymentSuccess &&
              !paymentPending &&
              !paymentFailure &&
              !paymentUnableProcess &&
              getStepContent(activeStep)}

            {paymentPending && (
              <Box className={classes.responseBox}>
                <CircularProgress color="primary" />
                <Typography
                  variant="h2"
                  className={classes.subtitle}
                  style={{ textAlign: "center" }}
                >
                  We&apos;re hitching your trailer on now...
                </Typography>
                <Typography
                  variant="h3"
                  className={classes.thirdTitle}
                  style={{ textAlign: "center" }}
                >
                  Payment processing...
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.bodytext}
                  style={{ textAlign: "center" }}
                >
                  Please do not reload or refresh your browser.
                </Typography>
              </Box>
            )}
            {paymentFailure && (
              <Box className={classes.responseBox}>
                <CancelIcon className={classes.responseIcon} color="error" />
                <Typography
                  variant="h2"
                  className={classes.subtitle}
                  style={{ textAlign: "center" }}
                >
                  Payment Failed
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.bodytext}
                  style={{ textAlign: "center" }}
                >
                  We were unable to charge your card for your FarmFLiX
                  Membership. Please try again.
                </Typography>
                <Button size="medium" onClick={tryAgain}>
                  Try Again
                </Button>
                <Divider className={classes.divider} />
                <Typography variant="body2" className={classes.responseHelp}>
                  <b>Need help?</b> Contact us using the &apos;Help&apos; Widget
                  or email us at{" "}
                  <a href="mailto:info@farmflix.tv">info.farmflix.tv</a>
                </Typography>
              </Box>
            )}
            {paymentSuccess && (
              <Box className={classes.responseBox}>
                <CheckCircleIcon
                  className={classes.responseIcon}
                  color="primary"
                />
                <Typography
                  component="h1"
                  variant="h1"
                  className={classes.upgradeTitle}
                >
                  Woo hoo!
                </Typography>
                <Typography
                  variant="h2"
                  className={classes.thirdTitle}
                  style={{ textAlign: "center" }}
                >
                  Payment Successfully Processed!
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.bodytext}
                  style={{ textAlign: "center" }}
                >
                  Thank you for your payment. Your FarmFLiX Account is now set
                  up.
                </Typography>
                <a href="/videos" style={{ textDecoration: "none" }}>
                  <Button name="get-started">Get Started</Button>
                </a>
              </Box>
            )}
            {paymentUnableProcess && (
              <Box className={classes.responseBox}>
                <ErrorIcon
                  className={classes.responseIcon}
                  style={{ fill: "#ff9f00" }}
                />
                <Typography
                  variant="h2"
                  className={classes.subtitle}
                  style={{ textAlign: "center" }}
                >
                  Unable to Process Payment
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.bodytext}
                  style={{ textAlign: "center" }}
                >
                  Unfortunately we cannot process your payment at this time.
                </Typography>
                <Button size="medium" onClick={tryAgain}>
                  Try Again
                </Button>
                <Divider className={classes.divider} />
                <Typography variant="body2" className={classes.responseHelp}>
                  <b>Need help?</b> Contact us using the &apos;Help&apos; Widget
                  or email us at{" "}
                  <a href="mailto:info@farmflix.tv">info.farmflix.tv</a>
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default UpgradeFormNew;
