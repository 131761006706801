import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { AccountContext } from "../store/AccountContext";
import { OverviewContext } from "../store/OverviewContext";
import DefaultLayout from "./DefaultLayout";
import { Box } from "@material-ui/core";
import BackdropLoader from "../components/common/BackdropLoader";
import UpdateEmailBanner from "../components/Banners/UpdateEmail";

const useStyles = makeStyles(theme => ({
  background: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(4)
  },
  loading: {
    textAlign: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  }
}));

const LoggedInLayout = ({ children }) => {
  const { loading, loggedIn, userProfile } = useContext(AccountContext);
  const { overview } = useContext(OverviewContext);
  const history = useHistory();
  let location = useLocation();

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!loading && !loggedIn) {
    history.push("/login");
    return null;
  }

  // Loading Screen - May still this up slightly
  if (loading && !loggedIn) {
    return (
      <DefaultLayout showTry={false} showSignIn={false}>
        <Box className={classes.background} />
        <BackdropLoader />
      </DefaultLayout>
    );
  }

  // Member has a Expired Subscription (paid at least once) - lets welcome them back - get them back into the clan

  if (
    loggedIn &&
    (userProfile.memberStatus === "standard-expired" ||
      userProfile.memberStatus === "trial-expired" ||
      userProfile.memberStatus === "standard-incomplete" ||
      userProfile.memberStatus === "locked") &&
    overview &&
    !overview.doors.open &&
    !overview.doors.freeDailyContentOpen
  ) {
    return <Redirect to="/myaccount/overview" />;
  }

  if (
    loggedIn &&
    (userProfile.memberStatus === "standard-expired" ||
      userProfile.memberStatus === "trial-expired" ||
      userProfile.memberStatus === "standard-incomplete" ||
      userProfile.memberStatus === "locked") &&
    overview &&
    overview.doors.open &&
    !overview.doors.freeDailyContentOpen
  ) {
    return <Redirect to="/myaccount/overview" />;
  }

  if (
    loggedIn &&
    (userProfile.memberStatus === "standard-expired" ||
      userProfile.memberStatus === "trial-expired" ||
      userProfile.memberStatus === "standard-incomplete" ||
      userProfile.memberStatus === "locked") &&
    overview &&
    overview.doors.open &&
    overview.doors.freeDailyContentOpen
  ) {
    return <Redirect to="/welcomeback" />;
  }

  // Trial Member - Trial Expired - Redirect To Subscription Window
  // if (loggedIn && !loading && userProfile.memberStatus === "trial-expired") {
  //   return <Redirect to="/myaccount/overview" />;
  //   // return <Redirect to="/welcomebacktrial" />;
  // }

  // Member is Locked
  // Open Period = /membership-unlock || Closed Period = /membership-lock
  // if (loggedIn && !loading && userProfile.memberStatus === "locked") {
  //   return <Redirect to="/myaccount/overview" />;
  // }

  // if (
  //   loggedIn &&
  //   !loading &&
  //   userProfile.memberStatus === "standard-incomplete"
  // ) {
  //   return <Redirect to="/myaccount/overview" />;
  // }

  // Member is Trial
  if (loggedIn && userProfile.memberStatus === "trial") {
    return (
      <DefaultLayout>
        <Box className={classes.background}>{children}</Box>
      </DefaultLayout>
    );
  }
  const displayEmailWarning =
    location.pathname === "/videos" && userProfile.emailRejected;

  return (
    <DefaultLayout>
      <Box className={classes.background}>
        {displayEmailWarning && <UpdateEmailBanner />}
        {children}
      </Box>
    </DefaultLayout>
  );
};

export default LoggedInLayout;
