import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Box,
  Typography,
  IconButton
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "./../../../node_modules/react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { CatalogOverviewResponse } from "@booyaltd/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    padding: "10px 0 15px 0",
    "@media (min-width:600px)": {
      padding: "10px 0 20px 0"
    },
    "@media (min-width:960px)": {
      padding: "10px 0 30px 0"
    }
  },
  fullWidthItemContainer: {
    height: "300px",
    display: "block",
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    zIndex: 1,
    "@media (min-width:600px)": {
      height: "400px"
    },
    "@media (min-width:960px)": {
      height: "700px"
    }
  },
  fullWidthItemContainerBottomGradient: {
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 40%, rgba(23,23,23,1) 100%)",
    height: "300px",
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(23,23,23,0.8) 100%)",
      height: "300px"
    },
    "@media (min-width:600px)": {
      height: "400px",
      "&:hover": {
        background:
          "linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(23,23,23,0.8) 100%)",
        height: "400px"
      }
    },
    "@media (min-width:800px)": {
      height: "700px",
      "&:hover": {
        background:
          "linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(23,23,23,0.8) 100%)",
        height: "700px"
      }
    }
  },
  fullWidthItemDetails: {
    position: "absolute",
    bottom: "15px",
    left: "10px",
    textAlign: "left",
    "@media (min-width:600px)": {
      bottom: "30px",
      left: "25px",
      textAlign: "left"
    },
    "@media (min-width:960px)": {
      bottom: "30px",
      left: "25px",
      textAlign: "left"
    }
  },
  fullWidthItemChannel: {
    marginBottom: theme.spacing(1),
    borderRadius: "50%",
    width: "50px !important",
    "@media (min-width:600px)": {
      borderRadius: "50%",
      width: "60px !important"
    },
    "@media (min-width:960px)": {
      borderRadius: "50%",
      width: "70px !important"
    }
  },
  fullWidthItemTitle: {
    fontSize: "1.4rem",
    lineHeight: "1.6rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(0.2),
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3.4rem",
      lineHeight: "3.4rem",
      marginBottom: theme.spacing(1)
    }
  },
  fullWidthItemEpisodeCount: {
    fontSize: "0.9rem",
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    color: "#c5c5c5",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem"
    }
  },
  "@global": {
    "@keyframes fadeInFromTop": {
      from: {
        transform: "translate3d(0,-15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    },
    "@keyframes fadeInFromBottom": {
      from: {
        transform: "translate3d(0,15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    }
  },
  fullWidthItemDetailsActiveTop: {
    animation: "fadeInFromTop .3s ease-in-out",
    position: "absolute",
    top: "25px",
    left: "25px"
  },
  fullWidthItemDetailsActiveBottom: {
    animation: "fadeInFromBottom .3s ease-in-out",
    position: "absolute",
    bottom: "35px",
    right: "35px"
  },
  fullWidthItemDetailsActiveDescription: {
    fontSize: "2.2rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem",
      fontWeight: "400"
    }
  },
  episodeHoverDetailsActivePlayIconButton: {
    fontSize: "2.2rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        // @ts-ignore
        color: theme.palette.background.ffGreen
      }
    }
  },
  playButton: {
    position: "absolute",
    bottom: "20px",
    right: "10px",
    "@media (min-width:600px)": {
      bottom: "20px",
      right: "20px"
    },
    "@media (min-width:960px)": {
      bottom: "20px",
      right: "20px"
    }
  },
  seriesPlayIconButton: {
    fontSize: "1.8rem",
    // @ts-ignore
    backgroundColor: "#555555",
    // @ts-ignore
    color: theme.palette.common.white,
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        // @ts-ignore
        color: theme.palette.background.ffGreen
      }
    },
    "@media (min-width:600px) ": {
      fontSize: "2.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.2rem"
    }
  },
  iconButton: {
    width: "2rem",
    height: "2rem",
    "@media (min-width:600px)": {
      width: "2.4rem",
      height: "2.4rem"
    },
    "@media (min-width:960px)": {
      width: "3rem",
      height: "3rem"
    }
  }
}));

const HorizontalPoster = ({
  playlists
}: Pick<CatalogOverviewResponse, "playlists">) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <>
                {playlists.length > 1 ? (
                  <Carousel
                    useKeyboardArrows={true}
                    showArrows={true}
                    interval={5000}
                    showThumbs={false}
                    infiniteLoop={true}
                    showStatus={false}
                    autoPlay={false}
                    showIndicators={true}
                    transitionTime={600}
                  >
                    {playlists.map((pl, ind) => (
                      <Link
                        key={ind}
                        to={"/series/" + pl.tag}
                        style={{ textDecoration: "none" }}
                      >
                        <Box style={{ position: "relative" }}>
                          <img
                            src={pl.posterHorizontalMediaUrl || undefined}
                            style={{ width: "100%", height: "auto" }}
                            alt={pl.name}
                          />
                        </Box>
                        <div
                          className={
                            classes.fullWidthItemContainerBottomGradient
                          }
                        >
                          <div className={classes.fullWidthItemDetails}>
                            {pl.thumbnailMediaUrl && (
                              <img
                                src={pl.thumbnailMediaUrl}
                                alt=""
                                className={classes.fullWidthItemChannel}
                              />
                            )}
                            <Typography
                              className={classes.fullWidthItemTitle}
                              variant="h4"
                            >
                              {pl.name}
                            </Typography>
                            <Typography
                              className={classes.fullWidthItemEpisodeCount}
                            >
                              {pl.total} Episodes
                            </Typography>
                          </div>
                          <Box className={classes.playButton}>
                            <IconButton
                              aria-label="Play Episode"
                              className={classes.seriesPlayIconButton}
                            >
                              <PlayArrowIcon className={classes.iconButton} />
                            </IconButton>
                          </Box>
                        </div>
                      </Link>
                    ))}
                  </Carousel>
                ) : (
                  <>
                    <Link to={"/series/" + playlists[0].tag}>
                      <Box
                        className={classes.fullWidthItemContainer}
                        style={{
                          backgroundImage: `url(${playlists[0].posterHorizontalMediaUrl})`
                        }}
                      >
                        <div
                          className={
                            classes.fullWidthItemContainerBottomGradient
                          }
                        >
                          <div className={classes.fullWidthItemDetails}>
                            {playlists[0].thumbnailMediaUrl && (
                              <img
                                src={playlists[0].thumbnailMediaUrl}
                                alt=""
                                className={classes.fullWidthItemChannel}
                              />
                            )}
                            <Typography
                              className={classes.fullWidthItemTitle}
                              variant="h4"
                            >
                              {playlists[0].name}
                            </Typography>
                            <Typography
                              className={classes.fullWidthItemEpisodeCount}
                            >
                              {playlists[0].total} Episodes
                            </Typography>
                          </div>
                          <Box className={classes.playButton}>
                            <IconButton
                              aria-label="Play Episode"
                              className={classes.seriesPlayIconButton}
                            >
                              <PlayArrowIcon className={classes.iconButton} />
                            </IconButton>
                          </Box>
                        </div>
                      </Box>
                    </Link>
                  </>
                )}
              </>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default HorizontalPoster;
