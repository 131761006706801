import React, { PropsWithChildren, useContext } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TodayIcon from "@material-ui/icons/Today";
import { makeStyles } from "@material-ui/core/styles";
import { MEMBER_STATUS_TRIAL, VideoSearchResult } from "@booyaltd/core";
import { msToTime } from "../../utils/strings";
import VideoFavouriteButton from "./VideoFavouriteButton";
import VideoPlayButton from "./VideoPlayButton";
import { getTitleAndChannelFromVideoName } from "../../utils/strings";
import dayjs from "dayjs";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import { OverviewContext } from "../../store/OverviewContext";
import { PlayerContext } from "../../store/PlayerContext";
import VideoProgress from "./VideoProgress";
import LockRoundedIcon from "@material-ui/icons/LockRounded";

type VideoRowProps = {
  video: VideoSearchResult;
  idx: number;
  memberStatusId?: string;
  listType:
    | "members-only-video"
    | "current-video"
    | "playlist"
    | "series"
    | "channel"
    | "continue-watching";
};

const useStyles = makeStyles(theme => ({
  episodeContainer: {
    position: "relative",
    "&:hover": {
      cursor: "pointer"
    }
  },
  episodeLink: {
    textDecoration: "none"
  },
  episodeWrapper: {
    marginBottom: theme.spacing(2)
  },
  episodeThumbnailContainer: {
    position: "relative",
    marginBottom: 0,
    paddingBottom: 0
  },
  episodeThumbnail: {
    width: "100%",
    height: "auto",
    aspectRatio: "16 / 9"
  },

  episodeTitleWrapper: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "@media (min-width:600px)": {
      flexDirection: "row"
    },
    "@media (min-width:960px)": {}
  },
  currentVideoEpisodeTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  episodeChannel: {
    borderRadius: "50%",
    width: "48px",
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  episodeChannelLg: {
    borderRadius: "50%",
    width: "80px",
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(1),
    "@media (min-width:600px)": {
      width: "90px"
    },
    "@media (min-width:960px)": {
      width: "100px"
    }
  },
  episodeTitle: {
    fontWeight: 500,
    fontSize: "1.6rem",
    lineHeight: "1.8rem",
    marginTop: 0,
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      marginBottom: theme.spacing(0)
    },
    "@media (min-width:960px)": {
      fontSize: "1.5rem"
    }
  },
  episodeNumber: {
    display: "inline",
    // @ts-ignore
    marginRight: theme.spacing(2),
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    fontWeight: 500,
    color: "#bbb",
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.1rem"
    }
  },
  episodeMetaDetailsContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    }
  },
  episodeDetailsIcon: {
    display: "inline",
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginRight: "4px",
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem"
    }
  },
  episodeDetailsText: {
    display: "inline",
    // @ts-ignore
    color: "#888",
    marginRight: theme.spacing(2),
    fontSize: "0.8rem",
    lineHeight: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.2rem"
    }
  },
  episodeDescription: {
    // @ts-ignore
    color: theme.palette.common.darkgray,
    fontSize: "0.9rem",
    lineHeight: "1rem",
    borderBottom: "1px solid #292929",
    paddingBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      borderBottom: "none"
    }
  },

  iconButton: {
    width: "2rem",
    height: "2rem"
  },
  "@global": {
    "@keyframes fadeIn": {
      from: {
        transform: "translate3d(0,15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    }
  },
  episodeGradientWatched: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.4) 10%, rgba(23,23,23,1) 100%)",
    height: "100%",
    width: "100%"
  },
  episodeWatchedLabel: {
    position: "absolute",
    top: "10px",
    right: "0px",
    backgroundColor: "rgba(23,23,23,0.4)",
    color: "#fff",
    padding: theme.spacing(0.7),
    display: "flex"
  },
  episodeWatchedLabelText: {
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  episodeWatchedLabelIcon: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginRight: theme.spacing(1)
  },
  continueWatchingWrapper: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#2c2c2c"
  },
  previewLabel: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontWeight: 800
  },
  membersOnlyWrapper: {
    marginBottom: theme.spacing(1)
  },
  episodeThumbnailContinueWatching: {
    width: "100%",
    height: "auto",
    aspectRatio: "16 / 9"
  },
  continueWatchingEpisodeTitle: {
    fontWeight: 500,
    fontSize: "1.2rem",
    lineHeight: "1.3rem",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      marginBottom: theme.spacing(1)
    },
    "@media (min-width:960px)": {
      fontSize: "1.5rem"
    }
  },
  currentVideoEpisodeTitle: {
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "1.7rem",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      fontSize: "2rem",
      lineHeight: "2.2rem",
      marginBottom: theme.spacing(0.25)
    },
    "@media (min-width:960px)": {
      fontSize: "2.5rem",
      lineHeight: "2.7rem",
      marginBottom: theme.spacing(0.5)
    }
  },
  continueWatchingEpisodeDescription: {
    fontSize: "0.9rem",
    maxHeight: "2rem",
    lineHeight: "1rem",
    // @ts-ignore
    color: theme.palette.common.darkgray,
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    display: "none",
    "@media (min-width:600px)": {
      display: "block"
    }
  },
  continueWatchingButtons: {
    padding: 0,
    "@media (min-width:600px)": {
      padding: "10px"
    }
  }
}));

const VideoRow = ({ video, listType, idx, memberStatusId }: VideoRowProps) => {
  const classes = useStyles();

  const overview = useContext(OverviewContext);
  const player = useContext(PlayerContext);

  const channel = overview?.overview?.channels?.find(
    c => video.channelId && c.id === video.channelId
  );

  const showMembersOnly =
    memberStatusId === MEMBER_STATUS_TRIAL &&
    video.restrictedFromMemberStatuses?.includes(memberStatusId);
  const { title, episode } = getTitleAndChannelFromVideoName(video.name);

  const RowLink = ({ children }: PropsWithChildren<any>) =>
    listType === "current-video" || listType === "members-only-video" ? (
      <Box>{children}</Box>
    ) : (
      <Link to={"/video-details/" + video.slug} className={classes.episodeLink}>
        {children}
      </Link>
    );

  return (
    <Grid
      container
      spacing={2}
      className={
        listType === "members-only-video"
          ? classes.membersOnlyWrapper
          : listType === "continue-watching"
          ? classes.continueWatchingWrapper
          : classes.episodeWrapper
      }
    >
      {listType !== "current-video" && listType !== "members-only-video" ? (
        <Grid item xs={listType === "continue-watching" ? 5 : 12} sm={5} md={4}>
          <RowLink>
            <Box className={classes.episodeContainer}>
              <img
                src={video.thumbnailThumb}
                className={
                  listType === "continue-watching"
                    ? classes.episodeThumbnailContinueWatching
                    : classes.episodeThumbnail
                }
                alt={video.name}
              />
              {showMembersOnly ? (
                <div className={classes.episodeGradientWatched}>
                  <div className={classes.episodeWatchedLabel}>
                    <LockRoundedIcon
                      className={classes.episodeWatchedLabelIcon}
                    />
                    <Typography
                      variant="body2"
                      className={classes.episodeWatchedLabelText}
                    >
                      Members Only
                    </Typography>
                  </div>
                </div>
              ) : video.watched ? (
                <div className={classes.episodeGradientWatched}>
                  <div className={classes.episodeWatchedLabel}>
                    <CheckCircleRoundedIcon
                      className={classes.episodeWatchedLabelIcon}
                    />
                    <Typography
                      variant="body2"
                      className={classes.episodeWatchedLabelText}
                    >
                      Watched
                    </Typography>
                  </div>
                </div>
              ) : null}
              <VideoProgress
                durationMs={video.duration}
                progress={video.progress}
                localProgress={player.localVideoProgresses[video.id]}
              />
            </Box>
          </RowLink>
        </Grid>
      ) : null}
      <Grid
        item
        xs={listType === "continue-watching" ? 7 : 12}
        sm={listType === "current-video" ? 12 : 7}
        md={listType === "current-video" ? 12 : 8}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              className={classes.episodeMetaDetailsContainer}
            >
              <RowLink>
                {listType === "series" ? (
                  <>
                    <Box style={{ marginBottom: "8px" }}>
                      <Typography className={classes.episodeTitle}>
                        {title}
                      </Typography>
                      <Typography className={classes.episodeNumber}>
                        Ep. {idx + 1}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    {channel && channel.logoImageUrl && (
                      <>
                        <Box
                          className={
                            listType === "current-video" ||
                            listType === "members-only-video"
                              ? classes.currentVideoEpisodeTitleWrapper
                              : classes.episodeTitleWrapper
                          }
                        >
                          {listType !== "channel" &&
                            listType !== "continue-watching" && (
                              <Box>
                                <img
                                  src={channel.logoImageUrl}
                                  className={
                                    listType === "current-video" ||
                                    listType === "members-only-video"
                                      ? classes.episodeChannelLg
                                      : classes.episodeChannel
                                  }
                                  alt={channel.name + " Logo"}
                                />
                              </Box>
                            )}
                          <Box>
                            <Typography
                              className={
                                listType === "continue-watching"
                                  ? classes.continueWatchingEpisodeTitle
                                  : listType === "current-video" ||
                                    listType === "members-only-video"
                                  ? classes.currentVideoEpisodeTitle
                                  : classes.episodeTitle
                              }
                            >
                              {listType === "members-only-video" ? (
                                <span className={classes.previewLabel}>
                                  Preview:{" "}
                                </span>
                              ) : (
                                ""
                              )}
                              {title}
                            </Typography>
                            {episode && (
                              <Typography className={classes.episodeNumber}>
                                Ep.{episode.replace("Ep", "")}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </RowLink>
              {listType !== "continue-watching" && (
                <Box display="flex" alignItems="flex-start" flexDirection="row">
                  <Box display="flex" alignItems="center" marginBottom={1}>
                    <AccessTimeIcon className={classes.episodeDetailsIcon} />
                    <Typography
                      variant="body2"
                      className={`${classes.episodeDetailsText}`}
                    >
                      {msToTime(video.duration)}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <TodayIcon className={classes.episodeDetailsIcon} />
                    <Typography
                      variant="body2"
                      className={`${classes.episodeDetailsText}`}
                    >
                      {video.publishedAt &&
                        dayjs(video.publishedAt).format("DD MMM YYYY")}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box>
              <Box
                display="flex"
                flexShrink={0}
                flexDirection="row"
                marginTop={listType === "current-video" ? 1 : 0}
                className={
                  listType !== "continue-watching"
                    ? classes.continueWatchingButtons
                    : ""
                }
              >
                {/* Check if video is added to Favourites List */}
                {!showMembersOnly &&
                  listType !== "continue-watching" &&
                  listType !== "members-only-video" && (
                    <VideoFavouriteButton
                      videoId={video.id}
                      initialFavourited={!!video.favourited || false}
                    />
                  )}
                {listType !== "current-video" &&
                  listType !== "members-only-video" && (
                    <VideoPlayButton videoSlug={video.slug} />
                  )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              className={
                listType === "continue-watching"
                  ? classes.continueWatchingEpisodeDescription
                  : classes.episodeDescription
              }
            >
              {video.description}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VideoRow;
