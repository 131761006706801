import React, { useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import HeaderInternal from "./Header/HeaderInternal";
import { AccountContext } from "../store/AccountContext";

import { Helmet } from "react-helmet";

// Styles
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4)
  },
  heading: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    marginBottom: "20px",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    }
  },
  subHeading: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    marginBottom: "20px",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    }
  },
  bodytext: {
    color: theme.palette.background.ffNavy,
    marginBottom: theme.spacing(3)
  }
}));

const TermsConditions = props => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { loggedIn } = useContext(AccountContext);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Terms of Use</title>
        <meta name="description" content="Please read our Terms of Use." />
      </Helmet>
      <>
        {loggedIn && <HeaderInternal />}
        {!loggedIn && <Header />}
      </>
      <Container fixed>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Container fixedcomponent="main" maxWidth="md">
              <div className={classes.paper}>
                <Typography
                  component="h1"
                  variant="h1"
                  className={classes.heading}
                >
                  Terms of Use
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  FarmFLiX provides a personalized subscription service that
                  allows our members to access original farming content
                  (&quot;FarmFLiX content&quot;) streamed over the Internet to
                  certain Internet-connected TVs, computers and other devices
                  (&quot;FarmFLiX ready devices&quot;).
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  These Terms of Use govern your use of our service. As used in
                  these Terms of Use, &quot;FarmFLiX service&quot;, &quot;our
                  service&quot; or &quot;the service&quot; means the
                  personalized service provided by FarmFLiX for discovering and
                  watching FarmFLiX content, including all features and
                  functionalities, recommendations and reviews, the website, and
                  user interfaces, as well as all content and software
                  associated with our service.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>1. Membership</b>
                  <br />
                  1.1. Your FarmFLiX membership will continue until terminated.
                  To use the FarmFLiX service you must have Internet access and
                  a FarmFLiX ready device, and provide us with one or more
                  Payment Methods. &quot;Payment Method&quot; means a current,
                  valid, accepted method of payment, as may be updated from time
                  to time, and which may include payment through your account
                  with a third party. Unless you cancel your membership before
                  your billing date, you authorize us to charge the membership
                  fee for the next billing cycle to your Payment Method (see
                  &quot;Cancellation&quot; below).
                  <br />
                  <br />
                  1.2. We may offer a number of membership plans, including
                  special promotional plans or memberships offered by third
                  parties in conjunction with the provision of their own
                  products and services. Some membership plans may have
                  differing conditions and limitations, which will be disclosed
                  at your sign-up or in other communications made available to
                  you. You can find specific details regarding your FarmFLiX
                  membership by visiting our website and clicking on the
                  &quot;Account&quot; link available at the top of the pages of
                  the FarmFLiX website under your profile name.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>2. Free Trials</b>
                  <br />
                  2.1. Your FarmFLiX membership may start with a free trial. The
                  duration of the free trial period of your membership will be
                  specified during sign-up and is intended to allow new members
                  and certain former members to try the service.
                  <br />
                  <br />
                  2.2. Free trial eligibility is determined by FarmFLiX at its
                  sole discretion and we may limit eligibility or duration to
                  prevent free trial abuse. We reserve the right to revoke the
                  free trial and put your account on hold in the event that we
                  determine that you are not eligible. Members of households
                  with an existing or recent FarmFLiX membership are not
                  eligible. We may use information such as device ID, method of
                  payment or an account email address used with an existing or
                  recent FarmFLiX membership to determine eligibility. For
                  combinations with other offers, restrictions may apply.
                  <br />
                  <br />
                  2.3. We will charge the membership fee for the next billing
                  cycle to your Payment Method at the end of the free trial
                  period unless you cancel your membership prior to the end of
                  the free trial period. To view the membership price and end
                  date of your free trial period, visit our website and click
                  the &quot;Billing details&quot; link on the
                  &quot;Account&quot; page.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>3. Billing and Cancellation</b>
                  <br />
                  3.1.{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Billing Cycle
                  </span>
                  . The membership fee for the FarmFLiX service will be charged
                  to your Payment Method on the specific billing date indicated
                  on your &quot;Account&quot; page. The length of your billing
                  cycle will depend on the type of subscription that you choose
                  when you sign-up for the service. In some cases your payment
                  date may change, for example if your Payment Method has not
                  successfully settled or if your paid membership began on a day
                  not contained in a given month. Visit our website and click on
                  the &quot;Billing details&quot; link on the
                  &quot;Account&quot; page to see your next payment date.
                  <br />
                  <br />
                  3.2.{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Payment Methods
                  </span>
                  . To use the FarmFLiX service you must provide one or more
                  Payment Methods. You authorize us to charge any Payment Method
                  associated to your account in case your primary Payment Method
                  is declined or no longer available to us for payment of your
                  subscription fee. You remain responsible for any uncollected
                  amounts. If a payment is not successfully settled, due to
                  expiration, insufficient funds, or otherwise, and you do not
                  cancel your account, we may suspend your access to the service
                  until we have successfully charged a valid Payment Method. For
                  some Payment Methods, the issuer may charge you certain fees,
                  such as foreign transaction fees or other fees relating to the
                  processing of your Payment Method. Local tax charges may vary
                  depending on the Payment Method used. Check with your Payment
                  Method service provider for details.
                  <br />
                  <br />
                  3.3.{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Updating your Payment Methods
                  </span>
                  . You can update your Payment Methods by going to the
                  &quot;Account&quot; page. We may also update your Payment
                  Methods using information provided by the payment service
                  providers. Following any update, you authorize us to continue
                  to charge the applicable Payment Method(s).
                  <br />
                  <br />
                  3.4.{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Cancellation
                  </span>
                  . You can cancel your FarmFLiX membership at any time, and you
                  will continue to have access to the FarmFLiX service through
                  the end of your billing period. Payments are non-refundable
                  and we do not provide refunds or credits for any partial -
                  month membership periods or unwatched FarmFLiX content. To
                  cancel, go to the &quot;Account&quot; page and follow the
                  instructions for cancellation. If you cancel your membership,
                  your account will automatically close at the end of your
                  current billing period. To see when your account will close,
                  click &quot;Billing details&quot; on the &quot;Account&quot;
                  page. If you signed up for Netflix using your account with a
                  third party as a Payment Method and wish to cancel your
                  FarmFLiX membership, you may need to do so through such third
                  party, for example by visiting your account with the
                  applicable third party and turning off auto-renew, or
                  unsubscribing from the FarmFLiX service through that third
                  party. You may also find billing information about your
                  FarmFLiX membership by visiting your account with the
                  applicable third party.
                  <br />
                  <br />
                  3.5.{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Changes to the Price and Subscription Plans
                  </span>
                  . We may change our subscription plans and the price of our
                  service from time to time; however, any price changes or
                  changes to your subscription plans will apply no earlier than
                  30 days following notice to you.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>4. FarmFLiX Service</b>
                  <br />
                  4.1. You must be 18 years of age, or the age of majority in
                  your province, territory or country, to become a member of the
                  FarmFLiX service. Minors may only use the service under the
                  supervision of an adult.
                  <br />
                  <br />
                  4.2. The FarmFLiX service and any content viewed through the
                  service are for your personal and non-commercial use only and
                  may not be shared with individuals beyond your household.
                  During your FarmFLiX membership we grant you a limited,
                  non-exclusive, non-transferable right to access the FarmFLiX
                  service and view FarmFLiX content. Except for the foregoing,
                  no right, title or interest shall be transferred to you. You
                  agree not to use the service for public performances.
                  <br />
                  <br />
                  4.3. You may view the FarmFLiX content primarily within the
                  country in which you have established your account. The number
                  of devices on which you may simultaneously watch depends on
                  your chosen subscription plan.
                  <br />
                  <br />
                  4.4. The FarmFLiX service, including the content library, is
                  regularly updated. In addition, we continually test various
                  aspects of our service, including our website, user
                  interfaces, promotional features and availability of FarmFLiX
                  content.
                  <br />
                  <br />
                  4.5. You agree not to archive, reproduce, distribute, modify,
                  display, perform, publish, license, create derivative works
                  from, offer for sale, or use (except as explicitly authorized
                  in these Terms of Use) content and information contained on or
                  obtained from or through the FarmFLiX service. You also agree
                  not to: circumvent, remove, alter, deactivate, degrade or
                  thwart any of the content protections in the FarmFLiX service;
                  use any robot, spider, scraper or other automated means to
                  access the FarmFLiX service; decompile, reverse engineer or
                  disassemble any software or other products or processes
                  accessible through the FarmFLiX service; insert any code or
                  product or manipulate the content of the FarmFLiX service in
                  any way; or use any data mining, data gathering or extraction
                  method. In addition, you agree not to upload, post, e-mail or
                  otherwise send or transmit any material designed to interrupt,
                  destroy or limit the functionality of any computer software or
                  hardware or telecommunications equipment associated with the
                  FarmFLiX service, including any software viruses or any other
                  computer code, files or programs. We may terminate or restrict
                  your use of our service if you violate these Terms of Use or
                  are engaged in illegal or fraudulent use of the service.
                  <br />
                  <br />
                  4.6. The quality of the display of the FarmFLiX content may
                  vary from device to device, and may be affected by a variety
                  of factors, such as your location, the bandwidth available
                  through and/or speed of your Internet connection. HD
                  availability is subject to your Internet service and device
                  capabilities. The minimum connection speed for SD quality is
                  0.5 Mbps; however, we recommend a faster connection for
                  improved video quality. A download speed of at least 5.0 Mbps
                  per stream is recommended to receive HD content (defined as a
                  resolution of 720p or higher). A download speed of at least
                  25.0 Mbps per stream is recommended to receive Ultra HD
                  (defined as a resolution of 1080p or higher) and HDR content.
                  You are responsible for all Internet access charges. Please
                  check with your Internet provider for information on possible
                  Internet data usage charges. The time it takes to begin
                  watching FarmFLiX content will vary based on a number of
                  factors, including your location, available bandwidth at the
                  time, the content you have selected and the configuration of
                  your FarmFLiX ready device.
                  <br />
                  <br />
                  4.7. FarmFLiX software is developed by, or for, FarmFLiX and
                  is designed to enable viewing of FarmFLiX content through
                  FarmFLiX ready devices. This software may vary by device and
                  medium, and functionalities and features may also differ
                  between devices. You acknowledge that the use of the service
                  may require third party software that is subject to third
                  party licenses. You agree that you may automatically receive
                  updated versions of the FarmFLiX and related third-party
                  software.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>5. Passwords and Account Access</b>
                  <br />
                  The member who created the FarmFLiX account and whose Payment
                  Method is charged (the &quot;Account Owner&quot;) has access
                  and control over the FarmFLiX account and the FarmFLiX ready
                  devices that are used to access our service and is responsible
                  for any activity that occurs through the FarmFLiX account. To
                  maintain control over the account and to prevent anyone from
                  accessing the account (which would include information on
                  viewing history for the account), the Account Owner should
                  maintain control over the FarmFLiX ready devices that are used
                  to access the service and not reveal the password or details
                  of the Payment Method associated with the account to anyone.
                  You are responsible for updating and maintaining the accuracy
                  of the information you provide to us relating to your account.
                  We can terminate your account or place your account on hold in
                  order to protect you, FarmFLiX or our partners from identity
                  theft or other fraudulent activity.
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  className={classes.bodytext}
                >
                  <b>6. Miscellaneous</b>
                  <br />
                  6.1.{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Governing Law
                  </span>
                  . These Terms of Use shall be governed by and construed in
                  accordance with the laws of the UK. These terms will not limit
                  any consumer protection rights that you may be entitled to
                  under the mandatory laws of your country of residence.
                  <br />
                  <br />
                  6.2.{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Unsolicited Materials
                  </span>
                  . FarmFLiX does not accept unsolicited materials or ideas for
                  Netflix content, and is not responsible for the similarity of
                  any of its content or programming in any media to materials or
                  ideas transmitted to FarmFLiX.
                  <br />
                  <br />
                  6.3.{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Customer Support
                  </span>
                  . To find more information about our service and its features
                  or if you need assistance with your account, please visit the
                  FarmFLiX Help Center on our website. In certain instances,
                  Customer Service may best be able to assist you by using a
                  remote access support tool through which we have full access
                  to your computer. If you do not want us to have this access,
                  you should not consent to support through the remote access
                  tool, and we will assist you through other means.
                  <br />
                  <br />
                  6.4.{" "}
                  <span style={{ textDecoration: "underline" }}>Survival</span>.
                  If any provision or provisions of these Terms of Use shall be
                  held to be invalid, illegal, or unenforceable, the validity,
                  legality and enforceability of the remaining provisions shall
                  remain in full force and effect.
                  <br />
                  <br />
                  6.5.{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Changes to Terms of Use
                  </span>
                  . FarmFLiX may, from time to time, change these Terms of Use.
                  We will notify you at least 30 days before such changes apply
                  to you.
                  <br />
                  <br />
                  6.6.{" "}
                  <span style={{ textDecoration: "underline" }}>
                    Electronic Communications
                  </span>
                  . We will send you information relating to your account (e.g.
                  payment authorizations, invoices, changes in password or
                  Payment Method, confirmation messages, notices) in electronic
                  form only, for example via emails to your email address
                  provided during registration.
                </Typography>
              </div>
              <Typography
                component="p"
                variant="body2"
                className={classes.bodytext}
              >
                <b>Last Updated</b>: 30 April 2020
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default TermsConditions;
