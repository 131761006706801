import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Grid,
  Button,
  CircularProgress
} from "@material-ui/core";
import { OverviewContext } from "../../store/OverviewContext";
import TryButton from "../Onboarding/TryButton";
import { Link } from "react-router-dom";
import { Player } from "../common/player";
import { PlayerContext } from "../../store/PlayerContext";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(5, 0, 6, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0, 10, 0)
    }
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },
  description: {
    letterSpacing: "0px",
    fontSize: "1rem",
    lineHeight: "1rem",
    fontWeight: 500,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      letterSpacing: "-1px",
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },
  secondaryButton: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.white,
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    border: "2px solid #fff",
    marginLeft: 0,
    marginRight: 0,
    "&:hover": {
      border: "2px solid #99ca3c",
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      width: "80%"
    }
  },
  videoContainer: {
    position: "relative",
    paddingBottom: "56.25%",
    width: "100%",
    height: "0",
    margin: theme.spacing(3, 0)
  },
  videoWrapper: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%"
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%"
  },
  player: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    "& video": {
      "&:focus": {
        outline: "none"
      }
    }
  },
  playIcon: {
    fontSize: "6rem",
    "@media (min-width:600px)": {
      fontSize: "7rem"
    },
    "@media (min-width:960px)": {
      fontSize: "8rem"
    }
  },
  ctaButtonContainer: {
    width: "100%",
    textAlign: "center"
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "300px"
    },
    "@media (min-width:960px)": {
      width: "300px"
    }
  },
  buttonSubText: {
    fontSize: "0.9rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    "@media (min-width:600px)": {
      textAlign: "center",
      marginLeft: 0
    },
    "@media (min-width:960px)": {}
  },
  noUnderline: {
    textDecoration: "none"
  },
  textContainer: {
    textAlign: "center",
    margin: theme.spacing(3, 0)
  },
  textHeading: {
    letterSpacing: "-1px",
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    fontWeight: 500,
    textShadow: "-1px 1px 1px #171717",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.6rem"
    },
    "@media (min-width:960px)": {
      letterSpacing: "-1px",
      fontSize: "1.8rem",
      lineHeight: "1.8rem",
      fontWeight: 500,
      textShadow: "-2px 2px 2px #171717",
      marginBottom: theme.spacing(1)
    }
  }
}));

const PreviewVideo = ({
  titleSmall,
  titleLarge,
  videoId,
  description
}: {
  titleSmall: any;
  titleLarge: string | Element | undefined;
  videoId: string;
  description: string | undefined;
}) => {
  const classes = useStyles();
  const { overview } = useContext(OverviewContext);
  const {
    loadPreviewVideo,
    currentPreviewVideo,
    clearCurrentPreviewVideo
  } = useContext(PlayerContext);

  useEffect(() => {
    return () => {
      clearCurrentPreviewVideo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (videoId) {
      loadPreviewVideo(videoId, "slug");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  return (
    <div className={classes.background}>
      <Container maxWidth="lg" fixed>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.title}>
              {titleSmall}
            </Typography>
            <Typography variant="h2" className={classes.titleLarge}>
              {titleLarge}
            </Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.videoContainer}>
            {currentPreviewVideo ? (
              <Player
                userId={undefined}
                video={currentPreviewVideo}
                type="preview"
              />
            ) : (
              <CircularProgress color="primary" />
            )}
          </Grid>
          {overview && (
            <>
              {/*
                  Doors Open & Trials Open - show Free Trial
                  Doors Closed & Trials Open - show Free Trial
                  Doors Open & Trials Closed - show Join Now
                  Doors Closed & Trials Closed - show Free Trial (notify me status)
                */}
              {overview.doors?.open && overview.doors?.trialsOpen && (
                <Grid item xs={12} className={classes.textContainer}>
                  <Typography variant="h4" className={classes.textHeading}>
                    Watch the entire {titleLarge} Series now!
                  </Typography>

                  <TryButton
                    label="Take Free Trial"
                    size="large"
                    className={classes.button}
                  />
                  <Typography variant="body1" className={classes.buttonSubText}>
                    No Payment Required for Free Trial
                  </Typography>
                </Grid>
              )}
              {!overview.doors?.open && overview.doors?.trialsOpen && (
                <Grid item xs={12} className={classes.textContainer}>
                  <Typography variant="h4" className={classes.textHeading}>
                    Watch the entire {titleLarge} Series now!
                  </Typography>

                  <TryButton
                    label="Take Free Trial"
                    size="large"
                    className={classes.button}
                  />
                  <Typography variant="body1" className={classes.buttonSubText}>
                    No Payment Required for Free Trial
                  </Typography>
                </Grid>
              )}
              {overview.doors?.open && !overview.doors?.trialsOpen && (
                <>
                  <Grid item xs={12} className={classes.textContainer}>
                    <Typography variant="h4" className={classes.textHeading}>
                      Watch the entire {titleLarge} Series now!
                    </Typography>
                    <Link to="/buy" style={{ textDecoration: "none" }}>
                      <Button
                        disableRipple
                        size="large"
                        className={classes.button}
                      >
                        Join Now
                      </Button>
                    </Link>
                    <Typography
                      variant="body1"
                      className={classes.buttonSubText}
                    >
                      <b>Don&apos;t Miss Out!</b>
                      <br />
                      Last chance to join this season!
                    </Typography>
                  </Grid>
                </>
              )}
              {!overview.doors?.trialsOpen && !overview.doors?.open && (
                <>
                  <Grid item xs={12} className={classes.textContainer}>
                    <Typography variant="h4" className={classes.textHeading}>
                      Watch the entire {titleLarge} Series now!
                    </Typography>
                    <TryButton
                      label="Take Free Trial"
                      size="large"
                      className={classes.button}
                    />
                    <Typography
                      variant="body1"
                      className={classes.buttonSubText}
                    >
                      No Payment Required for Free Trial
                    </Typography>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default PreviewVideo;
