import React, { useContext } from "react";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line
import { Element } from 'react-scroll';
import TryButton from "../Onboarding/TryButton";
import { OverviewContext } from "../../store/OverviewContext";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  background: {
    textAlign: "center",
    flexGrow: 1,
    backgroundColor: theme.palette.background.ffNavyLighter,
    borderTop: "10px solid #202020"
  },
  formBackground: {
    padding: "10% 5%",
    "@media (min-width:600px)": {
      padding: "5%"
    }
  },
  loading: {
    textAlign: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  mainTitle: {
    color: theme.palette.common.white,
    fontSize: "2rem",
    lineHeight: "2rem",
    marginBottom: theme.spacing(1),
    textAlign: "center",
    // padding: theme.spacing(0, 4),
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.8rem",
      lineHeight: "2.8rem"
    }
  },
  subTitle: {
    fontSize: "1.1rem",
    lineHeight: "1.3rem",
    margin: theme.spacing(2.5, 0),
    textAlign: "center",
    padding: theme.spacing(0, 4),
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.3rem"
    }
  },
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "300px"
    }
  },
  buttonSubText: {
    fontSize: "0.9rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginLeft: 0,
    "@media (min-width:600px)": {
      textAlign: "center",
      marginLeft: 0
    },
    "@media (min-width:960px)": {}
  }
}));

const PreviewCTA = () => {
  const classes = useStyles();

  const { overview } = useContext(OverviewContext);

  return (
    <>
      {overview && (
        <div className={classes.background}>
          <>
            <Element name="notify-form">
              <div className={classes.formBackground}>
                <>
                  <Container
                    fixed
                    fixedcomponent="main"
                    maxWidth="md"
                    className={classes.doorsOpeningContainer}
                  >
                    <Grid container>
                      {overview.doors.open && overview.doors.trialsOpen && (
                        <>
                          <Grid item xs={12} align="center">
                            <Typography
                              component="h3"
                              variant="h3"
                              className={classes.mainTitle}
                            >
                              Want to watch the full episode?
                            </Typography>
                            <TryButton
                              label="Take Free Trial"
                              size="large"
                              className={classes.button}
                            />
                            <Typography
                              variant="body1"
                              className={classes.buttonSubText}
                            >
                              No Payment Required for Free Trial
                            </Typography>
                          </Grid>
                        </>
                      )}

                      {!overview.doors.open && overview.doors.trialsOpen && (
                        <Grid item xs={12} align="center">
                          <Typography
                            component="h3"
                            variant="h3"
                            className={classes.mainTitle}
                          >
                            Want to watch the full episode?
                          </Typography>
                          <TryButton
                            label="Take Free Trial"
                            size="large"
                            className={classes.button}
                          />
                          <Typography
                            variant="body1"
                            className={classes.buttonSubText}
                          >
                            No Payment Required for Free Trial
                          </Typography>
                        </Grid>
                      )}

                      {overview.doors.open && !overview.doors.trialsOpen && (
                        <>
                          <Grid item xs={12} align="center">
                            <Typography
                              component="h3"
                              variant="h3"
                              className={classes.mainTitle}
                            >
                              Want to see the full episode?
                            </Typography>
                            <Link to="/buy" style={{ textDecoration: "none" }}>
                              <Button
                                disableRipple
                                size="large"
                                className={classes.button}
                              >
                                Join Now
                              </Button>
                            </Link>
                            <Typography
                              variant="body1"
                              className={classes.buttonSubText}
                            >
                              <b>Don&apos;t Miss Out!</b>
                              <br />
                              Last chance to join this season!
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {!overview.doors.open && !overview.doors.trialsOpen && (
                        <>
                          <Grid item xs={12} align="center">
                            <Typography
                              component="h3"
                              variant="h3"
                              className={classes.mainTitle}
                            >
                              Membership Locked until Christmas{" "}
                              {dayjs(overview.doors?.until).format("YYYY")}
                            </Typography>
                            <Typography
                              component="h4"
                              variant="h4"
                              className={classes.subTitle}
                            >
                              Tell us your mobile number and will notify you
                              when we&apos;re taking on new members.
                            </Typography>
                            <TryButton
                              label="Notify Me"
                              size="large"
                              className={classes.button}
                            />
                          </Grid>
                        </>
                      )}
                      {/*  */}
                    </Grid>
                  </Container>
                </>
              </div>
            </Element>
          </>
        </div>
      )}
    </>
  );
};

export default PreviewCTA;
