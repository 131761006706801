import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(6, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0, 10, 0)
    }
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "4.2rem",
      lineHeight: "4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "80%"
    },
    "@media (min-width:960px)": {}
  },
  secondaryButton: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.white,
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    border: "2px solid #fff",
    marginLeft: 0,
    marginRight: 0,
    "&:hover": {
      border: "2px solid #99ca3c",
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      width: "80%"
    }
  },
  textContainer: {
    margin: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      margin: theme.spacing(1, 0)
    },
    "@media (min-width:960px)": {
      margin: theme.spacing(3, 0)
    }
  },
  featureTitle: {
    letterSpacing: "-1px",
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    fontWeight: 500,
    textShadow: "-1px 1px 1px #171717",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.6rem"
    },
    "@media (min-width:960px)": {
      letterSpacing: "-1px",
      fontSize: "1.8rem",
      lineHeight: "1.8rem",
      fontWeight: 500,
      textShadow: "-2px 2px 2px #171717",
      marginBottom: theme.spacing(1)
    }
  },
  featureDesc: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.3rem"
    },
    "@media (min-width:960px)": {}
  }
}));

const WhatIs = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="lg" fixed>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.title}>
                What is
              </Typography>
              <Typography variant="h2" className={classes.titleLarge}>
                FarmFLiX?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} className={classes.textContainer}>
              <Typography variant="h3" className={classes.featureTitle}>
                Hours of Agri Entertainment
              </Typography>
              <Typography variant="body1" className={classes.featureDesc}>
                Access over 400 episodes of high quality content in Full HD.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} className={classes.textContainer}>
              <Typography variant="h3" className={classes.featureTitle}>
                New Episode Added Weekly
              </Typography>
              <Typography variant="body1" className={classes.featureDesc}>
                Huge variety of episodes across the AgLife, Stockyards and
                Straight6 channels.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} className={classes.textContainer}>
              <Typography variant="h3" className={classes.featureTitle}>
                Stream and Enjoy on The App
              </Typography>
              <Typography variant="body1" className={classes.featureDesc}>
                Access FarmFLiX on our new App. No limits. No ads.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default WhatIs;
