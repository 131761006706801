export const uppercaseFirst = (str: string) =>
  str[0].toUpperCase() + str.slice(1);

// Convert milliseconds to minutes and seconds string
export const msToTime = (s: number): string => {
  const secs = (s / 1000) % 60;
  const hrs = Math.floor((s / (1000 * 60 * 60)) % 24);
  const mins = Math.floor(((s / (1000 * 60)) % 60) + hrs * 60);

  const secsStr = secs < 10 ? `0${secs}` : secs;
  const minsStr = mins < 10 ? `0${mins}` : mins;

  return `${minsStr}m ${secsStr}s`;
};

export const calculateProgress = (progress: number, duration: number) => {
  let progressPercentage = progress > 0 ? progress / (duration / 1000) : 0;
  return Math.floor(progressPercentage * 100);
};

export const getTitleAndChannelFromVideoName = (
  name: string
): { title: string; episode?: string } => {
  let title = name;
  let episode: string | undefined = undefined;
  const nameParts = name
    .split(" - ", 4)
    .map(t => t.trim())
    .filter(part => !part.match(/aglife|straight6|loft|stockyards/i));

  const episodeRegex = /Ep([0-9]{1,})/i;
  for (let partIdx = 0; partIdx < nameParts.length; partIdx++) {
    const part = nameParts[partIdx];
    const episodeRegexMatch = part.match(episodeRegex);
    if (episodeRegexMatch && episodeRegexMatch[0] && episodeRegexMatch[1]) {
      episode = episodeRegexMatch[1];
    } else {
      title = part;
    }
  }

  return { title, episode };
};

export const currencySymbol = (currency: string) => {
  switch (currency) {
    case "GBP":
      return "\u00A3";
    case "EUR":
      return "\u20AC";
    case "USD":
      return "\u0024";
    default:
      return "\u00A3";
  }
};
