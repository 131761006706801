import React, { useCallback, useState } from "react";
import Typography from "@material-ui/core/Typography";
import ReactCodeInput from "react-verification-code-input";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Box, BoxProps } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {},
  submit: {
    width: "100%",
    margin: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  bodyText: {
    color: theme.palette.common.white,
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  verificationCodeContainer: {
    width: "100% !important",
    fontWeight: 600,
    margin: theme.spacing(1.5, 0),
    "& input": {
      height: "56px !important",
      width: "15.6% !important",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderTopLeftRadius: "0px !important",
      borderBottomLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
      borderBottom: 0,
      backgroundColor: "#444",
      margin: "0 0.5%",
      fontSize: "2rem",
      fontWeight: 500,
      color: "#fff",
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
      "&:focus": {
        border: "1px solid #99ca3c",
        caretColor: "#fff",
        "&:last-child": {
          borderRight: "1px solid #99ca3c"
        }
      },
      "&:last-child": {
        borderRight: "none"
      }
    }
  }
}));

const CodeForm = ({
  onSubmit,
  submitButtonText = "Reset Password",
  descText = "Enter this code below to reset your password.",
  containerClassName,
  textClassName,
  verificationCodeContainerClassName,
  submitClassName
}: {
  onSubmit: (code: string) => void;
  descText?: string;
  submitButtonText?: string;
  containerClassName?: BoxProps["className"];
  textClassName?: BoxProps["className"];
  verificationCodeContainerClassName?: BoxProps["className"];
  submitClassName?: BoxProps["className"];
}) => {
  const classes = useStyles();
  const [code, setCode] = useState("");

  const isValid = code && code.length === 6;
  const onClick = useCallback(() => {
    onSubmit(code);
  }, [code, onSubmit]);

  return (
    <Box className={containerClassName || classes.container}>
      {descText && (
        <Typography
          variant="body1"
          className={textClassName || classes.bodyText}
        >
          We have sent you an email with a <b>6 digit code</b>.<br />
          <br />
          {descText}
        </Typography>
      )}
      {/** @ts-ignore */}
      <ReactCodeInput
        className={
          verificationCodeContainerClassName ||
          classes.verificationCodeContainer
        }
        type="number"
        fields={6}
        onComplete={setCode}
      />
      <Button
        className={submitClassName || classes.submit}
        disabled={!isValid}
        type="button"
        onClick={onClick}
      >
        {submitButtonText}
      </Button>
    </Box>
  );
};

export default CodeForm;
