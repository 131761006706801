import React, { useState, useContext, useMemo } from "react";
import {
  Popover,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Typography
} from "@material-ui/core";
import { SearchContext } from "./SearchContainer";
import TodayIcon from "@material-ui/icons/Today";

import useStyles from "./styles";
import dayjs from "dayjs";

const ChannelFilter = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { search, updateSearch } = useContext(SearchContext);
  // const { searchYear, setSearchYear } = useContext(SearchContext);

  const handleFilterOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "year-popover" : undefined;

  const handleFilterChange = (e: any, yearStr: string) => {
    if (yearStr === "all") {
      updateSearch({
        publishedDateFrom: undefined,
        publishedDateTo: undefined
      });
    } else {
      const year = parseInt(yearStr);
      updateSearch({
        publishedDateFrom: dayjs()
          .set("year", year)
          .startOf("year")
          .toDate(),
        publishedDateTo: dayjs()
          .set("year", year)
          .endOf("year")
          .toDate()
      });
    }
  };

  const yearsFilter = useMemo(() => {
    const currentYear = new Date().getFullYear(),
      years = [];

    let startYear = 2016;

    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.sort(function(a, b) {
      return b - a;
    });
  }, []);

  const selectedYear =
    search && search.publishedDateFrom
      ? dayjs(search.publishedDateFrom).get("year")
      : undefined;

  return (
    <>
      <Chip
        className={classes.filterChip}
        label={
          <>
            <Box display="flex" alignItems="center">
              <TodayIcon
                className={
                  selectedYear
                    ? classes.filterChipIconActive
                    : classes.filterChipIcon
                }
              />
              <Typography className={classes.filterChipLabel}>
                {selectedYear ? selectedYear : "Year"}
              </Typography>
            </Box>
          </>
        }
        aria-describedby={id}
        onClick={handleFilterOpen}
      />

      <Popover
        classes={{ paper: classes.popOverContainer }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <RadioGroup
          aria-label="year_filter"
          name="yearFilter"
          value={selectedYear ? selectedYear.toString() : "all"}
          onChange={handleFilterChange}
        >
          <FormControlLabel
            classes={{ label: classes.filterLabel }}
            value="all"
            control={<Radio color="primary" />}
            label="All"
          />
          {yearsFilter.map(year => (
            <FormControlLabel
              key={year}
              classes={{ label: classes.filterLabel }}
              value={year.toString()}
              control={<Radio color="primary" />}
              label={year.toString()}
            />
          ))}
        </RadioGroup>
      </Popover>
    </>
  );
};

export default ChannelFilter;
