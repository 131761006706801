import React from "react";
import { Button, Card, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(4),
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    padding: "8px 14px",
    fontSize: "0.8rem",
    lineHeight: "1.2rem",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s ease-in-out"
    }
  },
  buttonPrimary: {},
  buttonSecondary: {
    border: "2px solid #fff",
    color: "#fff",
    "&:hover": {
      // @ts-ignore
      borderColor: theme.palette.background.ffGreen,
      // @ts-ignore
      color: theme.palette.background.ffGreen
    }
  },
  membershipOptionWrapper: {
    "@media (min-width:600px)": {
      padding: "12px 20px"
    }
  },
  membershipOptionContainer: {
    flex: 1,
    textAlign: "center",
    padding: theme.spacing(3, 1.5),
    margin: 0,
    "&:first-child": {
      marginRight: theme.spacing(1)
    },
    "&:last-child": {
      marginLeft: theme.spacing(1)
    },

    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    "@media (min-width:600px)": {
      padding: theme.spacing(3, 4)
    }
  },
  membershipOptionTitle: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontSize: "1.8rem",
    marginBottom: theme.spacing(3)
  },
  membershipOptionPrimaryPrice: {
    fontSize: "2.2rem",
    color: theme.palette.common.white,
    fontWeight: 500,
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      fontSize: "3rem"
    },
    "& span": {
      fontSize: "1.4rem",
      fontWeight: "400"
    }
  },
  membershipOptionDescription: {
    margin: theme.spacing(2, 0)
  },
  smlDivider: {
    height: "2px",
    width: "30%",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffGreen,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  }
}));

export type SubscriptionPriceCardProps = {
  title: string;
  productId: string;
  duration: "monthly" | "yearly";
  gbpPrice: number;
  eurPrice: number;
  usdPrice: number;
  isPrimary: boolean;
};

export const SubscriptionPriceCard = ({
  title,
  productId,
  duration,
  gbpPrice,
  eurPrice,
  usdPrice,
  isPrimary
}: SubscriptionPriceCardProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.membershipOptionContainer}>
      <Typography className={classes.membershipOptionTitle}>{title}</Typography>
      <Typography className={classes.membershipOptionPrimaryPrice}>
        &pound;{gbpPrice.toFixed(2)}
        <span>/{duration === "monthly" ? "Month" : "Year"}</span>
      </Typography>
      <Typography
        className={classes.membershipOptionDescription}
        variant="body1"
      >
        <b>&euro;{eurPrice.toFixed(2)}</b> / <b>&#36;{usdPrice.toFixed(2)}</b>
      </Typography>

      <Link to={`/buy?product=${productId}`} style={{ textDecoration: "none" }}>
        <Button
          disableRipple
          size="large"
          variant={isPrimary ? undefined : "outlined"}
          className={classnames(classes.button, {
            [classes.buttonPrimary]: isPrimary,
            [classes.buttonSecondary]: !isPrimary
          })}
        >
          Join Now
        </Button>
      </Link>
    </Card>
  );
};
