import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Typography, Grid, Button } from "@material-ui/core";
// import { Link } from "react-router-dom";
import { OverviewContext } from "../../store/OverviewContext";
import TryButton from "../Onboarding/TryButton";
import TodayIcon from "@material-ui/icons/Today";
import { Theme } from "@material-ui/core";
import { Link } from "react-router-dom";
export interface StyleProps {
  backgroundPositionSm: string | undefined;
  backgroundPositionXl: string | undefined;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    "@media (min-width:1280px)": {
      padding: 0
    }
  },
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "auto",
    display: "flex",
    height: "80vh",
    boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.6)",
    backgroundPositionX: ({ backgroundPositionSm }) => backgroundPositionSm,

    // @ts-ignore
    paddingBottom: theme.spacing(0),
    position: "relative",
    "@media (min-width:600px)": {
      backgroundSize: "cover",
      height: "80vh"
    },
    "@media (min-width:769px)": {
      height: "80vh"
    },
    "@media (min-width:960px)": {
      backgroundSize: "cover",
      height: "80vh"
    },
    "@media (min-width:1280px)": {
      backgroundPositionX: ({ backgroundPositionXl }) => backgroundPositionXl,
      boxShadow: "none",
      backgroundSize: "contain",
      height: "80vh",
      maxHeight: "740px"
    }
  },
  gradient: {
    background: "none",
    height: "80vh",
    "@media (min-width:600px)": {
      height: "80vh"
    },
    "@media (min-width:769px)": {
      height: "80vh"
    },
    "@media (min-width:960px)": {
      height: "80vh"
    },
    "@media (min-width:1280px)": {
      boxShadow: "none",
      maxHeight: "740px",
      background:
        "linear-gradient(95deg, rgba(23,23,23,1) 9%, rgba(23,23,23,1) 22%, rgba(23,23,23,0.08307072829131656) 60%, rgba(23,23,23,0.665703781512605) 77%, rgba(23,23,23,1) 85%)",
      height: "80vh",
      marginLeft: "-24px",
      marginRight: "-24px"
    }
  },
  centerBox: {
    position: "relative",
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(0),
    "@media (min-width:600px)": {
      height: "80vh",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:769px)": {
      height: "80vh",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:960px)": {
      height: "80vh",
      alignItems: "flex-start",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:1280px)": {
      height: "80vh",
      maxHeight: "740px"
    }
  },
  logo: {
    width: "40%",
    "@media (min-width:600px)": {
      width: "200px"
    },
    "@media (min-width:960px)": {}
  },
  title: {
    marginTop: theme.spacing(0),
    letterSpacing: "-2px",
    fontSize: "4.4rem",
    lineHeight: "3.6rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(2),
    "& span": {
      fontSize: "2.2rem",
      lineHeight: "2.6rem"
    },
    "@media (min-width:600px)": {
      fontSize: "5.4rem",
      lineHeight: "4.6rem",
      "& span": {
        fontSize: "2.2rem",
        lineHeight: "2.4rem"
      }
    },
    "@media (min-width:960px)": {
      fontSize: "5.8rem",
      lineHeight: "5rem",
      "& span": {
        fontSize: "2.6rem",
        lineHeight: "2.8rem"
      }
    },
    "@media (min-width:1100px)": {
      fontSize: "6.4rem",
      lineHeight: "5rem",
      marginBottom: theme.spacing(3),
      "& span": {
        fontSize: "3rem",
        lineHeight: "3.2rem"
      }
    }
  },
  subTitle: {
    //@ts-ignore
    color: theme.palette.background.ffGreen,
    marginTop: theme.spacing(2),
    letterSpacing: "-2px",
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    },
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {}
  },
  detailsIcon: {
    color: "#eee",
    marginRight: theme.spacing(0.5),
    textShadow: "-1px 1px 1px #171717"
  },
  details: {
    textShadow: "-1px 1px 1px #171717",
    color: "#eee",
    fontWeight: 500,
    lineHeight: "1.5rem"
  },
  detailsSeparator: {
    lineHeight: "1.5rem",
    color: "#bbb",
    margin: theme.spacing(0, 1)
  },
  ctaButtonContainer: {
    width: "100%",
    textAlign: "center",
    "@media (min-width:600px)": {
      width: "300px"
    },
    "@media (min-width:960px)": {
      width: "300px"
    }
  },
  button: {
    marginTop: theme.spacing(4),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "300px"
    },
    "@media (min-width:960px)": {
      width: "300px"
    }
  },
  buttonSubText: {
    fontSize: "0.9rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    "@media (min-width:600px)": {
      width: "300px",
      textAlign: "center",
      marginLeft: 0
    },
    "@media (min-width:960px)": {}
  },
  noUnderline: {
    textDecoration: "none"
  },
  channelGraphic: {
    borderRadius: "50%",
    marginBottom: theme.spacing(2),
    maxWidth: "120px"
  },
  seriesLabel: {
    background: "rgba(0,0,0,0.9)",
    color: theme.palette.common.white,
    textTransform: "uppercase",
    width: "fit-content",
    padding: theme.spacing(0.8, 2),
    fontWeight: 600,
    marginBottom: theme.spacing(1.5)
  }
}));

const Top = ({
  title,
  channelImg,
  backgroundImg,
  year,
  seasonCount,
  episodeCount,
  backgroundPositionSm,
  backgroundPositionXl
}: {
  title: string | undefined;
  channelImg?: string | undefined;
  backgroundImg?: string | undefined;
  year?: number | undefined;
  seasonCount?: number | undefined;
  episodeCount?: number | undefined;
  backgroundPositionSm?: string | undefined;
  backgroundPositionXl?: string | undefined;
}) => {
  const styles = {
    backgroundPositionSm: backgroundPositionSm,
    backgroundPositionXl: backgroundPositionXl
  };

  const classes = useStyles(styles);
  const { overview } = useContext(OverviewContext);

  return (
    <>
      <div
        className={classes.background}
        style={{ backgroundImage: "url(" + backgroundImg + "" }}
      >
        <Container maxWidth="lg" fixed className={classes.container}>
          <div className={classes.gradient}>
            <Grid container>
              <Grid item xs={12} md={12} className={classes.centerBox}>
                <Box>
                  <Box>
                    <img
                      src={channelImg}
                      className={classes.channelGraphic}
                      alt=""
                    />
                    <Typography className={classes.seriesLabel}>
                      Original Series
                    </Typography>
                    <Typography variant="h1" className={classes.title}>
                      {title}
                    </Typography>
                    <Box display="flex">
                      <Box display="flex">
                        <TodayIcon className={classes.detailsIcon} />
                        <Typography variant="body1" className={classes.details}>
                          {year}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="body1"
                          className={classes.detailsSeparator}
                        >
                          |
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1" className={classes.details}>
                          {seasonCount} Season
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="body1"
                          className={classes.detailsSeparator}
                        >
                          |
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1" className={classes.details}>
                          {episodeCount} Episodes
                        </Typography>
                      </Box>
                    </Box>
                    {/* 
                      Doors Open & Trials Open - show Free Trial
                      Doors Closed & Trials Open - show Free Trial
                      Doors Open & Trials Closed - show Join Now
                      Doors Closed & Trials Closed - show Free Trial (notify me status)
                    */}
                    {overview && (
                      <>
                        {overview.doors?.trialsOpen && overview.doors?.open && (
                          <>
                            <Box className={classes.ctaButtonContainer}>
                              <TryButton
                                label="Watch on Free Trial"
                                size="large"
                                className={classes.button}
                              />
                              <Typography
                                variant="body1"
                                className={classes.buttonSubText}
                              >
                                No Payment Required for Free Trial
                              </Typography>
                            </Box>
                          </>
                        )}
                        {overview.doors?.trialsOpen && !overview.doors?.open && (
                          <>
                            <Box className={classes.ctaButtonContainer}>
                              <TryButton
                                label="Watch on Free Trial"
                                size="large"
                                className={classes.button}
                              />
                              <Typography
                                variant="body1"
                                className={classes.buttonSubText}
                              >
                                No Payment Required for Free Trial
                              </Typography>
                            </Box>
                          </>
                        )}
                        {overview.doors?.open && !overview.doors?.trialsOpen && (
                          <>
                            <Box className={classes.ctaButtonContainer}>
                              <Link
                                to="/buy"
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  disableRipple
                                  size="large"
                                  className={classes.button}
                                >
                                  Join Now
                                </Button>
                              </Link>
                              <Typography
                                variant="body1"
                                className={classes.buttonSubText}
                              >
                                <b>Don&apos;t Miss Out!</b>
                                <br />
                                Last chance to join this season!
                              </Typography>
                            </Box>
                          </>
                        )}
                        {!overview.doors?.trialsOpen && !overview.doors?.open && (
                          <>
                            <Box className={classes.ctaButtonContainer}>
                              <TryButton
                                label="Take Free Trial"
                                size="large"
                                className={classes.button}
                              />
                              <Typography
                                variant="body1"
                                className={classes.buttonSubText}
                              >
                                No Payment Required for Free Trial
                              </Typography>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Top;
