import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Button, Paper, Grid } from "@material-ui/core";
import { AccountContext } from "../store/AccountContext";
import Footer from "./Footer/Footer";
import HeaderInternal from "./Header/HeaderInternal";
import Header from "./Header/Header";
import { Helmet } from "react-helmet";
import classnames from "classnames";

const useStyles = makeStyles(theme => ({
  mainTitle: {
    color: theme.palette.common.white,
    fontSize: "2.6rem",
    lineHeight: "2.8rem",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    "& span": {
      color: theme.palette.background.ffGreen,
      fontWeight: 600
    }
  },
  subTitle: {
    color: theme.palette.background.ffGreen,
    fontSize: "1.6rem",
    lineHeight: "1.7rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    "& span": {
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    }
  },
  bottomTitle: {
    color: theme.palette.background.ffGreen,
    fontSize: "1.2rem",
    lineHeight: "1.3rem",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    }
  },
  sponsorWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 2)
  },
  sponsorImage: {
    alignSelf: "center",
    maxWidth: "90%",
    height: "auto",
    margin: theme.spacing(2, 0)
  },
  locationImage: {
    width: "100%",
    height: "auto",
    marginBottom: theme.spacing(1)
  },
  bodyText: {
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    "& span": {
      color: theme.palette.background.ffGreen,
      fontWeight: 600
    }
  },
  warningText: {
    fontWeight: 400,
    // Subtle red color
    color: "rgba(255,0,0,0.85)"
  },
  headerContainer: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "auto",
    height: "auto",
    minHeight: "350px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.7)",
    verticalAlign: "middle",
    textAlign: "center",
    alignItems: "center"
  },
  pageSection: {
    padding: theme.spacing(2)
  },
  background: {
    backgroundColor: "#292929"
  },
  backgroundAlt: {
    backgroundColor: theme.palette.background.ffNavy
  },
  header: {
    color: theme.palette.background.lightgray,
    fontSize: "2.8rem",
    lineHeight: "2.6rem",
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(4),
      fontSize: "2.8rem",
      lineHeight: "3rem"
    },
    "@media (min-width:960px)": {
      fontSize: "4.2rem",
      lineHeight: "4.4rem"
    }
  },
  subHeader: {
    color: theme.palette.common.white,
    fontSize: "2.2rem",
    lineHeight: "2.3rem",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(0.5),
      fontSize: "3.2rem",
      lineHeight: "3.3rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3.2rem",
      lineHeight: "3.4rem"
    },
    "& span": {
      color: theme.palette.background.ffGreen,
      fontWeight: 600
    }
  },
  shareDivider: {
    height: "2px",
    backgroundColor: theme.palette.background.ffGreen,
    width: "60%",
    marginRight: "10%",
    marginLeft: "10%",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      textAlign: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  },
  shareText: {
    fontSize: "1rem",
    lineHeight: "1.1rem",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: theme.palette.common.darkgray,
    "& span": {
      fontWeight: 500
    },
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(0),
      fontSize: "0.9rem",
      lineHeight: "1.1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    }
  },
  shareButton: {
    marginRight: theme.spacing(1),
    width: 34,
    "@media (min-width:600px)": {
      width: 34
    }
  },
  simpleLink: {
    color: theme.palette.background.ffGreen
  },
  link: {
    textDecoration: "none",
    alignSelf: "flex-end",
    justifySelf: "flex-end",
    margin: 0
  },
  button: {
    marginTop: theme.spacing(1),
    textDecoration: "none",
    fontSize: "0.75rem",
    padding: theme.spacing(2, 4),
    "@media (min-width:600px)": {
      fontSize: "1rem",
      padding: theme.spacing(2, 6)
    }
  },
  infoSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "space-evenly",
    padding: theme.spacing(2),
    borderRadius: "8px",
    backgroundColor: "#292929",
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)"
  },
  infoSectionAlt: {
    backgroundColor: theme.palette.background.ffNavy
  },
  infoSectionHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  infoSectionBody: {
    flex: 1,
    fontSize: "1.1rem",
    "& strong": {
      fontWeight: 500,
      color: theme.palette.background.ffGreen
    }
  },
  infoSectionIcon: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.background.ffGreen,
    borderRadius: "50%",
    fontSize: "1.8rem",
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
  infoSectionContainer: {
    display: "grid",
    "column-gap": "0.75rem",
    "row-gap": "0.75rem",

    "grid-template-columns": "repeat(2, auto)",

    "@media (min-width:900px)": {
      "grid-template-columns": "repeat(12, 1fr)",
      "grid-template-rows": "repeat(2, auto)"
    }
  },
  sponsorInfoSectionContainer: {
    "grid-template-columns": "repeat(1, auto)",

    "@media (min-width:600px)": {
      "grid-template-columns": "repeat(6, 1fr)"
    },
    "@media (min-width:900px)": {
      "grid-template-columns": "repeat(3, 1fr)"
    }
  },
  sponsorInfoSectionRedrock: {
    gridColumn: "1 / 2",
    gridRow: "1 / 2",

    "@media (min-width:600px)": {
      gridColumn: "1 / 3",
      gridRow: "1 / 2"
    },
    "@media (min-width:900px)": {
      gridColumn: "1 / 2",
      gridRow: "1 / 2"
    }
  },
  sponsorInfoSectionMoore: {
    gridColumn: "1 / 2",
    gridRow: "2 / 3",

    "@media (min-width:600px)": {
      gridColumn: "3 / 5",
      gridRow: "1 / 2"
    },
    "@media (min-width:900px)": {
      gridColumn: "2 / 3",
      gridRow: "1 / 2"
    }
  },
  sponsorInfoSectionCouncil: {
    gridColumn: "1 / 2",
    gridRow: "3 / 4",

    "@media (min-width:600px)": {
      gridColumn: "5 / 7",
      gridRow: "1 / 2"
    },
    "@media (min-width:900px)": {
      gridColumn: "3 / 4",
      gridRow: "1 / 2"
    }
  },
  infoSectionMap: {
    "grid-area": "2 / 1 / 3 / 3",
    "@media (min-width:900px)": {
      "grid-area": "1 / 1 / 4 / 7"
    }
  },
  infoSectionHours: {
    "grid-area": "3 / 1 / 4 / 3",
    "@media (min-width:900px)": {
      "grid-area": "1 / 7 / 2 / 10"
    }
  },
  infoSectionParking: {
    "grid-area": "4 / 1 / 5 / 3",
    "@media (min-width:900px)": {
      "grid-area": "1 / 10 / 2 / 13"
    }
  },
  infoSectionBins: {
    "grid-area": "5 / 1 / 6 / 3",
    "@media (min-width:900px)": {
      "grid-area": "2 / 7 / 3 / 13"
    }
  },
  infoSectionAccessibility: {
    "grid-area": "12 / 1 / 13 / 3",
    "@media (min-width:900px)": {
      "grid-area": "3 / 7 / 4 / 13"
    }
  },
  infoSectionSeating: {
    "grid-area": "7 / 1 / 8 / 3",
    "@media (min-width:900px)": {
      "grid-area": "4 / 1 / 5 / 7"
    }
  },
  infoSectionViewing: {
    "grid-area": "6 / 1 / 7 / 3",
    "@media (min-width:900px)": {
      "grid-area": "4 / 7 / 5 / 13"
    }
  },
  infoSectionFood: {
    "grid-area": "9 / 1 / 10 / 3",
    "@media (min-width:900px)": {
      "grid-area": "5 / 1 / 6 / 5"
    }
  },
  infoSectionShop: {
    "grid-area": "10 / 1 / 11 / 3",
    "@media (min-width:900px)": {
      "grid-area": "5 / 5 / 6 / 9"
    }
  },
  infoSectionKids: {
    "grid-area": "11 / 1 / 12 / 3",
    "@media (min-width:900px)": {
      "grid-area": "5 / 9 / 6 / 13"
    }
  },
  infoSectionWeather: {
    "grid-area": "8 / 1 / 9 / 3",
    "@media (min-width:900px)": {
      "grid-area": "6 / 1 / 8 / 5"
    }
  },
  infoSectionFirstAid: {
    "grid-area": "13 / 1 / 14 / 3",
    "@media (min-width:900px)": {
      "grid-area": "6 / 5 / 7 / 9"
    }
  },
  infoSectionSmokingAlcohol: {
    "grid-area": "13 / 1 / 14 / 3",
    "@media (min-width:900px)": {
      "grid-area": "7 / 5 / 8 / 9"
    }
  },
  infoSectionDogs: {
    "grid-area": "14 / 1 / 15 / 3",
    "@media (min-width:900px)": {
      "grid-area": "6 / 9 / 8 / 13"
    }
  },
  infoSectionHotels: {
    "grid-area": "15 / 1 / 16 / 3",
    "@media (min-width:900px)": {
      "grid-area": "8 / 1 / 10 / 9"
    }
  },
  infoSectionTouristInfo: {
    "grid-area": "16 / 1 / 17 / 3",
    "@media (min-width:900px)": {
      "grid-area": "8 / 9 / 9 / 13"
    }
  },
  infoSectionEventVideo: {
    "grid-area": "20 / 1 / 21 / 3",
    "@media (min-width:900px)": {
      "grid-area": "9 / 9 / 10 / 13"
    }
  },
  infoSectionMedia: {
    "grid-area": "18 / 1 / 19 / 3",
    "@media (min-width:900px)": {
      "grid-area": "10 / 1 / 11 / 13"
    }
  },
  infoSectionMeetAndGreet: {
    "grid-area": "19 / 1 / 20 / 3",
    "@media (min-width:900px)": {
      "grid-area": "11 / 1 / 12 / 7"
    }
  },
  infoSectionAirAmbulance: {
    "grid-area": "17 / 1 / 18 / 3",
    "@media (min-width:900px)": {
      "grid-area": "11 / 7 / 12 / 13"
    }
  },
  bottomPlayerWrapper: {
    margin: theme.spacing(2, 0),
    position: "relative",
    paddingBottom: "56.25%",
    width: "100%",
    height: "0"
  },
  bottomVideo: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%"
  }
}));

const OnThePull = () => {
  const { loggedIn } = useContext(AccountContext);

  const classes = useStyles();

  return (
    <div>
      <>
        {loggedIn && <HeaderInternal />}
        {!loggedIn && <Header />}
      </>
      <div className={classes.background}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FarmFLiX.tv | #OnThePull24 Event Information</title>
          <meta name="description" content="#OnThePull24 Event Information" />
        </Helmet>
        <section name="section1">
          <Paper
            className={classnames(
              classes.headerContainer,
              " banner-bg",
              classes.pageSection
            )}
          >
            <Container
              maxWidth="md"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem"
              }}
            >
              <Typography variant="h1" className={classes.mainTitle}>
                FarmFliX #OnThePull24
              </Typography>

              <Typography variant="h2" className={classes.subTitle}>
                Saturday, 6 July 2024
              </Typography>
            </Container>

            <Container
              maxWidth="md"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <a href="https://onthepull.co/" className={classes.link}>
                <Button className={classes.button}>Event Information</Button>
              </a>
            </Container>

            <Typography
              variant="body1"
              className={classnames(classes.bodyText, classes.warningText)}
            >
              This is a ticket only event.{" "}
              <strong>Do not arrive without a ticket</strong> as you will be
              turned away.
            </Typography>
          </Paper>
        </section>

        <section name="section3">
          <div className={classnames(classes.background, classes.pageSection)}>
            <Container
              maxWidth="md"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.bottomPlayerWrapper}
              >
                <iframe
                  title="#OnThePull24 Event Video"
                  src="https://www.youtube.com/embed/SufaI79E7cA"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                  allowFullScreen
                  className={classes.bottomVideo}
                ></iframe>
              </Grid>
            </Container>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default OnThePull;
