import React, { createContext, PropsWithChildren, useState } from "react";
import ShareModal from "./ShareModal";

type ShareContextType = {
  setDialogOpen: (open: boolean) => void;
};

export const ShareContext = createContext<ShareContextType>({
  setDialogOpen: () => {}
});

const ShareContainer = ({ children }: PropsWithChildren<{}>) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const context: ShareContextType = {
    setDialogOpen
  };

  return (
    <ShareContext.Provider value={context}>
      {children}
      {dialogOpen && <ShareModal />}
    </ShareContext.Provider>
  );
};

export default ShareContainer;
