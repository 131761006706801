import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { AccountContext, SendMethod } from "../../store/AccountContext";
import { Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Alert, AlertTitle } from "@material-ui/lab";
import PhoneInput from "react-phone-input-2";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import ReactCodeInput from "react-verification-code-input";
import { Box, Divider, makeStyles } from "@material-ui/core";
import {
  // WhatsappButton,
  SmsButton,
  PrimaryButton,
  SmsButtonSecondary
} from "../common/buttons";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    flex: 1
    // margin: theme.spacing(1.5, 0, 2)
  },
  bodyText: {
    color: theme.palette.common.white,
    marginBottom: "10px",
    textAlign: "center"
  },
  center: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  divider: {
    backgroundColor: "white",
    borderRadius: 5,
    height: 2,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  validationError: {
    marginBottom: theme.spacing(2)
  },
  backdrop: {
    zIndex: 100000000,
    color: "#fff"
  },
  forgotPassword: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontWeight: 400
  },
  button: {
    margin: 0
  },
  secButton: {
    margin: theme.spacing(1.5, 0, 2),
    borderColor: "#e9e9e9",
    color: "#e9e9e9",
    "@media (min-width:600px)": {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    },
    "&:hover": {
      borderColor: "#fff",
      color: "#fff"
    }
  },
  phoneInput: {
    width: "inherit !important"
  },
  formFieldSetLarge: {
    margin: theme.spacing(2, 0, 0.5, 0),
    "& label": {
      color: theme.palette.common.white
    },
    "& .react-tel-input .form-control": {
      backgroundColor: "#444",
      color: "#fff",
      borderColor: "#444 !important"
    },
    "& .react-tel-input .form-control+div:before": {
      color: "#fff !important",
      // @ts-ignore
      backgroundColor: theme.palette.background.ffNavy
    },
    "& .react-tel-input .arrow": {
      borderTop: "4px solid #fff !important"
    }
  },
  verificationCodeContainer: {
    width: "100% !important",
    fontWeight: 600,
    "& input": {
      height: "56px !important",
      width: "15.6% !important",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderTopLeftRadius: "0px !important",
      borderBottomLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
      borderBottom: 0,
      backgroundColor: "#444",
      margin: "0 0.5%",
      fontSize: "2rem",
      fontWeight: 500,
      color: "#fff",
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
      "&:focus": {
        border: "1px solid #99ca3c",
        caretColor: "#fff",
        "&:last-child": {
          borderRight: "1px solid #99ca3c"
        }
      },
      "&:last-child": {
        borderRight: "none"
      }
    }
  },
  submittedMobileNumber: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    textAlign: "center",
    fontSize: "1.4rem",
    color: theme.palette.common.white
  },
  secSubmit: {
    width: "100%",
    margin: theme.spacing(1.5, 0, 2),
    borderColor: "#e9e9e9",
    color: "#e9e9e9",
    "@media (min-width:600px)": {
      width: "auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    "&:hover": {
      borderColor: "#fff",
      color: "#fff"
    }
  },
  thirdHeader: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontSize: "1.6rem",
    textAlign: "center",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    }
  }
}));

const MobileSignIn = ({
  toggleLoginMethod
}: {
  toggleLoginMethod: () => void;
}) => {
  const {
    loginWithPhone,
    loginWithPhoneVerificationToken,
    loading,
    resetErrors,
    loginError,
    loggedIn,
    waitingOnVerificationCode
  } = useContext(AccountContext);
  const classes = useStyles();
  const [sendMethod, setSendMethod] = useState<"sms" | "whatsapp">("sms");
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>();
  const [disabledLogin, setDisabledLogin] = useState(true);
  const [verificationCode, setVerificationCode] = useState<string>();

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitSms = useCallback(async () => {
    if (!countryCode || !phoneNumber) return;

    setSendMethod("sms");
    await loginWithPhone(countryCode, phoneNumber, "sms");
  }, [loginWithPhone, phoneNumber, countryCode]);

  // const onSubmitWhatsapp = useCallback(async () => {
  //   if (!countryCode || !phoneNumber) return;

  //   setSendMethod("whatsapp");
  //   await loginWithPhone(countryCode, phoneNumber, "whatsapp");
  // }, [loginWithPhone, phoneNumber, countryCode]);

  const onChange = (value: string, { dialCode }: { dialCode: string }) => {
    setDisabledLogin(false);
    setPhoneNumber(value.substring(dialCode.length));
    setCountryCode(dialCode);
  };

  const handleVerficationCodeSubmit = async () => {
    if (!verificationCode) return;

    await loginWithPhoneVerificationToken(verificationCode);
  };

  const resendVerificationCode = (
    method: SendMethod
  ): MouseEventHandler => evt => {
    if (!countryCode || !phoneNumber) return;
    evt.preventDefault();
    loginWithPhone(countryCode, phoneNumber, sendMethod);
  };

  if (loggedIn) {
    return <Redirect to={"/videos"} />;
  }

  return (
    <>
      {!waitingOnVerificationCode && (
        <>
          <Typography
            component="p"
            variant="body2"
            className={classes.bodyText}
            style={{ marginBottom: "20px" }}
          >
            Log In with your Mobile Number.
            <br />
            <br />
            We&apos;ll send a <b>One Time Passcode</b> via SMS which you can use
            to log into your account.
          </Typography>
          <div className={classes.form}>
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={12} className={classes.formFieldSetLarge}>
                {loginError && (
                  <Alert
                    className={classes.validationError}
                    severity="error"
                    variant="filled"
                    style={{ marginBottom: "40px" }}
                  >
                    {loginError}
                  </Alert>
                )}
                <PhoneInput
                  inputClass={classes.phoneInput}
                  inputProps={{
                    name: "mobileNumber",
                    required: true
                  }}
                  country="gb"
                  placeholder="Mobile Number"
                  aria-label="Mobile Number"
                  enableTerritories={true}
                  countryCodeEditable={false}
                  preferredCountries={["gb", "ie", "us", "au", "nz"]}
                  excludeCountries={["je"]}
                  onChange={onChange}
                  onFocus={resetErrors}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {loading && (
                  <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="primary" />
                  </Backdrop>
                )}

                <Box flex={1} flexDirection="row">
                  <SmsButton
                    title="Log In with SMS"
                    onClick={onSubmitSms}
                    disabled={disabledLogin || loading}
                  />
                  {/* <WhatsappButton
                    title={"Log In with WhatsApp"}
                    onClick={onSubmitWhatsapp}
                    disabled={disabledLogin || loading}
                  /> */}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider className={classes.divider} title="or" />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                fullWidth
                onClick={toggleLoginMethod}
                size="medium"
                variant="outlined"
                className={classes.secButton}
              >
                Switch to Email
              </Button>
            </Grid>
          </div>
        </>
      )}
      {waitingOnVerificationCode && (
        <>
          <Typography
            component="h3"
            variant="h3"
            className={classes.thirdHeader}
          >
            Verify Mobile
          </Typography>
          <Typography
            variant="body2"
            className={classes.bodyText}
            align="center"
            style={{ textAlign: "center" }}
          >
            We need to make sure it&apos;s really you, so we&apos;ve sent a 6
            digit code to the following mobile number. Please enter it below.
          </Typography>
          <Typography variant="body1" className={classes.submittedMobileNumber}>
            +{countryCode}
            {phoneNumber}
          </Typography>
          <form className={classes.form}>
            <Grid container spacing={2}>
              {loginError && (
                <Alert
                  severity="error"
                  variant="filled"
                  style={{ marginBottom: "12px", width: "100%" }}
                >
                  <AlertTitle style={{ color: "#fff", marginTop: "-0" }}>
                    Invalid Verification Code
                  </AlertTitle>
                  Please re-enter code of request another with the &apos;Resend
                  Code&apos; button.
                </Alert>
              )}
              <Grid item xs={12} sm={12}>
                {/* @ts-ignore */}
                <ReactCodeInput
                  className={classes.verificationCodeContainer}
                  type="number"
                  fields={6}
                  value={verificationCode}
                  onComplete={(code: string) => setVerificationCode(code)}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12}>
                {loading && (
                  <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="primary" />
                  </Backdrop>
                )}
                <PrimaryButton
                  title="Verify"
                  onClick={handleVerficationCodeSubmit}
                  disabled={loading || !verificationCode}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={12}>
                {/* <WhatsappButton
                  title="Resend code with WhatsApp"
                  onClick={resendVerificationCode(
                    sendMethod === "sms" ? "whatsapp" : "sms"
                  )}
                /> */}
                <SmsButtonSecondary
                  title="Resend code with SMS"
                  onClick={resendVerificationCode("sms")}
                />
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
};

export default MobileSignIn;
