import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Divider
} from "@material-ui/core";
import { Link } from "react-router-dom";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(6, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0)
    }
  },

  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },

  accordionContainer: {
    marginTop: theme.spacing(3)
  },
  accordionEntry: {
    //@ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    borderRadius: "0 !important",
    padding: theme.spacing(0.5, 0),
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      padding: theme.spacing(1, 0)
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: theme.spacing(0, 1.5)
    }
  },
  accordionEntryTitle: {
    fontSize: "1.1rem",
    lineHeight: "1.2rem",
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem"
    }
  },
  accordionEntryText: {
    fontSize: "1rem",
    lineHeight: "1.2rem",
    "& a": {
      color: "#eee"
    }
  },
  accordionEntryExpand: {
    // @ts-ignore
    fill: theme.palette.background.ffGreen,
    fontSize: "2.2rem",
    padding: 0
  },
  membershipOptionWrapper: {
    "@media (min-width:600px)": {
      padding: "12px 20px"
    }
  },
  membershipOptionContainer: {
    textAlign: "center",
    padding: theme.spacing(3, 1.5),
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    "@media (min-width:600px)": {
      padding: theme.spacing(3, 4)
    }
  },
  membershipOptionTitle: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontSize: "1.5rem",
    marginBottom: theme.spacing(3)
  },
  membershipOptionPrimaryPrice: {
    fontSize: "2rem",
    color: theme.palette.common.white,
    fontWeight: 500,
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      fontSize: "2.6rem"
    },
    "& span": {
      fontSize: "0.9rem",
      fontWeight: "400"
    }
  },
  membershipOptionDescription: {
    margin: theme.spacing(2, 0)
  },
  smlDivider: {
    height: "2px",
    width: "30%",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffGreen,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  }
}));

const FAQs = () => {
  const classes = useStyles();

  const faqs = [
    {
      id: 1,
      title: "What is FarmFLiX?",
      body:
        "FarmFLiX is an online video platform dedicated to delivering relevant agricultural content. We bring the reality of the job to you."
    },
    {
      id: 3,
      title: "How much is FarmFLiX Membership?",
      body: (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                style={{ width: "100%" }}
                className={classes.accordionEntryText}
              >
                FarmFLiX offers two Membership options; Annual and Monthly.
                <br /> <br />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.membershipOptionContainer}>
                <Typography className={classes.membershipOptionTitle}>
                  Annual
                </Typography>
                <Typography className={classes.membershipOptionPrimaryPrice}>
                  &pound;60.00<span>/year</span>
                </Typography>
                <Divider className={classes.smlDivider} />
                <Typography
                  className={classes.membershipOptionDescription}
                  variant="body2"
                >
                  Our Annual Membership
                </Typography>
                <Typography style={{ margin: "12px 0" }} variant="body2">
                  or
                </Typography>
                <Typography
                  className={classes.membershipOptionDescription}
                  variant="body1"
                >
                  <b>&euro;70.00</b> / <b>&#36;85.00</b>
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.membershipOptionContainer}>
                <Typography className={classes.membershipOptionTitle}>
                  Monthly
                </Typography>
                <Typography className={classes.membershipOptionPrimaryPrice}>
                  &pound;6.99<span>/month</span>
                </Typography>
                <Divider className={classes.smlDivider} />
                <Typography
                  className={classes.membershipOptionDescription}
                  variant="body2"
                >
                  Our Monthly Membership
                </Typography>
                <Typography style={{ margin: "12px 0" }} variant="body2">
                  or
                </Typography>
                <Typography
                  className={classes.membershipOptionDescription}
                  variant="body1"
                >
                  <b>&euro;7.99</b> / <b>&#36;9.99</b>
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Fragment>
      )
    },
    {
      id: 7,
      title: "Can I buy FarmFLiX Membership as a gift?",
      body: (
        <Fragment>
          Yes, you can buy a 3 Month or 12 Month Digital Pass from the&nbsp;
          <a
            href="http://shop.farmflix.tv"
            rel="noopener noreferrer"
            target="_blank"
          >
            FarmFLiX Shop
          </a>
          . Digital Passes are sent instantly to the purchaser&apos;s email
          address on successful payment.
        </Fragment>
      ),
      open: 1
    },
    {
      id: 8,
      title: "How can I watch FarmFLiX on my TV?",
      body:
        "Install the FarmFLiX App, which supports Google Chromecast and Apple AirPlay.",
      open: 2
    },
    {
      id: 12,
      title: "How do I redeem a Giftcard?",
      body: (
        <Fragment>
          Simple. You can redeem a giftcard&nbsp;
          <Link to="/usemycode">here</Link>.
        </Fragment>
      ),
      open: 2
    },
    {
      id: 13,
      title: "Is FarmFLiX suitable for kids?",
      body:
        "Absolutely.  All our content is family-friendly, showcasing the events of real-world farming.",
      open: 2
    }
  ];

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="md" fixed>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.titleLarge}>
                FAQ&apos;s
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {faqs.map((q, i) => (
                <Accordion className={classes.accordionEntry} key={i}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        className={classes.accordionEntryExpand}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.accordionEntryTitle}>
                      {q.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.accordionEntryText}>
                      {q.body}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default FAQs;
