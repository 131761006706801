import PasswordValidator from "password-validator";

export const isRequired = value => (value ? undefined : "Required");
export const isRequiredWithMessage = message => value =>
  value ? undefined : message;
export const isEmailRequired = value =>
  value ? undefined : "Email Address required";

export const isEmail = value =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/.test(value)
    ? undefined
    : "Please enter a valid email";

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const schema = new PasswordValidator();
schema.is().min(8);

export const isPasswordValid = value =>
  schema.validate(value)
    ? undefined
    : "Password must be a minimum of 8 characters";

export const isPasswordRequired = value =>
  value ? undefined : "Password required";
