import axios, { AxiosError, AxiosRequestConfig, CancelToken } from "axios";
import {
  AUTH_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY
} from "../store/AccountContext";
import Notification from "./entities/Notification";
import UserProfile from "./entities/UserProfile";
import CatalogSearchRequest from "./requests/CatalogSearchRequest";
import FirebaseTokenRequest from "./requests/FirebaseTokenRequest";
import LoginByUsernamePasswordRequest from "./requests/LoginByUsernamePasswordRequest";
import ILogoutRequest from "./requests/LogoutRequest";
import NotificationsRequest from "./requests/NotificationsRequest";
import RefreshAccessTokenRequest from "./requests/RefreshAccessTokenRequest";
import SendLogsRequest from "./requests/SendLogsRequest";
import TicketRequest from "./requests/TicketRequest";
import VerifyPhoneRequest from "./requests/VerifyPhoneRequest";
import SignUpTrialRequest from "./requests/SignUpTrialRequest";
import CreatePaymentRequest from "./requests/CreatePaymentRequest";
import AuthTokenResponse from "./responses/AuthTokenResponse";
import NotificationsResponse from "./responses/NotificationsResponse";
import ProductsResponse from "./responses/ProductsResponse";
import { API_HOST } from "../env";
import EventEmitter from "eventemitter3";
import UpdateUserRequest from "./requests/UpdateUserRequest";
import UpdateCardRequest from "./requests/UpdateCardRequest";
import CancelMembershipRequest from "./requests/CancelMembershipRequest";
import UpdatePhoneRequest from "./requests/UpdatePhoneRequest";
import ConfirmPasswordResetRequest from "./requests/ConfirmPasswordResetRequest";
import GiftcardResponse from "./responses/GiftcardResponse";
import StoreLockedMemberNotifyRequest from "./requests/StoreLockedMemberNotifyRequest";
import UpdateUserNotificationPrefsRequest from "./requests/UpdateUserNotificationPrefsRequest";
import UserNotificationPrefResponse from "./responses/UserNotificationPrefResponse";
import ReferralLink, { RecentReferralLink } from "./entities/ReferralLink";
import { PaymentRequiredError, ValidationError } from "./errors";
import LoginByAutoLoginTokenRequest from "./requests/LoginByAutoLoginTokenRequest";
import {
  CreateApplication,
  CreateMediaRequest,
  CreateMediaResponse,
  GetMediaResponse,
  PublicCreateGiftcardApplicationRequest,
  SubmitResponse,
  Submit,
  UpdateDetails,
  UpdatePhoneDetails,
  UserProfileApplication,
  VerifyDetails,
  VideoSearchAutocompleteRequest,
  VideoSearchAutocompleteResponse,
  VideoSearchRequest,
  VideoSearchResponse,
  Video,
  ExternalOverviewResponse,
  CatalogOverviewResponse,
  DetailedVideoSearchResult,
  CatalogSearchResponse,
  MembershipDetails,
  LoginByPhoneRequest,
  UpdateMarketingOptOutPrefsRequest,
  MarketingOptOutPrefsResponse
} from "@booyaltd/core";
import Payment from "./entities/Product";

const ENDPOINT_CATALOG_OVERVIEW = "/catalog/overview";
const ENDPOINT_EXTERNAL_CATALOG_OVERVIEW = "/catalog/externaloverview";
const ENDPOINT_VIDEO_SOURCE_SLUG = "/catalog/videoslug";
const ENDPOINT_PREVIEW_VIDEO_SOURCE_SLUG = "/catalog/previewvideoslug";
const ENDPOINT_EXTERNAL_MASTER_VIDEO_SOURCE_SLUG =
  "/catalog/externalmastervideoslug";
const ENDPOINT_PREVIEW_SOURCE = "/catalog/preview";
const ENDPOINT_CATALOG_SEARCH = "/catalog/search";
const ENDPOINT_LOGIN_USERNAME = "/auth/login/username";
const ENDPOINT_LOGIN_PHONE = "/auth/login/phone";
const ENDPOINT_VERIFY_PHONE = "/auth/verify/phone";
const ENDPOINT_AUTH = "/auth";
const ENDPOINT_AUTH_AUTO_LOGIN = "/auth/auto-login-token/login";
const ENDPOINT_REFRESH_ACCESS_TOKEN = "/auth/refresh";
const ENDPOINT_LOGOUT = "/auth/logout";
const ENDPOINT_ME = "/profile/me";
const ENDPOINT_UPDATE_PHONE = "/profile/me/verification-code";
const ENDPOINT_LOGS = "/logs";
const ACCEPT_TERMS = "/profile/accept-terms";
const REGISTER_FIREBASE_TOKEN = "/notification/register";
const SUPPORT = "/support";
const ENDPOINT_NOTIFICATIONS = "/notification";
const ENDPOINT_SIGNUP_TRIAL = "/profiles";
const ENDPOINT_EMAIL_EXISTS = "/profiles/email-exists";
const ENDPOINT_PHONE_EXISTS = "/profiles/phone-exists";
const ENDPOINT_MOVE_EMAIL = "/profile/me/move-email";
const ENDPOINT_PRODUCTS = "/products";
const ENDPOINT_PAYMENTS = "/payments";
const ENDPOINT_RECENT_REFERRAL_LINKS = "/referral-links";
const ENDPOINT_REFERRAL_LINK = "/referral-link";
const ENDPOINT_MEMBERSHIP_DETAILS = "/member-statuses/overview/me";
const ENDPOINT_UPDATE_CARD = "/stripe/updateCard";
const ENDPOINT_PREVIEW_UPGRADE_MEMBERSHIP =
  "/stripe/upgradeSubscription/preview";
const ENDPOINT_UPGRADE_MEMBERSHIP = "/stripe/upgradeSubscription";
const ENDPOINT_CANCEL_MEMBERSHIP = "/stripe/cancelSubscription";
const ENDPOINT_RESUME_MEMBERSHIP = "/stripe/resumeSubscription";
const ENDPOINT_REMOVE_STRIPE_PAYMENT_METHOD = "/stripe/removeCard";
const ENDPOINT_GENERATE_PASSWORD_RESET = "/auth/password-reset/generate-code";
const ENDPOINT_VALIDATE_PASSWORD_RESET = "/auth/password-reset/validate-code";
const ENDPOINT_CONFIRM_PASSWORD_RESET = "/auth/password-reset";
const ENDPOINT_GIFTCARD_VALIDATE = "/giftcards/validate";
const ENDPOINT_APPLICATION = "/applications";
const ENDPOINT_APPLICATION_INTEREST = "/applications/interest";

const ENDPOINT_STORE_LOCKED_MEMBER_NOTIFICATION_PREFERENCE =
  "/profiles/locked-notification-preferences";
const ENDPOINT_USER_NOTIFICATION_PREFS = "/notification-prefs";
const ENDPOINT_MARKETING_OPT_OUT = "/marketing-opt-out";
const ENDPOINT_USER_MEDIA = "/user-media";
const ENDPOINT_USER_MEDIA_PUBLIC = "/user-media/public";
const ENDPOINT_GIFTCARD_APPLICATIONS_PUBLIC = "/giftcards/applications/public";
const ENDPOINT_GIFTCARD_APPLICATIONS_EVENT_PASS =
  "/giftcards/applications/eventpass";

const ENDPOINT_VIDEO_SEARCH = "/video/search";
const ENDPOINT_VIDEO_SEARCH_AUTOCOMPLETE = "/video/search/autocomplete";
const ENDPOINT_VIDEO_FAVOURITE = "/video-favourite";

export const API_EVENT_ERROR = "error";
export const API_EVENT_UNAUTHORIZED = "unauthorized";
export const API_EVENT_AUTH_REFRESH = "auth-refresh";

export const apiEventEmitter = new EventEmitter();

axios.interceptors.request.use(
  async config => {
    const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error instanceof axios.Cancel) {
      return;
    }

    apiEventEmitter.emit(API_EVENT_ERROR, error);
    console.error(error);
    console.log(
      "Api Error",
      error.request?.responseURL,
      error.response?.status
    );

    if (error && error.response && error.response?.status === 401) {
      const isAuthRequest =
        error.request.responseURL &&
        error.request.responseURL.includes(ENDPOINT_AUTH);
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
      const requestConfig = error.config;
      const canRetry = !isAuthRequest && refreshToken && requestConfig;

      // Remove existing tokens from storage
      localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);

      if (canRetry) {
        try {
          const {
            authToken,
            refreshToken: updatedRefreshToken
          } = await refreshAccessToken({
            refreshToken
          } as RefreshAccessTokenRequest);
          localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);
          localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, updatedRefreshToken);

          apiEventEmitter.emit(API_EVENT_AUTH_REFRESH, {
            authToken,
            refreshToken: updatedRefreshToken
          });

          return axios.request(requestConfig);
        } catch (e) {
          apiEventEmitter.emit(API_EVENT_UNAUTHORIZED, e);
          return Promise.reject(e);
        }
      }

      localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
      apiEventEmitter.emit(API_EVENT_UNAUTHORIZED);
    }

    if (error.response.status === 400) {
      if (error.response.data) {
        return Promise.reject(new ValidationError(error.response.data));
      }

      return Promise.reject(new ValidationError("Validation Error"));
    }

    if (error.response.status === 402) {
      return Promise.reject(new PaymentRequiredError(error.response.data));
    }

    console.error(
      "Unhandled API Error",
      error.response.status,
      error.response.data
    );
    return Promise.reject(
      error.response.status !== 500 ? error.response.data : error.response.data
    );
  }
);

const getRequest = <T>(
  endpoint: string,
  params: AxiosRequestConfig["params"] = undefined,
  configOverrides: AxiosRequestConfig = {}
) => {
  const config = configOverrides;
  if (params) {
    config.params = params;
  }

  return axios.get<T>(`${API_HOST}${endpoint}`, config);
};

const postRequest = <T>(endpoint: string, data?: any) =>
  axios.post<T>(`${API_HOST}${endpoint}`, data);

const putRequest = <T>(endpoint: string, data?: any) =>
  axios.put<T>(`${API_HOST}${endpoint}`, data);

const deleteRequest = <T>(endpoint: string) =>
  axios.delete<T>(`${API_HOST}${endpoint}`);

export const getExternalCatalogOverview = () =>
  getRequest<ExternalOverviewResponse>(ENDPOINT_EXTERNAL_CATALOG_OVERVIEW).then(
    r => r.data
  );

export const getCatalogOverview = () =>
  getRequest<CatalogOverviewResponse>(ENDPOINT_CATALOG_OVERVIEW).then(
    r => r.data
  );

export const getVideoSource = (id: string) =>
  getRequest<DetailedVideoSearchResult>(
    `${ENDPOINT_VIDEO_SOURCE_SLUG}/${id}`
  ).then(r => r.data);

export const getExternalMasterVideo = (id: string) =>
  getRequest<DetailedVideoSearchResult>(
    `${ENDPOINT_EXTERNAL_MASTER_VIDEO_SOURCE_SLUG}/${id}`
  ).then(r => r.data);

export const getPreviewVideo = (id: string) =>
  getRequest<DetailedVideoSearchResult>(
    `${ENDPOINT_PREVIEW_VIDEO_SOURCE_SLUG}/${id}`
  ).then(r => r.data);

export const getPreviewSource = (id: string) =>
  getRequest<DetailedVideoSearchResult>(
    `${ENDPOINT_PREVIEW_SOURCE}/${id}`
  ).then(r => r.data);

export const getCatalogSearch = (search: CatalogSearchRequest) =>
  getRequest<CatalogSearchResponse>(ENDPOINT_CATALOG_SEARCH, search).then(
    r => r.data
  );

export const loginWithUsernamePassword = (
  request: LoginByUsernamePasswordRequest
) =>
  postRequest<AuthTokenResponse>(ENDPOINT_LOGIN_USERNAME, request).then(
    r => r.data
  );

export const loginWithAutoLoginToken = (
  request: LoginByAutoLoginTokenRequest
) =>
  postRequest<AuthTokenResponse>(ENDPOINT_AUTH_AUTO_LOGIN, request).then(
    r => r.data
  );

export const loginWithPhone = (request: LoginByPhoneRequest) =>
  postRequest(ENDPOINT_LOGIN_PHONE, request).then(
    r => r.status >= 200 && r.status < 300
  );

export const verifyPhone = (request: VerifyPhoneRequest) =>
  postRequest<AuthTokenResponse>(ENDPOINT_VERIFY_PHONE, request).then(
    r => r.data
  );

export const refreshAccessToken = (request: RefreshAccessTokenRequest) =>
  postRequest<AuthTokenResponse>(ENDPOINT_REFRESH_ACCESS_TOKEN, request).then(
    r => r.data
  );

export const getMe = (): Promise<UserProfile> =>
  getRequest<UserProfile>(ENDPOINT_ME).then(r => r.data);

export const logout = (request: ILogoutRequest) =>
  postRequest<AuthTokenResponse>(ENDPOINT_LOGOUT, request).then(r => r.data);

export const sendLogs = (request: SendLogsRequest) =>
  postRequest<boolean>(ENDPOINT_LOGS, request)
    .then(r => r.status === 200)
    .catch(() => false);

export const acceptTerms = () =>
  postRequest<boolean>(ACCEPT_TERMS)
    .then(r => r.status === 200)
    .catch(() => false);

export const updateMe = (request: UpdateUserRequest): Promise<UserProfile> =>
  putRequest<UserProfile>(ENDPOINT_ME, request).then(r => r.data);

export const moveEmail = (
  email: string,
  verificationCode?: string
): Promise<{ moved: boolean; codeSent: boolean }> =>
  postRequest<{ moved: boolean; codeSent: boolean }>(ENDPOINT_MOVE_EMAIL, {
    email,
    verificationCode
  }).then(r => r.data);

export const getCode = (request: UpdatePhoneRequest): Promise<boolean> =>
  postRequest(ENDPOINT_UPDATE_PHONE, request)
    .then(r => r.status === 200)
    .catch(e => {
      if (e instanceof ValidationError) throw e;

      throw new Error("Error... please try again.");
    });

export const sendFirebaseToken = (request: FirebaseTokenRequest) =>
  postRequest<boolean>(REGISTER_FIREBASE_TOKEN, request)
    .then(r => r.status === 200)
    .catch(() => false);

export const sendSupportTicket = (request: TicketRequest) =>
  postRequest<boolean>(SUPPORT, request)
    .then(r => r.status === 200)
    .catch(() => false);

export const getNotificationsInbox = (request: NotificationsRequest) =>
  getRequest<NotificationsResponse>(ENDPOINT_NOTIFICATIONS, request).then(
    r =>
      ({
        notifications: r.data.notifications.map((i: Notification) => ({
          ...i,
          activeDate: new Date(i.activeDate),
          expiryDate: new Date(i.expiryDate)
        }))
      } as NotificationsResponse)
  );

export const register = (request: SignUpTrialRequest) =>
  postRequest(ENDPOINT_SIGNUP_TRIAL, request).then(r => r.status === 200);

export const getProducts = () =>
  getRequest<ProductsResponse>(ENDPOINT_PRODUCTS).then(r => r.data);

export const createPayment = (
  request: CreatePaymentRequest
): Promise<{ payment: Payment }> =>
  postRequest<{ payment: Payment }>(ENDPOINT_PAYMENTS, request).then(
    r => r.data
  );

export const getMembershipDetails = (): Promise<MembershipDetails> =>
  getRequest<MembershipDetails>(ENDPOINT_MEMBERSHIP_DETAILS).then(r => r.data);

export type UpdateStripeCardResponse =
  | { success: false; error: string }
  | { success: true; authRequired: false }
  | { success: true; authRequired: true; clientSecret: string };

export const updateStripeCard = (
  paymentMethod: UpdateCardRequest
): Promise<UpdateStripeCardResponse> =>
  postRequest<UpdateStripeCardResponse>(
    ENDPOINT_UPDATE_CARD,
    paymentMethod
  ).then(r => {
    if (r.status >= 200 && r.status < 300) {
      return r.data;
    }

    return {
      success: false,
      error: "Unknown error updating card",
      requiresAuth: false
    };
  });

export const cancelMembership = (id: CancelMembershipRequest) =>
  postRequest(ENDPOINT_CANCEL_MEMBERSHIP, id).then(r => r.status === 200);

export const resumeMembership = (id: CancelMembershipRequest) =>
  postRequest(ENDPOINT_RESUME_MEMBERSHIP, id).then(r => r.status === 200);

export const retrieveUpgradePreview = (id: CancelMembershipRequest) =>
  postRequest(ENDPOINT_PREVIEW_UPGRADE_MEMBERSHIP, id).then(r => r.data);

export const upgradeMembership = (id: CancelMembershipRequest) =>
  postRequest(ENDPOINT_UPGRADE_MEMBERSHIP, id).then(r => r.status === 200);

export const removeSavedPaymentMethod = (id: CancelMembershipRequest) =>
  postRequest(ENDPOINT_REMOVE_STRIPE_PAYMENT_METHOD, id).then(
    r => r.status === 200
  );

export const generatePasswordReset = (email: string): Promise<void> =>
  postRequest(ENDPOINT_GENERATE_PASSWORD_RESET, { email })
    .then(() => {}) // Pass or fail, we dont want the user to know if the API fails here for security reasons, dont show user an email does / doesn't exist
    .catch(() => {});

export const validatePasswordResetCode = (
  email: string,
  code: string
): Promise<boolean> =>
  postRequest(ENDPOINT_VALIDATE_PASSWORD_RESET, { email, code })
    .then(r => r.status >= 200 && r.status < 300)
    .catch(() => false);

export const confirmPasswordReset = (
  req: ConfirmPasswordResetRequest
): Promise<boolean> =>
  postRequest(ENDPOINT_CONFIRM_PASSWORD_RESET, req).then(r => r.status === 200);

export const validateGiftcardCode = (code: string): Promise<GiftcardResponse> =>
  getRequest<GiftcardResponse>(
    `${ENDPOINT_GIFTCARD_VALIDATE}?code=${encodeURIComponent(code)}`
  )
    .then(r => r.data)
    .catch(e => {
      if (e instanceof ValidationError) throw e;

      throw new Error("that Giftcard code is not valid, please try again.");
    });

export const storeLockedMemberNotifyPreferences = (
  request: StoreLockedMemberNotifyRequest
) =>
  postRequest(
    ENDPOINT_STORE_LOCKED_MEMBER_NOTIFICATION_PREFERENCE,
    request
  ).then(r => r.status === 200);

const handleRequestError = (
  e: string | AxiosError | ValidationError<any> | Error
) => {
  console.error(e);
  throw e;
};

export const createApplication = (request: CreateApplication) =>
  postRequest<UserProfileApplication>(ENDPOINT_APPLICATION, request).then(
    r => r.data
  );

export const updateApplicationPhoneDetails = (
  id: string,
  request: UpdatePhoneDetails
) =>
  postRequest<UserProfileApplication>(
    `${ENDPOINT_APPLICATION}/${encodeURIComponent(id)}/phone`,
    request
  )
    .then(r => r.data)
    .catch(handleRequestError);

export const verifyApplicationPhoneDetails = (
  id: string,
  request: VerifyDetails
): Promise<UserProfileApplication | undefined> =>
  postRequest<UserProfileApplication>(
    `${ENDPOINT_APPLICATION}/${encodeURIComponent(id)}/phone/verify`,
    request
  )
    .then(r => r.data)
    .catch(handleRequestError);

export const updateApplicationEmailDetails = (
  id: string,
  request: UpdateDetails
) =>
  postRequest<UserProfileApplication>(
    `${ENDPOINT_APPLICATION}/${encodeURIComponent(id)}/details`,
    request
  )
    .then(r => r.data)
    .catch(handleRequestError);

export const verifyApplicationEmailDetails = (
  id: string,
  request: VerifyDetails
): Promise<UserProfileApplication> =>
  postRequest<UserProfileApplication>(
    `${ENDPOINT_APPLICATION}/${encodeURIComponent(id)}/email/verify`,
    request
  )
    .then(r => r.data)
    .catch(handleRequestError);

export const submitApplication = (id: string, request: Submit) =>
  postRequest<SubmitResponse>(
    `${ENDPOINT_APPLICATION}/${encodeURIComponent(id)}/submit`,
    request
  )
    .then(r => r.data)
    .catch(handleRequestError);

export const getUserNotificationPreferences = () =>
  getRequest<UserNotificationPrefResponse>(
    ENDPOINT_USER_NOTIFICATION_PREFS
  ).then(r => r.data);

export const updateUserNotificationPreferences = (
  request: UpdateUserNotificationPrefsRequest
) =>
  putRequest<UserNotificationPrefResponse>(
    ENDPOINT_USER_NOTIFICATION_PREFS,
    request
  ).then(r => r.data);

export const getMarketingOptOutPrefs = (unsubscribeToken: string) =>
  getRequest<MarketingOptOutPrefsResponse>(
    `${ENDPOINT_MARKETING_OPT_OUT}/${unsubscribeToken}`
  ).then(r => r.data);

export const updateMarketingOptOutPrefs = (
  unsubscribeToken: string,
  request: UpdateMarketingOptOutPrefsRequest
) =>
  putRequest<MarketingOptOutPrefsResponse>(
    `${ENDPOINT_MARKETING_OPT_OUT}/${unsubscribeToken}`,
    request
  ).then(r => r.data);

export const getRecentReferralLinks = (): Promise<RecentReferralLink[]> =>
  getRequest<RecentReferralLink[]>(ENDPOINT_RECENT_REFERRAL_LINKS).then(
    r => r.data
  );

export const getReferralLink = (): Promise<ReferralLink> =>
  getRequest<ReferralLink>(ENDPOINT_REFERRAL_LINK).then(r => r.data);

export const validateReferralLink = (code: string): Promise<true | string> =>
  getRequest<true | undefined>(`${ENDPOINT_REFERRAL_LINK}/${code}`)
    .then(r => {
      if (!r) return "Unknown Error";

      if (r.status === 200) return true;

      if (r.status === 404) return "Referral link not found";

      if (r.status === 400 && r.data) return r.data;

      return "Unknown Error";
    })
    .catch(e => {
      console.error("Error validating referral link", e);

      if (typeof e === "string") return e;
      if (e instanceof ValidationError) {
        return e.toString();
      }

      return "Unknown Error";
    });

export const createInterestApplication = (
  req: Pick<UserProfileApplication, "countryCode" | "phoneNumber">
): Promise<any> => postRequest(ENDPOINT_APPLICATION_INTEREST, req);

export const phoneExists = (
  countryCode: string,
  phoneNumber: string
): Promise<boolean> =>
  postRequest(ENDPOINT_PHONE_EXISTS, { phoneNumber, countryCode })
    .then(r => r.status === 200)
    .catch(() => false);

export const emailExists = (email: string): Promise<boolean> =>
  postRequest(ENDPOINT_EMAIL_EXISTS, { email })
    .then(r => r.status === 200)
    .catch(() => false);

export const createMedia = (
  req: CreateMediaRequest
): Promise<CreateMediaResponse> =>
  postRequest<CreateMediaResponse>(ENDPOINT_USER_MEDIA, req).then(r => r.data);

export const getMediaItem = (id: string): Promise<GetMediaResponse> =>
  getRequest<GetMediaResponse>(`${ENDPOINT_USER_MEDIA}/${id}`).then(
    r => r.data
  );

export const createPublicMedia = (
  req: CreateMediaRequest
): Promise<CreateMediaResponse> =>
  postRequest<CreateMediaResponse>(ENDPOINT_USER_MEDIA_PUBLIC, req).then(
    r => r.data
  );

export const getPublicMediaItem = (id: string): Promise<GetMediaResponse> =>
  getRequest<GetMediaResponse>(`${ENDPOINT_USER_MEDIA_PUBLIC}/${id}`).then(
    r => r.data
  );

export const createGiftcardApplication = (
  req: PublicCreateGiftcardApplicationRequest
) =>
  postRequest(ENDPOINT_GIFTCARD_APPLICATIONS_PUBLIC, req).then(
    r => r.status === 200
  );

export const createGiftcardApplicationEventPass = (
  req: PublicCreateGiftcardApplicationRequest
) =>
  postRequest(ENDPOINT_GIFTCARD_APPLICATIONS_EVENT_PASS, req).then(
    r => r.status === 200
  );

export const searchVideos = (
  request: VideoSearchRequest
): Promise<VideoSearchResponse> =>
  getRequest<VideoSearchResponse>(ENDPOINT_VIDEO_SEARCH, request).then(
    r => r.data
  );

export const autocompleteVideoSearch = (
  request: VideoSearchAutocompleteRequest,
  cancelToken?: CancelToken
): Promise<VideoSearchAutocompleteResponse> =>
  getRequest<VideoSearchAutocompleteResponse>(
    ENDPOINT_VIDEO_SEARCH_AUTOCOMPLETE,
    request,
    { cancelToken }
  ).then(r =>
    r ? r.data : { results: [], phrase: request.phrase || "", total: 0 }
  );

export const favouriteVideo = (videoId: Video["id"]): Promise<any> =>
  postRequest(`${ENDPOINT_VIDEO_FAVOURITE}/${videoId}`);

export const deleteFavouriteVideo = (videoId: Video["id"]): Promise<any> =>
  deleteRequest(`${ENDPOINT_VIDEO_FAVOURITE}/${videoId}`);
