import React, { useContext } from "react";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import SixthStep from "./SixthStep";
import SeventhStep from "./SeventhStep";
import Confirm from "./Confirm";
import Success from "./Success";
import { MemberSurveyContext } from "../../store/MemberSurveyContext";

import { Stepper, Step, StepLabel, Hidden } from "@material-ui/core";

// Step titles
const labels = [
  "About You",
  "Comms",
  "Membership",
  "Your Thoughts",
  "The App",
  "Before You Go",
  "Confirm"
];
const handleSteps = (step: number) => {
  switch (step) {
    case 0:
      return <SecondStep />;
    case 1:
      return <ThirdStep />;
    case 2:
      return <FourthStep />;
    case 3:
      return <FifthStep />;
    case 4:
      return <SixthStep />;
    case 5:
      return <SeventhStep />;
    case 6:
      return <Confirm />;

    default:
      throw new Error("Unknown step");
  }
};

const StepForm = () => {
  const { activeStep } = useContext(MemberSurveyContext);

  return (
    <>
      {activeStep === labels.length ? (
        <Success />
      ) : (
        <>
          <Stepper activeStep={activeStep} alternativeLabel>
            {labels.map(label => (
              <Step key={label}>
                <StepLabel>
                  <Hidden smDown>{label}</Hidden>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {handleSteps(activeStep)}
        </>
      )}
    </>
  );
};

export default StepForm;
