import React, { createContext, PropsWithChildren, useState } from "react";
import TryBuyModal from "./TryBuyModal";

type TryBuyContextType = {
  setDialogOpen: (open: false | "notify" | "trial") => void;
};

export const TryBuyContext = createContext<TryBuyContextType>({
  setDialogOpen: () => {}
});

const TryBuyContainer = ({ children }: PropsWithChildren<{}>) => {
  const [dialogOpen, setDialogOpen] = useState<false | "notify" | "trial">();

  const context: TryBuyContextType = {
    setDialogOpen
  };

  return (
    <TryBuyContext.Provider value={context}>
      {children}
      {dialogOpen && <TryBuyModal type={dialogOpen} />}
    </TryBuyContext.Provider>
  );
};

export default TryBuyContainer;
