import React from "react";
import "./App.css";
import Store from "../store";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { MuiThemeProvider } from "@material-ui/core";
import muiTheme from "./Theme";
import { ToastProvider } from "react-toast-notifications";
import SearchContainer from "./Search/SearchContainer";
import ShareContainer from "./Share/ShareContainer";
import TryBuyContainer from "./Onboarding/TryBuyContainer";
import { ScrollToTopOnRouteChange } from "./common/ScrollToTopOnRouteChange";

export default function App() {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <ToastProvider autoDismiss={true} placement="top-right">
        <ShareContainer>
          <Router>
            <ScrollToTopOnRouteChange />
            <Store>
              <SearchContainer>
                <TryBuyContainer>
                  <Routes />
                </TryBuyContainer>
              </SearchContainer>
            </Store>
          </Router>
        </ShareContainer>
      </ToastProvider>
    </MuiThemeProvider>
  );
}
