import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  redeemGiftcardContainer: {
    width: "100%",
    padding: 0,
    margin: 0,
    "@media (min-width:600px)": {
      margin: "0 auto"
    },
    display: "flex",
    justifyContent: "center"
  },
  background: {
    position: "relative",
    backgroundImage: "none",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "scroll",
    width: "100%",
    height: "auto",
    display: "flex",
    flexGrow: 1,
    boxShadow: "none",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    "@media (min-width:600px)": {
      backgroundImage: `url(https://farmflix-images-test.s3-eu-west-1.amazonaws.com/usemycode_bg.jpg)`,
      boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.6)"
    }
  },
  noBackground: {
    position: "relative",
    backgroundImage: "none",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "scroll",
    width: "100%",
    height: "auto",
    display: "flex",
    flexGrow: 1,
    boxShadow: "none",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy
  },
  paper: {
    width: "100%",
    borderRadius: "0px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: "none",
    "@media (min-width:600px)": {
      backgroundColor: "rgba(23,23,23,0.9)",
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%"
  },
  secSubmit: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    borderColor: "#e9e9e9",
    color: "#e9e9e9",
    "&:hover": {
      borderColor: "#99ca3c",
      color: "#99ca3c"
    }
  },
  button: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(2)
    }
  },
  goBack: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    marginTop: "2px",
    float: "left",
    marginBottom: "10px",
    paddingLeft: "0",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "none",
      cursor: "pointer"
    }
  },
  formHeader: {
    color: theme.palette.common.white,
    fontSize: "2rem",
    lineHeight: "2rem",
    marginBottom: theme.spacing(4),
    textAlign: "center",
    fontWeight: 600,
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.2rem",
      textAlign: "center"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem",
      textAlign: "center"
    },
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    }
  },
  formTitle: {},
  formSubHeader: {
    color: theme.palette.common.white,
    fontSize: "1.6rem",
    fontWeight: 500,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "1.8rem",
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "1.8rem"
    }
  },
  stepper: {
    backgroundColor: "transparent",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "& .MuiStepLabel-label": {
      color: theme.palette.common.white
    },
    "& .MuiStepLabel-active": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "& .MuiStepLabel-completed": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "& .MuiStepConnector-line": {
      borderColor: "#777"
    },
    "& .MuiStepIcon-text": {
      // @ts-ignore
      fill: theme.palette.background.ffNavy
    }
  },
  stepperLabel: {
    fontSize: "0.9rem !important",
    color: "#fff !important"
  },
  stepContent: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  divider: {
    height: "1px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#444"
  },
  bodyText: {
    color: theme.palette.common.white,
    marginBottom: "10px",
    lineHeight: "1.2rem",
    "& a": {
      textDecoration: "underline",
      color: theme.palette.common.white
    }
  },
  bodySml: {
    fontSize: "0.7rem",
    marginTop: "10px",
    color: theme.palette.common.white,
    "& a": {
      textDecoration: "underline",
      color: theme.palette.common.white
    }
  },
  table: {},
  tableText: {
    color: theme.palette.common.white,
    borderBottom: "1px solid #444",
    "& a": {
      textDecoration: "underline",
      color: theme.palette.common.white
    }
  },
  errorAlert: {
    width: "100%",
    margin: "10px 0 0 0"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  giftcardCodeContainer: {
    width: "100% !important",
    fontWeight: 600,
    "& input": {
      textTransform: "uppercase",
      height: "46px !important",
      width: "7.3% !important",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderTopLeftRadius: "0px !important",
      borderBottomLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
      borderBottom: 0,
      backgroundColor: "#eee",
      margin: "0 0.5%",
      fontSize: "1rem",
      fontWeight: 500,
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
      "&:focus": {
        border: "1px solid #888",
        caretColor: "#888",
        "&:last-child": {
          borderRight: "1px solid #888"
        }
      },
      "&:last-child": {
        borderRight: "none"
      },
      "@media (min-width:600px)": {
        fontSize: "1.4rem",
        height: "50px !important"
      },
      "@media (min-width:960px)": {
        fontSize: "2rem",
        height: "56px !important"
      }
    }
  },
  phoneInput: {
    width: "inherit !important",
    ".flag-dropdown::before": {
      content: '"Whatss\'s your Mobile Number?" !important'
    }
  },
  phoneInputError: {
    width: "inherit !important",
    border: "1px solid red !important"
  },
  termsCheckbox: {
    display: "flex"
  },
  termsLabel: {
    color: theme.palette.common.white,
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: "0rem",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(0),
      fontSize: "0.8rem",
      lineHeight: "0rem"
    },
    "@media (min-width:960px)": {
      fontSize: "0.8rem",
      lineHeight: "0rem"
    },
    "& a": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    }
  },
  validationError: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: "#c53737",
    fontWeight: 500,
    textAlign: "center"
  },
  verificationCodeContainer: {
    width: "100% !important",
    fontWeight: 600,
    "& input": {
      height: "56px !important",
      width: "15.6% !important",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderTopLeftRadius: "0px !important",
      borderBottomLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
      borderBottom: 0,
      backgroundColor: "#444",
      margin: "0 0.5% 10px 0.5%",
      fontSize: "2rem",
      fontWeight: 500,
      color: "#fff",
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
      "&:focus": {
        border: "1px solid #99ca3c",
        caretColor: "#fff",
        "&:last-child": {
          borderRight: "1px solid #99ca3c"
        }
      },
      "&:last-child": {
        borderRight: "none"
      }
    },
    "@media (min-width:600px)": {
      width: "80% !important",
      margin: "0 10%"
    }
  },
  alertBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: "0.9rem",
    backgroundColor: "#fff",
    textAlign: "center",
    width: "100%",
    padding: 0,
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    "& .MuiAlert-message": {
      width: "100%"
    }
  },
  alertBoxTitle: {
    fontSize: "1.2rem",
    color: "#4caf50"
  },
  forgotPassword: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontWeight: 500
  },
  giftcardFooter: {
    marginBottom: theme.spacing(4)
  },
  successBox: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffGreen,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  successBoxHeader: {
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    fontSize: "1.6rem",
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  successLogo: {
    maxWidth: "140px",
    height: "auto"
  },
  resetCode: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 500,
    marginTop: theme.spacing(4),
    "&:hover": {
      cursor: "pointer"
    },
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2)
    }
  },
  accountTable: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  registerMobile: {
    marginTop: theme.spacing(2)
  },
  phoneHelperText: {
    color: "#d11124",
    margin: "0",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: "1.66",
    marginLeft: "0",
    fontFamily: "Roboto,'Helvetica Neue',Arial,sans-serif"
  },
  submittedMobileNumber: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    textAlign: "center",
    fontSize: "1.4rem",
    color: theme.palette.common.white
  },
  incorrectMobileNumber: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    textAlign: "center",
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 500
  },
  codeDurationString: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    fontSize: "1.1rem",
    color: theme.palette.common.white,
    textAlign: "center"
  },
  formField: {
    "& label": {
      color: theme.palette.common.white
    },
    "& input": {
      backgroundColor: "#444",
      color: "#fff",
      borderColor: "#444"
    }
  },
  formFieldSetLarge: {
    margin: theme.spacing(1, 0, 0, 0),
    "& label": {
      color: theme.palette.common.white
    },
    "& .react-tel-input .form-control": {
      backgroundColor: "#444",
      color: "#fff",
      borderColor: "#444 !important"
    },
    "& .react-tel-input .form-control+div:before": {
      color: "#fff !important",
      // @ts-ignore
      backgroundColor: theme.palette.background.ffNavy
    },
    "& .react-tel-input .arrow": {
      borderTop: "4px solid #fff !important"
    }
  },
  primaryColor: {
    color: theme.palette.primary.main
  },
  dividerWhite: {
    backgroundColor: "white",
    borderRadius: 5,
    height: 2,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export default useStyles;
