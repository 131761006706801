export const buildStatusString = (
  status: string | undefined
): {
  message: string;
  color: "info" | "success" | "warning" | "danger";
} | null => {
  switch (status) {
    case "Queued":
      return { message: "Queued", color: "info" };
    case "Go to gate":
      return { message: "Go to gate", color: "warning" };
    case "Standby in pit":
      return { message: "Standby in pit", color: "danger" };
    case "Waiting":
    case "Completed":
    default:
      return null;
  }
};
