import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useLocation, useParams } from "react-router-dom";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import SignUpForm from "./SignUp/SignUpForm";
import ErrorMessage from "../common/ErrorMessage";
import BackdropLoader from "../common/BackdropLoader";
import { validateReferralLink } from "../../api/client";
import { APPLICATION_SOURCE } from "@booyaltd/core";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default
      // margin: theme.spacing(2),
      // padding: theme.spacing(2)
    }
  })
);

const Referral = () => {
  const classes = useStyles();
  const { code } = useParams<{ code?: string }>();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setError(undefined);
    setValidated(false);

    if (!code) {
      setError("Invalid referral link");
      return;
    }

    setLoading(true);
    validateReferralLink(code)
      .then(result => {
        if (result === true) {
          setValidated(true);
        } else {
          setError(result);
        }
      })
      .finally(() => setLoading(false));
  }, [code]);

  return (
    <DefaultLayout showSignIn={false} showTry={false}>
      <Grid
        container
        direction="column"
        justify="center"
        // spacing={2}
        alignItems="center"
        className={classes.root}
      >
        {loading && <BackdropLoader />}
        {!loading && (
          <>
            {!validated && error !== undefined && (
              <ErrorMessage
                title="Invalid Referral Link"
                message={error}
                linkHome={true}
              />
            )}
            {validated && code && (
              <SignUpForm
                route={undefined}
                source={APPLICATION_SOURCE.REFERRAL}
                code={code}
                page={location.pathname}
              />
            )}
          </>
        )}
      </Grid>
    </DefaultLayout>
  );
};

export default Referral;
