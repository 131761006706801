import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid, Box } from "@material-ui/core";
import { Element } from "react-scroll";
import guaranteeGraphic from "./guarantee.png";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(6, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0)
    }
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.4rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "5.4rem",
      lineHeight: "4.8rem"
    }
  },
  bodyText: {
    padding: theme.spacing(2, 0),
    //@ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    fontSize: "1.1rem",
    lineHeight: "1.3rem",
    fontWeight: 500,
    letterSpacing: "-1px",
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem"
    }
  },
  guaranteeGraphic: {
    width: "80px",
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      width: "130px",
      marginBottom: theme.spacing(1)
    }
  }
}));

const Guarantee = () => {
  const classes = useStyles();

  return (
    <>
      <Element name="guarantee-section">
        <div className={classes.background}>
          <Container maxWidth="md" fixed>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box style={{ display: "flex" }}>
                  <Box marginRight={2}>
                    <img
                      src={guaranteeGraphic}
                      alt="FarmFLiX App Icon"
                      className={classes.guaranteeGraphic}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h2" className={classes.title}>
                      The
                    </Typography>
                    <Typography variant="h2" className={classes.titleLarge}>
                      FarmFLiX Guarantee
                    </Typography>
                    <Typography variant="body1" className={classes.bodyText}>
                      If you don&apos;t love your FarmFLiX Membership in the
                      first month we&apos;ll refund your order, no questions
                      asked. Just contact us to cancel before the renewal date
                      in the following month.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Element>
    </>
  );
};

export default Guarantee;
