import React, { useContext, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  Container,
  Divider
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form } from "react-final-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { createGiftcardApplicationEventPass } from "../api/client";
import { OverviewContext } from "../store/OverviewContext";
import { AccountContext } from "../store/AccountContext";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_CAPTCHA_SITE_KEY } from "../env";
import { composeValidators, isEmail, isRequired } from "../utils/validators";
import { Helmet } from "react-helmet";
import logo from "./Header/nav-logo-internal.png";
import { scroller, Element } from "react-scroll";
import { Link, Redirect } from "react-router-dom";
import dayjs from "dayjs";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    position: "relative",
    backgroundImage: "none",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "scroll",
    width: "100%",
    height: "auto",
    display: "flex",
    flexGrow: 1,
    boxShadow: "none",
    textAlign: "center",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    "@media (min-width:600px)": {
      backgroundImage: `url(https://farmflix-images-test.s3-eu-west-1.amazonaws.com/usemycode_bg.jpg)`,
      boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.8)"
    }
  },
  backgroundSolid: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  backgroundContainer: {
    borderRadius: "0px",
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(4, 4, 6, 4),
    backgroundColor: "none",
    "@media (min-width:600px)": {
      backgroundColor: "rgba(23,23,23,0.5)",
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6)
    }
  },
  backgroundContainerTitle: {
    textAlign: "center",
    fontSize: "4rem",
    lineHeight: "3.4rem",
    letterSpacing: "-4px",
    margin: theme.spacing(2, 0),
    color: "#fff",
    fontWeight: 800,
    "@media (min-width:600px)": {
      fontSize: "7.6rem",
      lineHeight: "6rem"
    },
    "& span": {
      fontSize: "5rem",
      lineHeight: "5rem",
      // @ts-ignore
      color: theme.palette.background.ffGreen,
      "@media (min-width:600px)": {
        fontSize: "10.6rem",
        lineHeight: "9rem",
        fontWeight: "800",
        letterSpacing: "-5px"
      }
    }
  },
  backgroundContainerEvent: {
    fontSize: "1.4rem",
    lineHeight: "1.6rem",
    fontWeight: 600,
    marginBottom: theme.spacing(3)
  },
  termsLink: {
    fontSize: "0.8rem",
    marginTop: theme.spacing(2),
    "&:hover": {
      cursor: "pointer"
    }
  },
  formSection: {
    padding: "5% 0",
    width: "100%",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    float: "left",
    minHeight: "270px",
    "@media (min-width:600px)": {
      // @ts-ignore
      backgroundColor: theme.palette.background.ffNavy,
      minHeight: "350px",
      padding: "5%",
      width: "90%"
    },
    "@media (min-width:800px)": {
      minHeight: "370px",
      padding: "5%",
      width: "40%"
    }
  },
  formTitle: {
    textAlign: "center",
    fontSize: "1.6rem",
    lineHeight: "1.2rem",
    letterSpacing: "-2px",
    margin: theme.spacing(0, 0, 2, 0),
    color: "#fff",
    fontWeight: 800,
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2rem"
    }
  },
  formBodyText: {
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "1.1rem",
    margin: theme.spacing(2, 0, 1, 0),
    color: "#fff",
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.3rem"
    },
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen,
      textDecoration: "underline",
      fontWeight: "600"
    }
  },
  formContainer: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(4, 4, 6, 4)
  },
  formSuccessContainer: {
    textAlign: "center"
  },
  formSuccessIcon: {
    fontSize: "5rem",
    marginBottom: theme.spacing(2)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  phoneInput: {
    width: "inherit !important"
  },
  formField: {
    "& label": {
      color: theme.palette.common.white
    },
    "& input": {
      backgroundColor: "#444",
      color: "#fff",
      borderColor: "#444"
    }
  },
  formFieldSet: {
    padding: "4px 0 !important",
    width: "100%",
    position: "relative",
    "& input": {
      padding: "14px 10px",
      fontSize: "1rem",
      background: "#fff"
    },
    "& .react-tel-input .flag-dropdown:before": {
      fontSize: "15px !important",
      top: "-29px !important",
      content: "'' !important",
      color: "#0000008a !important",
      display: "none"
    },
    "& .react-tel-input .form-control": {
      fontSize: "16px !important"
    }
  },
  validPhoneNumberContainer: {
    position: "absolute",
    top: "26px",
    right: "10px",
    zIndex: 1000,
    "@media (min-width:600px)": {
      top: "23px"
    }
  },
  validPhoneNumber: {
    fontSize: "1.6rem"
  },
  button: {
    width: "100%"
  },
  secondaryButton: {
    // @ts-ignore
    backgroundColor: theme.palette.background.upgrade,
    width: "100%",
    border: "2px solid #3aa9de",
    margin: "0px",
    color: "#fff",
    "&:hover": {
      // @ts-ignore
      backgroundColor: theme.palette.background.upgrade,
      border: "2px solid #3aa9de"
    },
    "&:visited": {
      // @ts-ignore
      backgroundColor: theme.palette.background.upgrade,
      border: "2px solid #3aa9de"
    }
  },
  submitLoader: {
    height: "22px !important",
    width: "22px !important",
    // @ts-ignore
    color: theme.palette.background.ffNavy
  },
  termsContainer: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(0, 4, 4, 4)
  },
  termsSection: {
    padding: "10%",
    width: "80%",
    backgroundColor: "#fff",
    float: "left",
    "@media (min-width:600px)": {
      padding: "5%",
      width: "80%"
    },
    "@media (min-width:800px)": {
      padding: "5%",
      width: "40%"
    }
  },
  termsList: {
    paddingLeft: 0,
    color: "#eee"
  },
  termsTitle: {
    textAlign: "center",
    fontSize: "1.8rem",
    lineHeight: "1.2rem",
    letterSpacing: "-2px",
    margin: theme.spacing(0, 0, 2, 0),
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontWeight: 800,
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2rem"
    }
  },
  termsText: {
    fontSize: "1rem",
    lineHeight: "1.1rem",
    margin: theme.spacing(2, 0, 1, 0),
    color: "#eee",
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.3rem"
    },
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen,
      textDecoration: "underline",
      fontWeight: "600"
    }
  },
  termsTextHighLight: {
    fontWeight: 600,
    color: "#99ca3c"
  },
  shareSection: {
    textAlign: "center",
    width: "90%",
    // @ts-ignore
    padding: "30px 5%"
  },
  shareTitle: {
    marginBottom: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.background.ffNavyLighter,
    letterSpacing: "-1px",
    fontWeight: 600
  },
  shareButton: {
    marginRight: theme.spacing(1),
    width: 46,
    "@media (min-width:600px)": {
      width: 34
    }
  }
}));

type FormValues = {
  name: string;
  email: string;
};

const EventPass = () => {
  const classes = useStyles();

  // State
  const [error, setError] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>();
  const [dialCode, setDialCode] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Google ReCAPTCHA
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const { overview } = useContext(OverviewContext);
  const { loggedIn } = useContext(AccountContext);

  // Submit Form
  const submit = async ({ name, email }: FormValues) => {
    setSuccess(false);
    setError(undefined);

    if (!recaptchaRef.current) {
      console.log("Error initializing recaptcha");
      return;
    }

    const captchaToken = recaptchaRef.current.getValue();

    if (!phoneNumber || !countryCode || !dialCode) {
      setError("Whoops! You haven't entered a phone number");
      return;
    }

    if (
      !isValidPhoneNumber(phoneNumber, countryCode.toUpperCase() as CountryCode)
    ) {
      setError("Whoops! Your phone number isn't valid");
      return;
    }

    try {
      setLoading(true);

      if (!captchaToken) {
        setError("Google Recaptcha Error Occurred, please try again.");
        return;
      }

      const success = await createGiftcardApplicationEventPass({
        name,
        email,
        countryCode: dialCode,
        phoneNumber,
        captchaToken
      });

      if (!success) {
        setError("Error submitting form, please try again.");
        return;
      }

      setSuccess(true);
    } catch (e) {
      // @ts-ignore
      setError(e?._error.replace("Validation Error: ", ""));
    } finally {
      setLoading(false);
    }
  };

  const onPhoneChange = (
    value: string,
    { dialCode, countryCode }: { dialCode: string; countryCode: any }
  ) => {
    setError(undefined);
    setCountryCode(countryCode.toUpperCase());
    setDialCode(dialCode);
    setPhoneNumber(value.substring(dialCode.length).replace(/^0+/, ""));
    setPhoneNumber(value.substring(dialCode.length).replace(/^0+/, ""));

    // Show Tick if Phone Number Valid
    if (!isValidPhoneNumber(value, countryCode.toUpperCase())) {
      setPhoneNumberValid(false);
    } else {
      setPhoneNumberValid(true);
    }
  };

  // Scrolling to relevant sections
  const onClickScrollToSection = (section: string) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };

  return (
    <>
      {loggedIn ? <Redirect to="/videos" /> : null}
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Event Pass</title>
        <meta
          name="description"
          content="Get a Free 1 Month Trial FarmFLiX Membership now."
        />
      </Helmet>

      {overview && overview.eventPass && (
        <>
          {overview.eventPass.open ? (
            <>
              <div className={classes.background}>
                <Container
                  maxWidth={"sm"}
                  className={classes.backgroundContainer}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      {/* <Typography
                        variant="body1"
                        className={classes.backgroundContainerEvent}
                      >
                        
                      </Typography> */}
                      <img src={logo} alt="" />
                      <Typography
                        variant="h1"
                        className={classes.backgroundContainerTitle}
                      >
                        <span>FREE</span>
                        <br />
                        1 Month
                        <br />
                        Trial
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className={classes.secondaryButton}
                        size="large"
                        onClick={() => onClickScrollToSection("form")}
                      >
                        Get 1 Month Trial
                      </Button>
                      <Typography variant="body2" className={classes.termsLink}>
                        <span
                          onClick={() =>
                            onClickScrollToSection("terms-conditions")
                          }
                        >
                          <u>TERMS &amp; CONDITIONS APPLY</u>
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
              </div>
              <Element name="form">
                <div className={classes.backgroundSolid}>
                  <Container maxWidth={"sm"}>
                    <Grid
                      container
                      spacing={4}
                      className={classes.formContainer}
                    >
                      {success && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={classes.formSuccessContainer}
                          >
                            <CheckCircleIcon
                              color="primary"
                              className={classes.formSuccessIcon}
                            />
                            <Typography
                              variant="h4"
                              align="center"
                              style={{ marginBottom: "12px" }}
                            >
                              Woo Hoo! A 1 Month Trial is on it&apos;s way to
                              <br />
                              <br />+{dialCode}
                              {phoneNumber}
                            </Typography>
                            <Link
                              to="/usemycode"
                              style={{ textDecoration: "none" }}
                            >
                              <Button className={classes.button} size="large">
                                Redeem Trial Now
                              </Button>
                            </Link>
                          </Grid>
                        </>
                      )}
                      {!success && (
                        <>
                          <Grid item xs={12} sm={12}>
                            <Typography
                              variant="h3"
                              align="center"
                              className={classes.formTitle}
                            >
                              Get Your 1 Month Trial Code
                            </Typography>
                            <Typography
                              variant="body1"
                              align="center"
                              className={classes.formBodyText}
                            >
                              Complete the form below and we&apos;ll{" "}
                              <span>txt</span> you a 1 Month Trial Code for
                              FarmFLiX
                            </Typography>
                          </Grid>
                          <Form onSubmit={submit}>
                            {props => (
                              <form onSubmit={props.handleSubmit}>
                                <div className={classes.form}>
                                  {error && (
                                    <Alert
                                      variant="filled"
                                      severity="error"
                                      onClose={() => setError(undefined)}
                                      style={{ margin: "10px 0" }}
                                    >
                                      {error}
                                    </Alert>
                                  )}
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      className={classes.formFieldSet}
                                    >
                                      <Field
                                        name="name"
                                        validate={composeValidators(isRequired)}
                                      >
                                        {({ input, meta }) => (
                                          <TextField
                                            fullWidth
                                            required
                                            {...input}
                                            type="text"
                                            placeholder="Name"
                                            error={meta.error && meta.touched}
                                            helperText={meta.error}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      className={classes.formFieldSet}
                                    >
                                      <Field
                                        name="email"
                                        validate={composeValidators(
                                          isRequired,
                                          isEmail
                                        )}
                                      >
                                        {({ input, meta }) => (
                                          <TextField
                                            fullWidth
                                            required
                                            {...input}
                                            type="email"
                                            placeholder="Email"
                                            error={meta.error && meta.touched}
                                            helperText={meta.error}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      className={classes.formFieldSet}
                                      style={{ marginBottom: "12px" }}
                                    >
                                      {phoneNumberValid && (
                                        <Box
                                          className={
                                            classes.validPhoneNumberContainer
                                          }
                                        >
                                          <CheckCircleIcon
                                            className={classes.validPhoneNumber}
                                            color="primary"
                                          />
                                        </Box>
                                      )}
                                      <PhoneInput
                                        inputClass={classes.phoneInput}
                                        inputProps={{
                                          name: "mobileNumber",
                                          required: true
                                        }}
                                        enableLongNumbers={true}
                                        countryCodeEditable={false}
                                        autoFormat={false}
                                        enableTerritories={true}
                                        country="gb"
                                        placeholder="Mobile Number"
                                        aria-label="Mobile Number"
                                        preferredCountries={[
                                          "gb",
                                          "ie",
                                          "us",
                                          "au",
                                          "nz"
                                        ]}
                                        excludeCountries={["je"]}
                                        onChange={onPhoneChange}
                                        disabled={loading}
                                      />
                                    </Grid>
                                    <ReCAPTCHA
                                      ref={recaptchaRef}
                                      size="normal"
                                      theme="dark"
                                      sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                                    />
                                    <Grid item xs={12} sm={12}>
                                      <Button
                                        className={classes.button}
                                        type="submit"
                                        size="large"
                                        disabled={loading}
                                      >
                                        {!loading && <>Send</>}
                                        {loading && (
                                          <>
                                            <CircularProgress
                                              className={classes.submitLoader}
                                            />
                                          </>
                                        )}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </div>
                              </form>
                            )}
                          </Form>
                        </>
                      )}
                    </Grid>
                  </Container>
                </div>
              </Element>
              <Element name="terms-conditions">
                <div className={classes.backgroundSolid}>
                  <Container maxWidth={"sm"}>
                    <Grid
                      container
                      spacing={4}
                      className={classes.termsContainer}
                    >
                      <Divider />
                      <Grid item xs={12} sm={12}>
                        <Typography
                          variant="h3"
                          align="center"
                          className={classes.termsTitle}
                        >
                          Terms &amp; Conditions
                        </Typography>
                        <ul className={classes.termsList}>
                          <li>
                            <Typography
                              variant="body1"
                              className={classes.termsText}
                            >
                              Current Active members are <b>NOT ELIGIBLE</b> for
                              this Trial. It does not extend an active
                              membership.
                            </Typography>
                          </li>
                          <li>
                            <Typography
                              variant="body1"
                              className={classes.termsText}
                            >
                              The Trial can only be redeemed once.
                            </Typography>
                          </li>

                          <li>
                            <Typography
                              variant="body1"
                              className={classes.termsText}
                            >
                              The Trial must be activated by the{" "}
                              <b>
                                {dayjs(overview.eventPass.expiryDate).format(
                                  "DD MMMM YYYY"
                                )}
                              </b>
                              .
                            </Typography>
                          </li>
                          <li>
                            <Typography
                              variant="body1"
                              className={classes.termsText}
                            >
                              Code sent via standard SMS - your normal receiving
                              text rates apply.
                            </Typography>
                          </li>
                          <li>
                            <Typography
                              variant="body1"
                              className={classes.termsText}
                            >
                              By requesting a code, you are opting in to future
                              FarmFLiX communications.
                            </Typography>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </Element>
            </>
          ) : (
            <>
              <div
                className={classes.backgroundSolid}
                style={{ height: "100vh" }}
              >
                <Container
                  maxWidth={"sm"}
                  className={classes.backgroundContainer}
                >
                  <Grid container style={{ textAlign: "center" }}>
                    <Grid item xs={12} sm={12} md={12}>
                      <img src={logo} alt="" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        style={{ marginTop: "12px" }}
                        className={classes.backgroundContainerEvent}
                      >
                        Event Pass is now Closed
                      </Typography>
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <Button className={classes.button} size="large">
                          Go to Home
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EventPass;
