import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.lightgray
  },
  quoteText: {
    fontSize: "1.4rem",
    lineHeight: "1.2rem",
    color: theme.palette.common.dark
  },
  quoteName: {
    marginTop: theme.spacing(2),
    fontSize: "1.2rem",
    fontWeight: "600",
    color: theme.palette.common.dark
  }
}));

export default function Quote() {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <Container fixed>
        <Grid container align="center" spacing={0}>
          <Grid item xs={12} sm={12}>
            <Box my={8} mx={4} align="center">
              <Typography variant="body" className={classes.quoteText}>
                &quot;We&apos;re doing everything we can to get you the angles
                you can&apos;t see...
                <br />
                to the best quality we can&quot;
              </Typography>
              <br />
              <Typography variant="h3" className={classes.quoteName}>
                John McClean
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
