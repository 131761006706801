import React, { useContext } from "react";
import { IconButton } from "@material-ui/core";
import { SearchContext } from "../SearchContainer";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import useStyles from "../styles";

const ShareModalIconButton = () => {
  const { openSearch } = useContext(SearchContext);
  const classes = useStyles();

  const onClick = () => {
    openSearch();
  };

  return (
    <IconButton name="searchModal" onClick={onClick}>
      <SearchOutlinedIcon className={classes.searchButtonIcon} />
    </IconButton>
  );
};

export default ShareModalIconButton;
