import React, { createContext, useCallback, useState } from "react";

import {
  getVideoSource,
  getPreviewVideo,
  getExternalMasterVideo,
  getPreviewSource
} from "../api/client";
import CatalogSearchRequest from "../api/requests/CatalogSearchRequest";
import useLogger, {
  LogEventCategory,
  PlaybackEvents
} from "../hooks/useLogger";
import useVideoSearch from "../hooks/useVideoSearch";
import {
  DetailedVideoSearchResult,
  VideoSearchResult,
  VideoSource
} from "@booyaltd/core";
import { PaymentRequiredError } from "../api/errors";

interface IPlayerContext {
  lastVideo?: VideoSearchResult;
  lastVideoRoute?: string;
  videoLoading: boolean;
  currentVideo?: DetailedVideoSearchResult;
  currentPreviewVideo?: DetailedVideoSearchResult;
  membersOnlyResponse?: DetailedVideoSearchResult;
  currentVideoSource?: string;
  currentPreviewVideoSource?: string;
  error: string;
  videos: VideoSearchResult[];
  videoSourceLoading: boolean;
  videoListLoading: boolean;
  total: number;
  searchPhrase: string;
  searchTag: string;
  searchSortOrder: string;
  localVideoProgresses: { [key: string]: number };
  search: CatalogSearchRequest;
  updateTagVideoSearch(
    tag: string,
    includePreviewVideos: boolean,
    sort: string,
    initialVideos: VideoSearchResult[],
    initialTotal: number
  ): void;
  setSearchPhrase(phrase: string): void;
  setSearchTag(tag: string): void;
  setSearchSortOrder(order: string): void;
  setSearch(search: CatalogSearchRequest): void;
  loadVideo(videoId: string): Promise<void>;
  resetError(): void;
  resetVideoSearch(): void;
  onVideoProgress(progressSecs: number): void;
  triggerNextPage(): void;
  triggerRefresh(): void;
  clearCurrentVideo(): void;
  clearCurrentPreviewVideo(): void;
  fullScreen: boolean;
  setFullscreen(fullScreen: boolean): void;
  loadPreviewVideo(videoId: string, idType?: "slug" | "id"): void;
  loadSingleVideo(videoId: string): void;

  loadExternalMasterVideo(videoId: string): void;
  videoExternalLoading: boolean;
  currentExternalVideo?: VideoSearchResult;
  currentExternalVideoSource?: string;
}

export interface IVideoProgress {
  playbackPosition: number;
}

const PlayerContext = createContext<IPlayerContext>({} as IPlayerContext);

const PlayerProvider = (props: any) => {
  const [currentVideo, setCurrentVideo] = useState<VideoSearchResult>();
  const [currentPreviewVideo, setCurrentPreviewVideo] = useState<
    DetailedVideoSearchResult
  >();
  const [currentVideoSource, setCurrentVideoSource] = useState<string>();
  const [currentPreviewVideoSource, setCurrentPreviewVideoSource] = useState<
    string
  >();

  const [videoExternalLoading, setVideoExternalLoading] = useState<boolean>(
    false
  );
  const [currentExternalVideo, setCurrentExternalVideo] = useState<
    DetailedVideoSearchResult
  >();
  const [currentExternalVideoSource, setCurrentExternalVideoSource] = useState<
    string
  >();

  const [nextVideoId, setNextVideoId] = useState<string>();
  const [videoSourceLoading, setVideoSourceLoading] = useState<string>();
  const [fullScreen, setFullscreen] = useState<boolean>(false);
  const [videoLoading, setVideoLoading] = useState<boolean>(false);
  const {
    videos,
    loading,
    error,
    total,
    triggerNextPage,
    triggerRefresh,
    searchPhrase,
    searchTag,
    searchSortOrder,
    searchResults,
    searchSubmittedNew,
    setSearchPhrase,
    setSearchTag,
    setSearchSortOrder,
    setVideos,
    setTotal,
    resetError,
    resetVideoSearch,
    search,
    setSearch
  } = useVideoSearch();

  const [localVideoProgresses, setLocalVideoProgresses] = useState<{
    [key: string]: number;
  }>({});

  const [membersOnlyResponse, setMembersOnlyResponse] = React.useState<
    DetailedVideoSearchResult
  >();

  const { logEvent, logMetricEvent } = useLogger();

  const loadPreviewVideo = useCallback(
    async (videoId: string, idType: "slug" | "id" = "slug") => {
      try {
        setVideoLoading(true);
        const searchPreview =
          idType === "slug"
            ? await getPreviewVideo(videoId)
            : await getPreviewSource(videoId);

        if (!searchPreview) {
          return;
        }

        setCurrentPreviewVideo(searchPreview);

        const previewVideoSource = searchPreview.sources.find(
          (vs: VideoSource) => vs.type === "application/x-mpegURL"
        );

        if (!previewVideoSource || !previewVideoSource.src) {
          return;
        }

        setVideoSourceLoading(undefined);
        setCurrentPreviewVideoSource(previewVideoSource.src);
      } catch (e) {
        console.log(e);
      } finally {
        setVideoLoading(false);
      }
    },
    []
  );

  const loadExternalMasterVideo = useCallback(
    async (videoId: string) => {
      try {
        setVideoExternalLoading(true);

        const searchExternal = await getExternalMasterVideo(videoId);
        if (!searchExternal) {
          return;
        }

        logMetricEvent({
          type: "video_view",
          payload: {
            id: searchExternal.id,
            name: searchExternal.name,
            tags: searchExternal.tags,
            softLinks: searchExternal.softLinks
          }
        });

        setCurrentExternalVideo(searchExternal);

        const externalVideoSource = searchExternal.sources.find(
          (vs: VideoSource) => vs.type === "application/x-mpegURL"
        );

        if (!externalVideoSource || !externalVideoSource.src) {
          return;
        }

        setVideoSourceLoading(undefined);
        setCurrentExternalVideoSource(externalVideoSource.src);
      } catch (e) {
        console.log(e);
      } finally {
        setVideoExternalLoading(false);
      }
    },
    [logMetricEvent]
  );

  const loadSingleVideo = useCallback(
    async (videoId: string) => {
      const videoSourceResponse = await getVideoSource(videoId).catch(e => {
        if (e instanceof PaymentRequiredError) {
          console.log("Payment Required Error", e);
          setMembersOnlyResponse(e.response as DetailedVideoSearchResult);
          setCurrentPreviewVideo(e.response as DetailedVideoSearchResult);
        }
      });

      if (!videoSourceResponse) {
        return;
      }

      logMetricEvent({
        type: "video_view",
        payload: {
          id: videoSourceResponse.id,
          name: videoSourceResponse.name,
          tags: videoSourceResponse.tags,
          softLinks: videoSourceResponse.softLinks
        }
      });

      setCurrentVideo(videoSourceResponse);

      const videoSource = videoSourceResponse.sources.find(
        (vs: VideoSource) => vs.type.toLowerCase() === "application/x-mpegurl"
      );

      if (!videoSource || !videoSource.src) {
        return;
      }

      setVideoSourceLoading(undefined);
      setCurrentVideoSource(videoSource.src);
    },
    [logMetricEvent]
  );

  const loadVideo = useCallback(
    async (videoId: string) => {
      // If video is already loading dont bother trying again
      // if (videoSourceLoading !== undefined && videoSourceLoading === videoId) {
      //   console.log('error');
      //   return;
      // }

      const selectedVideo = videos.find(v => v.slug === videoId);
      const selectedVideoIndex = videos.findIndex(v => v.slug === videoId);

      if (!selectedVideo) {
        // Look up video manually video slug
        loadSingleVideo(videoId).catch(e => {
          console.log(e);
        });
        return;
      }

      setNextVideoId(
        videos[selectedVideoIndex + 1]
          ? videos[selectedVideoIndex + 1].id
          : undefined
      );

      if (localVideoProgresses && localVideoProgresses[selectedVideo.id]) {
        selectedVideo.progress = localVideoProgresses[selectedVideo.id];
      }

      setCurrentVideo(selectedVideo);

      setVideoSourceLoading(videoId);
      await getVideoSource(selectedVideo.id)
        .then(videoSourcesResponse => {
          logMetricEvent({
            type: "video_view",
            payload: {
              id: videoSourcesResponse.id,
              name: videoSourcesResponse.name,
              tags: videoSourcesResponse.tags,
              softLinks: videoSourcesResponse.softLinks
            }
          });
          const videoSource = videoSourcesResponse.sources.find(
            (vs: VideoSource) => vs.type === "application/x-mpegURL"
          );
          if (!videoSource || !videoSource.src) {
            return;
          }

          setVideoSourceLoading(undefined);
          setCurrentVideo(videoSourcesResponse);
          setCurrentVideoSource(videoSource.src);
        })
        .catch(e => {
          if (e instanceof PaymentRequiredError) {
            console.log("Payment Required Error", e);
            setMembersOnlyResponse(e.response as DetailedVideoSearchResult);
            setCurrentPreviewVideo(e.response as DetailedVideoSearchResult);
          }
        });
    },
    [videos, loadSingleVideo, localVideoProgresses, logMetricEvent]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadNextVideo = useCallback(async () => {
    console.log("Load Next Video");
    if (nextVideoId) {
      await loadVideo(nextVideoId);
    }
  }, [nextVideoId, loadVideo]);

  const updateTagVideoSearch = (
    tag: string,
    includePreviewVideos: boolean,
    sort: string,
    initialVideos: VideoSearchResult[],
    initialTotal: number
  ) => {
    setVideos(initialVideos);
    setTotal(initialTotal);
    setSearch({
      ...search,
      tag,
      sort,
      offset: initialVideos.length,
      limit: 22
    });
  };

  //   const onVideoEnd = useCallback(async () => {
  //     console.log('On Video End', autoplay);
  //     if (autoplay && !loadingNext) {
  //       loadingNext = true;
  //       await loadNextVideo();
  //     }

  //     await logEvent({
  //       category: LogEventCategory.PLAYBACK_ACTION,
  //       event: PlaybackEvents.PLAYBACK_FINISHED,
  //       data: {
  //         videoId: currentVideo?.id,
  //         progress: currentVideo?.duration,
  //         playbackLocation: [CastState.CONNECTED, CastState.CONNECTING].includes(
  //           castState,
  //         )
  //           ? 'Casting'
  //           : 'Device',
  //       },
  //     });
  //   }, [autoplay, currentVideo, castState]);

  const onVideoProgress = useCallback(
    async (positionSecs: number) => {
      if (loading) {
        return;
      }

      if (currentVideo?.id && positionSecs > 0) {
        setLocalVideoProgresses({
          ...localVideoProgresses,
          [currentVideo?.id]: positionSecs
        });
      }

      if (positionSecs > 0) {
        if ((2 * Math.floor(positionSecs / 2)) % 10 === 0) {
          await logEvent({
            category: LogEventCategory.PLAYBACK_ACTION,
            event: PlaybackEvents.PLAYBACK_PROGRESS,
            data: {
              videoId: currentVideo?.id,
              progress: Math.floor(positionSecs),
              playbackLocation: "web"
            }
          });
        }
      }
    },
    [currentVideo, loading, localVideoProgresses, logEvent]
  );

  const context = {
    videoLoading,
    setSearch,
    currentVideo,
    currentPreviewVideo,
    currentVideoSource,
    currentPreviewVideoSource,
    loadVideo,
    error,
    videos,
    videoListLoading: loading,
    videoSourceLoading: videoSourceLoading !== undefined,
    total,
    search,
    updateTagVideoSearch,
    triggerNextPage,
    triggerRefresh,
    searchPhrase,
    searchTag,
    searchSortOrder,
    searchResults,
    searchSubmittedNew,
    setSearchPhrase,
    setSearchTag,
    setSearchSortOrder,
    onVideoProgress,
    resetError,
    resetVideoSearch,
    membersOnlyResponse,
    clearCurrentVideo(): void {
      setCurrentVideo(undefined);
      setCurrentVideoSource(undefined);
      setMembersOnlyResponse(undefined);
    },
    clearCurrentPreviewVideo(): void {
      setCurrentPreviewVideo(undefined);
      setCurrentPreviewVideoSource(undefined);
      setMembersOnlyResponse(undefined);
    },
    clearCurrentExternalVideo(): void {
      setCurrentExternalVideo(undefined);
      setCurrentExternalVideoSource(undefined);
      setMembersOnlyResponse(undefined);
    },
    localVideoProgresses,
    fullScreen,
    setFullscreen,
    loadPreviewVideo,
    loadSingleVideo,

    loadExternalMasterVideo,
    videoExternalLoading,
    currentExternalVideo,
    currentExternalVideoSource
  };

  return (
    <PlayerContext.Provider value={context as IPlayerContext}>
      {props.children}
    </PlayerContext.Provider>
  );
};

export { PlayerContext, PlayerProvider };
