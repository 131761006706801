import React, { useEffect, useState } from "react";
import {
  IUserMediaItemType,
  IUserMediaContentType,
  IUserMediaItem
} from "@booyaltd/core";
import Dropzone from "react-dropzone";
import { Alert } from "@material-ui/lab";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import BackdropLoader from "../common/BackdropLoader";
import { createMedia, createPublicMedia } from "../../api/client";

type ImageUploaderFieldProps = {
  error?: string;
  type: IUserMediaItemType;
  public: boolean;
  contentType: IUserMediaContentType | IUserMediaContentType[];
  onChange: (value: string | undefined) => void;
  validationText?: string;
  dropzoneText?: string;
};

const useStyles = makeStyles(theme => ({
  container: {},
  previewContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  previewImage: {
    maxWidth: 200,
    maxHeight: 200,
    marginBottom: theme.spacing(1)
  },
  previewReset: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white
  },
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    marginTop: theme.spacing(1),
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    // @ts-ignore
    // backgroundColor: theme.palette.background.ffNavyLighter,
    backgroundColor: "#353535",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer"
  },
  active: {
    borderColor: theme.palette.action.active
  },
  accept: {
    borderColor: "#00e676"
  },
  reject: {
    borderColor: "#ff1744"
  }
}));

const ImageUploaderField = (props: ImageUploaderFieldProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [droppedFile, setDroppedFile] = useState<File>();
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [mediaItem, setMediaItem] = useState<IUserMediaItem>();
  const classes = useStyles();

  useEffect(() => {
    if (mediaItem && !loading && !error) {
      props.onChange(mediaItem.id);
    }
  }, [error, loading, mediaItem, props]);

  const onDropRejected = () => {
    props.onChange(undefined);
    setError(
      props.validationText ||
        "Images must be either PNG or JPEG format, and no larger than 10MB"
    );
  };

  const onDropAccepted = (files: File[]) => {
    if (!files || files.length !== 1) return;

    const file = files[0];

    setDroppedFile(file);
    setPreviewUrl(URL.createObjectURL(file));
    setError(undefined);
    setLoading(true);

    const create = props.public ? createPublicMedia : createMedia;

    create({
      type: props.type,
      contentType: file.type as IUserMediaContentType,
      contentLength: file.size,
      filename: file.name
    })
      .then(({ uploadUrl, item: mediaItem }) => {
        setMediaItem(mediaItem);
        return fetch(uploadUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type
          }
        });
      })
      .then(r => {
        if (r.status < 200 || r.status > 299) {
          throw new Error(`Invalid Response Status: ${r.status}`);
        }

        console.log("Uploaded file");
      })
      .catch(e => {
        console.error("Error uploading file", e);
        setError("Error uploading file, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onReset = () => {
    setLoading(false);
    setError(undefined);
    setDroppedFile(undefined);
    setPreviewUrl(undefined);
    setMediaItem(undefined);
    props.onChange(undefined);
  };

  return (
    <Box className={classes.container}>
      {!error && props.error && (
        <Alert
          color="error"
          variant="filled"
          severity="error"
          style={{ margin: "10px 0" }}
        >
          {props.error}
        </Alert>
      )}
      {error ? (
        <Alert
          variant="filled"
          severity="error"
          style={{ margin: "10px 0" }}
          action={
            <Button color="inherit" size="small" onClick={onReset}>
              Try Again
            </Button>
          }
        >
          {error}
        </Alert>
      ) : droppedFile ? (
        <Box className={classes.previewContainer}>
          {loading && <BackdropLoader />}
          {previewUrl && (
            <img
              className={classes.previewImage}
              src={previewUrl}
              alt="Preview upload"
            />
          )}
          <Button
            className={classes.previewReset}
            size="small"
            variant="outlined"
            onClick={onReset}
          >
            Change Image
          </Button>
        </Box>
      ) : (
        <Dropzone
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          accept={props.contentType}
          maxFiles={1}
          maxSize={1024 * 1024 * 10}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject
          }) => {
            const className = `${classes.dropzone} ${
              isDragActive
                ? classes.active
                : isDragReject
                ? classes.reject
                : isDragAccept
                ? classes.active
                : ""
            }`.trim();

            return (
              <Box {...getRootProps()} className={className}>
                <input {...getInputProps()} />
                <Typography variant="body2">
                  {props.dropzoneText || "Click here or drop an image"}
                </Typography>
              </Box>
            );
          }}
        </Dropzone>
      )}
    </Box>
  );
};

export default ImageUploaderField;
