import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Box,
  Typography,
  IconButton
} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import "./../../../node_modules/react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { CatalogOverviewResponse } from "@booyaltd/core";
// @ts-ignore
import ItemsCarousel from "react-items-carousel";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    padding: "10px 0 15px 0",
    "@media (min-width:600px)": {
      padding: "10px 0 20px 0"
    },
    "@media (min-width:960px)": {
      padding: "10px 0 30px 0"
    }
  },
  chevron: {
    // @ts-ignore
    color: theme.palette.common.lightgray,
    cursor: "pointer",
    padding: "10px",
    fontSize: "1.4rem",
    borderRadius: "50%",
    backgroundColor: "rgba(2,2,2,0.5)",
    "&:hover": {
      color: theme.palette.common.white
    }
  },
  desktopCarouselItemContainer: {
    height: "600px",
    display: "block",
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  desktopCarouselItemContainerBottomGradient: {
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(23,23,23,1) 150%)",

    height: "300px",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "600px"
    }
  },
  desktopCarouselItemContainerBottomGradientHover: {
    transition: "0.5s",
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(23,23,23,1) 100%)",
    height: "300px",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "600px"
    }
  },
  desktopCarouselItemDetails: {
    position: "absolute",
    bottom: "20px",
    left: "15px"
  },
  desktopCarouselItemChannel: {
    marginBottom: theme.spacing(1),
    borderRadius: "50%",
    width: "80px",
    "@media (min-width:600px)": {
      borderRadius: "50%",
      width: "120px"
    },
    "@media (min-width:960px)": {
      borderRadius: "50%",
      width: "70px"
    }
  },
  desktopCarouselItemTitle: {
    fontSize: "2.2rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  desktopCarouselItemEpisodeCount: {
    fontSize: "1.1rem",
    textShadow: "-2px 2px 2px #171717",
    fontWeigth: "400",
    color: "#c5c5c5"
  },
  desktopCarouselItemDetailsActiveTop: {
    animation: "fadeInFromTop .3s ease-in-out",
    position: "absolute",
    top: "25px",
    left: "25px"
  },
  desktopCarouselItemDetailsActiveBottom: {
    animation: "fadeInFromBottom .3s ease-in-out",
    position: "absolute",
    bottom: "25px",
    right: "15px"
  },
  desktopCarouselItemDetailsActiveDescription: {
    fontSize: "2.2rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(1),
    width: "90%",
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem",
      fontWeight: "400"
    }
  },
  desktopCarouselItemDetailsActivePlayIconButton: {
    fontSize: "2.2rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        // @ts-ignore
        color: theme.palette.background.ffGreen
      }
    }
  },
  fullWidthItemContainer: {
    height: "200px",
    display: "block",
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    zIndex: 1,
    "@media (min-width:600px)": {
      height: "400px"
    },
    "@media (min-width:960px)": {
      height: "500px"
    }
  },
  fullWidthItemContainerBottomGradient: {
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 0%, rgba(23,23,23,1) 100%)",
    height: "200px",
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "100%",
    "@media (min-width:600px)": {
      height: "400px"
    },
    "@media (min-width:800px)": {
      height: "500px"
    }
  },
  fullWidthItemContainerBottomGradientHover: {
    transition: "0.5s",
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(23,23,23,1) 100%)",

    height: "200px",
    "@media (min-width:600px)": {
      height: "400px"
    },
    "@media (min-width:800px)": {
      height: "500px"
    }
  },
  fullWidthItemDetails: {
    position: "absolute",
    bottom: "15px",
    left: "10px",
    textAlign: "left",
    "@media (min-width:600px)": {
      bottom: "30px",
      left: "25px",
      textAlign: "left"
    },
    "@media (min-width:960px)": {
      bottom: "30px",
      left: "25px",
      textAlign: "left"
    }
  },
  fullWidthItemChannel: {
    marginBottom: theme.spacing(1),
    borderRadius: "50%",
    width: "40px !important",
    "@media (min-width:600px)": {
      borderRadius: "50%",
      width: "60px !important"
    },
    "@media (min-width:960px)": {
      borderRadius: "50%",
      width: "100px"
    }
  },
  fullWidthItemTitle: {
    fontSize: "1.4rem",
    lineHeight: "1.6rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(0.2),
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1.8rem",
      lineHeight: "1.6rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem",
      fontWeight: "400",
      marginBottom: theme.spacing(1)
    }
  },
  fullWidthItemEpisodeCount: {
    fontSize: "0.9rem",
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    color: "#888",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.3rem"
    }
  },
  "@global": {
    "@keyframes fadeInFromTop": {
      from: {
        transform: "translate3d(0,-15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    },
    "@keyframes fadeInFromBottom": {
      from: {
        transform: "translate3d(0,15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    }
  },
  fullWidthItemDetailsActiveTop: {
    animation: "fadeInFromTop .3s ease-in-out",
    position: "absolute",
    top: "25px",
    left: "25px"
  },
  fullWidthItemDetailsActiveBottom: {
    animation: "fadeInFromBottom .3s ease-in-out",
    position: "absolute",
    bottom: "35px",
    right: "35px"
  },
  fullWidthItemDetailsActiveDescription: {
    fontSize: "2.2rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem",
      fontWeight: "400"
    }
  },
  episodeHoverDetailsActivePlayIconButton: {
    fontSize: "2.2rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        // @ts-ignore
        color: theme.palette.background.ffGreen
      }
    }
  },
  iconButton: {
    width: "3rem",
    height: "3rem"
  },
  mobileTabletContainerBottomGradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 40%, rgba(23,23,23,0.9) 90%)",

    height: "90%",
    width: "100%"
  },
  mobileTabletContainerBottomGradientHover: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(33,33,33,1) 100%)",
    height: "100%",
    width: "100%"
  },
  mobileTabletContainer: {
    height: "330px",
    overflow: "auto",
    whiteSpace: "nowrap",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  mobileTabletEpisode: {
    display: "inline-block",
    width: "190px",
    height: "330px",
    whiteSpace: "initial",
    marginRight: theme.spacing(0.5),
    verticalAlign: "top",
    position: "relative",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat"
  },
  mobileTabletEpisodeDetails: {
    position: "absolute",
    bottom: "10px",
    left: "10px"
  },
  mobileTabletEpisodeChannel: {
    borderRadius: "50%",
    width: "40px"
  },
  mobileTabletEpisodeTitle: {
    marginTop: theme.spacing(0.5),
    fontSize: "1.1rem",
    paddingRight: "10px",
    // @ts-ignore
    color: theme.palette.common.upgrade,
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.1rem"
    }
  },
  mobileTabletEpisodeNumber: {
    marginTop: theme.spacing(0),
    fontSize: "0.8rem",
    marginBottom: "2px",
    paddingRight: "10px",
    // @ts-ignore
    color: theme.palette.common.upgrade,
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.4rem"
    }
  }
}));

const VerticalPoster = ({
  playlists
}: Pick<CatalogOverviewResponse, "playlists">) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const chevronWidth = 0;

  const classes = useStyles();

  const [isFullWidthHovering, setIsFullWidthHovering] = useState<string>();
  const [isItemHovering, setIsItemHovering] = useState<string>();

  // Desktop - Full Width
  const handleDesktopFullWidthHover = (index: string) => {
    setIsFullWidthHovering(index);
  };

  const handleDesktopFullWidthHoverClose = () => {
    setIsFullWidthHovering(undefined);
  };

  // Desktop - Item
  const handleDesktopItemHover = (index: string) => {
    setIsItemHovering(index);
  };

  const handleDesktopItemHoverClose = () => {
    setIsItemHovering(undefined);
  };

  return (
    <>
      <div className={classes.background}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <>
                {/* Desktop */}
                <Hidden only={["xs", "sm"]}>
                  {/* If more than 1 playlist */}
                  {playlists.length > 1 ? (
                    <ItemsCarousel
                      requestToChangeActive={setActiveItemIndex}
                      activeItemIndex={activeItemIndex}
                      numberOfCards={3}
                      slidesToScroll={3}
                      gutter={10}
                      leftChevron={
                        <div>
                          <ArrowBackIosRoundedIcon
                            className={classes.chevron}
                          />
                        </div>
                      }
                      rightChevron={
                        <div>
                          <ArrowForwardIosRoundedIcon
                            className={classes.chevron}
                          />
                        </div>
                      }
                      chevronWidth={chevronWidth}
                    >
                      {playlists.map((pl, ind) => (
                        <Link to={"/series/" + pl.tag} key={ind}>
                          <Box
                            className={classes.desktopCarouselItemContainer}
                            style={{
                              backgroundImage: `url(${pl.posterVerticalMediaUrl})`
                            }}
                            onMouseEnter={e => handleDesktopItemHover(pl.id)}
                            onMouseLeave={handleDesktopItemHoverClose}
                          >
                            <div
                              className={
                                isItemHovering === pl.id
                                  ? classes.desktopCarouselItemContainerBottomGradientHover
                                  : classes.desktopCarouselItemContainerBottomGradient
                              }
                            >
                              {isItemHovering === pl.id && (
                                <>
                                  <Box
                                    display="flex"
                                    className={
                                      classes.desktopCarouselItemDetailsActiveTop
                                    }
                                  >
                                    <Typography
                                      className={
                                        classes.desktopCarouselItemDetailsActiveDescription
                                      }
                                    >
                                      {pl.description ? pl.description : ""}
                                    </Typography>
                                  </Box>
                                  <Box
                                    display="flex"
                                    className={
                                      classes.desktopCarouselItemDetailsActiveBottom
                                    }
                                  >
                                    <Box display="flex">
                                      <IconButton
                                        aria-label="Play Episode"
                                        className={
                                          classes.episodeHoverDetailsActivePlayIconButton
                                        }
                                      >
                                        <PlayArrowIcon
                                          className={classes.iconButton}
                                        />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </>
                              )}
                              <div
                                className={classes.desktopCarouselItemDetails}
                              >
                                {pl.thumbnailMediaUrl && (
                                  <img
                                    src={pl.thumbnailMediaUrl}
                                    alt=""
                                    className={
                                      classes.desktopCarouselItemChannel
                                    }
                                  />
                                )}
                                <Typography
                                  className={classes.desktopCarouselItemTitle}
                                  variant="h4"
                                >
                                  {pl.name}
                                </Typography>
                                <Typography
                                  className={
                                    classes.desktopCarouselItemEpisodeCount
                                  }
                                >
                                  {pl.total} Episodes
                                </Typography>
                              </div>
                            </div>
                          </Box>
                        </Link>
                      ))}
                    </ItemsCarousel>
                  ) : (
                    // Single Playlist
                    <>
                      <Link to={"/series/" + playlists[0].tag}>
                        <Box
                          className={classes.fullWidthItemContainer}
                          style={{
                            backgroundImage: `url(${playlists[0].posterHorizontalMediaUrl})`
                          }}
                          onMouseEnter={e =>
                            handleDesktopFullWidthHover(playlists[0].id)
                          }
                          onMouseLeave={handleDesktopFullWidthHoverClose}
                        >
                          <div
                            className={
                              isFullWidthHovering === playlists[0].id
                                ? classes.fullWidthItemContainerBottomGradientHover
                                : classes.fullWidthItemContainerBottomGradient
                            }
                          >
                            {isFullWidthHovering === playlists[0].id && (
                              <>
                                <Box
                                  display="flex"
                                  className={
                                    classes.fullWidthItemDetailsActiveTop
                                  }
                                >
                                  <Typography
                                    className={
                                      classes.fullWidthItemDetailsActiveDescription
                                    }
                                  >
                                    {playlists[0].description
                                      ? playlists[0].description
                                      : ""}
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  className={
                                    classes.fullWidthItemDetailsActiveBottom
                                  }
                                >
                                  <Box display="flex">
                                    <Box display="flex">
                                      <IconButton
                                        aria-label="Play Episode"
                                        className={
                                          classes.episodeHoverDetailsActivePlayIconButton
                                        }
                                      >
                                        <PlayArrowIcon
                                          className={classes.iconButton}
                                        />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            )}
                            <div className={classes.fullWidthItemDetails}>
                              {playlists[0].thumbnailMediaUrl && (
                                <img
                                  src={playlists[0].thumbnailMediaUrl}
                                  alt=""
                                  className={classes.fullWidthItemChannel}
                                />
                              )}
                              <Typography
                                className={classes.fullWidthItemTitle}
                                variant="h4"
                              >
                                {playlists[0].name}
                              </Typography>
                              <Typography
                                className={classes.fullWidthItemEpisodeCount}
                              >
                                {playlists[0].total} Episodes
                              </Typography>
                            </div>
                          </div>
                        </Box>
                      </Link>
                    </>
                  )}
                </Hidden>
                {/* Mobile */}
                <Hidden only={["md", "lg", "xl"]}>
                  {playlists.length > 1 ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.mobileTabletContainer}
                      >
                        {playlists.map((pl, ind) => {
                          return (
                            <Link key={ind} to={"/series/" + pl.tag}>
                              <Box
                                className={classes.mobileTabletEpisode}
                                style={{
                                  backgroundImage: `url(${pl.posterVerticalMediaUrl})`
                                }}
                              >
                                <div
                                  className={
                                    classes.mobileTabletContainerBottomGradient
                                  }
                                >
                                  <div
                                    className={
                                      classes.mobileTabletEpisodeDetails
                                    }
                                  >
                                    {pl.thumbnailMediaUrl && (
                                      <img
                                        src={pl.thumbnailMediaUrl}
                                        alt=""
                                        className={
                                          classes.mobileTabletEpisodeChannel
                                        }
                                      />
                                    )}
                                    <Typography
                                      className={
                                        classes.mobileTabletEpisodeTitle
                                      }
                                      variant="h4"
                                    >
                                      {pl.name}
                                    </Typography>
                                    <Typography
                                      className={
                                        classes.mobileTabletEpisodeNumber
                                      }
                                    >
                                      {pl.total} Episodes
                                    </Typography>
                                  </div>
                                </div>
                              </Box>
                            </Link>
                          );
                        })}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Link to={"/series/" + playlists[0].tag}>
                        <Box
                          className={classes.fullWidthItemContainer}
                          style={{
                            backgroundImage: `url(${playlists[0].posterHorizontalMediaUrl})`
                          }}
                        >
                          <div
                            className={
                              classes.fullWidthItemContainerBottomGradient
                            }
                          >
                            {isFullWidthHovering === playlists[0].id && (
                              <>
                                <Box
                                  display="flex"
                                  className={
                                    classes.fullWidthItemDetailsActiveTop
                                  }
                                >
                                  <Typography
                                    className={
                                      classes.fullWidthItemDetailsActiveDescription
                                    }
                                  >
                                    {playlists[0].description}
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  className={
                                    classes.fullWidthItemDetailsActiveBottom
                                  }
                                >
                                  <Box display="flex">
                                    <Box display="flex">
                                      <Link to={"/series/" + playlists[0].tag}>
                                        <IconButton
                                          aria-label="Play Episode"
                                          className={
                                            classes.episodeHoverDetailsActivePlayIconButton
                                          }
                                        >
                                          <PlayArrowIcon
                                            className={classes.iconButton}
                                          />
                                        </IconButton>
                                      </Link>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            )}
                            <div className={classes.fullWidthItemDetails}>
                              {/* To update this with 'series logo' */}
                              {playlists[0].thumbnailMediaUrl && (
                                <img
                                  src={playlists[0].thumbnailMediaUrl}
                                  alt=""
                                  className={classes.fullWidthItemChannel}
                                />
                              )}
                              <Typography
                                className={classes.fullWidthItemTitle}
                                variant="h4"
                              >
                                {playlists[0].name}
                              </Typography>
                              <Typography
                                className={classes.fullWidthItemEpisodeCount}
                              >
                                {playlists[0].total} Episodes
                              </Typography>
                            </div>
                          </div>
                        </Box>
                      </Link>
                    </>
                  )}
                </Hidden>
              </>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default VerticalPoster;
