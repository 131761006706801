import React, { useContext, useEffect } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { OverviewContext } from "../../store/OverviewContext";
import { Redirect } from "react-router-dom";
import NotifyDialog from "../Dialogs/DoorsClosedNotifyMe";
import { TryBuyContext } from "./TryBuyContainer";

function TryBuyModal({ type }: { type: "notify" | "trial" }) {
  const overviewContext = useContext(OverviewContext);
  const { setDialogOpen } = useContext(TryBuyContext);

  const doorsOpen = !!overviewContext.overview?.doors?.trialsOpen;
  useEffect(() => {
    if (doorsOpen) {
      setDialogOpen(false);
    }
  }, [doorsOpen, setDialogOpen]);

  if (
    !overviewContext.overview ||
    overviewContext.error ||
    overviewContext.loading
  ) {
    return (
      <Backdrop open style={{ zIndex: 1000000, color: "#fff" }}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  if (!doorsOpen)
    return (
      <NotifyDialog open type={type} closed={() => setDialogOpen(false)} />
    );

  return <Redirect to="/try" />;
}

export default TryBuyModal;
