import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid, Button } from "@material-ui/core";
import Countdown from "react-countdown";
import { AccountContext } from "../../store/AccountContext";
import { Link } from "react-router-dom";
// import dayjs from "dayjs";

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(0, 0, 2, 0)
  },
  innerBackground: {
    // @ts-ignore
    backgroundColor: "#424242",
    position: "relative",
    padding: theme.spacing(2, 2),
    "@media (min-width:600px)": {
      padding: theme.spacing(4, 4)
    }
  },
  title: {
    letterSpacing: "-1px",
    fontSize: "1.4rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
    textShadow: "-1px 1px 1px #171717",
    textAlign: "center",
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      textAlign: "left",
      textShadow: "-2px 2px 2px #171717",
      fontWeight: 600,
      fontSize: "1.8rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "2rem",
      lineHeight: "2rem"
    }
  },
  titleLarge: {
    textAlign: "center",
    letterSpacing: "-1px",
    fontSize: "1.9rem",
    lineHeight: "1.7rem",
    fontWeight: 600,
    textShadow: "-1px 1px 1px #171717",
    "@media (min-width:600px)": {
      textAlign: "left",
      textShadow: "-2px 2px 2px #171717",
      fontSize: "2.2rem",
      lineHeight: "2.2rem"
    },
    "@media (min-width:1280px)": {
      letterSpacing: "-2px",
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    },
    "& span": {
      // @ts-ignore
      color: "#e3e3e3"
    }
  },

  desc: {
    fontWeight: 400,
    color: "#ddd",
    fontSize: "0.9rem",
    textAlign: "center",
    lineHeight: "1rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: "0 10%",
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1rem",
      lineHeight: "1.1rem",
      padding: 0
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.1rem"
    }
  },
  moreWrapper: {
    "@media (min-width:600px)": {
      display: "flex",
      justifyContent: "flex-end",
      alignContent: "flex-end",
      alignItems: "flex-end",
      paddingBottom: theme.spacing(1)
    }
  },
  moreButton: {
    margin: 0,
    height: "42px",
    marginTop: theme.spacing(1),
    width: "90%",
    marginLeft: "5%",
    marginRight: "5%",
    fontSize: "1rem",
    padding: theme.spacing(2),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(0),
      marginLeft: "0",
      marginRight: "0",
      width: "auto",
      height: "48px",
      fontSize: "1rem",
      padding: theme.spacing(2, 6)
    },
    "@media (min-width:960px)": {
      fontSize: "1.1rem"
    }
  },
  bannerCloseIcon: {
    position: "absolute",
    right: 15,
    top: 15,
    color: theme.palette.common.white,
    width: "1em",
    height: "1em",
    "&:hover": {
      // @ts-ignore
      color: theme.palette.background.ffGreen,
      cursor: "pointer"
    },
    "@media (min-width:600px)": {
      width: "0.9em",
      height: "0.9em",
      right: 15,
      top: 14
    }
  }
}));

const FreeTrialLarge = () => {
  const classes = useStyles();

  const { userProfile } = useContext(AccountContext);

  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      return (
        <>
          <Grid container className={classes.background}>
            <Typography variant="h2" className={classes.titleLarge}>
              Your Free Trial has ended.
            </Typography>
          </Grid>
        </>
      );
    } else {
      // Render countdown
      return (
        <>
          <Grid container className={classes.innerBackground}>
            <Grid item xs={12} sm={8} md={6} className={classes.textContainer}>
              <Typography variant="h2" className={classes.title}>
                Your Free Trial ends in:
              </Typography>
              <Typography variant="h2" className={classes.titleLarge}>
                {days >= 1 && (
                  <>
                    {days.toString().length === 1 ? "" : ""}
                    {days}
                    <span>d</span>
                    <span style={{ color: "#fff" }}>{" : "}</span>
                  </>
                )}
                {hours.toString().length === 1 ? "0" : ""}
                {hours}
                <span>h</span>
                <span style={{ color: "#fff" }}>{" : "}</span>
                {minutes.toString().length === 1 ? "0" : ""}
                {minutes}
                <span>m</span>
                <span style={{ color: "#fff" }}>{" : "}</span>
                {seconds.toString().length === 1 ? "0" : ""}
                {seconds}
                <span>s</span>
              </Typography>
              {/* <Typography variant="body1" className={classes.desc}>
                You will have 24 hours to get onboard after your trial is over,
                after that you will be locked out until Christmas{" "}
                {dayjs().year()}.
              </Typography> */}
            </Grid>
            <Grid item xs={12} sm={4} md={6} className={classes.moreWrapper}>
              <Link to="/myaccount/join-now" style={{ textDecoration: "none" }}>
                <Button className={classes.moreButton}>Join Now</Button>
              </Link>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <div className={classes.background}>
        <Container className={classes.container}>
          <Countdown
            date={Date.parse(userProfile.subscriptionExpiryDate)}
            renderer={countdownRenderer}
            zeroPadTime={2}
            daysInHours={true}
          />
        </Container>
      </div>
    </>
  );
};

export default FreeTrialLarge;
