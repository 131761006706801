import "reflect-metadata";
import React from "react";
import { hydrate, render } from "react-dom";
import App from "./components/App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { ENABLE_ROLLBAR } from "./env";

// @ts-ignore
import * as rollbar from "@rollbar/react";
const rollbarConfig = {
  accessToken: "37fc5604296c4fd8bf4eb050bee02935",
  environment: "production",
  enabled: ENABLE_ROLLBAR
};

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <rollbar.Provider config={rollbarConfig}>
      <rollbar.ErrorBoundary>
        <App />
      </rollbar.ErrorBoundary>
    </rollbar.Provider>,
    rootElement
  );
} else {
  render(
    <rollbar.Provider config={rollbarConfig}>
      <rollbar.ErrorBoundary>
        <App />
      </rollbar.ErrorBoundary>
    </rollbar.Provider>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
