import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  UserProfileApplication,
  APPLICATION_SOURCE,
  APPLICATION_STATUS
} from "@booyaltd/core";
import {
  createApplication,
  submitApplication,
  updateApplicationEmailDetails,
  updateApplicationPhoneDetails,
  verifyApplicationEmailDetails,
  verifyApplicationPhoneDetails
} from "../api/client";
import { buildDeviceDetails } from "../utils/device";
import { AccountContext } from "./AccountContext";
import {
  MEMBER_STATUS_STANDARD,
  MEMBER_STATUS_TRIAL
} from "../api/entities/UserProfile";
import useLogger from "../hooks/useLogger";

export type InitialiseParams = {
  route: "try" | "buy" | "trybuy" | "giftcard" | undefined;
} & (
  | { source: APPLICATION_SOURCE.PUBLIC; code?: string; page: string }
  | { source: APPLICATION_SOURCE.GIFTCARD; code?: string; page: string }
  | {
      source: APPLICATION_SOURCE.REFERRAL;
      code: string;
      page: string;
    }
);

export enum Step {
  giftcard,
  loading,
  "phone-input-verify",
  "input-details",
  purchase,
  success
}

type StepLabel = { steps: Array<Step>; label: string };

type OnboardingContextType = {
  initialise: ({ source, code }: InitialiseParams) => Promise<void> | void;
  runWithLoader: (action: () => Promise<void>, force?: boolean) => void;
  application?: UserProfileApplication;
  stepLabels: StepLabel[];
  activeStep: Step;
  loading: boolean;
  error?: string;
  resetError(): void;
  setPhone(
    countryCode: string,
    phoneNumber: string,
    // sendMethod?: "sms" | "whatsapp",
    captchaToken?: string
  ): void;
  verifyPhone(code: string): void;
  setDetails(
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ): void;
  verifyEmail(code: string): void;
  submit(): void;
};

const OnboardingContext = createContext<OnboardingContextType>({
  initialise: () => {},
  runWithLoader: () => {},
  application: undefined,
  stepLabels: [],
  activeStep: Step.loading,
  loading: true,
  error: undefined,
  resetError: () => {},
  setPhone: () => {},
  verifyPhone: () => {},
  setDetails: () => {},
  verifyEmail: () => {},
  submit: () => {}
});

const OnboardingProvider = (props: PropsWithChildren<InitialiseParams>) => {
  const { logMetricEvent } = useLogger();
  const { userProfile, storeAuthResponse, validateLogin } = useContext(
    AccountContext
  );
  const [source, setSource] = useState<APPLICATION_SOURCE>();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string>();
  const [application, setApplication] = useState<UserProfileApplication>();

  const runWithLoader = useCallback(
    (action: () => Promise<void>, force: boolean = false) => {
      if (!force && loading) return;

      setError(undefined);
      setLoading(true);
      action()
        .catch(e => {
          if (typeof e === "string") {
            setError(e);
          } else if (e.toString && typeof e.toString === "function") {
            setError(e.toString());
          } else {
            setError("Unknown Error");
          }
        })
        .finally(() => setLoading(false));
    },
    [loading]
  );

  const initialise = useCallback(
    ({ source, code, page }: InitialiseParams) => {
      setSource(source);

      if (
        source !== APPLICATION_SOURCE.GIFTCARD ||
        (source === APPLICATION_SOURCE.GIFTCARD && typeof code === "string")
      ) {
        runWithLoader(async () => {
          setApplication(await createApplication({ source, code, page }));
        });
      }
    },
    [runWithLoader]
  );

  const resetError = useCallback(() => {
    setError(undefined);
  }, [setError]);

  const setPhone = useCallback(
    (countryCode: string, phoneNumber: string, captcha: string) => {
      if (!application) return;

      runWithLoader(async () => {
        setApplication(
          await updateApplicationPhoneDetails(application.id, {
            method: "sms",
            countryCode,
            phoneNumber,
            captcha
          })
        );
      });
    },
    [application, runWithLoader]
  );

  const verifyPhone = useCallback(
    async (code: string) => {
      if (!application) return;

      runWithLoader(async () => {
        setApplication(
          await verifyApplicationPhoneDetails(application.id, { code })
        );
      });
    },
    [application, runWithLoader]
  );

  const setDetails = useCallback(
    async (
      firstName: string,
      lastName: string,
      email: string,
      password: string
    ) => {
      if (!application) return;

      runWithLoader(async () => {
        setApplication(
          await updateApplicationEmailDetails(application.id, {
            firstName,
            lastName,
            email,
            password
          })
        );
      });
    },
    [application, runWithLoader]
  );

  const verifyEmail = useCallback(
    async (code: string) => {
      if (!application) return;

      runWithLoader(async () => {
        setApplication(
          await verifyApplicationEmailDetails(application.id, { code })
        );
      });
    },
    [application, runWithLoader]
  );

  const submit = useCallback(() => {
    if (!application || submitted) return;

    setSubmitted(true);

    runWithLoader(async () => {
      const {
        application: updatedApplication,
        authToken,
        refreshToken
      } = await submitApplication(application.id, {
        device: await buildDeviceDetails()
      });

      if (!application.phoneExists && !application.emailExists) {
        logMetricEvent({
          type: "sign_up",
          payload: { source: application.source }
        });
      }

      if (authToken && refreshToken) {
        await storeAuthResponse({ authToken, refreshToken });
      }

      await validateLogin();

      setApplication(updatedApplication);
    });
  }, [
    submitted,
    application,
    runWithLoader,
    storeAuthResponse,
    validateLogin,
    logMetricEvent
  ]);

  const stepLabels = useMemo((): StepLabel[] => {
    if (source === APPLICATION_SOURCE.GIFTCARD) {
      return [
        {
          steps: [Step["giftcard"]],
          label: "Giftcard"
        },
        {
          steps: [Step["phone-input-verify"]],
          label: "Phone"
        },
        {
          steps: [Step["input-details"]],
          label: "Login Details"
        }
      ];
    }

    if (!application) return [{ steps: [Step.loading], label: "" }];

    return [
      {
        steps: [Step["phone-input-verify"]],
        label: "Phone"
      },
      {
        steps: [Step["input-details"]],
        label: "Login Details"
      },
      {
        steps: [Step.purchase],
        label: props.route === "buy" ? "Purchase" : "Trial Ready"
      }
    ];
  }, [source, application, props.route]);

  const activeStep = useMemo<Step>(() => {
    if (!application && source === APPLICATION_SOURCE.GIFTCARD.valueOf())
      return Step.giftcard;

    if (!application) return Step.loading;

    const { status } = application;

    switch (status.toString()) {
      case APPLICATION_STATUS.CREATED.toString():
        return Step["phone-input-verify"];
      case APPLICATION_STATUS.PHONE_VERIFIED.toString():
        return Step["input-details"];
      case APPLICATION_STATUS.DETAILS_VERIFIED.toString():
      case APPLICATION_STATUS.CONVERTED.toString():
        if (!userProfile) return Step.loading;

        if (
          userProfile.memberStatusId === MEMBER_STATUS_STANDARD ||
          userProfile.memberStatusId === MEMBER_STATUS_TRIAL
        ) {
          return Step["success"];
        }

        return Step["purchase"];
      default:
    }

    return Step.loading;
  }, [application, source, userProfile]);

  // Only run on mount
  useEffect(() => {
    initialise(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !submitted &&
      !loading &&
      application &&
      application.status.toString() ===
        APPLICATION_STATUS.DETAILS_VERIFIED.toString()
    ) {
      submit();
    }
  }, [loading, application, props, submit, submitted]);

  const context: OnboardingContextType = {
    initialise,
    runWithLoader,
    application,
    stepLabels,
    activeStep,
    loading,
    error,
    resetError,
    setPhone,
    verifyPhone,
    setDetails,
    verifyEmail,
    submit
  };

  return (
    <OnboardingContext.Provider value={context}>
      {props.children}
    </OnboardingContext.Provider>
  );
};

export { OnboardingContext, OnboardingProvider };
