import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Box } from "@material-ui/core";
import { OverviewContext } from "../../store/OverviewContext";
import merchImg from "../../assets/merch.jpg";
import classnames from "classnames";
import giftcardGraphic from "./giftcard_graphic.png";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    "@media (min-width:600px)": {
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "center",
      // padding: theme.spacing(4),
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8)
    },
    margin: theme.spacing(0),
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy
  },
  section: {
    flex: 1,
    padding: theme.spacing(4),
    borderRadius: "8px",
    backgroundColor: "#292929",
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
    "&:last-child": {
      marginTop: theme.spacing(4)
    },
    "@media (min-width:600px)": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  },
  left: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(0)
    }
  },
  right: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "2rem",
      lineHeight: "2rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "2.5rem",
      lineHeight: "2.5rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "3rem",
    lineHeight: "3rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      fontSize: "4rem",
      lineHeight: "4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "4.5rem",
      lineHeight: "4.5rem"
    }
  },
  open: {
    // @ts-ignore
    color: theme.palette.background.ffGreen
  },
  closed: {
    // @ts-ignore
    color: theme.palette.error.main
  },
  buttonScaleOnHover: {
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s ease-in-out"
    }
  },
  button: {
    marginTop: theme.spacing(4),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "300px"
    }
  },
  secondaryButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.common.white,
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    border: "2px solid #fff",
    marginLeft: 0,
    marginRight: 0,
    "&:hover": {
      border: "2px solid #99ca3c",
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      width: "300px"
    }
  },
  pullButtonRight: {
    float: "right",
    alignSelf: "flex-end"
  },
  textContainer: {
    margin: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      margin: theme.spacing(1, 0)
    },
    "@media (min-width:960px)": {
      margin: theme.spacing(3, 0)
    }
  },
  featureTitle: {
    letterSpacing: "-1px",
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    fontWeight: 500,
    textShadow: "-1px 1px 1px #171717",
    marginBottom: theme.spacing(0.5),
    color: "#fff",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.6rem"
    },
    "@media (min-width:960px)": {
      letterSpacing: "-1px",
      fontSize: "1.8rem",
      lineHeight: "1.8rem",
      fontWeight: 500,
      textShadow: "-2px 2px 2px #171717",
      marginBottom: theme.spacing(1)
    }
  },
  featureDesc: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.1rem",
    "& a": {
      color: "#e9e9e9",
      "&:hover": {
        // @ts-ignore
        color: theme.palette.background.ffGreen
      }
    },
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.3rem"
    },
    "@media (min-width:960px)": {}
  },
  buttonSubText: {
    fontSize: "0.9rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    "@media (min-width:600px)": {
      width: "300px",
      textAlign: "center",
      marginLeft: 0
    },
    "@media (min-width:960px)": {}
  },
  merchImg: {
    width: "100%",
    height: "auto"
  },
  shopButton: {
    textDecoration: "underline",
    textDecorationThickness: "2px",
    boxShadow: "none",
    backgroundColor: "transparent",
    border: "none",
    // @ts-ignore
    color: theme.palette.common.white,
    transition: "none",
    margin: theme.spacing(1, 0),
    fontSize: "1.2rem",
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 500,
    "&:hover": {
      border: "none",
      backgroundColor: "transparent",
      textDecoration: "underline",
      textDecorationThickness: "2px",
      transition: "none",
      boxShadow: "none",
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      textAlign: "center",
      margin: theme.spacing(3, 0)
    },
    "@media (min-width:960px)": {
      textAlign: "left",
      margin: theme.spacing(3, 0)
    }
  },
  giftcardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (min-width:600px)": {
      flexDirection: "row",
      alignItems: "stretch"
    }
  },
  sectionBody: {
    textAlign: "center",
    fontSize: "1.2rem",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      textAlign: "center",
      fontSize: "1.4rem",
      lineHeight: "1.5rem"
    },
    "@media (min-width:960px)": {
      textAlign: "left",
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  giftcardTextHeading: {
    // @ts-ignore
    color: theme.palette.background.ffGreen
  },
  giftcardGraphic: {
    width: "70%",
    margin: "0 15% 20px 15%",
    "@media (min-width:600px)": {
      width: "70%",
      margin: "0 15% 20px 15%"
    },
    "@media (min-width:960px)": {
      width: "60%",
      margin: "0 20% 20px 20%"
    },
    "@media (min-width:1200px)": {
      width: "70%",
      margin: "0 15% 20px 15%"
    }
  }
}));

const Upsells = () => {
  const classes = useStyles();

  const { overview } = useContext(OverviewContext);

  return (
    <>
      {overview && (
        <div className={classes.container}>
          <Box className={classnames(classes.section, classes.left)}>
            <Box>
              <Typography variant="h2" className={classes.title}>
                Have you seen our
              </Typography>
              <Typography variant="h2" className={classes.titleLarge}>
                Merch?
              </Typography>
            </Box>

            <Box paddingRight={4}>
              <a
                href="https://shop.farmflix.tv/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.merchImg}
                  src={merchImg}
                  alt="Merch Banner"
                />
              </a>
            </Box>

            <Button
              className={classnames(
                classes.button,
                classes.pullButtonRight,
                classes.buttonScaleOnHover
              )}
              href="https://shop.farmflix.tv/"
              target="_blank"
              rel="noreferrer"
            >
              Buy Merch
            </Button>
          </Box>

          <Box className={classnames(classes.right)}>
            <Box className={classes.section}>
              <Box className={classes.giftcardContainer}>
                <Box>
                  <Typography variant="h2" className={classes.title}>
                    Want to
                  </Typography>
                  <Typography variant="h2" className={classes.titleLarge}>
                    Gift FarmFLiX?
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classnames(
                      classes.sectionBody,
                      classes.giftcardTextHeading
                    )}
                  >
                    Delivered instantly via email
                  </Typography>
                </Box>
                <Box>
                  <a
                    href="https://shop.farmflix.tv/collections/digital-collection"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={giftcardGraphic}
                      alt="FarmFLiX Giftcard"
                      className={classes.giftcardGraphic}
                    />
                  </a>
                </Box>
              </Box>

              <Button
                variant="outlined"
                href="https://shop.farmflix.tv/collections/digital-collection"
                rel="noopener noreferrer"
                target="_blank"
                style={{ textDecoration: "none" }}
                className={classnames(
                  classes.secondaryButton,
                  classes.pullButtonRight,
                  classes.buttonScaleOnHover
                )}
              >
                Buy a Giftcard
              </Button>
            </Box>

            <Box className={classes.section}>
              <Typography variant="h2" className={classes.title}>
                Got a
              </Typography>
              <Typography variant="h2" className={classes.titleLarge}>
                Giftcard?
              </Typography>
              <Typography variant="body1" className={classes.sectionBody}>
                Get started in 2 simple steps
              </Typography>
              <Button
                href="/usemycode"
                variant="outlined"
                className={classnames(
                  classes.secondaryButton,
                  classes.pullButtonRight,
                  classes.buttonScaleOnHover
                )}
              >
                Redeem a Giftcard
              </Button>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default Upsells;
