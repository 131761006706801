import React from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { CatalogOverviewResponse } from "@booyaltd/core";
import { makeStyles } from "@material-ui/core/styles";
import { SubscriptionPriceCard } from "../BuyNow/SubscriptionPriceCard";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(6, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0, 4, 0)
    }
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      fontSize: "4.2rem",
      lineHeight: "4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },
  open: {
    // @ts-ignore
    color: theme.palette.background.ffGreen
  },
  closed: {
    // @ts-ignore
    color: theme.palette.error.main
  },
  button: {
    marginTop: theme.spacing(4),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "300px"
    }
  },
  secondaryButton: {
    marginTop: theme.spacing(4),
    color: theme.palette.common.white,
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    border: "2px solid #fff",
    marginLeft: 0,
    marginRight: 0,
    "&:hover": {
      border: "2px solid #99ca3c",
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      width: "300px"
    }
  },
  textContainer: {
    margin: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      margin: theme.spacing(1, 0)
    },
    "@media (min-width:960px)": {
      margin: theme.spacing(3, 0)
    }
  },
  featureTitle: {
    letterSpacing: "-1px",
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    fontWeight: 500,
    textShadow: "-1px 1px 1px #171717",
    marginBottom: theme.spacing(0.5),
    color: "#fff",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.6rem"
    },
    "@media (min-width:960px)": {
      letterSpacing: "-1px",
      fontSize: "1.8rem",
      lineHeight: "1.8rem",
      fontWeight: 500,
      textShadow: "-2px 2px 2px #171717",
      marginBottom: theme.spacing(1)
    }
  },
  featureDesc: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.1rem",
    "& a": {
      color: "#e9e9e9",
      "&:hover": {
        // @ts-ignore
        color: theme.palette.background.ffGreen
      }
    },
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.3rem"
    },
    "@media (min-width:960px)": {}
  },
  buttonSubText: {
    fontSize: "0.9rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    "@media (min-width:600px)": {},
    "@media (min-width:960px)": {}
  },
  left: {
    marginBottom: "32px !important",
    paddingBottom: "32px !important",
    borderBottom: "5px solid #292929",
    borderRight: "0",
    "@media (min-width:960px)": {
      borderBottom: "0",
      borderRight: "5px solid #292929",
      marginBottom: theme.spacing(0)
    }
  },
  right: {
    padding: "8px !important",
    "@media (min-width:960px)": {
      padding: "8px 8px 8px 24px !important"
    }
  }
}));

export type TryBuyCtaProps = {
  overview: CatalogOverviewResponse | undefined;
};

/*
 - Doors Open & Trials Open - show Free Trial
 - Doors Closed & Trials Open - show Free Trial
 - Doors Open & Trials Closed - show Join Now
 - Doors Closed & Trials Closed - show Membership Closed
*/
export const TryBuyCta = ({ overview }: TryBuyCtaProps) => {
  const classes = useStyles();

  if (!overview?.doors?.open) {
    return null;
  }

  return (
    <Box className={classes.background}>
      <Container maxWidth="lg" fixed>
        <Grid container direction="row">
          <Grid item xs={12} md={6}>
            <Typography variant="h2" className={classes.titleLarge}>
              Pricing
            </Typography>
            <Typography variant="h2" className={classes.title}>
              <span className={classes.open}>Membership is open.</span>
            </Typography>
            {/*<Typography variant="body1" className={classes.buttonSubText}>*/}
            {/*  Discounted annual plan available for a Limited Time.{" "}*/}
            {/*  <b>{"Don't Miss Out - Join Now!"}</b>*/}
            {/*</Typography>*/}
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="row" flex={1}>
              <SubscriptionPriceCard
                title="Monthly"
                productId="monthly799"
                duration="monthly"
                gbpPrice={7.99}
                eurPrice={8.99}
                usdPrice={9.99}
                isPrimary={true}
              />
              {/*<SubscriptionPriceCard*/}
              {/*  title="Yearly"*/}
              {/*  productId="annual75"*/}
              {/*  duration="yearly"*/}
              {/*  gbpPrice={75}*/}
              {/*  eurPrice={90}*/}
              {/*  usdPrice={95}*/}
              {/*  isPrimary={true}*/}
              {/*/>*/}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
