import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import Footer from "../../Footer/Footer";
import HeaderInternal from "../../Header/HeaderInternal";
import SuccessAppNew from "../../Banners/SuccessAppNew";

const useStyles = makeStyles(theme => ({
  trialHeader: {
    backgroundColor: theme.palette.background.ffGreen,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    }
  },
  trialTitle: {
    color: theme.palette.background.ffGreen,
    fontSize: "3rem",
    lineHeight: "2.8rem",
    fontWeight: 500,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    textAlign: "center",
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "3.2rem",
      lineHeight: "3rem",
      marginBottom: theme.spacing(1)
    },
    "@media (min-width:960px)": {
      fontSize: "4rem",
      lineHeight: "3.8rem"
    }
  },
  trialSubTitle: {
    fontSize: "1.4rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1.8rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    }
  },
  playerBackground: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.upgradeBG,
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    marginBottom: theme.spacing(2)
  },
  player: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    "& video": {
      "&:focus": {
        outline: "none"
      }
    }
  },
  playIcon: {
    fontSize: "6rem",
    "@media (min-width:600px)": {
      fontSize: "7rem"
    },
    "@media (min-width:960px)": {
      fontSize: "8rem"
    }
  },
  bodyText: {
    "& a": {
      color: "#fff"
    }
  },
  marginSpacing: {
    margin: "20px 0"
  },
  dashboardButton: {
    width: "100%",
    margin: "20px 0 0 0",
    padding: "12px 20px",
    "@media (min-width:600px)": {
      width: "auto",
      padding: "12px 30px"
    },
    "@media (min-width:960px)": {
      width: "auto",
      padding: "12px 30px"
    }
  },
  appBackground: {
    backgroundColor: theme.palette.background.ffNavy,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: "center"
  }
}));

export default function Success() {
  const classes = useStyles();

  return (
    <div>
      <HeaderInternal />
      <div className={classes.playerBackground}>
        <Container fixed fixedcomponent="main" maxWidth="md">
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                component="h1"
                variant="h1"
                className={classes.trialTitle}
              >
                Woo Hoo!
              </Typography>
              <Typography
                component="h2"
                variant="h2"
                className={classes.trialSubTitle}
              >
                Welcome to FarmFLiX!
              </Typography>
              <Typography variant="body2" className={classes.bodyText}>
                Your FarmFLiX Membership is now active - get stuck in and enjoy
                our content.
              </Typography>
              <br />
              <Typography variant="body2" className={classes.bodyText}>
                Feel free to reach out to us with questions at anytime! Right
                now, have a browse through out back catalogue, or why not
                install our new App and browse from your mobile device.
              </Typography>
              <br />
              <Typography variant="body2" className={classes.bodyText}>
                Enjoy,
              </Typography>
              <Typography variant="body2" className={classes.bodyText}>
                <b>John &amp; The FarmFLiX Crew</b>
              </Typography>
              <a href="/videos" style={{ textDecoration: "none" }}>
                <Button
                  name="get-started"
                  className={classes.dashboardButton}
                  size="medium"
                >
                  Get Started Now
                </Button>
              </a>
              <Typography
                variant="body2"
                className={classes.bodyText}
                style={{ marginTop: "16px" }}
              >
                <b>We&apos;re here to help!</b>
                <br />
                If you have any questions or issues, simply use our Help Widget
                on this page or contact us at{" "}
                <a href="mailto:info@farmflix.tv">info@farmflix.tv</a>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <SuccessAppNew />
      <Footer />
    </div>
  );
}
