import React, { useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import { ShareContext } from "./ShareContainer";
import ShareSteps from "./ShareSteps";
import useStyles from "./styles";
import CloseIcon from "@material-ui/icons/Close";

const ShareModal = () => {
  const classes = useStyles();
  const { setDialogOpen } = useContext(ShareContext);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={() => setDialogOpen(false)}
      disableBackdropClick
      aria-labelledby="form-dialog-title"
      style={{ textAlign: "center" }}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Get your mates into FarmFLiX
        <IconButton
          onClick={() => setDialogOpen(false)}
          style={{ position: "absolute", top: "5px", right: "5px" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ShareSteps />
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default ShareModal;
