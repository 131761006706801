import React, { useState, useContext, useEffect, useCallback } from "react";
import { AccountContext } from "./../../store/AccountContext";
import { Grid, Typography, Button, Box, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Form } from "react-final-form";
import { useToasts } from "react-toast-notifications";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import ReactCodeInput from "react-verification-code-input";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  bodyText: {
    color: theme.palette.background.ffNavy,
    "& span": {
      fontWeight: 500,
      fontSize: "1.3rem"
    }
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    "& span": {
      fontWeight: 500
    }
  },
  formContainer: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  inputField: {
    "& .react-tel-input": {
      marginTop: 0,
      marginBottom: theme.spacing(1)
    },
    "& .react-tel-input .flag-dropdown:before": {
      display: "none !important"
    },
    "& .react-tel-input .form-control": {
      border: "1px solid #a5a5a5 !important",
      backgroundColor: "#f7f7f7 !important",
      fontSize: "16px !important",
      borderRadius: "5px !important",
      width: "100% !important"
    }
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "5% 0 2% 0"
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    color: theme.palette.background.ffNavy,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  },
  verificationCodeContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: "100% !important",
    fontWeight: "600",
    "& input": {
      height: "56px !important",
      width: "15.6% !important",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderTopLeftRadius: "0px !important",
      borderBottomLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
      borderBottom: 0,
      border: "1px solid #a5a5a5 !important",
      backgroundColor: "#f7f7f7 !important",
      margin: "0 0.5%",
      fontSize: "2rem",
      fontWeight: 500,
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
      "&:focus": {
        border: "1px solid green",
        caretColor: "green"
      },
      "&:last-child": {
        borderRight: "none"
      }
    }
  },
  submit: {
    margin: "10px 0",
    width: "100%",
    "@media (min-width:600px)": {
      marginTop: 0,
      marginRight: theme.spacing(1.5),
      width: "auto"
    }
  },
  validatonError: {
    marginBottom: theme.spacing(2)
  }
}));

export default function ChangePhone() {
  const classes = useStyles();
  const { addToast } = useToasts();

  // Account Context
  const {
    userProfile,
    update,
    updating,
    updateError,
    getVerificationCode,
    waitingOnVerificationCode,
    validateLogin,
    resetLoginWithPhone,
    loading
  } = useContext(AccountContext);

  // State
  const [verificationCode, setVerificationCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(userProfile?.phoneNumber);
  const [countryCode, setCountryCode] = useState(userProfile?.countryCode);
  const onChange = (value, { dialCode }) => {
    setPhoneNumber(value.substring(dialCode.length));
    setCountryCode(dialCode);
  };

  useEffect(() => {
    resetLoginWithPhone();
    // eslint-disable-next-line
  }, []);

  const onSubmitMobile = useCallback(async () => {
    if (!countryCode || !phoneNumber) {
      addToast("You must enter a new mobile number", {
        appearance: "error"
      });
      return;
    }

    if (
      userProfile.countryCode === countryCode &&
      userProfile.phoneNumber === phoneNumber
    ) {
      addToast(
        "You're new phone number must be different from you current number!",
        {
          appearance: "error"
        }
      );
      return;
    }

    try {
      await getVerificationCode({ countryCode, phoneNumber });
    } catch (e) {
      addToast("Oops! There was an error sending the verification code", {
        appearance: "error"
      });
    }
  }, [addToast, getVerificationCode, phoneNumber, countryCode, userProfile]);

  // Submit Verification Code
  const onSubmitCode = useCallback(async () => {
    try {
      await update({
        countryCode: countryCode,
        phoneNumber: phoneNumber,
        phoneVerificationCode: verificationCode,
        phoneNumberChanges: userProfile.phoneNumberChanges + 1
      });
      validateLogin();
      addToast("Phone updated successfully!", {
        appearance: "success"
      });
    } catch (e) {
      addToast("Oops! There was an problem updating your phone", {
        appearance: "error"
      });
    }
  }, [
    validateLogin,
    addToast,
    update,
    phoneNumber,
    countryCode,
    verificationCode,
    userProfile
  ]);

  return (
    <>
      {userProfile && userProfile.countryCode && userProfile.phoneNumber && (
        <>
          {userProfile.phoneNumberChanges <= 5 ? (
            <>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    className={classes.breadcrumbContainer}
                  >
                    <Link
                      className={classes.activeBreadcrumb}
                      to="/myaccount/overview"
                    >
                      Overview
                    </Link>
                    <Typography className={classes.disabledBreadcrumb}>
                      Change Phone
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="h2"
                    className={classes.myAccountSubTitle}
                  >
                    Change Phone
                  </Typography>
                </Grid>
                <Grid container className={classes.formContainer}>
                  {!updating && (
                    <>
                      <Grid item xs={12} sm={8} md={6}>
                        {!waitingOnVerificationCode && (
                          <>
                            {loading && (
                              <Box m={5} align="center">
                                <CircularProgress variant="indeterminate"></CircularProgress>
                                <Typography
                                  variant="body2"
                                  className={classes.bodyTextSml}
                                >
                                  <br />
                                  Sending verification code...
                                </Typography>
                              </Box>
                            )}
                            {!loading && (
                              <>
                                {userProfile &&
                                  userProfile.countryCode &&
                                  userProfile.phoneNumber && (
                                    <>
                                      <Typography
                                        variant="body1"
                                        className={classes.bodyText}
                                        style={{ marginBottom: "24px" }}
                                      >
                                        Enter your new mobile phone number
                                        below.
                                      </Typography>
                                      <Form
                                        onSubmit={onSubmitMobile}
                                        initialValues={{
                                          countryCode: userProfile.countryCode,
                                          phoneNumber: userProfile.phoneNumber
                                        }}
                                      >
                                        {props => (
                                          <form onSubmit={props.handleSubmit}>
                                            <Grid container spacing={2}>
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                className={classes.inputField}
                                              >
                                                {updateError && (
                                                  <Alert
                                                    severity="error"
                                                    variant="filled"
                                                    style={{
                                                      marginBottom: "12px"
                                                    }}
                                                  >
                                                    {updateError}
                                                  </Alert>
                                                )}
                                                <PhoneInput
                                                  inputProps={{
                                                    name: "mobileNumber",
                                                    required: true,
                                                    autoFocus: false
                                                  }}
                                                  placeholder="Mobile Number"
                                                  aria-label="Mobile Number"
                                                  preferredCountries={[
                                                    "gb",
                                                    "ie",
                                                    "us",
                                                    "au",
                                                    "nz"
                                                  ]}
                                                  excludeCountries={["je"]}
                                                  onChange={onChange}
                                                  enableTerritories={true}
                                                  disabled={updating}
                                                  countryCodeEditable={false}
                                                  value={
                                                    userProfile.countryCode +
                                                    userProfile.phoneNumber
                                                  }
                                                />
                                              </Grid>
                                              <Grid item xs={12} sm={12}>
                                                <Button
                                                  className={classes.submit}
                                                  type="submit"
                                                  disabled={updating}
                                                >
                                                  Update Mobile
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </form>
                                        )}
                                      </Form>
                                    </>
                                  )}
                                {!userProfile.countryCode &&
                                  !userProfile.phoneNumber && (
                                    <Typography
                                      variant="body1"
                                      className={classes.bodyText}
                                      style={{ marginBottom: "24px" }}
                                    >
                                      You need to add a phone.
                                    </Typography>
                                  )}
                              </>
                            )}
                          </>
                        )}
                        {waitingOnVerificationCode && (
                          <>
                            <Typography
                              variant="body1"
                              className={classes.bodyText}
                              align="left"
                              style={{ marginBottom: "24px" }}
                            >
                              We need to know that it&apos;s really you so
                              we&apos;ve sent you a verification code.
                              <br />
                              <br />
                              Please enter the code sent to:
                              <br />
                              <br />
                              <span>
                                +{countryCode}
                                {phoneNumber}
                              </span>
                            </Typography>
                            <Grid item xs={12} sm={12}>
                              {updateError && (
                                <Alert
                                  severity="error"
                                  variant="filled"
                                  className={classes.validatonError}
                                >
                                  {updateError}
                                </Alert>
                              )}
                            </Grid>
                            <>
                              <Grid item xs={12} sm={12}>
                                <ReactCodeInput
                                  className={classes.verificationCodeContainer}
                                  type="number"
                                  fields={6}
                                  value={verificationCode}
                                  onComplete={e => setVerificationCode(e)}
                                  disabled={updating}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Button
                                  size="medium"
                                  className={classes.submit}
                                  onClick={onSubmitCode}
                                >
                                  Verify Phone
                                </Button>

                                <Button
                                  size="medium"
                                  variant="outlined"
                                  className={classes.submit}
                                  onClick={onSubmitMobile}
                                >
                                  Resend Code
                                </Button>
                              </Grid>
                            </>
                          </>
                        )}
                        <Divider className={classes.footerDivider} />
                        <Typography
                          variant="body1"
                          className={classes.bodyTextSml}
                        >
                          <span>Tip</span> - Did you know you can log in with
                          your Mobile Number?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={5}></Grid>
                    </>
                  )}
                  {updating && (
                    <Box m={5} align="center">
                      <CircularProgress variant="indeterminate"></CircularProgress>
                      <Typography
                        variant="body2"
                        className={classes.bodyTextSml}
                      >
                        <br />
                        Updating your Phone Number. Please do not refresh your
                        browser.
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="h2"
                    className={classes.myAccountSubTitle}
                  >
                    Change Phone
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.bodyText}
                    style={{ marginBottom: "24px" }}
                  >
                    You have exceeded the number of times you can change your
                    phone number. Please contact support or email{" "}
                    <a
                      style={{ color: "#171717", fontWeight: "600" }}
                      href="mailto:info@farmflix.tv"
                    >
                      info@farmflix.tv
                    </a>{" "}
                    for help.
                  </Typography>
                  <Link
                    to="/myaccount/overview"
                    style={{ textDecoration: "none" }}
                  >
                    <Button size="medium">My Account</Button>
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
