import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: "16px 50px",
    backgroundColor: "#eee",
    "& h2": {
      lineHeight: "1.1"
    }
  },
  dialogContent: {
    padding: "20px"
  },
  dialogContentText: {
    color: "#6f6f6f",
    marginBottom: "0"
  },
  "&.MuiTooltip-popper": {
    zIndex: 1
  },
  shareButton: {
    marginRight: theme.spacing(1),
    width: 56,
    "@media (min-width:600px)": {
      width: 140
    }
  },
  shareText: {
    fontSize: "1.2rem",
    display: "block",
    color: theme.palette.common.white,
    fontWeight: 500,
    marginTop: theme.spacing(1)
  },

  buttonIcon: {
    paddingTop: "0.1rem",
    fontSize: "1.6rem",
    borderRadius: "50%",
    margin: "0",
    color: "#171717",
    cursor: "pointer",
    "@media (min-width:960px)": {
      fontSize: "1.6rem"
    }
  },
  generateButton: {
    width: "100%",
    marginTop: "16px",
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  dismissedButtonIcon: {
    paddingTop: "0.1rem",
    fontSize: "1.6rem",
    borderRadius: "50%",
    margin: "0",
    color: "#fff",
    cursor: "pointer",
    "&:hover": {
      color: "#99ca3c"
    },
    "@media (min-width:600px)": {
      margin: "4px 16px 0px 0px"
    }
  },
  shareLinksContainer: {
    flex: 1,
    flexGrow: 1,
    flexDirection: "column",
    width: "100%"
  },
  iconContainer: {
    padding: "0px 10px"
  },
  loadingSpinner: {},
  errorAlert: {},
  shareInput: {
    "& input": {
      "&:disabled": {
        backgroundColor: "#eee",
        border: "1px solid #ccc"
      }
    }
  },
  shareInputCopy: {
    minWidth: "39px",
    height: "39px",
    padding: 0,
    borderRadius: "50%",
    backgroundColor: "#c5c5c5",
    border: "#c5c5c5",
    color: "#fff",
    margin: "7px 0",
    "&:hover": {
      backgroundColor: "#c5c5c5",
      border: "#c5c5c5",
      color: "#fff"
    }
  },
  shareInputIconContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "space-around",
    justifyContent: "space-around"
  },
  shareInputIcon: {
    paddingRight: theme.spacing(1)
  },
  tooltipHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "space-between",
    alignItems: "center",
    "& h6": {
      fontSize: "1rem",
      marginBottom: "8px"
    }
  },
  tooltipClose: { color: "inherit" },
  tooltipMessage: {
    textAlign: "center",
    paddingBottom: theme.spacing(1),
    cursor: "pointer",
    margin: 0
  },
  tooltipDismiss: {
    cursor: "pointer",
    textAlign: "center",
    textDecoration: "underline",
    "&:hover": {
      color: "#99ca3c"
    }
  },
  pulsingFab: {
    animation: "pulse 2s infinite",
    height: "46px",
    width: "46px",
    margin: "4px 0px 0px 0px",
    // marginRight: "12px",
    "&:hover": {
      backgroundColor: "#a2d641",
      color: "#fff"
    },
    "@media (min-width:600px)": {
      margin: "4px 16px 0px 0px"
    }
  },
  "@global": {
    "@keyframes pulse": {
      "0%": {
        opacity: 0.9,
        transform: "scale(0.95)"
      },
      "70%": {
        opacity: 1,
        transform: "scale(1)"
      },
      "100%": {
        opacity: 0.9,
        transform: "scale(0.95)"
      }
    }
  },
  menuLinkContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center"
  },
  menuLinkText: {
    textAlign: "center",
    marginBottom: theme.spacing(1)
  },
  menuLinkButton: {
    marginBottom: theme.spacing(1)
  }
}));

export default useStyles;
