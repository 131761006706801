import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid, Button } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    // backgroundColor: "#545454",
    position: "relative",
    padding: theme.spacing(3),
    "@media (min-width:600px)": {
      padding: theme.spacing(4, 4)
    }
  },
  container: {
    // padding: 0
  },
  title: {
    letterSpacing: "-1px",
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "2rem",
      lineHeight: "2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-1px",
    fontSize: "1.5rem",
    lineHeight: "1.7rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:1280px)": {
      letterSpacing: "-2px",
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    },
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    }
  },

  textContainer: {},
  descHeavy: {
    color: theme.palette.common.white,
    letterSpacing: "-1px",
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    textShadow: "-1px 1px 1px #171717",
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      marginTop: theme.spacing(0.5),
      lineHeight: "1.1rem"
    },
    "@media (min-width:960px)": {
      letterSpacing: "-1px",
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
      fontWeight: 500,
      textShadow: "-2px 2px 2px #171717"
    }
  },
  desc: {
    fontWeight: 400,
    color: "#ddd",
    fontSize: "1rem",
    lineHeight: "1rem",
    marginTop: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.1rem",
      lineHeight: "1.3rem"
    }
  },
  moreWrapper: {
    "@media (min-width:600px)": {
      display: "flex",
      justifyContent: "flex-end",
      alignContent: "flex-end",
      alignItems: "flex-end",
      paddingBottom: theme.spacing(1)
    }
  },
  moreButton: {
    margin: 0,
    height: "42px",
    marginTop: theme.spacing(1),
    width: "100%",
    fontSize: "1rem",
    padding: theme.spacing(2),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(0),
      width: "auto",
      height: "48px",
      fontSize: "1rem",
      padding: theme.spacing(2, 6)
    },
    "@media (min-width:960px)": {
      fontSize: "1.1rem"
    }
  },
  bannerCloseIcon: {
    position: "absolute",
    right: 15,
    top: 15,
    color: theme.palette.common.white,
    width: "1em",
    height: "1em",
    "&:hover": {
      // @ts-ignore
      color: theme.palette.background.ffGreen,
      cursor: "pointer"
    },
    "@media (min-width:600px)": {
      width: "0.9em",
      height: "0.9em",
      right: 15,
      top: 14
    }
  }
}));

const JobVacancy = ({ fullWidth }: { fullWidth: boolean }) => {
  const classes = useStyles();

  const [hiringSectionOpen, setHiringSectionOpen] = useState(false);

  setTimeout(() => {
    if (localStorage.getItem("dismissHiringSection") === null) {
      setHiringSectionOpen(true);
    } else {
      setHiringSectionOpen(false);
      return null;
    }
  }, 1000);

  const handleClose = () => {
    setHiringSectionOpen(false);
    const dismissHiringSection = {
      value: "true",
      expiry: Date.now() + 60000
    };
    localStorage.setItem(
      "dismissHiringSection",
      JSON.stringify(dismissHiringSection)
    );
    setHiringSectionOpen(false);
  };

  return (
    <>
      {hiringSectionOpen && (
        <>
          <div className={fullWidth ? classes.background : ""}>
            <Container style={{ padding: fullWidth ? 0 : "" }}>
              <Grid container className={!fullWidth ? classes.background : ""}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  className={classes.textContainer}
                >
                  <Typography variant="h2" className={classes.title}>
                    We Are Hiring
                  </Typography>
                  <Typography variant="h2" className={classes.titleLarge}>
                    <span>- </span>Videographer
                  </Typography>
                  <Typography variant="h2" className={classes.titleLarge}>
                    <span>- </span>Marketer
                  </Typography>
                  <Typography variant="h2" className={classes.titleLarge}>
                    <span>- </span>Software Developer
                  </Typography>
                  <Typography variant="body1" className={classes.desc}>
                    We are looking for talented individuals with outstanding
                    skills to join our team.
                  </Typography>
                  <Typography variant="h3" className={classes.descHeavy}>
                    Have you got what it takes?
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  className={classes.moreWrapper}
                >
                  <Link
                    to="/careers"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button className={classes.moreButton}>More info</Button>
                  </Link>
                </Grid>
                <CloseRoundedIcon
                  onClick={handleClose}
                  className={classes.bannerCloseIcon}
                />
              </Grid>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default JobVacancy;
