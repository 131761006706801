import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Hidden
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Element, scroller } from "react-scroll";
import ItemsCarousel from "react-items-carousel";
// Social Icons
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { Helmet } from "react-helmet";
import { OverviewContext } from "../store/OverviewContext";
// Import AgLife Logo
import aglife_logo from "../assets/AgLifeFarmingForeverLogo.png";
// Import FarmFLiX Logo
import farmflix_logo from "../assets/FarmFLiXLogoWhite.png";

const useStyles = makeStyles(theme => ({
  topBackground: {
    background: "#2D56A6",
    height: "80vh",
    textAlign: "center",
    "@media (min-width:600px)": {
      height: "80vh"
    }
  },
  topContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "80vh",
    padding: theme.spacing(0, 4),
    "@media (min-width:600px)": {
      height: "80vh"
      // padding: theme.spacing(10)
    },
    "@media (min-width:800px)": {}
  },
  mainLogo: {
    width: "100%",
    margin: "0 auto",
    "@media (min-width:600px)": {
      width: "80%"
    },
    "@media (min-width:960px)": {
      width: "60%"
    }
  },
  blurbHeading: {
    fontWeight: 400,
    letterSpacing: "-1px",
    fontSize: "1.8rem",
    textShadow: "-1px 1px #383838",
    color: "#fff",
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      textShadow: "-2px 2px #383838",
      fontSize: "2rem"
    },
    "@media (min-width:800px)": {
      fontSize: "2.2rem"
    }
  },
  blurbSubHeading: {
    textShadow: "-1px 1px #383838",
    fontWeight: 400,
    letterSpacing: "-1px",
    fontSize: "2rem",
    color: "#fff",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      textShadow: "-2px 2px #383838",
      fontSize: "2.5rem"
    },
    "@media (min-width:800px)": {
      fontSize: "3rem"
    }
  },
  blurbBackground: {
    backgroundColor: "#234993",
    margin: theme.spacing(1, 1, 2, 1),
    padding: theme.spacing(4),
    "@media (min-width:600px)": {
      padding: theme.spacing(3)
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(4)
    }
  },
  merchBackground: {
    background: "#fff",
    textAlign: "center",
    padding: theme.spacing(5, 0)
  },
  merchContent: {
    padding: theme.spacing(5, 0)
  },
  merchHeading: {
    fontWeight: 600,
    fontSize: "2rem",
    color: "#2D56A6",
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "2.5rem"
    },
    "@media (min-width:800px)": {
      fontSize: "3rem"
    }
  },
  bodyText: {
    fontWeight: 400,
    color: "#eee",
    fontSize: "1.2rem",
    lineHeight: "1.3rem",
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:800px)": {
      fontSize: "1.4rem",
      lineHeight: "1.5rem"
    }
  },
  bodyTextSml: {
    fontWeight: 400,
    color: "#eee",
    fontSize: "1rem",
    lineHeight: "1.1rem",
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.1rem"
    },
    "@media (min-width:800px)": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem"
    }
  },
  button: {
    backgroundColor: "#0d9488",
    borderColor: "#0d9488",
    color: "#fff",
    fontSize: "1.1rem",
    maxWidth: "200px",
    borderRadius: "30px",
    padding: "16px 40px",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: "#0f766e",
      borderColor: "#0f766e"
    }
  },
  chevron: {
    color: "#e9e9e9",
    cursor: "pointer",
    fontSize: "1.4rem",
    height: "1em",
    width: "1em",
    borderRadius: "50%",
    padding: theme.spacing(1),
    backgroundColor: "rgba(2,2,2,0.8)",
    "&:hover": {
      color: theme.palette.common.white
    }
  },
  carouselWrapper: {
    marginTop: theme.spacing(2)
  },
  desktopCarouselMerchItemContainer: {
    height: "600px",
    display: "block",
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  desktopCarouselMerchItemImageContainer: {
    height: "500px",
    display: "block",
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  desktopCarouselMerchItemDetailsContainer: {
    maxHeight: "200px",
    marginTop: theme.spacing(1),
    textAlign: "left",
    borderTop: "1px solid #585858",
    paddingTop: theme.spacing(1)
  },
  desktopCarouselMerchItemContainerBottomGradient: {
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(23,23,23,1) 250%)",

    height: "300px",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "500px"
    }
  },
  desktopCarouselItemDetails: {
    position: "absolute",
    bottom: "20px",
    left: "15px",
    right: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "1rem"
  },
  desktopCarouselItemDetailsWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  desktopCarouselMerchItemTitle: {
    fontSize: "2.2rem",
    textAlign: "left",
    marginBottom: theme.spacing(0.2),
    fontWeight: 500,
    letterSpacing: "-1px",
    color: "#5e5e5e",
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem"
    }
  },
  desktopCarouselMerchItemPrice: {
    fontSize: "0.9rem",
    fontWeight: 400,
    color: "#8f8f8f"
  },
  mobileTabletContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    overflow: "auto",
    whiteSpace: "nowrap",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },

  mobileTabletMerchItem: {
    width: "160px",
    height: "auto",
    position: "relative",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    verticalAlign: "top",
    marginRight: theme.spacing(1),
    whiteSpace: "initial",
    padding: 0,
    margin: 0
  },
  mobileTabletMerchItemImageContainer: {
    position: "relative"
  },
  mobileTabletMerchItemImage: {
    width: "160px",
    height: "auto",
    aspectRatio: "9/16"
  },
  mobileTabletMerchItemDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    textAlign: "left",
    borderTop: "1px solid #585858",
    paddingTop: theme.spacing(0.5)
  },
  mobileTabletMerchItemTitle: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0),
    textAlign: "left",
    fontSize: "1.1rem",
    paddingRight: "5px",
    fontWeight: 500,
    letterSpacing: "-1px",
    color: "#5e5e5e",
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.1rem"
    }
  },
  mobileTabletMerchPrice: {
    marginTop: theme.spacing(0),
    fontSize: "0.9rem",
    marginBottom: "2px",
    paddingRight: "10px",
    fontWeight: 400,
    color: "#8f8f8f",
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.4rem"
    }
  },
  footerBackground: {
    textAlign: "center",
    background: "#2D56A6",
    padding: theme.spacing(6, 0)
  },
  footerHeading: {
    fontWeight: 500,
    fontSize: "1rem",
    color: "#fff",
    marginBottom: theme.spacing(1)
  },
  footerSubHeading: {
    fontWeight: 400,
    fontSize: "0.9rem",
    color: "#fff",
    marginTop: theme.spacing(2)
  },
  socialWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: theme.spacing(1)
  },
  socialIcon: {
    color: "#fff",
    height: "2.2rem",
    width: "2.2rem",
    margin: theme.spacing(0, 1),
    "&:hover": {
      color: "#87a9ed",
      cursor: "pointer"
    }
  },
  footerLogo: {
    width: "40%",
    margin: "0 auto",
    "@media (min-width:600px)": {
      width: "25%"
    },
    "@media (min-width:960px)": {
      width: "20%"
    }
  }
}));

const AgLife = props => {
  const classes = useStyles();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 0;

  const { overview } = useContext(OverviewContext);

  // Whizz to Merch Section
  function scrollToMerch() {
    scroller.scrollTo("merch-section", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }

  return (
    <div>
      {overview && (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>AgLife - Farming Forever - Powered by FarmFLiX.tv</title>
            <meta
              name="description"
              content="If you know You Know.... it's livestock, it's machinery, it's Summer Sunshine, it's The Dream. Watching the Forecast, working The Land, it's the Challenge, calving at all hours and Calling the Vet.  It's the Harvest safely brought in... it's Beating the Ban, it's the Red-tape paperwork, it's the Lambing and the Litres... it's SURVIVAL... we have One Life to Live and it's Farming Forever.  Are you living the AgLife?"
            ></meta>
          </Helmet>
          {/* Top */}
          <div className={classes.topBackground}>
            <Container maxWidth="md">
              <Box className={classes.topContent}>
                <Typography variant="h1" component="h1"></Typography>
                <Box className={classes.mainLogoContainer}>
                  <Typography variant="h1" component="h1">
                    <img
                      src={aglife_logo}
                      alt="Living the AgLife"
                      className={classes.mainLogo}
                    />
                  </Typography>
                </Box>
                <Typography variant="h2" className={classes.blurbHeading}>
                  {/* Desktop */}
                  <Hidden only={["xs"]}>
                    If You Know... <b>You Know...</b>
                  </Hidden>
                  {/* Mobile */}
                  <Hidden only={["sm", "md", "lg", "xl"]}>
                    If You Know...
                    <br />
                    <b>You Know...</b>
                  </Hidden>
                </Typography>
                <Box className={classes.blurbBackground}>
                  <Typography
                    variant="body1"
                    className={classes.bodyTextSml}
                    style={{ marginBottom: 0 }}
                  >
                    It&apos;s <b>Livestock</b>, it&apos;s <b>Machinery</b>,
                    it&apos;s <b>Summer Sunshine</b>, it&apos;s the<b> Dream</b>
                    . Watching the forecast, working the land, it&apos;s the{" "}
                    <b>Challenge</b>, calving at all hours and{" "}
                    <b>Calling the Vet</b>. It&apos;s the{" "}
                    <b>Harvest Safely Brought In...</b> it&apos;s{" "}
                    <b>Beating the Ban</b>, it&apos;s the{" "}
                    <b>Red-Tape Paperwork</b>, it&apos;s the <b>Lambing </b>and
                    the <b>Litres...</b>
                  </Typography>
                </Box>
                <Typography variant="h3" className={classes.blurbSubHeading}>
                  <b> It&apos;s SURVIVAL...</b>
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.bodyTextSlogan}
                  style={{ marginTop: "24px" }}
                >
                  {/* Desktop */}
                  <Hidden only={["xs", "sm"]}>
                    We have <b>One Life to Live </b>and it&apos;s{" "}
                    <b>Farming Forever</b>. Are you living the AgLife?
                  </Hidden>
                  {/* Mobile */}
                  <Hidden only={["md", "lg", "xl"]}>
                    We have <b>One Life to Live </b>and it&apos;s{" "}
                    <b>Farming Forever</b>.<br />
                    Are you living the AgLife?
                  </Hidden>
                </Typography>
              </Box>
            </Container>
          </div>
          {/* Merch */}
          <div className={classes.merchBackground}>
            <Element name="merch-section">
              <Container maxWidth="md">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      component="h2"
                      className={classes.merchHeading}
                    >
                      Farming Forever
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.bodyTextSml}
                      style={{ color: "#626262" }}
                    >
                      Support the AgLife community by wearing the merchandise.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.carouselWrapper}>
                    {/* Desktop */}
                    <Hidden only={["xs", "sm"]}>
                      <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={3}
                        slidesToScroll={1}
                        gutter={20}
                        leftChevron={
                          <div>
                            <ArrowBackRoundedIcon className={classes.chevron} />
                          </div>
                        }
                        rightChevron={
                          <div>
                            <ArrowForwardRoundedIcon
                              className={classes.chevron}
                            />
                          </div>
                        }
                        chevronWidth={chevronWidth}
                      >
                        {overview.promo.items.map((promoItem, ind) => (
                          <a
                            href={promoItem.clickUrl}
                            rel="noreferrer"
                            target="_blank"
                            key={ind}
                            style={{ textDecoration: "none" }}
                          >
                            <Box
                              className={
                                classes.desktopCarouselMerchItemContainer
                              }
                            >
                              <Box
                                className={
                                  classes.desktopCarouselMerchItemImageContainer
                                }
                                style={{
                                  backgroundImage: `url(${promoItem.posterMediaUrl})`
                                }}
                              >
                                <div
                                  className={
                                    classes.desktopCarouselMerchItemContainerBottomGradient
                                  }
                                ></div>
                              </Box>
                              <Box
                                className={
                                  classes.desktopCarouselMerchItemDetailsContainer
                                }
                              >
                                <div
                                  className={
                                    classes.desktopCarouselItemDetailsWrapper
                                  }
                                >
                                  <Typography
                                    className={
                                      classes.desktopCarouselMerchItemTitle
                                    }
                                    variant="h4"
                                  >
                                    {promoItem.title}
                                  </Typography>
                                </div>

                                {promoItem.subtitle ? (
                                  <Typography
                                    className={
                                      classes.desktopCarouselMerchItemPrice
                                    }
                                  >
                                    &pound;{promoItem.subtitle}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                          </a>
                        ))}
                      </ItemsCarousel>
                    </Hidden>
                    {/* Mobile */}
                    <Hidden only={["md", "lg", "xl"]}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.mobileTabletContainer}
                      >
                        {overview.promo.items.map((promoItem, ind) => {
                          return (
                            <a
                              key={ind}
                              href={promoItem.clickUrl}
                              rel="noreferrer"
                              target="_blank"
                              style={{ textDecoration: "none" }}
                            >
                              <Box
                                key={ind}
                                className={classes.mobileTabletMerchItem}
                              >
                                <Box
                                  className={
                                    classes.mobileTabletMerchItemImageContainer
                                  }
                                >
                                  <img
                                    src={promoItem.posterMediaUrl}
                                    className={
                                      classes.mobileTabletMerchItemImage
                                    }
                                    alt={promoItem.title}
                                  />
                                </Box>
                                <Box
                                  className={
                                    classes.mobileTabletMerchItemDetails
                                  }
                                >
                                  <div
                                    className={
                                      classes.desktopCarouselItemDetailsWrapper
                                    }
                                  >
                                    <Typography
                                      className={
                                        classes.mobileTabletMerchItemTitle
                                      }
                                      variant="h4"
                                    >
                                      {promoItem.title}
                                    </Typography>
                                  </div>
                                  {promoItem.subtitle ? (
                                    <Typography
                                      className={classes.mobileTabletMerchPrice}
                                    >
                                      &pound;{promoItem.subtitle}
                                    </Typography>
                                  ) : null}
                                </Box>
                              </Box>
                            </a>
                          );
                        })}
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
                <a
                  href="https://shop.farmflix.tv/collections/aglife/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button className={classes.button} onClick={scrollToMerch}>
                    Shop AgLife
                  </Button>
                </a>
              </Container>
            </Element>
          </div>
          <div className={classes.footerBackground}>
            <Container maxWidth="md">
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.footerHeading}
                  >
                    Powered By
                  </Typography>
                  <Box className={classes.footerLogoContainer}>
                    <a
                      href="https://farmflix.tv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={farmflix_logo}
                        alt="FarmFliX Logo"
                        className={classes.footerLogo}
                      />
                    </a>
                  </Box>
                  <Typography
                    variant="h4"
                    component="h4"
                    className={classes.footerSubHeading}
                  >
                    Find Us On
                  </Typography>
                  <Box className={classes.socialWrapper}>
                    <a
                      href="https://en-gb.facebook.com/farmflix/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacebookIcon className={classes.socialIcon} />
                    </a>
                    <a
                      href="https://www.instagram.com/farmflix/?hl=en"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstagramIcon className={classes.socialIcon} />
                    </a>
                    <a
                      href="https://www.youtube.com/farmflix"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <YouTubeIcon className={classes.socialIcon} />
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </div>
        </>
      )}
    </div>
  );
};

export default AgLife;
