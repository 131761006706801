import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid } from "@material-ui/core";
import BuildIcon from "@material-ui/icons/Build";
import { AccountContext } from "../../store/AccountContext";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  background: {
    height: "50vh",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "auto",
    display: "flex",
    // @ts-ignore
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  container: {
    textAlign: "center"
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "1.6rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "18px",
    textShadow: "-2px 2px 2px #171717",
    color: "#fff",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    textAlign: "center",
    fontSize: "2.6rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    marginBottom: "8px",
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "4.4rem",
      lineHeight: "4rem"
    }
  },
  bodyText: {
    fontWeight: 500,
    fontSize: "1rem",
    textAlign: "center",
    lineHeight: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.3rem"
    },
    "@media (min-width:960px)": {}
  },
  buildIcon: {
    fontSize: "5rem",
    color: "#fff",
    marginBottom: "12px",
    "@media (min-width:600px)": {
      fontSize: "8rem"
    }
  }
}));

const TrialsDisabled = () => {
  const classes = useStyles();

  const { loggedIn } = useContext(AccountContext);

  return (
    <>
      {loggedIn ? <Redirect to="/videos" /> : null}
      {!loggedIn && <Header />}
      <div className={classes.background}>
        <Container maxWidth={"md"}>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <BuildIcon className={classes.buildIcon} />
              <Typography variant="h3" className={classes.titleLarge}>
                We&apos;re working on it
              </Typography>
              <Typography variant="h3" className={classes.title}>
                Free Trials are not available right now.
              </Typography>
              <Typography variant="body1" className={classes.bodyText}>
                Check back soon.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default TrialsDisabled;
