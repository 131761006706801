export default interface Log {
  category: LogEventCategory;
  event:
    | PlaybackEvents
    | AccountActionEvents
    | InteractionEvents
    | HardwareEvents;
  data: object;
  timestamp?: Date;
  userId?: string;
}

export enum LogEventCategory {
  PLAYBACK_ACTION = "playback-action",
  INTERACTION = "interaction",
  ACCOUNT_ACTION = "account-action",
  HARDWARE = "hardware"
}

export enum PlaybackEvents {
  PLAYBACK_STARTED = "playback-started",
  PLAYBACK_PROGRESS = "playback-progress",
  PLAYBACK_FINISHED = "playback-finished",
  CASTING_CONNECTED = "casting-connected",
  CASTING_DISCONNECTED = "casting-disconnected"
}

export enum AccountActionEvents {
  LOGIN_WITH_PHONE = "login-with-phone",
  LOGIN_WITH_EMAIL = "login-with-email",
  TWO_FACTOR_AUTH = "two-factor-auth",
  AUTOPLAY_TOGGLED = "autoplay-toggled"
}

export enum HardwareEvents {
  CHROME_CAST_DETAILS = "chrome-cast-details"
}

export enum InteractionEvents {
  SEARCH = "search",
  VIDEO_SELECTED = "video-selected",
  PLAYLIST_SELECTED = "playlist-selected",
  CHANNEL_SELECTED = "channel-selected",
  FEATURED_VIDEO_SELECTED = "featured-video",
  NOTIFICATION_ICON_SELECTED = "notification-icon",
  NOTIFICATION_TAPPED_SELECTED = "notification-card-tapped",
  NAVIGATION = "navigation",
  LANDING_PAGE_SHOWN = "landing-shown",
  LANDING_PAGE_DISMISSED = "landing-dismissed"
}
