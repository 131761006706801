import React, { useContext } from "react";
import StepContainer from "./StepContainer";
import { Field, Form } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { OnboardingContext } from "../../../../store/OnboardingContext";
import useStyles from "../SignUpForm.styles";
import {
  composeValidators,
  isEmail,
  isEmailRequired,
  isPasswordRequired,
  isPasswordValid,
  isRequired
} from "../../../../utils/validators";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReactCodeInput from "react-verification-code-input";

const SignUpStepDetails = () => {
  const { application, loading, setDetails, verifyEmail } = useContext(
    OnboardingContext
  );
  const classes = useStyles();

  const submit = ({
    firstName,
    lastName,
    email,
    password
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => {
    setDetails(firstName, lastName, email, password);
  };

  const { emailVerificationSentCount } = application!;

  const verifying = emailVerificationSentCount > 0;

  return (
    <StepContainer>
      <StepContainer.Title title="Your Details" />
      <StepContainer.Body>
        {!verifying && (
          <Form onSubmit={submit}>
            {props => (
              <form onSubmit={props.handleSubmit}>
                <div className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className={classes.formField}>
                      <Field name="firstName" validate={isRequired}>
                        {({ input, meta }) => (
                          <TextField
                            fullWidth
                            label="First Name"
                            {...input}
                            disabled={loading}
                            type="text"
                            placeholder="First Name"
                            error={meta.error && meta.touched}
                            helperText={meta.touched && meta.error}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.formField}>
                      <Field name="lastName" validate={isRequired}>
                        {({ input, meta }) => (
                          <TextField
                            fullWidth
                            label="Last Name"
                            {...input}
                            disabled={loading}
                            type="text"
                            placeholder="Last Name"
                            error={meta.error && meta.touched}
                            helperText={meta.touched && meta.error}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.formField}>
                      <Field
                        name="email"
                        validate={composeValidators(isEmail, isEmailRequired)}
                      >
                        {({ input, meta }) => (
                          <TextField
                            fullWidth
                            label="Email"
                            {...input}
                            disabled={loading}
                            type="email"
                            placeholder="Email"
                            error={meta.error && meta.touched}
                            helperText={meta.touched && meta.error}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12} sm={12} className={classes.formField}>
                      <Field
                        name="password"
                        validate={composeValidators(
                          isPasswordRequired,
                          isPasswordValid
                        )}
                      >
                        {({ input, meta }) => (
                          <TextField
                            fullWidth
                            label="Password"
                            {...input}
                            disabled={loading}
                            type="password"
                            placeholder="Password"
                            error={meta.error && meta.touched}
                            helperText={meta.touched && meta.error}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Button
                        name="next"
                        type="submit"
                        fullWidth
                        size="large"
                        className={classes.submit}
                        disabled={loading}
                        style={{ margin: 0 }}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </form>
            )}
          </Form>
        )}
        {verifying && (
          <>
            <Typography
              variant="body2"
              className={classes.bodyText}
              align="center"
            >
              We found an account matching the email address you provided, for
              security reasons we need to verify you own this account. We have
              sent a 6 digit code to:
            </Typography>
            <Typography
              variant="body1"
              className={classes.submittedMobileNumber}
            >
              {application?.email}
            </Typography>
            <Typography
              variant="body2"
              className={classes.incorrectMobileNumber}
              // onClick={changeMobileNumber}
            >
              Incorrect email? Change it?
            </Typography>
            <Typography
              variant="body1"
              className={classes.bodyText}
              align="center"
              style={{ fontWeight: 500 }}
            >
              Please enter the code below.
            </Typography>
            <form className={classes.form}>
              <Grid item xs={12} sm={12}>
                {/* @ts-ignore */}
                <ReactCodeInput
                  className={classes.verificationCodeContainer}
                  type="number"
                  fields={6}
                  onComplete={verifyEmail}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  size="medium"
                  // onClick={resendVerificationCode}
                  className={`${classes.secSubmit} ${classes.goBack}`}
                  style={{ paddingTop: 0, paddingLeft: "20px" }}
                >
                  Resend Code
                </Button>
              </Grid>
            </form>
          </>
        )}
      </StepContainer.Body>
      <StepContainer.Actions />
    </StepContainer>
  );
};

export default SignUpStepDetails;
