import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Hidden
} from "@material-ui/core";
import { Link } from "react-router-dom";

export default function UpdateEmailBanner() {
  const useStyles = makeStyles(theme => ({
    background: {
      paddingTop: 0,
      flexGrow: 1,
      backgroundColor: theme.palette.background.upgradeBG,
      textAlign: "left"
    },
    bannerToolbar: {
      paddingTop: 0
      // padding: "0 40px"
    },
    bannerIcon: {
      marginRight: "10px",
      color: theme.palette.background.ffGreen
    },
    bannerText: {
      flexGrow: 1,
      color: theme.palette.primary.upgrade,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      "@media (max-width:600px)": {
        marginBottom: theme.spacing(1)
      }
    },
    mobileBackground: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.upgradeBG,
      textAlign: "left",
      minHeight: "48px",
      padding: "10px 0"
    }
  }));

  const classes = useStyles();

  return (
    <div>
      {/* Visible SM, MD, L, XL */}
      <Hidden xsDown>
        <AppBar position="static" className={classes.background}>
          <Toolbar className={classes.bannerToolbar}>
            <Typography variant="body2" className={classes.bannerText}>
              Oops! Your email address seems to be invalid
            </Typography>
            <Link
              to="/myaccount/change-email"
              style={{ textDecoration: "none" }}
            >
              <Button size="small">Fix now</Button>
            </Link>
          </Toolbar>
        </AppBar>
      </Hidden>

      {/* Visible XS only */}
      <Hidden smUp>
        <Container className={classes.mobileBackground}>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              textAlign: "center"
            }}
          >
            <Grid item xs={12} sm={12}>
              <Typography variant="body2" className={classes.bannerText}>
                Oops! Your email address seems to be invalid
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Link
                to="/myaccount/change-email"
                style={{ textDecoration: "none" }}
              >
                <Button size="small">Fix now</Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Hidden>
    </div>
  );
}
