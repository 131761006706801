import React, { useContext } from "react";
import { Grid, Container, Typography, Divider } from "@material-ui/core";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Overview from "./MyAccount/Overview";
import AutorenewOff from "./MyAccount/AutoRenewOff";
import AutorenewOn from "./MyAccount/AutoRenewOn";
import ChangeName from "./MyAccount/ChangeName";
import ChangeEmail from "./MyAccount/ChangeEmail";
import ChangePassword from "./MyAccount/ChangePassword";
import AddPhone from "./MyAccount/AddPhone";
import ChangePhone from "./MyAccount/ChangePhone";
import PaymentHistory from "./MyAccount/PaymentHistory";
import EditProfile from "./MyAccount/EditProfile";
import AddPaymentMethodAutoRenew from "./MyAccount/AddPaymentMethodAutoRenew";
import PaymentMethod from "./MyAccount/PaymentMethodAction";
import JoinNow from "./MyAccount/JoinNow";
import HeaderInternal from "./Header/HeaderInternal";
import Footer from "./Footer/Footer";
import MembershipCTABanner from "./MyAccount/MembershipCTABanner";
import { OverviewContext } from "../store/OverviewContext";
import { AccountContext } from "../store/AccountContext";
import { useHistory, useLocation } from "react-router-dom";
import TrialSubscriptionWindow from "./MyAccount/TrialSubscriptionWindow";
import GracePeriodBanner from "./Banners/GracePeriod";
import AnnualUpgrade from "./MyAccount/AnnualUpgrade";
import { MEMBER_STATUS_STANDARD } from "@booyaltd/core";
import PriceRise from "./Banners/PriceRise";
import dayjs from "dayjs";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    },
    "& a": {
      color: theme.palette.background.ffNavy,
      textDecoration: "none"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  sectionDivider: {
    height: "1px",
    backgroundColor: "#c1c1c1"
  },
  priceAlertBanner: {
    backgroundColor: theme.palette.error.main
  },
  priceAlertBannerWrapper: {
    backgroundColor: theme.palette.error.main,
    fontWeight: 500,
    "& .MuiSvgIcon-root": {
      width: "1.4em",
      height: "1.4em"
    },
    "& .MuiAlertTitle-root": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem",
      color: theme.palette.common.white
    },
    "& .MuiAlert-message": {
      fontSize: "1rem",
      color: theme.palette.common.white,
      fontWeight: 400
    }
  },
  priceAlertBannerIcon: {
    fill: theme.palette.common.white
  },
  priceInfoBanner: {
    backgroundColor: theme.palette.background.upgradeBG
  },
  priceInfoBannerWrapper: {
    backgroundColor: theme.palette.background.upgradeBG,
    fontWeight: 500,
    "& .MuiSvgIcon-root": {
      width: "1.4em",
      height: "1.4em"
    },
    "& .MuiAlertTitle-root": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem",
      color: theme.palette.common.white
    },
    "& .MuiAlert-message": {
      fontSize: "1rem",
      color: theme.palette.common.white,
      fontWeight: 400
    }
  },
  priceInfoBannerIcon: {
    fill: theme.palette.common.white
  },
  joinNowTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "center",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    },
    "& a": {
      color: theme.palette.background.ffNavy,
      textDecoration: "none"
    }
  }
}));

export default function MyAccount() {
  const classes = useStyles();
  const history = useHistory();
  const { loading, loggedIn, userProfile } = useContext(AccountContext);
  const { overview } = useContext(OverviewContext);
  const location = useLocation();

  if (!loading && !loggedIn) {
    history.push("/login");
    return null;
  }

  const isPaymentRetrying = userProfile
    ? userProfile.memberStatusId === "standard-expired" &&
      userProfile.productAutoRenewing
    : false;

  return (
    <>
      <HeaderInternal />
      <div className={classes.background}>
        {/* Display CTA Upgrade Banner on Price Rise to everyone - Lock in Price Rise */}
        {overview &&
          userProfile &&
          location.pathname === "/myaccount/overview" &&
          overview.doors.annualUpgradeOpen &&
          userProfile.memberStatusId !== MEMBER_STATUS_STANDARD && (
            <PriceRise
              disableButton
              title="Beat the Price Rise"
              subTitle="Lock in the current price before they increase on 5th December 2022"
              showCountdown
            />
          )}

        {/* Display CTA Upgrade Banner on Price Rise to Standard Users with AR Disabled - Turn on Auto Renew to secure */}
        {overview &&
          userProfile &&
          location.pathname === "/myaccount/overview" &&
          overview.doors.annualUpgradeOpen &&
          userProfile.memberStatusId === MEMBER_STATUS_STANDARD &&
          dayjs(userProfile?.dateWebFirstLoggedIn).isAfter(
            dayjs("2022-11-25")
          ) &&
          !userProfile.productAutoRenewing && (
            <PriceRise
              disableButton
              title="Beat the Price Rise"
              subTitle="Turn On Auto Renew to secure current price before the increase on 5th December 2022"
              showCountdown
            />
          )}

        {overview &&
          userProfile &&
          location.pathname === "/myaccount/overview" &&
          overview.doors.annualUpgradeOpen &&
          userProfile.memberStatusId === MEMBER_STATUS_STANDARD &&
          dayjs(userProfile?.dateWebFirstLoggedIn).isBefore(
            dayjs("2022-11-25")
          ) &&
          userProfile.productAutoRenewing && (
            <PriceRise
              disableButton
              title="Our Price Rise will not affect you!"
              subTitle="Good News! As an active member, your price will remain on your current plan."
              showCountdown
            />
          )}

        {/* Display CTA Banner on Doors Open to everyone != standard/trial/standard-expired+autorenew */}
        {overview &&
          userProfile &&
          overview.doors.open &&
          location.pathname === "/myaccount/overview" &&
          !isPaymentRetrying &&
          userProfile.memberStatusId !== "standard" &&
          userProfile.memberStatusId !== "trial" && <MembershipCTABanner />}

        {/* Display Grace Period Banner based on first/second failed retry attempts */}
        {overview &&
          userProfile &&
          userProfile.memberStatusId === "standard" &&
          userProfile.subscriptionPaymentRetryCount > 0 && (
            <GracePeriodBanner />
          )}

        {/* Display 24H Countdown Banner when Trial Expired resurfaces - 72hr window to purchase */}
        {overview &&
          userProfile &&
          !overview.doors.open &&
          location.pathname === "/myaccount/overview" &&
          userProfile.memberStatusId === "trial-expired" && (
            <TrialSubscriptionWindow />
          )}
        <Container className={classes.myAccountContainer}>
          <>
            <Grid container className={classes.myAccountGridContainer}>
              {location.pathname !== "/myaccount/join-now" ? (
                <Grid item xs={12} sm={12}>
                  <Typography variant="h1" className={classes.myAccountTitle}>
                    <Link to="/myaccount/overview">My Account</Link>
                  </Typography>
                  <Divider className={classes.sectionDivider} />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <Typography variant="h1" className={classes.joinNowTitle}>
                    <Link to="/myaccount/overview">Join Now</Link>
                  </Typography>
                  <Divider className={classes.sectionDivider} />
                </Grid>
              )}
            </Grid>

            <Switch>
              <Route
                exact
                path="/myaccount"
                component={() => <Redirect to="/myaccount/overview" />}
              />
              <Route path="/myaccount/overview" component={Overview} />
              {/* Handle Auto Renew  */}
              <Route path="/myaccount/autorenew-off" component={AutorenewOff} />
              <Route path="/myaccount/autorenew-on" component={AutorenewOn} />
              {/* Standard Profile Updates */}
              <Route path="/myaccount/editprofile" component={EditProfile} />
              <Route path="/myaccount/change-name" component={ChangeName} />
              <Route path="/myaccount/change-email" component={ChangeEmail} />
              <Route
                path="/myaccount/change-password"
                component={ChangePassword}
              />
              <Route path="/myaccount/add-phone" component={AddPhone} />
              <Route path="/myaccount/change-phone" component={ChangePhone} />
              <Route
                path="/myaccount/payment-history"
                component={PaymentHistory}
              />
              <Route
                path="/myaccount/payment-method/:action"
                component={PaymentMethod}
              />

              {/* Payment Method Redirects */}
              <Route
                exact
                path="/myaccount/add-payment-method"
                component={() => (
                  <Redirect to="/myaccount/payment-method/add" />
                )}
              />
              <Route
                exact
                path="/myaccount/change-payment-method"
                component={() => (
                  <Redirect to="/myaccount/payment-method/update" />
                )}
              />
              <Route
                exact
                path="/myaccount/remove-payment-method"
                component={() => (
                  <Redirect to="/myaccount/payment-method/remove" />
                )}
              />

              <Route
                path="/myaccount/add-payment-method-autorenew"
                component={AddPaymentMethodAutoRenew}
              />
              {/* Upgrade / Join Now */}
              <Route path="/myaccount/join-now" component={JoinNow} />
              {/* Annual Upgrade */}
              <Route
                path="/myaccount/annual-upgrade"
                component={AnnualUpgrade}
              />
            </Switch>
          </>
        </Container>
      </div>
      <Footer />
    </>
  );
}
