import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Typography,
  Grid,
  Divider,
  FormControl,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from "@material-ui/core";
import { AccountContext } from "../../store/AccountContext";
import { MemberSurveyContext } from "../../store/MemberSurveyContext";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    // @ts-ignore
  },
  stepHeading: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    // @ts-ignore
    color: theme.palette.background.ffNavyLighter,
    fontWeight: 600,
    // textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    }
  },
  divider: {
    height: "1px",
    margin: "6px 0 12px 0",
    backgroundColor: "#b7b7b7"
  },
  formStep: {
    marginTop: "24px"
  },
  stepButton: {
    border: "none",
    "&:hover": {
      border: "none"
    }
  },
  stepButtonBack: {
    backgroundColor: "#fff",
    border: "2px solid #171717",
    "&:hover": {
      backgroundColor: "#fafafa",
      border: "2px solid #171717"
    }
  },
  bodyText: {
    // @ts-ignore
    color: theme.palette.background.ffNavyLighter
  },
  formQuestion: {
    marginTop: "12px",
    marginBottom: "36px",
    "& .MuiFormControl-root": {
      width: "100%"
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem",
      marginBottom: "18px"
    },
    "& .MuiTypography-body1": {
      color: "#3d3d3d"
    }
  },
  formGroup: {
    "& .MuiFormControl-root label": {
      marginBottom: "6px"
    },
    "& .MuiTypography-body1": {
      marginBottom: "6px"
    }
  },
  buttonContainer: {
    margin: "24px 0"
  },
  userPhone: {
    fontSize: "1.1rem",
    fontWeight: "500",
    margin: "12px 0 24px 0",
    "& span": {
      color: "#99ca3c",
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer"
      }
    }
  }
}));

export default function Confirm() {
  const classes = useStyles();

  const { userProfile } = useContext(AccountContext);

  const { formValues, handleBack, handleChange } = useContext(
    MemberSurveyContext
  );
  const {
    userId,
    age,
    gender,
    growUpOnFarm,
    connectionToFarming1,
    connectionToFarming2,
    connectionToFarming3,
    connectionToFarming4,
    connectionToFarming5,
    connectionToFarming6,
    connectionToFarming7,
    connectionToFarming9,
    connectionToFarming10,
    connectionToFarming11,
    connectionToFarming12,
    connectionToFarming13,
    connectionToFarming14,
    connectionToFarmingOther,
    connectionToFarmingFarmService,
    connectionToFarmingFarmServiceRole,
    connectionToFarmingFarmProduct,
    connectionToFarmingFarmProductRole,
    connectionToFarmingFarmEnthusiast,
    regionGrowUp,
    regionGrowUpOther,
    regionLivingIn,
    regionLivingInOther,
    job,
    checkEmails,
    emailsDelivery,
    smsTrust,
    useWhatsapp,
    platformUsageFacebook,
    platformUsageYouTube,
    platformUsageInstagram,
    platformUsageTiktok,
    platformUsageSnapchat,
    platformUsageOther,
    platformUsageOtherReason,
    favouriteFacebook,
    favouriteYouTube,
    favouriteInstagram,
    favouriteTiktok,
    favouriteSnapchat,
    discoverFarmFLiX,
    discoverFarmFLiXOther,
    joinMembership,
    waitedMembership,
    reasonForJoining1,
    reasonForJoining2,
    reasonForJoining3,
    reasonForJoining4,
    reasonForJoining5,
    reasonForJoining6,
    reasonForJoining7,
    reasonForJoiningOther,
    useFreeTrial,
    firstLoginLayout,
    firstLoginLayoutOther,
    watchBehaviour,
    skipAgLife,
    skipStraight6,
    skipStockyards,
    skipWhy,
    firstChannel,
    secondChannel,
    thirdChannel,
    enjoyingTopic1,
    enjoyingTopic2,
    enjoyingTopic3,
    enjoyingTopic4,
    enjoyingTopic5,
    enjoyingTopic6,
    enjoyingTopicOther,
    idealMixWeek1,
    idealMixWeek2,
    idealMixWeek3,
    idealMixWeek4,
    missTheLoft,
    watchRoutine,
    episodeLength,
    breakEpisodes,
    resumePlayback,
    resumePlaybackWhy,
    reWatchOldEpisodes,
    episodeRegion1,
    episodeRegion2,
    episodeRegion3,
    episodeRegion4,
    episodeRegion5,
    episodeRegion6,
    episodeRegionWhy,
    travelOverseas,
    unbiasEdit,
    unbiasEditWhy,
    documentFarming,
    documentFarmingWhy,
    favouriteThingFarmFLiX,
    frustratingThingFarmFLiX,
    convinceFarmFLiX,
    membersFarmFLiX,
    editDuration,
    employeesFarmFLiX,
    teamJohn,
    teamBrian,
    teamAlan,
    teamWillis,
    teamConor,
    teamKevin,
    teamWhy,
    subtitles,
    appInstalled,
    appReliable,
    appEasyToUse,
    improveAppSuggestions,
    appCasting,
    appCastingReliable,
    appCastingEasyToUse,
    triedTvApp,
    triedTvAppReliable,
    triedTvAppEasyToUse,
    improveTvAppSuggestions,
    usedSearch,
    usedSearchResults,
    improveSearchSuggestions,
    useFavourites,
    episodeFeature,
    episodeFeatureShare,
    followedYouTube,
    followBeforeAfter,
    moreAvailableFarmFLiX,
    youtubeContentStyle1,
    youtubeContentStyle2,
    youtubeContentStyle3,
    youtubeContentStyle4,
    youtubeThought,
    youtubeDoNext,
    youtubeDoNextOther,
    watchOnThePull,
    onThePullVideoThoughts,
    runOnThePullAgain,
    wantAnotherOnThePull,
    enterYourTractor,
    wearAgriMerch,
    wearMerchBrands,
    seenAgLifeMerch,
    boughtGiftedAgLifeMerch,
    wearAgLifeMerch,
    onlineComm1,
    onlineComm2,
    onlineComm3,
    onlineComm4,
    onlineComm5,
    onlineComm6,
    onlineComm7,
    onlineComm8,
    onlineComm9,
    onlineCommOther,
    partOfOnlineComm,
    finalComments,
    permissionToCall,
    userContactPhone
  } = formValues;

  const [submitted, setSubmitted] = useState(false);
  const [showUserPhoneInput, setShowUserPhoneInput] = useState(false);

  const handleFormSubmission = () => {
    // set completed in localStorage
    localStorage.setItem("ff_memberSurvey22_completed", true);
    setSubmitted(true);
  };

  const showEnterUserPhone = () => {
    setShowUserPhoneInput(true);
  };

  return (
    <>
      <Grid container spacing={2} className={classes.formStep}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.stepHeading}>
            Confirmation
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        <>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.bodyText}>
              Thank you for taking the time to participate in the survey – if
              there&apos;s anything we&apos;ve missed just add it here in the
              comment box.
            </Typography>
            <FormControl
              style={{ marginTop: "12px", marginBottom: "24px", width: "100%" }}
            >
              <TextField
                multiline={true}
                rows={10}
                fullWidth
                name="finalComments"
                placeholder=""
                value={finalComments.value}
                onChange={handleChange}
                error={!!finalComments.error}
                helperText={finalComments.error}
                required={finalComments.required}
              />
            </FormControl>
            {/* Wrap Mobile check here */}

            {userProfile && userProfile.phoneNumber && (
              <>
                <Typography variant="body1" className={classes.bodyText}>
                  If we’re curious to find out more about your answers in this
                  survey – can we have permission to call you to discuss them?
                </Typography>
                <div className={classes.formQuestion}>
                  <FormControl>
                    <RadioGroup
                      onChange={handleChange}
                      name="permissionToCall"
                      className={classes.formGroup}
                    >
                      <FormControlLabel
                        value="Yes"
                        checked={permissionToCall.value === "Yes"}
                        control={<Radio />}
                        label="Yes"
                        required={true}
                      />
                      <FormControlLabel
                        value="No"
                        checked={permissionToCall.value === "No"}
                        control={<Radio />}
                        label="No"
                        required={true}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className={classes.formQuestion}>
                  <Typography variant="body1" className={classes.bodyText}>
                    Confirm your mobile number for us so we can contact you if
                    you win a prize.
                  </Typography>
                  <Typography variant="body1" className={classes.userPhone}>
                    {"+" + userProfile.countryCode + userProfile.phoneNumber}{" "}
                    <span onClick={showEnterUserPhone}>Change</span>
                  </Typography>
                </div>
                {showUserPhoneInput && (
                  <div className={classes.formQuestion}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Your Phone Number
                      </FormLabel>
                      <TextField
                        fullWidth
                        name="userContactPhone"
                        placeholder=""
                        value={userContactPhone.value}
                        onChange={handleChange}
                        error={!!userContactPhone.error}
                        helperText={userContactPhone.error}
                        required={userContactPhone.required}
                      />
                    </FormControl>
                  </div>
                )}
              </>
            )}

            {userProfile && !userProfile.phoneNumber && (
              <>
                <div className={classes.formQuestion}>
                  <Typography variant="body1" className={classes.bodyText}>
                    Please your mobile number for us so we can contact you if
                    you win a prize.
                  </Typography>
                </div>
                <div className={classes.formQuestion}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Your Phone Number</FormLabel>
                    <TextField
                      fullWidth
                      name="userContactPhone"
                      placeholder=""
                      value={userContactPhone.value}
                      onChange={handleChange}
                      error={!!userContactPhone.error}
                      helperText={userContactPhone.error}
                      required={userContactPhone.required}
                    />
                  </FormControl>
                </div>
              </>
            )}
          </Grid>

          {/* Local - http://localhost:3000/member-survey-completed
            Test - http://web-test.farmflix.tv/member-survey-completed
            Prod - http://farmflix.tv/member-survey-completed */}

          <iframe
            title="Hidden IFrame"
            name="hidden_iframe"
            id="hidden_iframe"
            style={{ display: "none" }}
            onLoad={() =>
              submitted
                ? (window.location.href =
                    "http://farmflix.tv/member-survey-completed")
                : null
            }
          ></iframe>

          <div style={{ width: "100%" }}>
            <form
              className="form"
              action="https://docs.google.com/forms/d/e/1FAIpQLScIPDDHUz5mjTq2iUa-zkvIY-TihN9kkIUj87jWm-Tu7dwWNQ/formResponse"
              method="post"
              target="hidden_iframe"
              onSubmit={handleFormSubmission}
            >
              {userProfile && (
                <input
                  type="hidden"
                  value={userProfile.id}
                  name={userId.googleFormId}
                />
              )}

              {age && age.value && (
                <input
                  type="hidden"
                  value={age.value}
                  name={age.googleFormId}
                />
              )}
              {gender && gender.value && (
                <input
                  type="hidden"
                  value={gender.value}
                  name={gender.googleFormId}
                />
              )}
              {growUpOnFarm && growUpOnFarm.value && (
                <input
                  type="hidden"
                  value={growUpOnFarm.value}
                  name={growUpOnFarm.googleFormId}
                />
              )}
              {connectionToFarming1 && connectionToFarming1.value === true && (
                <input
                  type="hidden"
                  value={connectionToFarming1.label}
                  name={connectionToFarming1.googleFormId}
                />
              )}
              {connectionToFarming2 && connectionToFarming2.value === true && (
                <input
                  type="hidden"
                  value={connectionToFarming2.label}
                  name={connectionToFarming2.googleFormId}
                />
              )}
              {connectionToFarming3 && connectionToFarming3.value === true && (
                <input
                  type="hidden"
                  value={connectionToFarming3.label}
                  name={connectionToFarming3.googleFormId}
                />
              )}
              {connectionToFarming4 && connectionToFarming4.value === true && (
                <input
                  type="hidden"
                  value={connectionToFarming4.label}
                  name={connectionToFarming4.googleFormId}
                />
              )}
              {connectionToFarming5 && connectionToFarming5.value === true && (
                <input
                  type="hidden"
                  value={connectionToFarming5.label}
                  name={connectionToFarming5.googleFormId}
                />
              )}
              {connectionToFarming6 && connectionToFarming6.value === true && (
                <input
                  type="hidden"
                  value={connectionToFarming6.label}
                  name={connectionToFarming6.googleFormId}
                />
              )}
              {connectionToFarming7 && connectionToFarming7.value === true && (
                <input
                  type="hidden"
                  value={connectionToFarming7.label}
                  name={connectionToFarming7.googleFormId}
                />
              )}
              {connectionToFarming9 && connectionToFarming9.value === true && (
                <input
                  type="hidden"
                  value={connectionToFarming9.label}
                  name={connectionToFarming9.googleFormId}
                />
              )}
              {connectionToFarming10 &&
                connectionToFarming10.value === true && (
                  <input
                    type="hidden"
                    value={connectionToFarming10.label}
                    name={connectionToFarming10.googleFormId}
                  />
                )}
              {connectionToFarming11 &&
                connectionToFarming11.value === true && (
                  <input
                    type="hidden"
                    value={connectionToFarming11.label}
                    name={connectionToFarming11.googleFormId}
                  />
                )}
              {connectionToFarming12 &&
                connectionToFarming12.value === true && (
                  <input
                    type="hidden"
                    value={connectionToFarming12.label}
                    name={connectionToFarming12.googleFormId}
                  />
                )}
              {connectionToFarming13 &&
                connectionToFarming13.value === true && (
                  <input
                    type="hidden"
                    value={connectionToFarming13.label}
                    name={connectionToFarming13.googleFormId}
                  />
                )}
              {/* Farm Service */}
              {connectionToFarming11 && connectionToFarming11.value === true && (
                <>
                  <input
                    type="hidden"
                    value={connectionToFarmingFarmService.value}
                    name={connectionToFarmingFarmService.googleFormId}
                  />

                  <input
                    type="hidden"
                    value={connectionToFarmingFarmServiceRole.value}
                    name={connectionToFarmingFarmServiceRole.googleFormId}
                  />
                </>
              )}
              {/* Farm Product */}
              {connectionToFarming12 && connectionToFarming12.value === true && (
                <>
                  <input
                    type="hidden"
                    value={connectionToFarmingFarmProduct.value}
                    name={connectionToFarmingFarmProduct.googleFormId}
                  />

                  <input
                    type="hidden"
                    value={connectionToFarmingFarmProductRole.value}
                    name={connectionToFarmingFarmProductRole.googleFormId}
                  />
                </>
              )}
              {/* Farm Enthusiast */}
              {connectionToFarming13 && connectionToFarming13.value === true && (
                <>
                  <input
                    type="hidden"
                    value={connectionToFarmingFarmEnthusiast.value}
                    name={connectionToFarmingFarmEnthusiast.googleFormId}
                  />
                </>
              )}
              {/* Other */}
              {connectionToFarming14 && connectionToFarming14.value === true && (
                <>
                  <input
                    type="hidden"
                    value={"__other_option__"}
                    name={connectionToFarmingOther.googleFormId}
                  />
                  <input
                    type="hidden"
                    value={connectionToFarmingOther.value}
                    name={connectionToFarmingOther.googleFormIdOtherResponse}
                  />
                </>
              )}
              {regionGrowUp.value !== "Other" &&
                regionGrowUp &&
                regionGrowUp.value && (
                  <input
                    type="hidden"
                    value={regionGrowUp.value}
                    name={regionGrowUp.googleFormId}
                  />
                )}
              {regionGrowUp.value === "Other" &&
                regionGrowUpOther &&
                regionGrowUpOther.value && (
                  <>
                    <input
                      type="hidden"
                      value={"__other_option__"}
                      name={regionGrowUpOther.googleFormId}
                    />
                    <input
                      type="hidden"
                      value={regionGrowUpOther.value}
                      name={regionGrowUpOther.googleFormIdOtherResponse}
                    />
                  </>
                )}
              {regionLivingIn.value !== "Other" &&
                regionLivingIn &&
                regionLivingIn.value && (
                  <input
                    type="hidden"
                    value={regionLivingIn.value}
                    name={regionLivingIn.googleFormId}
                  />
                )}
              {regionLivingIn.value === "Other" &&
                regionLivingInOther &&
                regionLivingInOther.value && (
                  <>
                    <input
                      type="hidden"
                      value={"__other_option__"}
                      name={regionLivingInOther.googleFormId}
                    />
                    <input
                      type="hidden"
                      value={regionLivingInOther.value}
                      name={regionLivingInOther.googleFormIdOtherResponse}
                    />
                  </>
                )}
              {job && job.value && (
                <input
                  type="hidden"
                  value={job.value}
                  name={job.googleFormId}
                />
              )}
              {checkEmails && checkEmails.value && (
                <input
                  type="hidden"
                  value={checkEmails.value}
                  name={checkEmails.googleFormId}
                />
              )}
              {emailsDelivery && emailsDelivery.value && (
                <input
                  type="hidden"
                  value={emailsDelivery.value}
                  name={emailsDelivery.googleFormId}
                />
              )}
              {smsTrust && smsTrust.value && (
                <input
                  type="hidden"
                  value={smsTrust.value}
                  name={smsTrust.googleFormId}
                />
              )}
              {useWhatsapp && useWhatsapp.value && (
                <input
                  type="hidden"
                  value={useWhatsapp.value}
                  name={useWhatsapp.googleFormId}
                />
              )}
              {platformUsageFacebook && platformUsageFacebook.value === true && (
                <>
                  <input
                    type="hidden"
                    value={platformUsageFacebook.label}
                    name={platformUsageFacebook.googleFormId}
                  />

                  {favouriteFacebook && (
                    <input
                      type="hidden"
                      value={favouriteFacebook.value}
                      name={favouriteFacebook.googleFormId}
                    />
                  )}
                </>
              )}
              {platformUsageYouTube && platformUsageYouTube.value === true && (
                <>
                  <input
                    type="hidden"
                    value={platformUsageYouTube.label}
                    name={platformUsageYouTube.googleFormId}
                  />

                  {favouriteYouTube && (
                    <input
                      type="hidden"
                      value={favouriteYouTube.value}
                      name={favouriteYouTube.googleFormId}
                    />
                  )}
                </>
              )}
              {platformUsageInstagram && platformUsageInstagram.value === true && (
                <>
                  <input
                    type="hidden"
                    value={platformUsageInstagram.label}
                    name={platformUsageInstagram.googleFormId}
                  />

                  {favouriteInstagram && (
                    <input
                      type="hidden"
                      value={favouriteInstagram.value}
                      name={favouriteInstagram.googleFormId}
                    />
                  )}
                </>
              )}
              {platformUsageTiktok && platformUsageTiktok.value === true && (
                <>
                  <input
                    type="hidden"
                    value={platformUsageTiktok.label}
                    name={platformUsageTiktok.googleFormId}
                  />

                  {favouriteTiktok && (
                    <input
                      type="hidden"
                      value={favouriteTiktok.value}
                      name={favouriteTiktok.googleFormId}
                    />
                  )}
                </>
              )}
              {platformUsageSnapchat && platformUsageSnapchat.value === true && (
                <>
                  <input
                    type="hidden"
                    value={platformUsageSnapchat.label}
                    name={platformUsageSnapchat.googleFormId}
                  />

                  {favouriteSnapchat && (
                    <input
                      type="hidden"
                      value={favouriteSnapchat.value}
                      name={favouriteSnapchat.googleFormId}
                    />
                  )}
                </>
              )}
              {platformUsageOther && platformUsageOther.value === true && (
                <>
                  <input
                    type="hidden"
                    value={"__other_option__"}
                    name={platformUsageOtherReason.googleFormId}
                  />
                  <input
                    type="hidden"
                    value={platformUsageOtherReason.value}
                    name={platformUsageOtherReason.googleFormIdOtherResponse}
                  />
                </>
              )}
              {discoverFarmFLiX.value !== "Other" &&
                discoverFarmFLiX &&
                discoverFarmFLiX.value && (
                  <input
                    type="hidden"
                    value={discoverFarmFLiX.value}
                    name={discoverFarmFLiX.googleFormId}
                  />
                )}
              {discoverFarmFLiX.value === "Other" &&
                discoverFarmFLiXOther &&
                discoverFarmFLiXOther.value && (
                  <>
                    <input
                      type="hidden"
                      value={"__other_option__"}
                      name={discoverFarmFLiXOther.googleFormId}
                    />
                    <input
                      type="hidden"
                      value={discoverFarmFLiXOther.value}
                      name={discoverFarmFLiXOther.googleFormIdOtherResponse}
                    />
                  </>
                )}
              {joinMembership && joinMembership.value && (
                <input
                  type="hidden"
                  value={joinMembership.value}
                  name={joinMembership.googleFormId}
                />
              )}
              {waitedMembership && waitedMembership.value && (
                <input
                  type="hidden"
                  value={waitedMembership.value}
                  name={waitedMembership.googleFormId}
                />
              )}
              {reasonForJoining1 && reasonForJoining1.value === true && (
                <input
                  type="hidden"
                  value={reasonForJoining1.label}
                  name={reasonForJoining1.googleFormId}
                />
              )}
              {reasonForJoining2 && reasonForJoining2.value === true && (
                <input
                  type="hidden"
                  value={reasonForJoining2.label}
                  name={reasonForJoining2.googleFormId}
                />
              )}
              {reasonForJoining3 && reasonForJoining3.value === true && (
                <input
                  type="hidden"
                  value={reasonForJoining3.label}
                  name={reasonForJoining3.googleFormId}
                />
              )}
              {reasonForJoining4 && reasonForJoining4.value === true && (
                <input
                  type="hidden"
                  value={reasonForJoining4.label}
                  name={reasonForJoining4.googleFormId}
                />
              )}
              {reasonForJoining5 && reasonForJoining5.value === true && (
                <input
                  type="hidden"
                  value={reasonForJoining5.label}
                  name={reasonForJoining5.googleFormId}
                />
              )}
              {reasonForJoining6 && reasonForJoining6.value === true && (
                <input
                  type="hidden"
                  value={reasonForJoining6.label}
                  name={reasonForJoining6.googleFormId}
                />
              )}
              {reasonForJoining7 && reasonForJoining7.value === true && (
                <>
                  <input
                    type="hidden"
                    value={"__other_option__"}
                    name={reasonForJoiningOther.googleFormId}
                  />
                  <input
                    type="hidden"
                    value={reasonForJoiningOther.value}
                    name={reasonForJoiningOther.googleFormIdOtherResponse}
                  />
                </>
              )}
              {useFreeTrial && useFreeTrial.value && (
                <input
                  type="hidden"
                  value={useFreeTrial.value}
                  name={useFreeTrial.googleFormId}
                />
              )}
              {firstLoginLayout.value !== "Other" &&
                firstLoginLayout &&
                firstLoginLayout.value && (
                  <input
                    type="hidden"
                    value={firstLoginLayout.value}
                    name={firstLoginLayout.googleFormId}
                  />
                )}
              {firstLoginLayout.value === "Other" &&
                firstLoginLayoutOther &&
                firstLoginLayoutOther.value && (
                  <>
                    <input
                      type="hidden"
                      value={"__other_option__"}
                      name={firstLoginLayoutOther.googleFormId}
                    />
                    <input
                      type="hidden"
                      value={firstLoginLayoutOther.value}
                      name={firstLoginLayoutOther.googleFormIdOtherResponse}
                    />
                  </>
                )}
              {watchBehaviour && watchBehaviour.value === "Watch All" && (
                <>
                  <input
                    type="hidden"
                    value={watchBehaviour.value}
                    name={watchBehaviour.googleFormId}
                  />

                  {firstChannel && (
                    <input
                      type="hidden"
                      value={firstChannel.value}
                      name={firstChannel.googleFormId}
                    />
                  )}
                  {secondChannel && (
                    <input
                      type="hidden"
                      value={secondChannel.value}
                      name={secondChannel.googleFormId}
                    />
                  )}
                  {thirdChannel && (
                    <input
                      type="hidden"
                      value={thirdChannel.value}
                      name={thirdChannel.googleFormId}
                    />
                  )}
                </>
              )}
              {watchBehaviour && watchBehaviour.value === "Skip a few" && (
                <>
                  <input
                    type="hidden"
                    value={watchBehaviour.value}
                    name={watchBehaviour.googleFormId}
                  />

                  {skipAgLife && skipAgLife.value === true && (
                    <input
                      type="hidden"
                      value={skipAgLife.label}
                      name={skipAgLife.googleFormId}
                    />
                  )}
                  {skipStraight6 && skipStraight6.value === true && (
                    <input
                      type="hidden"
                      value={skipStraight6.label}
                      name={skipStraight6.googleFormId}
                    />
                  )}
                  {skipStockyards && skipStockyards.value === true && (
                    <input
                      type="hidden"
                      value={skipStockyards.label}
                      name={skipStockyards.googleFormId}
                    />
                  )}

                  {skipWhy && skipWhy.value && (
                    <input
                      type="hidden"
                      value={skipWhy.value}
                      name={skipWhy.googleFormId}
                    />
                  )}
                </>
              )}
              {enjoyingTopic1 && enjoyingTopic1.value === true && (
                <input
                  type="hidden"
                  value={enjoyingTopic1.label}
                  name={enjoyingTopic1.googleFormId}
                />
              )}
              {enjoyingTopic2 && enjoyingTopic2.value === true && (
                <input
                  type="hidden"
                  value={enjoyingTopic2.label}
                  name={enjoyingTopic2.googleFormId}
                />
              )}
              {enjoyingTopic3 && enjoyingTopic3.value === true && (
                <input
                  type="hidden"
                  value={enjoyingTopic3.label}
                  name={enjoyingTopic3.googleFormId}
                />
              )}
              {enjoyingTopic4 && enjoyingTopic4.value === true && (
                <input
                  type="hidden"
                  value={enjoyingTopic4.label}
                  name={enjoyingTopic4.googleFormId}
                />
              )}
              {enjoyingTopic5 && enjoyingTopic5.value === true && (
                <input
                  type="hidden"
                  value={enjoyingTopic5.label}
                  name={enjoyingTopic5.googleFormId}
                />
              )}
              {enjoyingTopic6 && enjoyingTopic6.value === true && (
                <>
                  <input
                    type="hidden"
                    value={"__other_option__"}
                    name={enjoyingTopicOther.googleFormId}
                  />
                  <input
                    type="hidden"
                    value={enjoyingTopicOther.value}
                    name={enjoyingTopicOther.googleFormIdOtherResponse}
                  />
                </>
              )}
              {idealMixWeek1 && idealMixWeek1.value && (
                <input
                  type="hidden"
                  value={idealMixWeek1.value}
                  name={idealMixWeek1.googleFormId}
                />
              )}
              {idealMixWeek2 && idealMixWeek2.value && (
                <input
                  type="hidden"
                  value={idealMixWeek2.value}
                  name={idealMixWeek2.googleFormId}
                />
              )}
              {idealMixWeek3 && idealMixWeek3.value && (
                <input
                  type="hidden"
                  value={idealMixWeek3.value}
                  name={idealMixWeek3.googleFormId}
                />
              )}
              {idealMixWeek4 && idealMixWeek4.value && (
                <input
                  type="hidden"
                  value={idealMixWeek4.value}
                  name={idealMixWeek4.googleFormId}
                />
              )}
              {missTheLoft && missTheLoft.value && (
                <input
                  type="hidden"
                  value={missTheLoft.value}
                  name={missTheLoft.googleFormId}
                />
              )}
              {watchRoutine && watchRoutine.value && (
                <input
                  type="hidden"
                  value={watchRoutine.value}
                  name={watchRoutine.googleFormId}
                />
              )}
              {episodeLength && episodeLength.value && (
                <input
                  type="hidden"
                  value={episodeLength.value}
                  name={episodeLength.googleFormId}
                />
              )}
              {breakEpisodes && breakEpisodes.value && (
                <input
                  type="hidden"
                  value={breakEpisodes.value}
                  name={breakEpisodes.googleFormId}
                />
              )}
              {resumePlayback && resumePlayback.value && (
                <input
                  type="hidden"
                  value={resumePlayback.value}
                  name={resumePlayback.googleFormId}
                />
              )}
              {resumePlayback && resumePlayback.value === "No" && (
                <input
                  type="hidden"
                  value={resumePlaybackWhy.value}
                  name={resumePlaybackWhy.googleFormId}
                />
              )}
              {reWatchOldEpisodes && reWatchOldEpisodes.value && (
                <input
                  type="hidden"
                  value={reWatchOldEpisodes.value}
                  name={reWatchOldEpisodes.googleFormId}
                />
              )}
              {episodeRegion1 && episodeRegion1.value === true && (
                <input
                  type="hidden"
                  value={episodeRegion1.label}
                  name={episodeRegion1.googleFormId}
                />
              )}
              {episodeRegion2 && episodeRegion2.value === true && (
                <input
                  type="hidden"
                  value={episodeRegion2.label}
                  name={episodeRegion2.googleFormId}
                />
              )}
              {episodeRegion3 && episodeRegion3.value === true && (
                <input
                  type="hidden"
                  value={episodeRegion3.label}
                  name={episodeRegion3.googleFormId}
                />
              )}
              {episodeRegion4 && episodeRegion4.value === true && (
                <input
                  type="hidden"
                  value={episodeRegion4.label}
                  name={episodeRegion4.googleFormId}
                />
              )}
              {episodeRegion5 && episodeRegion5.value === true && (
                <input
                  type="hidden"
                  value={episodeRegion5.label}
                  name={episodeRegion5.googleFormId}
                />
              )}
              {episodeRegion6 && episodeRegion6.value === true && (
                <input
                  type="hidden"
                  value={episodeRegion6.label}
                  name={episodeRegion6.googleFormId}
                />
              )}
              {episodeRegionWhy && episodeRegionWhy.value && (
                <input
                  type="hidden"
                  value={episodeRegionWhy.value}
                  name={episodeRegionWhy.googleFormId}
                />
              )}
              {travelOverseas && travelOverseas.value && (
                <input
                  type="hidden"
                  value={travelOverseas.value}
                  name={travelOverseas.googleFormId}
                />
              )}
              {unbiasEdit && unbiasEdit.value && (
                <input
                  type="hidden"
                  value={unbiasEdit.value}
                  name={unbiasEdit.googleFormId}
                />
              )}
              {unbiasEditWhy && unbiasEditWhy.value && (
                <input
                  type="hidden"
                  value={unbiasEditWhy.value}
                  name={unbiasEditWhy.googleFormId}
                />
              )}
              {documentFarming && documentFarming.value && (
                <input
                  type="hidden"
                  value={documentFarming.value}
                  name={documentFarming.googleFormId}
                />
              )}
              {documentFarmingWhy && documentFarmingWhy.value && (
                <input
                  type="hidden"
                  value={documentFarmingWhy.value}
                  name={documentFarmingWhy.googleFormId}
                />
              )}
              {favouriteThingFarmFLiX && favouriteThingFarmFLiX.value && (
                <input
                  type="hidden"
                  value={favouriteThingFarmFLiX.value}
                  name={favouriteThingFarmFLiX.googleFormId}
                />
              )}
              {frustratingThingFarmFLiX && frustratingThingFarmFLiX.value && (
                <input
                  type="hidden"
                  value={frustratingThingFarmFLiX.value}
                  name={frustratingThingFarmFLiX.googleFormId}
                />
              )}
              {convinceFarmFLiX && convinceFarmFLiX.value && (
                <input
                  type="hidden"
                  value={convinceFarmFLiX.value}
                  name={convinceFarmFLiX.googleFormId}
                />
              )}
              {membersFarmFLiX && membersFarmFLiX.value && (
                <input
                  type="hidden"
                  value={membersFarmFLiX.value}
                  name={membersFarmFLiX.googleFormId}
                />
              )}
              {editDuration && editDuration.value && (
                <input
                  type="hidden"
                  value={editDuration.value}
                  name={editDuration.googleFormId}
                />
              )}
              {employeesFarmFLiX && employeesFarmFLiX.value && (
                <input
                  type="hidden"
                  value={employeesFarmFLiX.value}
                  name={employeesFarmFLiX.googleFormId}
                />
              )}
              {teamJohn && teamJohn.value === true && (
                <input
                  type="hidden"
                  value={teamJohn.label}
                  name={teamJohn.googleFormId}
                />
              )}
              {teamBrian && teamBrian.value === true && (
                <input
                  type="hidden"
                  value={teamBrian.label}
                  name={teamBrian.googleFormId}
                />
              )}
              {teamAlan && teamAlan.value === true && (
                <input
                  type="hidden"
                  value={teamAlan.label}
                  name={teamAlan.googleFormId}
                />
              )}
              {teamWillis && teamWillis.value === true && (
                <input
                  type="hidden"
                  value={teamWillis.label}
                  name={teamWillis.googleFormId}
                />
              )}
              {teamConor && teamConor.value === true && (
                <input
                  type="hidden"
                  value={teamConor.label}
                  name={teamConor.googleFormId}
                />
              )}
              {teamKevin && teamKevin.value === true && (
                <input
                  type="hidden"
                  value={teamKevin.label}
                  name={teamKevin.googleFormId}
                />
              )}
              {teamWhy && teamWhy.value && (
                <input
                  type="hidden"
                  value={teamWhy.value}
                  name={teamWhy.googleFormId}
                />
              )}
              {subtitles && subtitles.value && (
                <input
                  type="hidden"
                  value={subtitles.value}
                  name={subtitles.googleFormId}
                />
              )}
              {appInstalled && appInstalled.value && (
                <input
                  type="hidden"
                  value={appInstalled.value}
                  name={appInstalled.googleFormId}
                />
              )}
              {appReliable && appReliable.value && (
                <input
                  type="hidden"
                  value={appReliable.value}
                  name={appReliable.googleFormId}
                />
              )}
              {appEasyToUse && appEasyToUse.value && (
                <input
                  type="hidden"
                  value={appEasyToUse.value}
                  name={appEasyToUse.googleFormId}
                />
              )}
              {improveAppSuggestions && improveAppSuggestions.value && (
                <input
                  type="hidden"
                  value={improveAppSuggestions.value}
                  name={improveAppSuggestions.googleFormId}
                />
              )}
              {appCasting && appCasting.value && (
                <input
                  type="hidden"
                  value={appCasting.value}
                  name={appCasting.googleFormId}
                />
              )}
              {appCastingReliable && appCastingReliable.value && (
                <input
                  type="hidden"
                  value={appCastingReliable.value}
                  name={appCastingReliable.googleFormId}
                />
              )}
              {appCastingEasyToUse && appCastingEasyToUse.value && (
                <input
                  type="hidden"
                  value={appCastingEasyToUse.value}
                  name={appCastingEasyToUse.googleFormId}
                />
              )}
              {triedTvApp && triedTvApp.value && (
                <input
                  type="hidden"
                  value={triedTvApp.value}
                  name={triedTvApp.googleFormId}
                />
              )}
              {triedTvAppReliable && triedTvAppReliable.value && (
                <input
                  type="hidden"
                  value={triedTvAppReliable.value}
                  name={triedTvAppReliable.googleFormId}
                />
              )}
              {triedTvAppEasyToUse && triedTvAppEasyToUse.value && (
                <input
                  type="hidden"
                  value={triedTvAppEasyToUse.value}
                  name={triedTvAppEasyToUse.googleFormId}
                />
              )}
              {improveTvAppSuggestions && improveTvAppSuggestions.value && (
                <input
                  type="hidden"
                  value={improveTvAppSuggestions.value}
                  name={improveTvAppSuggestions.googleFormId}
                />
              )}
              {usedSearch && usedSearch.value && (
                <input
                  type="hidden"
                  value={usedSearch.value}
                  name={usedSearch.googleFormId}
                />
              )}
              {usedSearchResults && usedSearchResults.value && (
                <input
                  type="hidden"
                  value={usedSearchResults.value}
                  name={usedSearchResults.googleFormId}
                />
              )}
              {improveSearchSuggestions && improveSearchSuggestions.value && (
                <input
                  type="hidden"
                  value={improveSearchSuggestions.value}
                  name={improveSearchSuggestions.googleFormId}
                />
              )}
              {useFavourites && useFavourites.value && (
                <input
                  type="hidden"
                  value={useFavourites.value}
                  name={useFavourites.googleFormId}
                />
              )}
              {episodeFeature && episodeFeature.value && (
                <input
                  type="hidden"
                  value={episodeFeature.value}
                  name={episodeFeature.googleFormId}
                />
              )}
              {episodeFeatureShare && episodeFeatureShare.value && (
                <input
                  type="hidden"
                  value={episodeFeatureShare.value}
                  name={episodeFeatureShare.googleFormId}
                />
              )}
              {followedYouTube && followedYouTube.value && (
                <input
                  type="hidden"
                  value={followedYouTube.value}
                  name={followedYouTube.googleFormId}
                />
              )}
              {followBeforeAfter && followBeforeAfter.value && (
                <input
                  type="hidden"
                  value={followBeforeAfter.value}
                  name={followBeforeAfter.googleFormId}
                />
              )}
              {moreAvailableFarmFLiX && moreAvailableFarmFLiX.value && (
                <input
                  type="hidden"
                  value={moreAvailableFarmFLiX.value}
                  name={moreAvailableFarmFLiX.googleFormId}
                />
              )}
              {youtubeContentStyle1 && youtubeContentStyle1.value === true && (
                <input
                  type="hidden"
                  value={youtubeContentStyle1.label}
                  name={youtubeContentStyle1.googleFormId}
                />
              )}
              {youtubeContentStyle2 && youtubeContentStyle2.value === true && (
                <input
                  type="hidden"
                  value={youtubeContentStyle2.label}
                  name={youtubeContentStyle2.googleFormId}
                />
              )}
              {youtubeContentStyle3 && youtubeContentStyle3.value === true && (
                <input
                  type="hidden"
                  value={youtubeContentStyle3.label}
                  name={youtubeContentStyle3.googleFormId}
                />
              )}
              {youtubeContentStyle4 && youtubeContentStyle4.value === true && (
                <input
                  type="hidden"
                  value={youtubeContentStyle4.label}
                  name={youtubeContentStyle4.googleFormId}
                />
              )}
              {youtubeThought && youtubeThought.value && (
                <input
                  type="hidden"
                  value={youtubeThought.value}
                  name={youtubeThought.googleFormId}
                />
              )}

              {youtubeDoNext.value !== "Something different" &&
                youtubeDoNext &&
                youtubeDoNext.value && (
                  <input
                    type="hidden"
                    value={youtubeDoNext.value}
                    name={youtubeDoNext.googleFormId}
                  />
                )}

              {youtubeDoNext.value === "Something different" &&
                youtubeDoNextOther &&
                youtubeDoNextOther.value && (
                  <>
                    <input
                      type="hidden"
                      value={"__other_option__"}
                      name={youtubeDoNextOther.googleFormId}
                    />
                    <input
                      type="hidden"
                      value={youtubeDoNextOther.value}
                      name={youtubeDoNextOther.googleFormIdOtherResponse}
                    />
                  </>
                )}

              {watchOnThePull && watchOnThePull.value && (
                <input
                  type="hidden"
                  value={watchOnThePull.value}
                  name={watchOnThePull.googleFormId}
                />
              )}
              {onThePullVideoThoughts && onThePullVideoThoughts.value && (
                <input
                  type="hidden"
                  value={onThePullVideoThoughts.value}
                  name={onThePullVideoThoughts.googleFormId}
                />
              )}
              {runOnThePullAgain && runOnThePullAgain.value && (
                <input
                  type="hidden"
                  value={runOnThePullAgain.value}
                  name={runOnThePullAgain.googleFormId}
                />
              )}
              {wantAnotherOnThePull && wantAnotherOnThePull.value && (
                <input
                  type="hidden"
                  value={wantAnotherOnThePull.value}
                  name={wantAnotherOnThePull.googleFormId}
                />
              )}
              {enterYourTractor && enterYourTractor.value && (
                <input
                  type="hidden"
                  value={enterYourTractor.value}
                  name={enterYourTractor.googleFormId}
                />
              )}
              {wearAgriMerch && wearAgriMerch.value && (
                <input
                  type="hidden"
                  value={wearAgriMerch.value}
                  name={wearAgriMerch.googleFormId}
                />
              )}
              {wearMerchBrands && wearMerchBrands.value && (
                <input
                  type="hidden"
                  value={wearMerchBrands.value}
                  name={wearMerchBrands.googleFormId}
                />
              )}
              {seenAgLifeMerch && seenAgLifeMerch.value && (
                <input
                  type="hidden"
                  value={seenAgLifeMerch.value}
                  name={seenAgLifeMerch.googleFormId}
                />
              )}
              {boughtGiftedAgLifeMerch && boughtGiftedAgLifeMerch.value && (
                <input
                  type="hidden"
                  value={boughtGiftedAgLifeMerch.value}
                  name={boughtGiftedAgLifeMerch.googleFormId}
                />
              )}
              {wearAgLifeMerch && wearAgLifeMerch.value && (
                <input
                  type="hidden"
                  value={wearAgLifeMerch.value}
                  name={wearAgLifeMerch.googleFormId}
                />
              )}
              {onlineComm1 && onlineComm1.value === true && (
                <input
                  type="hidden"
                  value={onlineComm1.label}
                  name={onlineComm1.googleFormId}
                />
              )}
              {onlineComm2 && onlineComm2.value === true && (
                <input
                  type="hidden"
                  value={onlineComm2.label}
                  name={onlineComm2.googleFormId}
                />
              )}
              {onlineComm3 && onlineComm3.value === true && (
                <input
                  type="hidden"
                  value={onlineComm3.label}
                  name={onlineComm3.googleFormId}
                />
              )}
              {onlineComm4 && onlineComm4.value === true && (
                <input
                  type="hidden"
                  value={onlineComm4.label}
                  name={onlineComm4.googleFormId}
                />
              )}
              {onlineComm5 && onlineComm5.value === true && (
                <input
                  type="hidden"
                  value={onlineComm5.label}
                  name={onlineComm5.googleFormId}
                />
              )}
              {onlineComm6 && onlineComm6.value === true && (
                <input
                  type="hidden"
                  value={onlineComm6.label}
                  name={onlineComm6.googleFormId}
                />
              )}
              {onlineComm7 && onlineComm7.value === true && (
                <input
                  type="hidden"
                  value={onlineComm7.label}
                  name={onlineComm7.googleFormId}
                />
              )}
              {onlineComm8 && onlineComm8.value === true && (
                <input
                  type="hidden"
                  value={onlineComm8.label}
                  name={onlineComm8.googleFormId}
                />
              )}
              {onlineComm9 && onlineComm9.value === true && (
                <>
                  <input
                    type="hidden"
                    value={"__other_option__"}
                    name={onlineComm9.googleFormId}
                  />
                  <input
                    type="hidden"
                    value={onlineCommOther.value}
                    name={onlineCommOther.googleFormIdOtherResponse}
                  />
                </>
              )}
              {partOfOnlineComm && partOfOnlineComm.value && (
                <input
                  type="hidden"
                  value={partOfOnlineComm.value}
                  name={partOfOnlineComm.googleFormId}
                />
              )}
              {finalComments && finalComments.value && (
                <input
                  type="hidden"
                  value={finalComments.value}
                  name={finalComments.googleFormId}
                />
              )}
              {permissionToCall && permissionToCall.value && (
                <input
                  type="hidden"
                  value={permissionToCall.value}
                  name={permissionToCall.googleFormId}
                />
              )}
              {userContactPhone && userContactPhone.value && (
                <input
                  type="hidden"
                  value={userContactPhone.value}
                  name={userContactPhone.googleFormId}
                />
              )}
              <Grid item xs={12} className={classes.buttonContainer}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 3,
                    marginBottom: 3
                  }}
                >
                  <Button
                    onClick={handleBack}
                    className={classes.stepButtonBack}
                  >
                    Back
                  </Button>
                  <Button type="submit" className={classes.stepButton}>
                    Confirm
                  </Button>
                </Box>
              </Grid>
            </form>
          </div>
        </>
      </Grid>
    </>
  );
}
