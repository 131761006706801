import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Grid,
  Hidden,
  Box,
  IconButton
} from "@material-ui/core";
//@ts-ignore
import ItemsCarousel from "react-items-carousel";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { OverviewContext } from "../../store/OverviewContext";
import { Link } from "react-router-dom";
import { getTitleAndChannelFromVideoName } from "../../utils/strings";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import TryButton from "../Onboarding/TryButton";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(6, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0)
    }
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "4.2rem",
      lineHeight: "4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },
  mobileTabletContainerBottomGradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 40%, rgba(23,23,23,0.9) 90%)",

    height: "90%",
    width: "100%"
  },
  mobileTabletContainerBottomGradientHover: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(33,33,33,1) 100%)",
    height: "100%",
    width: "100%"
  },
  mobileTabletContainer: {
    marginTop: theme.spacing(2),
    overflow: "auto",
    whiteSpace: "nowrap",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  mobileTabletEpisode: {
    display: "inline-block",
    maxWidth: "280px",
    height: "auto",
    whiteSpace: "initial",
    marginRight: theme.spacing(1),
    verticalAlign: "top",
    position: "relative"
  },
  mobileTabletEpisodeDetails: {
    position: "absolute",
    bottom: "10px",
    left: "5px",
    "@media (min-width:600px)": {
      bottom: "5px"
    }
  },
  mobileTabletEpisodeChannel: {
    borderRadius: "50%",
    width: "30px"
  },
  mobileTabletEpisodeTitle: {
    marginTop: theme.spacing(0),
    fontSize: "1rem",
    paddingRight: "10px",
    // @ts-ignore
    color: theme.palette.common.upgrade,
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.1rem"
    }
  },
  mobileTabletEpisodeNumber: {
    marginTop: theme.spacing(0),
    fontSize: "0.7rem",
    marginBottom: "2px",
    paddingRight: "10px",
    // @ts-ignore
    color: theme.palette.common.upgrade,
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "0.8rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "0.9rem",
      lineHeight: "1rem"
    }
  },
  desktopContainer: {
    marginTop: theme.spacing(3)
  },
  episodeContainer: {
    position: "relative",
    "&:hover": {
      cursor: "pointer"
    }
  },
  episodeContainerBottomGradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(33,33,33,1) 90%)",
    height: "50%",
    width: "100%"
  },
  episodeContainerBottomGradientHover: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.4) 10%, rgba(23,23,23,1) 100%)",
    height: "100%",
    width: "100%"
  },
  episodeDetails: {
    position: "absolute",
    bottom: "15px",
    left: "15px"
  },
  chevron: {
    // @ts-ignore
    color: theme.palette.common.lightgray,
    cursor: "pointer",
    padding: "10px",
    fontSize: "1.4rem",
    borderRadius: "50%",
    backgroundColor: "rgba(2,2,2,0.5)",
    "&:hover": {
      color: theme.palette.common.white
    }
  },
  episodeChannel: {
    borderRadius: "50%",
    "@media (min-width:960px)": {
      width: "50px"
    },
    "@media (min-width:1280px)": {
      width: "60px"
    }
  },
  episodeTitle: {
    marginTop: theme.spacing(0.8),
    fontSize: "1.1rem",
    paddingRight: "10px",
    // @ts-ignore
    color: theme.palette.common.upgrade,
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.5rem",
      lineHeight: "1.5rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "1.6rem",
      lineHeight: "1.6rem"
    }
  },
  episodeNumber: {
    marginTop: theme.spacing(0.5),
    fontSize: "1.1rem",
    marginBottom: "2px",
    paddingRight: "10px",
    // @ts-ignore
    color: theme.palette.common.lightgray,
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.1rem",
      lineHeight: "1rem"
    }
  },
  "@global": {
    "@keyframes fadeInFromBottom": {
      from: {
        transform: "translate3d(0,15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    }
  },
  episodeDetailsActiveBottom: {
    animation: "fadeInFromBottom .3s ease-in-out",
    position: "absolute",
    bottom: "25px",
    right: "5px"
  },
  episodeDetailsActivePlayIconButton: {
    fontSize: "1.2rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        // @ts-ignore
        color: theme.palette.background.ffGreen
      }
    }
  },
  iconButton: {
    width: "2rem",
    height: "2rem"
  },
  episodeContainerBottomGradientWatched: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.4) 10%, rgba(23,23,23,1) 100%)",
    height: "100%",
    width: "100%"
  },
  episodeWatchedLabel: {
    position: "absolute",
    top: "5px",
    right: "0px",
    backgroundColor: "rgba(23,23,23,0.4)",
    color: "#fff",
    padding: theme.spacing(0.7),
    display: "flex"
  },
  episodeWatchedLabelText: {
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  episodeWatchedLabelIcon: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginRight: theme.spacing(1)
  },
  textContainer: {
    textAlign: "center",
    margin: theme.spacing(3, 0)
  },
  textHeading: {
    letterSpacing: "-1px",
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    fontWeight: 500,
    textShadow: "-1px 1px 1px #171717",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.6rem"
    },
    "@media (min-width:960px)": {
      letterSpacing: "-1px",
      fontSize: "1.8rem",
      lineHeight: "1.8rem",
      fontWeight: 500,
      textShadow: "-2px 2px 2px #171717",
      marginBottom: theme.spacing(1)
    }
  },
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "300px"
    }
  },
  buttonSubText: {
    fontSize: "0.9rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    "@media (min-width:600px)": {
      textAlign: "center",
      marginLeft: 0
    },
    "@media (min-width:960px)": {}
  }
}));

const Previews = () => {
  const classes = useStyles();

  const { overview } = useContext(OverviewContext);

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [hoverIdx, setHoverIdx] = useState();

  const onEpisodeEnter = index => {
    setHoverIdx(index);
  };

  const onEpisodeLeave = () => {
    setHoverIdx(undefined);
  };

  const chevronWidth = 0;

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="lg" fixed>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.title}>
                Check Out These
              </Typography>
              <Typography variant="h2" className={classes.titleLarge}>
                Previews
              </Typography>
            </Grid>
            {/* Visible XS, SM Only */}
            {overview && overview.previewVideos && (
              <Hidden only={["md", "lg", "xl"]}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={classes.mobileTabletContainer}
                >
                  {overview &&
                    overview.previewVideos.videos.map(episode => {
                      const {
                        title,
                        episode: episodeNumber
                      } = getTitleAndChannelFromVideoName(episode.name);

                      return (
                        <React.Fragment key={episode.id}>
                          <Link
                            to={"video-details/" + episode.slug}
                            style={{ textDecoration: "none" }}
                          >
                            <Box
                              className={classes.mobileTabletEpisode}
                              key={episode.id}
                            >
                              <div
                                className={
                                  classes.mobileTabletContainerBottomGradient
                                }
                              />
                              <img
                                src={episode.thumbnailThumb}
                                style={{ width: "100%" }}
                                alt={episode.name}
                              />

                              <Box
                                className={classes.mobileTabletEpisodeDetails}
                              >
                                {(() => {
                                  if (episode.tags.includes("straight6")) {
                                    return (
                                      <img
                                        src="https://media-test.farmflix.tv/03417afd-2b1b-49b6-a041-9e4a8038b6fc"
                                        alt=""
                                        className={
                                          classes.mobileTabletEpisodeChannel
                                        }
                                      />
                                    );
                                  } else if (episode.tags.includes("aglife")) {
                                    return (
                                      <img
                                        src="https://media-test.farmflix.tv/3512186f-0058-4f3d-8fc3-b09583557f4d"
                                        alt=""
                                        className={
                                          classes.mobileTabletEpisodeChannel
                                        }
                                      />
                                    );
                                  } else if (
                                    episode.tags.includes("stockyards")
                                  ) {
                                    return (
                                      <img
                                        src="https://media-test.farmflix.tv/1f2e8165-489b-46e6-927f-51a82a040636"
                                        alt=""
                                        className={
                                          classes.mobileTabletEpisodeChannel
                                        }
                                      />
                                    );
                                  } else if (episode.tags.includes("loft")) {
                                    return (
                                      <img
                                        src="https://media-test.farmflix.tv/46fb0173-5522-42c7-9518-93bd4ec99430"
                                        alt=""
                                        className={
                                          classes.mobileTabletEpisodeChannel
                                        }
                                      />
                                    );
                                  }
                                })()}
                                {title && (
                                  <Typography
                                    variant="body1"
                                    className={classes.mobileTabletEpisodeTitle}
                                  >
                                    {title}
                                  </Typography>
                                )}
                                {episodeNumber && (
                                  <Typography
                                    variant="body2"
                                    className={
                                      classes.mobileTabletEpisodeNumber
                                    }
                                  >
                                    Ep. {episodeNumber}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Link>
                        </React.Fragment>
                      );
                    })}
                </Grid>
              </Hidden>
            )}
            {/* Visible MD & Up Only - Use Carousel Here */}
            {overview && overview.previewVideos && (
              <>
                <Hidden only={["xs", "sm"]}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.desktopContainer}
                  >
                    <ItemsCarousel
                      requestToChangeActive={setActiveItemIndex}
                      activeItemIndex={activeItemIndex}
                      numberOfCards={2}
                      slidesToScroll={4}
                      gutter={4}
                      leftChevron={
                        <div>
                          <ArrowBackIosRoundedIcon
                            className={classes.chevron}
                          />
                        </div>
                      }
                      rightChevron={
                        <div>
                          <ArrowForwardIosRoundedIcon
                            className={classes.chevron}
                          />
                        </div>
                      }
                      chevronWidth={chevronWidth}
                    >
                      {overview &&
                        overview.previewVideos.videos.map((episode, index) => {
                          const {
                            title,
                            episode: episodeNumber
                          } = getTitleAndChannelFromVideoName(episode.name);

                          return (
                            <Link
                              key={episode.id}
                              to={"video-details/" + episode.slug}
                              style={{ textDecoration: "none" }}
                            >
                              <Box
                                className={classes.episodeContainer}
                                onMouseEnter={e => onEpisodeEnter(index)}
                                onMouseLeave={() => onEpisodeLeave()}
                              >
                                <div
                                  className={
                                    hoverIdx === index
                                      ? classes.episodeContainerBottomGradientHover
                                      : episode.watched
                                      ? classes.episodeContainerBottomGradientWatched
                                      : classes.episodeContainerBottomGradient
                                  }
                                />
                                <img
                                  src={episode.thumbnailThumb}
                                  style={{ width: "100%" }}
                                  alt={episode.name}
                                />

                                {episode.watched && (
                                  <div className={classes.episodeWatchedLabel}>
                                    <CheckCircleRoundedIcon
                                      className={
                                        classes.episodeWatchedLabelIcon
                                      }
                                    />
                                    <Typography
                                      variant="body2"
                                      className={
                                        classes.episodeWatchedLabelText
                                      }
                                    >
                                      Watched
                                    </Typography>
                                  </div>
                                )}

                                <Box className={classes.episodeDetails}>
                                  {(() => {
                                    if (episode.tags.includes("straight6")) {
                                      return (
                                        <img
                                          src="https://media-test.farmflix.tv/03417afd-2b1b-49b6-a041-9e4a8038b6fc"
                                          alt=""
                                          className={classes.episodeChannel}
                                        />
                                      );
                                    } else if (
                                      episode.tags.includes("aglife")
                                    ) {
                                      return (
                                        <img
                                          src="https://media-test.farmflix.tv/3512186f-0058-4f3d-8fc3-b09583557f4d"
                                          alt=""
                                          className={classes.episodeChannel}
                                        />
                                      );
                                    } else if (
                                      episode.tags.includes("stockyards")
                                    ) {
                                      return (
                                        <img
                                          src="https://media-test.farmflix.tv/1f2e8165-489b-46e6-927f-51a82a040636"
                                          alt=""
                                          className={classes.episodeChannel}
                                        />
                                      );
                                    } else if (episode.tags.includes("loft")) {
                                      return (
                                        <img
                                          src="https://media-test.farmflix.tv/46fb0173-5522-42c7-9518-93bd4ec99430"
                                          alt=""
                                          className={classes.episodeChannel}
                                        />
                                      );
                                    }
                                  })()}
                                  {title && (
                                    <Typography
                                      className={classes.episodeTitle}
                                      variant="body1"
                                    >
                                      {title}
                                    </Typography>
                                  )}
                                  {episodeNumber && (
                                    <Typography
                                      className={classes.episodeNumber}
                                      variant="body2"
                                    >
                                      Ep.{episodeNumber.replace("Ep", "")}
                                    </Typography>
                                  )}
                                </Box>

                                {hoverIdx === index && (
                                  <>
                                    <Box
                                      className={
                                        classes.episodeDetailsActiveBottom
                                      }
                                    >
                                      <IconButton
                                        aria-label="Play Episode"
                                        className={
                                          classes.episodeDetailsActivePlayIconButton
                                        }
                                      >
                                        <PlayArrowIcon
                                          className={classes.iconButton}
                                        />
                                      </IconButton>
                                    </Box>
                                  </>
                                )}
                              </Box>
                            </Link>
                          );
                        })}
                    </ItemsCarousel>
                  </Grid>
                </Hidden>
              </>
            )}
            {overview && (
              <>
                {/*
                  Doors Open & Trials Open - show Free Trial
                  Doors Closed & Trials Open - show Free Trial
                  Doors Open & Trials Closed - show Join Now
                  Doors Closed & Trials Closed - show Free Trial (notify me status)
                */}
                {overview.doors.open && overview.doors.trialsOpen && (
                  <Grid item xs={12} className={classes.textContainer}>
                    <Typography variant="h4" className={classes.textHeading}>
                      You can watch these full episodes now!
                    </Typography>

                    <TryButton
                      label="Take Free Trial"
                      size="large"
                      className={classes.button}
                    />
                    <Typography
                      variant="body1"
                      className={classes.buttonSubText}
                    >
                      No Payment Required for Free Trial
                    </Typography>
                  </Grid>
                )}

                {!overview.doors.open && overview.doors.trialsOpen && (
                  <Grid item xs={12} className={classes.textContainer}>
                    <Typography variant="h4" className={classes.textHeading}>
                      You can watch these full episodes now!
                    </Typography>

                    <TryButton
                      label="Take Free Trial"
                      size="large"
                      className={classes.button}
                    />
                    <Typography
                      variant="body1"
                      className={classes.buttonSubText}
                    >
                      No Payment Required for Free Trial
                    </Typography>
                  </Grid>
                )}

                {overview.doors.open && !overview.doors.trialsOpen && (
                  <Grid item xs={12} className={classes.textContainer}>
                    <Typography variant="h4" className={classes.textHeading}>
                      You can watch these full episodes now!
                    </Typography>

                    <TryButton
                      label="Join Now"
                      size="large"
                      className={classes.button}
                    />
                    <Typography
                      variant="body1"
                      className={classes.buttonSubText}
                    >
                      <b>Don&apos;t Miss Out!</b>
                      <br />
                      Last chance to join this season!
                    </Typography>
                  </Grid>
                )}

                {!overview.doors.trialsOpen && !overview.doors.open && (
                  <Grid item xs={12} className={classes.textContainer}>
                    <TryButton
                      label="Take Free Trial"
                      size="large"
                      className={classes.button}
                    />
                    <Typography
                      variant="body1"
                      className={classes.buttonSubText}
                    >
                      No Payment Required for Free Trial
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Previews;
