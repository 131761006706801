import React, { useState, useContext } from "react";
import {
  Popover,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Typography
} from "@material-ui/core";
import { SearchContext } from "./SearchContainer";
import useStyles from "./styles";
import SortIcon from "@material-ui/icons/Sort";
import { VideoSort } from "@booyaltd/core";

const VIDEO_SORT_LABELS: Omit<
  { [key in VideoSort]: string },
  | "favouritedDate"
  | "watchedDate"
  | "publishDateAsc"
  | "captureDateAsc"
  | "customOrder"
> = {
  [VideoSort.Relevance]: "Relevance",
  [VideoSort.Popularity]: "Popularity",
  [VideoSort.PublishDate]: "Publish Date",
  [VideoSort.CaptureDate]: "Capture Date",
  [VideoSort.FavouritedCount]: "Favourite Count",
  [VideoSort.VideoLength]: "Video Length",
  [VideoSort.VideoLengthDesc]: "Video Length (Desc)"
};

const RegionFilter = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { search, updateSearch } = useContext(SearchContext);

  const handleFilterOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "region-popover" : undefined;

  const handleFilterChange = (e: any, sort: VideoSort) => {
    updateSearch({ sort: sort });
  };

  return (
    <>
      <Chip
        className={classes.filterChip}
        label={
          <>
            <Box display="flex" alignItems="center">
              <SortIcon
                className={
                  search && search.sort
                    ? classes.filterChipIconActive
                    : classes.filterChipIcon
                }
              />
              <Typography className={classes.filterChipLabel}>
                {// @ts-ignore
                search && search.sort ? VIDEO_SORT_LABELS[search.sort] : "Sort"}
              </Typography>
            </Box>
          </>
        }
        aria-describedby={id}
        onClick={handleFilterOpen}
      />

      <Popover
        classes={{ paper: classes.popOverContainer }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <RadioGroup
          aria-label="region_filter"
          name="regionFilter"
          value={search?.sort}
          // @ts-ignore
          onChange={handleFilterChange}
        >
          {/* Get Regions Here  */}
          {Object.keys(VIDEO_SORT_LABELS).map(sort => (
            <FormControlLabel
              key={sort.toString()}
              classes={{ label: classes.filterLabel }}
              value={sort.toString()}
              control={<Radio color="primary" />}
              // @ts-ignore
              label={VIDEO_SORT_LABELS[sort]}
            />
          ))}
        </RadioGroup>
      </Popover>
    </>
  );
};

export default RegionFilter;
