import React, { useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MemberSurveyContext } from "../../store/MemberSurveyContext";
import {
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormGroup,
  Radio,
  TextField,
  Button,
  Checkbox,
  Typography,
  Divider
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    // @ts-ignore
  },
  stepHeading: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    color: theme.palette.background.ffNavyLighter,
    fontWeight: 600,
    // textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    }
  },
  divider: {
    height: "1px",
    margin: "6px 0 12px 0",
    backgroundColor: "#b7b7b7"
  },
  formStep: {
    marginTop: "24px"
  },
  formQuestion: {
    marginBottom: "36px",
    "& .MuiFormControl-root": {
      width: "100%"
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem",
      marginBottom: "18px"
    },
    "& .MuiTypography-body1": {
      color: "#3d3d3d"
    }
  },
  formGroup: {
    "& .MuiFormControl-root label": {
      marginBottom: "6px"
    },
    "& .MuiTypography-body1": {
      marginBottom: "6px"
    }
  },
  buttonContainer: {
    margin: "24px 0"
  },
  thirdHeading: {
    marginBottom: "12px",
    fontWeight: 500
  },
  stepButton: {
    border: "none",
    "&:hover": {
      border: "none",
      backgroundColor: "#99ca3c"
    }
  },
  stepButtonBack: {
    backgroundColor: "#fff",
    border: "2px solid #171717",
    "&:hover": {
      backgroundColor: "#fafafa",
      border: "2px solid #171717"
    }
  },
  subHeading: {
    fontSize: "1.1rem",
    margin: "24px 0 12px 0"
  },
  selectBump: {
    marginBottom: "24px"
  },
  topBump: {
    marginTop: "24px"
  }
}));

export default function FourthStep() {
  const classes = useStyles();

  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant
  } = useContext(MemberSurveyContext);
  const {
    joinMembership,
    waitedMembership,
    reasonForJoining1,
    reasonForJoining2,
    reasonForJoining3,
    reasonForJoining4,
    reasonForJoining5,
    reasonForJoining6,
    reasonForJoining7,
    reasonForJoiningOther,
    useFreeTrial,
    firstLoginLayout,
    firstLoginLayoutOther,
    watchBehaviour,
    skipAgLife,
    skipStraight6,
    skipStockyards,
    skipWhy,
    firstChannel,
    secondChannel,
    thirdChannel,
    enjoyingTopic1,
    enjoyingTopic2,
    enjoyingTopic3,
    enjoyingTopic4,
    enjoyingTopic5,
    enjoyingTopic6,
    enjoyingTopicOther,
    idealMixWeek1,
    idealMixWeek2,
    idealMixWeek3,
    idealMixWeek4,
    missTheLoft,
    watchRoutine,
    episodeLength,
    breakEpisodes,
    resumePlayback,
    resumePlaybackWhy,
    reWatchOldEpisodes,
    episodeRegion1,
    episodeRegion2,
    episodeRegion3,
    episodeRegion4,
    episodeRegion5,
    episodeRegion6,
    episodeRegionWhy
  } = formValues;

  const isError = useCallback(
    () =>
      Object.keys({}).some(
        name =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues]
  );

  return (
    <>
      <Grid container spacing={2} className={classes.formStep}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.stepHeading}>
            Membership
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        {/* Join Membership */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Did you decide to join membership immediately or did you put it
              off?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="joinMembership"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Immediately"
                checked={joinMembership.value === "Immediately"}
                control={<Radio />}
                label="Immediately"
                required={true}
              />
              <FormControlLabel
                value="Waited"
                checked={joinMembership.value === "Waited"}
                control={<Radio />}
                label="Waited"
                required={true}
              />
            </RadioGroup>
          </FormControl>

          {joinMembership.value === "Waited" && (
            <Grid item xs={12} sm={12} className={classes.topBump}>
              <FormLabel id="demo-radio-buttons-group-label">
                You waited, why did you put it off?
              </FormLabel>
              <TextField
                multiline={true}
                variant={variant}
                rows={5}
                fullWidth
                name="waitedMembership"
                placeholder=""
                value={waitedMembership.value}
                onChange={handleChange}
                error={!!waitedMembership.error}
                helperText={waitedMembership.error}
                required={waitedMembership.required}
              />
            </Grid>
          )}
        </Grid>

        {/* What pushed you over the edge to finally join FarmFLiX? */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              What was your reason(s) for joining FarmFLiX
            </FormLabel>
            <FormGroup>
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="reasonForJoining1"
                      onChange={handleChange}
                      value={reasonForJoining1.value}
                      checked={reasonForJoining1.value}
                    />
                  }
                  label="To watch a specific episode"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="reasonForJoining2"
                      onChange={handleChange}
                      checked={reasonForJoining2.value}
                      value={reasonForJoining2.value}
                    />
                  }
                  label="Didn’t want to miss the lock deadline"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="reasonForJoining3"
                      onChange={handleChange}
                      checked={reasonForJoining3.value}
                      value={reasonForJoining3.value}
                    />
                  }
                  label="Needed some pandemic entertainment"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="reasonForJoining4"
                      onChange={handleChange}
                      checked={reasonForJoining4.value}
                      value={reasonForJoining4.value}
                    />
                  }
                  label="I followed the FarmFLiX Youtube channel and wanted to support you"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="reasonForJoining5"
                      onChange={handleChange}
                      checked={reasonForJoining5.value}
                      value={reasonForJoining5.value}
                    />
                  }
                  label="So many ad’s that I had to see what the fuss was about"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="reasonForJoining6"
                      onChange={handleChange}
                      checked={reasonForJoining6.value}
                      value={reasonForJoining6.value}
                    />
                  }
                  label="Someone I know told me I would enjoy it"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="reasonForJoining7"
                      onChange={handleChange}
                      checked={reasonForJoining7.value}
                      value={reasonForJoining7.value}
                    />
                  }
                  label="Other"
                />
              </>
            </FormGroup>
          </FormControl>

          {/* If 'Other' is checked  */}
          {reasonForJoining7.value === true && (
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" className={classes.subHeading}>
                Other, please specify.
              </Typography>
              <TextField
                multiline={true}
                variant={variant}
                rows={5}
                fullWidth
                name="reasonForJoiningOther"
                placeholder=""
                value={reasonForJoiningOther.value}
                onChange={handleChange}
                error={!!reasonForJoiningOther.error}
                helperText={reasonForJoiningOther.error}
                required={reasonForJoiningOther.required}
              />
            </Grid>
          )}
        </Grid>

        {/* Use the Free Trial */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Did you use the free trial before you joined?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="useFreeTrial"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Yes"
                checked={useFreeTrial.value === "Yes"}
                control={<Radio />}
                label="Yes"
                required={true}
              />
              <FormControlLabel
                value="No"
                checked={useFreeTrial.value === "No"}
                control={<Radio />}
                label="No"
                required={true}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* When you first logged into FarmFLiX was the layout: */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              When you first logged into FarmFLiX was the layout:
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="firstLoginLayout"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Simple"
                checked={firstLoginLayout.value === "Simple"}
                control={<Radio />}
                label="Simple"
                required={true}
              />
              <FormControlLabel
                value="Confusing"
                checked={firstLoginLayout.value === "Confusing"}
                control={<Radio />}
                label="Confusing"
                required={true}
              />
              <FormControlLabel
                value="Other"
                checked={firstLoginLayout.value === "Other"}
                control={<Radio />}
                label="Other"
                required={true}
              />
            </RadioGroup>
          </FormControl>

          {/* If 'Other' is checked  */}
          {firstLoginLayout.value === "Other" && (
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" className={classes.subHeading}>
                Other, please specify.
              </Typography>
              <TextField
                multiline={true}
                variant={variant}
                rows={5}
                fullWidth
                name="firstLoginLayoutOther"
                placeholder=""
                value={firstLoginLayoutOther.value}
                onChange={handleChange}
                error={!!firstLoginLayoutOther.error}
                helperText={firstLoginLayoutOther.error}
                required={firstLoginLayoutOther.required}
              />
            </Grid>
          )}
        </Grid>

        {/* Do you watch every episode or skip a few: */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Do you watch every episode we release or skip a few?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="watchBehaviour"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Watch All"
                checked={watchBehaviour.value === "Watch All"}
                control={<Radio />}
                label="Watch All"
                required={true}
              />
              <FormControlLabel
                value="Skip a few"
                checked={watchBehaviour.value === "Skip a few"}
                control={<Radio />}
                label="Skip a few"
                required={true}
              />
            </RadioGroup>
          </FormControl>

          {/* If 'Watch Behaviour' is checked as Skip  */}
          {watchBehaviour.value === "Watch All" && (
            <Grid item xs={12} sm={12} style={{ marginTop: "36px" }}>
              <FormLabel component="legend">
                Rank the channels in order of your favourite
              </FormLabel>

              <Typography variant="body1" className={classes.thirdHeading}>
                First Preference
              </Typography>
              <TextField
                className={classes.selectBump}
                variant={variant}
                fullWidth
                select
                SelectProps={{
                  native: true
                }}
                name="firstChannel"
                value={firstChannel.value}
                onChange={handleChange}
                error={!!firstChannel.error}
                helperText={firstChannel.error}
                required={firstChannel.required}
              >
                <option value="">Please select... </option>
                <option value="AgLife">AgLife </option>
                <option value="Straight6">Straight6</option>
                <option value="Stockyards">Stockyards</option>
              </TextField>

              <Typography variant="body1" className={classes.thirdHeading}>
                Second Preference
              </Typography>
              <TextField
                className={classes.selectBump}
                variant={variant}
                fullWidth
                select
                SelectProps={{
                  native: true
                }}
                name="secondChannel"
                value={secondChannel.value}
                onChange={handleChange}
                error={!!secondChannel.error}
                helperText={secondChannel.error}
                required={secondChannel.required}
              >
                <option value="">Please select... </option>
                <option value="AgLife">AgLife </option>
                <option value="Straight6">Straight6</option>
                <option value="Stockyards">Stockyards</option>
              </TextField>

              <Typography variant="body1" className={classes.thirdHeading}>
                Third Preference
              </Typography>
              <TextField
                variant={variant}
                fullWidth
                select
                SelectProps={{
                  native: true
                }}
                name="thirdChannel"
                value={thirdChannel.value}
                onChange={handleChange}
                error={!!thirdChannel.error}
                helperText={thirdChannel.error}
                required={thirdChannel.required}
              >
                <option value="">Please select... </option>
                <option value="AgLife">AgLife </option>
                <option value="Straight6">Straight6</option>
                <option value="Stockyards">Stockyards</option>
              </TextField>
            </Grid>
          )}

          {/* If 'Watch Behaviour' is checked as Skip  */}
          {watchBehaviour.value === "Skip a few" && (
            <>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.formQuestion}
                style={{ marginTop: "36px" }}
              >
                <FormLabel component="legend">
                  What channels do you skip?
                </FormLabel>
                <FormGroup>
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="skipAgLife"
                          onChange={handleChange}
                          value={skipAgLife.value}
                          checked={skipAgLife.value}
                        />
                      }
                      label="AgLife"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="skipStraight6"
                          onChange={handleChange}
                          value={skipStraight6.value}
                          checked={skipStraight6.value}
                        />
                      }
                      label="Straight6"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="skipStockyards"
                          onChange={handleChange}
                          value={skipStockyards.value}
                          checked={skipStockyards.value}
                        />
                      }
                      label="Stockyards"
                    />
                  </>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl component="fieldset">
                  <FormLabel id="demo-radio-buttons-group-label">
                    Why do you skip these?
                  </FormLabel>
                  <TextField
                    multiline={true}
                    variant={variant}
                    rows={10}
                    fullWidth
                    name="skipWhy"
                    placeholder=""
                    value={skipWhy.value}
                    onChange={handleChange}
                    error={!!skipWhy.error}
                    helperText={skipWhy.error}
                    required={skipWhy.required}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>

        {/* What topics do you enjoy in the episodes? */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              What topics do you enjoy in the episodes?
            </FormLabel>
            <FormGroup>
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enjoyingTopic1"
                      onChange={handleChange}
                      value={enjoyingTopic1.value}
                      checked={enjoyingTopic1.value}
                    />
                  }
                  label="Pit silage"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enjoyingTopic2"
                      onChange={handleChange}
                      checked={enjoyingTopic2.value}
                      value={enjoyingTopic2.value}
                    />
                  }
                  label="Manure"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enjoyingTopic3"
                      onChange={handleChange}
                      checked={enjoyingTopic3.value}
                      value={enjoyingTopic3.value}
                    />
                  }
                  label="Livestock Farming"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enjoyingTopic4"
                      onChange={handleChange}
                      checked={enjoyingTopic4.value}
                      value={enjoyingTopic4.value}
                    />
                  }
                  label="Landwork"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enjoyingTopic5"
                      onChange={handleChange}
                      checked={enjoyingTopic5.value}
                      value={enjoyingTopic5.value}
                    />
                  }
                  label="Harvest"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enjoyingTopic6"
                      onChange={handleChange}
                      checked={enjoyingTopic6.value}
                      value={enjoyingTopic6.value}
                    />
                  }
                  label="Other"
                />
              </>
            </FormGroup>
          </FormControl>

          {/* If 'Other' is checked  */}
          {enjoyingTopic6.value === true && (
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" className={classes.subHeading}>
                Other, please specify.
              </Typography>
              <TextField
                multiline={true}
                variant={variant}
                rows={5}
                fullWidth
                name="enjoyingTopicOther"
                placeholder=""
                value={enjoyingTopicOther.value}
                onChange={handleChange}
                error={!!enjoyingTopicOther.error}
                helperText={enjoyingTopicOther.error}
                required={enjoyingTopicOther.required}
              />
            </Grid>
          )}
        </Grid>

        {/* Awkward question */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              What&apos;s your ideal mix of content in a 4 week month with one
              new episode per week? e.g we currently try to deliver at least 1
              Silage Straight6, 1 Non-silage Straight6 &amp; 1 Livestock episode
              per month.
            </FormLabel>
            <Typography variant="body1" className={classes.thirdHeading}>
              Week 1
            </Typography>
            <TextField
              className={classes.selectBump}
              variant={variant}
              fullWidth
              select
              SelectProps={{
                native: true
              }}
              name="idealMixWeek1"
              value={idealMixWeek1.value}
              onChange={handleChange}
              error={!!idealMixWeek1.error}
              helperText={idealMixWeek1.error}
              required={idealMixWeek1.required}
            >
              <option value="">Please select...</option>
              <option value="AgLife">AgLife</option>
              <option value="Stockyards">Stockyards</option>
              <option value="Straight6 - Pit Silage">
                Straight6 - Pit Silage
              </option>
              <option value="Straight6 - Other">Straight6 - Other</option>
              <option value="The Loft">The Loft</option>
            </TextField>
          </FormControl>
          <FormControl>
            <Typography variant="body1" className={classes.thirdHeading}>
              Week 2
            </Typography>
            <TextField
              className={classes.selectBump}
              variant={variant}
              fullWidth
              select
              SelectProps={{
                native: true
              }}
              name="idealMixWeek2"
              value={idealMixWeek2.value}
              onChange={handleChange}
              error={!!idealMixWeek2.error}
              helperText={idealMixWeek2.error}
              required={idealMixWeek2.required}
            >
              <option value="">Please select...</option>
              <option value="AgLife">AgLife</option>
              <option value="Stockyards">Stockyards</option>
              <option value="Straight6 - Pit Silage">
                Straight6 - Pit Silage
              </option>
              <option value="Straight6 - Other">Straight6 - Other</option>
              <option value="The Loft">The Loft</option>
            </TextField>
          </FormControl>
          <FormControl>
            <Typography variant="body1" className={classes.thirdHeading}>
              Week 3
            </Typography>
            <TextField
              className={classes.selectBump}
              variant={variant}
              fullWidth
              select
              SelectProps={{
                native: true
              }}
              name="idealMixWeek3"
              value={idealMixWeek3.value}
              onChange={handleChange}
              error={!!idealMixWeek3.error}
              helperText={idealMixWeek3.error}
              required={idealMixWeek3.required}
            >
              <option value="">Please select...</option>
              <option value="AgLife">AgLife</option>
              <option value="Stockyards">Stockyards</option>
              <option value="Straight6 - Pit Silage">
                Straight6 - Pit Silage
              </option>
              <option value="Straight6 - Other">Straight6 - Other</option>
              <option value="The Loft">The Loft</option>
            </TextField>
          </FormControl>
          <FormControl>
            <Typography variant="body1" className={classes.thirdHeading}>
              Week 4
            </Typography>
            <TextField
              className={classes.selectBump}
              variant={variant}
              fullWidth
              select
              SelectProps={{
                native: true
              }}
              name="idealMixWeek4"
              value={idealMixWeek4.value}
              onChange={handleChange}
              error={!!idealMixWeek4.error}
              helperText={idealMixWeek4.error}
              required={idealMixWeek4.required}
            >
              <option value="">Please select...</option>
              <option value="AgLife">AgLife</option>
              <option value="Stockyards">Stockyards</option>
              <option value="Straight6 - Pit Silage">
                Straight6 - Pit Silage
              </option>
              <option value="Straight6 - Other">Straight6 - Other</option>
              <option value="The Loft">The Loft</option>
            </TextField>
          </FormControl>
        </Grid>
      </Grid>

      {/* Do you miss The Loft? */}
      <Grid item xs={12} sm={12} className={classes.formQuestion}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Do you miss The Loft?
          </FormLabel>
          <RadioGroup
            onChange={handleChange}
            name="missTheLoft"
            className={classes.formGroup}
          >
            <FormControlLabel
              value="Yes"
              checked={missTheLoft.value === "Yes"}
              control={<Radio />}
              label="Yes"
              required={true}
            />
            <FormControlLabel
              value="No"
              checked={missTheLoft.value === "No"}
              control={<Radio />}
              label="No"
              required={true}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {/* Do you watch weekly as routine or do you save them up to binge watch?  */}
      <Grid item xs={12} sm={12} className={classes.formQuestion}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Do you watch weekly as routine or do you save them up to binge
            watch?
          </FormLabel>
          <RadioGroup
            onChange={handleChange}
            name="watchRoutine"
            className={classes.formGroup}
          >
            <FormControlLabel
              value="Weekly"
              checked={watchRoutine.value === "Weekly"}
              control={<Radio />}
              label="Weekly"
              required={true}
            />
            <FormControlLabel
              value="Binge"
              checked={watchRoutine.value === "Binge"}
              control={<Radio />}
              label="Binge"
              required={true}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {/* What episode length do you like best? */}
      <Grid item xs={12} sm={12} className={classes.formQuestion}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            What episode length do you like best?
          </FormLabel>
          <RadioGroup
            onChange={handleChange}
            name="episodeLength"
            className={classes.formGroup}
          >
            <FormControlLabel
              value="Less than 20 mins"
              checked={episodeLength.value === "Less than 20 mins"}
              control={<Radio />}
              label="Less than 20 mins"
              required={true}
            />
            <FormControlLabel
              value="20 - 30 mins"
              checked={episodeLength.value === "20 - 30 mins"}
              control={<Radio />}
              label="20 - 30 mins"
              required={true}
            />

            <FormControlLabel
              value="31 - 40 mins"
              checked={episodeLength.value === "31 - 40 mins"}
              control={<Radio />}
              label="31 - 40 mins"
              required={true}
            />

            <FormControlLabel
              value="More than 40 mins"
              checked={episodeLength.value === "More than 40 mins"}
              control={<Radio />}
              label="More than 40 mins"
              required={true}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {/*  Would you prefer us to break episodes that are over 40 minutes into
            two parts to make them easier to complete in a single viewing?*/}
      <Grid item xs={12} sm={12} className={classes.formQuestion}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Would you prefer us to break episodes that are over 40 minutes into
            two parts to make them easier to complete in a single viewing?
          </FormLabel>
          <RadioGroup
            onChange={handleChange}
            name="breakEpisodes"
            className={classes.formGroup}
          >
            <FormControlLabel
              value="Yes"
              checked={breakEpisodes.value === "Yes"}
              control={<Radio />}
              label="Yes"
              required={true}
            />
            <FormControlLabel
              value="No"
              checked={breakEpisodes.value === "No"}
              control={<Radio />}
              label="No"
              required={true}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {/*  Does our resume playback position work well?*/}
      <Grid item xs={12} sm={12} className={classes.formQuestion}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Does our resume playback position work well?
          </FormLabel>
          <RadioGroup
            onChange={handleChange}
            name="resumePlayback"
            className={classes.formGroup}
          >
            <FormControlLabel
              value="Yes"
              checked={resumePlayback.value === "Yes"}
              control={<Radio />}
              label="Yes"
              required={true}
            />
            <FormControlLabel
              value="No"
              checked={resumePlayback.value === "No"}
              control={<Radio />}
              label="No"
              required={true}
            />
          </RadioGroup>
        </FormControl>

        {/* If 'Other' is checked  */}
        {resumePlayback.value === "No" && (
          <Grid item xs={12} sm={12} className={classes.topBump}>
            <FormLabel id="demo-radio-buttons-group-label">
              Resume playback doesn&apos;t work well? Why?
            </FormLabel>
            <TextField
              multiline={true}
              variant={variant}
              rows={5}
              fullWidth
              name="resumePlaybackWhy"
              placeholder=""
              value={resumePlaybackWhy.value}
              onChange={handleChange}
              error={!!resumePlaybackWhy.error}
              helperText={resumePlaybackWhy.error}
              required={resumePlaybackWhy.required}
            />
          </Grid>
        )}
      </Grid>

      {/*  Do you re-watch older episodes in the archive? */}
      <Grid item xs={12} sm={12} className={classes.formQuestion}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Do you re-watch older episodes in the archive?
          </FormLabel>
          <RadioGroup
            onChange={handleChange}
            name="reWatchOldEpisodes"
            className={classes.formGroup}
          >
            <FormControlLabel
              value="Yes"
              checked={reWatchOldEpisodes.value === "Yes"}
              control={<Radio />}
              label="Yes"
              required={true}
            />
            <FormControlLabel
              value="No"
              checked={reWatchOldEpisodes.value === "No"}
              control={<Radio />}
              label="No"
              required={true}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {/* What region do you like to watch episodes from?
       */}
      <Grid item xs={12} sm={12} className={classes.formQuestion}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            What region do you like to watch episodes from?
          </FormLabel>
          <FormGroup>
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    name="episodeRegion1"
                    onChange={handleChange}
                    value={episodeRegion1.value}
                    checked={episodeRegion1.value}
                  />
                }
                label="Northern Ireland"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="episodeRegion2"
                    onChange={handleChange}
                    checked={episodeRegion2.value}
                    value={episodeRegion2.value}
                  />
                }
                label="Republic of Ireland"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="episodeRegion3"
                    onChange={handleChange}
                    checked={episodeRegion3.value}
                    value={episodeRegion3.value}
                  />
                }
                label="England"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="episodeRegion4"
                    onChange={handleChange}
                    checked={episodeRegion4.value}
                    value={episodeRegion4.value}
                  />
                }
                label="Scotland"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="episodeRegion5"
                    onChange={handleChange}
                    checked={episodeRegion5.value}
                    value={episodeRegion5.value}
                  />
                }
                label="Wales"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="episodeRegion6"
                    onChange={handleChange}
                    checked={episodeRegion6.value}
                    value={episodeRegion6.value}
                  />
                }
                label="USA"
              />
            </>
          </FormGroup>
        </FormControl>
        <FormControl style={{ marginTop: "36px" }}>
          <FormLabel id="demo-radio-buttons-group-label">Why?</FormLabel>
          <TextField
            multiline={true}
            variant={variant}
            rows={10}
            fullWidth
            name="episodeRegionWhy"
            placeholder=""
            value={episodeRegionWhy.value}
            onChange={handleChange}
            error={!!episodeRegionWhy.error}
            helperText={episodeRegionWhy.error}
            required={episodeRegionWhy.required}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} className={classes.buttonContainer}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 3
          }}
        >
          <Button onClick={handleBack} className={classes.stepButtonBack}>
            Back
          </Button>
          <Button
            className={classes.stepButton}
            variant="contained"
            disabled={isError()}
            color="primary"
            onClick={!isError() ? handleNext : () => null}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </>
  );
}
