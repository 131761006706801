import React, { FC, useCallback, useContext } from "react";
import {
  Grid,
  Container,
  Backdrop,
  CircularProgress,
  Typography
} from "@material-ui/core";
import {
  InitialiseParams,
  OnboardingContext,
  OnboardingProvider,
  Step
} from "../../../store/OnboardingContext";
import Stepper from "@material-ui/core/Stepper";
import StepperStep from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import useStyles from "./SignUpForm.styles";
import SignUpStepBlank from "./Steps/SignUpStepBlank";
import SignUpStepDetails from "./Steps/SignUpStepDetails";
import SignUpStepPhone from "./Steps/SignUpStepPhone";
import SignUpStepPurchase from "./Steps/SignUpStepPurchase";
import SignUpStepSuccess from "./Steps/SignUpStepSuccess";
import SignUpStepGiftcard from "./Steps/SignUpStepGiftcard";

const SignUpFormInner = ({
  title,
  background,
  route
}: {
  title?: string;
  background?: boolean;
  route?: "try" | "buy" | "trybuy" | "giftcard" | undefined;
}) => {
  const classes = useStyles();
  const { stepLabels, activeStep, loading } = useContext(OnboardingContext);

  const PurchaseStep = useCallback(() => <SignUpStepPurchase route={route} />, [
    route
  ]);

  const ActiveStepComponent = ((): FC => {
    switch (activeStep) {
      case Step.giftcard:
        return SignUpStepGiftcard;
      case Step["phone-input-verify"]:
        return SignUpStepPhone;
      case Step["input-details"]:
        return SignUpStepDetails;
      case Step.purchase:
        return PurchaseStep;
      case Step.success:
        return SignUpStepSuccess;
      default:
        return SignUpStepBlank;
    }
  })();

  return (
    <div className={background ? classes.background : classes.noBackground}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container maxWidth="sm" className={classes.redeemGiftcardContainer}>
        <Grid
          container
          className={classes.paper}
          justify="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h1"
              className={classes.formTitle}
            >
              {title}
            </Typography>
            <Stepper
              activeStep={stepLabels.findIndex(({ steps }) =>
                steps.includes(activeStep)
              )}
              alternativeLabel
              className={classes.stepper}
            >
              {stepLabels.map(({ steps, label }) => (
                <StepperStep key={steps.join("-")}>
                  <StepLabel className={classes.stepperLabel}>
                    {label}
                  </StepLabel>
                </StepperStep>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <ActiveStepComponent />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const SignUpForm = (
  props: InitialiseParams & {
    title?: string;
    background?: boolean;
  }
) => (
  <OnboardingProvider {...props}>
    <SignUpFormInner
      title={props.title}
      background={props.background}
      route={props.route}
    />
  </OnboardingProvider>
);

export default SignUpForm;
