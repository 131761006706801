import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Divider,
  Collapse
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link, useHistory } from "react-router-dom";
import Badge from "@material-ui/core/Badge";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import { Helmet } from "react-helmet";
import LoggedInLayout from "../layouts/LoggedInLayout";
import { PlayerContext } from "../store/PlayerContext";

import TuneIcon from "@material-ui/icons/Tune";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.ffNavyLight,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4)
  },
  heading: {
    color: theme.palette.common.lightgray,
    fontSize: "1.4rem",
    marginBottom: "20px",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    }
  },
  subHeading: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    marginBottom: "20px",
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    }
  },
  bodytext: {
    color: theme.palette.background.ffNavy,
    marginBottom: "10px"
  },
  mainDivider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#444",
    margin: theme.spacing(0.5, 0)
  },
  searchBanner: {
    height: "60px",
    backgroundColor: "#ccc"
  },
  search: {
    position: "relative",
    backgroundColor: "#ccc",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    "@media (min-width:600px)": {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    backgroundColor: "#444",
    color: "inherit",
    border: "none !important",
    borderBottom: "1px solid #ccc",
    width: "100%",
    outline: "none !important",
    "&:focus": {
      border: "none",
      borderBottom: "1px solid green",
      outline: "none"
    },
    "& input": {
      outline: "none",
      border: "none",
      marginTop: "0px",
      padding: theme.spacing(2),
      fontSize: "1rem",
      caretColor: theme.palette.background.ffGreen,
      color: "#fff",
      fontWeight: 500,
      "&:focus": {
        border: "none",
        outline: "none"
      }
    }
  },
  inputInput: {
    // padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    border: "none",
    "&:focus": {
      border: "none",
      outline: "none"
    }
  },
  searchButton: {
    color: theme.palette.background.ffGreen
  },
  noResultsContainer: {
    padding: theme.spacing(6, 0)
  },
  searchPlaceholder: {
    padding: theme.spacing(0, 2),
    color: theme.palette.common.lightgray,
    fontSize: "1.4rem",
    marginBottom: theme.spacing(1),
    fontWeight: 500
  },
  searchPlaceholderDescription: {
    color: "#777",
    fontSize: "0.9rem",
    fontWeight: 500
  },
  searchPlaceholderIcon: {
    padding: "1% 0",
    color: "#444",
    fontSize: "1rem"
  },
  filterButton: {
    backgroundColor: "transparent",
    color: theme.palette.common.white,
    padding: 0,
    margin: "15px 5px",
    border: "none",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.background.ffGreen,
      border: "none"
    }
  },
  resetButton: {
    backgroundColor: "transparent",
    color: theme.palette.common.white,
    padding: 0,
    margin: "15px 5px",
    border: "none",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.background.ffGreen,
      border: "none"
    }
  },
  searchResultLink: {
    width: "100%",
    textDecoration: "none",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: "1px solid #444"
  },
  searchResultsTerm: {
    fontSize: "1.2rem",
    fontWeight: 500,
    color: theme.palette.common.lightgray,
    margin: theme.spacing(1.5, 0),
    "& span": {
      color: theme.palette.background.ffGreen,
      fontWeight: 500
    }
  },
  searchResultContainer: {
    "& a": {
      textDecoration: "none"
    },
    "&:hover": {
      backgroundColor: theme.palette.background.ffNavyLighter
    }
  },
  searchResultsDetails: {
    paddingTop: "0 !important",
    "@media (min-width:600px)": {
      paddingTop: "8px"
    }
  },
  searchResultTitle: {
    color: theme.palette.common.white,
    fontSize: "1.2rem",
    marginTop: theme.spacing(0),
    textDecoration: "none",
    fontWeight: 500,
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1)
    }
  },
  searchResultDate: {
    color: "#8e8e8e",
    fontSize: "0.8rem",
    marginTop: theme.spacing(0.5),
    textDecoration: "none",
    "& span": {
      fontWeight: 500
    },
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1)
    }
  },
  searchResultDescription: {
    color: theme.palette.common.lightgray,
    fontSize: "0.9rem",
    marginTop: theme.spacing(1),
    textDecoration: "none",
    width: "80%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "block",
    whiteSpace: "nowrap"
  },
  searchResultsChannelImage: {
    marginTop: theme.spacing(0.5),
    width: "40px",
    height: "40px",
    borderRadius: "50%"
  },
  filterContainer: {
    marginTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: 0,
    paddingRight: 0
  },
  filterHeader: {
    color: theme.palette.common.white,
    fontSize: "0.9rem",
    fontWeight: 500,
    paddingBottom: theme.spacing(0.8),
    borderBottom: "1px solid #444",
    textTransform: "uppercase"
  },
  filterOption: {
    backgroundColor: "none",
    color: theme.palette.background.upgradeAlt,
    fontSize: "0.9rem",
    fontWeight: 500,
    padding: theme.spacing(0.4, 0)
  },
  sortOptionButton: {
    minWidth: "auto",
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    color: theme.palette.background.upgradeAlt,
    fontSize: "0.9rem",
    fontWeight: 500,
    padding: theme.spacing(0.4, 0),
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.common.white,
      border: "none"
    },
    "& .MuiButton-label": {
      textAlign: "left",
      justifyContent: "left",
      padding: theme.spacing(1, 0)
    }
  },
  sortOptionButtonActive: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
    backgroundColor: "transparent",
    border: "none",
    color: theme.palette.common.white,
    fontSize: "0.9rem",
    fontWeight: 500,
    padding: theme.spacing(0.4, 0),
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.common.white,
      border: "none",
      cursor: "pointer"
    },
    "& .MuiButton-label": {
      textAlign: "left",
      justifyContent: "left",
      padding: theme.spacing(1, 0)
    }
  },
  filterOptionButton: {
    minWidth: "auto",
    backgroundColor: "transparent",
    border: "none",
    color: theme.palette.background.upgradeAlt,
    fontSize: "0.9rem",
    fontWeight: 500,
    padding: theme.spacing(0.4, 0),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.common.white,
      border: "none"
    },
    "& .MuiButton-label": {
      textAlign: "left",
      justifyContent: "left",
      padding: theme.spacing(1, 0)
    }
  },
  filterOptionButtonActive: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
    backgroundColor: "transparent",
    border: "none",
    color: theme.palette.common.white,
    fontSize: "0.9rem",
    fontWeight: 500,
    padding: theme.spacing(0.4, 0),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.common.white,
      border: "none",
      cursor: "pointer"
    },
    "& .MuiButton-label": {
      textAlign: "left",
      justifyContent: "left",
      padding: theme.spacing(1, 0)
    }
  },
  filterOptionButtonDisable: {
    fontSize: "1.4rem",
    marginTop: "-2px",
    marginLeft: "12px",
    color: theme.palette.background.upgradeAlt,
    "&:hover": {
      color: theme.palette.background.ffGreen,
      cursor: "pointer"
    }
  },
  noResultsTitle: {
    color: theme.palette.common.white,
    fontWeight: 500,
    padding: "10% 0",
    fontSize: "1rem"
  },
  loadMore: {
    width: "100%",
    margin: theme.spacing(3, 0, 0, 0),
    "@media (min-width:600px)": {
      width: "auto",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  }
}));

const Search = props => {
  const classes = useStyles();
  const history = useHistory();

  const {
    videoListLoading,
    error,
    searchPhrase,
    searchResults,
    searchSubmittedNew,
    setSearchPhrase,
    setSearchTag,
    setSearchSortOrder,
    clearCurrentVideo,
    resetError,
    triggerRefresh,
    triggerNextPage,
    videos,
    total
  } = useContext(PlayerContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(false);
  // eslint-disable-next-line
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const [searchCountryNI, setSearchCountryNI] = useState(false);
  const [searchCountryROI, setSearchCountryROI] = useState(false);
  const [searchCountryGB, setSearchCountryGB] = useState(false);
  const [searchCountryUSA, setSearchCountryUSA] = useState(false);
  const [searchRelevance, setSearchRelevance] = useState(true);
  const [searchPublishedDate, setSearchPublishedDate] = useState(false);

  // Get URL
  const urlParams = new URLSearchParams(window.location.search);
  const query = urlParams.get("q");
  const country = urlParams.get("c");
  const order = urlParams.get("o");

  useEffect(() => {
    resetError();
    setShowFilters(false);
    if (query && query.length > 0) {
      setSearchTerm(query);
      setSearchPhrase(query);
      setSearchSubmitted(true);

      if (country && country.length > 0) {
        setFilterCount(1);
        setSearchTag(country);
        resetCountryTags();
        if (country === "ni") {
          setSearchCountryNI(true);
        } else if (country === "roi") {
          setSearchCountryROI(true);
        } else if (country === "gb") {
          setSearchCountryGB(true);
        } else if (country === "usa") {
          setSearchCountryUSA(true);
        }
      } else {
        resetCountryTags();
      }

      if (order && order.length > 0) {
        if (order === "pubAt") {
          setSearchRelevance(false);
          setSearchPublishedDate(true);
          setSearchSortOrder("publishedAt desc");
        } else {
          setSearchPublishedDate(false);
          setSearchRelevance(true);
          setSearchSortOrder("");
        }
      }
      triggerRefresh();
    } else if (query === null) {
      setSearchTerm("");
      setSearchPhrase("");
      setSearchTag("");
      setSearchPublishedDate(false);
      setSearchRelevance(true);
      setSearchSubmitted(false);
    }
    clearCurrentVideo();

    return () => {
      resetError();
      setFilterCount(0);
      setSearchPhrase("");
      setSearchTag("");
      setSearchSortOrder("");
      clearCurrentVideo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, country, order]);

  const setSearchParams = e => {
    setSearchDisabled(false);
    setSearchTerm(e.target.value);
  };

  const submitSearch = () => {
    setSearchDisabled(true);
    setSearchPhrase(searchTerm);
    setSearchSubmitted(true);

    // if (searchTerm === searchPhrase) {
    //   triggerRefresh();
    // }
    if (country && country.length > 0 && !order) {
      history.push("/search?q=" + searchTerm + "&c=" + country);
    } else if (order && order.length > 0 && !country) {
      if (order === "pubAt") {
        history.push("/search?q=" + searchTerm + "&o=pubAt");
      } else {
        history.push("/search?q=" + searchTerm);
      }
    } else if (country && country.length > 0 && order && order.length > 0) {
      if (order === "pubAt") {
        history.push("/search?q=" + searchTerm + "&c=" + country + "&o=pubAt");
      } else {
        history.push("/search?q=" + searchTerm + "&c=" + country);
      }
    } else {
      history.push("/search?q=" + searchTerm);
    }
  };

  const keyPressSearch = () => {
    setSearchDisabled(true);
    setSearchPhrase(searchTerm);
    setSearchSubmitted(true);

    // if (searchTerm === searchPhrase) {
    //    triggerRefresh();
    // }
    if (country && country.length > 0 && !order) {
      history.push("/search?q=" + searchTerm + "&c=" + country);
    } else if (order && order.length > 0 && !country) {
      if (order === "pubAt") {
        history.push("/search?q=" + searchTerm + "&o=pubAt");
      } else {
        history.push("/search?q=" + searchTerm);
      }
    } else if (country && country.length > 0 && order && order.length > 0) {
      if (order === "pubAt") {
        history.push("/search?q=" + searchTerm + "&c=" + country + "&o=pubAt");
      } else {
        history.push("/search?q=" + searchTerm + "&c=" + country);
      }
    } else {
      history.push("/search?q=" + searchTerm);
    }
  };

  const resetSearch = () => {
    setTimeout(() => {
      textInput.current.value = "";
    }, 100);
    setShowFilters(false);
    setSearchPhrase("");
    setSearchTerm("");
    setSearchTag("");
    setSearchSubmitted(false);
    resetCountryTags();
    history.push("/search");
  };

  const showFilterPane = () => {
    if (showFilters) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  };

  let textInput = useRef(null);

  const showCountryTag = e => {
    resetCountryTags();
    if (e === "ni") {
      setSearchCountryNI(true);
    } else if (e === "roi") {
      setSearchCountryROI(true);
    } else if (e === "gb") {
      setSearchCountryGB(true);
    } else if (e === "usa") {
      setSearchCountryUSA(true);
    }
    setSearchPhrase(searchTerm);
    setSearchTag(e);
    setFilterCount(1);
    setTimeout(() => {
      setSearchTag(e);
    }, 100);

    if (order && order.length > 0) {
      if (order === "pubAt") {
        history.push("/search?q=" + searchTerm + "&c=" + e + "&o=pubAt");
      } else {
        history.push("/search?q=" + searchTerm + "&c=" + e);
      }
    } else {
      history.push("/search?q=" + searchTerm + "&c=" + e);
    }
  };

  const removeCountryTag = () => {
    resetCountryTags();
    setSearchPhrase(searchTerm);
    setSearchTag("");

    if (order && order.length > 0) {
      if (order === "pubAt") {
        history.push("/search?q=" + searchTerm + "&o=pubAt");
      } else {
        history.push("/search?q=" + searchTerm);
      }
    } else {
      history.push("/search?q=" + searchTerm);
    }
  };

  const resetCountryTags = () => {
    setSearchCountryNI(false);
    setSearchCountryROI(false);
    setSearchCountryGB(false);
    setSearchCountryUSA(false);
  };

  const sortByRelevance = () => {
    setSearchPublishedDate(false);
    setSearchRelevance(true);
    setSearchSortOrder("");

    if (country && country.length > 0) {
      history.push("/search?q=" + searchTerm + "&c=" + country);
    } else {
      history.push("/search?q=" + searchTerm);
    }
  };

  const sortByPublishedDate = () => {
    setSearchRelevance(false);
    setSearchPublishedDate(true);
    setSearchSortOrder("publishedAt desc");

    if (country && country.length > 0) {
      history.push("/search?q=" + searchTerm + "&c=" + country + "&o=pubAt");
    } else {
      history.push("/search?q=" + searchTerm + "&o=pubAt");
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Search FarmFLiX</title>
        <meta name="description" content="" />
      </Helmet>
      <LoggedInLayout>
        <div className={classes.background}>
          <Container>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  onChange={setSearchParams}
                  inputRef={textInput}
                  onKeyPress={ev => {
                    if (ev.key === "Enter" && !searchDisabled) {
                      keyPressSearch();
                    }
                  }}
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot
                  }}
                  // defaultValue={searchTerm}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          onClick={() => {
                            if (!searchDisabled) {
                              submitSearch();
                            }
                          }}
                          className={classes.searchButton}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>

              {/* Error Message */}
              {error && (
                <>
                  <Grid item xs={12} align="center">
                    <Typography>Error loading search results</Typography>
                  </Grid>
                </>
              )}

              {!error && (
                <>
                  {/* Show Search Results */}
                  {!videoListLoading && (
                    <>
                      {searchSubmittedNew && (
                        <>
                          <Grid item xs={12}>
                            <Box display="flex" style={{ marginTop: "8px" }}>
                              <Box flexGrow={1}>
                                {/* {videos.length > 0 && searchResults && ( */}
                                <Button
                                  className={classes.filterButton}
                                  startIcon={
                                    <Badge
                                      badgeContent={filterCount}
                                      color="primary"
                                    >
                                      <TuneIcon />{" "}
                                    </Badge>
                                  }
                                  onClick={showFilterPane}
                                >
                                  Filter
                                </Button>
                                {/* )} */}
                              </Box>
                              <Box>
                                <Button
                                  className={classes.resetButton}
                                  startIcon={<RotateLeftIcon />}
                                  onClick={resetSearch}
                                >
                                  Reset
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                          <Collapse in={showFilters} style={{ width: "100%" }}>
                            <Container className={classes.filterContainer}>
                              <Grid container spacing={2}>
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    variant="h3"
                                    className={classes.filterHeader}
                                  >
                                    Sort
                                  </Typography>
                                  {searchRelevance && (
                                    <Button
                                      disableRipple={true}
                                      className={classes.sortOptionButtonActive}
                                    >
                                      Relevance
                                    </Button>
                                  )}
                                  {!searchRelevance && (
                                    <Button
                                      disableRipple={true}
                                      className={classes.sortOptionButton}
                                      onClick={e => sortByRelevance()}
                                    >
                                      Relevance
                                    </Button>
                                  )}
                                  {searchPublishedDate && (
                                    <Button
                                      disableRipple={true}
                                      className={classes.sortOptionButtonActive}
                                    >
                                      Published Date
                                    </Button>
                                  )}
                                  {!searchPublishedDate && (
                                    <Button
                                      disableRipple={true}
                                      className={classes.sortOptionButton}
                                      onClick={e => sortByPublishedDate()}
                                    >
                                      Published Date
                                    </Button>
                                  )}
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <Typography
                                    variant="h3"
                                    className={classes.filterHeader}
                                  >
                                    Country
                                  </Typography>
                                  {!searchCountryNI && (
                                    <Button
                                      disableRipple={true}
                                      className={classes.filterOptionButton}
                                      onClick={e => showCountryTag("ni")}
                                    >
                                      NI
                                    </Button>
                                  )}
                                  {searchCountryNI && (
                                    <Button
                                      disableRipple={true}
                                      className={
                                        classes.filterOptionButtonActive
                                      }
                                      onClick={e => removeCountryTag()}
                                    >
                                      NI
                                      <span
                                        className={
                                          classes.filterOptionButtonDisable
                                        }
                                      >
                                        &times;
                                      </span>
                                    </Button>
                                  )}
                                  <br />
                                  {!searchCountryROI && (
                                    <Button
                                      disableRipple={true}
                                      className={classes.filterOptionButton}
                                      onClick={e => showCountryTag("roi")}
                                    >
                                      ROI
                                    </Button>
                                  )}
                                  {searchCountryROI && (
                                    <Button
                                      disableRipple={true}
                                      className={
                                        classes.filterOptionButtonActive
                                      }
                                      onClick={e => removeCountryTag()}
                                    >
                                      ROI
                                      <span
                                        className={
                                          classes.filterOptionButtonDisable
                                        }
                                      >
                                        &times;
                                      </span>
                                    </Button>
                                  )}
                                  <br />
                                  {!searchCountryGB && (
                                    <Button
                                      disableRipple={true}
                                      className={classes.filterOptionButton}
                                      onClick={e => showCountryTag("gb")}
                                    >
                                      GB
                                    </Button>
                                  )}
                                  {searchCountryGB && (
                                    <Button
                                      disableRipple={true}
                                      className={
                                        classes.filterOptionButtonActive
                                      }
                                      onClick={e => removeCountryTag()}
                                    >
                                      GB
                                      <span
                                        className={
                                          classes.filterOptionButtonDisable
                                        }
                                      >
                                        &times;
                                      </span>
                                    </Button>
                                  )}
                                  <br />
                                  <Button
                                    disableRipple={true}
                                    className={`${
                                      searchCountryUSA
                                        ? classes.filterOptionButtonActive
                                        : classes.filterOptionButton
                                    }`}
                                    onClick={e => showCountryTag("usa")}
                                  >
                                    USA
                                  </Button>
                                </Grid>
                              </Grid>
                            </Container>
                          </Collapse>
                        </>
                      )}

                      {videos.length === 0 &&
                        searchSubmittedNew &&
                        !searchResults && (
                          <Grid item xs={12} align="center">
                            <Box className={classes.noResultsContainer}>
                              <Typography className={classes.searchPlaceholder}>
                                No Results Found
                              </Typography>
                              <Typography
                                className={classes.searchPlaceholderDescription}
                              >
                                Try different search terms or remove filters
                              </Typography>
                            </Box>
                          </Grid>
                        )}

                      {videos.length > 0 &&
                        searchSubmittedNew &&
                        searchResults && (
                          <>
                            <Divider className={classes.mainDivider} />
                            <Grid item xs={12}>
                              <Typography className={classes.searchResultsTerm}>
                                {total} results found for:{" "}
                                <span>{searchPhrase}</span>
                              </Typography>
                            </Grid>
                            {videos.map(episode => (
                              <Link
                                key={episode.id}
                                to={"/video-details/" + episode.slug}
                                className={classes.searchResultLink}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.searchResultContainer}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} md={3}>
                                      <img
                                        src={episode.thumbnail}
                                        alt=""
                                        style={{ width: "100%" }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={8}
                                      md={9}
                                      className={classes.searchResultsDetails}
                                    >
                                      <Typography
                                        className={classes.searchResultTitle}
                                      >
                                        {episode.name}
                                      </Typography>
                                      <Typography
                                        className={classes.searchResultDate}
                                      >
                                        Published:{" "}
                                        <span>
                                          {episode.publishedAt &&
                                            new Intl.DateTimeFormat("en-GB", {
                                              year: "numeric",
                                              month: "long",
                                              day: "2-digit"
                                            }).format(
                                              new Date(episode.publishedAt)
                                            )}
                                        </span>
                                      </Typography>
                                      <Typography
                                        className={
                                          classes.searchResultDescription
                                        }
                                      >
                                        {episode.description}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Link>
                            ))}
                          </>
                        )}
                    </>
                  )}

                  {/* Trigger the next set of results (if any) */}
                  {videos.length < total && (
                    <Grid item xs={12} sm={12} align="center">
                      <Button
                        className={classes.loadMore}
                        onClick={triggerNextPage}
                      >
                        Load More
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Container>
        </div>
      </LoggedInLayout>
    </div>
  );
};

export default Search;
