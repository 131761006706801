import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Box
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { CatalogOverviewResponse } from "@booyaltd/core";
import { getTitleAndChannelFromVideoName } from "../../utils/strings";

const useStyles = makeStyles(theme => ({
  carouselContainer: {
    marginBottom: theme.spacing(4)
  },
  desktopCarouselItemContainer: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    height: "250px",
    display: "block",
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:960px)": {
      height: "690px"
    }
  },
  desktopCarouselItemContainerBottomGradient: {
    background:
      "linear-gradient(290deg, rgba(23,23,23,0) 40%, rgba(23,23,23,1) 100%)",
    width: "60%",
    height: "250px",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:960px)": {
      height: "690px"
    }
  },
  desktopCarouselItemContainerBottomGradientHover: {
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(23,23,23,0.7) 100%)",
    width: "100%",
    height: "250px",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:960px)": {
      height: "690px"
    }
  },
  desktopCarouselItemDetails: {
    position: "absolute",
    top: "30%",
    left: "15px",
    "@media (min-width:600px)": {
      top: "30%",
      left: "25px"
    },
    "@media (min-width:960px)": {
      top: "30%",
      left: "25px"
    }
  },
  desktopCarouselItemChannel: {
    marginBottom: theme.spacing(1),
    borderRadius: "50%",
    width: "60px",
    "@media (min-width:600px)": {
      borderRadius: "50%",
      width: "70px"
    },
    "@media (min-width:960px)": {
      borderRadius: "50%",
      width: "80px"
    }
  },
  desktopCarouselItemTitleHeading: {
    fontSize: "1.2rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 500,
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
      textShadow: "-3px 3px 3px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  desktopCarouselItemTitle: {
    fontSize: "1.6rem",
    lineHeight: "1.6rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: "#fafafa",
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "3.4rem",
      lineHeight: "3.6rem",
      textShadow: "-3px 3px 3px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "4.6rem",
      lineHeight: "4.8rem"
    }
  },
  episodeHoverDetailsActivePlayIconButton: {
    fontSize: "1.8rem",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffGreen,
    // @ts-ignore
    color: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      // @ts-ignore
      backgroundColor: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "2.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.2rem"
    }
  },
  iconButton: {
    width: "2rem",
    height: "2rem",
    "@media (min-width:600px)": {
      width: "2.4rem",
      height: "2.4rem"
    },
    "@media (min-width:960px)": {
      width: "3rem",
      height: "3rem"
    }
  },
  playButton: {
    position: "absolute",
    bottom: "20px",
    right: "10px",
    "@media (min-width:600px)": {
      bottom: "20px",
      right: "20px"
    },
    "@media (min-width:960px)": {
      bottom: "20px",
      right: "20px"
    }
  }
}));

const Featured = ({
  latest,
  channels
}: Pick<CatalogOverviewResponse, "latest" | "channels">) => {
  const classes = useStyles();
  const history = useHistory();
  const [featuredHovering, setFeaturedHovering] = useState(false);

  const goToLatest = () => {
    history.push("/latest/");
  };

  const handleFeaturedItemHover = () => {
    setFeaturedHovering(true);
  };

  const handleFeaturedItemHoverClose = () => {
    setFeaturedHovering(false);
  };

  const latestEp = Array.isArray(latest) ? latest[0] : latest;

  if (!latestEp) {
    return null;
  }

  const { title } = getTitleAndChannelFromVideoName(latestEp.name);

  const channel = channels.find(
    c => latestEp.channelId && c.id === latestEp.channelId
  );

  return (
    <div>
      <Container className={classes.carouselContainer}>
        <Grid container>
          <Box
            key={latestEp.id}
            className={classes.desktopCarouselItemContainer}
            style={{
              backgroundImage: `url(${latestEp.poster})`
            }}
            onMouseEnter={() => handleFeaturedItemHover()}
            onMouseLeave={() => handleFeaturedItemHoverClose()}
            onClick={() => goToLatest()}
          >
            <div
              className={
                featuredHovering
                  ? classes.desktopCarouselItemContainerBottomGradientHover
                  : classes.desktopCarouselItemContainerBottomGradient
              }
            >
              <div className={classes.desktopCarouselItemDetails}>
                {/* To update this with 'featured' channel logo' */}
                {channel && (
                  <img
                    src={channel.logoImageUrl}
                    alt=""
                    className={classes.desktopCarouselItemChannel}
                  />
                )}
                <Typography className={classes.desktopCarouselItemTitleHeading}>
                  New this week...
                </Typography>
                <Typography
                  className={classes.desktopCarouselItemTitle}
                  variant="h4"
                >
                  {title}
                </Typography>
              </div>
              <Box className={classes.playButton}>
                <Link to={"/latest/"}>
                  <IconButton
                    aria-label="Play Episode"
                    className={classes.episodeHoverDetailsActivePlayIconButton}
                  >
                    <PlayArrowIcon className={classes.iconButton} />
                  </IconButton>
                </Link>
              </Box>
            </div>
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default Featured;
