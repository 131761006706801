import React from "react";
import { Button, ButtonProps, makeStyles, Theme } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SmsIcon from "@material-ui/icons/Sms";

const useStyles = makeStyles((theme: Theme) => ({
  whatsappButton: {
    marginBottom: theme.spacing(2),
    borderWidth: 2,
    backgroundColor: "#25D366",
    borderColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "#25D366",
      borderColor: "white",
      color: "white"
    }
  },
  smsButton: {
    marginBottom: theme.spacing(2),
    borderWidth: 2,
    backgroundColor: theme.palette.primary.main,
    borderColor: "black",
    color: "black",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: "white",
      color: "black"
    }
  },
  secondaryButton: {
    marginBottom: theme.spacing(1),
    borderWidth: 2,
    borderColor: "black",
    color: "white",
    "&:hover": {
      borderWidth: 2,
      marginBottom: theme.spacing(1),
      borderColor: "white",
      color: "white"
    },
    "&:disabled": {
      borderWidth: 2,
      marginBottom: theme.spacing(1),
      borderColor: "rgba(255,255,255,0.8)",
      color: "rgba(255,255,255,0.8)"
    }
  }
}));

type CustomButtonProps = Omit<ButtonProps, "children"> & { title: string };

export const WhatsappButton = ({ title, ...props }: CustomButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      fullWidth={true}
      startIcon={<WhatsAppIcon />}
      className={classes.whatsappButton}
      {...props}
    >
      {title || ""}
    </Button>
  );
};

export const SmsButton = ({ title, ...props }: CustomButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      fullWidth={true}
      startIcon={<SmsIcon />}
      className={classes.smsButton}
      color={"secondary"}
      {...props}
    >
      {title || ""}
    </Button>
  );
};

export const SmsButtonSecondary = ({ title, ...props }: CustomButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      startIcon={<SmsIcon />}
      variant="outlined"
      fullWidth={true}
      className={classes.secondaryButton}
      color={"secondary"}
      {...props}
    >
      {title || ""}
    </Button>
  );
};

export const SecondaryButton = ({ title, ...props }: CustomButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      fullWidth={true}
      className={classes.secondaryButton}
      color={"secondary"}
      {...props}
    >
      {title || ""}
    </Button>
  );
};

export const PrimaryButton = ({ title, ...props }: CustomButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      fullWidth={true}
      className={classes.smsButton}
      color={"secondary"}
      {...props}
    >
      {title || ""}
    </Button>
  );
};
