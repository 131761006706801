import React, { useCallback, useState } from "react";
import { Box, IconButton, makeStyles, Theme } from "@material-ui/core";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { deleteFavouriteVideo, favouriteVideo } from "../../api/client";
import { VideoSearchResult } from "@booyaltd/core";

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    fontSize: 24
  },
  favouriteIconButton: {
    fontSize: "2.2rem",
    backgroundColor: "#555555",
    color: "#fff",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        // @ts-ignore
        color: "#f13849"
      }
    }
  },
  favouritedIconButton: {
    fontSize: "1.8rem",
    backgroundColor: "#555555",
    color: "#ff5050",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        // @ts-ignore
        color: "#f13849"
      }
    }
  }
}));

type VideoFavouriteButtonProps = {
  videoId: VideoSearchResult["id"];
  initialFavourited: boolean;
  onChangeFavourited?: (favourited: boolean) => void;
};

const VideoFavouriteButton = ({
  videoId,
  initialFavourited,
  onChangeFavourited
}: VideoFavouriteButtonProps) => {
  const classes = useStyles();
  const [favourited, setFavourited] = useState(initialFavourited);

  const onClick = useCallback(() => {
    setFavourited(!favourited);
    const mutateMethod = favourited ? deleteFavouriteVideo : favouriteVideo;
    mutateMethod(videoId)
      .then(() => {
        if (onChangeFavourited) {
          onChangeFavourited(!favourited);
        }
      })
      .catch(e => {
        console.error("Error favouriting video", e);
        setFavourited(favourited);
      });
  }, [favourited, videoId, onChangeFavourited]);

  return (
    <Box>
      <IconButton
        onClick={onClick}
        aria-label={
          favourited ? "Unmark Episode Favourite" : "Mark Episode Favourite"
        }
        className={
          favourited
            ? classes.favouritedIconButton
            : classes.favouriteIconButton
        }
      >
        {!favourited ? (
          <FavoriteBorderIcon className={classes.iconButton} />
        ) : (
          <FavoriteIcon className={classes.iconButton} />
        )}
      </IconButton>
    </Box>
  );
};

export default VideoFavouriteButton;
