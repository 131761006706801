import React, { useContext } from "react";
import Featured from "./Dashboard/Featured";
import Channels from "./Dashboard/Channels";
import LoggedInLayout from "../layouts/LoggedInLayout";
import { OverviewContext } from "../store/OverviewContext";
import BackdropLoader from "./common/BackdropLoader";
import { Helmet } from "react-helmet";
import ComingSoonCarousel from "./Dashboard/ComingSoonCarousel";
import LinksSection from "./Dashboard/LinksSection";
import { PlaylistCatalogResponse, PlaylistDisplayType } from "@booyaltd/core";
import ContinueWatching from "./Dashboard/ContinueWatching";
import PlaylistSections from "./Dashboard/PlaylistSections";
import { AccountContext } from "../store/AccountContext";
import InstallAppBanner from "./Banners/InstallApp";
import Careers from "./Banners/Careers";
import FreeTrialLargeBanner from "./Banners/FreeTrialLarge";
import PromoPoster from "./Dashboard/PromoPoster";
import Survey from "./Banners/Survey";
import PriceRise from "./Banners/PriceRise";
import dayjs from "dayjs";

type DashboardSection = {
  type: PlaylistCatalogResponse["type"];
  displayType: PlaylistDisplayType;
  playlists: PlaylistCatalogResponse[];
};

export const buildDashboardSectionsFromPlaylists = (
  playlists: PlaylistCatalogResponse[]
) =>
  playlists.reduce<DashboardSection[]>((sections, playlist) => {
    if (
      sections.length > 0 &&
      sections[sections.length - 1].displayType === playlist.displayType
    ) {
      sections[sections.length - 1].playlists.push(playlist);
    } else {
      sections.push({
        displayType: playlist.displayType,
        type: playlist.type,
        playlists: [playlist]
      });
    }
    return sections;
  }, []);

export default function Dashboard() {
  const { overview } = useContext(OverviewContext);
  const { userProfile } = useContext(AccountContext);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Home</title>
      </Helmet>
      <LoggedInLayout>
        {!overview || !overview.playlists || !overview.channels ? (
          <BackdropLoader />
        ) : (
          <>
            {overview &&
              overview.doors?.open &&
              overview.doors?.annualUpgradeOpen && (
                <div style={{ marginBottom: "18px" }}>
                  {/* In here we shouldn't show this banner if someone has just
                  joined on Black Friday */}

                  {userProfile && userProfile.memberStatusId === "trial" && (
                    <PriceRise
                      disableButton
                      title={"Beat the Price Rise Now!"}
                      subTitle={
                        "Lock in the current price before they increase on 5th December 2022"
                      }
                      showCountdown
                    />
                  )}

                  {userProfile &&
                    userProfile.memberStatusId === "standard" &&
                    userProfile.dateWebFirstLoggedIn !== undefined &&
                    dayjs(userProfile?.dateWebFirstLoggedIn).isBefore(
                      dayjs("2022-11-25")
                    ) && (
                      <PriceRise
                        disableButton
                        title={
                          userProfile.productAutoRenewing
                            ? "Our Price Rise will not affect you!"
                            : "Beat the Price Rise Now!"
                        }
                        subTitle={
                          userProfile.productAutoRenewing
                            ? "Good News! As an active member, your price will remain on your current plan."
                            : "Lock in the current price before they increase on 5th December 2022"
                        }
                        showCountdown={!userProfile.productAutoRenewing}
                      />
                    )}
                </div>
              )}

            {overview && overview.latest && overview.channels && (
              <Featured latest={overview.latest} channels={overview.channels} />
            )}

            {overview &&
              userProfile &&
              userProfile.memberStatus === "trial" && <FreeTrialLargeBanner />}

            {overview &&
              overview.memberSurvey &&
              !localStorage.getItem("ff_memberSurvey22_completed") && (
                <Survey surveyLink={overview.memberSurvey} fullWidth={false} />
              )}

            {overview && overview.doors?.careersOpen && (
              <Careers fullWidth={false} />
            )}

            {overview && <LinksSection />}

            {overview && <ContinueWatching />}

            {overview && overview.playlists && (
              <PlaylistSections
                playlists={overview.playlists}
                channels={overview.channels}
                memberStatusId={userProfile?.memberStatusId}
              />
            )}
            {overview && overview.channels && (
              <Channels channels={overview.channels} />
            )}

            {overview && overview.comingSoon?.length && overview.channels && (
              <ComingSoonCarousel
                videos={overview.comingSoon}
                channels={overview.channels}
              />
            )}

            {overview &&
              overview.promo &&
              userProfile?.memberStatusId !== "trial" && (
                <PromoPoster promo={overview.promo} />
              )}

            {overview && userProfile && !userProfile.dateAppFirstLoggedIn && (
              <InstallAppBanner />
            )}
          </>
        )}
      </LoggedInLayout>
    </>
  );
}
