import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import appleappstore from "./../Modals/app-store-logo.png";
import googleplaystore from "./../Modals/google-play-logo.png";
import chromecast from "./../Banners/chromecast_logo.png";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(4, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(6, 0)
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(8, 0)
    }
  },
  appContainer: {
    paddingTop: theme.spacing(6)
  },
  playerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  appTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    fontSize: "2.2rem",
    color: theme.palette.common.white,
    "@media (min-width:600px)": {
      fontSize: "2.8rem",
      lineHeight: "2.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3.6rem",
      lineHeight: "3.8rem"
    }
  },
  appSubTitle: {
    textAlign: "center",
    fontSize: "1.2rem",
    marginBottom: theme.spacing(2),
    color: theme.palette.background.ffGreen,
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  whatIsFarmflix: {
    color: theme.palette.common.white,
    alignSelf: "center",
    justifySelf: "center"
  },
  appDesc: {
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "1.2rem",
    padding: theme.spacing(0, 4),
    fontWeight: 500,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontSize: "1.2rem",
      lineHeight: "1.4rem"
    },
    "& span": {
      color: theme.palette.background.ffGreen
    }
  },
  appLink: {
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "0.8rem",
    lineHeight: "1rem",
    padding: theme.spacing(0, 4),
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.1rem",
      marginTop: theme.spacing(1)
    },
    "& span": {
      color: theme.palette.background.ffGreen
    }
  },
  appSmlPrint: {
    fontSize: "0.7rem",
    color: theme.palette.background.ffNavy,
    textAlign: "center",
    "@media (min-width:600px)": {
      textAlign: "center"
    },
    "@media (min-width:960px)": {
      textAlign: "left"
    }
  },
  appStoreLogo: {
    maxWidth: "140px",
    margin: "5px",
    "@media (min-width:600px)": {
      textAlign: "center",
      maxWidth: "140px",
      margin: "10px 5px"
    },
    "@media (min-width:960px)": {
      textAlign: "left",
      maxWidth: "160px",
      margin: "10px 5px"
    }
  },
  appGraphic: {
    width: "90%",
    margin: "0 5%",
    textAlign: "center",
    "@media (min-width:600px)": {
      width: "90%",
      margin: "20px 5%"
    }
  },
  appDownloadLinks: {
    textAlign: "center",
    // margin: "15px 0 5px 0",
    "@media (min-width:600px)": {
      textAlign: "center"
    },
    "@media (min-width:960px)": {
      textAlign: "center"
    }
  },
  chromecastContainer: {
    marginTop: "20px",
    textAlign: "center"
  },
  chromecastText: {
    margin: "20px 0"
  },
  chromecastLogo: {
    width: "40%",
    textAlign: "center",
    "@media (min-width:600px)": {
      maxWidth: "150px"
    }
  },
  installAppButton: {
    width: "90%",
    margin: "32px 5% 16px 5%",
    fontSize: "1.3rem",
    padding: theme.spacing(2),
    "@media (min-width:600px)": {
      width: "auto",
      padding: theme.spacing(2, 6)
    }
  }
}));

export default function FarmFLiXApp() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background}>
        <Container fixed fixedcomponent="main" maxWidth="md">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.playerWrapper}
              display="flex"
            >
              <Typography
                component="h2"
                variant="h2"
                className={classes.appTitle}
              >
                The FarmFLiX App is Here!
              </Typography>
              <Typography variant="h3" className={classes.appSubTitle}>
                Watch Anytime. Watch Anywhere.
              </Typography>
              <img
                src={
                  "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/App/farmflix_app21.png"
                }
                alt="FarmFLiX App"
                className={classes.appGraphic}
              />
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginBottom: "12px" }}
              >
                <a
                  href={"https://smart.link/6fa45jd155j1i"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button size="large" className={classes.installAppButton}>
                    INSTALL THE APP
                  </Button>
                </a>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.appDownloadLinks}
                >
                  <a
                    href={"https://smart.link/6fa45jd155j1i"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={appleappstore}
                      className={classes.appStoreLogo}
                      alt="Apple App Store Graphic"
                    />
                  </a>
                  <a
                    href={"https://smart.link/6fa45jd155j1i"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={googleplaystore}
                      className={classes.appStoreLogo}
                      alt="Google Play Store Graphic"
                    />
                  </a>
                </Grid>
              </Grid>
              <Grid container className={classes.chromecastContainer}>
                <Grid item xs={12}>
                  <Typography className={classes.chromecastText}>
                    Watch on the big screen. App supports:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <img
                    src={chromecast}
                    alt="Google Chromecast Logo"
                    className={classes.chromecastLogo}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
