import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { OverviewContext } from "../../store/OverviewContext";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import Countdown from "react-countdown";

dayjs.extend(advancedFormat);

const PriceRise = props => {
  const useStyles = makeStyles(theme => ({
    background: {
      borderTop: "1px solid #333",
      flexGrow: 1,
      backgroundColor: "#1ab9e8",

      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      "@media (min-width:600px)": {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
      },
      "@media (min-width:960px)": {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
      }
    },
    container: {
      position: "relative"
    },
    heading: {
      color: theme.palette.common.white,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      textAlign: "center",
      fontSize: "1.6rem",
      lineHeight: "1.6rem",
      fontWeight: 600,
      letterSpacing: "-1px",
      "@media (min-width:600px)": {
        marginBottom: theme.spacing(0.2),
        textAlign: "left",
        fontSize: "1.6rem",
        lineHeight: "1.8rem"
      },
      "@media (min-width:960px)": {
        fontSize: "1.6rem",
        lineHeight: "1.8rem"
      }
    },
    bodyText: {
      color: theme.palette.common.white,
      fontSize: "0.95rem",
      lineHeight: "1rem",
      textAlign: "center",
      "& a": {
        color: theme.palette.common.white,
        fontWeight: 600
      },
      "@media (min-width:600px)": {
        textAlign: "left"
      }
    },
    countdown: {
      color: theme.palette.background.ffNavy,
      fontSize: "3rem",
      textAlign: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      "@media (min-width:600px)": {
        fontSize: "3.2rem",
        lineHeight: "3.4rem",
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
      },
      "@media (min-width:960px)": {
        fontSize: "4rem",
        lineHeight: "4.2rem",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    countdownContainer: {
      padding: theme.spacing(0.5, 1, 0, 2),
      "@media (min-width:600px)": {
        padding: theme.spacing(0, 0, 0, 8)
      }
    },
    countdownWrapper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      "@media (min-width:600px)": {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0)
      }
    },
    countdownLabelWrapper: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    },
    countdownUnitWrapper: {
      textAlign: "center",
      backgroundColor: theme.palette.background.ffNavy,
      padding: "1% 0",
      fontSize: "1.8rem",
      fontWeight: 700,
      color: theme.palette.common.white,
      maxWidth: "21.8%",
      borderRadius: "5px",
      margin: "0 1.6%",
      "@media (min-width:600px)": {
        padding: "1% 0",
        fontSize: "1.5rem",
        fontWeight: 700
      },
      "@media (min-width:960px)": {
        padding: "1% 0",
        fontSize: "1.6rem",
        fontWeight: 700
      }
    },
    countdownUnitFirstWrapper: {
      margin: 0
    },
    countdownUnitLabel: {
      fontSize: "0.8rem",
      fontWeight: 500,
      textAlign: "center",
      color: theme.palette.common.white,
      maxWidth: "21.8%",
      margin: "0 1.6%",
      "@media (min-width:600px)": {
        fontSize: "0.7rem"
      }
    },
    joinButton: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
      fontSize: "1rem",
      padding: theme.spacing(1.4, 4),
      borderColor: theme.palette.common.white,
      color: theme.palette.common.white,
      "&:hover": {
        borderColor: theme.palette.common.white,
        color: theme.palette.common.white
      },
      "@media (min-width:600px)": {
        marginTop: theme.spacing(2),
        width: "auto",
        marginLeft: "0%",
        marginRight: "0%",
        fontSize: "1rem",
        padding: theme.spacing(1, 4)
      },
      "@media (min-width:960px)": {
        marginTop: theme.spacing(1)
      }
    },
    link: {
      textDecoration: "none"
    }
  }));

  const classes = useStyles();

  const { overview } = useContext(OverviewContext);

  const RenderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <div className={classes.background}>
          <Container fixedcomponent="main" maxWidth="lg">
            <Grid container>
              <Grid item xs={12} sm={6} md={8} lg={9}>
                <Typography className={classes.heading}>
                  {props.title ? (
                    <>{props.title}</>
                  ) : (
                    "Last chance to join FarmFLiX!"
                  )}
                </Typography>
                <Typography variant="body1" className={classes.bodyText}>
                  {props.subTitle ? (
                    <>{props.subTitle}</>
                  ) : (
                    <>
                      Our Membership locks on{" "}
                      <b>
                        {dayjs(overview?.doors?.until).format("dddd Do MMMM")}
                      </b>{" "}
                      for another season. Are you onboard?
                    </>
                  )}
                </Typography>
              </Grid>
              {props.showCountdown && (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Container className={classes.countdownContainer}>
                    <Grid container className={classes.countdownWrapper}>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.countdownUnitWrapper}
                      >
                        <span>
                          {days.toString().length === 1 ? "0" : ""}
                          {days}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.countdownUnitWrapper}
                      >
                        <span>
                          {hours.toString().length === 1 ? "0" : ""}
                          {hours}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.countdownUnitWrapper}
                      >
                        <span>
                          {minutes.toString().length === 1 ? "0" : ""}
                          {minutes}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.countdownUnitWrapper}
                      >
                        <span>
                          {seconds.toString().length === 1 ? "0" : ""}
                          {seconds}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.countdownLabelWrapper}>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.countdownUnitLabel}
                      >
                        Days
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.countdownUnitLabel}
                      >
                        Hours
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.countdownUnitLabel}
                      >
                        Mins
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        className={classes.countdownUnitLabel}
                      >
                        Secs
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
              )}
              {!props.disableButton && (
                <Grid item xs={12}>
                  <Link
                    to={props.buttonLink ? props.buttonLink : "/buy"}
                    className={classes.link}
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      className={classes.joinButton}
                    >
                      Join Now
                    </Button>
                  </Link>
                </Grid>
              )}
            </Grid>
          </Container>
        </div>
      );
    }
  };

  return (
    <>
      {overview && (
        <>
          <Countdown
            date={Date.parse("2022-12-05T00:00:00.000Z")}
            renderer={RenderCountdown}
            zeroPadTime={2}
            daysInHours={true}
          />
        </>
      )}
    </>
  );
};

export default PriceRise;
