import React, { useCallback, useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "react-phone-input-2/lib/material.css";
import { AccountContext } from "../store/AccountContext";
import { Field, Form } from "react-final-form";
import Alert from "@material-ui/lab/Alert";
import {
  composeValidators,
  isEmail,
  isEmailRequired,
  isPasswordValid
} from "../utils/validators";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useToasts } from "react-toast-notifications";
import DefaultLayout from "../layouts/DefaultLayout";
import { Redirect } from "react-router-dom";
import CodeForm from "./common/CodeForm";
import { validatePasswordResetCode } from "../api/client";

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: "none",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "scroll",
    width: "100%",
    height: "auto",
    display: "flex",
    boxShadow: "none",
    backgroundColor: theme.palette.background.ffNavy,
    "@media (min-width:600px)": {
      backgroundImage: `url(https://farmflix-images-test.s3-eu-west-1.amazonaws.com/freetrial_bg.jpg)`,
      boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.6)"
    }
  },
  paper: {
    borderRadius: "0px",
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(0),
    padding: theme.spacing(4),
    backgroundColor: "none",
    "@media (min-width:600px)": {
      backgroundColor: "rgba(23,23,23,0.9)",
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10)
    }
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    width: "100%",
    margin: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  formHeader: {
    color: theme.palette.common.white,
    fontSize: "2.2rem",
    textAlign: "center",
    fontWeight: 600,
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  formSubHeader: {
    color: theme.palette.common.white,
    fontSize: "2.0rem",
    textAlign: "center",
    fontWeight: "500",
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.0rem",
      lineHeight: "2.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    }
  },
  bodyText: {
    color: theme.palette.common.white,
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  emailField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  formField: {
    "& label": {
      color: theme.palette.common.white
    },
    "& input": {
      backgroundColor: "#444",
      color: "#fff",
      borderColor: "#444"
    }
  },
  errorAlert: {
    margin: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      margin: theme.spacing(2, 0)
    }
  },
  tryAgainButton: {
    borderColor: "#e9e9e9",
    color: "#e9e9e9",
    "@media (min-width:600px)": {
      width: "auto"
    },
    "&:hover": {
      borderColor: "#fff",
      color: "#fff"
    }
  }
}));

const ForgotPassword = props => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [error, setError] = useState();
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [submittedCode, setSubmittedCode] = useState(false);
  const { addToast } = useToasts();

  const {
    loggedIn,
    sendPasswordResetEmail,
    confirmPasswordReset,
    loginWithUsernamePassword
  } = useContext(AccountContext);

  const onSubmitEmail = async ({ email }) => {
    setLoading(true);
    setError(undefined);

    try {
      await sendPasswordResetEmail(email);
      setSubmittedEmail(email);
    } catch (e) {
      setError("Error sending password reset email, please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onSubmitVerificationCode = useCallback(
    code => {
      setSubmittedCode(undefined);
      setError(undefined);
      validatePasswordResetCode(submittedEmail, code).then(success => {
        if (success) {
          setSubmittedCode(code);
        } else {
          setError("Invalid verification code, please try again.");
        }
      });
    },
    [submittedEmail]
  );

  const onSubmitPassword = useCallback(
    async ({ password }) => {
      setLoading(true);
      setError(undefined);

      try {
        await confirmPasswordReset({
          email: submittedEmail,
          code: submittedCode,
          password
        });

        addToast("Password reset successfully", {
          appearance: "success"
        });

        if (!loggedIn) {
          await loginWithUsernamePassword(submittedEmail, password);
        }

        setReset(true);
      } catch (e) {
        setError("Error resetting password, please try again");
      } finally {
        setLoading(false);
      }
    },
    [
      loggedIn,
      submittedEmail,
      submittedCode,
      addToast,
      confirmPasswordReset,
      loginWithUsernamePassword
    ]
  );

  const tryAgain = useCallback(() => {
    setError(undefined);
    setSubmittedCode(undefined);
    setSubmittedEmail(undefined);
  }, [setError, setSubmittedCode, setSubmittedEmail]);

  if (reset && loggedIn) {
    return <Redirect to="/videos" />;
  }

  return (
    <DefaultLayout>
      <div className={classes.background}>
        <Container fixedcomponent="main" maxWidth="sm">
          <div className={classes.paper}>
            <Typography
              component="h1"
              variant="h1"
              className={classes.formHeader}
            >
              Forgot Password
            </Typography>

            {error && (
              <Alert
                className={classes.errorAlert}
                severity="error"
                variant="filled"
                action={
                  <Button
                    size="small"
                    variant="outlined"
                    className={classes.tryAgainButton}
                    onClick={tryAgain}
                  >
                    Try Again
                  </Button>
                }
              >
                {error}
              </Alert>
            )}

            {!submittedEmail && <EmailForm onSubmit={onSubmitEmail} />}
            {submittedEmail && !submittedCode && (
              <Grid item xs={12} sm={12} alignContent="center">
                <CodeForm onSubmit={onSubmitVerificationCode} />
              </Grid>
            )}
            {submittedEmail && submittedCode && (
              <PasswordForm onSubmit={onSubmitPassword} />
            )}
          </div>

          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      </div>
    </DefaultLayout>
  );
};

const EmailForm = ({ onSubmit }) => {
  const classes = useStyles();
  const { userProfile, loading } = useContext(AccountContext);

  if (loading) {
    return null;
  }

  return (
    <Form
      initialValues={userProfile ? { email: userProfile.email } : undefined}
      onSubmit={onSubmit}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <div className={classes.form}>
            <Grid container>
              <Grid item xs={12} sm={12} className={classes.formField}>
                <Typography variant="body2" className={classes.bodyText}>
                  Enter your <b>email address</b> that you used to register with
                  FarmFLiX.
                  <br />
                  <br />
                  We&apos;ll send you an email containing a <b>
                    6 digit code
                  </b>{" "}
                  for you to input at the next stage.
                </Typography>
                <Field
                  name="email"
                  validate={composeValidators(isEmailRequired, isEmail)}
                >
                  {({ input, meta }) => (
                    <TextField
                      className={classes.emailField}
                      fullWidth
                      disabled={!!userProfile}
                      name="email"
                      label="Email"
                      {...input}
                      type="email"
                      placeholder="Email address"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : undefined
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <Button
                  size="medium"
                  className={classes.submit}
                  type="submit"
                  name="sendForgotPassword"
                >
                  Send Password Reset Email
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      )}
    </Form>
  );
};

const PasswordForm = ({ onSubmit }) => {
  const classes = useStyles();

  const validate = ({ password, confirm_password }) => {
    const errors = {};

    if (!password || password.length <= 0) {
      errors.password = "Password is required";
    }

    if (password && password.length > 0) {
      const passwordValidationError = isPasswordValid(password);

      if (typeof passwordValidationError === "string") {
        errors.password = passwordValidationError;
      }
    }

    if (!errors.password) {
      if (!confirm_password || confirm_password <= 0) {
        errors.confirm_password = "Password confirmation is required";
      } else if (confirm_password !== password) {
        errors.confirm_password = "Password confirmation does not match";
      }
    }

    return errors;
  };

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {props => (
        <form onSubmit={props.handleSubmit}>
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} className={classes.formField}>
                <Typography variant="body1" className={classes.bodyText}>
                  Please enter your new password below.
                </Typography>
                <Field name="password">
                  {({ input, meta }) => (
                    <TextField
                      fullWidth
                      name="password"
                      label="Password"
                      {...input}
                      type="password"
                      placeholder="Password"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : undefined
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.formField}>
                <Field name="confirm_password">
                  {({ input, meta }) => (
                    <TextField
                      fullWidth
                      name="confirm_password"
                      label="Confirm Password"
                      {...input}
                      type="password"
                      placeholder="Confirm Password"
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : undefined
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <Button
                  fullWidth
                  size="medium"
                  className={classes.submit}
                  type="submit"
                >
                  Update Password
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      )}
    </Form>
  );
};

export default ForgotPassword;
