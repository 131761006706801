import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Button,
  Divider,
  TextField,
  Box
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { makeStyles } from "@material-ui/core/styles";
import { AccountContext } from "../../store/AccountContext";
import { Field, Form } from "react-final-form";
import {
  composeValidators,
  isRequired,
  isEmail,
  isEmailRequired
} from "../../utils/validators";
import Alert from "@material-ui/lab/Alert";
import ReactCodeInput from "react-verification-code-input";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useToasts } from "react-toast-notifications";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: "10px 0 30px 0"
  },
  sectionTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.8rem",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2rem",
      lineHeight: "2.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  sectionSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    fontWeight: "500",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },
  formBackground: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2)
  },
  formContainer: {
    padding: "0"
  },
  divider: {
    height: "2px",
    marginBottom: theme.spacing(2),
    backgroundColor: "#e8e8e8"
  },
  phoneInput: {
    width: "inherit !important"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  formHeader: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.4rem",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  bodyText: {
    color: theme.palette.background.ffNavy,
    marginBottom: "10px",
    lineHeight: "1.2"
  },
  verificationCodeContainer: {
    width: "100% !important",
    fontWeight: "600",
    "& input": {
      height: "56px !important",
      width: "15.6% !important",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderTopLeftRadius: "0px !important",
      borderBottomLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
      borderBottom: 0,
      backgroundColor: "#eee",
      margin: "0 0.5%",
      fontSize: "2rem",
      fontWeight: 500,
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
      "&:focus": {
        border: "1px solid green",
        caretColor: "green"
      },
      "&:last-child": {
        borderRight: "none"
      }
    }
  }
}));

export default function EditProfile() {
  const classes = useStyles();
  const {
    userProfile,
    update,
    updating,
    updateError,
    getVerificationCode,
    waitingOnVerificationCode,
    resetLoginWithPhone
  } = useContext(AccountContext);
  const { addToast } = useToasts();

  const [verificationCode, setVerificationCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(userProfile.phoneNumber);
  const [countryCode, setCountryCode] = useState(userProfile.countryCode);
  const [emailNameSubmitted, setEmailNameSubmitted] = useState(false);
  const [mobileSubmitted, setMobileSubmitted] = useState(false);
  const onChange = (value, { dialCode }) => {
    setPhoneNumber(value.substring(dialCode.length));
    setCountryCode(dialCode);
  };

  useEffect(() => {
    return () => resetLoginWithPhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitEmailName = async ({ email, firstName, lastName }) => {
    setEmailNameSubmitted(true);
    setMobileSubmitted(false);
    try {
      await update({ email, firstName, lastName });
      addToast("Profile updated successfully", { appearance: "success" });
    } catch (e) {
      addToast("Ooops! There was an error saving your profile information", {
        appearance: "error"
      });
    }
  };

  const onSubmitMobile = useCallback(async () => {
    setMobileSubmitted(true);
    setEmailNameSubmitted(false);
    try {
      const success = await getVerificationCode({ countryCode, phoneNumber });
      if (!success) {
        throw new Error();
      }

      addToast(
        "Please check your phone for the verification we have sent you",
        {
          appearance: "success"
        }
      );
    } catch (e) {
      addToast("Oops! There was an error sending the verification code", {
        appearance: "error"
      });
    }
  }, [addToast, getVerificationCode, phoneNumber, countryCode]);

  const onSubmitCode = useCallback(async () => {
    try {
      await update({
        countryCode: countryCode,
        phoneNumber: phoneNumber,
        phoneVerificationCode: verificationCode
      });
      addToast("Profile updated successfully", {
        appearance: "success"
      });
    } catch (e) {}
  }, [addToast, update, phoneNumber, countryCode, verificationCode]);

  return (
    <div className={classes.background}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h1" className={classes.sectionTitle}>
              Edit Profile
            </Typography>
            <Divider className={classes.divider} />
            <Box my={4}>
              <Typography variant="h2" className={classes.sectionSubTitle}>
                Your Details
              </Typography>
              <Container className={classes.formBackground}>
                <Form
                  onSubmit={onSubmitEmailName}
                  initialValues={{
                    email: userProfile.email,
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName
                  }}
                >
                  {props => (
                    <form onSubmit={props.handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          {updateError && emailNameSubmitted && (
                            <Alert
                              severity="error"
                              style={{ marginBottom: "0.5rem" }}
                            >
                              {updateError}
                            </Alert>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Field
                            name="email"
                            validate={composeValidators(
                              isEmailRequired,
                              isEmail
                            )}
                          >
                            {({ input, meta }) => (
                              <TextField
                                fullWidth
                                name="email"
                                label="Email"
                                required
                                {...input}
                                disabled={updating}
                                type="email"
                                placeholder="Email"
                                error={meta.error && meta.touched}
                                helperText={meta.error}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Field
                            name="firstName"
                            validate={composeValidators(isRequired)}
                          >
                            {({ input, meta }) => (
                              <TextField
                                fullWidth
                                name="firstName"
                                label="First Name"
                                required
                                {...input}
                                disabled={updating}
                                type="text"
                                placeholder="First Name"
                                error={meta.error && meta.touched}
                                helperText={meta.error}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <Field
                            name="lastName"
                            validate={composeValidators(isRequired)}
                          >
                            {({ input, meta }) => (
                              <TextField
                                fullWidth
                                name="lastName"
                                label="Last Name"
                                required
                                {...input}
                                disabled={updating}
                                type="text"
                                placeholder="Last Name"
                                error={meta.error && meta.touched}
                                helperText={meta.error}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Button
                            className={classes.submit}
                            type="submit"
                            disabled={updating}
                          >
                            Update Profile
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Form>
              </Container>
            </Box>
            <Box my={4}>
              <Typography variant="h2" className={classes.sectionSubTitle}>
                Your Mobile
              </Typography>
              {!waitingOnVerificationCode && (
                <Container className={classes.formBackground}>
                  {userProfile.countryCode && userProfile.phoneNumber && (
                    <Form
                      onSubmit={onSubmitMobile}
                      initialValues={{
                        countryCode: userProfile.countryCode,
                        phoneNumber: userProfile.phoneNumber
                      }}
                    >
                      {props => (
                        <form onSubmit={props.handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              {updateError && mobileSubmitted && (
                                <Alert severity="error">{updateError}</Alert>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <PhoneInput
                                inputClass={classes.phoneInput}
                                inputProps={{
                                  name: "mobileNumber",
                                  required: true,
                                  autoFocus: false
                                }}
                                placeholder="Mobile Number"
                                aria-label="Mobile Number"
                                preferredCountries={[
                                  "gb",
                                  "ie",
                                  "us",
                                  "au",
                                  "nz"
                                ]}
                                excludeCountries={["je"]}
                                onChange={onChange}
                                disabled={updating}
                                enableTerritories={true}
                                value={
                                  userProfile.countryCode +
                                  userProfile.phoneNumber
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Button
                                className={classes.submit}
                                type="submit"
                                disabled={updating}
                              >
                                Update Mobile
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    </Form>
                  )}
                  {!userProfile.countryCode && !userProfile.phoneNumber && (
                    <Form onSubmit={onSubmitMobile}>
                      {props => (
                        <form onSubmit={props.handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              {updateError && mobileSubmitted && (
                                <Alert severity="error">{updateError}</Alert>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <PhoneInput
                                inputClass={classes.phoneInput}
                                inputProps={{
                                  name: "mobileNumber",
                                  required: true,
                                  autoFocus: false
                                }}
                                placeholder="Mobile Number"
                                aria-label="Mobile Number"
                                country="gb"
                                preferredCountries={[
                                  "gb",
                                  "ie",
                                  "us",
                                  "au",
                                  "nz"
                                ]}
                                excludeCountries={["je"]}
                                enableTerritories={true}
                                onChange={onChange}
                                disabled={updating}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Button
                                className={classes.submit}
                                type="submit"
                                disabled={updating}
                              >
                                Update Mobile
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    </Form>
                  )}
                </Container>
              )}

              {waitingOnVerificationCode && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          variant="body1"
                          className={classes.bodyText}
                          align="center"
                        >
                          Let&apos;s make sure it&apos;s really you. Please
                          enter the verification code sent to your mobile
                          number.
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        {updateError && mobileSubmitted && (
                          <Alert severity="error">{updateError}</Alert>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <ReactCodeInput
                          className={classes.verificationCodeContainer}
                          type="number"
                          fields={6}
                          value={verificationCode}
                          onComplete={e => setVerificationCode(e)}
                          disabled={updating}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button
                          size="medium"
                          className={classes.submit}
                          onClick={onSubmitCode}
                        >
                          Verify
                        </Button>
                        <Backdrop className={classes.backdrop} open={updating}>
                          <CircularProgress color="primary" />
                        </Backdrop>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
