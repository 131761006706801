import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Hidden
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import { scroller } from "react-scroll";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "auto",
    display: "flex",
    boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.8)",
    height: "auto",

    "@media (min-width:600px)": {
      height: "auto"
    },
    "@media (min-width:769px)": {
      height: "auto"
    },
    "@media (min-width:960px)": {
      height: "80vh"
    }
  },
  centerBoxLeft: {
    position: "relative",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(5),
    "@media (min-width:600px)": {
      height: "auto",
      marginTop: theme.spacing(5)
    },
    "@media (min-width:769px)": {
      height: "auto"
    },
    "@media (min-width:960px)": {
      height: "80vh",
      alignItems: "flex-start",
      marginTop: theme.spacing(0)
    }
  },
  centerBoxRight: {
    position: "relative",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    "@media (min-width:600px)": {
      height: "auto",
      marginTop: theme.spacing(4)
    },
    "@media (min-width:769px)": {
      height: "auto"
    },
    "@media (min-width:960px)": {
      height: "80vh",
      alignItems: "flex-start",
      marginTop: theme.spacing(0)
    }
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    letterSpacing: "-2px",
    fontSize: "4.4rem",
    lineHeight: "3rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    },
    "@media (min-width:600px)": {
      fontSize: "6.4rem",
      lineHeight: "4.6rem",
      "& span": {
        fontSize: "4rem",
        lineHeight: "4.2rem"
      }
    },
    "@media (min-width:960px)": {}
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "80%"
    },
    "@media (min-width:960px)": {}
  },
  buttonSubText: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginLeft: 0,
    "@media (min-width:600px)": {
      textAlign: "left",
      marginLeft: theme.spacing(5)
    },
    "@media (min-width:960px)": {}
  },
  appImage: {
    minHeight: "500px",
    maxHeight: "700px",
    marginBottom: theme.spacing(5),
    "@media (min-width:960px)": {
      marginBottom: theme.spacing(0)
    }
  },
  appImageHalf: {
    minHeight: "250px",
    maxHeight: "450px",
    marginBottom: theme.spacing(0),
    "@media (min-width:960px)": {
      marginBottom: theme.spacing(0)
    }
  },
  skinWrapper: {
    position: "relative"
  },
  dashboardScroller: {
    position: "absolute",
    top: "64px",
    left: "-1px",
    height: "365px",
    width: "258px",
    padding: "7% 7% 0 7%",
    overflow: "hidden",
    "@media (min-width:600px)": {
      width: "296px",
      top: "95px",
      left: "22px",
      padding: "0",
      height: "580px"
    },
    "@media (min-width:960px)": {}
  },
  imageContainer: {
    height: "365px",
    "@media (min-width:600px)": {
      height: "515px"
    }
  },
  noUnderline: {
    textDecoration: "none"
  }
}));

const Trial = () => {
  const classes = useStyles();

  function scrollToForm() {
    scroller.scrollTo("onboarding-form", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="md" fixed>
          <Grid container>
            <Grid item xs={12} md={7} className={classes.centerBoxLeft}>
              <Box>
                <Box>
                  <Typography variant="h1" className={classes.title}>
                    <span>Take the</span>
                    <br />
                    Free Trial
                  </Typography>
                  <Button onClick={scrollToForm} className={classes.button}>
                    Take Free Trial
                  </Button>
                  <Typography variant="body1" className={classes.buttonSubText}>
                    No Payment Required for Free Trial
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} className={classes.centerBoxRight}>
              <div className={classes.skinWrapper}>
                {/* Visible SM, MD, L, XL */}
                <Hidden xsDown>
                  <img
                    src={
                      "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/device_skin_100.png"
                    }
                    alt="FarmFLiX App"
                    className={classes.appImage}
                  />
                </Hidden>
                {/* Visible XS only */}
                <Hidden smUp>
                  <img
                    src={
                      "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/device_skin_75.png"
                    }
                    alt="FarmFLiX App"
                    className={classes.appImageHalf}
                  />
                </Hidden>
                <div className={classes.dashboardScroller}>
                  <Carousel
                    useKeyboardArrows={true}
                    showArrows={false}
                    interval={3000}
                    showThumbs={false}
                    infiniteLoop={true}
                    showStatus={false}
                    autoPlay={true}
                    showIndicators={false}
                    emulateTouch={false}
                    swipeable={false}
                    transitionTime={500}
                  >
                    <Box className={classes.imageContainer}>
                      <img
                        src={
                          "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/1.png"
                        }
                        alt="App Dashboard"
                      />
                    </Box>
                    <Box className={classes.imageContainer}>
                      <img
                        src={
                          "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/2.png"
                        }
                        alt="App Currently Playing"
                      />
                    </Box>
                    <Box className={classes.imageContainer}>
                      <img
                        src={
                          "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/4.png"
                        }
                        alt="Clydes Farm Series"
                      />
                    </Box>
                  </Carousel>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Trial;
