import React, { useState, useContext } from "react";
import { AccountContext } from "./../../store/AccountContext";
import {
  Grid,
  Typography,
  Button,
  Box,
  Divider,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { useToasts } from "react-toast-notifications";

import CircularProgress from "@material-ui/core/CircularProgress";

import { Field, Form } from "react-final-form";

import { composeValidators, isRequired } from "../../utils/validators";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  bodyText: {
    color: theme.palette.background.ffNavy
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    "& span": {
      fontWeight: 500
    }
  },
  formContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  inputField: {
    "& input": {
      padding: "12px",
      border: "1px solid #a5a5a5",
      backgroundColor: "#f7f7f7",
      margin: "4px 0 10px 0",
      fontSize: "16px",
      borderRadius: "5px"
    },
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem",
      fontWeight: "500",
      lineHeight: "1rem"
    },
    "& label": {
      color: "#656565",
      fontSize: "1.3rem",
      fontWeight: "500"
    }
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "5% 0 2% 0"
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    color: theme.palette.background.ffNavy,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  },
  MuiInput: {
    input: {
      padding: "12px",
      border: "1px solid #a5a5a5",
      backgroundColor: "#f7f7f7",
      margin: "4px 0 15px 0",
      fontSize: "16px",
      borderRadius: "5px"
    }
  },
  submit: {
    margin: "10px 0",
    width: "100%",
    "@media (min-width:600px)": {
      marginTop: 0,
      width: "auto"
    }
  }
}));

export default function ChangeName() {
  const classes = useStyles();
  const { addToast } = useToasts();

  // Account Context
  const {
    updating,
    userProfile,
    update,
    updateError,
    validateLogin
  } = useContext(AccountContext);

  // State
  const [nameSubmitted, setNameSubmitted] = useState(false);

  const onSubmitName = async ({ firstName, lastName }) => {
    setNameSubmitted(true);
    try {
      const updateName = await update({ firstName, lastName });
      if (updateName) {
        validateLogin();
        addToast("Name successfully updated.", {
          appearance: "success"
        });
      }
    } catch (e) {}
  };

  return (
    <>
      {userProfile && (
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.breadcrumbContainer}
            >
              <Link
                className={classes.activeBreadcrumb}
                to="/myaccount/overview"
              >
                Overview
              </Link>
              <Typography className={classes.disabledBreadcrumb}>
                Change Name
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2" className={classes.myAccountSubTitle}>
              Change Name
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              Provide your new name details here.
            </Typography>
          </Grid>
          <Grid container className={classes.formContainer}>
            {!updating && (
              <>
                <Grid item xs={12} sm={8} md={6}>
                  {updateError && nameSubmitted && (
                    <Alert
                      severity="error"
                      variant="filled"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {updateError}
                    </Alert>
                  )}
                  <Form
                    onSubmit={onSubmitName}
                    initialValues={{
                      firstName: userProfile.firstName,
                      lastName: userProfile.lastName
                    }}
                  >
                    {props => (
                      <form onSubmit={props.handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <Field
                              name="firstName"
                              validate={composeValidators(isRequired)}
                            >
                              {({ input, meta }) => (
                                <TextField
                                  fullWidth
                                  name="firstName"
                                  label="First Name"
                                  required
                                  {...input}
                                  disabled={updating}
                                  type="text"
                                  className={classes.inputField}
                                  placeholder="First Name"
                                  error={meta.error && meta.touched}
                                  helperText={meta.error}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Field
                              name="lastName"
                              validate={composeValidators(isRequired)}
                            >
                              {({ input, meta }) => (
                                <TextField
                                  fullWidth
                                  name="lastName"
                                  label="Last Name"
                                  required
                                  {...input}
                                  disabled={updating}
                                  type="text"
                                  className={classes.inputField}
                                  placeholder="Last Name"
                                  error={meta.error && meta.touched}
                                  helperText={meta.error}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Button
                              className={classes.submit}
                              type="submit"
                              disabled={updating}
                            >
                              Update Name
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Form>
                  <Divider className={classes.footerDivider} />
                  <Typography variant="body1" className={classes.bodyTextSml}>
                    <span>Tip</span> - Tell us your name, we&apos;d like to know
                    you!
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}></Grid>
              </>
            )}
            {updating && (
              <Box m={5} align="center">
                <CircularProgress variant="indeterminate"></CircularProgress>
                <Typography variant="body2" className={classes.bodyTextSml}>
                  <br />
                  Updating your Name. Please do not refresh your browser.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
