import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ComputedCompetitor } from "./types";
import { useStyles } from "./styles";
import { RunTimeIcon } from "./RunTime";
import { buildStatusString } from "./utils";

export type MatchupsProps = {
  matchedCompetitors: [ComputedCompetitor, ComputedCompetitor | null][];
  displayType?: "final" | "semi" | "run";
};

export const CompetitorSection = ({
  competitor,
  displayType = "run"
}: {
  competitor: ComputedCompetitor | null;
  isWinner: boolean;
  displayType?: "final" | "semi" | "run";
}) => {
  const classes = useStyles();
  if (!competitor) {
    return (
      <Box className={classes.matchCompetitorContainer}>
        <Typography variant="h3">TBC</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.matchCompetitorContainer}>
      <Box>
        <Typography variant="h3">
          {competitor.firstName} {competitor.lastName}
        </Typography>
        <Typography variant="h4">
          {competitor.tractorMake} {competitor.tractorModel}
        </Typography>
      </Box>

      <Box className={classes.matchCompetitorRunTimes}>
        {displayType === "run" ? (
          <>
            {competitor.run1Time ? (
              <RunTimeIcon
                runTime={competitor.run1Time}
                penalty={competitor.run1Penalty}
              />
            ) : null}

            {competitor.run2Time ? (
              <RunTimeIcon
                runTime={competitor.run2Time}
                penalty={competitor.run2Penalty}
              />
            ) : null}
          </>
        ) : displayType === "semi" && competitor.semiTime ? (
          <RunTimeIcon
            runTime={competitor.semiTime}
            penalty={competitor.semiPenalty}
          />
        ) : displayType === "final" && competitor.finalTime ? (
          <RunTimeIcon
            runTime={competitor.finalTime}
            penalty={competitor.finalPenalty}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export const Matchups = ({
  matchedCompetitors,
  displayType
}: MatchupsProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column">
      {matchedCompetitors.map(([competitor1, competitor2]) => {
        let status = buildStatusString(
          competitor1?.status || competitor2?.status
        );

        // If run is completed, dont show status
        if (
          displayType === "run" &&
          (competitor1?.run1Time || competitor2?.run1Time)
        ) {
          status = null;
        }

        // If semi is completed, dont show status
        if (
          displayType === "semi" &&
          (competitor1?.semiTime || competitor2?.semiTime)
        ) {
          status = null;
        }

        // If final is completed, dont show status
        if (
          displayType === "final" &&
          (competitor1?.finalTime || competitor2?.finalTime)
        ) {
          status = null;
        }

        return (
          <Box className={classes.matchupContainer} key={competitor1.matchUp}>
            <Box className={classes.matchupInnerContainer}>
              <CompetitorSection
                competitor={competitor1}
                displayType={displayType}
                isWinner={
                  !!(
                    competitor1.runTotal &&
                    competitor2?.runTotal &&
                    competitor1.runTotal < competitor2.runTotal
                  )
                }
              />

              <div className={classes.verticalSeparator} />

              <CompetitorSection
                competitor={competitor2}
                displayType={displayType}
                isWinner={
                  !!(
                    competitor1.runTotal &&
                    competitor2?.runTotal &&
                    competitor1.runTotal > competitor2.runTotal
                  )
                }
              />
            </Box>

            {status ? (
              <Typography variant="h3">
                <span className={status.color}>{status?.message}</span>
              </Typography>
            ) : null}
          </Box>
        );
      })}
    </Box>
  );
};
