import React, { useContext, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import logo from "./nav-logo.svg";
import TryButton from "../Onboarding/TryButton";
import { OverviewContext } from "../../store/OverviewContext";

const useStyles = makeStyles(theme => ({
  externalHeader: {
    backgroundColor: theme.palette.background.ffNavy
  },
  grow: {
    flexGrow: 1
  },
  mobileNavIcon: {
    color: theme.palette.common.white,
    fontSize: "2rem"
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  logo: {
    width: "130px",
    height: "auto",
    padding: "10px 0",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(2)
    }
  },
  signInBut: {
    border: "2px solid #fff",
    color: "#fff",
    transition: "none",
    "&:hover": {
      border: "2px solid #99ca3c",
      color: "#99ca3c",
      transition: "none"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.gray, 0.55),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.gray, 0.85)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  mobileNav: {
    width: "100%",
    backgroundColor: theme.palette.background.dark
  },
  mobileNavCloseIcon: {
    color: theme.palette.common.lightgray,
    fontSize: "2rem"
  },
  mobileNavListItem: {
    color: theme.palette.common.lightgray,
    textDecoration: "none",
    fontSize: "1.6rem",
    fontWeight: 600,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  mobileNavListItemSub: {
    color: theme.palette.common.lightgray,
    textDecoration: "none",
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  mobileNavListDivider: {
    height: "2px",
    marginLeft: theme.spacing(2)
  }
}));

export default function Header({ showTry = true, showSignIn = true }) {
  const classes = useStyles();
  const { overview } = useContext(OverviewContext);

  // State
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        p={1}
        m={1}
        css={{ height: 100 }}
      >
        <Box>
          <List>
            {showSignIn && (
              <ListItem>
                <ListItemText
                  primary={
                    <Link to="/login" className={classes.mobileNavListItem}>
                      Log In
                    </Link>
                  }
                />
              </ListItem>
            )}
          </List>
          <Divider className={classes.mobileNavListDivider} />
          {showTry && (
            <>
              {/* 
              Doors Open & Trials Open - show Free Trial
              Doors Closed & Trials Open - show Free Trial
              Doors Open & Trials Closed - show Join Now
              Doors Closed & Trials Closed - show Free Trial (notify me status)
            */}
              {overview && (
                <>
                  {overview.doors?.open && overview.doors?.trialsOpen && (
                    <>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <TryButton
                              label="Try FarmFLiX"
                              name="try-farmflix"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#99ca3c",
                                textTransform: "capitalize",
                                padding: "0",
                                fontSize: "1.6rem",
                                fontWeight: "600",
                                marginLeft: 0
                              }}
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                      <Divider className={classes.mobileNavListDivider} />
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Link
                              to="/buy"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#ddd",
                                textDecoration: "none",
                                textTransform: "capitalize",
                                padding: "0",
                                fontSize: "1.6rem",
                                fontWeight: "600",
                                marginLeft: 0
                              }}
                            >
                              Join FarmFLiX
                            </Link>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </>
                  )}

                  {!overview.doors?.open && overview.doors?.trialsOpen && (
                    <>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <TryButton
                              label="Try FarmFLiX"
                              name="try-farmflix"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#99ca3c",
                                textTransform: "capitalize",
                                padding: "0",
                                fontSize: "1.6rem",
                                fontWeight: "600",
                                marginLeft: 0
                              }}
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                      <Divider className={classes.mobileNavListDivider} />
                    </>
                  )}

                  {overview.doors?.open && !overview.doors?.trialsOpen && (
                    <>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Link
                              to="/buy"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#99ca3c",
                                textDecoration: "none",
                                textTransform: "capitalize",
                                padding: "0",
                                fontSize: "1.6rem",
                                fontWeight: "600",
                                marginLeft: 0
                              }}
                            >
                              Join FarmFLiX
                            </Link>
                          </ListItemText>
                        </ListItem>
                      </List>
                      <Divider className={classes.mobileNavListDivider} />
                    </>
                  )}

                  {!overview.doors?.open && !overview.doors?.trialsOpen && (
                    <>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <TryButton
                              label="Try FarmFLiX"
                              name="try-farmflix"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#99ca3c",
                                textTransform: "capitalize",
                                padding: "0",
                                fontSize: "1.6rem",
                                fontWeight: "600",
                                marginLeft: 0
                              }}
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                      <Divider className={classes.mobileNavListDivider} />
                    </>
                  )}
                </>
              )}
            </>
          )}
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <Link to="/about" className={classes.mobileNavListItemSub}>
                    What is FarmFLiX?
                  </Link>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <a
                    href="https://shop.farmflix.tv"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.mobileNavListItemSub}
                  >
                    The Shop
                  </a>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Link to="/help" className={classes.mobileNavListItemSub}>
                    Help
                  </Link>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Link
                    to="/usemycode"
                    className={classes.mobileNavListItemSub}
                  >
                    Redeem Giftcard
                  </Link>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Link to="/contact" className={classes.mobileNavListItemSub}>
                    Contact Us
                  </Link>
                }
              />
            </ListItem>
          </List>
        </Box>
        <div className={classes.grow} />
        <Box p={1}>
          <CloseIcon
            className={classes.mobileNavCloseIcon}
            onClick={toggleDrawer("right", true)}
          />
        </Box>
      </Box>
    </div>
  );

  return (
    <AppBar position="static" className={classes.externalHeader}>
      <Toolbar>
        <Link to="/">
          <img src={logo} className={classes.logo} alt="FarmFLiX Logo" />
        </Link>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          {showSignIn && (
            <Link to="/login" style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                className={classes.signInBut}
                size="small"
              >
                Log In
              </Button>
            </Link>
          )}
          {showTry && (
            <TryButton
              size="small"
              label="Try Now"
              name="try-now"
              variant="text"
            />
          )}
        </div>
        <div className={classes.sectionMobile}>
          <MenuIcon
            className={classes.mobileNavIcon}
            onClick={toggleDrawer("right", true)}
          />
        </div>
      </Toolbar>
      <Drawer
        variant="temporary"
        anchor="right"
        classes={{ paper: classes.mobileNav }}
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        {sideList("right")}
      </Drawer>
    </AppBar>
  );
}
