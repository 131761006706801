import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./../../store/AccountContext";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { uppercaseFirst } from "../../utils/strings";
import usePromise from "react-use-promise";
import CircularProgress from "@material-ui/core/CircularProgress";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  bodyText: {
    color: theme.palette.background.ffNavy
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem"
  },
  historyContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    color: theme.palette.background.ffNavy,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  }
}));

export default function PaymentHistory() {
  const classes = useStyles();

  // Account Context
  const { getMembershipDetails, resetErrors } = useContext(AccountContext);

  useEffect(() => {
    return () => resetErrors();
    // eslint-disable-next-line
  }, []);

  const [reloadTrigger, setReloadTrigger] = useState(Date.now());
  const [membershipDetails, , state] = usePromise(getMembershipDetails, [
    reloadTrigger
  ]);
  const loading = state === "pending";

  // eslint-disable-next-line
  const reload = () => setReloadTrigger(Date.now());

  function CurrencySymbol({ currency }) {
    switch (currency) {
      case "GBP":
        return "\u00A3";
      case "EUR":
        return "\u20AC";
      case "USD":
        return "\u0024";
      default:
        return "\u00A3";
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumbContainer}
        >
          <Link className={classes.activeBreadcrumb} to="/myaccount/overview">
            Overview
          </Link>
          <Typography className={classes.disabledBreadcrumb}>
            Payment History
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h2" className={classes.myAccountSubTitle}>
          Payment History
        </Typography>
      </Grid>
      <Grid container className={classes.cardContainer}>
        {loading && (
          <Grid item xs={12} sm={12}>
            <Box m={5} align="center">
              <CircularProgress variant="indeterminate"></CircularProgress>
            </Box>
          </Grid>
        )}
        {!loading && (
          <>
            {membershipDetails && (
              <>
                <TableContainer elevation={0}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Method</TableCell>
                        <TableCell align="left">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <>
                      <TableBody>
                        {membershipDetails.payments.map(item => (
                          <TableRow key={item.id}>
                            <TableCell>
                              {item.cost <= 0 && "N/A"}
                              {item.cost > 0 && (
                                <>
                                  <CurrencySymbol currency={item.currency} />
                                  {item.cost}
                                </>
                              )}
                              {/* {parseFloat(item.cost) <= 0
                                      ? "N/A"
                                      : "£" + parseFloat(item.cost).toFixed(2)}
                                       */}
                            </TableCell>
                            <TableCell>
                              {new Date(item.created).toLocaleString()}
                            </TableCell>
                            <TableCell>
                              {item.paymentProvider === "stripe"
                                ? "Card"
                                : item.paymentProvider === "giftcard"
                                ? "Gift Card"
                                : item.paymentProvider === "paypal"
                                ? "Card (Paypal)"
                                : "Free Trial"}
                            </TableCell>
                            <TableCell>
                              {uppercaseFirst(item.status)}
                              {item.failureReason
                                ? ` - ${item.failureReason}`
                                : ""}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
