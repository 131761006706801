import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AccountContext } from "../../../../store/AccountContext";
import { OnboardingContext } from "../../../../store/OnboardingContext";
import {
  APPLICATION_SOURCE_GIFTCARD,
  APPLICATION_STATUS
} from "@booyaltd/core";

const SignUpStepSuccess = () => {
  const { application } = useContext(OnboardingContext);
  const { userProfile } = useContext(AccountContext);

  if (
    !userProfile ||
    !application ||
    application.status !== APPLICATION_STATUS.CONVERTED.toString()
  )
    return null;

  const { source } = application;

  console.log(application.status, source, userProfile.memberStatusId);

  if (source.toString() === APPLICATION_SOURCE_GIFTCARD) {
    return <Redirect to="/success/giftcard" />;
  }

  return <Redirect to={"/videos"} />;
};

export default SignUpStepSuccess;
