import React from "react";
import { Typography } from "@material-ui/core";

export default function Success() {
  return (
    <>
      <Typography variant="h2" align="center">
        Thank you!
      </Typography>
      <Typography component="p" align="center">
        You will get an email with further instructions
      </Typography>
    </>
  );
}
