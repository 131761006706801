import HeaderInternal from "../components/Header/HeaderInternal";
import Footer from "../components/Footer/Footer";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { AccountContext } from "../store/AccountContext";
import DefaultLayout from "./DefaultLayout";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  loading: {
    textAlign: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  }
}));

const LatestLayout = ({ children }) => {
  const { loading, loggedIn, userProfile } = useContext(AccountContext);
  const history = useHistory();
  const classes = useStyles();

  if (!loading && !loggedIn) {
    history.push("/latest-preview");
    return null;
  }

  // Loading Screen
  if (loading && !loggedIn) {
    return (
      <DefaultLayout>
        <div className={`${classes.background} ${classes.loading}`}>
          <CircularProgress color="primary" />
        </div>
      </DefaultLayout>
    );
  }

  // Trial Member - Trial Expired - Redirect To Subscription Window
  if (loggedIn && userProfile.memberStatus === "trial-expired") {
    return <Redirect to="/myaccount/overview" />;
  }

  // Member is Locked
  if (
    loggedIn &&
    (userProfile.isLocked || userProfile.memberStatus === "locked")
  ) {
    return <Redirect to="/myaccount/overview" />;
  }

  // Member is Standard Incomplete - send them to lock them for the time being
  if (
    loggedIn &&
    !loading &&
    userProfile.memberStatus === "standard-incomplete"
  ) {
    return <Redirect to="/myaccount/overview" />;
  }

  // Member is Trial
  if (loggedIn && userProfile.memberStatus === "trial") {
    return (
      <>
        <HeaderInternal />
        {children}
        <Footer />
      </>
    );
  }

  // Member has a Expired Subscription (paid at least once)
  if (loggedIn && userProfile.memberStatus === "standard-expired") {
    return <Redirect to="/myaccount/overview" />;
  }

  return (
    <>
      <HeaderInternal />
      {children}

      <Footer />
    </>
  );
};

export default LatestLayout;
