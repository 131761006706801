import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { getCatalogSearch } from "../api/client";
import CatalogSearchRequest from "../api/requests/CatalogSearchRequest";
import useLogger from "./useLogger";
import { InteractionEvents, LogEventCategory } from "../api/entities/Log";
import { VideoSearchResult } from "@booyaltd/core";

const useVideoSearch = (): IUseVideoSearch => {
  const [search, setSearch] = useState<CatalogSearchRequest>();
  const [lastFetchOffset, setLastFetchOffset] = useState<number>(0);
  const [videos, setVideos] = useState<VideoSearchResult[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchSubmittedNew, setSearchSubmittedNew] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<boolean>(false);
  const [rawSearchPhrase, setSearchPhrase] = useState<string>("");
  const [rawSearchTag, setSearchTag] = useState<string>("");
  const [rawSortOrder, setSortOrder] = useState<string>("");
  const [debouncedSearchPhrase] = useDebounce(rawSearchPhrase, 100);
  const [debouncedSearchLoggingPhrase] = useDebounce(rawSearchPhrase, 2000);

  const { logEvent } = useLogger();

  const triggerRefresh = async () => {
    if (total && videos && total >= videos.length) {
      return;
    }

    setLoading(false);
    setSearchSubmittedNew(false);
    setVideos([]);
    setTotal(0);
    setError(undefined);
    setSearch({
      ...search,
      tag: rawSearchTag,
      phrase: rawSearchPhrase,
      offset: 0,
      type: "master",
      sort: rawSortOrder
    } as CatalogSearchRequest);
  };

  const triggerNextPage = async () => {
    if (total && videos && videos.length >= total) {
      return;
    }

    setSearch({ ...search, offset: videos.length } as CatalogSearchRequest);
  };

  const fetchSearch = async () => {
    if (loading) {
      return;
    }

    if (total && videos.length >= total) {
      return;
    }

    if (!search || (!search.tag && !search.phrase)) {
      return;
    }

    if (lastFetchOffset > 0 && lastFetchOffset === search.offset) {
      return;
    }

    if (search.offset === 0) {
      setLoading(true);
    }

    setSearchSubmittedNew(true);

    setLastFetchOffset(search.offset);

    try {
      const response = await getCatalogSearch(search);
      setVideos([
        ...videos,
        ...response.videos.filter(
          newVideo =>
            !videos.some(existingVideo => existingVideo.id === newVideo.id) // Don't add same video twice (just incase)
        )
      ]);
      if (response.total === 0) {
        setSearchResults(false);
      } else {
        setSearchResults(true);
      }
      setTotal(response.total);
      setLoading(false);
    } catch (e) {
      setSearchResults(false);
      setError(e);
      setVideos([]);
      setTotal(0);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (!debouncedSearchPhrase || debouncedSearchPhrase.length < 3) {
      return;
    }
    triggerRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchPhrase]);

  useEffect(() => {
    if (debouncedSearchLoggingPhrase !== "") {
      logEvent({
        category: LogEventCategory.INTERACTION,
        event: InteractionEvents.SEARCH,
        data: { searchPhrase: debouncedSearchLoggingPhrase, results: total }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchLoggingPhrase]);

  const setSearchPhraseWrapped = (phrase: string) => {
    setVideos([]);
    setTotal(0);
    setSearchPhrase(phrase);
    setLastFetchOffset(0);
  };

  const setSearchTagWrapped = (tag: string) => {
    setVideos([]);
    setTotal(0);
    setSearchTag(tag);
    setLastFetchOffset(0);
  };

  const setSortOrderWrapped = (order: string) => {
    console.log(order);
    setVideos([]);
    setTotal(0);
    setSortOrder(order);
    setLastFetchOffset(0);
  };

  const resetError = () => {
    setSearchSubmittedNew(false);
    setError(undefined);
    setLastFetchOffset(0);
  };

  const resetVideoSearch = () => {
    setVideos([]);
    setSearch(undefined);
    setLastFetchOffset(0);
  };

  return {
    search,
    setSearch,
    loading,
    error,
    videos,
    total,
    triggerNextPage,
    triggerRefresh,
    searchSubmittedNew,
    searchResults,
    searchPhrase: rawSearchPhrase,
    setSearchPhrase: setSearchPhraseWrapped,
    searchTag: rawSearchTag,
    searchSortOrder: rawSortOrder,
    setSearchTag: setSearchTagWrapped,
    setSearchSortOrder: setSortOrderWrapped,
    setVideos,
    setTotal,
    resetError,
    resetVideoSearch
  };
};

interface IUseVideoSearch {
  error: string | undefined;
  loading: boolean;
  total: number;
  videos: VideoSearchResult[];
  search: CatalogSearchRequest | undefined;
  triggerNextPage(): void;
  triggerRefresh(): void;
  searchPhrase: string;
  searchSubmittedNew: boolean;
  searchResults: boolean;
  setSearchPhrase(phrase: string): void;
  searchTag: string;
  searchSortOrder: string;
  setSearchTag(tag: string): void;
  setSearchSortOrder(order: string): void;
  setSearch(search: CatalogSearchRequest): void;
  setVideos(videos: VideoSearchResult[]): void;
  setTotal(total: number): void;
  resetError(): void;
  resetVideoSearch(): void;
}

export default useVideoSearch;
