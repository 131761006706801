import React, { useContext } from "react";
import { Button, ButtonProps } from "@material-ui/core";
import { TryBuyContext } from "./TryBuyContainer";
// import useStyles from "../styles";

type TryButtonProps = Omit<ButtonProps, "onClick" | "children"> & {
  label: string;
  modalType?: "trial" | "notify";
};

function TryButton({ label, modalType = "trial", ...props }: TryButtonProps) {
  const { setDialogOpen } = useContext(TryBuyContext);

  return (
    <Button
      onClick={() => {
        setDialogOpen(modalType);
      }}
      {...props}
      size={props.size}
    >
      {label}
    </Button>
  );
}

export default TryButton;
