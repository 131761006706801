import React, { useContext, useEffect } from "react";
import { AccountContext } from "../../store/AccountContext";
import { Link, Redirect } from "react-router-dom";
import { Field, Form } from "react-final-form";
import {
  composeValidators,
  isEmail,
  isEmailRequired,
  isPasswordRequired
} from "../../utils/validators";
import {
  CircularProgress,
  makeStyles,
  Typography,
  Grid,
  Backdrop,
  Button,
  TextField
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(1.5, 0, 2)
  },
  bodyText: {
    color: theme.palette.common.white,
    marginBottom: "10px",
    textAlign: "center"
  },
  center: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  validationError: {
    marginBottom: theme.spacing(2)
  },
  backdrop: {
    zIndex: 100000000,
    color: "#fff"
  },
  forgotPassword: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontWeight: 400,
    textAlign: "center"
  },
  button: {
    margin: 0
  },
  secButton: {
    margin: theme.spacing(1.5, 0, 2),
    borderColor: "#e9e9e9",
    color: "#e9e9e9",
    "@media (min-width:600px)": {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    },
    "&:hover": {
      borderColor: "#fff",
      color: "#fff"
    }
  },
  formField: {
    "& label": {
      color: theme.palette.common.white
    },
    "& input": {
      backgroundColor: "#444",
      color: "#fff",
      borderColor: "#444"
    }
  }
}));

const EmailSignIn = ({
  toggleLoginMethod
}: {
  toggleLoginMethod: () => void;
}) => {
  const {
    loginWithUsernamePassword,
    loggedIn,
    loading,
    resetErrors,
    loginError
  } = useContext(AccountContext);
  const classes = useStyles();

  useEffect(() => {
    return () => resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async ({
    email,
    password
  }: {
    email: string;
    password: string;
  }) => {
    await loginWithUsernamePassword(email, password);
  };

  if (loggedIn) {
    return <Redirect to="/videos" />;
  }

  return (
    <>
      <Typography
        component="p"
        variant="body2"
        className={classes.bodyText}
        style={{ marginBottom: "20px" }}
      >
        Log In with your Email and Password.
      </Typography>
      <Form onSubmit={onSubmit}>
        {props => (
          <form onSubmit={props.handleSubmit}>
            <div className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className={classes.formField}>
                  {loginError && (
                    <Alert
                      className={classes.validationError}
                      severity="error"
                      variant="filled"
                    >
                      {loginError}
                    </Alert>
                  )}
                  <Field
                    name="email"
                    validate={composeValidators(isEmailRequired, isEmail)}
                  >
                    {({ input, meta }) => (
                      <TextField
                        fullWidth
                        label="Email"
                        {...input}
                        disabled={loading || loggedIn}
                        type="email"
                        placeholder="Email"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : undefined
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.formField}>
                  <Field
                    name="password"
                    validate={composeValidators(isPasswordRequired)}
                  >
                    {({ input, meta }) => (
                      <TextField
                        fullWidth
                        label="Password"
                        {...input}
                        disabled={loading || loggedIn}
                        type="password"
                        placeholder="Password"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : undefined
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Link
                    to="/forgotpassword"
                    style={{
                      textDecoration: "underline",
                      textDecorationColor: "#99ca3c"
                    }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.forgotPassword}
                    >
                      Forgot Your Password?
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {!loading && (
                    <Button
                      name="signIn"
                      fullWidth
                      size="medium"
                      className={classes.submit}
                      type="submit"
                    >
                      Log In
                    </Button>
                  )}
                  {loading && (
                    <Backdrop className={classes.backdrop} open={true}>
                      <CircularProgress color="primary" />
                    </Backdrop>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  onClick={toggleLoginMethod}
                  fullWidth
                  className={classes.secButton}
                  size="medium"
                  variant="outlined"
                  focusRipple={true}
                >
                  Switch to Mobile
                </Button>
              </Grid>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};

export default EmailSignIn;
