import React, { useContext, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import LoggedInLayout from "../layouts/LoggedInLayout";
import ReactPlayer from "react-player";
import { Redirect } from "react-router-dom";
import { PlayerContext } from "../store/PlayerContext";
import { AccountContext } from "../store/AccountContext";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import VideoPlayerDetails from "./Video/VideoPlayerDetails";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    }
  },
  largeTitle: {
    color: theme.palette.background.lightgray,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  episodeTitle: {
    color: theme.palette.background.upgradeAlt,
    marginBottom: theme.spacing(1),
    fontSize: "1.4rem"
  },
  bodyText: {
    color: "#bbb"
  },
  detailsContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  detailsIcon: {
    display: "inline",
    color: theme.palette.background.ffGreen,
    marginRight: "4px",
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem"
    }
  },
  detailsText: {
    display: "inline",
    color: theme.palette.common.darkgray,
    marginRight: theme.spacing(2),
    fontSize: "0.9rem"
  },
  marginSpacing: {
    margin: "20px 0"
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%"
  },
  player: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    "& video": {
      "&:focus": {
        outline: "none"
      }
    }
  },
  playIcon: {
    fontSize: "6rem",
    "@media (min-width:600px)": {
      fontSize: "7rem"
    },
    "@media (min-width:960px)": {
      fontSize: "8rem"
    }
  },
  smlDivider: {
    height: "2px",
    backgroundColor: theme.palette.background.ffNavyLight,
    width: "10%",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  upgradeBannerBackground: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.upgrade,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: "center"
  },
  upgradeTitle: {
    marginBottom: theme.spacing(1),
    fontSize: "1.8rem",
    fontWeight: "500",
    color: theme.palette.common.white
  },
  upgradeSubTitle: {
    marginBottom: theme.spacing(2),
    fontSize: "1.4rem",
    fontWeight: "400",
    color: theme.palette.background.white
  },
  upgradeBodyText: {
    marginBottom: theme.spacing(2),
    color: theme.palette.background.white,
    fontWeight: 300,
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    }
  },
  channelImage: {
    margin: "0 5% 0 15%",
    borderRadius: "50%",
    width: "80%",
    "@media (min-width:600px)": {
      margin: "0 5% 0 35%",
      borderRadius: "50%",
      width: "60%"
    },
    "@media (min-width:960px)": {
      margin: "0 5% 0 35%",
      borderRadius: "50%",
      width: "60%"
    }
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  relatedVideoList: {},
  relatedVideoListTitle: {
    marginBottom: "10px",
    color: theme.palette.common.lightgray
  },
  creditsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  creditsTitle: {
    color: theme.palette.background.upgradeAlt
  },
  creditsDetail: {
    color: "#c3c3c3",
    margin: "4px 0",
    "& span": {
      color: theme.palette.background.upgradeAlt
    }
  },
  tagsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  tagsTitle: {
    marginBottom: theme.spacing(1),
    color: theme.palette.background.upgradeAlt
  },
  legacyText: {
    fontSize: "0.8rem",
    lineHeight: "0.9rem",
    color: theme.palette.background.upgradeAlt
  },
  legacyLink: {
    color: theme.palette.background.ffGreen
  }
}));

const VideoLegacy = props => {
  const { loading, loggedIn } = useContext(AccountContext);
  const videoId = props.match.params.id;

  const {
    resetError,
    resetVideoSearch,
    clearCurrentVideo,
    currentVideo,
    currentVideoSource,
    loadVideo,
    videos,
    onVideoProgress
  } = useContext(PlayerContext);

  useEffect(() => {
    return () => {
      clearCurrentVideo();
      resetError();
      resetVideoSearch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (videoId && !currentVideo) {
      loadVideo(videoId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId, videos.length, currentVideo]);

  // Add video progress logs
  const addProgress = event => {
    onVideoProgress({ playbackPosition: Math.floor(event.playedSeconds) });
  };

  const player = useRef();

  const resumeProgress = e => {
    player.current.seekTo(parseFloat(currentVideo.progress));
  };

  const classes = useStyles();

  if (!loading && !loggedIn) {
    return <Redirect to={"/preview/" + videoId + "-preview"} />;
  }

  return (
    <div>
      <LoggedInLayout>
        {currentVideo && (
          <div className={classes.background}>
            <Container>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.playerWrapper}
                >
                  <ReactPlayer
                    playing
                    ref={player}
                    className={classes.player}
                    url={currentVideoSource}
                    onStart={resumeProgress}
                    onProgress={addProgress}
                    progressInterval={2000}
                    controls={true}
                    width="100%"
                    height="100%"
                    light={currentVideo.poster}
                    playIcon={
                      <PlayCircleFilledIcon
                        color="primary"
                        className={classes.playIcon}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <VideoPlayerDetails video={currentVideo} />
                </Grid>
              </Grid>
            </Container>
          </div>
        )}
      </LoggedInLayout>
    </div>
  );
};

export default VideoLegacy;
