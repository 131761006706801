import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Header from "./Header/Header";
import HeaderInternal from "./Header/HeaderInternal";
import Footer from "./Footer/Footer";
import { AccountContext } from "../store/AccountContext";
import { Redirect } from "react-router";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import StepForm from "./MemberSurvey/StepForm";
import { StepsProvider } from "../store/MemberSurveyContext";
import { Link } from "react-router-dom";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    // @ts-ignore
  },
  surveyContainer: {
    // @ts-ignore
    paddingTop: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(3),
    letterSpacing: "-2px",
    fontSize: "3rem",
    lineHeight: "3.2rem",
    color: theme.palette.background.ffNavyLighter,
    fontWeight: 600,
    // textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "4rem",
      lineHeight: "4.2rem"
    },
    "@media (min-width:960px)": {}
  },
  bodyText: {
    color: theme.palette.background.ffNavyLighter,
    marginBottom: theme.spacing(2)
  },
  inputLabel: {
    "& span.MuiFormControlLabel-label": {
      color: "#292929"
    }
  },
  linearLabel: {
    color: theme.palette.background.ffNavyLighter
  }
}));

const MemberSurvey = () => {
  const classes = useStyles();
  const { loggedIn } = useContext(AccountContext);

  // Redirect if not logged in
  if (!loggedIn) {
    return <Redirect to={"/login"} />;
  }

  const title = "Member Survey 2022";
  const description = "Please take a moment to complete the survey below.";

  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Member Survey | FarmFLiX.tv</title>
        <meta name="description" content={description} />
      </Helmet>
      {loggedIn && <HeaderInternal />}
      {!loggedIn && <Header />}
      <Container maxWidth={"md"} className={classes.surveyContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
            {localStorage.getItem("ff_memberSurvey22_completed") === "true" ? (
              <>
                <Typography
                  variant="body1"
                  className={classes.bodyText}
                  style={{ marginTop: "24px" }}
                >
                  Thank-you for completing the FarmFLiX survey.
                </Typography>
                <Link to={"/videos"} style={{ textDecoration: "none" }}>
                  <Button style={{ margin: "24px 0 36px 0" }}>
                    Go to Dashboard
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <Typography variant="body1" className={classes.bodyText}>
                  {description}
                </Typography>
                <StepsProvider>
                  <StepForm />
                </StepsProvider>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default MemberSurvey;
