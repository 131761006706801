import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

type ErrorMessageProps = {
  title: string;
  message: string;
  linkHome: boolean;
};

const useStyles = makeStyles(theme => ({
  largeTitle: {
    fontSize: "4rem",
    color: theme.palette.common.white
  },
  marginSpacing: {
    margin: "20px 0"
  }
}));

function ErrorMessage(props: ErrorMessageProps) {
  const classes = useStyles();

  return (
    <Container fixed>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          alignItems="center"
          alignContent="center"
        >
          <Box my={10} alignItems="center" textAlign="center">
            <Typography variant="h1" className={classes.largeTitle}>
              {props.title}
            </Typography>
            <Typography variant="h3" color="primary">
              {props.message}
            </Typography>
            {props.linkHome && (
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button size="small" className={classes.marginSpacing}>
                  Go back to Home
                </Button>
              </Link>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ErrorMessage;
