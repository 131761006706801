import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import appleappstore from "./../Modals/app-store-logo.png";
import googleplaystore from "./../Modals/google-play-logo.png";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.ffNavyLighter,
    borderTopColor: theme.palette.background.ffGreen,
    borderTop: "5px solid",
    padding: theme.spacing(2, 4),
    "@media (min-width:600px)": {
      padding: theme.spacing(4)
    }
  },
  appContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    "@media (min-width:960px)": {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  },
  appTitle: {
    marginBottom: "20px",
    textAlign: "center",
    fontSize: "1.6rem",
    color: theme.palette.common.white,
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  appDesc: {
    fontWeight: "normal",
    color: theme.palette.common.white,
    textAlign: "center",
    lineHeight: "1rem",
    fontSize: "0.8rem",
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.1rem"
    }
  },
  appSmlPrint: {
    fontSize: "0.7rem",
    color: theme.palette.background.ffNavy,
    textAlign: "center"
  },
  appDownloadLinks: {
    textAlign: "center",
    margin: "15px 0 5px 0"
  },
  appStoreLogo: {
    maxWidth: "120px",
    margin: "5px",
    textAlign: "center",
    "@media (min-width:600px)": {
      margin: "10px 5px",
      maxWidth: "130px"
    },
    "@media (min-width:960px)": {
      margin: "10px 5px",
      maxWidth: "140px"
    }
  },
  appGraphic: {
    display: "none",
    width: "70%",
    margin: "20px 15% -3px 15%",
    textAlign: "center",
    "@media (min-width:600px)": {
      display: "block",
      width: "96%",
      margin: "20px 2% -3px 2%"
    },
    "@media (min-width:960px)": {
      width: "80%",
      margin: "0 10% -3px 10%"
    }
  }
}));

export default function InstallAppBanner() {
  const classes = useStyles();

  return (
    <div>
      <Container className={classes.appContainer}>
        <Box>
          <Grid container spacing={0} className={classes.background}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography className={classes.appTitle} variant="h2">
                Get the App Now
              </Typography>
              <Typography className={classes.appDesc} variant="body2">
                Download the Free FarmFLiX App to have on-the-go access to our
                extensive library of agricultural content.
              </Typography>
              <Grid item className={classes.appDownloadLinks}>
                <a
                  href={"https://smart.link/6fa45jd155j1i"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={appleappstore}
                    className={classes.appStoreLogo}
                    alt="Apple App Store Graphic"
                  />
                </a>
                <a
                  href={"https://smart.link/6fa45jd155j1i"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={googleplaystore}
                    className={classes.appStoreLogo}
                    alt="Google Play Store Graphic"
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
