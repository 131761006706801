import React, { useCallback, useContext, useState } from "react";
import StepContainer from "./StepContainer";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import logo from "../../../Header/nav-logo.png";
import Button from "@material-ui/core/Button";
import { OnboardingContext } from "../../../../store/OnboardingContext";
import useStyles from "../SignUpForm.styles";
import { validateGiftcardCode } from "../../../../api/client";
import GiftcardResponse from "../../../../api/responses/GiftcardResponse";
import { APPLICATION_SOURCE } from "@booyaltd/core";
import { useLocation, useParams } from "react-router-dom";

const SignUpStepGiftcard = () => {
  const { code: urlCode } = useParams<{ code?: string }>();
  const location = useLocation();
  const { loading, error, runWithLoader, initialise } = useContext(
    OnboardingContext
  );
  const classes = useStyles();
  const [complete, setComplete] = useState(false);
  const [codeInputValue, setCodeInputValue] = useState(urlCode || "");
  const [code, setCode] = useState<GiftcardResponse>();

  const codeDurationString = code
    ? `${code.entitlementPeriodValue} ${code.entitlementPeriodUnit}${
        code.entitlementPeriodValue > 1 ? "s" : ""
      }`
    : "";

  const handleGiftcardInput = useCallback(
    evt => {
      setCodeInputValue(evt.target.value.toUpperCase().trim());

      if (complete) {
        setComplete(false);
      }
    },
    [complete]
  );

  const onCheckClicked = useCallback(
    evt => {
      evt.preventDefault();

      if (!codeInputValue) {
        return;
      }

      runWithLoader(async () => {
        await validateGiftcardCode(codeInputValue).then(giftcard => {
          setCodeInputValue(giftcard.giftcardCode);
          setCode(giftcard);
        });
      });
    },
    [codeInputValue, runWithLoader]
  );

  const onResetClicked = useCallback(() => {
    setCode(undefined);
    setCodeInputValue("");
  }, []);

  const onNextClicked = useCallback(() => {
    if (code && code.giftcardCode === codeInputValue) {
      initialise({
        route: "giftcard",
        source: APPLICATION_SOURCE.GIFTCARD,
        code: code.giftcardCode,
        page: location.pathname
      });
    }
  }, [code, codeInputValue, initialise, location.pathname]);

  return (
    <StepContainer>
      <StepContainer.Title title={complete ? "Confirm Code" : "Enter Code"} />
      <StepContainer.Body>
        <>
          {!complete && (
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="body2"
                  className={classes.bodyText}
                  align="center"
                >
                  Enter the 16 character code from your Giftcard to start
                  enjoying FarmFLiX.
                </Typography>
                <TextField
                  name="giftcard"
                  inputProps={{
                    min: 0,
                    style: {
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold"
                    }
                  }}
                  className={classes.formField}
                  fullWidth={true}
                  type="text"
                  autoCapitalize="true"
                  value={codeInputValue}
                  onChange={handleGiftcardInput}
                  disabled={loading}
                  error={!!error}
                  helperText={"E.g. xxxx-xxxx-xxxx-xxxx"}
                />
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
            {code && (
              <>
                <div>
                  <Box className={classes.successBox}>
                    <img src={logo} alt="" className={classes.successLogo} />
                    <Typography
                      variant="body2"
                      className={classes.bodyText}
                      style={{
                        marginTop: "10px",
                        fontWeight: 500,
                        fontSize: "1rem",
                        color: "#171717"
                      }}
                    >
                      This Giftcard will give you access to FarmFLiX for:
                    </Typography>
                    <Typography className={classes.successBoxHeader}>
                      {codeDurationString}
                    </Typography>
                  </Box>
                </div>
                <Grid container>
                  <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                    <Typography
                      className={`${classes.bodyText} ${classes.resetCode}`}
                      variant="body2"
                      onClick={onResetClicked}
                    >
                      Wrong Code? Reset Now
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

            {(!code || code.giftcardCode !== codeInputValue) && (
              <Button
                name="check"
                size="medium"
                type="button"
                className={classes.submit}
                disabled={loading || !codeInputValue}
                onClick={onCheckClicked}
              >
                Check Giftcard
              </Button>
            )}

            {code && code.giftcardCode === codeInputValue && (
              <Button
                name="next"
                size="medium"
                type="button"
                className={classes.submit}
                disabled={loading || !codeInputValue}
                onClick={onNextClicked}
              >
                Next
              </Button>
            )}
          </Grid>
        </>
      </StepContainer.Body>
      <StepContainer.Actions />
    </StepContainer>
  );
};

export default SignUpStepGiftcard;
