import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "auto",
      height: "auto",
      minHeight: "250px",
      display: "flex",
      flexDirection: "column",
      boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.7)",
      verticalAlign: "middle",
      textAlign: "center",
      alignItems: "center"
    },
    pageSection: {
      padding: theme.spacing(2)
    },
    mainSection: {
      minHeight: "calc(100vh - 340px)"
    },
    background: {
      backgroundColor: "#292929"
    },
    backgroundAlt: {
      // @ts-ignore
      backgroundColor: theme.palette.background.ffNavy
    },
    mainTitle: {
      color: theme.palette.common.white,
      fontSize: "2.6rem",
      lineHeight: "2.8rem",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2)
    },
    classButton: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      borderRadius: "4px",
      borderWidth: "1px",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        transform: "scaleX(1.03)",
        transition: "transform 0.2s ease-in-out"
      }
    },
    classButtonSelected: {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
        transform: "scaleX(1.0)",
        cursor: "default"
      }
    },
    matchupContainer: {
      display: "flex",
      borderRadius: "8px",
      backgroundColor: "#292929",
      boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      "& > h3": {
        marginTop: theme.spacing(2),
        textAlign: "center",
        alignItems: "center",
        fontSize: "1.2rem",
        color: theme.palette.common.white,

        "& > span.info": {},
        "& > span.warning": {
          color: theme.palette.warning.main
        },
        "& > span.danger": {
          color: theme.palette.error.main
        },
        "& > span.success": {
          color: theme.palette.success.main
        }
      }
    },
    matchupInnerContainer: {
      display: "flex",
      borderRadius: "8px",
      flexDirection: "row",
      justifyContent: "space-evenly"
    },
    matchCompetitorContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-between",
      textAlign: "center",
      "& h3": {
        color: theme.palette.common.white,
        fontSize: "1.2rem",
        "@media (min-width: 400px)": {
          fontSize: "1.6rem"
        }
      },
      "& h4": {
        color: theme.palette.primary.main,
        fontSize: "0.8rem",
        marginBottom: theme.spacing(2),
        "@media (min-width: 400px)": {
          fontSize: "1.2rem"
        }
      }
    },
    matchCompetitorRunTimes: {},
    buttonsContainer: {
      display: "flex",
      flexDirection: "column",
      "@media (min-width: 400px)": {
        flexDirection: "row"
      }
    },
    classButtonsContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-evenly",

      "& > button": {
        marginBottom: theme.spacing(2)
      }
    },
    relativeSection: {
      position: "relative"
    },
    viewButton: {
      "@media (min-width: 600px)": {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
        // position: "relative",
        // alignSelf: "flex-end",
      }
    },
    link: {
      textDecoration: "none"
    },
    verticalSeparator: {
      borderLeft: "1px solid",
      // @ts-ignore
      borderColor: "#555555",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  })
);
