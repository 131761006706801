import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

// Styles
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
    textAlign: "center",
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(6)
    },
    "@media (min-width:960px)": {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(6)
    }
  },
  heading: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    marginBottom: "20px",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    }
  },
  subHeading: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2.0rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    }
  },
  bodytext: {
    color: theme.palette.background.ffNavy,
    marginBottom: "10px"
  }
}));

const MidSection = props => {
  const classes = useStyles();
  return (
    <div>
      <Container fixedcomponent="main" maxWidth="sm">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.paper}>
              <Typography
                component="h2"
                variant="h2"
                className={classes.subHeading}
              >
                What is FarmFLiX?
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.bodytext}
              >
                FarmFLiX is an online video platform dedicated to delivering
                relevant agricultural content. We bring the reality of the job
                to you.
              </Typography>
              <br />
              <Typography
                component="h2"
                variant="h2"
                className={classes.subHeading}
              >
                Our Commitment
              </Typography>
              <Typography
                component="p"
                variant="body1"
                className={classes.bodytext}
              >
                We make videos too! FarmFLiX is committed to creating fresh new
                content for the agricultural community. With full membership,
                you will gain a brand new full length episode every week...
                guaranteed!
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default MidSection;
