import React from "react";
import StepContainer from "./StepContainer";

const SignUpStepBlank = () => {
  return (
    <StepContainer>
      <StepContainer.Body />
    </StepContainer>
  );
};

export default SignUpStepBlank;
