import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import DefaultLayout from "../layouts/DefaultLayout";
import FAQs from "./Home/FAQs";

const Help = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Help</title>
        <meta
          name="description"
          content="If you require help/assistance or are a content creator and would like to speak to us, please see below to get in touch."
        />
      </Helmet>
      <FAQs />
    </DefaultLayout>
  );
};

export default Help;
