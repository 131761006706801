import React, { Fragment, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

import Header from "./Header/Header";
import HeaderInternal from "./Header/HeaderInternal";
import Top from "./SeriesPreview/Top";
import PreviewVideo from "./SeriesPreview/PreviewVideo";
import App from "./SeriesPreview/App";
import Testimonials from "./SeriesPreview/Testimonials";
import FAQs from "./SeriesPreview/FAQs";
import Footer from "./Footer/Footer";
import { AccountContext } from "../store/AccountContext";
// import { OverviewContext } from "../store/OverviewContext";
import { Redirect } from "react-router";
import TestimonialItems from "./SeriesPreview/ClydesTestimonials.json";
// import DoorsClosingCountdown from "./Banners/DoorsClosingCountdown";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy
  }
}));

const ClydesFarm = () => {
  const classes = useStyles();
  const { loggedIn } = useContext(AccountContext);
  // const { overview } = useContext(OverviewContext);

  return (
    <div className={classes.container}>
      {loggedIn ? <Redirect to="/videos" /> : null}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Watch Clydes Farm only on FarmFLiX | FarmFLiX.tv</title>
        <meta
          name="description"
          content="Watch the entire series of Clydes Farm only on FarmFLiX."
        />
      </Helmet>
      {loggedIn && <HeaderInternal />}
      {!loggedIn && <Header />}
      {/* {overview && overview.doors?.open && <DoorsClosingCountdown />} */}
      <Top
        title="Clydes Farm"
        backgroundImg="https://community-assets-test.farmflix.tv/21/10/28/704a1957-fde4-4d42-96a9-0108fed512a4/full"
        channelImg="https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/Merch/aglife_thumb.png"
        year={2020}
        seasonCount={1}
        episodeCount={9}
        backgroundPositionSm="70%"
        backgroundPositionXl="50%"
      />
      <PreviewVideo
        titleSmall={
          <Fragment>
            A <span>quick glimpse</span> into
          </Fragment>
        }
        titleLarge={"Clydes Farm"}
        description="For Clydes 2020 begins with a major fencing campaign during the fantastic Spring weather..."
        videoId="clydes-farm-series-compilation"
      />

      <App title="Clydes Farm" />

      <Testimonials data={TestimonialItems} />
      <FAQs />
      <Footer />
    </div>
  );
};

export default ClydesFarm;
