import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(6, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0)
    }
  },

  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },

  accordionContainer: {
    marginTop: theme.spacing(3)
  },
  accordionEntry: {
    //@ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    borderRadius: "0 !important",
    padding: theme.spacing(0.5, 0),
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      padding: theme.spacing(1, 0)
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: theme.spacing(0, 1.5)
    }
  },
  accordionEntryTitle: {
    fontSize: "1.1rem",
    lineHeight: "1.2rem",
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem"
    }
  },
  accordionEntryText: {
    fontSize: "1rem",
    lineHeight: "1.2rem",
    "& a": {
      color: "#eee"
    }
  },
  accordionEntryExpand: {
    // @ts-ignore
    fill: theme.palette.background.ffGreen,
    fontSize: "2.2rem",
    padding: 0
  }
}));

const FAQs = () => {
  const classes = useStyles();

  const faqs = [
    {
      title: "How do I take a Free Trial?",
      body:
        "Simple! click the 'Start Trial Now' button, or alternatively, click 'Start Trial On App' to get you up and running on the App."
    },
    {
      title: "How long does the Free Trial last?",
      body:
        "The Free Trial lasts for 72 Hours from activation.  There are no limits or ads, so fill your boots and watch as much content as you possibly can."
    },
    {
      title: "Why do you need my phone number?",
      body:
        "To activate a Free Trial we require your phone number to verify that it's actually you (a real person).  "
    },
    {
      title: "Do I need to add my Credit Card for a Free Trial?",
      body: "No Credit/Debit card details are needed to activate a Free Trial."
    },
    {
      title: "Are there watch limits in the Free Trial?",
      body:
        "There are no watch limits during your Trial experience. We have changed the dashboard for the Trial to show you our member favourites, and all 300+ episodes are available through search."
    },
    {
      title: "Can I purchase a membership after my Trial?",
      body:
        "Absolutely, you can purchase a Full FarmFLiX Membership anytime during or after your Trial."
    }
  ];

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="md" fixed>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.title}>
                Free Trial
              </Typography>
              <Typography variant="h2" className={classes.titleLarge}>
                FAQ&apos;s
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {faqs.map((q, i) => (
                <Accordion className={classes.accordionEntry} key={i}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        className={classes.accordionEntryExpand}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.accordionEntryTitle}>
                      {q.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.accordionEntryText}>
                      {q.body}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default FAQs;
