import React, { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../store/AccountContext";
import {
  Button,
  Typography,
  Grid,
  Box,
  Checkbox,
  Radio
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import usePromise from "react-use-promise";

// Card Icons
import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import amex from "payment-icons/min/flat/amex.svg";
import discover from "payment-icons/min/flat/discover.svg";
import cvcIcon from "./assets/cvc_icon.png";
import CircularProgress from "@material-ui/core/CircularProgress";

import Alert from "@material-ui/lab/Alert";
import useLogger from "../../hooks/useLogger";

const useStyles = makeStyles(theme => ({
  paymentContainer: {
    marginTop: theme.spacing(2),
    boxShadow: "none",
    textAlign: "left"
  },
  paymentContainerHeader: {
    color: theme.palette.background.ffNavy,
    fontWeight: "500",
    fontSize: "1.4rem",
    lineHeight: "1.4rem"
  },
  paymentButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "100%",
    marginLeft: 0,
    "@media (min-width:600px)": {
      width: "auto"
    }
  },
  cardElementLabel: {
    color: props => (props.dark ? "#eee" : "#656565"),
    fontSize: "1rem",
    fontWeight: 600,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  },
  paymentInformationTitle: {
    color: props => (props.dark ? "#eee" : theme.palette.background.ffNavy),
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    fontWeight: 500,
    marginBottom: theme.spacing(0.5)
  },
  bodyText: {
    color: props =>
      props.dark ? "#d9d9d9" : theme.palette.background.upgradeAlt,
    marginBottom: theme.spacing(1.5),
    fontSize: "0.9rem"
  },
  cardLogo: {
    height: "18px",
    marginLeft: "5px"
  },
  cardElementContainer: {
    border: "1px solid #dadada",
    borderRadius: "5px",
    padding: "12px 10px",
    backgroundColor: "##fbfbfb"
  },
  cardInputError: {
    marginBottom: theme.spacing(2)
  },
  termsLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.background.ffNavyLighter,
    fontSize: "0.8rem",
    lineHeight: "1rem",
    textAlign: "left",
    "@media (min-width:600px)": {
      fontSize: "0.8rem",
      lineHeight: "1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "0.8rem",
      lineHeight: "1rem"
    },
    "& a": {
      color: theme.palette.background.ffNavyLighter,
      fontWeight: 500
    }
  },
  responseText: {
    color: "black"
  },
  validationError: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  cardPaymentContainer: {
    marginTop: theme.spacing(2)
  },
  inputField: {
    padding: "12px",
    backgroundColor: "#f7f7f7",
    margin: "4px 0 15px 0",
    fontSize: "16px",
    // borderRadius: "5px"
    border: "1px solid #c3c3c3"
  },
  midPaymentCreds: {
    marginTop: "4px",
    "@media (min-width:600px)": {
      marginTop: 0
    }
  },
  bottomPaymentCreds: {
    marginBottom: 0
  },
  paymentInformation: {
    marginBottom: 0
  },
  confirmContainer: {
    backgroundColor: "#ddd",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    "@media (min-width:600px)": {
      padding: theme.spacing(1.5)
    }
  },
  defaultPaymentMethodContainer: {
    backgroundColor: props => (props.dark ? "#d9d9d9" : "#f5f5f5"),
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: props => (props.dark ? "#d9d9d9" : "#fff")
    }
  },
  defaultPaymentCardNumber: {
    textAlign: "left",
    color: theme.palette.background.ffNavy,
    fontWeight: 500,
    fontSize: "1.1rem"
  },
  defaultPaymentMethodText: {
    textAlign: "left",
    color: theme.palette.background.ffNavy
  },
  defaultPaymentMethodExpDate: {
    color: theme.palette.background.ffNavy,
    fontWeight: 400,
    fontSize: "0.8rem"
  },
  defaultPaymentMethodCardBrand: {
    width: "50px",
    maxWidth: "50px",
    paddingRight: "9px",
    paddingTop: "6px"
  },
  defaultPaymentMethodChange: {
    color: theme.palette.background.ffGreen,
    fontWeight: 500,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  defaultPaymentMethodLabel: {
    color: theme.palette.background.ffNavy,
    textAlign: "left",
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    marginTop: "20px",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  },
  stripeLogo: {
    width: "120px",
    maxWidth: "120px",
    opacity: 0.3,
    marginTop: theme.spacing(2)
  }
}));

const CardPaymentForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    createStandardSubscriptionStripe,
    paymentErrorResponse,
    paymentError,
    getMembershipDetails
  } = useContext(AccountContext);
  const { logMetricEvent } = useLogger();
  const classes = useStyles(props);

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        "::placeholder": {
          color: "#ccc"
        }
      },
      invalid: {
        color: "#b92020"
      }
    }
  };

  // Renewal Date config
  let renewalDate = new Date();

  if (props.Subscription.entitlementPeriodUnit === "Year") {
    renewalDate = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit"
    }).format(
      new Date(
        renewalDate.setFullYear(
          renewalDate.getFullYear() + props.Subscription.entitlementPeriodValue
        )
      )
    );
  } else if (props.Subscription.entitlementPeriodUnit === "Month") {
    renewalDate = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit"
    }).format(
      new Date(
        renewalDate.setMonth(
          renewalDate.getMonth() + props.Subscription.entitlementPeriodValue
        )
      )
    );
  } else if (props.Subscription.entitlementPeriodUnit === "Day") {
    renewalDate = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit"
    }).format(
      new Date(
        renewalDate.setMonth(
          renewalDate.getMonth() + props.Subscription.entitlementPeriodValue
        )
      )
    );
  }

  const [cardInputError, setCardInputError] = useState(null);
  const [submittingButton, setSubmittingButton] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(Date.now());
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [membershipDetails, , state] = usePromise(getMembershipDetails, [
    reloadTrigger
  ]);

  const loading = state === "pending";

  // eslint-disable-next-line
  const [paymentStatus, setPaymentStatus] = useState();

  // eslint-disable-next-line
  const reload = () => setReloadTrigger(Date.now());

  const clearErrors = event => {
    setTermsChecked(false);
    setCardInputError("");
  };

  // Set selectedPaymentMethod as 'default' if a storedCard exists
  useEffect(() => {
    setSelectedPaymentMethod(
      membershipDetails && membershipDetails.defaultPaymentMethod
        ? "default"
        : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipDetails]);

  function CurrencySymbol({ currency }) {
    switch (currency) {
      case "GBP":
        return "\u00A3";
      case "EUR":
        return "\u20AC";
      default:
        return "\u00A3";
    }
  }

  const onPaymentSuccess = payment => {
    logMetricEvent({
      type: "purchase",
      payload: {
        userId: payment.memberId,
        paymentId: payment.id,
        id: payment.productId || "",
        memberStatusId: payment.memberStatusId || "",
        name: payment.productId || "",
        cost: parseFloat(payment.cost || "0"),
        currency: payment.currency || "",
        stripeReference: payment.paymentProviderReference || ""
      }
    });
  };

  const { handlePaymentResponse } = props;

  // Stripe (Card) Submission
  const handleStripeSubmit = async event => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Block native form submission.
    event.preventDefault();

    //Disable Submit button - block double/multiple clicks/taps
    setSubmittingButton(true);

    try {
      const cardElement = elements.getElement(CardNumberElement);

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
      });

      if (payload.error) {
        setCardInputError(payload.error.message);
        // setSubmittingButton(false);
        return;
      } else {
        // Set Pending State
        handlePaymentResponse({
          status: "pending",
          title: "We're hitching your trailer on now...",
          subTitle: "Payment processing...",
          description: "Please do not reload or refresh your browser."
        });

        setCardInputError(null);
        const productId = props.Subscription.id;
        const stripePaymentToken = payload.paymentMethod.id;

        //eslint-disable-next-line
         const paymentAttempt = await createStandardSubscriptionStripe({
          productId,
          stripePaymentToken
        });

        if (paymentAttempt.status === "payment_error") {
          handlePaymentResponse({
            status: "failure",
            title: "Payment Failed",
            subTitle: paymentAttempt.message,
            description:
              "We were unable to charge your card for your FarmFLiX Membership. Please try again.",
            tryAgain: true
          });
        } else {
          statusPoller();
        }
      }
    } catch (e) {
      console.log("Error", e);
      handlePaymentResponse({
        status: "failure",
        title: "Payment Failed",
        subTitle: "Error" + e,
        description:
          "We were unable to charge your card for your FarmFLiX Membership. Please try again.",
        tryAgain: true
      });
    }
  };

  const statusPoller = async () => {
    // Documentation
    // https://stripe.com/docs/billing/subscriptions/fixed-price#manage-payment-authentication

    // Get the Latest Payment
    const latestPayment = async () => {
      const memberDetails = await getMembershipDetails();
      const paymentDetails = memberDetails.payments[0];
      return paymentDetails;
    };

    // Set a limit on the number of number of attempts to poll for success/failure response
    let pollAttempts = 0;

    // Poll The DB for the Stripe Webhook Callback
    const StripeCallback = async () => {
      const LP = await latestPayment(); //call the loop contents

      // If latest payment entry is pending, call it again in 2 seconds
      if (LP.status === "pending") {
        // Increment the Polling Attempts
        pollAttempts++;
        console.log("Payment pending...");
        if (pollAttempts === 5) {
          // Show timeout message
          handlePaymentResponse({
            status: "timeout",
            title: "Unable to Process Payment",
            subTitle:
              "Unfortunately we cannot process your payment at this time.",
            tryAgain: true
          });
          return;
        }
        setTimeout(StripeCallback, 2000);
      }
      // If the latest payment entry is failure
      else if (LP.status === "failure") {
        const latestInvoice = JSON.parse(LP.paymentProviderRawPayload);

        // Reason is 'Action Required', then the payment requires action
        if (latestInvoice.payment_intent.status === "requires_action") {
          console.log("Action Required...");
          handlePaymentResponse({
            status: "pending",
            title: "We're hitching your trailer on now...",
            subTitle: "Authentication required...",
            description: "Please do not reload or refresh your browser."
          });

          const clientSecret = latestInvoice.payment_intent.client_secret;
          const paymentMethod = latestInvoice.payment_intent.payment_method;

          // Pass client_ secret and payment_intent to confirmCardPayment (SCA Modal)
          return stripe
            .confirmCardPayment(clientSecret, {
              payment_method: paymentMethod
            })
            .then(result => {
              if (result.error) {
                console.log("Payment Auth failure...", result);
                handlePaymentResponse({
                  status: "failure",
                  title: "Authentication Failed",
                  subTitle:
                    "Your authentication has failed for your payment. Please try again or use a different card.",
                  tryAgain: true
                });
                return;
              } else {
                if (result.paymentIntent.status === "succeeded") {
                  console.log("Payment Auth success...");
                  onPaymentSuccess(LP);
                  handlePaymentResponse({
                    status: "success",
                    title: "Woo hoo!",
                    subTitle: "Payment Successfully Processed!",
                    description:
                      "Thank you for your payment. Your FarmFLiX Account is now set up."
                  });
                  return;
                }
              }
            })
            .catch(error => {
              handlePaymentResponse({
                status: "failure",
                title: "Payment Failed",
                subTitle:
                  "We were unable to charge your card for your FarmFLiX Membership.  Please try again or use a different card.",
                tryAgain: true
              });
              return;
            });
        }
        handlePaymentResponse({
          status: "failure",
          title: "Payment Failed",
          subTitle:
            "We were unable to charge your card for your FarmFLiX Membership.  Please try again or use a different card.",
          tryAgain: true
        });
        return;
      }
      // If the latest payment entry is success, show success message
      else if (LP.status === "success") {
        console.log("Payment Success - No Auth Required.");
        onPaymentSuccess(LP);
        handlePaymentResponse({
          status: "success",
          title: "Woo hoo!",
          subTitle: "Payment Successfully Processed!",
          description:
            "Thank you for your payment. Your FarmFLiX Account is now set up."
        });
        return;
      }
    };

    await StripeCallback();
  };

  // Stripe Membership Submission
  const handleNewMembership = async () => {
    handlePaymentResponse({
      status: "pending",
      title: "We're hitching your trailer on now...",
      subTitle: "Payment processing...",
      description: "Please do not reload or refresh your browser."
    });

    const productId = props.Subscription.id;

    // Passing "default" in to use the current payment method on the Stripe Account
    const stripePaymentToken = membershipDetails.defaultPaymentMethod?.id;

    const paymentAttempt = await createStandardSubscriptionStripe({
      productId,
      stripePaymentToken
    });

    if (paymentAttempt) {
      setTimeout(statusPoller, 3000);
    }
  };

  const handleTermsChecked = event => {
    setTermsChecked(event.target.checked);
    if (termsChecked) {
      setSubmittingButton(false);
    } else {
      setSubmittingButton(true);
    }
    // setSubmittingButton(true);
  };
  const handlePaymentMethodSelection = event => {
    // resetBuyNowErrors();
    setTermsChecked(false);
    setSelectedPaymentMethod(event.target.value);
    setSubmittingButton(false);
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} className={classes.paymentContainer}>
        <div className={classes.cardContainer}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.paymentInformation}
            >
              <Typography
                className={classes.paymentInformationTitle}
                variant="body1"
              >
                Payment
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {!loading && (
                <>
                  {paymentError && (
                    <>
                      {paymentErrorResponse !== "undefined" && (
                        <Alert
                          className={classes.validationError}
                          severity="error"
                          variant="filled"
                        >
                          {paymentErrorResponse}
                        </Alert>
                      )}
                    </>
                  )}
                  {/* If user was a previous member */}
                  {membershipDetails && membershipDetails.defaultPaymentMethod && (
                    <>
                      {/* If user has Stripe account and a saved card  */}
                      {membershipDetails.defaultPaymentMethod.cardBrand &&
                        membershipDetails.defaultPaymentMethod.id && (
                          <>
                            <Typography
                              variant="body1"
                              className={classes.bodyText}
                            >
                              Pay securely with your previous card or use a new
                              card.
                            </Typography>

                            <Box
                              display="flex"
                              alignItems="center"
                              className={classes.defaultPaymentMethodContainer}
                            >
                              <Box>
                                <Radio
                                  color="primary"
                                  checked={
                                    membershipDetails.defaultPaymentMethod
                                      .cardBrand &&
                                    membershipDetails.defaultPaymentMethod.id &&
                                    selectedPaymentMethod === "default"
                                  }
                                  onChange={handlePaymentMethodSelection}
                                  value="default"
                                  name="radio-button-demo"
                                  inputProps={{ "aria-label": "Default" }}
                                />
                              </Box>
                              <Box flex="1" alignItems="center">
                                <Typography
                                  variant="body1"
                                  className={classes.defaultPaymentCardNumber}
                                >
                                  **** **** ****{" "}
                                  {membershipDetails.defaultPaymentMethod.last4}
                                  <br />
                                  <span
                                    className={
                                      classes.defaultPaymentMethodExpDate
                                    }
                                  >
                                    Exp:{" "}
                                    {
                                      membershipDetails.defaultPaymentMethod.exp
                                        .month
                                    }{" "}
                                    /{" "}
                                    {
                                      membershipDetails.defaultPaymentMethod.exp
                                        .year
                                    }
                                  </span>
                                </Typography>
                              </Box>
                              <Box alignItems="center">
                                <Typography
                                  variant="body1"
                                  className={classes.defaultPaymentMethodChange}
                                >
                                  {membershipDetails.defaultPaymentMethod
                                    .cardBrand === "visa" && (
                                    <img
                                      alt="Visa"
                                      src={visa}
                                      className={
                                        classes.defaultPaymentMethodCardBrand
                                      }
                                    />
                                  )}
                                  {membershipDetails.defaultPaymentMethod
                                    .cardBrand === "mastercard" && (
                                    <img
                                      alt="Mastercard"
                                      src={mastercard}
                                      className={
                                        classes.defaultPaymentMethodCardBrand
                                      }
                                    />
                                  )}
                                  {membershipDetails.defaultPaymentMethod
                                    .cardBrand === "amex" && (
                                    <img
                                      alt="American Express"
                                      src={amex}
                                      className={
                                        classes.defaultPaymentMethodCardBrand
                                      }
                                    />
                                  )}
                                  {membershipDetails.defaultPaymentMethod
                                    .cardBrand === "discover" && (
                                    <img
                                      alt="Discover"
                                      src={discover}
                                      className={
                                        classes.defaultPaymentMethodCardBrand
                                      }
                                    />
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              className={classes.defaultPaymentMethodContainer}
                            >
                              <Box alignItems="center">
                                <Radio
                                  color="primary"
                                  checked={selectedPaymentMethod === "new"}
                                  onChange={handlePaymentMethodSelection}
                                  value="new"
                                  name="radio-button-demo"
                                  inputProps={{ "aria-label": "New" }}
                                />
                              </Box>
                              <Box alignItems="center">
                                <Typography
                                  variant="body1"
                                  className={classes.defaultPaymentMethodText}
                                  style={{ padding: "10px 0" }}
                                >
                                  Use a new card
                                </Typography>
                              </Box>
                            </Box>

                            {selectedPaymentMethod === "default" && (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  className={classes.confirmContainer}
                                >
                                  <Box
                                    display="flex"
                                    className={classes.termsContainer}
                                  >
                                    <Box>
                                      <Checkbox
                                        color="primary"
                                        style={{ color: "#99ca3c" }}
                                        onChange={handleTermsChecked}
                                        inputProps={{
                                          name: "terms",
                                          "aria-label": "primary checkbox"
                                        }}
                                      />
                                    </Box>
                                    <Box>
                                      <Typography
                                        variant="body2"
                                        className={classes.termsLabel}
                                      >
                                        I agree to FarmFLiX&apos;s{" "}
                                        <Link to="/terms">
                                          Terms and Conditions
                                        </Link>{" "}
                                        and{" "}
                                        <Link to="/privacy-policy">
                                          Privacy Policy
                                        </Link>
                                        .
                                        {props.Subscription.isSubscription ? (
                                          <>
                                            Your next membership payment will be
                                            taken on <b>{renewalDate}</b>.
                                            FarmFLiX will automatically track
                                            and charge your membership fee{" "}
                                            <b>
                                              (
                                              <CurrencySymbol
                                                currency={
                                                  props.Subscription.currency
                                                }
                                              />
                                              {props.Subscription.cost}/
                                              {
                                                props.Subscription
                                                  .entitlementPeriodUnit
                                              }
                                              )
                                            </b>{" "}
                                            until you cancel your membership.
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            This is a one time payment for{" "}
                                            <b>
                                              {
                                                props.Subscription
                                                  .entitlementPeriodValue
                                              }{" "}
                                              {
                                                props.Subscription
                                                  .entitlementPeriodUnit
                                              }
                                              &apos;s
                                            </b>{" "}
                                            access to FarmFLiX. This Membership
                                            will automatically expire in{" "}
                                            {
                                              props.Subscription
                                                .entitlementPeriodValue
                                            }{" "}
                                            {
                                              props.Subscription
                                                .entitlementPeriodUnit
                                            }{" "}
                                            and no further charges will be
                                            applied to your account.
                                          </>
                                        )}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    align="center"
                                  >
                                    <Button
                                      name="submit"
                                      size="large"
                                      onClick={handleNewMembership}
                                      className={classes.paymentButton}
                                      disabled={
                                        !stripe ||
                                        !props.Subscription.id ||
                                        !submittingButton
                                      }
                                    >
                                      Start Membership
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                    </>
                  )}
                  {/* Payment Form */}
                  {selectedPaymentMethod !== "default" && (
                    <form onSubmit={handleStripeSubmit}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="body1"
                          className={classes.bodyText}
                        >
                          Please enter your card details below.
                        </Typography>
                        {cardInputError && (
                          <>
                            {cardInputError !== "undefined" && (
                              <Alert
                                className={classes.validationError}
                                severity="error"
                                variant="filled"
                              >
                                {cardInputError}
                              </Alert>
                            )}
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <label
                              className={classes.cardElementLabel}
                              htmlFor="cardNumber"
                            >
                              Card Number
                            </label>
                          </Box>
                          <Box>
                            <img
                              className={classes.cardLogo}
                              src={visa}
                              alt="Visa"
                            />
                            <img
                              className={classes.cardLogo}
                              src={mastercard}
                              alt="Mastercard"
                            />
                            <img
                              className={classes.cardLogo}
                              src={amex}
                              alt="American Express"
                            />
                            <img
                              className={classes.cardLogo}
                              src={discover}
                              alt="Discover"
                            />
                          </Box>
                        </Box>
                        <CardNumberElement
                          id="cardNumber"
                          className={classes.inputField}
                          onFocus={clearErrors}
                          options={ELEMENT_OPTIONS}
                        />
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={classes.midPaymentCreds}
                        >
                          <label
                            className={classes.cardElementLabel}
                            htmlFor="expiry"
                          >
                            Card Expiration
                          </label>
                          <CardExpiryElement
                            id="expiry"
                            className={`${classes.inputField} ${classes.bottomPaymentCreds}`}
                            onFocus={clearErrors}
                            options={ELEMENT_OPTIONS}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box display="flex">
                            <Box flexGrow={1}>
                              <label
                                className={classes.cardElementLabel}
                                htmlFor="cvc"
                              >
                                CVC
                              </label>
                            </Box>
                            <Box>
                              <img
                                className={classes.cardLogo}
                                src={cvcIcon}
                                alt="CVC"
                              />
                            </Box>
                          </Box>
                          <CardCvcElement
                            id="cvc"
                            className={classes.inputField}
                            onFocus={clearErrors}
                            options={ELEMENT_OPTIONS}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.confirmContainer}
                      >
                        <Box display="flex" className={classes.termsContainer}>
                          <Box>
                            <Checkbox
                              color="primary"
                              style={{ color: "#99ca3c" }}
                              onChange={handleTermsChecked}
                              inputProps={{
                                name: "terms",
                                "aria-label": "primary checkbox"
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              className={classes.termsLabel}
                            >
                              I agree to FarmFLiX&apos;s{" "}
                              <Link to="/terms">Terms and Conditions</Link> and{" "}
                              <Link to="/privacy-policy">Privacy Policy</Link>.
                              {props.Subscription.isSubscription ? (
                                <>
                                  Your next membership payment will be taken on{" "}
                                  <b>{renewalDate}</b>. FarmFLiX will
                                  automatically track and charge your membership
                                  fee{" "}
                                  <b>
                                    (
                                    <CurrencySymbol
                                      currency={props.Subscription.currency}
                                    />
                                    {props.Subscription.cost}/
                                    {props.Subscription.entitlementPeriodUnit})
                                  </b>{" "}
                                  until you cancel your membership.
                                </>
                              ) : (
                                <>
                                  {" "}
                                  This is a one time payment for{" "}
                                  <b>
                                    {props.Subscription.entitlementPeriodValue}{" "}
                                    {props.Subscription.entitlementPeriodUnit}
                                    &apos;s
                                  </b>{" "}
                                  access to FarmFLiX. This Membership will
                                  automatically expire in{" "}
                                  {
                                    props.Subscription.entitlementPeriodValue
                                  }{" "}
                                  {props.Subscription.entitlementPeriodUnit} and
                                  no further charges will be applied to your
                                  account.
                                </>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                        <Grid item xs={12} sm={12} md={12} align="center">
                          <Button
                            name="submit"
                            type="submit"
                            size="large"
                            className={classes.paymentButton}
                            disabled={
                              !stripe ||
                              !props.Subscription.id ||
                              !submittingButton
                            }
                          >
                            Start Membership
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </>
              )}
              {loading && (
                <>
                  <Box m={5} align="center">
                    <CircularProgress variant="indeterminate"></CircularProgress>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default React.memo(CardPaymentForm);
