import React from "react";
import { Helmet } from "react-helmet";
import DefaultLayout from "../../layouts/DefaultLayout";
import SignUpForm from "./SignUp/SignUpForm";
import { useLocation, useParams } from "react-router-dom";
import { APPLICATION_SOURCE } from "@booyaltd/core";

export default function Giftcard() {
  const location = useLocation();
  const { code } = useParams<{ code?: string }>();
  return (
    <DefaultLayout showTry={false} showSignIn={false}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Redeem FarmFLiX Giftcard Now</title>
        <meta
          name="description"
          content="Got a FarmFLiX Giftcard? New Member? Redeem it here to get started.  Existing Member? Redeem to top up your membership now."
        />
      </Helmet>
      <SignUpForm
        route={undefined}
        source={APPLICATION_SOURCE.GIFTCARD}
        code={code}
        page={location.pathname}
        title="Redeem a Giftcard"
      />
    </DefaultLayout>
  );
}
