import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import appleappstore from "./../Modals/app-store-logo.png";
import googleplaystore from "./../Modals/google-play-logo.png";
import Countdown from "react-countdown";
import {
  isMobile,
  isTablet,
  isBrowser,
  isIOS,
  isAndroid
} from "react-device-detect";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(4, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(6, 0)
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(8, 0)
    }
  },
  appContainer: {
    paddingTop: theme.spacing(6)
  },
  playerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  countdownTitle: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
    lineHeight: "1.3rem",
    fontSize: "1.3rem",
    padding: "0 30px",
    color: theme.palette.common.upgrade,
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    }
  },
  appTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    lineHeight: "2.2rem",
    fontSize: "2.2rem",
    padding: "0 30px",
    color: theme.palette.common.white,
    "@media (min-width:600px)": {
      fontSize: "2.8rem",
      lineHeight: "2.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3.6rem",
      lineHeight: "3.8rem"
    }
  },
  appSubTitle: {
    textAlign: "center",
    fontSize: "1.2rem",
    marginBottom: theme.spacing(2),
    color: theme.palette.background.ffGreen,
    "@media (min-width:600px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  whatIsFarmflix: {
    color: theme.palette.common.white,
    alignSelf: "center",
    justifySelf: "center"
  },
  appDesc: {
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "1.2rem",
    padding: theme.spacing(0, 4),
    fontWeight: 500,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontSize: "1.2rem",
      lineHeight: "1.4rem"
    },
    "& span": {
      color: theme.palette.background.ffGreen
    }
  },
  appLink: {
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "0.8rem",
    lineHeight: "1rem",
    padding: theme.spacing(0, 4),
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.1rem",
      marginTop: theme.spacing(1)
    },
    "& span": {
      color: theme.palette.background.ffGreen
    }
  },
  appSmlPrint: {
    fontSize: "0.7rem",
    color: theme.palette.background.ffNavy,
    textAlign: "center",
    "@media (min-width:600px)": {
      textAlign: "center"
    },
    "@media (min-width:960px)": {
      textAlign: "left"
    }
  },
  appStoreLogo: {
    maxWidth: "140px",
    margin: "5px",
    "@media (min-width:600px)": {
      textAlign: "center",
      maxWidth: "140px",
      margin: "10px 5px"
    },
    "@media (min-width:960px)": {
      textAlign: "left",
      maxWidth: "160px",
      margin: "10px 5px"
    }
  },
  appGraphic: {
    width: "90%",
    margin: "0 5%",
    textAlign: "center",
    "@media (min-width:600px)": {
      width: "90%",
      margin: "20px 5%"
    }
  },
  appDownloadLinks: {
    // backgroundColor: theme.palette.background.ffGreen,
    textAlign: "center",
    // margin: "15px 0 5px 0",
    "@media (min-width:600px)": {
      textAlign: "center"
    },
    "@media (min-width:960px)": {
      textAlign: "center"
    }
  },
  smartDeviceContainer: {
    textAlign: "center"
  },
  smartDeviceText: {
    fontSize: "0.8rem",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& a": {
      color: theme.palette.background.ffGreen
    }
  },
  chromecastLogo: {
    width: "40%",
    textAlign: "center",
    "@media (min-width:600px)": {
      maxWidth: "150px"
      // margin: "20px 5%"
    }
  },
  lightBackground: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(4, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(6, 0)
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(8, 0)
    }
  },
  countdown: {
    color: theme.palette.background.ffNavy,
    fontSize: "3rem",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "3.2rem",
      lineHeight: "3.4rem",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    },
    "@media (min-width:960px)": {
      fontSize: "4rem",
      lineHeight: "4.2rem",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  countdownContainer: {
    padding: theme.spacing(1, 4),
    "@media (min-width:600px)": {
      padding: theme.spacing(2, 4)
    }
  },
  countdownWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  countdownLabelWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  countdownUnitWrapper: {
    textAlign: "center",
    backgroundColor: "#2f2f2f",
    padding: "4% 0",
    fontSize: "2rem",
    fontWeight: "600",
    color: "#eeeeee",
    maxWidth: "21.8%",
    margin: "0 1.6%",
    borderRadius: "10px",
    "@media (min-width:600px)": {
      padding: "3% 0",
      fontSize: "3rem",
      fontWeight: "600"
    },
    "@media (min-width:960px)": {
      padding: "3% 0",
      fontSize: "4rem",
      fontWeight: "600"
    }
  },
  countdownUnitFirstWrapper: {
    margin: 0
  },
  countdownUnitLabel: {
    fontSize: "0.9rem",
    fontWeight: 600,
    textAlign: "center",
    color: "#eee",
    maxWidth: "21.8%",
    margin: "0 1.6%",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem"
    }
  },
  installAppButton: {
    width: "90%",
    margin: "32px 5% 16px 5%",
    fontSize: "1.3rem",
    padding: theme.spacing(2),
    "@media (min-width:600px)": {
      width: "auto",
      padding: theme.spacing(2, 6)
    }
  },
  secondaryTrialButton: {
    borderWidth: "1px",
    borderColor: "white",
    color: "white",
    width: "90%",
    fontSize: "0.8rem",
    padding: theme.spacing(1),
    "@media (min-width:600px)": {
      width: "auto",
      padding: theme.spacing(1, 3)
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "#eee",
      transform: "none",
      border: "1px solid #eee"
    }
  }
}));

export default function FarmFLiXApp() {
  const classes = useStyles();

  function AppLinks() {
    const classes = useStyles();

    if (isMobile || isTablet) {
      return (
        <>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              marginBottom: "12px",
              padding: "0px"
            }}
          >
            {isIOS && (
              <>
                <a
                  href="https://apps.apple.com/us/app/farmflix/id1490609587"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button size="large" className={classes.installAppButton}>
                    INSTALL THE APP
                  </Button>
                </a>
              </>
            )}

            {isAndroid && (
              <>
                <a
                  href="https://play.google.com/store/apps/details?id=com.farmflix&hl=en_GB&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button size="large" className={classes.installAppButton}>
                    INSTALL THE APP
                  </Button>
                </a>
              </>
            )}
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.appDownloadLinks}
            >
              <a
                href={"https://apps.apple.com/us/app/farmflix/id1490609587"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appleappstore}
                  className={classes.appStoreLogo}
                  alt="Apple App Store Graphic"
                />
              </a>
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.farmflix&hl=en_GB&gl=US"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googleplaystore}
                  className={classes.appStoreLogo}
                  alt="Google Play Store Graphic"
                />
              </a>
            </Grid>
          </Grid>
        </>
      );
    } else if (isBrowser) {
      return (
        <>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.appDownloadLinks}
            >
              <a
                href={"https://apps.apple.com/us/app/farmflix/id1490609587"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appleappstore}
                  className={classes.appStoreLogo}
                  alt="Apple App Store Graphic"
                />
              </a>
              <a
                href={
                  "https://play.google.com/store/apps/details?id=com.farmflix&hl=en_GB&gl=US"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googleplaystore}
                  className={classes.appStoreLogo}
                  alt="Google Play Store Graphic"
                />
              </a>
            </Grid>
          </Grid>
        </>
      );
    }
  }

  const RenderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Container fixed fixedcomponent="main" maxWidth="md">
          <Grid container>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h2"
                className={classes.appTitle}
              >
                Start Your Free Trial On The FarmFLiX App!
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    } else {
      return (
        <>
          <Container
            fixed
            fixedcomponent="main"
            maxWidth="sm"
            className={classes.countdownContainer}
          >
            <Grid container className={classes.countdownWrapper}>
              <Grid item xs={12}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.countdownTitle}
                >
                  The coundown has started...
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                className={classes.countdownUnitWrapper}
                style={{ color: "#99ca3d" }}
              >
                <span>
                  {days.toString().length === 1 ? "0" : ""}
                  {days}
                </span>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                className={classes.countdownUnitWrapper}
              >
                <span>
                  {hours.toString().length === 1 ? "0" : ""}
                  {hours}
                </span>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                className={classes.countdownUnitWrapper}
              >
                <span>
                  {minutes.toString().length === 1 ? "0" : ""}
                  {minutes}
                </span>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                className={classes.countdownUnitWrapper}
              >
                <span>
                  {seconds.toString().length === 1 ? "0" : ""}
                  {seconds}
                </span>
              </Grid>
            </Grid>
            <Grid container className={classes.countdownLabelWrapper}>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                className={classes.countdownUnitLabel}
                style={{ color: "#99ca3d" }}
              >
                Days
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                className={classes.countdownUnitLabel}
              >
                Hours
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                className={classes.countdownUnitLabel}
              >
                Mins
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                className={classes.countdownUnitLabel}
              >
                Secs
              </Grid>
            </Grid>
          </Container>
          <Container fixed fixedcomponent="main" maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.appTitle}
                >
                  Start Your Free Trial On The App!
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </>
      );
    }
  };

  return (
    <>
      <div className={classes.background}>
        <Countdown
          date={Date.parse("2021-11-26T00:00:00.000Z")}
          renderer={RenderCountdown}
          zeroPadTime={2}
          daysInHours={true}
        />
        <Container fixed fixedcomponent="main" maxWidth="md">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.playerWrapper}
              display="flex"
            >
              <Typography variant="h3" className={classes.appSubTitle}>
                Watch Anytime. Watch Anywhere.
              </Typography>
              <img
                src={
                  "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/App/farmflix_app21.png"
                }
                alt="FarmFLiX App"
                className={classes.appGraphic}
              />
              <AppLinks />
              {/* <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  marginBottom: "12px",
                  padding: "0px"
                }}
              >
                <a
                  href={"https://smart.link/6fa45jd155j1i"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button size="large" className={classes.installAppButton}>
                    INSTALL THE APP
                  </Button>
                </a>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.appDownloadLinks}
                >
                  <a
                    href={"https://smart.link/6fa45jd155j1i"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={appleappstore}
                      className={classes.appStoreLogo}
                      alt="Apple App Store Graphic"
                    />
                  </a>
                  <a
                    href={"https://smart.link/6fa45jd155j1i"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={googleplaystore}
                      className={classes.appStoreLogo}
                      alt="Google Play Store Graphic"
                    />
                  </a>
                </Grid>
              </Grid> */}
              <Grid container className={classes.smartDeviceContainer}>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    className={classes.smartDeviceText}
                  >
                    <b>Prefer the website? No Problem</b>
                  </Typography>
                  <a
                    href={"/trybuy"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      className={classes.secondaryTrialButton}
                    >
                      Click Here
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
