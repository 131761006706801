import React, { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Dialog, makeStyles, createStyles } from "@material-ui/core";
import MarketingPreferencesForm from "../MarketingPreferences/MarketingPreferencesForm";
import { AccountContext } from "../../store/AccountContext";
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      height: "100%",
      width: "100%",
      boxSizing: "content-box"
    }
  })
);

const MarketingPreferencesModal = () => {
  const { userProfile } = useContext(AccountContext);
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push("/myaccount/overview");
  }, [history]);

  return (
    <Dialog open={true} onClose={handleClose}>
      <div className={classes.root}>
        {userProfile ? (
          <MarketingPreferencesForm
            unsubscribeToken={userProfile.unsubscribeToken}
            embedded={true}
            onClose={handleClose}
          />
        ) : null}
      </div>
    </Dialog>
  );
};

export default MarketingPreferencesModal;
