import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import PlaylistRowCarousel from "./PlaylistRowCarousel";
import PlaylistRowScroll from "./PlaylistRowScroll";
import {
  ChannelCatalogResponse,
  PlaylistCatalogResponse
} from "@booyaltd/core";

export type PlaylistRowProps = {
  playlist: PlaylistCatalogResponse;
  channels: ChannelCatalogResponse[];
  memberStatusId?: string;
};

type PlaylistsSectionProps = {
  playlists: PlaylistCatalogResponse[];
  channels: ChannelCatalogResponse[];
  memberStatusId?: string;
};

const PlaylistsSection = ({
  playlists,
  channels,
  memberStatusId
}: PlaylistsSectionProps) => {
  const useStyles = makeStyles(theme => ({
    background: {
      flexGrow: 1,
      // @ts-ignore
      backgroundColor: theme.palette.background.dark,
      padding: "10px 0 15px 0",
      "@media (min-width:600px)": {
        padding: "10px 0 20px 0"
      },
      "@media (min-width:960px)": {
        padding: "10px 0 30px 0"
      }
    },
    viewAllBut: {
      // @ts-ignore
      color: theme.palette.background.ffGreen,
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: "0.9rem",
      paddingRight: theme.spacing(1),
      lineHeight: "1.6rem"
    },
    playlistTitle: {
      marginBottom: "10px"
    }
  }));

  const classes = useStyles();

  return (
    <>
      {playlists.map((pl, ind) => (
        <div className={classes.background} key={ind + pl.id}>
          <Container>
            <Grid container>
              <Grid item xs={6} sm={6}>
                <Typography variant="h4" className={classes.playlistTitle}>
                  {pl.name}
                </Typography>
              </Grid>
              {pl.total >= 10 && (
                <Grid item xs={6} sm={6} style={{ textAlign: "right" }}>
                  <Link
                    to={"/playlist/" + pl.tag.toLowerCase()}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography className={classes.viewAllBut}>
                      View All
                    </Typography>
                  </Link>
                </Grid>
              )}
            </Grid>

            {/* Visible XS, SM Only - Scroll */}
            <Hidden only={["md", "lg", "xl"]}>
              <PlaylistRowScroll
                playlist={pl}
                channels={channels}
                memberStatusId={memberStatusId}
              />
            </Hidden>

            {/* Visible MD & Up Only - Carousel */}
            <Hidden only={["xs", "sm"]}>
              <PlaylistRowCarousel
                playlist={pl}
                channels={channels}
                memberStatusId={memberStatusId}
              />
            </Hidden>
          </Container>
        </div>
      ))}
    </>
  );
};

export default PlaylistsSection;
