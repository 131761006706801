import React from "react";
import {
  ChannelCatalogResponse,
  PlaylistCatalogResponse,
  PlaylistDisplayType
} from "@booyaltd/core";
import HorizontalPoster from "./HorizontalPoster";
import VerticalPoster from "./VerticalPoster";
import PlaylistsSection from "./PlaylistsSection";
import { buildDashboardSectionsFromPlaylists } from "../Dashboard";
import SingleVideoPlaylistSection from "./SingleVideoPlaylistSection";

type PlaylistSectionsProps = {
  playlists: PlaylistCatalogResponse[];
  channels: ChannelCatalogResponse[];
  memberStatusId?: string;
};

const PlaylistSections = ({
  playlists,
  channels,
  memberStatusId
}: PlaylistSectionsProps) => {
  return (
    <>
      {buildDashboardSectionsFromPlaylists(playlists).map((section, index) => {
        switch (section.displayType.toString()) {
          case PlaylistDisplayType.HorizontalPoster.toString():
            return (
              <HorizontalPoster
                key={"hp_" + index}
                playlists={section.playlists}
              />
            );
          case PlaylistDisplayType.VerticalPoster.toString():
            return (
              <VerticalPoster
                key={"vp_" + index}
                playlists={section.playlists}
              />
            );

          case PlaylistDisplayType.SingleVideoPoster.toString():
            return (
              <SingleVideoPlaylistSection
                playlists={section.playlists}
                key={"svp_" + index}
              />
            );

          default:
            return (
              <PlaylistsSection
                key={"playlist" + index}
                playlists={section.playlists}
                channels={channels}
                memberStatusId={memberStatusId}
              />
            );
        }
      })}
    </>
  );
};

export default PlaylistSections;
