import React from "react";
import { makeStyles } from "@material-ui/core";

type VideoProgressProps = {
  localProgress?: number;
  progress?: number;
  durationMs: number;
};

const useStyles = makeStyles(theme => ({
  episodeProgressContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "transparent",
    display: "flex"
  },
  episodeProgressBar: {
    height: "6px",
    alignSelf: "flex-start",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffGreen
  }
}));

const VideoProgress = ({
  localProgress,
  progress,
  durationMs
}: VideoProgressProps) => {
  const classes = useStyles();
  const progressPct = Math.floor(
    ((localProgress || progress || 0) / (durationMs / 1000)) * 100
  );

  return progressPct > 0 && progressPct < 95 ? (
    <div className={classes.episodeProgressContainer}>
      <div
        className={classes.episodeProgressBar}
        style={{ width: `${progressPct}%` }}
      />
    </div>
  ) : null;
};

export default VideoProgress;
