import React from "react";
import CountdownBase from "react-countdown";
import dayjs from "dayjs";

export type CountdownProps = { date: Date };

export const Countdown = ({ date }: CountdownProps) => {
  if (!date) {
    return null;
  }

  const daysjsDate = dayjs(date);
  const isDateMoreThanOneDayAway = daysjsDate.diff(dayjs(), "day") > 1;
  return (
    <CountdownBase
      date={daysjsDate.toDate()}
      zeroPadTime={2}
      daysInHours={!isDateMoreThanOneDayAway}
    />
  );
};
