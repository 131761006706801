import React, { useState, useContext, useCallback } from "react";
import { AccountContext } from "./../../store/AccountContext";
import { Grid, Typography, Button, Box, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Form } from "react-final-form";
import { useToasts } from "react-toast-notifications";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import ReactCodeInput from "react-verification-code-input";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  bodyText: {
    color: theme.palette.background.ffNavy,
    "& span": {
      fontWeight: 500,
      fontSize: "1.3rem"
    }
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    "& span": {
      fontWeight: 500
    }
  },
  formContainer: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  inputField: {
    "& .react-tel-input": {
      marginTop: 0,
      marginBottom: 0
    },
    "& .react-tel-input .flag-dropdown:before": {
      display: "none !important"
    },
    "& .react-tel-input .form-control": {
      border: "1px solid #a5a5a5 !important",
      backgroundColor: "#f7f7f7 !important",
      fontSize: "16px !important",
      borderRadius: "5px !important"
    }
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "5% 0 2% 0"
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    color: theme.palette.background.ffNavy,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  },
  submit: {
    marginTop: theme.spacing(1)
  },
  verificationCodeContainer: {
    width: "100% !important",
    fontWeight: "600",
    marginBottom: theme.spacing(2),
    "& input": {
      height: "56px !important",
      width: "15.6% !important",
      border: "1px solid #a5a5a5",
      borderRight: "none",
      fontSize: "2rem",
      fontWeight: 500,
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
      "&:focus": {
        border: "1px solid #aaa",
        caretColor: "#aaa"
      },
      "&:last-child": {
        borderRight: "1px solid #a5a5a5"
      }
    }
  },
  validationError: {
    marginBottom: theme.spacing(2)
  }
}));

export default function ChangePhone() {
  const classes = useStyles();
  const { addToast } = useToasts();

  // Account Context
  const {
    userProfile,
    update,
    updating,
    updateError,
    getVerificationCode,
    waitingOnVerificationCode,
    validateLogin,
    resetLoginWithPhone
  } = useContext(AccountContext);

  // State
  const [verificationCode, setVerificationCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(userProfile.phoneNumber);
  const [countryCode, setCountryCode] = useState(userProfile.countryCode);
  const [mobileSubmitted, setMobileSubmitted] = useState(false);
  const onChange = (value, { dialCode }) => {
    setPhoneNumber(value.substring(dialCode.length));
    setCountryCode(dialCode);
  };

  const goBack = () => {
    resetLoginWithPhone();
  };

  const onSubmitMobile = useCallback(async () => {
    setMobileSubmitted(true);
    try {
      const success = await getVerificationCode({ countryCode, phoneNumber });
      if (!success) {
        throw new Error();
      }
      // addToast(
      //   "Please check your phone for the verification we have sent you",
      //   {
      //     appearance: "success"
      //   }
      // );
    } catch (e) {
      // addToast("Oops! There was an error sending the verification code", {
      //   appearance: "error"
      // });
    }
  }, [getVerificationCode, phoneNumber, countryCode]);

  // Submit Verification Code
  const onSubmitCode = useCallback(async () => {
    try {
      await update({
        countryCode: countryCode,
        phoneNumber: phoneNumber,
        phoneVerificationCode: verificationCode
      });
      validateLogin();
      addToast("Profile updated successfully", {
        appearance: "success"
      });
    } catch (e) {}
  }, [
    validateLogin,
    addToast,
    update,
    phoneNumber,
    countryCode,
    verificationCode
  ]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumbContainer}
        >
          <Link className={classes.activeBreadcrumb} to="/myaccount/overview">
            Overview
          </Link>
          <Typography className={classes.disabledBreadcrumb}>
            Add Phone
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h2" className={classes.myAccountSubTitle}>
          Add Phone
        </Typography>
      </Grid>
      <Grid container className={classes.formContainer}>
        {!updating && (
          <>
            <Grid item xs={12} sm={8} md={6}>
              {/* {updateError && emailNameSubmitted && (
            <Alert
              severity="error"
              variant="filled"
              style={{ marginBottom: "0.5rem" }}
            >
              {updateError}
            </Alert>
          )} */}
              {!waitingOnVerificationCode && (
                <>
                  {!userProfile.phoneNumber && (
                    <>
                      <Typography
                        variant="body1"
                        className={classes.bodyText}
                        style={{ marginBottom: "24px" }}
                      >
                        Provide your new mobile phone number below.
                      </Typography>
                      <Form
                        onSubmit={onSubmitMobile}
                        initialValues={{
                          countryCode: userProfile.countryCode,
                          phoneNumber: userProfile.phoneNumber
                        }}
                      >
                        {props => (
                          <form onSubmit={props.handleSubmit}>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                className={classes.inputField}
                              >
                                {updateError && mobileSubmitted && (
                                  <Alert severity="error" variant="filled">
                                    {updateError}
                                  </Alert>
                                )}
                                <PhoneInput
                                  country="gb"
                                  inputProps={{
                                    name: "mobileNumber",
                                    required: true,
                                    autoFocus: false
                                  }}
                                  placeholder="Mobile Number"
                                  aria-label="Mobile Number"
                                  preferredCountries={[
                                    "gb",
                                    "ie",
                                    "us",
                                    "au",
                                    "nz"
                                  ]}
                                  excludeCountries={["je"]}
                                  enableTerritories={true}
                                  onChange={onChange}
                                  disabled={updating}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Button
                                  className={classes.submit}
                                  type="submit"
                                  disabled={updating}
                                >
                                  Add Mobile
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Form>
                    </>
                  )}
                  {userProfile.countryCode && userProfile.phoneNumber && (
                    <>
                      <Typography
                        variant="body1"
                        className={classes.bodyText}
                        style={{ marginBottom: "24px" }}
                      >
                        Provide your new mobile phone number below.
                      </Typography>
                      <Form
                        onSubmit={onSubmitMobile}
                        initialValues={{
                          countryCode: userProfile.countryCode,
                          phoneNumber: userProfile.phoneNumber
                        }}
                      >
                        {props => (
                          <form onSubmit={props.handleSubmit}>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                className={classes.inputField}
                              >
                                {updateError && mobileSubmitted && (
                                  <Alert severity="error" variant="filled">
                                    {updateError}
                                  </Alert>
                                )}
                                <PhoneInput
                                  inputProps={{
                                    name: "mobileNumber",
                                    required: true,
                                    autoFocus: false
                                  }}
                                  placeholder="Mobile Number"
                                  aria-label="Mobile Number"
                                  preferredCountries={[
                                    "gb",
                                    "ie",
                                    "us",
                                    "au",
                                    "nz"
                                  ]}
                                  excludeCountries={["je"]}
                                  enableTerritories={true}
                                  onChange={onChange}
                                  disabled={updating}
                                  value={
                                    userProfile.countryCode +
                                    userProfile.phoneNumber
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Button
                                  className={classes.submit}
                                  type="submit"
                                  disabled={updating}
                                >
                                  Update Mobile
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Form>
                    </>
                  )}
                </>
              )}

              {waitingOnVerificationCode && (
                <>
                  <Typography
                    variant="body1"
                    className={classes.bodyText}
                    align="left"
                    style={{ marginBottom: "24px" }}
                  >
                    Please enter the verification code sent to:
                    <br />
                    <br />
                    <span>
                      +{countryCode}
                      {phoneNumber}
                    </span>
                    .
                  </Typography>
                  <Grid item xs={12} sm={12}>
                    {updateError && mobileSubmitted && (
                      <Alert
                        severity="error"
                        variant="filled"
                        className={classes.validationError}
                      >
                        {updateError}
                      </Alert>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <ReactCodeInput
                      className={classes.verificationCodeContainer}
                      type="number"
                      fields={6}
                      value={verificationCode}
                      onComplete={e => setVerificationCode(e)}
                      disabled={updating}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      size="medium"
                      className={classes.submit}
                      onClick={onSubmitCode}
                    >
                      Verify Phone
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      size="medium"
                      variant="outlined"
                      style={{ marginTop: "16px" }}
                      className={classes.submit}
                      onClick={goBack}
                    >
                      Go Back
                    </Button>
                  </Grid>
                </>
              )}
              <Divider className={classes.footerDivider} />
              <Typography variant="body1" className={classes.bodyTextSml}>
                <span>Tip</span> - did you know you can log in with your Mobile
                Number?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}></Grid>
          </>
        )}
        {updating && (
          <Box m={5} align="center">
            <CircularProgress variant="indeterminate"></CircularProgress>
            <Typography variant="body2" className={classes.bodyTextSml}>
              <br />
              Updating your Phone Number. Please do not refresh your browser.
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
