import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid, Box } from "@material-ui/core";
import appleappstore from "./../Modals/app-store-logo.png";
import googleplaystore from "./../Modals/google-play-logo.png";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(6, 0, 0, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0, 0, 0)
    }
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },

  buttonContainer: {
    textAlign: "center",
    margin: theme.spacing(1, 0, 3, 0),
    padding: theme.spacing(1),
    "@media (min-width:600px)": {
      textAlign: "center"
    }
  },

  downloadText: {
    letterSpacing: "-1px",
    fontSize: "1.1rem",
    lineHeight: "1.2rem",
    fontWeight: 400,
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(1),
    color: "#ddd"
  },
  purchaseText: {
    letterSpacing: "-1px",
    fontSize: "1.1rem",
    lineHeight: "1.2rem",
    fontWeight: 500,
    textShadow: "-2px 2px 2px #171717",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    color: "#ddd"
  },
  appIconGraphic: {
    width: "80px",
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      width: "130px",
      marginBottom: theme.spacing(1)
    }
  },
  appStoreLogo: {
    maxWidth: "140px",
    margin: "5px",
    textAlign: "center",
    "@media (min-width:600px)": {
      margin: "10px 5px",
      maxWidth: "130px"
    },
    "@media (min-width:960px)": {
      margin: "10px 5px",
      maxWidth: "180px"
    }
  }
}));

const InstallApp = ({ p }: { p: string | undefined }) => {
  const classes = useStyles();

  const buildAppSmartLink = () => {
    switch (p) {
      case "ga":
        return "https://smart.link/9aotytyfzl6b2";
      case "sc":
        return "https://smart.link/3bq5xlc8iwxay";
      case "tt":
        return "https://smart.link/05s8kbsiwc29s";
      default:
        return "https://smart.link/6fa45jd155j1i";
    }
  };

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="md" fixed>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Box style={{ display: "flex" }}>
                <Box marginRight={2}>
                  <a
                    href={buildAppSmartLink()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={
                        "https://farmflix-images-prod.s3.eu-west-1.amazonaws.com/FreeTrial/app_logo_200_dark.jpg"
                      }
                      alt="FarmFLiX App Icon"
                      className={classes.appIconGraphic}
                    />
                  </a>
                </Box>
                <Box>
                  <Typography variant="h2" className={classes.title}>
                    Install The
                  </Typography>
                  <Typography variant="h2" className={classes.titleLarge}>
                    Free App
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} style={{ textAlign: "right" }}></Grid>
            <Grid item xs={12} sm={12} className={classes.buttonContainer}>
              <Typography variant="h4" className={classes.downloadText}>
                Download at:
              </Typography>
              <a
                href={buildAppSmartLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appleappstore}
                  className={classes.appStoreLogo}
                  alt="Apple App Store Graphic"
                />
              </a>
              <a
                href={buildAppSmartLink()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googleplaystore}
                  className={classes.appStoreLogo}
                  alt="Google Play Store Graphic"
                />
              </a>
              <Typography variant="h4" className={classes.purchaseText}>
                Free Download. No Payment Required.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default InstallApp;
