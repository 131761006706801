import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import {
  getCatalogOverview as apiGetCatalogOverview,
  getExternalCatalogOverview as apiGetExternalCatalogOverview
} from "../api/client";
import Notification from "../api/entities/Notification";
import { AccountContext, IAccountContext } from "./AccountContext";
import usePromise from "react-use-promise";
import { CatalogOverviewResponse } from "@booyaltd/core";

const NOTIFICATIONS_READ_DATE_STORAGE_KEY = "ff-notifications";

interface IOverviewContext {
  overview: CatalogOverviewResponse | undefined;
  loading: boolean;
  error: Error | undefined;
  reload(): void;
  notificationsRead(): void;
  isNewNotification(notification: Notification): boolean;
  unreadNotificationCount: number;
  doors?: { open: boolean; annualUpgrade?: boolean; until: Date | string };
  eventPass?: { open: boolean; until: Date | string };
}

const OverviewContext = createContext<IOverviewContext>({
  overview: undefined,
  loading: false,
  error: undefined
} as IOverviewContext);

const OverviewProvider = (props: any) => {
  const { loggedIn, loading: loggingIn, isLocked, userProfile } = useContext<
    IAccountContext
  >(AccountContext);
  const [reloadTrigger, setReloadTrigger] = useState(Date.now());
  const [notificationReadDate, setNotificationReadDate] = useState<Date>();
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const getCatalogOverview = useCallback((): Promise<
    CatalogOverviewResponse | undefined
  > => {
    if (loggingIn) {
      return Promise.resolve(undefined);
    }

    // Not logged in or locked
    if (!loggedIn || isLocked) {
      // @ts-ignore
      return apiGetExternalCatalogOverview();
    }

    // Logged in
    if (loggedIn && userProfile) {
      // Trial is expired = subscription-window
      if (userProfile.memberStatus === "trial-expired") {
        // @ts-ignore
        return apiGetExternalCatalogOverview();
      }
    }
    return apiGetCatalogOverview();
  }, [userProfile, loggedIn, isLocked, loggingIn]);

  const [overview, error, state] = usePromise(getCatalogOverview, [
    reloadTrigger
  ]);

  const isNewNotification = useCallback(
    (notification: Notification): boolean => {
      if (!notificationReadDate) {
        return false;
      }

      return notification.activeDate >= notificationReadDate;
    },
    [notificationReadDate]
  );

  useEffect(() => {
    const lastRead = localStorage.getItem(NOTIFICATIONS_READ_DATE_STORAGE_KEY);
    if (!lastRead) {
      setNotificationReadDate(new Date(0));
      return;
    }

    const lastReadDate = new Date(lastRead);
    setNotificationReadDate(lastReadDate);
  }, []);

  useEffect(() => {
    if (
      !notificationReadDate ||
      !overview ||
      !overview.notifications ||
      overview.notifications.length === 0
    ) {
      setUnreadNotificationCount(0);
      return;
    }

    setUnreadNotificationCount(
      overview.notifications.filter(isNewNotification).length
    );
  }, [notificationReadDate, isNewNotification, overview]);

  // Removed isLocked -
  const reload = useCallback((): void => {
    if (loggedIn) {
      setReloadTrigger(Date.now);
    }
  }, [loggedIn]);

  // eslint-disable-next-line
  useEffect(reload, []);
  // eslint-disable-next-line
  useEffect(reload, [loggedIn, loggingIn]);

  const notificationsRead = () => {
    const now = new Date();
    localStorage.setItem(
      NOTIFICATIONS_READ_DATE_STORAGE_KEY,
      now.toISOString()
    );

    setUnreadNotificationCount(0);
    setNotificationReadDate(now);
  };

  const context = {
    overview,
    loading: state === "pending",
    error,
    reload,
    notificationsRead,
    unreadNotificationCount,
    isNewNotification
  } as IOverviewContext;

  return (
    <OverviewContext.Provider value={context}>
      {props.children}
    </OverviewContext.Provider>
  );
};

export { OverviewContext, OverviewProvider };
