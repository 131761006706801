import React, { useState, useContext } from "react";
import {
  Popover,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box
} from "@material-ui/core";
import { Favorite } from "@material-ui/icons";
import { SearchContext } from "./SearchContainer";
import useStyles from "./styles";

const FavouritedFilter = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { search, updateSearch } = useContext(SearchContext);

  const handleFilterOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "favourite-popover" : undefined;

  const handleFilterChange = (e: any, value: "all" | "1" | "0") => {
    updateSearch({
      favourited: value === "all" ? undefined : value === "1"
    });
  };

  return (
    <>
      <Chip
        className={classes.filterChip}
        label={
          <>
            <Box display="flex" alignItems="center">
              <Favorite
                className={
                  search && search.favourited
                    ? classes.filterChipIconActive
                    : classes.filterChipIcon
                }
              />
              <Typography className={classes.filterChipLabel}>
                Favourite
              </Typography>
            </Box>
          </>
        }
        aria-describedby={id}
        onClick={handleFilterOpen}
      />

      <Popover
        classes={{ paper: classes.popOverContainer }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <RadioGroup
          aria-label="favourite_filter"
          name="favouriteFilter"
          value={
            search?.favourited === undefined
              ? "all"
              : search.favourited
              ? "1"
              : "0"
          }
          // @ts-ignore
          onChange={handleFilterChange}
        >
          <FormControlLabel
            classes={{ label: classes.filterLabel }}
            value="all"
            control={<Radio color="primary" />}
            label="All"
          />
          <FormControlLabel
            classes={{ label: classes.filterLabel }}
            value="1"
            control={<Radio color="primary" />}
            label="My Favourites"
          />
          <FormControlLabel
            classes={{ label: classes.filterLabel }}
            value="0"
            control={<Radio color="primary" />}
            label="Not My Favourites"
          />
        </RadioGroup>
      </Popover>
    </>
  );
};

export default FavouritedFilter;
