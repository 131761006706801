import React, { useContext, useState } from "react";
import StepContainer from "./StepContainer";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ReactCodeInput from "react-verification-code-input";
import useStyles from "../SignUpForm.styles";
import { Form } from "react-final-form";
import PhoneInput from "../../../common/TwoStepPhoneInput";
import { OnboardingContext } from "../../../../store/OnboardingContext";
import { Alert } from "@material-ui/lab";
import { SecondaryButton, SmsButton } from "../../../common/buttons";
import { Box, Divider } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_CAPTCHA_SITE_KEY } from "../../../../env";

const SignUpStepPhone = () => {
  const {
    application,
    loading,
    setPhone,
    verifyPhone,
    runWithLoader
  } = useContext(OnboardingContext);
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [changingPhoneNumber, setChangingPhoneNumber] = useState(false);
  const [resent, setResent] = useState(false);
  const [captchaRequired, setCaptchaRequired] = useState(false);

  // Google ReCAPTCHA
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  if (!application) return null;

  const {
    countryCode: savedCountryCode,
    phoneNumber: savedPhoneNumber
  } = application;

  const verifying =
    !changingPhoneNumber && savedCountryCode && savedPhoneNumber;

  const onPhoneChange = (countryCode: string, phoneNumber: string) => {
    setPhoneNumber(phoneNumber.replace(/^0+/, "").replace(/\D/g, ""));
    setCountryCode(countryCode);
  };

  const changeMobileNumber = () => {
    setChangingPhoneNumber(true);
  };

  const resend = (sendMethod: "sms" | "whatsapp") => () => {
    if (!canSubmit || !countryCode || !phoneNumber) return;

    setResent(false);

    runWithLoader(async () => {
      setPhone(countryCode, phoneNumber);
      setResent(true);
    }, true);
  };

  const canSubmit =
    countryCode &&
    countryCode.length > 0 &&
    phoneNumber &&
    phoneNumber.length > 6;

  // Submit Form
  const submit = (sendMethod: "sms" | "whatsapp") => async () => {
    if (!canSubmit || !countryCode || !phoneNumber) return;

    if (!recaptchaRef.current) {
      console.log("Error initializing recaptcha");
      return;
    }
    const captchaToken = recaptchaRef.current.getValue();

    if (!captchaToken) {
      setCaptchaRequired(true);
      console.log("Error Occurred, please try again.");
      return;
    }
    try {
      setChangingPhoneNumber(false);
      // console.log("Token", captchaToken);
      setPhone(countryCode, phoneNumber, captchaToken);
    } catch (e) {
      console.error("Error Submitting Form", e);
    } finally {
      recaptchaRef.current.reset();
    }
  };

  return (
    <StepContainer>
      <StepContainer.Body>
        {!verifying && (
          <Form onSubmit={submit("sms")}>
            {props => (
              <form onSubmit={props.handleSubmit} autoComplete="false">
                <div className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        className={classes.bodyText}
                        align="center"
                        style={{
                          fontWeight: 500,
                          marginTop: "24px",
                          color: "#eee",
                          lineHeight: "1.4rem"
                        }}
                      >
                        We{" "}
                        <span className={classes.primaryColor}>
                          send a code
                        </span>{" "}
                        to your phone to verify it&apos;s you. Please select
                        your phone{" "}
                        <span className={classes.primaryColor}>
                          country code
                        </span>{" "}
                        and type your{" "}
                        <span className={classes.primaryColor}>number</span>{" "}
                        below
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.formFieldSetLarge}
                    >
                      <PhoneInput onChange={onPhoneChange} disabled={loading} />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="normal"
                        theme="dark"
                        sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                      />
                      {captchaRequired && (
                        <Alert
                          severity="error"
                          variant="filled"
                          onClose={() => setCaptchaRequired(false)}
                        >
                          Please complete Captcha
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SmsButton
                        title="Continue with SMS"
                        disabled={!canSubmit}
                        onClick={submit("sms")}
                      />
                      {/* <WhatsappButton
                        title="Continue with WhatsApp"
                        disabled={!canSubmit}
                        onClick={submit("whatsapp")}
                      /> */}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "center",
                          color: "#ddd",
                          fontSize: "0.9rem",
                          lineHeight: "0.9rem",
                          marginTop: "12px",
                          fontWeight: 600
                        }}
                      >
                        Don&apos;t know your Country Code?{" "}
                        <a
                          href="https://countrycode.org/"
                          title="Check Your Country Code here"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#99ca3c" }}
                        >
                          Check here
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </form>
            )}
          </Form>
        )}
        {verifying && (
          <>
            <Typography
              variant="h3"
              className={classes.bodyText}
              align="center"
              style={{
                fontWeight: 500,
                marginTop: "24px",
                marginBottom: "12px",
                color: "#eee"
              }}
            >
              Verify Phone
            </Typography>
            <Typography
              variant="body2"
              className={classes.bodyText}
              align="center"
              style={{ fontWeight: 400, color: "#999", lineHeight: "1rem" }}
            >
              Enter the OTP (One Time Passcode) we&apos;ve sent to your phone
              number.
            </Typography>
            {resent && (
              <Alert
                severity="success"
                variant="filled"
                onClose={() => setResent(false)}
              >
                Verification code resent successfully!
              </Alert>
            )}
            <Typography
              variant="body1"
              className={classes.submittedMobileNumber}
            >
              {`+${countryCode} ${phoneNumber}`}
            </Typography>

            <form className={classes.form}>
              <Grid item xs={12} sm={12}>
                {/* @ts-ignore */}
                <ReactCodeInput
                  className={classes.verificationCodeContainer}
                  type="number"
                  fields={6}
                  onComplete={verifyPhone}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box px={2} pt={1}>
                  <SmsButton
                    title="Resend Code with SMS"
                    onClick={resend("sms")}
                  />
                  {/* <WhatsappButton
                    title="Resend Code with WhatsApp"
                    onClick={resend("whatsapp")}
                  /> */}

                  <Box px={2} pb={2}>
                    <Divider className={classes.dividerWhite} />
                  </Box>

                  <SecondaryButton
                    title="Change Phone"
                    onClick={changeMobileNumber}
                  />
                </Box>
              </Grid>
            </form>
          </>
        )}
      </StepContainer.Body>
      <StepContainer.Actions />
    </StepContainer>
  );
};

export default SignUpStepPhone;
