import React, { useContext } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { SearchContext } from "./SearchContainer";
import VideoRow from "../Video/VideoRow";
import LoadMoreButton from "./LoadMoreButton";
import { AccountContext } from "../../store/AccountContext";

const SearchResults = () => {
  const { searchResponse, searchLoading, canLoadMore, loadMore } = useContext(
    SearchContext
  );
  const { userProfile } = useContext(AccountContext);

  return (
    <Grid container>
      <Grid item>
        {searchLoading && !searchResponse?.total ? (
          <CircularProgress />
        ) : (searchResponse?.total || 0) > 0 ? (
          <>
            {searchResponse?.results.map((video, idx) => (
              <VideoRow
                idx={idx}
                key={video.id}
                video={video}
                listType={"playlist"}
                memberStatusId={userProfile?.memberStatusId}
              />
            ))}
          </>
        ) : (
          <Typography variant="body1">No results</Typography>
        )}
      </Grid>
      <LoadMoreButton
        canLoadMore={canLoadMore}
        loading={searchLoading}
        loadMore={loadMore}
      />
    </Grid>
  );
};

export default SearchResults;
