import BlogPosts from "./Blog/blogposts_short.json";
import { useHistory } from "react-router-dom";

const Post = props => {
  const history = useHistory();
  const slug = props.match.params.id;
  // eslint-disable-next-line
  let postTitle, postContent;

  // Build custom mapper here to show Post details
  const postsMapper = BlogPosts;

  postsMapper.forEach(function(arrItem) {
    if (slug === arrItem.slug) {
      postTitle = arrItem.title;
      postContent = arrItem.content.replace(/<[^>]*>?/gm, "");
    }
  });

  var matches = postContent.match(/\bhttps?:\/\/\S+/gi);
  if (matches) {
    if (matches.length > 1) {
      // Find first occurance of 'video-details' and link to this preview
      matches.forEach(function(mat) {
        if (mat.includes("video-details")) {
          var cleanMat = mat.replace(/[{()}]/g, "");
          var matSlug = cleanMat.split("/video-details/")[1];
          history.push("/preview/" + matSlug + "-preview");
          return true;
        }
      });
    } else if (matches.length === 1) {
      if (matches[0].includes("video-details")) {
        var cleanMatch = matches[0].replace(/[{()}]/g, "");
        var matSlug = cleanMatch.split("/video-details/")[1];
        history.push("/preview/" + matSlug + "-preview");
        return true;
      } else {
        history.push("/");
        return true;
      }
    } else {
      history.push("/");
      return true;
    }
  } else {
    history.push("/");
    return true;
  }
};

export default Post;
