import React from "react";
import { Button, CircularProgress, Grid } from "@material-ui/core";

type LoadMoreButtonProps = {
  canLoadMore: boolean;
  loading: boolean;
  loadMore: () => void;
};

const LoadMoreButton = ({
  canLoadMore,
  loading,
  loadMore
}: LoadMoreButtonProps) => {
  return canLoadMore ? (
    <Grid
      item
      xs={12}
      style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}
    >
      <Button onClick={loadMore} size="large" disabled={loading}>
        Load More Results
        {loading && <CircularProgress size={20} />}
      </Button>
    </Grid>
  ) : null;
};

export default LoadMoreButton;
