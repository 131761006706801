import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import {
  getAnalytics,
  logEvent as logEventFirebase,
  setUserProperties
} from "firebase/analytics";
import { firebaseApp } from "../store/LoggerContext";

import Top from "./FreeTrial/Top";
import Stepper from "./FreeTrial/Stepper";
import Video from "./FreeTrial/Video";
import WhatIs from "./FreeTrial/WhatIs";
import InstallApp from "./FreeTrial/InstallApp";
import FAQs from "./FreeTrial/FAQs";
import Testimonials from "./FreeTrial/Testimonials";
import Trial from "./FreeTrial/Trial";
import { AccountContext } from "../store/AccountContext";
import { OverviewContext } from "../store/OverviewContext";
import { Redirect } from "react-router";
import DoorsClosed from "./DoorsClosed";

import BuyNowTop from "./BuyNow/Top";
import BuyNowFAQs from "./BuyNow/FAQs";
import BuyNowGuarantee from "./BuyNow/Guarantee";
import TrialsDisabled from "./FreeTrial/TrialsDisabled";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy
  }
}));

const FreeTrialLanding = ({ platform }: { platform: string | undefined }) => {
  const classes = useStyles();
  const { loggedIn } = useContext(AccountContext);
  const { overview } = useContext(OverviewContext);

  useEffect(() => {
    logEventFirebase(getAnalytics(firebaseApp), "seen_landing_page_1");
    setUserProperties(getAnalytics(firebaseApp), {
      seen_landing_page_1: "1"
    });
  }, []);

  return (
    <>
      {loggedIn ? <Redirect to="/videos" /> : null}

      {overview && (
        /*
            Doors Open & Trials Open - show Free Trial
            Doors Closed & Trials Open - show Free Trial
            Doors Open & Trials Closed - show Join Now
            Doors Closed & Trials Closed - show Free Trial (notify me status)
          */
        <>
          {overview.doors?.trialsOpen &&
            overview.doors?.open &&
            overview.doors?.trialSMSEnabled && (
              <>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>FarmFLiX.tv | Take a 72 Hour Free Trial Now!</title>
                  <meta
                    name="description"
                    content="Now is your opportunity to take a 72 Hour Free Trial."
                  />
                </Helmet>
                <div className={classes.container}>
                  <Top />
                  <Stepper />
                  <Video />
                  <WhatIs />
                  <Testimonials />
                  <FAQs />
                  <Trial />
                  <InstallApp p={platform} />
                </div>
              </>
            )}

          {overview.doors?.trialsOpen &&
            !overview.doors?.open &&
            overview.doors?.trialSMSEnabled && (
              <>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>FarmFLiX.tv | Take a 72 Hour Free Trial Now!</title>
                  <meta
                    name="description"
                    content="Now is your opportunity to take a 72 Hour Free Trial."
                  />
                </Helmet>
                <div className={classes.container}>
                  <Top />
                  <Stepper />
                  <Video />
                  <WhatIs />
                  <Testimonials />
                  <FAQs />
                  <Trial />
                  <InstallApp p={platform} />
                </div>
              </>
            )}

          {overview.doors?.open && !overview.doors?.trialsOpen && (
            <>
              {" "}
              <Helmet>
                <meta charSet="utf-8" />
                <title>FarmFLiX.tv | Join The Clan Now!</title>
                <meta
                  name="description"
                  content="Last chance to get a FarmFLiX Membership this year!."
                />
              </Helmet>
              <div className={classes.container}>
                <BuyNowTop />
                <Video />
                <WhatIs />
                <Testimonials />
                <BuyNowFAQs />
                <BuyNowGuarantee />
              </div>
            </>
          )}

          {!overview.doors?.trialsOpen && !overview.doors?.open && (
            <>
              <DoorsClosed />
            </>
          )}

          {!overview.doors?.trialSMSEnabled && (
            <>
              <TrialsDisabled />
            </>
          )}

          {overview.doors?.trialsOpen &&
            !overview.doors?.open &&
            !overview.doors?.trialSMSEnabled && (
              <>
                <TrialsDisabled />
              </>
            )}
        </>
      )}
    </>
  );
};

export default FreeTrialLanding;
