import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  searchDialogContainer: {
    textAlign: "center"
  },
  searchDialogTitle: {
    backgroundColor: "#171717",
    color: "#fff"
  },
  searchDialogCloseButton: {
    position: "absolute",
    top: "15px",
    right: "15px",
    color: "#fff",
    padding: "6px",
    backgroundColor: "#333"
  },
  searchDialogContent: {
    backgroundColor: "#333",
    padding: 0
  },
  searchDialogContentContainer: {
    textAlign: "left"
  },
  searchAutoCompleteContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  searchInputField: {
    width: "100%",
    flex: 1,
    padding: 0,
    backgroundColor: "#444",
    color: "inherit",
    border: "none !important",
    borderBottom: "1px solid #ccc",
    outline: "none !important",
    marginTop: theme.spacing(1),
    "&:focus": {
      border: "none",
      borderBottom: "1px solid green",
      outline: "none"
    },
    "& input": {
      // paddingLeft: 10,
      outline: "none",
      border: "none",
      marginTop: "0px",
      marginLeft: 10,
      // padding: theme.spacing(2),
      fontSize: "1rem",
      caretColor: theme.palette.primary.main,
      color: "#fff",
      fontWeight: 500,
      "&:focus": {
        border: "none",
        outline: "none"
      }
    }
  },
  searchButtonContainer: {
    padding: theme.spacing(0.5, 1)
  },
  searchButtonIcon: {
    paddingTop: "0.05em",
    fontSize: "2rem",
    borderRadius: "50%",
    color: "#e9e9e9",
    cursor: "pointer",
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.4, 0),
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  searchButton: {
    color: "#99ca3c"
  },
  filtersContainer: {
    textAlign: "left"
  },
  filtersList: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0)
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  filterButton: {
    backgroundColor: "#444",
    color: theme.palette.common.white,
    padding: 0,
    margin: "15px 5px",
    border: "none",
    "&:hover": {
      backgroundColor: "#444",
      color: theme.palette.primary.main,
      border: "none"
    }
  },
  filterChip: {
    padding: "6px",
    margin: "0 2% 12px 2%",
    width: "46%",
    backgroundColor: "#545454",
    "&:focus": {
      backgroundColor: "#545454"
    },
    "&:hover": {
      backgroundColor: "#545454"
    },
    "@media (min-width:600px)": {
      margin: "0 1% 12px 1%",
      width: "31%"
    },
    "@media (min-width:960px)": {
      width: "auto",
      margin: "0 0 0 12px"
    }
  },
  filterChipIcon: {
    fontSize: "1.1rem",
    marginRight: "6px",
    color: "#fff"
  },
  filterChipIconActive: {
    fontSize: "1.1rem",
    marginRight: "6px",
    color: theme.palette.primary.main
  },
  filterChipLabel: {
    fontSize: "0.9rem"
  },
  popOverContainer: {
    zIndex: 2000,
    backgroundColor: "#444",
    padding: theme.spacing(2)
  },
  filterLabel: {
    fontSize: "0.9rem !important"
  },
  autoCompletePopper: {
    backgroundColor: "#666",
    color: "#fff",
    fontWeight: 500,
    "& li": {
      fontSize: "0.95rem",
      padding: theme.spacing(1, 2),
      "&:hover": {
        backgroundColor: "#555"
      }
    }
  }
}));

export default useStyles;
