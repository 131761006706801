import React, { useContext, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Grid, Button } from "@material-ui/core";
import LoggedInLayout from "../layouts/LoggedInLayout";
import { OverviewContext } from "../store/OverviewContext";
import { PlayerContext } from "../store/PlayerContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import VideoRow from "./Video/VideoRow";
import useVideoTypeSearch from "../hooks/useVideoTypeSearch";
import {
  buildVideoSearchParamsForPlaylist,
  VideoSearchParamIsReady,
  VideoType
} from "@booyaltd/core";

const useStyles = makeStyles(theme => ({
  seriesBackgroundSection: {
    height: "300px",
    backgroundColor: theme.palette.background.dark,
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "700px"
    }
  },
  seriesBackgroundSectionBottomGradient: {
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 0%, rgba(23,23,23,1) 100%)",

    height: "300px",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "700px"
    }
  },
  seriesDetailsOverlay: {
    position: "absolute",
    bottom: "20px"
  },
  seriesChannel: {
    marginBottom: theme.spacing(1),
    borderRadius: "50%",
    width: "80px",
    "@media (min-width:600px)": {
      borderRadius: "50%",
      width: "120px"
    },
    "@media (min-width:960px)": {
      borderRadius: "50%",
      width: "120px"
    }
  },
  seriesTitle: {
    fontSize: "2.2rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "2.2rem",
      lineHeight: "2.4rem",
      textShadow: "-3px 3px 3px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "3.6rem",
      lineHeight: "3.8rem"
    }
  },
  seriesEpisodeCount: {
    fontWeigth: "500",
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    fontSize: "1.2rem"
  },
  seriesEpisodeListBackground: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    "@media (min-width:960px)": {
      backgroundColor: theme.palette.background.dark,
      marginTop: theme.spacing(4)
    }
  },
  seriesEpisodeListContainer: {
    backgroundColor: theme.palette.background.ffNavy,
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(0),
    "@media (min-width:960px)": {
      backgroundColor: theme.palette.background.ffNavyLighter,
      paddingTop: theme.spacing(4)
    }
  },
  seriesDescription: {
    marginBottom: theme.spacing(5),
    fontWeight: 400,
    width: "100%",
    "@media (min-width:600px)": {
      fontWeight: 500,
      width: "80%"
    }
  },
  seriesEpisodesTitle: {
    fontSize: "1.4rem",
    marginBottom: theme.spacing(2)
  },
  episodeWrapper: {
    marginBottom: theme.spacing(2)
  },
  episodeThumbnailContainer: {
    position: "relative",
    marginBottom: 0,
    paddingBottom: 0
  },
  episodeThumbnail: {
    width: "100%"
  },
  episodeThumbnailHover: {
    minHeight: "100px",
    opacity: "0.3",
    width: "100%",
    transition: "0.3s",
    backgroundSize: "cover"
  },
  episodeTitle: {
    fontWeight: 500,
    fontSize: "1.3rem",
    marginTop: 0,
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1)
    }
  },
  episodeMetaDetailsContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  episodeDetailsIcon: {
    display: "inline",
    color: theme.palette.background.ffGreen,
    marginRight: "4px",
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem"
    }
  },
  episodeDetailsText: {
    display: "inline",
    color: theme.palette.common.darkgray,
    marginRight: theme.spacing(2),
    fontSize: "0.9rem",
    lineHeight: "1.2rem"
  },
  episodeDescription: {
    color: theme.palette.common.darkgray,
    fontSize: "0.9rem",
    borderBottom: "1px solid #292929",
    paddingBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      borderBottom: "none"
    }
  },
  favouriteIconButton: {
    fontSize: "1.8rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        color: "#ff5050"
      }
    }
  },
  favouritedIconButton: {
    fontSize: "1.8rem",
    backgroundColor: "#555555",
    color: "#ff5050",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1)
  },
  playIconButton: {
    fontSize: "2.2rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        color: theme.palette.background.ffGreen
      }
    }
  },
  iconButton: {
    width: "2rem",
    height: "2rem"
  },
  "@global": {
    "@keyframes fadeIn": {
      from: {
        transform: "translate3d(0,15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    }
  },
  episodeHoverDetailsActive: {
    animation: "fadeIn .3s ease-in-out",
    position: "absolute",
    bottom: "15px",
    right: "5px"
  }
}));

const Series = props => {
  const classes = useStyles();
  const playlistId = props.match.params.id;

  const { resetError, clearCurrentVideo } = useContext(PlayerContext);

  const { overview } = useContext(OverviewContext);

  const { initialise, searchResponse, loadMore } = useVideoTypeSearch();

  useEffect(() => {
    return () => {
      clearCurrentVideo();
      resetError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playlist = useMemo(
    () => overview?.playlists?.find(i => i.tag === playlistId),
    [overview, playlistId]
  );

  useEffect(() => {
    if (playlist) {
      const playlistSearch = buildVideoSearchParamsForPlaylist(playlist);
      console.log("Initialising series");
      initialise(playlistSearch, {
        search: {
          type: VideoType.Episode,
          isReady: VideoSearchParamIsReady.Master,
          ...playlistSearch
        },
        results: playlist.videos,
        total: playlist.total
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist]);

  const handleTrigger = () => {
    loadMore();
  };

  if (!playlist) {
    return (
      <LoggedInLayout>
        <div className={classes.background} style={{ textAlign: "center" }}>
          <CircularProgress color="primary" />
        </div>
      </LoggedInLayout>
    );
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          FarmFLiX.tv |{" "}
          {overview && playlist ? playlist.name : "Unknown Series"}
        </title>
        <style type="text/css"></style>
      </Helmet>
      <LoggedInLayout>
        {overview && playlist && searchResponse && searchResponse.total > 0 && (
          <>
            {/* New Top Background  */}
            <div
              className={classes.seriesBackgroundSection}
              style={{
                backgroundImage: `url(${playlist.posterHorizontalMediaUrl})`
              }}
            >
              <div className={classes.seriesBackgroundSectionBottomGradient}>
                <Container>
                  <Grid container>
                    <Grid
                      item
                      xs={8}
                      sm={8}
                      className={classes.seriesDetailsOverlay}
                    >
                      {playlist.thumbnailMediaUrl && (
                        <img
                          src={playlist.thumbnailMediaUrl}
                          alt=""
                          className={classes.seriesChannel}
                        />
                      )}
                      <Typography className={classes.seriesTitle} variant="h1">
                        {playlist.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
            <div className={classes.seriesEpisodeListBackground}>
              <Container
                maxWidth="lg"
                className={classes.seriesEpisodeListContainer}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      className={classes.seriesDescription}
                      variant="body1"
                    >
                      {playlist.description}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.seriesEpisodesTitle}
                    >
                      Episodes
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {searchResponse.results.map((video, idx) => (
                      <VideoRow
                        key={video.id}
                        idx={idx}
                        video={video}
                        listType={"series"}
                      />
                    ))}
                  </Grid>
                </Grid>
                {searchResponse.results.length < searchResponse.total && (
                  <Grid item xs={12} sm={12} align="center">
                    <Button
                      size="large"
                      className={classes.loadMore}
                      onClick={handleTrigger}
                    >
                      Load More
                    </Button>
                  </Grid>
                )}
              </Container>
            </div>
          </>
        )}
      </LoggedInLayout>
    </div>
  );
};

export default Series;
