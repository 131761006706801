import React from "react";
import { Helmet } from "react-helmet";
import ErrorMessage from "./common/ErrorMessage";
import DefaultLayout from "../layouts/DefaultLayout";

export default function NotFound() {
  return (
    <DefaultLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Not Found</title>
        <meta
          name="description"
          content="The page you are looking for could not be found."
        />
      </Helmet>
      <ErrorMessage title="404" message="Page Not Found" linkHome={true} />
    </DefaultLayout>
  );
}
