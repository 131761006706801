import React, { useContext, useState } from "react";
import { AccountContext } from "../../store/AccountContext";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import usePromise from "react-use-promise";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

export default function GracePeriodBanner() {
  const useStyles = makeStyles(theme => ({
    wrapper: {
      backgroundColor: "#f3c621",
      padding: theme.spacing(1.5, 4, 2, 2),
      borderTop: "3px solid #d59e05",
      "@media (min-width:600px)": {
        padding: theme.spacing(1.5, 4)
      }
    },
    heavyWrapper: {
      backgroundColor: "#ff841b",
      padding: theme.spacing(1.5, 4, 2, 2),
      borderTop: "3px solid #bd5904",
      "@media (min-width:600px)": {
        padding: theme.spacing(1.5, 4)
      }
    },
    background: {
      flexGrow: 1,
      paddingLeft: 0,
      paddingRight: 0
    },
    textIconWrapper: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      "@media (min-width:600px)": {
        alignItems: "center",
        justifyContent: "flex-start"
      }
    },
    warningIcon: {
      height: "1.3em",
      width: "1.3em",
      marginTop: "0px",
      color: "#292929",
      "@media (min-width:600px)": {
        height: "1.2em",
        width: "1.2em"
      }
    },
    heavyWarningIcon: {
      height: "1.3em",
      width: "1.3em",
      marginTop: "0px",
      color: "#292929",
      "@media (min-width:600px)": {
        height: "1.2em",
        width: "1.2em"
      }
    },
    bannerText: {
      marginTop: "0px",
      marginLeft: "6px",
      fontSize: "0.9rem",
      lineHeight: "1rem",
      textAlign: "left",
      color: "#292929",
      "& span": {
        fontWeight: 500
      },
      "@media (min-width:600px)": {
        fontSize: "0.95rem",
        marginTop: "2px"
      },
      "@media (min-width:960px)": {}
    },
    fixButtonWrapper: {
      textAlign: "left",
      paddingLeft: "36px",
      "@media (min-width:600px)": {
        textAlign: "right",
        paddingLeft: "0px"
      }
    },
    fixButton: {
      backgroundColor: "none",
      borderColor: "#292929",
      color: "#292929",
      marginTop: theme.spacing(1),
      width: "auto",
      margin: "0 auto",
      fontSize: "0.85rem",
      "&:hover": {
        borderColor: "#191919",
        color: "#191919"
      },
      "@media (min-width:600px)": {
        marginTop: theme.spacing(0),
        width: "auto",
        margin: 0,
        fontSize: "0.9rem"
      }
    }
  }));

  const classes = useStyles();

  const { userProfile, getMembershipDetails } = useContext(AccountContext);
  // eslint-disable-next-line
  const [reloadTrigger, setReloadTrigger] = useState(Date.now());
  const [membershipDetails] = usePromise(getMembershipDetails, [reloadTrigger]);

  return (
    <>
      {membershipDetails && (
        <div
          className={
            userProfile.subscriptionPaymentRetryCount === 1
              ? classes.wrapper
              : classes.heavyWrapper
          }
        >
          <Box>
            <Grid container spacing={0} className={classes.background}>
              <Grid item xs={12} sm={9}>
                <div className={classes.textIconWrapper}>
                  {userProfile.subscriptionPaymentRetryCount > 0 && (
                    <>
                      {userProfile.subscriptionPaymentRetryCount === 1 ? (
                        <>
                          <WarningRoundedIcon className={classes.warningIcon} />
                          <Typography
                            variant="body2"
                            className={classes.bannerText}
                          >
                            <span>
                              {membershipDetails.defaultPaymentMethod !=
                              null ? (
                                <>Check</>
                              ) : (
                                <>Add</>
                              )}{" "}
                              your card details now.
                            </span>{" "}
                            We were unable to process your membership payment.{" "}
                            {membershipDetails.paymentMethod != null && (
                              <>
                                {userProfile.subscriptionPaymentNextAttempt !=
                                null ? (
                                  <>
                                    We&apos;ll try again on{" "}
                                    <span>
                                      {dayjs(
                                        userProfile.subscriptionPaymentNextAttempt
                                      ).format("Do MMMM YYYY")}
                                    </span>
                                    .
                                  </>
                                ) : (
                                  <>We&apos;ll try again in a few days.</>
                                )}
                              </>
                            )}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <WarningRoundedIcon
                            className={classes.heavyWarningIcon}
                          />
                          <Typography
                            variant="body2"
                            className={classes.bannerText}
                          >
                            <span>
                              {membershipDetails.defaultPaymentMethod !=
                              null ? (
                                <>Check</>
                              ) : (
                                <>Add</>
                              )}{" "}
                              your card details now.
                            </span>{" "}
                            We were unable to process your membership payment on
                            the second attempt.{" "}
                            {membershipDetails.paymentMethod != null && (
                              <>
                                {userProfile.subscriptionPaymentNextAttempt !=
                                null ? (
                                  <>
                                    We&apos;ll try once last time on{" "}
                                    <span>
                                      {dayjs(
                                        userProfile.subscriptionPaymentNextAttempt
                                      ).format("Do MMMM YYYY")}
                                    </span>
                                    .
                                  </>
                                ) : (
                                  <>We&apos;ll try again in a few days.</>
                                )}
                              </>
                            )}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.fixButtonWrapper}>
                {membershipDetails.defaultPaymentMethod != null ? (
                  <>
                    {" "}
                    <Link
                      to="/myaccount/payment-method/update"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        className={classes.fixButton}
                        variant="outlined"
                        size="small"
                      >
                        Update Details
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to="/myaccount/payment-method/add"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        className={classes.fixButton}
                        variant="outlined"
                        size="small"
                      >
                        Add Details
                      </Button>
                    </Link>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </>
  );
}
