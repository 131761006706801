import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Box, Typography, Button } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import "./../../../node_modules/react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { CatalogOverviewResponse, PromoItem } from "@booyaltd/core";
// @ts-ignore
import ItemsCarousel from "react-items-carousel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from "../../store/LoggerContext";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(3, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(6, 0)
    }
  },
  container: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(2),
    "@media (min-width:600px)": {
      padding: theme.spacing(3)
    }
  },
  title: {
    letterSpacing: "-1px",
    fontSize: "1.4rem",
    lineHeight: "1.6rem",
    fontWeight: 500,
    textShadow: "-1px 1px 1px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      letterSpacing: "-2px",
      fontWeight: 600,
      textShadow: "-2px 2px 2px #171717",
      fontSize: "1.6rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "2rem",
      lineHeight: "2.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-1px",
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    fontWeight: 500,
    textShadow: "-1px 1px 1px #171717",
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontWeight: 600,
      textShadow: "-2px 2px 2px #171717",
      letterSpacing: "-2px",
      fontSize: "2.2rem",
      lineHeight: "2rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "3.4rem",
      lineHeight: "3rem"
    }
  },
  chevron: {
    // @ts-ignore
    color: theme.palette.common.lightgray,
    cursor: "pointer",
    padding: "10px",
    fontSize: "1.4rem",
    borderRadius: "50%",
    backgroundColor: "rgba(2,2,2,0.5)",
    "&:hover": {
      color: theme.palette.common.white
    }
  },
  desktopCarouselMerchItemContainer: {
    height: "600px",
    display: "block",
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  desktopCarouselMerchItemImageContainer: {
    height: "500px",
    display: "block",
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  desktopCarouselMerchItemDetailsContainer: {
    maxHeight: "200px",
    marginTop: theme.spacing(1),
    textAlign: "left",
    borderTop: "1px solid #585858",
    paddingTop: theme.spacing(1)
  },

  desktopCarouselMerchItemContainerBottomGradient: {
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(23,23,23,1) 250%)",

    height: "300px",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "500px"
    }
  },

  desktopCarouselItemDetails: {
    position: "absolute",
    bottom: "20px",
    left: "15px",
    right: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "1rem"
  },
  desktopCarouselMerchItemTitle: {
    fontSize: "2.2rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(0.5),
    fontWeight: 500,
    letterSpacing: "-1px",
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },
  desktopCarouselMerchItemPrice: {
    fontSize: "1.1rem",
    textShadow: "-2px 2px 2px #171717",
    fontWeigth: "400",
    color: "#c5c5c5"
  },

  mobileTabletContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    overflow: "auto",
    whiteSpace: "nowrap",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  mobileTabletContainerBottomGradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 40%, rgba(23,23,23,0.9) 200%)",

    height: "160px",
    width: "100%",
    borderColor: "white",
    borderWidth: 1
  },
  mobileTabletMerchItem: {
    width: "160px",
    height: "auto",
    position: "relative",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    verticalAlign: "top",
    marginRight: theme.spacing(1),
    whiteSpace: "initial",
    padding: 0,
    margin: 0
  },
  mobileTabletMerchItemImageContainer: {
    position: "relative"
  },
  mobileTabletMerchItemImage: {
    width: "160px",
    height: "auto",
    aspectRatio: "9/16"
  },
  mobileTabletMerchItemDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    textAlign: "left",
    borderTop: "1px solid #585858",
    paddingTop: theme.spacing(0.5)
  },
  mobileTabletMerchItemTitle: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0),
    alignSelf: "left",
    textAlign: "left",
    fontSize: "1.1rem",
    paddingRight: "10px",
    fontWeight: 500,
    letterSpacing: "-1px",
    // @ts-ignore
    color: theme.palette.common.upgrade,
    textShadow: "-1px 1px 1px #171717",
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.1rem"
    }
  },
  mobileTabletMerchPrice: {
    marginTop: theme.spacing(0),
    fontSize: "1rem",
    marginBottom: "2px",
    paddingRight: "10px",
    // @ts-ignore
    color: "#c5c5c5",
    textShadow: "-1px 1px 1px #171717",
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.4rem"
    }
  },
  promoTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.common.white,
    fontWeight: 600,
    alignSelf: "center",
    textAlign: "center"
  },
  promoSubtitle: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    color: theme.palette.common.white,
    fontWeight: 200,
    alignSelf: "left",
    textAlign: "left"
  },
  shopButton: {
    marginTop: theme.spacing(4),
    width: "90%",
    fontSize: "1.1rem",
    padding: theme.spacing(1.6),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      padding: theme.spacing(2),
      fontSize: "1.2rem",
      width: "300px"
    },
    "@media (min-width:960px)": {
      width: "300px"
    }
  }
}));

const VerticalPoster = ({ promo }: Pick<CatalogOverviewResponse, "promo">) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const chevronWidth = 0;

  const classes = useStyles();

  if (!promo || !promo.items || promo.items.length <= 1) {
    return null;
  }

  const onClickPromoItem = (item: PromoItem) => {
    try {
      logEvent(getAnalytics(firebaseApp), item.clickEvent, {
        id: item.id,
        title: item.title,
        url: item.clickUrl
      });
    } catch (e) {
      console.error("Error logging merch click to firebase");
    }
  };

  return (
    <>
      <Container>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.title}>
              Grab some
            </Typography>
            <Typography variant="h2" className={classes.titleLarge}>
              Merchandise
            </Typography>
            <>
              {/* Desktop */}
              <Hidden only={["xs", "sm"]}>
                <ItemsCarousel
                  requestToChangeActive={setActiveItemIndex}
                  activeItemIndex={activeItemIndex}
                  numberOfCards={4}
                  slidesToScroll={1}
                  gutter={20}
                  leftChevron={
                    <div>
                      <ArrowBackIosRoundedIcon className={classes.chevron} />
                    </div>
                  }
                  rightChevron={
                    <div>
                      <ArrowForwardIosRoundedIcon className={classes.chevron} />
                    </div>
                  }
                  chevronWidth={chevronWidth}
                >
                  {promo.items.map((promoItem, ind) => (
                    <a
                      href={promoItem.clickUrl}
                      rel="noreferrer"
                      target="_blank"
                      onClick={() => onClickPromoItem(promoItem)}
                      key={ind}
                      style={{ textDecoration: "none" }}
                    >
                      <Box
                        className={classes.desktopCarouselMerchItemContainer}
                      >
                        <Box
                          className={
                            classes.desktopCarouselMerchItemImageContainer
                          }
                          style={{
                            backgroundImage: `url(${promoItem.posterMediaUrl})`
                          }}
                        >
                          <div
                            className={
                              classes.desktopCarouselMerchItemContainerBottomGradient
                            }
                          ></div>
                        </Box>
                        <Box
                          className={
                            classes.desktopCarouselMerchItemDetailsContainer
                          }
                        >
                          <Typography
                            className={classes.desktopCarouselMerchItemTitle}
                            variant="h4"
                          >
                            {promoItem.title}
                          </Typography>
                          {promoItem.subtitle ? (
                            <Typography
                              className={classes.desktopCarouselMerchItemPrice}
                            >
                              {promoItem.subtitle}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                    </a>
                  ))}
                </ItemsCarousel>
              </Hidden>
              {/* Mobile */}
              <Hidden only={["md", "lg", "xl"]}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={classes.mobileTabletContainer}
                >
                  {promo.items.map((promoItem, ind) => {
                    return (
                      <a
                        key={ind}
                        href={promoItem.clickUrl}
                        onClick={() => onClickPromoItem(promoItem)}
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Box
                          key={ind}
                          className={classes.mobileTabletMerchItem}
                        >
                          <Box
                            className={
                              classes.mobileTabletMerchItemImageContainer
                            }
                          >
                            <img
                              src={promoItem.posterMediaUrl}
                              className={classes.mobileTabletMerchItemImage}
                              alt={promoItem.title}
                            />
                            <div
                              className={
                                classes.mobileTabletContainerBottomGradient
                              }
                            ></div>
                          </Box>
                          <Box className={classes.mobileTabletMerchItemDetails}>
                            <Typography
                              className={classes.mobileTabletMerchItemTitle}
                              variant="h4"
                            >
                              {promoItem.title}
                            </Typography>
                            {promoItem.subtitle ? (
                              <Typography
                                className={classes.mobileTabletMerchPrice}
                              >
                                {promoItem.subtitle}
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>
                      </a>
                    );
                  })}
                </Grid>
              </Hidden>
            </>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <a
              href="https://shop.farmflix.tv"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button className={classes.shopButton}>Go To Shop</Button>
            </a>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default VerticalPoster;
