import React, { useState, useContext } from "react";
import { AccountContext } from "../../store/AccountContext";
import {
  Grid,
  Typography,
  Button,
  Box,
  Divider,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, Redirect } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { useToasts } from "react-toast-notifications";

import CircularProgress from "@material-ui/core/CircularProgress";

import { Field, Form } from "react-final-form";

import {
  composeValidators,
  isEmail,
  isEmailRequired
} from "../../utils/validators";
import { DuplicateEmailError } from "../../api/errors";
import { moveEmail } from "../../api/client";
import CodeForm from "../common/CodeForm";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  codeFormContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-end",
    marginTop: 0
  },
  bodyText: {
    // @ts-ignore
    color: theme.palette.background.ffNavy
  },
  bodyTextSml: {
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    "& span": {
      fontWeight: 500
    }
  },
  formContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  inputField: {
    "& input": {
      padding: "12px",
      border: "1px solid #a5a5a5",
      backgroundColor: "#f7f7f7",
      margin: "4px 0 10px 0",
      fontSize: "16px",
      borderRadius: "5px"
    },
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem",
      fontWeight: "500"
    }
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "5% 0 2% 0"
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    // @ts-ignore
    color: theme.palette.background.ffNavy,
    "&:hover": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  },
  MuiInput: {
    input: {
      padding: "12px",
      border: "1px solid #a5a5a5",
      backgroundColor: "#f7f7f7",
      margin: "4px 0 15px 0",
      fontSize: "16px",
      borderRadius: "5px"
    }
  },
  submit: {
    alignSelf: "flex-end",
    width: "fit-content"
  }
}));

export default function ChangeEmail() {
  const classes = useStyles();
  const { addToast } = useToasts();
  const [moving, setMoving] = useState<boolean>();
  const [movingError, setMovingError] = useState<string>();
  const [duplicateEmail, setDuplicateEmail] = useState<string>();
  const [duplicateEmailCodeSent, setDuplicateEmailCodeSent] = useState<boolean>(
    false
  );
  const [updated, setUpdated] = useState(false);

  // Account Context
  const {
    updating,
    userProfile,
    update,
    updateError,
    validateLogin
  } = useContext(AccountContext);

  // State
  const [emailNameSubmitted, setEmailSubmitted] = useState(false);

  const onSubmitEmail = async ({ email }: { email: string }) => {
    setEmailSubmitted(true);
    try {
      const updateEmail = await update({ email });
      if (updateEmail) {
        await validateLogin();
        addToast("Email successfully updated.", {
          appearance: "success"
        });
        setUpdated(true);
      }
    } catch (e) {
      if (e instanceof DuplicateEmailError) {
        setDuplicateEmail(email);
      }
    }
  };

  const initialiseMoveEmail = () => {
    if (moving || !duplicateEmail || duplicateEmailCodeSent) return;

    setMoving(true);
    setMovingError(undefined);

    moveEmail(duplicateEmail)
      .then(() => {
        setDuplicateEmailCodeSent(true);
      })
      .catch(e => {
        setMovingError(e.toString());
      })
      .finally(() => setMoving(false));
  };

  const onSubmitVerificationCode = (verificationCode: string) => {
    if (moving || !duplicateEmail || !duplicateEmailCodeSent) return;

    setMoving(true);
    setMovingError(undefined);

    moveEmail(duplicateEmail, verificationCode)
      .then(({ moved }) => {
        if (!moved) {
          throw new Error("Invalid verification code");
        }

        return validateLogin().then(() => {
          addToast("Email successfully updated.", {
            appearance: "success"
          });
          setUpdated(true);
        });
      })
      .catch(e => {
        setMovingError(e.toString());
      })
      .finally(() => setMoving(false));
  };

  if (updated) {
    return <Redirect to="/myaccount/overview" />;
  }

  if (!userProfile) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumbContainer}
        >
          <Link className={classes.activeBreadcrumb} to="/myaccount/overview">
            Overview
          </Link>
          <Typography className={classes.disabledBreadcrumb}>
            Change Email Address
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h2" className={classes.myAccountSubTitle}>
          Change Email Address
        </Typography>

        {!duplicateEmailCodeSent && (
          <Typography variant="body1" className={classes.bodyText}>
            Provide your new email address here.
          </Typography>
        )}
      </Grid>
      <Grid container className={classes.formContainer}>
        <Grid item xs={12} sm={8} md={7}>
          {updateError && emailNameSubmitted && (
            <Alert
              severity="error"
              variant="filled"
              style={{ marginBottom: "0.5rem" }}
            >
              {updateError}
            </Alert>
          )}
          <Form
            onSubmit={onSubmitEmail}
            initialValues={{
              email: userProfile.email
            }}
          >
            {props => (
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  {!duplicateEmailCodeSent && (
                    <Grid item xs={12} sm={12}>
                      <Field
                        name="email"
                        validate={composeValidators(isEmailRequired, isEmail)}
                      >
                        {({ input, meta }) => (
                          <TextField
                            fullWidth
                            required
                            {...input}
                            disabled={updating || !!duplicateEmail}
                            type="email"
                            className={classes.inputField}
                            placeholder="Email"
                            error={meta.error && meta.touched}
                            helperText={meta.error}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      </Field>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {movingError && (
                      <Alert
                        severity="error"
                        variant="filled"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        {movingError}
                      </Alert>
                    )}

                    {updating || moving ? (
                      <Box
                        m={5}
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <CircularProgress
                          style={{ alignSelf: "center" }}
                          variant="indeterminate"
                        />
                        <Typography
                          variant="body2"
                          style={{ alignSelf: "center" }}
                          className={classes.bodyTextSml}
                        >
                          {moving ? "Moving" : "Updating"} your Email. Please do
                          not refresh your browser.
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {duplicateEmail && !duplicateEmailCodeSent && (
                          <Alert
                            severity="info"
                            variant="filled"
                            style={{ marginBottom: "0.5rem" }}
                            action={
                              <Button
                                size="small"
                                onClick={initialiseMoveEmail}
                              >
                                Move Email
                              </Button>
                            }
                          >
                            We have found this email address registered against
                            another account in our system. You can move this
                            email address into the current account by simply
                            clicking <b>Move Email</b>
                          </Alert>
                        )}

                        {duplicateEmail && duplicateEmailCodeSent && (
                          <Box m={5} alignSelf="center">
                            <Typography
                              variant="body2"
                              className={classes.bodyTextSml}
                            >
                              Please check your email inbox, we have sent you an
                              email containing a 6 digit code which you can
                              input below to confirm your email address.
                              <br />
                              You will not be able to login with this email
                              address until it has been confirmed.
                            </Typography>
                            <CodeForm
                              onSubmit={onSubmitVerificationCode}
                              submitButtonText="Confirm Email Address"
                              descText={undefined}
                              textClassName={classes.bodyText}
                              containerClassName={classes.codeFormContainer}
                              submitClassName={classes.submit}
                            />
                          </Box>
                        )}

                        {!duplicateEmail && (
                          <Box
                            display="flex"
                            flex={1}
                            justifyContent="flex-end"
                            flexDirection="row"
                          >
                            <Button
                              className={classes.submit}
                              type="submit"
                              disabled={updating}
                            >
                              Update Email
                            </Button>
                          </Box>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
          <Divider className={classes.footerDivider} />
          <Typography variant="body1" className={classes.bodyTextSml}>
            <span>Tip</span> - This is your email you use to log into your
            FarmFLiX Account.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
