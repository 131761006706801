import React, {
  createContext,
  useCallback,
  useContext,
  useEffect
} from "react";
import {
  API_EVENT_AUTH_REFRESH,
  apiEventEmitter,
  sendLogs
} from "../api/client";
import {
  AccountContext,
  LOGIN_EVENT,
  loginEventEmitter
} from "./AccountContext";
import Log from "../api/entities/Log";
import SendLogsRequest from "../api/requests/SendLogsRequest";
import { setInterval } from "timers";
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  setUserId as setUserIdFirebase,
  setUserProperties as setUserPropertiesFirebase,
  logEvent as logEventFirebase
} from "firebase/analytics";
import ReactPixel from "react-facebook-pixel";
import { MemberStatus, Product, Video } from "@booyaltd/core";
import Timeout = NodeJS.Timeout;

export type METRIC_EVENT =
  | {
      type: "login";
      payload: {
        method: string;
        subscriptionStatus: MemberStatus;
      };
    }
  | {
      type: "sign_up";
      payload: { source: string };
    }
  | {
      type: "purchase";
      payload: { paymentId: string; userId: number } & Pick<
        Product,
        | "id"
        | "name"
        | "cost"
        | "memberStatusId"
        | "currency"
        | "stripeReference"
      >;
    }
  | {
      type: "free-trial";
      payload: {};
    }
  | {
      type: "video_view";
      payload: Pick<Video, "id" | "name" | "tags" | "softLinks">;
    }
  | {
      type: "video_watched";
      payload: Pick<Video, "id" | "name" | "tags" | "softLinks">;
    };

const firebaseConfig = {
  apiKey: "AIzaSyA-dHfnE9HFQ3q2G_MAR_DfDajhk6rzVGY",
  authDomain: "farmflix-1a5bf.firebaseapp.com",
  databaseURL: "https://farmflix-1a5bf.firebaseio.com",
  projectId: "farmflix-1a5bf",
  storageBucket: "farmflix-1a5bf.appspot.com",
  messagingSenderId: "748754689061",
  appId: "1:748754689061:web:972308ebbc34e44f7db3cc",
  measurementId: "G-CBWNS5Y3RN"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

const LoggerContext = createContext({
  logEvent: (log: Log) => {},
  logScreenView: (path: string) => {},
  logMetricEvent: (event: METRIC_EVENT) => {}
});

let lastLogFlush = 0;
let flushTimeout: Timeout | undefined = undefined;

const LoggerProvider = (props: any) => {
  const [initialized, setInitialized] = React.useState(false);

  const { userProfile } = useContext(AccountContext);

  const initialise = useCallback(() => {
    if (initialized) {
      return;
    }

    ReactPixel.init("4259638347489125", undefined, {
      autoConfig: true,
      debug: true
    });
    setInitialized(true);
  }, [initialized]);

  const flushLogs = useCallback(() => {
    const now = Date.now();
    if (lastLogFlush > now - 30000) {
      return;
    }

    lastLogFlush = now;

    const keys = Object.keys(localStorage).filter(key =>
      key.includes("ff-log-")
    );

    if (!keys.length) {
      return;
    }

    const logs = keys
      .map(key => localStorage.getItem(key))
      .filter(val => val != null)
      .map(val => JSON.parse(JSON.stringify(val) as string) as Log);

    if (!logs || logs.length === 0) {
      return;
    }

    sendLogs({ logs } as SendLogsRequest)
      .catch(e => {
        console.error("Error sending log events");
        console.log(e);
      })
      .finally(() => {
        keys.forEach(key => localStorage.removeItem(key));
      });
  }, []);

  const logEvent = useCallback(
    async (log: Log) => {
      const timestamp = Date.now();

      const logWithTimestampAndUserId = {
        userId: userProfile?.id,
        category: log.category,
        event: log.event,
        data: log.data,
        timestamp: new Date()
      } as Log;

      localStorage.setItem(
        `ff-log-${timestamp}-${log.event}`,
        JSON.stringify(logWithTimestampAndUserId)
      );
    },
    [userProfile]
  );

  const logScreenView = useCallback(
    (path: string) => {
      console.log("Logging screen view", path);
      initialise();

      logEventFirebase(getAnalytics(firebaseApp), "page_view", {
        page_path: path
      });
      ReactPixel.pageView();
    },
    [initialise]
  );

  const logMetricEvent = useCallback(
    (event: METRIC_EVENT) => {
      console.log("Logging Metric Event", event.type, event.payload);
      initialise();

      switch (event.type) {
        case "login":
          const { method, subscriptionStatus } = event.payload;
          logEventFirebase(getAnalytics(firebaseApp), "login", {
            method,
            subscriptionStatus
          });
          ReactPixel.trackCustom("login", { method, subscriptionStatus });
          break;

        case "sign_up":
          logEventFirebase(getAnalytics(firebaseApp), "sign_up", {
            source: event.payload.source
          });
          ReactPixel.trackCustom("sign_up");
          break;

        case "purchase":
          const {
            paymentId,
            id,
            name,
            currency,
            cost,
            memberStatusId
          } = event.payload;
          logEventFirebase(getAnalytics(firebaseApp), "purchase", {
            transaction_id: paymentId,
            value: cost,
            currency: (currency || "gbp").toLowerCase(),
            items: [{ item_id: id, item_name: name, price: cost, quantity: 1 }]
          });
          ReactPixel.track("Purchase", {
            currency,
            value: cost,
            content_category: memberStatusId,
            content_name: name,
            content_ids: [id]
          });
          break;

        case "free-trial":
          logEventFirebase(getAnalytics(firebaseApp), "free_trial");
          ReactPixel.track("free_trial");
          break;

        case "video_view":
          logEventFirebase(getAnalytics(firebaseApp), "video_view", {
            ...event.payload,
            tags:
              event.payload.tags && Array.isArray(event.payload.tags)
                ? event.payload.tags.join(",")
                : "",
            softLinks:
              event.payload.softLinks && Array.isArray(event.payload.softLinks)
                ? event.payload.softLinks.join(",")
                : ""
          });
          ReactPixel.track("video_view", {
            content_name: event.payload.name,
            content_ids: [event.payload.id]
          });
          break;

        case "video_watched":
          logEventFirebase(getAnalytics(firebaseApp), "video_watched", {
            ...event.payload,
            tags:
              event.payload.tags && Array.isArray(event.payload.tags)
                ? event.payload.tags.join(",")
                : "",
            softLinks:
              event.payload.softLinks && Array.isArray(event.payload.softLinks)
                ? event.payload.softLinks.join(",")
                : ""
          });
          ReactPixel.track("video_watched", {
            content_name: event.payload.name,
            content_ids: [event.payload.id]
          });
          break;
      }
    },
    [initialise]
  );

  const onAuthRefresh = useCallback(() => {
    if (userProfile) {
      logMetricEvent({
        type: "login",
        payload: {
          method: "refresh",
          subscriptionStatus: (userProfile.memberStatus as unknown) as MemberStatus
        }
      });
    }
  }, [userProfile, logMetricEvent]);

  const onLoginEvent = useCallback(
    (method: string) => {
      if (userProfile) {
        logMetricEvent({
          type: "login",
          payload: {
            method: method,
            subscriptionStatus: (userProfile.memberStatus as unknown) as MemberStatus
          }
        });
      }
    },
    [userProfile, logMetricEvent]
  );

  useEffect(() => {
    initialise();
  }, [initialise]);

  useEffect(() => {
    apiEventEmitter.on(API_EVENT_AUTH_REFRESH, onAuthRefresh);

    return () => {
      apiEventEmitter.off(API_EVENT_AUTH_REFRESH, onAuthRefresh);
    };
  }, [onAuthRefresh]);

  useEffect(() => {
    loginEventEmitter.on(LOGIN_EVENT, onLoginEvent);

    return () => {
      loginEventEmitter.off(LOGIN_EVENT, onLoginEvent);
    };
  }, [onLoginEvent]);

  useEffect(() => {
    if (flushTimeout) {
      console.log("Clearing Flush Interval");
      clearTimeout(flushTimeout);
    }

    if (userProfile) {
      console.log("Registering Flush Interval");
      setUserIdFirebase(getAnalytics(firebaseApp), userProfile.id.toString());
      setUserPropertiesFirebase(getAnalytics(firebaseApp), {
        firstName: userProfile.firstName || "",
        lastName: userProfile.lastName || "",
        email: userProfile.email || "",
        countryCode: userProfile.countryCode || "",
        phoneNumber: userProfile.phoneNumber || "",
        subscriptionStatus: userProfile.memberStatus || ""
      });

      flushTimeout = setInterval(flushLogs, 5000);
    }

    return () => {
      if (flushTimeout) {
        console.log("Clearing Flush Interval");
        clearTimeout(flushTimeout);
      }
    };
  }, [flushLogs, userProfile]);

  return (
    <LoggerContext.Provider value={{ logEvent, logScreenView, logMetricEvent }}>
      {props.children}
    </LoggerContext.Provider>
  );
};

export { LoggerContext, LoggerProvider };
