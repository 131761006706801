import React from "react";
import { ComputedCompetitor } from "./types";
import TableContainer from "@material-ui/core/TableContainer";
import { Box, Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { RunTime } from "./RunTime";
import { buildStatusString } from "./utils";

export type CompetitorLeaderboardProps = {
  competitors: ComputedCompetitor[];
};

export const CompetitorLeaderboard = ({
  competitors
}: CompetitorLeaderboardProps) => {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Pos.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Tractor</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Run 1</TableCell>
              <TableCell>Run 2</TableCell>
              <TableCell>Semi</TableCell>
              <TableCell>Final</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {competitors.map((competitor, idx) => (
              <TableRow key={competitor.id}>
                <TableCell>{competitor.run1Time ? idx + 1 : ""}</TableCell>
                <TableCell component="th" scope="row">
                  {competitor.firstName} {competitor.lastName}
                </TableCell>
                <TableCell>
                  {competitor.tractorMake} {competitor.tractorModel}
                </TableCell>
                <TableCell>
                  {buildStatusString(competitor.status)?.message}
                </TableCell>
                <TableCell>
                  <RunTime
                    runTime={competitor.run1Time}
                    penalty={competitor.run1Penalty}
                  />
                </TableCell>
                <TableCell>
                  <RunTime
                    runTime={competitor.run2Time}
                    penalty={competitor.run2Penalty}
                  />
                </TableCell>
                <TableCell>
                  <RunTime
                    runTime={competitor.semiTime}
                    penalty={competitor.semiPenalty}
                  />
                </TableCell>

                <TableCell>
                  <RunTime
                    runTime={competitor.finalTime}
                    penalty={competitor.finalPenalty}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
