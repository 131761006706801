import { createMuiTheme } from "@material-ui/core";

const muiTheme = createMuiTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      gray: "#eee",
      darkgray: "#bbb",
      lightgray: "#e9e9e9",
      dark: "#171717",
      upgrade: "#f1f1f1"
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
      ffGreen: "#99ca3c",
      ffNavy: "#171717",
      ffNavyLighter: "#292929",
      ffNavyLight: "#202020",
      agLife: "#2F5FB0",
      navy: "#263238",
      dark: "#171717",
      upgrade: "#3aa9de",
      upgradeHover: "#3cb5ef",
      upgradeAlt: "#797979",
      upgradeBG: "#585858"
    },
    primary: {
      light: "rgba(207, 232, 65, 1)",
      main: "#99ca3c",
      dark: "#ffa400",
      contrastText: "rgba(23, 3, 3, 1)"
    },
    secondary: {
      light: "rgba(83, 187, 243, 1)",
      main: "rgba(0, 160, 245, 1)",
      dark: "rgba(17, 133, 194, 1)",
      contrastText: "#fff"
    },
    error: {
      light: "#e57373",
      main: "#c53737",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  },
  spacing: 8,
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "2rem",
      fontWeight: "500",
      color: "#ffffff",
      "@media (min-width:600px)": {
        fontSize: "2.8rem",
        lineHeight: "3rem"
      },
      "@media (min-width:960px)": {
        fontSize: "3.4rem",
        lineHeight: "3.6rem"
      }
    },
    h2: {
      fontSize: "2.4rem",
      fontWeight: "500",
      color: "#ffffff"
    },
    h3: {
      fontSize: "1.6rem",
      fontWeight: "500",
      color: "#99ca3c",
      "@media (min-width:600px)": {
        fontSize: "1.6rem",
        lineHeight: "1.7rem"
      },
      "@media (min-width:960px)": {
        fontSize: "1.8rem",
        lineHeight: "2rem"
      }
    },
    h4: {
      fontSize: "1.2rem",
      lineHeight: "1.3rem",
      color: "#fff",
      "@media (min-width:600px)": {
        fontSize: "1.4rem",
        lineHeight: "1.5rem"
      },
      "@media (min-width:960px)": {
        fontSize: "1.5rem",
        lineHeight: "1.6rem"
      }
    },
    h5: {},
    h6: {},
    subtitle1: {
      fontWeight: "500",
      color: "#ffffff",
      fontSize: "1.6rem",
      lineHeight: "1.8rem",
      "@media (min-width:600px)": {
        fontSize: "1.2rem",
        lineHeight: "1.3rem"
      },
      "@media (min-width:960px)": {
        fontSize: "1.6rem",
        lineHeight: "1.7rem"
      }
    },
    subtitle2: {},
    body1: {
      color: "#e9e9e9",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.2rem"
    },
    body2: {
      color: "#e9e9e9",
      fontWeight: "400",
      fontSize: "0.875rem",
      lineHeight: "1rem"
    },
    bodysml: {
      fontSize: "0.6rem"
    },
    button: {
      backgroundColor: "#99ca3c"
    },
    a: {
      textDecoration: "none"
    }
  },
  overrides: {
    MuiButton: {
      root: {
        border: "2px solid #99ca3c",
        borderRadius: "25px",
        margin: "0 5px",
        "@media (min-width:600px)": {},
        "@media (min-width:960px)": {},
        "&:hover": {
          backgroundColor: "#a2d641",
          border: "2px solid #a2d641",
          boxShadow: "none",
          transition: "0.25s",
          "@media (hover: none)": {
            backgroundColor: "#a2d641"
          }
        }
      },
      containedSecondary: {
        backgroundColor: "#55b6e4",
        color: "#fff",
        border: "none",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#3aaee6",
          border: "none",
          boxShadow: "none",
          transition: "0.25s"
        }
      },
      text: {
        padding: "12px 20px",
        lineHeight: "1rem"
      },
      outlined: {
        lineHeight: "1rem",
        padding: "12px 20px",
        backgroundColor: "transparent",
        border: "2px solid rgba(0, 0, 0, 0.87)",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          border: "2px solid rgba(0, 0, 0, 0.87)",
          color: "rgba(0, 0, 0, 0.87)",
          boxShadow: "none",
          transition: "0.25s"
        }
      },
      sizeSmall: {
        padding: "6px 20px"
      },
      sizeLarge: {
        padding: "12px 40px",
        fontSize: "1rem",
        margin: "10px 0 0 0",
        "@media (min-width:600px)": {
          fontSize: "1.1rem",
          lineHeight: "1.3rem"
        },
        "@media (min-width:960px)": {
          fontSize: "1.1rem"
        }
      }
    },
    MuiDialog: {
      root: {
        // zIndex: "9999999 !important"
      }
    },
    MuiDivider: {
      root: {
        height: "5px",
        backgroundColor: "#202020"
      }
    },
    MuiAppBar: {
      root: {
        backgroundColor: "#171717"
      }
    },
    MuiChip: {
      root: {
        backgroundColor: "#444444",
        color: "#9e9e9e"
      }
    },
    MuiMenu: {
      paper: {
        backgroundColor: "#171717",
        color: "#fff"
      }
    },
    MuiList: {
      padding: "0"
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: "#444444"
        }
      }
    },
    MuiTableCell: {
      root: {
        fontWeight: "400",
        padding: "12px"
      }
    },
    MuiFormControl: {
      root: {
        "& label": {
          color: "#171717",
          fontWeight: 500,
          fontSize: "1.2rem",
          width: "100%"
        }
      }
    },
    MuiFormHelperText: {
      root: {
        color: "#99ca3c",
        "&:focus": {
          outline: "none"
        },
        "&:hover": {
          outline: "none"
        }
      }
    },
    MuiStepper: {
      root: {
        padding: 0,
        "@media (min-width:600px)": {
          padding: "24px 0"
        },
        "@media (min-width:960px)": {
          padding: "24px 0"
        }
      }
    },
    MuiStepIcon: {
      root: {
        color: "#a0a0a0",
        fontSize: "1.6rem",
        "@media (min-width:600px)": {
          fontSize: "1.6rem"
        },
        "@media (min-width:960px)": {
          fontSize: "1.6rem"
        }
      },
      active: {
        color: "#99ca3c !important"
      },
      text: {
        fill: "#fff"
      }
    },
    MuiTooltip: {
      popper: {
        zIndex: 1100
      }
    },
    MuiStepLabel: {
      label: {
        color: "#171717",
        fontSize: "0.8rem",
        lineHeight: "0",
        "@media (min-width:600px)": {
          fontSize: "1rem",
          lineHeight: "0"
        },
        "@media (min-width:960px)": {
          fontSize: "1rem",
          lineHeight: "0"
        }
      }
    },
    MuiFormLabel: {
      root: {
        lineHeight: 0.5,
        color: "#171717",
        fontWeight: 500,
        fontSize: "1.1rem"
      }
    },
    MuiInput: {
      underline: {
        borderBottom: "none",
        "&:before": {
          borderBottom: "none",
          content: "",
          "&:hover": {
            borderBottom: "none",
            outline: "none"
          }
        },
        "&:after": {
          borderBottom: "none",
          "&:hover": {
            borderBottom: "none",
            outline: "none"
          }
        },
        "&&&&:hover:before": {
          borderBottom: "none",
          outline: "none"
        }
      }
    },
    MuiSelect: {
      root: {
        marginTop: 0
      }
    },
    MuiInputBase: {
      input: {
        marginTop: "6px",
        fontSize: "0.9rem",
        lineHeight: "1.2rem",
        // background:"#eee",
        padding: "14px 10px 12px 10px",
        // borderBottom: "none",
        border: "1px solid #a5a5a5",
        borderRadius: "5px",
        "&:hover": {
          // borderBottom: "none"
        },
        "&:focus": {
          // borderBottom: "none"
          border: "1px solid #171717"
        }
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "13.5px 14px",
        fontSize: "0.9rem"
      },
      root: {
        "&.Mui-error": {
          borderColor: "#c53737 !important"
        }
      }
    },
    MuiAlert: {
      message: {
        padding: "10px 0"
      }
    }

    // MuiStepIcon: {
    //   root: {

    //   },
    //   active: {
    //     color: "red"
    //   }
    // }
  }
});

export default muiTheme;
