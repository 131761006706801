import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid, Button } from "@material-ui/core";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    position: "relative",
    padding: theme.spacing(3),
    "@media (min-width:600px)": {
      padding: theme.spacing(4, 4)
    }
  },
  title: {
    letterSpacing: "-1px",
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "2rem",
      lineHeight: "2rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "2rem",
      lineHeight: "2rem"
    }
  },
  subTitle: {
    letterSpacing: "-1px",
    fontSize: "1.3rem",
    lineHeight: "1.1rem",
    fontWeight: 600,
    marginTop: "12px",
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      marginTop: "6px",
      fontSize: "1.8rem",
      lineHeight: "1.8rem"
    },
    "@media (min-width:1280px)": {
      letterSpacing: "-2px",
      fontSize: "1.8rem",
      lineHeight: "1.8rem"
    },
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    }
  },
  desc: {
    fontWeight: 400,
    color: "#ddd",
    fontSize: "1rem",
    lineHeight: "1rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.1rem",
      lineHeight: "1.3rem"
    }
  },
  moreWrapper: {
    "@media (min-width:600px)": {
      display: "flex",
      justifyContent: "flex-end",
      alignContent: "flex-end",
      alignItems: "flex-end",
      paddingBottom: theme.spacing(1)
    }
  },
  moreButton: {
    margin: 0,
    height: "42px",
    marginTop: theme.spacing(1),
    width: "100%",
    fontSize: "1rem",
    padding: theme.spacing(2),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(0),
      width: "auto",
      height: "48px",
      fontSize: "1rem",
      padding: theme.spacing(2, 6)
    },
    "@media (min-width:960px)": {
      fontSize: "1.1rem"
    }
  },
  bannerCloseIcon: {
    position: "absolute",
    right: 15,
    top: 15,
    color: theme.palette.common.white,
    width: "1em",
    height: "1em",
    "&:hover": {
      // @ts-ignore
      color: theme.palette.background.ffGreen,
      cursor: "pointer"
    },
    "@media (min-width:600px)": {
      width: "0.9em",
      height: "0.9em",
      right: 15,
      top: 14
    }
  }
}));

const Survey = ({
  surveyLink,
  fullWidth
}: {
  surveyLink: string;
  fullWidth: boolean;
}) => {
  const classes = useStyles();

  const [surveyOpen, setSurveyOpen] = useState(false);

  setTimeout(() => {
    if (localStorage.getItem("dismissSurveySection") !== surveyLink) {
      setSurveyOpen(true);
    } else {
      setSurveyOpen(false);
      return null;
    }
  }, 1000);

  return (
    <>
      {surveyOpen && (
        <>
          <div className={fullWidth ? classes.background : ""}>
            <Container style={{ padding: fullWidth ? 0 : "" }}>
              <Grid container className={!fullWidth ? classes.background : ""}>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="h2" className={classes.title}>
                    Member Survey {dayjs().year()}
                  </Typography>
                  <Typography variant="h2" className={classes.subTitle}>
                    This is the chance to have your say
                  </Typography>
                  <Typography variant="body1" className={classes.desc}>
                    Let us know what you like, dislike, and want to see more of.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  className={classes.moreWrapper}
                >
                  <Link to={"member-survey"} style={{ textDecoration: "none" }}>
                    <Button className={classes.moreButton}>Open Survey</Button>
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default Survey;
