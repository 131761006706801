import React, { useContext } from "react";
import { IconButton } from "@material-ui/core";
import { SearchContext } from "../SearchContainer";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "../styles";

const ShareModalIconButton = () => {
  const { closeSearch } = useContext(SearchContext);
  const classes = useStyles();

  return (
    <IconButton
      className={classes.searchDialogCloseButton}
      onClick={() => closeSearch()}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default ShareModalIconButton;
