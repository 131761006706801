import React, { useCallback, useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import FreeTrialLargeBanner from "./Banners/FreeTrialLarge";
import LoggedInLayout from "../layouts/LoggedInLayout";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { OverviewContext } from "../store/OverviewContext";
import { PlayerContext } from "../store/PlayerContext";
import { AccountContext } from "../store/AccountContext";
import useLogger from "../hooks/useLogger";
import { LogEventCategory, PlaybackEvents } from "../api/entities/Log";
import { Helmet } from "react-helmet";
import VideoPlayerDetails from "./Video/VideoPlayerDetails";
import PromoPoster from "./Dashboard/PromoPoster";
import { Player } from "./common/player";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0)
  },
  largeTitle: {
    // @ts-ignore
    color: theme.palette.background.lightgray,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    },
    "@media (min-width:960px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  episodeTitle: {
    // @ts-ignore
    color: theme.palette.background.upgradeAlt,
    marginBottom: theme.spacing(1),
    fontSize: "1.4rem"
  },
  bodyText: {
    color: "#bbb"
  },
  detailsContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  detailsIcon: {
    display: "inline",
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginRight: "4px",
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem"
    }
  },
  detailsText: {
    display: "inline",
    // @ts-ignore
    color: theme.palette.common.darkgray,
    marginRight: theme.spacing(2),
    fontSize: "0.9rem"
  },
  marginSpacing: {
    margin: "20px 0"
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%"
  },
  playerContainer: {},
  player: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    "& video": {
      "&:focus": {
        outline: "none"
      }
    }
  },
  playIcon: {
    fontSize: "6rem",
    "@media (min-width:600px)": {
      fontSize: "7rem"
    },
    "@media (min-width:960px)": {
      fontSize: "8rem"
    }
  },
  smlDivider: {
    height: "2px",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLight,
    width: "10%",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  upgradeBannerBackground: {
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.upgrade,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: "center"
  },
  upgradeTitle: {
    marginBottom: theme.spacing(1),
    fontSize: "1.8rem",
    fontWeight: 500,
    color: theme.palette.common.white
  },
  upgradeSubTitle: {
    marginBottom: theme.spacing(2),
    fontSize: "1.4rem",
    fontWeight: 400,
    // @ts-ignore
    color: theme.palette.background.white
  },
  upgradeBodyText: {
    marginBottom: theme.spacing(2),
    // @ts-ignore
    color: theme.palette.background.white,
    fontWeight: 300,
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    }
  },
  channelImage: {
    margin: "0 5% 0 15%",
    borderRadius: "50%",
    width: "80%",
    "@media (min-width:600px)": {
      margin: "0 5% 0 35%",
      borderRadius: "50%",
      width: "60%"
    },
    "@media (min-width:960px)": {
      margin: "0 5% 0 35%",
      borderRadius: "50%",
      width: "60%"
    }
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  relatedVideoList: {},
  relatedVideoListTitle: {
    marginBottom: "10px",
    // @ts-ignore
    color: theme.palette.common.lightgray
  },
  creditsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  creditsTitle: {
    // @ts-ignore
    color: theme.palette.background.upgradeAlt
  },
  creditsDetail: {
    color: "#c3c3c3",
    margin: "4px 0",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.upgradeAlt
    }
  },
  tagsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  tagsTitle: {
    marginBottom: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.background.upgradeAlt
  },
  legacyText: {
    fontSize: "0.8rem",
    lineHeight: "0.9rem",
    // @ts-ignore
    color: theme.palette.background.upgradeAlt
  },
  legacyLink: {
    // @ts-ignore
    color: theme.palette.background.ffGreen
  }
}));

const Video = (props: RouteComponentProps<{ id: string }>) => {
  const { overview } = useContext(OverviewContext);
  const { loading, loggedIn, userProfile } = useContext(AccountContext);
  const { logEvent } = useLogger();

  const videoId = props.match.params.id;

  const {
    resetError,
    resetVideoSearch,
    clearCurrentVideo,
    currentVideo,
    membersOnlyResponse,
    loadVideo,
    onVideoProgress
  } = useContext(PlayerContext);

  useEffect(() => {
    return () => {
      clearCurrentVideo();
      resetError();
      resetVideoSearch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  useEffect(() => {
    if (videoId && !currentVideo) {
      loadVideo(videoId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId, currentVideo]);

  const onStart = useCallback(() => {
    if (!currentVideo) {
      return;
    }

    logEvent({
      category: LogEventCategory.PLAYBACK_ACTION,
      event: PlaybackEvents.PLAYBACK_STARTED,
      data: {
        videoId: currentVideo.id,
        progress: 0,
        playbackLocation: "web"
      }
    });
  }, [currentVideo, logEvent]);

  const onProgress = useCallback(
    (progressSecs: number) => {
      onVideoProgress(progressSecs);
    },
    [onVideoProgress]
  );

  const classes = useStyles();

  if (!loading && !loggedIn) {
    return <Redirect to={"/preview/" + videoId + "-preview"} />;
  }

  const video = currentVideo ? currentVideo : membersOnlyResponse;

  return (
    <LoggedInLayout>
      <Helmet>
        <title>{video ? video.name : "FarmFLiX"}</title>
      </Helmet>
      {video && !loading && (
        <>
          <div className={classes.background}>
            <Container
              style={{ marginBottom: membersOnlyResponse ? undefined : "24px" }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  {video.sources ? (
                    <Player
                      userId={userProfile?.id || undefined}
                      type={currentVideo ? "master" : "preview"}
                      video={video}
                      onProgress={onProgress}
                      onStart={onStart}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <VideoPlayerDetails
                    video={video}
                    membersOnly={!!membersOnlyResponse}
                    legacyLink={"/legacy-videos/" + videoId}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
          {userProfile && userProfile.memberStatus === "trial" && (
            <FreeTrialLargeBanner />
          )}
          {overview && overview.promo && (
            <div className={classes.background}>
              <PromoPoster promo={overview.promo} />
            </div>
          )}
        </>
      )}
    </LoggedInLayout>
  );
};

export default Video;
