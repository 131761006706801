import React, { useContext, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Grid, Button } from "@material-ui/core";
import LoggedInLayout from "../layouts/LoggedInLayout";
import { OverviewContext } from "../store/OverviewContext";
import { PlayerContext } from "../store/PlayerContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import VideoRow from "./Video/VideoRow";

const useStyles = makeStyles(theme => ({
  backgroundSection: {
    height: "300px",
    backgroundColor: theme.palette.background.dark,
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "700px"
    }
  },
  backgroundSectionNoImage: {
    backgroundColor: theme.palette.background.dark,
    position: "relative",
    width: "100%",
    height: "auto"
  },
  backgroundSectionBottomGradient: {
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 0%, rgba(23,23,23,1) 100%)",

    height: "300px",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "700px"
    }
  },
  detailsOverlay: {
    position: "absolute",
    bottom: "20px"
  },
  playlistTitle: {
    paddingTop: theme.spacing(4),
    fontSize: "2.2rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "2.2rem",
      lineHeight: "2.4rem",
      textShadow: "-3px 3px 3px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "3.6rem",
      lineHeight: "3.8rem"
    }
  },
  playlistEpisodeCount: {
    fontWeigth: "500",
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    fontSize: "1.2rem"
  },
  playlistEpisodeListBackground: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    "@media (min-width:960px)": {
      backgroundColor: theme.palette.background.dark,
      marginTop: theme.spacing(4)
    }
  },
  playlistEpisodeListContainer: {
    backgroundColor: theme.palette.background.ffNavy,
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(0),
    "@media (min-width:960px)": {
      backgroundColor: theme.palette.background.ffNavyLighter,
      paddingTop: theme.spacing(4)
    }
  },
  playlistDescription: {
    marginBottom: theme.spacing(5),
    fontWeight: 400,
    width: "100%",
    "@media (min-width:600px)": {
      fontWeight: 500,
      width: "80%"
    }
  },
  playlistEpisodesTitle: {
    fontSize: "1.4rem",
    marginBottom: theme.spacing(2)
  },
  episodeWrapper: {
    marginBottom: theme.spacing(2)
  },
  episodeThumbnailContainer: {
    position: "relative",
    marginBottom: 0,
    paddingBottom: 0
  },
  episodeThumbnail: {
    width: "100%"
  },
  episodeThumbnailHover: {
    minHeight: "100px",
    opacity: "0.3",
    width: "100%",
    transition: "0.3s",
    backgroundSize: "cover"
  },
  episodeTitle: {
    fontWeight: 500,
    fontSize: "1.3rem",
    marginTop: 0,
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1)
    }
  },
  episodeMetaDetailsContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  episodeDetailsIcon: {
    display: "inline",
    color: theme.palette.background.ffGreen,
    marginRight: "4px",
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem"
    }
  },
  episodeDetailsText: {
    display: "inline",
    color: theme.palette.common.darkgray,
    marginRight: theme.spacing(2),
    fontSize: "0.9rem",
    lineHeight: "1.2rem"
  },
  episodeDescription: {
    color: theme.palette.common.darkgray,
    fontSize: "0.9rem",
    borderBottom: "1px solid #292929",
    paddingBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      borderBottom: "none"
    }
  },
  favouriteIconButton: {
    fontSize: "1.8rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        color: "#ff5050"
      }
    }
  },
  favouritedIconButton: {
    fontSize: "1.8rem",
    backgroundColor: "#555555",
    color: "#ff5050",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1)
  },
  playIconButton: {
    fontSize: "2.2rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        color: theme.palette.background.ffGreen
      }
    }
  },
  iconButton: {
    width: "2rem",
    height: "2rem"
  },
  "@global": {
    "@keyframes fadeIn": {
      from: {
        transform: "translate3d(0,15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    }
  },
  episodeHoverDetailsActive: {
    animation: "fadeIn .3s ease-in-out",
    position: "absolute",
    bottom: "15px",
    right: "5px"
  }
}));

const PlaylistOverview = props => {
  const classes = useStyles();
  const playlistId = props.match.params.id;

  const {
    resetError,
    resetVideoSearch,
    clearCurrentVideo,
    updateTagVideoSearch,
    triggerNextPage,
    videos
  } = useContext(PlayerContext);

  const { overview } = useContext(OverviewContext);

  useEffect(() => {
    return () => {
      clearCurrentVideo();
      resetError();
      resetVideoSearch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playlist = useMemo(
    () => overview?.playlists?.find(i => i.tag === playlistId),
    [overview, playlistId]
  );

  useEffect(() => {
    if (playlist) {
      const {
        tag,
        defaultVideoSort,
        includePreviewVideos,
        total: initialTotal,
        videos: initialVideos
      } = playlist;

      updateTagVideoSearch(
        tag,
        includePreviewVideos,
        defaultVideoSort,
        initialVideos,
        initialTotal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist]);

  const handleTrigger = () => {
    triggerNextPage();
  };

  if (!playlist) {
    return (
      <LoggedInLayout>
        <div className={classes.background} style={{ textAlign: "center" }}>
          <CircularProgress color="primary" />
        </div>
      </LoggedInLayout>
    );
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          FarmFLiX.tv |{" "}
          {overview && playlist ? playlist.name : "Unknown playlist"}
        </title>
      </Helmet>
      <LoggedInLayout>
        {overview && playlist && videos && (
          <>
            {/* New Top Background  */}
            <div
              className={
                playlist?.posterHorizontalMediaUrl
                  ? classes.backgroundSection
                  : classes.backgroundSectionNoImage
              }
              style={{
                backgroundImage: playlist?.posterHorizontalMediaUrl
                  ? `url(${playlist.posterHorizontalMediaUrl})`
                  : undefined
              }}
            >
              <div
                className={
                  playlist?.posterHorizontalMediaUrl
                    ? classes.backgroundSectionBottomGradient
                    : classes.backgroundSectionNoImage
                }
              >
                <Container>
                  <Grid container>
                    <Grid
                      item
                      xs={8}
                      sm={8}
                      className={
                        playlist?.posterHorizontalMediaUrl
                          ? classes.detailsOverlay
                          : undefined
                      }
                    >
                      {/* Handle the playlist image  */}
                      <Typography
                        className={classes.playlistTitle}
                        variant="h1"
                      >
                        {playlist.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
            <div className={classes.playlistEpisodeListBackground}>
              <Container
                maxWidth="lg"
                className={classes.playlistEpisodeListContainer}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    {playlist.description.length > 0 && (
                      <Typography
                        className={classes.playlistDescription}
                        variant="body1"
                      >
                        {playlist.description}
                      </Typography>
                    )}
                    <Typography
                      variant="body1"
                      className={classes.playlistEpisodesTitle}
                    >
                      Episodes
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {videos.map((video, idx) => (
                      <VideoRow
                        idx={idx}
                        key={video.id}
                        video={video}
                        listType={"playlist"}
                        channels={overview.channels}
                      />
                    ))}
                  </Grid>
                </Grid>
                {videos.length < playlist.total && (
                  <Grid item xs={12} sm={12} align="center">
                    <Button
                      size="large"
                      className={classes.loadMore}
                      onClick={handleTrigger}
                    >
                      Load More
                    </Button>
                  </Grid>
                )}
              </Container>
            </div>
          </>
        )}
      </LoggedInLayout>
    </div>
  );
};

export default PlaylistOverview;
