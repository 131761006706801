import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Typography,
  Button,
  Divider
} from "@material-ui/core";
import { AccountContext } from "../store/AccountContext";
import Footer from "./Footer/Footer";
import HeaderInternal from "./Header/HeaderInternal";
import Header from "./Header/Header";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import { ChevronRightOutlined } from "@material-ui/icons";

const listings = [
  {
    title: "Videographer",
    link:
      "https://uk.indeed.com/cmp/Booya-Ltd-(farmflix)/jobs?jk=fafb8be601aae560&start=0"
  },
  {
    title: "Agricultural Presenter",
    link:
      "https://uk.indeed.com/cmp/Booya-Ltd-(farmflix)/jobs?jk=c6102b2ee486a9b0&start=0"
  }
];

const video = "https://youtube.com/embed/WCjIR8totxI";

const useStyles = makeStyles(theme => ({
  mainTitle: {
    color: theme.palette.common.white,
    fontSize: "3rem",
    lineHeight: "3.2rem",
    fontWeight: 600,
    letterSpacing: -1,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    textAlign: "center",
    "& span": {
      color: theme.palette.background.ffGreen,
      fontWeight: 600
    },
    "@media (min-width:600px)": {
      letterSpacing: -1,
      marginTop: theme.spacing(2),
      fontSize: "3.2rem",
      lineHeight: "3.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "5.2rem",
      lineHeight: "5rem"
    }
  },
  subTitle: {
    color: theme.palette.background.lightgray,
    fontSize: "1.2rem",
    lineHeight: "1.3rem",

    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    textAlign: "center",
    "& span": {
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      letterSpacing: -1,
      fontSize: "1.6rem",
      lineHeight: "1.8rem",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    }
  },
  secondHeading: {
    color: theme.palette.common.white,
    fontSize: "2rem",
    lineHeight: "2.2rem",
    fontWeight: 600,
    letterSpacing: -1,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    textAlign: "center",
    "& span": {
      color: theme.palette.background.ffGreen,
      fontWeight: 600
    },
    "@media (min-width:600px)": {
      letterSpacing: -1,
      marginTop: theme.spacing(2),
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "4.2rem",
      lineHeight: "4rem"
    }
  },
  bodyText: {
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "1rem",
    marginBottom: theme.spacing(2),
    "& span": {
      fontWeight: 600
    }
  },
  jobContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #fff",
    marginBottom: theme.spacing(2)
  },
  job: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
    "&:not(:last-child)": {
      borderBottom: "1px solid #fff"
    },
    padding: theme.spacing(1, 2),
    textDecoration: "none",
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  jobTitleText: {
    color: "inherit",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.1rem",
    margin: theme.spacing(1, 0),
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem"
    }
  },
  jobIcon: {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem"
    }
  },
  background: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.ffNavy
  },
  backgroundAlt: {
    flexGrow: 1,
    backgroundColor: "#292929",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  shareDivider: {
    height: "2px",
    backgroundColor: theme.palette.background.ffGreen,
    width: "60%",
    marginRight: "10%",
    marginLeft: "10%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      textAlign: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  },
  shareText: {
    fontSize: "1rem",
    lineHeight: "1.1rem",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: theme.palette.common.darkgray,
    "& span": {
      fontWeight: 500
    },
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(0),
      fontSize: "0.9rem",
      lineHeight: "1.1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    }
  },
  shareButton: {
    marginRight: theme.spacing(1),
    width: 34,
    "@media (min-width:600px)": {
      width: 34
    }
  },
  button: {
    width: "100%",
    margin: theme.spacing(1.5, 0),
    fontSize: "1.2rem",
    padding: theme.spacing(2, 6),
    "@media (min-width:600px)": {
      width: "auto",
      margin: theme.spacing(1.5),
      padding: theme.spacing(2, 10)
    }
  },
  bottomPlayerWrapper: {
    margin: theme.spacing(2, 0),
    position: "relative",
    paddingBottom: "56.25%",
    width: "100%",
    height: "0"
  },
  bottomVideo: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%"
  }
}));

const Careers = () => {
  const { loggedIn } = useContext(AccountContext);

  const classes = useStyles();

  // Share variables
  const shareUrl = "https://farmflix.tv/careers";
  const title = "FarmFLiX.tv | Careers - Have you got what it takes!";

  return (
    <div>
      <>
        {loggedIn && <HeaderInternal />}
        {!loggedIn && <Header />}
      </>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FarmFLiX.tv | Careers</title>
          <meta
            name="description"
            content="We're always on the lookout for talented individuals so why not send us your CV and persuade us why we need to make room for you at FarmFLiX!"
          />
        </Helmet>

        <div className={classes.backgroundAlt}>
          <Container fixed maxWidth="md">
            <Grid container>
              <Grid item xs={12} align="center">
                <Typography
                  copogmponent="h1"
                  variant="h1"
                  className={classes.mainTitle}
                >
                  Careers
                </Typography>
                <Typography variant="h2" className={classes.subTitle}>
                  Have you got what it takes to <span>join our team?</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.bottomPlayerWrapper}
              >
                <iframe
                  title="FarmFLiX: Recruitment Video"
                  src={video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                  allowFullScreen
                  className={classes.bottomVideo}
                ></iframe>
              </Grid>
            </Grid>
          </Container>
        </div>

        <div className={classes.background}>
          <Container className={classes.positionsContainer} fixed maxWidth="md">
            <Grid container>
              <Grid item xs={12} align="center">
                <Typography variant="h2" className={classes.secondHeading}>
                  Current Positions
                </Typography>
                <div className={classes.jobContainer}>
                  {listings.map((listing, index) => (
                    <a
                      key={index}
                      className={classes.job}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={listing.link}
                    >
                      <Typography className={classes.jobTitleText}>
                        {listing.title}
                      </Typography>
                      <ChevronRightOutlined className={classes.jobIcon} />
                    </a>
                  ))}
                </div>

                <Typography variant="body1" className={classes.bodyText}>
                  Click on the positions above to apply and find out more. If
                  you think you can bring something different to FarmFLiX, why
                  not send us your CV and persuade us why we need you on the
                  team.
                </Typography>
                <Link to={"/contact"} style={{ textDecoration: "none" }}>
                  <Button className={classes.button}>Get in Touch</Button>
                </Link>
              </Grid>
              <Grid item xs={12} align="center">
                <Divider className={classes.shareDivider} />
                <Typography varaint="body2" className={classes.shareText}>
                  Share on:
                </Typography>
                <FacebookShareButton url={shareUrl} quote={title}>
                  <FacebookIcon round className={classes.shareButton} />
                </FacebookShareButton>
                <FacebookMessengerShareButton url={shareUrl} quote={title}>
                  <FacebookMessengerIcon
                    round
                    className={classes.shareButton}
                  />
                </FacebookMessengerShareButton>

                <WhatsappShareButton url={shareUrl} quote={title}>
                  <WhatsappIcon round className={classes.shareButton} />
                </WhatsappShareButton>

                <TwitterShareButton url={shareUrl} quote={title}>
                  <TwitterIcon round className={classes.shareButton} />
                </TwitterShareButton>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>

      <Footer />
    </div>
  );
};

export default Careers;
