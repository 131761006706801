import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TodayIcon from "@material-ui/icons/Today";
import { makeStyles } from "@material-ui/core/styles";
import { VideoSearchResult } from "@booyaltd/core";
import { msToTime } from "../../utils/strings";
import { getTitleAndChannelFromVideoName } from "../../utils/strings";
import dayjs from "dayjs";

type PreviewVideoRowProps = {
  video: VideoSearchResult;
};

const useStyles = makeStyles(theme => ({
  episodeContainer: {
    position: "relative",
    "&:hover": {
      cursor: "pointer"
    }
  },
  episodeLink: {
    textDecoration: "none"
  },
  episodeWrapper: {
    marginBottom: theme.spacing(2)
  },
  episodeTitleWrapper: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "@media (min-width:600px)": {
      flexDirection: "row"
    },
    "@media (min-width:960px)": {}
  },
  currentVideoEpisodeTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  episodeChannelLg: {
    borderRadius: "50%",
    width: "80px",
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(1),
    "@media (min-width:600px)": {
      width: "90px"
    },
    "@media (min-width:960px)": {
      width: "100px"
    }
  },

  episodeNumber: {
    display: "inline",
    // @ts-ignore
    marginRight: theme.spacing(2),
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    fontWeight: 500,
    color: "#bbb",
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.1rem"
    }
  },
  episodeMetaDetailsContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    }
  },
  episodeDetailsIcon: {
    display: "inline",
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    marginRight: "4px",
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem"
    }
  },
  episodeDetailsText: {
    display: "inline",
    // @ts-ignore
    color: "#888",
    marginRight: theme.spacing(2),
    fontSize: "0.8rem",
    lineHeight: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "0.9rem",
      lineHeight: "1.2rem"
    }
  },
  episodeDescription: {
    // @ts-ignore
    color: theme.palette.common.darkgray,
    fontSize: "0.9rem",
    lineHeight: "1rem",
    borderBottom: "1px solid #292929",
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    "@media (min-width:600px)": {
      borderBottom: "none"
    }
  },

  currentVideoEpisodeTitle: {
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "1.7rem",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      fontSize: "2rem",
      marginBottom: theme.spacing(0.25)
    },
    "@media (min-width:960px)": {
      fontSize: "2.5rem",
      marginBottom: theme.spacing(0.5)
    }
  }
}));

const PreviewVideoRow = ({ video }: PreviewVideoRowProps) => {
  const classes = useStyles();

  const { title, episode } = getTitleAndChannelFromVideoName(video.name);
  return (
    <Grid container spacing={2} className={classes.episodeWrapper}>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              className={classes.episodeMetaDetailsContainer}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Box mt={2}>
                  <Box className={classes.currentVideoEpisodeTitleWrapper}>
                    <Box>
                      <div>
                        {(() => {
                          if (video.tags.includes("straight6")) {
                            return (
                              <img
                                src="https://media-test.farmflix.tv/03417afd-2b1b-49b6-a041-9e4a8038b6fc"
                                alt=""
                                className={classes.episodeChannelLg}
                              />
                            );
                          } else if (video.tags.includes("aglife")) {
                            return (
                              <img
                                src="https://media-test.farmflix.tv/3512186f-0058-4f3d-8fc3-b09583557f4d"
                                alt=""
                                className={classes.episodeChannelLg}
                              />
                            );
                          } else if (video.tags.includes("stockyards")) {
                            return (
                              <img
                                src="https://media-test.farmflix.tv/1f2e8165-489b-46e6-927f-51a82a040636"
                                alt=""
                                className={classes.episodeChannelLg}
                              />
                            );
                          } else if (video.tags.includes("loft")) {
                            return (
                              <img
                                src="https://media-test.farmflix.tv/46fb0173-5522-42c7-9518-93bd4ec99430"
                                alt=""
                                className={classes.episodeChannelLg}
                              />
                            );
                          }
                        })()}
                      </div>
                    </Box>

                    {title && episode && (
                      <Box>
                        <Typography
                          variant="h1"
                          className={classes.currentVideoEpisodeTitle}
                        >
                          {title}
                        </Typography>
                        {episode && (
                          <Typography className={classes.episodeNumber}>
                            Ep.{episode.replace("Ep", "")}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="flex-start" flexDirection="row">
                  <Box display="flex" alignItems="center" marginBottom={1}>
                    <AccessTimeIcon className={classes.episodeDetailsIcon} />
                    <Typography
                      variant="body2"
                      className={`${classes.episodeDetailsText}`}
                    >
                      {msToTime(video.duration)}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <TodayIcon className={classes.episodeDetailsIcon} />
                    <Typography
                      variant="body2"
                      className={`${classes.episodeDetailsText}`}
                    >
                      {video.publishedAt &&
                        dayjs(video.publishedAt).format("DD MMM YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography
                    variant="h2"
                    className={classes.episodeDescription}
                  >
                    {video.description}
                  </Typography>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PreviewVideoRow;
