import React from "react";
import { VideoSearchResult } from "@booyaltd/core";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import VideoRow from "./VideoRow";

const useStyles = makeStyles(theme => ({
  smlDivider: {
    height: "2px",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLight,
    width: "100%",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  legacyText: {
    fontSize: "0.8rem",
    lineHeight: "0.9rem",
    // @ts-ignore
    color: theme.palette.background.upgradeAlt
  },
  legacyLink: {
    // @ts-ignore
    color: theme.palette.background.ffGreen
  }
}));

const VideoPlayerDetails = ({
  video,
  legacyLink,
  membersOnly
}: {
  video: VideoSearchResult;
  legacyLink?: string;
  membersOnly?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Box mt={2}>
      <VideoRow
        video={video}
        idx={0}
        listType={membersOnly ? "members-only-video" : "current-video"}
      />
      {!membersOnly && legacyLink && (
        <>
          <Typography variant="body2" className={classes.legacyText}>
            Can&apos;t see the video?
            <br />
            Click here to use our{" "}
            <Link className={classes.legacyLink} to={legacyLink}>
              Legacy Player
            </Link>
            .
          </Typography>
        </>
      )}
    </Box>
  );
};

export default VideoPlayerDetails;
