import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Box, Container, Typography, Grid } from "@material-ui/core";
import { VideoSearchResult, ChannelCatalogResponse } from "@booyaltd/core";
import { makeStyles } from "@material-ui/core/styles";
import "./../../../node_modules/react-responsive-carousel/lib/styles/carousel.css";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { getTitleAndChannelFromVideoName } from "../../utils/strings";

dayjs.extend(relativeTime);

type ComingSoonCarouselProps = {
  videos: VideoSearchResult[];
  channels: ChannelCatalogResponse[];
};

const useStyles = makeStyles(theme => ({
  carouselContainer: {
    marginTop: theme.spacing(2)
  },
  comingSoonHeading: {
    marginBottom: "10px"
  },
  fullWidthItemContainer: {
    height: "200px",
    display: "block",
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    zIndex: 1,
    "@media (min-width:600px)": {
      height: "400px"
    },
    "@media (min-width:960px)": {
      height: "500px"
    }
  },
  fullWidthItemContainerBottomGradient: {
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 0%, rgba(23,23,23,1) 100%)",
    height: "200px",
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "100%",
    "@media (min-width:600px)": {
      height: "400px"
    },
    "@media (min-width:800px)": {
      height: "500px"
    }
  },
  fullWidthItemContainerBottomGradientHover: {
    transition: "0.5s",
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(23,23,23,1) 100%)",

    height: "200px",
    "@media (min-width:600px)": {
      height: "400px"
    },
    "@media (min-width:800px)": {
      height: "500px"
    }
  },
  fullWidthItemDetails: {
    position: "absolute",
    bottom: "20px",
    left: "10px",
    textAlign: "left",
    "@media (min-width:600px)": {
      bottom: "30px",
      left: "25px",
      textAlign: "left"
    },
    "@media (min-width:960px)": {
      bottom: "40px",
      left: "25px",
      textAlign: "left"
    }
  },
  fullWidthItemDays: {
    position: "absolute",
    bottom: "20px",
    right: "10px",
    textAlign: "left",
    "@media (min-width:600px)": {
      bottom: "30px",
      right: "25px",
      textAlign: "left"
    },
    "@media (min-width:960px)": {
      bottom: "40px",
      right: "25px",
      textAlign: "left"
    }
  },
  fullWidthItemComingSoon: {
    fontSize: "1rem",
    lineHeight: "1rem",
    color: "#99ca3c",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(0),
    fontWeight: 500,
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1.4rem",
      lineHeight: "1.8rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem",
      marginBottom: theme.spacing(1)
    }
  },
  fullWidthItemTitle: {
    fontSize: "1.4rem",
    lineHeight: "1.6rem",
    color: "white",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "2.4rem",
      lineHeight: "2.4rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "3.6rem",
      lineHeight: "3.6rem",
      fontWeight: 500
      // marginBottom: theme.spacing(1)
    }
  },
  fullWidthItemEpisode: {
    fontSize: "1rem",
    lineHeight: "1.1rem",
    color: "#ddd",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(0.2),
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1.6rem",
      lineHeight: "1.8rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "2.2rem",
      lineHeight: "2.2rem",
      fontWeight: 500,
      marginBottom: theme.spacing(1)
    }
  },
  fullWidthItemAvailableLabel: {
    color: "#ccc",
    fontSize: "0.8rem",
    textShadow: "-2px 2px 2px #171717",
    fontWeigth: "500",
    lineHeight: "1rem",
    marginLeft: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "1rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem"
    }
  },
  fullWidthItemAvailableValue: {
    fontSize: "0.9rem",
    textShadow: "-2px 2px 2px #171717",
    fontWeigth: 600,
    // color: "#99ca3c",
    marginLeft: theme.spacing(0.5),
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem",
      fontWeight: 500
    }
  },
  "@global": {
    "@keyframes fadeInFromTop": {
      from: {
        transform: "translate3d(0,-15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    },
    "@keyframes fadeInFromBottom": {
      from: {
        transform: "translate3d(0,15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    }
  },
  fullWidthItemDetailsActiveTop: {
    animation: "fadeInFromTop .3s ease-in-out",
    position: "absolute",
    top: "25px",
    left: "25px"
  },
  fullWidthItemDetailsActiveBottom: {
    animation: "fadeInFromBottom .3s ease-in-out",
    position: "absolute",
    bottom: "35px",
    right: "35px"
  },
  fullWidthItemChannel: {
    marginBottom: theme.spacing(1),
    borderRadius: "50%",
    width: "50px !important",
    "@media (min-width:600px)": {
      width: "60px !important"
    },
    "@media (min-width:960px)": {
      width: "70px !important"
    }
  },
  fullWidthItemDetailsActiveDescription: {
    fontSize: "2.2rem",
    textAlign: "left",
    textShadow: "-2px 2px 2px #171717",
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      textAlign: "left",
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem",
      fontWeight: "400"
    }
  },
  daysWrapper: {
    background: "rgba(23,23,23,0.7)",
    padding: theme.spacing(0.5),
    textAlign: "center",
    "@media (min-width:600px)": {
      padding: theme.spacing(1.5)
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(2)
    }
  }
}));

const ComingSoonCarousel = ({ videos, channels }: ComingSoonCarouselProps) => {
  const classes = useStyles();

  if (!videos || videos.length === 0) {
    return null;
  }
  const hasMultiple = videos.length > 1;

  return (
    <Container className={classes.carouselContainer}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Typography variant="h4" className={classes.comingSoonHeading}>
            Coming Soon
          </Typography>
        </Grid>
      </Grid>
      <Carousel
        useKeyboardArrows={hasMultiple}
        showArrows={hasMultiple}
        interval={5000}
        showThumbs={false}
        showIndicators={hasMultiple}
        infiniteLoop={hasMultiple}
        showStatus={false}
        autoPlay={hasMultiple}
        transitionTime={800}
      >
        {videos.map(video => {
          const { title, episode } = getTitleAndChannelFromVideoName(
            video.name
          );

          const channel = channels.find(
            c => video.channelId && c.id === video.channelId
          );

          return (
            <Box
              style={{ position: "relative", marginTop: "12px" }}
              key={video.id}
            >
              <Box style={{ position: "relative" }} key={video.id}>
                <img
                  src={video.poster}
                  style={{ width: "100%", height: "auto" }}
                  alt={video.name}
                />
              </Box>
              <div
                className={classes.fullWidthItemContainerBottomGradient}
              ></div>
              <div className={classes.fullWidthItemDetails}>
                {channel && (
                  <img
                    src={channel.logoImageUrl}
                    alt=""
                    className={classes.fullWidthItemChannel}
                  />
                )}
                <Typography className={classes.fullWidthItemComingSoon}>
                  Coming Soon
                </Typography>
                <Typography className={classes.fullWidthItemTitle} variant="h6">
                  {title}
                </Typography>
                {episode && (
                  <Typography
                    className={classes.fullWidthItemEpisode}
                    variant="h6"
                  >
                    Episode {episode}
                  </Typography>
                )}
              </div>
              <div className={classes.fullWidthItemDays}>
                {video.masterReleaseDate && (
                  <Box className={classes.daysWrapper}>
                    <Typography className={classes.fullWidthItemAvailableLabel}>
                      Available in&nbsp;
                    </Typography>
                    <Typography className={classes.fullWidthItemAvailableValue}>
                      {dayjs(video.masterReleaseDate).fromNow(true)}
                    </Typography>
                  </Box>
                )}
              </div>
            </Box>
          );
        })}
      </Carousel>
    </Container>
  );
};

export default ComingSoonCarousel;
