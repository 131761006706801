import React, { PropsWithChildren, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { Box } from "@material-ui/core";
import { AccountContext } from "../store/AccountContext";
import HeaderInteral from "../components/Header/HeaderInternal";

const useStyles = makeStyles(theme => ({
  background: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    paddingBottom: theme.spacing(4)
  },
  loading: {
    textAlign: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  }
}));

const DefaultLayout = ({
  children,
  showTry = true,
  showSignIn = true
}: PropsWithChildren<{ showTry?: boolean; showSignIn?: boolean }>) => {
  const { loggedIn } = useContext(AccountContext);
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      margin={0}
      padding={0}
      display="flex"
      minHeight="100vh"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box
        margin={0}
        padding={0}
        display="flex"
        flexDirection="column"
        flex={1}
      >
        {!loggedIn && <Header showTry={showTry} showSignIn={showSignIn} />}
        {loggedIn && <HeaderInteral />}
        <Box
          margin={0}
          padding={0}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          alignItems="center"
          className={classes.background}
        >
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default DefaultLayout;
