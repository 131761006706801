import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import HeaderInternal from "./Header/HeaderInternal";
import { AccountContext } from "../store/AccountContext";
import FarmFLiXAppComponent from "./Banners/SuccessAppTrial";

import { Helmet } from "react-helmet";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.ffNavy
  }
}));

const FarmFLiXApp = props => {
  const classes = useStyles();

  const { loggedIn } = useContext(AccountContext);

  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | The FarmFLiX App is here</title>
        <meta
          name="description"
          content="Get the FarmFLiX App Now, available on the App Store and Google Play Store.  Download Now for Free."
        />
      </Helmet>
      <>
        {loggedIn && <HeaderInternal />}
        {!loggedIn && <Header />}
      </>
      <FarmFLiXAppComponent />
      <Footer />
    </div>
  );
};

export default FarmFLiXApp;
