import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import { getTitleAndChannelFromVideoName } from "../../utils/strings";
import { PlaylistRowProps } from "./PlaylistsSection";
import VideoProgress from "../Video/VideoProgress";
import { PlayerContext } from "../../store/PlayerContext";
import { MEMBER_STATUS_TRIAL } from "@booyaltd/core";

const useStyles = makeStyles(theme => ({
  mobileTabletContainerBottomGradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 40%, rgba(23,23,23,0.9) 90%)",

    height: "90%",
    width: "100%"
  },
  mobileTabletContainerBottomGradientHover: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(33,33,33,1) 100%)",
    height: "100%",
    width: "100%"
  },
  mobileTabletContainer: {
    overflow: "auto",
    whiteSpace: "nowrap",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  mobileTabletEpisode: {
    display: "inline-block",
    maxWidth: "240px",
    height: "auto",
    whiteSpace: "initial",
    marginRight: theme.spacing(1),
    verticalAlign: "top",
    position: "relative"
  },
  mobileTabletEpisodeDetails: {
    position: "absolute",
    bottom: "10px",
    left: "5px",
    "@media (min-width:600px)": {
      bottom: "5px"
    }
  },
  mobileTabletEpisodeChannel: {
    borderRadius: "50%",
    width: "30px"
  },
  mobileTabletEpisodeTitle: {
    marginTop: theme.spacing(0),
    fontSize: "1rem",
    paddingRight: "10px",
    // @ts-ignore
    color: theme.palette.common.upgrade,
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "1.1rem",
      lineHeight: "1.1rem"
    }
  },
  mobileTabletEpisodeNumber: {
    marginTop: theme.spacing(0),
    fontSize: "0.7rem",
    marginBottom: "2px",
    paddingRight: "10px",
    // @ts-ignore
    color: theme.palette.common.upgrade,
    textShadow: "-2px 2px 2px #171717",
    fontWeight: 400,
    "@media (min-width:600px)": {
      fontSize: "0.8rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "0.9rem",
      lineHeight: "1rem"
    }
  },
  episodeProgressBar: {
    marginTop: "-7px",
    marginBottom: "3px"
  },
  episodeContainerBottomGradientWatched: {
    position: "absolute",
    bottom: 0,
    left: 0,
    background:
      "linear-gradient(180deg, rgba(31,31,31,0.7) 100%, rgba(23,23,23,0.9) 10%)",
    height: "100%",
    width: "100%"
  },
  episodeWatchedLabel: {
    position: "absolute",
    top: "5px",
    right: "0px",
    backgroundColor: "rgba(23,23,23,0.4)",
    color: "#fff",
    padding: theme.spacing(0.6),
    display: "flex"
  },
  episodeWatchedLabelText: {
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    fontSize: "0.8rem",
    lineHeight: "1rem",
    "@media (min-width:600px)": {
      fontSize: "0.8rem",
      lineHeight: "0.8rem"
    }
  },
  episodeWatchedLabelIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: "1rem"
  }
}));

const PlaylistRowScroll = ({
  playlist,
  channels,
  memberStatusId
}: PlaylistRowProps) => {
  const classes = useStyles();
  const player = useContext(PlayerContext);

  return (
    <>
      <Grid item xs={12} sm={12} className={classes.mobileTabletContainer}>
        {playlist.videos.map(episode => {
          const {
            title,
            episode: episodeNumber
          } = getTitleAndChannelFromVideoName(episode.name);
          const channel = channels.find(
            c => episode.channelId && c.id === episode.channelId
          );
          const showMembersOnlyBanner =
            memberStatusId === MEMBER_STATUS_TRIAL &&
            episode.restrictedFromMemberStatuses?.includes(MEMBER_STATUS_TRIAL);
          return (
            <Link
              key={episode.id}
              to={"video-details/" + episode.slug}
              style={{ textDecoration: "none" }}
            >
              <Box className={classes.mobileTabletEpisode} key={episode.id}>
                <div className={classes.mobileTabletContainerBottomGradient} />
                <img
                  src={episode.thumbnailThumb}
                  style={{ width: "100%" }}
                  alt={episode.name}
                />
                <VideoProgress
                  durationMs={episode.duration}
                  progress={episode.progress}
                  localProgress={player.localVideoProgresses[episode.id]}
                />

                {/* Watched Label  */}
                {showMembersOnlyBanner ? (
                  <div
                    className={classes.episodeContainerBottomGradientWatched}
                  >
                    <Box className={classes.episodeWatchedLabel}>
                      <LockRoundedIcon
                        color="primary"
                        className={classes.episodeWatchedLabelIcon}
                      />
                      <Typography className={classes.episodeWatchedLabelText}>
                        Members Only
                      </Typography>
                    </Box>
                  </div>
                ) : episode.watched ? (
                  <div
                    className={classes.episodeContainerBottomGradientWatched}
                  >
                    <Box className={classes.episodeWatchedLabel}>
                      <CheckCircleRoundedIcon
                        color="primary"
                        className={classes.episodeWatchedLabelIcon}
                      />
                      <Typography className={classes.episodeWatchedLabelText}>
                        Watched
                      </Typography>
                    </Box>
                  </div>
                ) : null}

                <Box className={classes.mobileTabletEpisodeDetails}>
                  {channel && channel.logoImageUrl && (
                    <img
                      src={channel.logoImageUrl}
                      alt=""
                      className={classes.mobileTabletEpisodeChannel}
                    />
                  )}
                  {title && (
                    <Typography
                      variant="body1"
                      className={classes.mobileTabletEpisodeTitle}
                    >
                      {title}
                    </Typography>
                  )}
                  {episodeNumber && (
                    <Typography
                      variant="body2"
                      className={classes.mobileTabletEpisodeNumber}
                    >
                      Ep. {episodeNumber}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Link>
          );
        })}
      </Grid>
    </>
  );
};

export default PlaylistRowScroll;
