import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Box, Typography, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SearchIcon from "@material-ui/icons/Search";
import HistoryIcon from "@material-ui/icons/History";
import { SearchContext } from "../Search/SearchContainer";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  },
  buttonInnerContainer: {
    borderRadius: "10px",
    padding: theme.spacing(2, 0.5),
    background: "#2c2c2c",
    textAlign: "center",
    height: "auto",
    "&:hover": {
      cursor: "pointer",
      background: "#333"
    },
    "@media (min-width:600px)": {
      padding: theme.spacing(2),
      height: "auto"
    },
    "@media (min-width:1100px)": {
      padding: theme.spacing(3, 2),
      height: "auto"
    }
  },
  buttonHeading: {
    textAlign: "center",
    fontSize: "0.9rem",
    lineHeight: "1rem",
    color: "#fff",
    textShadow: "-1px 1px 1px #171717",
    marginBottom: theme.spacing(0),
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem",
      textShadow: "-2px 2px 2px #171717"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
      marginBottom: theme.spacing(0)
    }
  },
  buttonIcon: {
    fontSize: "2.4rem",
    color: "#fff",
    marginBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      marginBottom: theme.spacing(0.5)
    },
    "@media (min-width:960px)": {
      fontSize: "3.6rem"
    }
  }
}));

const LinksSection = () => {
  const classes = useStyles();

  const { openSearch } = useContext(SearchContext);

  return (
    <div className={classes.background}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Link to={"/favourites"} style={{ textDecoration: "none" }}>
              <Box className={classes.buttonInnerContainer}>
                <FavoriteIcon
                  className={classes.buttonIcon}
                  style={{ color: "#f13849" }}
                />
                <Typography className={classes.buttonHeading}>
                  <Hidden only={["sm", "md", "lg", "xl"]}>
                    My
                    <br />
                    Favourites
                  </Hidden>
                  <Hidden only={["xs"]}>My Favourites</Hidden>
                </Typography>
              </Box>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Box
              className={classes.buttonInnerContainer}
              onClick={() => openSearch()}
            >
              <SearchIcon
                className={classes.buttonIcon}
                style={{ color: "#ccc" }}
              />
              <Typography className={classes.buttonHeading}>
                <Hidden only={["sm", "md", "lg", "xl"]}>
                  Search
                  <br />
                  FarmFLiX
                </Hidden>
                <Hidden only={["xs"]}>Search</Hidden>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Link to="/watch-history" style={{ textDecoration: "none" }}>
              <Box
                className={classes.buttonInnerContainer}
                onClick={() => console.log("Watch History")}
              >
                <HistoryIcon
                  className={classes.buttonIcon}
                  style={{ color: "#99ca3c" }}
                />
                <Typography className={classes.buttonHeading}>
                  <Hidden only={["sm", "md", "lg", "xl"]}>
                    Watch
                    <br />
                    History
                  </Hidden>
                  <Hidden only={["xs"]}>Watch History</Hidden>
                </Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default LinksSection;
