/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  VideoSearchAutocompleteResponse,
  VideoSearchParamIsReady,
  VideoSearchParams,
  VideoSearchResponse,
  VideoSort,
  VideoType
} from "@booyaltd/core";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { useHistory } from "react-router";
import SearchModal from "./SearchModal";
import { autocompleteVideoSearch, searchVideos } from "../../api/client";
import axios, { CancelTokenSource } from "axios";
import isEqual from "lodash/isEqual";
import throttle from "lodash/throttle";
import { debounce } from "lodash";

export type PartialVideoSearchParams = Pick<
  VideoSearchParams,
  | "sort"
  | "phrase"
  | "favourited"
  | "watched"
  | "publishedDateFrom"
  | "publishedDateTo"
  | "region"
  | "channelId"
  | "tags"
>;

type SearchContextType = {
  search: PartialVideoSearchParams | undefined;
  isOpen: boolean;
  updateSearch: (partialSearch: Partial<PartialVideoSearchParams>) => void;
  canLoadMore: boolean;
  loadMore: () => void;
  openSearch: () => void;
  closeSearch: () => void;
  autocompletePhrase: string;
  autocompleteLoading: boolean;
  updateAutocompletePhrase: (phrase: string) => void;
  autocompleteResponse: VideoSearchAutocompleteResponse;
  searchLoading: boolean;
  searchResponse: VideoSearchResponse | undefined;
};

export const SearchContext = createContext<SearchContextType>({
  search: undefined,
  isOpen: false,
  updateSearch: () => {},
  canLoadMore: false,
  loadMore: () => {},
  openSearch: () => {},
  closeSearch: () => {},
  updateAutocompletePhrase: () => {},
  autocompletePhrase: "",
  autocompleteLoading: false,
  autocompleteResponse: { phrase: "", total: 0, results: [] },
  searchLoading: false,
  searchResponse: undefined
});

const DEFAULT_SEARCH: PartialVideoSearchParams = {
  sort: VideoSort.PublishDate
};

let autocompleteCancelToken: CancelTokenSource;
let searchCancelToken: CancelTokenSource;

const SearchContainer = ({ children }: PropsWithChildren<{}>) => {
  const { search: queryString, pathname } = useLocation();
  const history = useHistory();

  const [searchResponse, setSearchResponse] = useState<VideoSearchResponse>();
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const [autocompletePhrase, setAutocompletePhrase] = useState<string>("");
  const [autocompleteResponse, setAutocompleteResponse] = useState<
    VideoSearchAutocompleteResponse
  >({ phrase: "", total: 0, results: [] });
  const [autocompleteLoading, setAutocompleteLoading] = useState<boolean>(
    false
  );

  const search = useMemo<VideoSearchParams | undefined>(() => {
    const queryStringParsed = queryString ? qs.parse(queryString) : {};

    if (
      !queryStringParsed ||
      !queryStringParsed.search ||
      typeof queryStringParsed.search !== "string"
    ) {
      return undefined;
    }

    return JSON.parse(queryStringParsed.search);
  }, [queryString]);

  const canLoadMore = React.useMemo<boolean>(
    () =>
      !!(
        searchResponse && searchResponse.total > searchResponse.results.length
      ),
    [searchResponse]
  );

  const loadMore = useCallback(() => {
    if (!canLoadMore || !searchResponse || searchLoading || !search) return;

    setSearchLoading(true);
    searchVideos({
      ...search,
      offset: searchResponse.results.length,
      limit: 20,
      type: VideoType.Episode,
      isReady: VideoSearchParamIsReady.Master,
      includes: ["watched", "favourited", "progress"]
    })
      .then(response => {
        setSearchResponse({
          ...response,
          results: [...searchResponse.results, ...response.results]
        });
      })
      .finally(() => {
        setSearchLoading(false);
      });
  }, [canLoadMore, searchLoading, search, searchResponse]);

  const getAutocompleteResponse = useCallback(
    debounce(
      (phrase: string) => {
        // if (autocompleteCancelToken) {
        //   autocompleteCancelToken.cancel("cancel");
        // }

        setAutocompleteLoading(true);
        try {
          autocompleteCancelToken = axios.CancelToken.source();
          return autocompleteVideoSearch(
            { phrase },
            autocompleteCancelToken.token
          );
        } finally {
          setAutocompleteLoading(false);
        }
      },
      2000,
      { leading: true, trailing: true }
    ),
    [setAutocompleteLoading]
  );

  const getSearchResponse = React.useMemo(
    () =>
      throttle((search: VideoSearchParams) => {
        if (searchCancelToken) {
          searchCancelToken.cancel("cancel");
        }

        setSearchLoading(true);
        if (search.phrase) {
          setAutocompletePhrase(search.phrase || "");
        }

        try {
          searchCancelToken = axios.CancelToken.source();
          return searchVideos({
            ...search,
            type: VideoType.Episode,
            offset: 0,
            limit: 20,
            isReady: VideoSearchParamIsReady.Master,
            includes: ["watched", "favourited", "progress"]
          });
        } finally {
          setSearchLoading(false);
        }
      }, 500),
    []
  );

  const updateSearch = useCallback(
    (updatedSearch: Partial<VideoSearchParams>) => {
      const newSearch = { ...(search || DEFAULT_SEARCH), ...updatedSearch };

      if ((!search || !search.phrase) && updatedSearch.phrase) {
        newSearch.sort = VideoSort.Relevance;
      }

      const { search: _, ...rest } = qs.parse(queryString || "");
      const newQueryStringStr = qs.stringify(
        {
          search: JSON.stringify(newSearch),
          ...rest
        },
        { skipNull: true, skipEmptyString: true, arrayFormat: "none" }
      );

      history.push({ pathname: pathname, search: newQueryStringStr });
    },
    [search, queryString, history, pathname]
  );

  const openSearch = useCallback(() => {
    updateSearch({});
  }, [updateSearch]);

  const closeSearch = useCallback(() => {
    const { search: _, ...rest } = qs.parse(queryString || "");
    const newQueryStringStr = qs.stringify(rest, {
      skipNull: true,
      skipEmptyString: true,
      arrayFormat: "none"
    });

    history.push({ pathname: pathname, search: newQueryStringStr });
    setAutocompletePhrase("");
    setAutocompleteResponse({ phrase: "", total: 0, results: [] });
    setSearchResponse(undefined);
    setSearchLoading(false);
    setAutocompleteLoading(false);
  }, [history, pathname, queryString]);

  const updateAutocompletePhrase = useCallback((phrase: string) => {
    let cleanedPhrase = phrase.trim();
    if (cleanedPhrase.length > 30) {
      cleanedPhrase = cleanedPhrase.substr(0, 30);
    }

    setAutocompletePhrase(cleanedPhrase);
  }, []);

  // On search change update querystring and grab new search results
  useEffect(() => {
    if (
      search &&
      (!searchResponse || !isEqual(search, searchResponse.search))
    ) {
      getSearchResponse(search)?.then(response => {
        setSearchResponse(response);
      });
    }
  }, [search]);

  // On autocomplete phrase update autocomplete suggestions
  useEffect(() => {
    if (!autocompletePhrase) {
      setAutocompleteResponse({ results: [], total: 0, phrase: "" });
    } else {
      getAutocompleteResponse(autocompletePhrase)?.then(response => {
        console.log("setting ac response", response.total);
        setAutocompleteResponse(response);
      });
    }
  }, [autocompletePhrase, getAutocompleteResponse, setAutocompleteResponse]);

  const context: SearchContextType = {
    search,
    isOpen: search !== undefined,
    openSearch,
    closeSearch,
    updateSearch,
    canLoadMore,
    loadMore,
    searchLoading,
    searchResponse,
    updateAutocompletePhrase,
    autocompletePhrase,
    autocompleteLoading,
    autocompleteResponse
  };

  return (
    <SearchContext.Provider value={context}>
      {children}
      {context.isOpen && <SearchModal />}
    </SearchContext.Provider>
  );
};

export default SearchContainer;
