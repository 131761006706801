import React, { KeyboardEvent, useContext } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  VideoSearchAutocompleteSuggestion,
  VideoSearchAutocompleteSuggestionResponse,
  VideoSearchAutocompleteSuggestionType
} from "@booyaltd/core";
import {
  Box,
  Chip,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { SearchContext } from "./SearchContainer";
import useStyles from "./styles";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

const getTypeLabelAndColour = (
  type: string
): { label: string; colour: "primary" | "secondary" } | null => {
  if (!type || type.length === 0) return null;
  switch (type) {
    case VideoSearchAutocompleteSuggestionType.Video.toString():
      return { label: "Episode", colour: "secondary" };
    case VideoSearchAutocompleteSuggestionType.Playlist.toString():
      return { label: "Playlist", colour: "primary" };
    case VideoSearchAutocompleteSuggestionType.Series.toString():
      return { label: "Series", colour: "primary" };
    case VideoSearchAutocompleteSuggestionType.Channel.toString():
      return { label: "Channel", colour: "secondary" };
  }

  return null;
};

const SearchAutocompleteInput = () => {
  const classes = useStyles();

  const {
    updateAutocompletePhrase,
    autocompletePhrase,
    autocompleteLoading,
    autocompleteResponse,
    updateSearch
  } = useContext(SearchContext);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      updateSearch({ phrase: autocompletePhrase });
    }
  };

  return (
    <Autocomplete<VideoSearchAutocompleteSuggestionResponse | string>
      freeSolo={true}
      PaperComponent={({ children }) => (
        <Paper className={classes.autoCompletePopper}>{children}</Paper>
      )}
      autoComplete={true}
      disableClearable={true}
      onInputChange={(event, newInputValue) => {
        updateAutocompletePhrase(newInputValue);
      }}
      value={autocompletePhrase || ""}
      onChange={(e, v: string | VideoSearchAutocompleteSuggestion | null) => {
        if (!v) {
          updateSearch({ phrase: "" });
        } else if (typeof v === "string") {
          updateSearch({ phrase: v });
        } else if (v.suggestion) {
          updateSearch({ phrase: v.suggestion });
        }
      }}
      loading={autocompleteLoading}
      options={autocompleteResponse.results}
      renderOption={(
        option: string | VideoSearchAutocompleteSuggestionResponse
      ) => {
        if (typeof option === "string") {
          return option;
        } else if (option.suggestion) {
          const labelAndColor = getTypeLabelAndColour(option.type);
          return (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
              p={0}
              m={0}
              paddingBottom={2}
              borderBottom={1}
              borderColor="rgba(255,255,255,0.1)"
            >
              {option.linkedEntityMediaUrl ? (
                <Box
                  width="50px"
                  height="50px"
                  borderRadius="25px"
                  overflow="hidden"
                  justifyContent="center"
                >
                  <img
                    style={{
                      width: "auto",
                      height: "50px"
                    }}
                    src={option.linkedEntityMediaUrl}
                    alt={option.suggestion}
                  />
                </Box>
              ) : (
                <Box width="50px" height="25px" />
              )}
              <Box flexDirection="column" ml={2}>
                {labelAndColor ? (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={labelAndColor.label}
                    color={labelAndColor.colour}
                    style={{ paddingTop: 1, paddingBottom: 2 }}
                  />
                ) : null}
                <Typography variant="body1">{option.suggestion}</Typography>
              </Box>
            </Box>
          );
        }
      }}
      getOptionLabel={r => (typeof r === "string" ? r : r.suggestion)}
      getOptionSelected={(option, value) => {
        const optionString =
          typeof option === "string" ? option : option.suggestion;
        const valueString =
          typeof value === "string" ? value : value.suggestion;

        return optionString === valueString;
      }}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth={false}
          className={classes.searchInputField}
          placeholder="Search…"
          onKeyDown={onKeyDown}
          InputProps={{
            ...params.InputProps,
            type: "search",
            endAdornment: (
              <IconButton
                name="search"
                className={classes.searchButtonContainer}
                onClick={() => updateSearch({ phrase: autocompletePhrase })}
              >
                <SearchOutlinedIcon className={classes.searchButtonIcon} />
              </IconButton>
            )
          }}
        />
      )}
    />
  );
};

export default SearchAutocompleteInput;
