import React, { useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MemberSurveyContext } from "../../store/MemberSurveyContext";
import {
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormGroup,
  Radio,
  TextField,
  Button,
  Checkbox,
  Typography,
  Divider
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    // @ts-ignore
  },
  stepHeading: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    color: theme.palette.background.ffNavyLighter,
    fontWeight: 600,
    // textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    }
  },
  divider: {
    height: "1px",
    margin: "6px 0 12px 0",
    backgroundColor: "#b7b7b7"
  },
  formStep: {
    marginTop: "24px"
  },
  formQuestion: {
    marginBottom: "36px",
    "& .MuiFormControl-root": {
      width: "100%"
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem",
      marginBottom: "18px"
    },
    "& .MuiTypography-body1": {
      color: "#3d3d3d"
    }
  },
  formGroup: {
    "& .MuiFormControl-root label": {
      marginBottom: "6px"
    },
    "& .MuiTypography-body1": {
      marginBottom: "6px"
    }
  },
  formControlDesc: {
    margin: "6px 0 0px 0",
    color: "#696969"
  },
  buttonContainer: {
    margin: "24px 0"
  },
  stepButton: {
    border: "none",
    "&:hover": {
      border: "none",
      backgroundColor: "#99ca3c"
    }
  },
  stepButtonBack: {
    backgroundColor: "#fff",
    border: "2px solid #171717",
    "&:hover": {
      backgroundColor: "#fafafa",
      border: "2px solid #171717"
    }
  },
  subHeading: {
    fontSize: "1.1rem",
    margin: "24px 0 12px 0"
  }
}));

export default function ThirdStep() {
  const classes = useStyles();

  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant
  } = useContext(MemberSurveyContext);
  const {
    checkEmails,
    emailsDelivery,
    smsTrust,
    useWhatsapp,
    platformUsageFacebook,
    platformUsageYouTube,
    platformUsageInstagram,
    platformUsageTiktok,
    platformUsageSnapchat,
    platformUsageOther,
    platformUsageOtherReason,
    favouriteFacebook,
    favouriteYouTube,
    favouriteInstagram,
    favouriteTiktok,
    favouriteSnapchat,
    discoverFarmFLiX,
    discoverFarmFLiXOther
  } = formValues;

  const isError = useCallback(
    () =>
      Object.keys({}).some(
        name =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues]
  );

  return (
    <>
      <Grid container spacing={2} className={classes.formStep}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.stepHeading}>
            Comms
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        {/* Check Emails */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Do you check your emails regularly?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="checkEmails"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="1 - Rarely"
                checked={checkEmails.value === "1 - Rarely"}
                control={<Radio />}
                label="1 - Rarely"
                required={true}
              />
              <FormControlLabel
                value="2 - Occasionally"
                checked={checkEmails.value === "2 - Occasionally"}
                control={<Radio />}
                label="2 - Occasionally"
                required={true}
              />
              <FormControlLabel
                value="3 - Daily"
                checked={checkEmails.value === "3 - Daily"}
                control={<Radio />}
                label="3 - Daily"
                required={true}
              />
              <FormControlLabel
                value="4 - Hourly"
                checked={checkEmails.value === "4 - Hourly"}
                control={<Radio />}
                label="4 - Hourly"
                required={true}
              />
              <FormControlLabel
                value="5 - Instantly on the phone after a notification"
                checked={
                  checkEmails.value ===
                  "5 - Instantly on the phone after a notification"
                }
                control={<Radio />}
                label="5 - Instantly on the phone after a notification"
                required={true}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* Email Delivery */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Do FarmFLiX emails go into your inbox or your spam folder?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="emailsDelivery"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Inbox"
                checked={emailsDelivery.value === "Inbox"}
                control={<Radio />}
                label="Inbox"
                required={true}
              />
              <FormControlLabel
                value="Spam Folder"
                checked={emailsDelivery.value === "Spam Folder"}
                control={<Radio />}
                label="Spam Folder"
                required={true}
              />
              <FormControlLabel
                value="Unsubscribed"
                checked={emailsDelivery.value === "Unsubscribed"}
                control={<Radio />}
                label="Unsubscribed"
                required={true}
              />
              <FormControlLabel
                value="I've never seen your emails"
                checked={emailsDelivery.value === "I've never seen your emails"}
                control={<Radio />}
                label="I've never seen your emails"
                required={true}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* SMS Trust */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Do you read and trust SMS messages from FarmFLiX?
              <Typography className={classes.formControlDesc} variant="body2">
                We use SMS very sparingly because we don&apos;t want to spam
                anyone. Please note that we will never ask you for any details
                over SMS. If in doubt as to who sent you an SMS message do not
                to click any link contained in it. We only include our main
                gateway address www.farmflix.tv in our SMS messages.
              </Typography>
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="smsTrust"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="1 - Never received any"
                checked={smsTrust.value === "1 - Never received any"}
                control={<Radio />}
                label="1 - Never received any"
                required={true}
              />
              <FormControlLabel
                value="2 - Received them but ignore"
                checked={smsTrust.value === "2 - Received them but ignore"}
                control={<Radio />}
                label="2 - Received them but ignore"
                required={true}
              />
              <FormControlLabel
                value="3 - Read them"
                checked={smsTrust.value === "3 - Read them"}
                control={<Radio />}
                label="3 - Read them"
                required={true}
              />
              <FormControlLabel
                value="4 - Trust them as real"
                checked={smsTrust.value === "4 - Trust them as real"}
                control={<Radio />}
                label="4 - Trust them as real"
                required={true}
              />
              <FormControlLabel
                value="5 - Use them to get logged in"
                checked={smsTrust.value === "5 - Use them to get logged in"}
                control={<Radio />}
                label="5 - Use them to get logged in"
                required={true}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      {/* Use WhatsApp */}
      <Grid item xs={12} sm={12} className={classes.formQuestion}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Do you use WhatsApp?
          </FormLabel>
          <RadioGroup
            onChange={handleChange}
            name="useWhatsapp"
            className={classes.formGroup}
          >
            <FormControlLabel
              value="Yes"
              checked={useWhatsapp.value === "Yes"}
              control={<Radio />}
              label="Yes"
              required={true}
            />
            <FormControlLabel
              value="No"
              checked={useWhatsapp.value === "No"}
              control={<Radio />}
              label="No"
              required={true}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {/* Platforms use */}
      <Grid item xs={12} sm={12} className={classes.formQuestion}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Which of these platforms do you currently spend 30 minutes or more
            daily?
          </FormLabel>
          <FormGroup>
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    name="platformUsageFacebook"
                    onChange={handleChange}
                    value={platformUsageFacebook.value}
                    checked={platformUsageFacebook.value}
                  />
                }
                label="Facebook"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="platformUsageYouTube"
                    onChange={handleChange}
                    checked={platformUsageYouTube.value}
                    value={platformUsageYouTube.value}
                  />
                }
                label="YouTube"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="platformUsageInstagram"
                    onChange={handleChange}
                    checked={platformUsageInstagram.value}
                    value={platformUsageInstagram.value}
                  />
                }
                label="Instagram"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="platformUsageTiktok"
                    onChange={handleChange}
                    checked={platformUsageTiktok.value}
                    value={platformUsageTiktok.value}
                  />
                }
                label="TikTok"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="platformUsageSnapchat"
                    onChange={handleChange}
                    checked={platformUsageSnapchat.value}
                    value={platformUsageSnapchat.value}
                  />
                }
                label="Snapchat"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="platformUsageOther"
                    onChange={handleChange}
                    checked={platformUsageOther.value}
                    value={platformUsageOther.value}
                  />
                }
                label="Other"
              />
            </>
          </FormGroup>
        </FormControl>

        {/* If Facebook is checked */}
        {platformUsageFacebook.value === true && (
          <>
            <Grid item xs={12} sm={12} className={classes.formQuestion}>
              <Typography variant="h6" className={classes.subHeading}>
                What are your favourite Facebook pages/groups?
              </Typography>
              <TextField
                variant={variant}
                fullWidth
                name="favouriteFacebook"
                placeholder=""
                value={favouriteFacebook.value}
                onChange={handleChange}
                error={!!favouriteFacebook.error}
                helperText={favouriteFacebook.error}
                required={favouriteFacebook.required}
              />
            </Grid>
          </>
        )}

        {/* If YouTube is checked */}
        {platformUsageYouTube.value === true && (
          <>
            <Grid item xs={12} sm={12} className={classes.formQuestion}>
              <Typography variant="h6" className={classes.subHeading}>
                What are your favourite YouTube channels?
              </Typography>
              <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
              <TextField
                variant={variant}
                fullWidth
                name="favouriteYouTube"
                placeholder=""
                value={favouriteYouTube.value}
                onChange={handleChange}
                error={!!favouriteYouTube.error}
                helperText={favouriteYouTube.error}
                required={favouriteYouTube.required}
              />
            </Grid>
          </>
        )}

        {/* If Instagram is checked */}
        {platformUsageInstagram.value === true && (
          <>
            <Grid item xs={12} sm={12} className={classes.formQuestion}>
              <Typography variant="h6" className={classes.subHeading}>
                Who are your favourite Instagram profiles?
              </Typography>
              <TextField
                variant={variant}
                fullWidth
                name="favouriteInstagram"
                placeholder=""
                value={favouriteInstagram.value}
                onChange={handleChange}
                error={!!favouriteInstagram.error}
                helperText={favouriteInstagram.error}
                required={favouriteInstagram.required}
              />
            </Grid>
          </>
        )}

        {/* If Tiktok is checked */}
        {platformUsageTiktok.value === true && (
          <>
            <Grid item xs={12} sm={12} className={classes.formQuestion}>
              <Typography variant="h6" className={classes.subHeading}>
                Who are your favourite TikTok profiles?
              </Typography>
              <TextField
                variant={variant}
                fullWidth
                name="favouriteTiktok"
                placeholder=""
                value={favouriteTiktok.value}
                onChange={handleChange}
                error={!!favouriteTiktok.error}
                helperText={favouriteTiktok.error}
                required={favouriteTiktok.required}
              />
            </Grid>
          </>
        )}

        {/* If Snapchat is checked */}
        {platformUsageSnapchat.value === true && (
          <>
            <Grid item xs={12} sm={12} className={classes.formQuestion}>
              <Typography variant="h6" className={classes.subHeading}>
                Who are your favourite farmers on Snapchat?
              </Typography>
              <TextField
                variant={variant}
                fullWidth
                name="favouriteSnapchat"
                placeholder=""
                value={favouriteSnapchat.value}
                onChange={handleChange}
                error={!!favouriteSnapchat.error}
                helperText={favouriteSnapchat.error}
                required={favouriteSnapchat.required}
              />
            </Grid>
          </>
        )}

        {/* If Other is checked */}
        {platformUsageOther.value === true && (
          <>
            <Grid item xs={12} sm={12} className={classes.formQuestion}>
              <Typography variant="h6" className={classes.subHeading}>
                Other, please specify
              </Typography>
              <TextField
                variant={variant}
                fullWidth
                name="platformUsageOtherReason"
                placeholder=""
                value={platformUsageOtherReason.value}
                onChange={handleChange}
                error={!!platformUsageOtherReason.error}
                helperText={platformUsageOtherReason.error}
                required={platformUsageOtherReason.required}
              />
            </Grid>
          </>
        )}
      </Grid>

      {/* First Dicover FarmFLiX */}
      <Grid item xs={12} sm={12} className={classes.formQuestion}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            How did you first discover FarmFLiX?
          </FormLabel>
          <RadioGroup onChange={handleChange} name="discoverFarmFLiX">
            <>
              <FormControlLabel
                value="Google Search"
                checked={discoverFarmFLiX.value === "Google Search"}
                control={<Radio />}
                label="Google Search"
              />
              <FormControlLabel
                value="Facebook"
                checked={discoverFarmFLiX.value === "Facebook"}
                control={<Radio />}
                label="Facebook"
              />
              <FormControlLabel
                value="YouTube"
                checked={discoverFarmFLiX.value === "YouTube"}
                control={<Radio />}
                label="YouTube"
              />
              <FormControlLabel
                value="Snapchat"
                checked={discoverFarmFLiX.value === "Snapchat"}
                control={<Radio />}
                label="Snapchat"
              />
              <FormControlLabel
                value="Instagram"
                checked={discoverFarmFLiX.value === "Instagram"}
                control={<Radio />}
                label="Instagram"
              />
              <FormControlLabel
                value="TikTok"
                checked={discoverFarmFLiX.value === "TikTok"}
                control={<Radio />}
                label="TikTok"
              />
              <FormControlLabel
                value="Met the team while filming"
                checked={
                  discoverFarmFLiX.value === "Met the team while filming"
                }
                control={<Radio />}
                label="Met the team while filming"
              />
              <FormControlLabel
                value="Met the team at an event"
                checked={discoverFarmFLiX.value === "Met the team at an event"}
                control={<Radio />}
                label="Met the team at an event"
              />
              <FormControlLabel
                value="Word of Mouth"
                checked={discoverFarmFLiX.value === "Word of Mouth"}
                control={<Radio />}
                label="Word of Mouth"
              />
              <FormControlLabel
                value="Other"
                checked={discoverFarmFLiX.value === "Other"}
                control={<Radio />}
                label="Other"
              />
            </>
          </RadioGroup>
        </FormControl>
        {discoverFarmFLiX.value === "Other" && (
          <Grid
            item
            xs={12}
            sm={12}
            className={classes.formQuestion}
            style={{ marginTop: "24px" }}
          >
            <Typography variant="h6" className={classes.subHeading}>
              Other, please specify
            </Typography>
            <TextField
              variant={variant}
              fullWidth
              name="discoverFarmFLiXOther"
              placeholder=""
              value={discoverFarmFLiXOther.value}
              onChange={handleChange}
              error={!!discoverFarmFLiXOther.error}
              helperText={discoverFarmFLiXOther.error}
              required={discoverFarmFLiXOther.required}
            />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} className={classes.buttonContainer}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 3
          }}
        >
          <Button onClick={handleBack} className={classes.stepButtonBack}>
            Back
          </Button>
          <Button
            className={classes.stepButton}
            variant="contained"
            disabled={isError()}
            color="primary"
            onClick={!isError() ? handleNext : () => null}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </>
  );
}
