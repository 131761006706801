import React from "react";
import { useParams } from "react-router-dom";
import MarketingPreferencesForm from "./MarketingPreferences/MarketingPreferencesForm";
import { Helmet } from "react-helmet";
import DefaultLayout from "../layouts/DefaultLayout";

const PublicMarketingPreferences = () => {
  const { unsubscribeToken } = useParams<{ unsubscribeToken?: string }>();

  return (
    <DefaultLayout showTry={false} showSignIn={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Marketing Preferences</title>
      </Helmet>
      <MarketingPreferencesForm
        unsubscribeToken={unsubscribeToken}
        embedded={false}
      />
    </DefaultLayout>
  );
};

export default PublicMarketingPreferences;
