import React, { useContext, useEffect, useState } from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import { AccountContext } from "../store/AccountContext";
import { Redirect, useLocation } from "react-router-dom";
import querystring from "query-string";
import BackdropLoader from "./common/BackdropLoader";

const AutoLogin = () => {
  const { loginWithAutoLoginToken } = useContext(AccountContext);
  const [redirectTo, setRedirectTo] = useState<string>();
  const location = useLocation();

  // One time - on mount
  useEffect(() => {
    if (!location.search || redirectTo) return;

    const { alt, rd } = querystring.parse(location.search);
    console.log("search", location.search);
    console.log("alt", alt);
    console.log("rd", rd);
    if (alt && typeof alt === "string" && alt.length > 0) {
      loginWithAutoLoginToken(alt)
        .then(() => {
          console.log(
            "Setting Redirect To",
            rd && typeof rd === "string" ? rd : "/"
          );
          setRedirectTo(rd && typeof rd === "string" ? rd : "/");
        })
        .catch(e => {
          console.error("Error logging in with auto login token", e);
          setRedirectTo("/");
        });
    } else {
      setRedirectTo("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <DefaultLayout>
      {redirectTo !== undefined && <Redirect to={redirectTo} />}
      <BackdropLoader />
    </DefaultLayout>
  );
};

export default AutoLogin;
