import React, { useCallback, useContext, useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LatestLayout from "../layouts/LatestLayout";
import { OverviewContext } from "../store/OverviewContext";
import { PlayerContext } from "../store/PlayerContext";
import useLogger from "../hooks/useLogger";
import { LogEventCategory, PlaybackEvents } from "../api/entities/Log";
import VideoPlayerDetails from "./Video/VideoPlayerDetails";
import PromoPoster from "./Dashboard/PromoPoster";
import { AccountContext } from "../store/AccountContext";
import FreeTrialLargeBanner from "./Banners/FreeTrialLarge";
import { Player } from "./common/player";

const useStyles = makeStyles(theme => ({
  background: {
    width: "100%",
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    }
  },
  loading: {
    textAlign: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  marginSpacing: {
    margin: "20px 0"
  },
  playerWrapper: {
    position: "relative"
  },
  player: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    "& video": {
      "&:focus": {
        outline: "none"
      }
    }
  },
  playIcon: {
    fontSize: "6rem",
    "@media (min-width:600px)": {
      fontSize: "7rem"
    },
    "@media (min-width:960px)": {
      fontSize: "8rem"
    }
  },
  upgradeBannerBackground: {
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.upgrade,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: "center"
  },
  upgradeTitle: {
    marginBottom: theme.spacing(1),
    fontSize: "1.8rem",
    fontWeight: 500,
    color: theme.palette.common.white
  },
  upgradeSubTitle: {
    marginBottom: theme.spacing(2),
    fontSize: "1.4rem",
    fontWeight: 400,
    // @ts-ignore
    color: theme.palette.background.white
  },
  upgradeBodyText: {
    marginBottom: theme.spacing(2),
    // @ts-ignore
    color: theme.palette.background.white,
    fontWeight: 300,
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1rem",
      lineHeight: "1.2rem"
    }
  },
  relatedVideoList: {},
  relatedVideoListTitle: {
    marginBottom: "10px",
    // @ts-ignore
    color: theme.palette.common.lightgray
  },
  creditsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  creditsTitle: {
    // @ts-ignore
    color: theme.palette.background.upgradeAlt
  },
  creditsDetail: {
    color: "#c3c3c3",
    margin: "4px 0",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.upgradeAlt
    }
  },
  tagsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  tagsTitle: {
    marginBottom: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.background.upgradeAlt
  },
  merchBackground: {
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: "-16px",
    "@media (min-width:600px)": {
      padding: theme.spacing(6, 0)
    }
  }
}));

const LatestMaster = () => {
  const { overview } = useContext(OverviewContext);
  const { userProfile } = useContext(AccountContext);
  const { logEvent } = useLogger();

  const {
    loadSingleVideo,
    currentVideo,
    clearCurrentVideo,
    onVideoProgress
  } = useContext(PlayerContext);

  const onStart = useCallback(() => {
    if (!currentVideo) {
      return;
    }

    logEvent({
      category: LogEventCategory.PLAYBACK_ACTION,
      event: PlaybackEvents.PLAYBACK_STARTED,
      data: {
        videoId: currentVideo.id,
        progress: 0,
        playbackLocation: "web"
      }
    });
  }, [currentVideo, logEvent]);

  const onProgress = useCallback(
    (progressSecs: number) => {
      onVideoProgress(progressSecs);
    },
    [onVideoProgress]
  );

  useEffect(() => {
    return () => {
      clearCurrentVideo();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!overview?.latest) {
      return;
    }

    const latest = Array.isArray(overview.latest)
      ? overview.latest[0]
      : overview.latest;

    if (latest?.slug) {
      loadSingleVideo(latest.slug);
    }
    // eslint-disable-next-line
  }, [overview?.latest]);

  const classes = useStyles();

  return (
    <LatestLayout>
      {overview && currentVideo && (
        <>
          <div className={classes.background}>
            <Container>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Player
                    userId={userProfile?.id || undefined}
                    video={currentVideo}
                    type="master"
                    onStart={onStart}
                    onProgress={onProgress}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <VideoPlayerDetails
                    video={currentVideo}
                    legacyLink="/legacy/latest"
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
          {userProfile && userProfile.memberStatus === "trial" && (
            <FreeTrialLargeBanner />
          )}
          {overview && overview.promo && (
            <div className={classes.background}>
              <PromoPoster promo={overview.promo} />
            </div>
          )}
        </>
      )}
    </LatestLayout>
  );
};

export default LatestMaster;
