import {
  VideoSearchParamIsReady,
  VideoSearchParams,
  VideoSearchResponse,
  VideoType
} from "@booyaltd/core";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useMemo, useState } from "react";
import { searchVideos } from "../api/client";

type PartialVideoSearchRequest = Omit<VideoSearchParams, "offset" | "limit">;

const useVideoSearch = (
  initialSearchRequest?: PartialVideoSearchRequest
): IUseVideoTypeSearch => {
  const [searchRequest, setSearchRequest] = useState<
    PartialVideoSearchRequest | undefined
  >(initialSearchRequest);
  const [searchResponse, setSearchResponse] = useState<VideoSearchResponse>();
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const updateSearch = useCallback(
    (updatedSearch: PartialVideoSearchRequest) => {
      setSearchRequest({ ...initialSearchRequest, ...updatedSearch });
    },
    [initialSearchRequest]
  );

  // On search change update querystring and grab new search results
  useEffect(() => {
    if (!searchRequest) return;

    // Dont re-initialise if existing search result search matches search request
    if (searchResponse?.search) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { offset, limit, ...partialSearch } = searchResponse.search;
      if (isEqual(partialSearch, searchRequest)) {
        return;
      }
    }

    console.log("Loading First Page", {
      ...searchRequest,
      offset: 0,
      limit: 20
    });

    setSearchLoading(true);
    searchVideos({
      ...searchRequest,
      type: VideoType.Episode,
      isReady: VideoSearchParamIsReady.Master,
      offset: 0,
      limit: 20
    })
      .then(setSearchResponse)
      .finally(() => {
        setSearchLoading(false);
      });
    // eslint-disable-next-line
  }, [searchRequest]);

  const canLoadMore = useMemo<boolean>(
    () =>
      !!(
        !searchLoading &&
        searchResponse &&
        searchResponse.total &&
        searchResponse.results &&
        searchResponse.total > searchResponse.results.length
      ),
    [searchLoading, searchResponse]
  );

  const loadMore = useCallback(() => {
    if (!canLoadMore || searchLoading || !searchResponse || !searchRequest)
      return;

    console.log("Loading Next Page", {
      ...searchRequest,
      offset: searchResponse.results.length,
      limit: 50
    });

    setSearchLoading(true);
    searchVideos({
      ...searchRequest,
      type: VideoType.Episode,
      isReady: VideoSearchParamIsReady.Master,
      offset: searchResponse.results.length,
      limit: 50
    })
      .then((response: VideoSearchResponse) => {
        setSearchResponse({
          total: response.total,
          results: [...searchResponse.results, ...response.results],
          search: response.search
        });
      })
      .finally(() => {
        setSearchLoading(false);
      });
  }, [canLoadMore, searchResponse, searchRequest, searchLoading]);

  const initialise = useCallback(
    (req: PartialVideoSearchRequest, res: VideoSearchResponse) => {
      setSearchLoading(false);
      setSearchResponse(res);
      setSearchRequest(req);
    },
    []
  );

  return {
    initialise,
    searchLoading,
    searchResponse,
    updateSearch,
    canLoadMore,
    loadMore
  };
};

interface IUseVideoTypeSearch {
  initialise: (
    req: PartialVideoSearchRequest,
    res: VideoSearchResponse
  ) => void;
  searchLoading: boolean;
  searchResponse: VideoSearchResponse | undefined;
  updateSearch: (partialSearch: PartialVideoSearchRequest) => void;
  canLoadMore: boolean;
  loadMore: () => void;
}

export default useVideoSearch;
