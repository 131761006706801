type RequestClassBase = { [key: string]: any };

type ValidationErrorInternalError<RequestClass extends RequestClassBase> =
  | string
  | { [key in keyof RequestClass]: string };

export class ValidationError<RequestClass extends RequestClassBase> {
  _error: ValidationErrorInternalError<RequestClass>;

  constructor(error: ValidationErrorInternalError<RequestClass>) {
    this._error = error;
  }

  toString(): string {
    return (typeof this._error === "string"
      ? this._error
      : Object.values(this._error).join(". ")
    )
      .replace("Validation Error:", "")
      .replace("Error:", "")
      .trim();
  }
}

export class PaymentRequiredError extends Error {
  public readonly response: unknown;

  constructor(responseData: unknown) {
    super("Payment Required");

    this.response = responseData;
  }
}

export class DuplicateEmailError extends Error {}
