import React, { useState, useContext, useCallback, useEffect } from "react";
import { AccountContext } from "./../../store/AccountContext";
import {
  Grid,
  Typography,
  Button,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import usePromise from "react-use-promise";

import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";

import CircularProgress from "@material-ui/core/CircularProgress";

import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import amex from "payment-icons/min/flat/amex.svg";
import discover from "payment-icons/min/flat/discover.svg";
import { useToasts } from "react-toast-notifications";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  myAccountThirdTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem"
    }
  },
  bodyText: {
    color: theme.palette.background.ffNavy
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem"
  },
  cardContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  inputLabel: {},
  inputField: {
    padding: "12px",
    border: "1px solid #a5a5a5",
    backgroundColor: "#f7f7f7",
    margin: "4px 0 15px 0",
    fontSize: "16px",
    borderRadius: "5px"
  },
  inputFieldTight: {
    padding: "12px",
    border: "1px solid #a5a5a5",
    backgroundColor: "#f7f7f7",
    margin: "4px 0 8px 0",
    fontSize: "16px",
    borderRadius: "5px"
  },
  midPaymentCreds: {
    marginTop: "4px",
    "@media (min-width:600px)": {
      marginTop: 0
    }
  },
  cardElementLabel: {
    color: "#656565",
    fontSize: "1rem",
    fontWeight: 500,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  },
  bottomPaymentCreds: {
    marginBottom: 0
  },
  paymentButton: {
    margin: "10px 0",
    width: "100%",
    "@media (min-width:600px)": {
      marginTop: 0,
      width: "auto"
    }
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "5% 0 2% 0"
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    color: theme.palette.background.ffNavy,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  },
  cardValidation: {
    marginBottom: theme.spacing(2)
  },
  defaultPaymentMethodContainer: {
    border: "1px solid #a5a5a5",
    borderRadius: "5px",
    backgroundColor: "#dedede",
    padding: theme.spacing(1.2),
    marginBottom: theme.spacing(3.5)
  },
  defaultPaymentCardNumber: {
    textAlign: "left",
    color: theme.palette.background.ffNavy,
    fontWeight: 500
  },
  defaultPaymentMethodText: {
    textAlign: "left",
    color: theme.palette.background.ffNavy
  },
  defaultPaymentMethodExpDate: {
    color: theme.palette.background.ffNavy,
    fontWeight: 400,
    fontSize: "0.9rem"
  },
  defaultPaymentMethodCardBrand: {
    width: "50px",
    maxWidth: "50px",
    paddingRight: "9px",
    paddingTop: "6px"
  },
  defaultPaymentMethodChange: {
    color: theme.palette.background.ffGreen,
    fontWeight: 500,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  defaultPaymentMethodLabel: {
    color: theme.palette.background.ffNavy,
    textAlign: "left",
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    marginTop: "20px",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  },
  validationError: {
    margin: theme.spacing(2, 0)
  },
  tableText: {
    color: theme.palette.background.ffNavy,
    "& span": {
      fontWeight: 500
    }
  },
  autoRenewDialog: {
    justifyContent: "center",
    textAlign: "center",
    "& .MuiDialogTitle-root": {
      backgroundColor: "#171717"
    }
  },
  autoRenewDialogTitle: {
    "& .MuiTypography-root": {
      lineHeight: "1.6rem",
      color: "#eee"
    }
  }
}));

export default function ChangePaymentMethod(props) {
  console.log("PROPS", props.match.params.action);

  const paymentMethodAction = props.match.params.action
    ? props.match.params.action
    : "add";
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const { addToast } = useToasts();

  // Account Context
  const {
    updating,
    handleCardSubmit,
    resetErrors,
    getMembershipDetails,
    validateLogin,
    updateError,
    userProfile,
    handleRemovePaymentMethod,
    handleCancelMembership
  } = useContext(AccountContext);

  useEffect(() => {
    resetErrors();
    // eslint-disable-next-line
  }, []);

  // State
  const [authConfirming, setAuthConfirming] = useState(false);
  const [authConfirmed, setAuthConfirmed] = useState(false);
  const [authConfirmError, setAuthConfirmError] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(Date.now());
  const [membershipDetails, , state] = usePromise(getMembershipDetails, [
    reloadTrigger
  ]);
  const [cardInputError, setCardInputError] = useState(null);

  const loading = state === "pending";

  // eslint-disable-next-line
  const reload = () => setReloadTrigger(Date.now());

  const clearErrors = event => {
    setCardInputError("");
    resetErrors();
  };

  const handleAuthConfirmDialogClose = () => {
    if (authConfirmError) {
      window.location.reload();
    } else {
      validateLogin();
      history.push("/myaccount/overview");
    }
  };

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        "::placeholder": {
          color: "#ccc"
        }
      },
      invalid: {
        color: "#b92020"
      }
    }
  };

  const onSubmitRemovePaymentMethod = async () => {
    try {
      const removePaymentMethod = await handleRemovePaymentMethod(
        userProfile.id
      );

      if (removePaymentMethod) {
        if (userProfile && userProfile.productAutoRenewing) {
          await handleCancelMembership();
        }
        addToast("Payment Method Removed.", {
          appearance: "success"
        });
        validateLogin();
        reload();
      } else {
        addToast("Error - there was a problem", {
          appearance: "error"
        });
      }
    } catch (e) {
      addToast(e.toString(), {
        appearance: "error"
      });
    } finally {
      history.push("/myaccount/overview");
    }
  };

  const onSubmitUpdatePaymentMethod = useCallback(
    async event => {
      event.preventDefault();

      const cardElement = elements.getElement(CardNumberElement);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
      });

      if (error) {
        console.error("Error creating payment method", error);
        setCardInputError(error.message);
        return;
      }

      if (paymentMethod) {
        try {
          const updateCard = await handleCardSubmit(paymentMethod.id);

          if (updateCard.success) {
            if (updateCard.authRequired) {
              setAuthConfirming(true);
              setAuthConfirmed(false);
              setAuthConfirmError(false);
              await stripe
                .confirmCardPayment(updateCard.clientSecret, {
                  payment_method: paymentMethod.id
                })
                .then(() => {
                  setAuthConfirmed(true);
                  setAuthConfirmError(false);
                })
                .catch(error => {
                  setAuthConfirmed(false);
                  setAuthConfirmError(true);
                })
                .finally(() => {
                  setAuthConfirming(false);
                });
            } else {
              addToast(
                paymentMethodAction === "add"
                  ? "Payment Method Successfully Added"
                  : paymentMethodAction === "update"
                  ? "Payment Method Successfully Updated"
                  : "",
                {
                  appearance: "success"
                }
              );
              validateLogin();
              reload();
              history.push("/myaccount/overview");
            }
          } else {
            addToast(updateCard.error, {
              appearance: "error"
            });
          }
        } catch (e) {
          console.log(e);
          addToast("Error:" + e, {
            appearance: "error"
          });
        }
      }
    },
    [
      addToast,
      elements,
      stripe,
      handleCardSubmit,
      validateLogin,
      history,
      paymentMethodAction
    ]
  );

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumbContainer}
        >
          <Link className={classes.activeBreadcrumb} to="/myaccount/overview">
            Overview
          </Link>
          <Typography className={classes.disabledBreadcrumb}>
            {paymentMethodAction === "update"
              ? "Update"
              : paymentMethodAction === "add"
              ? "Add"
              : paymentMethodAction === "remove"
              ? "Remove"
              : ""}{" "}
            Payment Method
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h2" className={classes.myAccountSubTitle}>
          {paymentMethodAction === "update"
            ? "Update"
            : paymentMethodAction === "add"
            ? "Add"
            : paymentMethodAction === "remove"
            ? "Remove"
            : ""}{" "}
          Payment Method
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          {paymentMethodAction === "update"
            ? "Want to update the credit/debit card that we have on file? Provide the new details here."
            : paymentMethodAction === "add"
            ? "Provide the new details here"
            : paymentMethodAction === "remove"
            ? "Are you sure you wish to remove your saved payment method?"
            : ""}{" "}
        </Typography>
      </Grid>
      {!loading && (
        <Grid container className={classes.cardContainer}>
          {!updating && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={6}>
                  {/* Current Card / Payment Method Details */}
                  {(paymentMethodAction === "update" ||
                    paymentMethodAction === "remove") &&
                    membershipDetails.defaultPaymentMethod && (
                      <>
                        <Typography
                          variant="h3"
                          className={classes.myAccountThirdTitle}
                        >
                          Current Card Details
                        </Typography>
                        <Box
                          display="flex"
                          alignItems="center"
                          className={classes.defaultPaymentMethodContainer}
                        >
                          <Box flex="1" alignItems="center">
                            <Typography
                              variant="body1"
                              className={classes.defaultPaymentCardNumber}
                            >
                              **** **** ****{" "}
                              {membershipDetails.defaultPaymentMethod.last4}
                              <br />
                              <span
                                className={classes.defaultPaymentMethodExpDate}
                              >
                                Exp:{" "}
                                {
                                  membershipDetails.defaultPaymentMethod.exp
                                    .month
                                }{" "}
                                /{" "}
                                {
                                  membershipDetails.defaultPaymentMethod.exp
                                    .year
                                }
                              </span>
                            </Typography>
                          </Box>
                          <Box alignItems="center">
                            <Typography
                              variant="body1"
                              className={classes.defaultPaymentMethodChange}
                            >
                              {membershipDetails.defaultPaymentMethod
                                .cardBrand === "visa" && (
                                <img
                                  src={visa}
                                  alt={
                                    membershipDetails.defaultPaymentMethod
                                      .cardBrand
                                  }
                                  className={
                                    classes.defaultPaymentMethodCardBrand
                                  }
                                />
                              )}
                              {membershipDetails.defaultPaymentMethod
                                .cardBrand === "mastercard" && (
                                <img
                                  src={mastercard}
                                  alt={
                                    membershipDetails.defaultPaymentMethod
                                      .cardBrand
                                  }
                                  className={
                                    classes.defaultPaymentMethodCardBrand
                                  }
                                />
                              )}
                              {membershipDetails.defaultPaymentMethod
                                .cardBrand === "amex" && (
                                <img
                                  src={amex}
                                  alt={
                                    membershipDetails.defaultPaymentMethod
                                      .cardBrand
                                  }
                                  className={
                                    classes.defaultPaymentMethodCardBrand
                                  }
                                />
                              )}
                              {membershipDetails.defaultPaymentMethod
                                .cardBrand === "discover" && (
                                <img
                                  src={discover}
                                  alt={
                                    membershipDetails.defaultPaymentMethod
                                      .cardBrand
                                  }
                                  className={
                                    classes.defaultPaymentMethodCardBrand
                                  }
                                />
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}

                  {(paymentMethodAction === "add" ||
                    paymentMethodAction === "update") && (
                    <>
                      <Typography
                        variant="h3"
                        className={classes.myAccountThirdTitle}
                      >
                        New Card Details
                      </Typography>
                      {updateError && (
                        <>
                          {updateError !== "undefined" && (
                            <Alert
                              className={classes.validationError}
                              severity="error"
                              variant="filled"
                            >
                              {updateError}
                            </Alert>
                          )}
                        </>
                      )}
                      {cardInputError && (
                        <>
                          {cardInputError !== "undefined" && (
                            <Alert
                              className={classes.validationError}
                              severity="error"
                              variant="filled"
                            >
                              {cardInputError}
                            </Alert>
                          )}
                        </>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ marginBottom: "15px" }}
                      >
                        <label
                          className={classes.cardElementLabel}
                          htmlFor="cardNumber"
                        >
                          Card Number
                        </label>
                        <CardNumberElement
                          id="cardNumber"
                          className={classes.inputFieldTight}
                          onFocus={clearErrors}
                          options={ELEMENT_OPTIONS}
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        ></div>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          className={classes.midPaymentCreds}
                        >
                          <label
                            className={classes.cardElementLabel}
                            htmlFor="expiry"
                          >
                            Card Expiration
                          </label>
                          <CardExpiryElement
                            id="expiry"
                            className={`${classes.inputField} ${classes.bottomPaymentCreds}`}
                            onFocus={clearErrors}
                            options={ELEMENT_OPTIONS}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          className={classes.midPaymentCreds}
                        >
                          <label
                            className={classes.cardElementLabel}
                            htmlFor="cvc"
                          >
                            CVC
                          </label>
                          <CardCvcElement
                            id="cvc"
                            className={classes.inputField}
                            onFocus={clearErrors}
                            options={ELEMENT_OPTIONS}
                          />
                        </Grid>
                      </Grid>

                      <Button
                        onClick={onSubmitUpdatePaymentMethod}
                        className={classes.paymentButton}
                        // disabled={!stripe || !Subscription.id || submittingButton}
                        // fullWidth={true}
                      >
                        {paymentMethodAction === "update"
                          ? "Update"
                          : paymentMethodAction === "add"
                          ? "Add"
                          : ""}{" "}
                        Payment Method
                      </Button>
                      <Divider className={classes.footerDivider} />
                      <Typography
                        variant="body1"
                        className={classes.bodyTextSml}
                      >
                        Tip! Don&apos;t want to miss out, ensure your Auto Renew
                        is turned on.
                      </Typography>
                    </>
                  )}
                  {paymentMethodAction === "remove" &&
                    membershipDetails.defaultPaymentMethod && (
                      <>
                        <Button
                          onClick={onSubmitRemovePaymentMethod}
                          className={classes.paymentButton}
                        >
                          Remove Payment Method
                        </Button>
                        <Divider className={classes.footerDivider} />
                        <Typography
                          variant="body1"
                          className={classes.bodyTextSml}
                        >
                          You can always add a new Payment Method in the future.
                        </Typography>
                      </>
                    )}
                </Grid>
              </Grid>
            </>
          )}
          {updating && (
            <Box m={5} align="center">
              <CircularProgress variant="indeterminate"></CircularProgress>
              <Typography variant="body2" className={classes.bodyTextSml}>
                <br />
                {paymentMethodAction === "update"
                  ? "Updating"
                  : paymentMethodAction === "add"
                  ? "Adding"
                  : paymentMethodAction === "remove"
                  ? "Removing"
                  : ""}{" "}
                Payment Method. Please do not refresh your browser.
              </Typography>
            </Box>
          )}
        </Grid>
      )}
      {loading && (
        <Grid container>
          <Grid item xs={12} sm={8} md={6}>
            <Box m={5} align="center">
              <CircularProgress variant="indeterminate"></CircularProgress>
            </Box>
          </Grid>
        </Grid>
      )}

      {/* Payment Authentication Modal */}
      <Dialog
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={authConfirming || authConfirmed || authConfirmError}
        className={classes.autoRenewDialog}
        onClose={handleAuthConfirmDialogClose}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.autoRenewDialogTitle}
        >
          Card Authorized
        </DialogTitle>
        <DialogContent dividers>
          {authConfirming ? (
            <CircularProgress
              variant="indeterminate"
              style={{ alignSelf: "center", marginTop: 50, marginBottom: 50 }}
            ></CircularProgress>
          ) : authConfirmed ? (
            <Typography variant="body1" className={classes.tableText}>
              Your card has been successfully authorized, any pending payments
              on your account will be processed within the next few minutes.
            </Typography>
          ) : (
            <Typography variant="body1" className={classes.tableText}>
              We had problems authorizing your card. Please try again.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <Button
                onClick={handleAuthConfirmDialogClose}
                className={classes.dialogButtonSml}
                variant="outlined"
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
