import React, { useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Divider
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { OverviewContext } from "../../store/OverviewContext";

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    padding: theme.spacing(6, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0)
    }
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "4.2rem",
      lineHeight: "4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },

  accordionContainer: {
    marginTop: theme.spacing(3)
  },
  accordionEntry: {
    //@ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    borderRadius: "0 !important",
    border: "1px solid #fff",
    padding: theme.spacing(0.5, 0),
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      padding: theme.spacing(1, 0)
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: theme.spacing(0, 1.5)
    }
  },
  accordionEntryTitle: {
    fontSize: "1.1rem",
    lineHeight: "1.2rem",
    fontWeight: 500,
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem"
    }
  },
  accordionEntryText: {
    fontSize: "0.95rem",
    lineHeight: "1.2rem",
    color: "#eee",

    "& a": {
      color: "#eee"
    }
  },
  accordionEntryExpand: {
    // @ts-ignore
    fill: theme.palette.background.ffGreen,
    fontSize: "2.2rem",
    padding: 0
  },
  membershipOptionWrapper: {
    "@media (min-width:600px)": {
      padding: "12px 20px"
    }
  },
  membershipOptionContainer: {
    textAlign: "center",
    padding: theme.spacing(3, 1.5),
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    "@media (min-width:600px)": {
      padding: theme.spacing(3, 4)
    }
  },
  membershipOptionTitle: {
    // @ts-ignore
    color: theme.palette.background.ffGreen,
    fontSize: "1.5rem",
    marginBottom: theme.spacing(3)
  },
  membershipOptionPrimaryPrice: {
    fontSize: "2rem",
    color: theme.palette.common.white,
    fontWeight: 500,
    marginBottom: theme.spacing(3),
    "@media (min-width:600px)": {
      fontSize: "2.6rem"
    },
    "& span": {
      fontSize: "0.9rem",
      fontWeight: "400"
    }
  },
  membershipOptionDescription: {
    margin: theme.spacing(2, 0)
  },
  smlDivider: {
    height: "2px",
    width: "30%",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffGreen,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  }
}));

const FAQs = () => {
  const classes = useStyles();

  const { overview } = useContext(OverviewContext);

  // 0 = closed
  // 1 = open
  // 2 = both

  const faqs = [
    {
      id: 1,
      title: "What is FarmFLiX?",
      body:
        "FarmFLiX is an online video platform dedicated to delivering relevant agricultural content. We bring the reality of the job to you.",
      open: 2
    },
    {
      id: 2,
      title: "How do I join FarmFLiX?",
      body:
        "FarmFLiX is only opened for new members from the last week of November to the last week of January every year.  For the rest of the year, we do not take on new members, as we're out doing what we do best... capturing real-world agricultural content.  If you have a Giftcard, you can redeem it below.",
      open: 0
    },
    {
      id: 3,
      title: "How much is FarmFLiX Membership?",
      body: (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                style={{ width: "100%" }}
                className={classes.accordionEntryText}
              >
                FarmFLiX offers monthly memberships.
                <br /> <br />
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
              <Card className={classes.membershipOptionContainer}>
                <Typography className={classes.membershipOptionTitle}>
                  Annual
                </Typography>
                <Typography className={classes.membershipOptionPrimaryPrice}>
                  &pound;60.00<span>/year</span>
                </Typography>
                <Divider className={classes.smlDivider} />
                <Typography
                  className={classes.membershipOptionDescription}
                  variant="body2"
                >
                  Our Annual Membership
                </Typography>
                <Typography style={{ margin: "12px 0" }} variant="body2">
                  or
                </Typography>
                <Typography
                  className={classes.membershipOptionDescription}
                  variant="body1"
                >
                  <b>&euro;70.00</b> / <b>&#36;85.00</b>
                </Typography>
              </Card>
            </Grid> */}
            <Grid item xs={6}>
              <Card className={classes.membershipOptionContainer}>
                <Typography className={classes.membershipOptionTitle}>
                  Monthly
                </Typography>
                <Typography className={classes.membershipOptionPrimaryPrice}>
                  &pound;7.99<span>/month</span>
                </Typography>
                <Divider className={classes.smlDivider} />
                <Typography
                  className={classes.membershipOptionDescription}
                  variant="body2"
                >
                  Our Monthly Membership
                </Typography>
                <Typography style={{ margin: "12px 0" }} variant="body2">
                  or
                </Typography>
                <Typography
                  className={classes.membershipOptionDescription}
                  variant="body1"
                >
                  <b>&euro;8.99</b> / <b>&#36;9.99</b>
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Fragment>
      ),
      open: 1
    },
    {
      id: 5,
      title: "How do I take a Free Trial?",
      body: (
        <Fragment>
          Simple. Start your Free Trial&nbsp;<Link to="/try">here</Link>. For
          more information on the Free Trial, go&nbsp;
          <Link to="/free-trial">here</Link>.
        </Fragment>
      ),
      open: 0
    },
    {
      id: 6,
      title: "Can I buy FarmFLiX Membership as a gift?",
      body:
        "Unfortunatly you can't gift FarmFLiX at present as the Membership is currently Closed.",
      open: 0
    },
    {
      id: 7,
      title: "Can I buy FarmFLiX Membership as a gift?",
      body: (
        <p>
          Yes, you can buy a 3 Month or 12 Month Digital Pass from the&nbsp;
          <a
            href="http://shop.farmflix.tv"
            rel="noopener noreferrer"
            target="_blank"
          >
            FarmFLiX Shop
          </a>
          . Digital Passes are sent instantly to the purchaser&apos;s email
          address on successful payment.
        </p>
      ),
      open: 1
    },
    {
      id: 8,
      title: "How can I watch FarmFLiX on my TV?",
      body:
        "Install the FarmFLiX App, which supports Google Chromecast and Apple AirPlay, or the FarmFLiX Amazon Fire TV App.",
      open: 2
    },
    {
      id: 9,
      title: <Fragment>I&apos;m unable to log into FarmFLiX</Fragment>,
      body: (
        <p>
          Use the Forgot Password button on the{" "}
          <Link to="/login">Login Page</Link>, or you can reset your password{" "}
          <Link to="/forgotpassword">here</Link>. Still no joy? Get in touch
          with the team.
        </p>
      ),
      open: 2
    },
    {
      id: 10,
      title:
        "My Account says I'm Locked Out / Need to Upgrade but I've already paid",
      body:
        "You most likely have set up more than one account and are logging into an unpaid one. Check you are using the correct email. Still no joy? Get in touch with the team.",
      open: 2
    },
    {
      id: 11,
      title: "How can I renew/cancel my Membership?",
      body: "Log in and manage your Membership in the 'My Account' area.",
      open: 2
    },
    {
      id: 12,
      title: "How do I redeem a Giftcard?",
      body: (
        <p>
          Simple. You can redeem a giftcard&nbsp;
          <Link to="/usemycode">here</Link>.
        </p>
      ),
      open: 2
    },
    {
      id: 13,
      title: "Is FarmFLiX suitable for kids?",
      body:
        "Absolutely.  All our content is family-friendly, showcasing the events of real-world farming.",
      open: 2
    }
  ];

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="lg" fixed>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.title}>
                FarmFLiX
              </Typography>
              <Typography variant="h2" className={classes.titleLarge}>
                FAQ&apos;s
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {faqs.map((q, i) => (
                <>
                  {q.open === 2 && (
                    <Accordion className={classes.accordionEntry} key={q.id}>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            className={classes.accordionEntryExpand}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.accordionEntryTitle}>
                          {q.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionEntryText}>
                        {/* <Typography className={classes.accordionEntryText}> */}
                        {q.body}
                        {/* </Typography> */}
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {overview && overview.doors ? (
                    overview.doors.open ? (
                      <>
                        {q.open === 1 && (
                          <Accordion
                            className={classes.accordionEntry}
                            key={q.id}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  className={classes.accordionEntryExpand}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography
                                className={classes.accordionEntryTitle}
                              >
                                {q.title}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              className={classes.accordionEntryText}
                            >
                              {/* <Typography

                              > */}
                              {q.body}
                              {/* </Typography> */}
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </>
                    ) : (
                      <>
                        {q.open === 0 && (
                          <Accordion
                            className={classes.accordionEntry}
                            key={q.id}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  className={classes.accordionEntryExpand}
                                />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography
                                className={classes.accordionEntryTitle}
                              >
                                {q.title}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              className={classes.accordionEntryText}
                            >
                              {q.body}
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </>
                    )
                  ) : null}
                </>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default FAQs;
