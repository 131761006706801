import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid, Box } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import "./../../../node_modules/react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(6, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0)
    }
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "2.6rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "4.2rem",
      lineHeight: "4rem"
    },
    "@media (min-width:1280px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },
  carouselContainer: {
    marginBottom: theme.spacing(2),
    "& .carousel .slide": {
      //@ts-ignore
      backgroundColor: theme.palette.background.ffNavyLighter,
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3, 1),
      position: "relative"
    }
  },
  testimonialContent: {
    padding: theme.spacing(3),
    //@ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    fontSize: "1.1rem",
    lineHeight: "1.3rem",
    fontWeight: 500,
    letterSpacing: "-1px",
    "@media (min-width:600px)": {
      fontSize: "1.3rem",
      lineHeight: "1.5rem"
    }
  }
}));

const Testimonials = ({ data }: { data: any | undefined }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="lg" fixed>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.title}>
                What The
              </Typography>
              <Typography variant="h2" className={classes.titleLarge}>
                Members Say
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.carouselContainer}>
              <Carousel
                useKeyboardArrows={true}
                showArrows={false}
                interval={7000}
                showThumbs={false}
                infiniteLoop={true}
                showStatus={false}
                autoPlay={true}
                showIndicators={true}
                transitionTime={1200}
              >
                {data.map((i: any, idx: any) => (
                  <Box key={idx}>
                    <Typography
                      variant="body1"
                      className={classes.testimonialContent}
                    >
                      {i.testimonial}
                    </Typography>
                  </Box>
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Testimonials;
