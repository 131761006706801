import { useContext } from "react";
import { LoggerContext } from "../store/LoggerContext";

export enum LogEventCategory {
  PLAYBACK_ACTION = "playback-action",
  INTERACTION = "interaction",
  ACCOUNT_ACTION = "account-action",
  HARDWARE = "hardware"
}

export enum PlaybackEvents {
  PLAYBACK_STARTED = "playback-started",
  PLAYBACK_PROGRESS = "playback-progress",
  PLAYBACK_FINISHED = "playback-finished",
  CASTING_CONNECTED = "casting-connected",
  CASTING_DISCONNECTED = "casting-disconnected"
}

const useLogger = () => {
  const { logEvent, logMetricEvent } = useContext(LoggerContext);

  return { logEvent, logMetricEvent };
};

export default useLogger;
