import React, { useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MemberSurveyContext } from "../../store/MemberSurveyContext";
import {
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormGroup,
  Radio,
  TextField,
  Button,
  Checkbox,
  Typography,
  Divider
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    // @ts-ignore
  },
  stepHeading: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    color: theme.palette.background.ffNavyLighter,
    fontWeight: 600,
    // textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    }
  },
  divider: {
    height: "1px",
    margin: "6px 0 12px 0",
    backgroundColor: "#b7b7b7"
  },
  formStep: {
    marginTop: "24px"
  },
  formQuestion: {
    marginBottom: "36px",
    "& .MuiFormControl-root": {
      width: "100%"
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem",
      marginBottom: "18px"
    },
    "& .MuiTypography-body1": {
      color: "#3d3d3d"
    }
  },
  formGroup: {
    "& .MuiFormControl-root label": {
      marginBottom: "6px"
    },
    "& .MuiTypography-body1": {
      marginBottom: "6px"
    }
  },
  buttonContainer: {
    margin: "24px 0"
  },
  subHeading: {
    margin: "24px 0 12px 0",
    fontSize: "1.1rem"
  },
  stepButton: {
    border: "none",
    "&:hover": {
      border: "none",
      backgroundColor: "#99ca3c"
    }
  },
  formControlDesc: {
    margin: "6px 0 0px 0",
    color: "#696969"
  },
  topBump: {
    marginTop: "24px"
  }
}));

export default function SecondStep() {
  const classes = useStyles();

  const { formValues, handleChange, handleNext, variant } = useContext(
    MemberSurveyContext
  );
  const {
    age,
    gender,
    growUpOnFarm,
    regionGrowUp,
    regionGrowUpOther,
    regionLivingIn,
    regionLivingInOther,
    job,
    connectionToFarming1,
    connectionToFarming2,
    connectionToFarming3,
    connectionToFarming4,
    connectionToFarming5,
    connectionToFarming6,
    connectionToFarming7,
    connectionToFarming9,
    connectionToFarming10,
    connectionToFarming11,
    connectionToFarming12,
    connectionToFarming13,
    connectionToFarming14,
    connectionToFarmingOther,
    connectionToFarmingFarmService,
    connectionToFarmingFarmServiceRole,
    connectionToFarmingFarmProduct,
    connectionToFarmingFarmProductRole,
    connectionToFarmingFarmEnthusiast
  } = formValues;

  const isError = useCallback(
    () =>
      Object.keys({ age, job }).some(
        name =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, age, job]
  );

  return (
    <>
      <Grid container spacing={2} className={classes.formStep}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.stepHeading}>
            About You
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        {/* Age */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              What age are you?
            </FormLabel>
            <TextField
              variant={variant}
              // fullWidth
              name="age"
              placeholder="Your age"
              value={age.value}
              onChange={handleChange}
              error={!!age.error}
              helperText={age.error}
              required={age.required}
            />
          </FormControl>
        </Grid>

        {/* Gender */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Are you:</FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="gender"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Male"
                checked={gender.value === "Male"}
                control={<Radio />}
                label="Male"
                required={true}
              />
              <FormControlLabel
                value="Female"
                checked={gender.value === "Female"}
                control={<Radio />}
                label="Female"
                required={true}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* Grow Up on a Farm */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Did you grow up on a farm?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="growUpOnFarm"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Yes"
                checked={growUpOnFarm.value === "Yes"}
                control={<Radio />}
                label="Yes"
                required={true}
              />
              <FormControlLabel
                value="No"
                checked={growUpOnFarm.value === "No"}
                control={<Radio />}
                label="No"
                required={true}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* Region Grow Up */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              What region did you grow up in?
            </FormLabel>
            <RadioGroup onChange={handleChange} name="regionGrowUp">
              <FormControlLabel
                value="Northern Ireland"
                checked={regionGrowUp.value === "Northern Ireland"}
                control={<Radio />}
                label="Northern Ireland"
              />
              <FormControlLabel
                value="Republic of Ireland"
                checked={regionGrowUp.value === "Republic of Ireland"}
                control={<Radio />}
                label="Republic of Ireland"
              />
              <FormControlLabel
                value="England"
                checked={regionGrowUp.value === "England"}
                control={<Radio />}
                label="England"
              />
              <FormControlLabel
                value="Scotland"
                checked={regionGrowUp.value === "Scotland"}
                control={<Radio />}
                label="Scotland"
              />
              <FormControlLabel
                value="Wales"
                checked={regionGrowUp.value === "Wales"}
                control={<Radio />}
                label="Wales"
              />
              <FormControlLabel
                value="Other"
                checked={regionGrowUp.value === "Other"}
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          {regionGrowUp.value === "Other" && (
            <Grid item xs={12} sm={12} className={classes.topBump}>
              <FormLabel id="demo-radio-buttons-group-label">
                Please specify
              </FormLabel>
              <TextField
                variant={variant}
                fullWidth
                name="regionGrowUpOther"
                placeholder=""
                value={regionGrowUpOther.value}
                onChange={handleChange}
                error={!!regionGrowUpOther.error}
                helperText={regionGrowUpOther.error}
                required={regionGrowUpOther.required}
              />
            </Grid>
          )}
        </Grid>

        {/* Region Living In */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              What region are you currently living in?
            </FormLabel>
            <RadioGroup onChange={handleChange} name="regionLivingIn">
              <FormControlLabel
                value="Northern Ireland"
                checked={regionLivingIn.value === "Northern Ireland"}
                control={<Radio />}
                label="Northern Ireland"
              />
              <FormControlLabel
                value="Republic of Ireland"
                checked={regionLivingIn.value === "Republic of Ireland"}
                control={<Radio />}
                label="Republic of Ireland"
              />
              <FormControlLabel
                value="England"
                checked={regionLivingIn.value === "England"}
                control={<Radio />}
                label="England"
              />
              <FormControlLabel
                value="Scotland"
                checked={regionLivingIn.value === "Scotland"}
                control={<Radio />}
                label="Scotland"
              />
              <FormControlLabel
                value="Wales"
                checked={regionLivingIn.value === "Wales"}
                control={<Radio />}
                label="Wales"
              />
              <FormControlLabel
                value="Other"
                checked={regionLivingIn.value === "Other"}
                control={<Radio />}
                label="Other..."
              />
            </RadioGroup>
          </FormControl>
          {regionLivingIn.value === "Other" && (
            <Grid item xs={12} sm={12} className={classes.topBump}>
              <FormLabel id="demo-radio-buttons-group-label">
                Please specify
              </FormLabel>
              <TextField
                variant={variant}
                fullWidth
                name="regionLivingInOther"
                placeholder=""
                value={regionLivingInOther.value}
                onChange={handleChange}
                error={!!regionLivingInOther.error}
                helperText={regionLivingInOther.error}
                required={regionLivingInOther.required}
              />
            </Grid>
          )}
        </Grid>

        {/* Job */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              What is your current job?
            </FormLabel>
            <TextField
              variant={variant}
              fullWidth
              name="job"
              placeholder="Your job"
              value={job.value}
              onChange={handleChange}
              error={!!job.error}
              helperText={job.error}
              required={job.required}
            />
          </FormControl>
        </Grid>

        {/* Connection to Farming */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              What is your connection to farming?
              <Typography className={classes.formControlDesc} variant="body2">
                FT - Full Time | PT - Part Time
              </Typography>
            </FormLabel>
            <FormGroup>
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming1"
                      onChange={handleChange}
                      value={connectionToFarming1.value}
                      checked={connectionToFarming1.value}
                    />
                  }
                  label="Active Farm Owner (F/T)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming2"
                      onChange={handleChange}
                      checked={connectionToFarming2.value}
                      value={connectionToFarming2.value}
                    />
                  }
                  label="Active Farm Owner (P/T)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming3"
                      onChange={handleChange}
                      checked={connectionToFarming3.value}
                      value={connectionToFarming3.value}
                    />
                  }
                  label="Farm Manager (F/T)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming4"
                      onChange={handleChange}
                      checked={connectionToFarming4.value}
                      value={connectionToFarming4.value}
                    />
                  }
                  label="Farm Manager (P/T)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming5"
                      onChange={handleChange}
                      checked={connectionToFarming5.value}
                      value={connectionToFarming5.value}
                    />
                  }
                  label="Farm Worker (F/T)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming6"
                      onChange={handleChange}
                      checked={connectionToFarming6.value}
                      value={connectionToFarming6.value}
                    />
                  }
                  label="Farm Worker (P/T)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming7"
                      onChange={handleChange}
                      checked={connectionToFarming7.value}
                      value={connectionToFarming7.value}
                    />
                  }
                  label="Machinery Contractor (Owner)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming9"
                      onChange={handleChange}
                      checked={connectionToFarming9.value}
                      value={connectionToFarming9.value}
                    />
                  }
                  label="Machinery Operator (F/T)"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming10"
                      onChange={handleChange}
                      checked={connectionToFarming10.value}
                      value={connectionToFarming10.value}
                    />
                  }
                  label="Machinery Operator (P/T)"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming11"
                      onChange={handleChange}
                      checked={connectionToFarming11.value}
                      value={connectionToFarming11.value}
                    />
                  }
                  label="Farm Service Supplier"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming12"
                      onChange={handleChange}
                      checked={connectionToFarming12.value}
                      value={connectionToFarming12.value}
                    />
                  }
                  label="Farm Product Supplier"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming13"
                      onChange={handleChange}
                      checked={connectionToFarming13.value}
                      value={connectionToFarming13.value}
                    />
                  }
                  label="Farm Enthusiast (not currently engaged in regular on-farm activity)"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="connectionToFarming14"
                      onChange={handleChange}
                      checked={connectionToFarming14.value}
                      value={connectionToFarming14.value}
                    />
                  }
                  label="Other"
                />
              </>
            </FormGroup>
          </FormControl>

          {/* If Farm Service Supplier is checked */}
          {connectionToFarming11.value === true && (
            <>
              <Typography variant="h6" className={classes.subHeading}>
                Farm Service Supplier
              </Typography>
              <Grid item xs={12} sm={12}>
                <FormLabel id="demo-radio-buttons-group-label">
                  What service do you supply?
                </FormLabel>
                <TextField
                  variant={variant}
                  fullWidth
                  name="connectionToFarmingFarmService"
                  placeholder=""
                  value={connectionToFarmingFarmService.value}
                  onChange={handleChange}
                  error={!!connectionToFarmingFarmService.error}
                  helperText={connectionToFarmingFarmService.error}
                  required={connectionToFarmingFarmService.required}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ marginTop: "24px" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  What is your role in the service business?
                </FormLabel>
                <TextField
                  variant={variant}
                  fullWidth
                  name="connectionToFarmingFarmServiceRole"
                  placeholder=""
                  value={connectionToFarmingFarmServiceRole.value}
                  onChange={handleChange}
                  error={!!connectionToFarmingFarmServiceRole.error}
                  helperText={connectionToFarmingFarmServiceRole.error}
                  required={connectionToFarmingFarmServiceRole.required}
                />
              </Grid>
            </>
          )}

          {/* If Farm Product Supplier is checked */}
          {connectionToFarming12.value === true && (
            <>
              <Typography variant="h6" className={classes.subHeading}>
                Farm Product Supplier
              </Typography>
              <Grid item xs={12} sm={12}>
                <FormLabel id="demo-radio-buttons-group-label">
                  What product do you supply?
                </FormLabel>
                <TextField
                  variant={variant}
                  fullWidth
                  name="connectionToFarmingFarmProduct"
                  placeholder=""
                  value={connectionToFarmingFarmProduct.value}
                  onChange={handleChange}
                  error={!!connectionToFarmingFarmProduct.error}
                  helperText={connectionToFarmingFarmProduct.error}
                  required={connectionToFarmingFarmProduct.required}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ marginTop: "24px" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  What is your role in the product business?
                </FormLabel>
                <TextField
                  variant={variant}
                  fullWidth
                  name="connectionToFarmingFarmProductRole"
                  placeholder=""
                  value={connectionToFarmingFarmProductRole.value}
                  onChange={handleChange}
                  error={!!connectionToFarmingFarmProductRole.error}
                  helperText={connectionToFarmingFarmProductRole.error}
                  required={connectionToFarmingFarmProductRole.required}
                />
              </Grid>
            </>
          )}

          {/* If Farm Enthusiast is checked  */}
          {connectionToFarming13.value === true && (
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" className={classes.subHeading}>
                Farm Enthusiast
              </Typography>

              <FormLabel id="demo-radio-buttons-group-label">
                Why does farming interest you?
              </FormLabel>
              <TextField
                multiline={true}
                variant={variant}
                rows={5}
                fullWidth
                name="connectionToFarmingFarmEnthusiast"
                placeholder=""
                value={connectionToFarmingFarmEnthusiast.value}
                onChange={handleChange}
                error={!!connectionToFarmingFarmEnthusiast.error}
                helperText={connectionToFarmingFarmEnthusiast.error}
                required={connectionToFarmingFarmEnthusiast.required}
              />
            </Grid>
          )}

          {/* If 'Other' is checked  */}
          {connectionToFarming14.value === true && (
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" className={classes.subHeading}>
                Other, please specify.
              </Typography>
              <TextField
                variant={variant}
                fullWidth
                name="connectionToFarmingOther"
                placeholder=""
                value={connectionToFarmingOther.value}
                onChange={handleChange}
                error={!!connectionToFarmingOther.error}
                helperText={connectionToFarmingOther.error}
                required={connectionToFarmingOther.required}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.buttonContainer}>
        <Box
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}
        >
          <Button
            className={classes.stepButton}
            variant="contained"
            disabled={isError()}
            color="primary"
            onClick={!isError() ? handleNext : () => null}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </>
  );
}
