import React, { useState, useContext, useCallback, useEffect } from "react";
import { AccountContext } from "./../../store/AccountContext";
import { Grid, Typography, Button, Box, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useToasts } from "react-toast-notifications";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  myAccountThirdTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem"
    }
  },
  bodyText: {
    color: theme.palette.background.ffNavy
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem"
  },
  cardContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  inputLabel: {},
  inputField: {
    padding: "12px",
    border: "1px solid #a5a5a5",
    backgroundColor: "#f7f7f7",
    margin: "4px 0 15px 0",
    fontSize: "16px",
    borderRadius: "5px"
  },
  inputFieldTight: {
    padding: "12px",
    border: "1px solid #a5a5a5",
    backgroundColor: "#f7f7f7",
    margin: "4px 0 8px 0",
    fontSize: "16px",
    borderRadius: "5px"
  },
  midPaymentCreds: {
    marginTop: "4px",
    "@media (min-width:600px)": {
      marginTop: 0
    }
  },
  cardElementLabel: {
    color: "#656565",
    fontSize: "1rem",
    fontWeight: 500,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  },
  bottomPaymentCreds: {
    marginBottom: 0
  },
  paymentButton: {
    margin: "10px 0",
    width: "100%",
    "@media (min-width:600px)": {
      marginTop: 0,
      width: "auto"
    }
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "5% 0 2% 0"
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    color: theme.palette.background.ffNavy,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  },
  cardValidation: {
    marginBottom: theme.spacing(2)
  },
  defaultPaymentMethodContainer: {
    border: "1px solid #a5a5a5",
    borderRadius: "5px",
    backgroundColor: "#dedede",
    padding: theme.spacing(1.2),
    marginBottom: theme.spacing(3.5)
  },
  defaultPaymentCardNumber: {
    textAlign: "left",
    color: theme.palette.background.ffNavy,
    fontWeight: 500
  },
  defaultPaymentMethodText: {
    textAlign: "left",
    color: theme.palette.background.ffNavy
  },
  defaultPaymentMethodExpDate: {
    color: theme.palette.background.ffNavy,
    fontWeight: 400,
    fontSize: "0.9rem"
  },
  defaultPaymentMethodCardBrand: {
    width: "50px",
    maxWidth: "50px",
    paddingRight: "9px",
    paddingTop: "6px"
  },
  defaultPaymentMethodChange: {
    color: theme.palette.background.ffGreen,
    fontWeight: 500,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  defaultPaymentMethodLabel: {
    color: theme.palette.background.ffNavy,
    textAlign: "left",
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    marginTop: "20px",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
  },
  validationError: {
    margin: theme.spacing(2, 0)
  }
}));

export default function AddPaymentMethodAutoRenew() {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { addToast } = useToasts();
  const history = useHistory();

  // Account Context
  const {
    updating,
    handleCardSubmit,
    handleResumeMembership,
    validateLogin,
    resetErrors,
    updateError
  } = useContext(AccountContext);

  // State
  const [cardInputError, setCardInputError] = useState(null);

  useEffect(() => {
    return () => resetErrors();
    // eslint-disable-next-line
  }, []);

  const clearErrors = event => {
    setCardInputError("");
    resetErrors();
  };

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        "::placeholder": {
          color: "#ccc"
        }
      },
      invalid: {
        color: "#b92020"
      }
    }
  };

  const onSubmitUpdatePaymentMethod = useCallback(
    async event => {
      event.preventDefault();

      const cardElement = elements.getElement(CardNumberElement);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
      });

      if (error) {
        console.error("Error creating payment method", error);
        setCardInputError(error.message);
        return;
      }

      if (paymentMethod) {
        try {
          const updateCard = await handleCardSubmit(paymentMethod.id);

          if (updateCard) {
            await handleResumeMembership();

            addToast(
              "Payment Method successfully added and Auto Renew turned on.",
              {
                appearance: "success"
              }
            );
            await validateLogin();
            history.push("/myaccount/overview");
          }
        } catch (e) {
          console.log(e);
          addToast("Error:" + e, {
            appearance: "error"
          });
        }
      }
    },
    [
      addToast,
      elements,
      stripe,
      handleCardSubmit,
      handleResumeMembership,
      history,
      validateLogin
    ]
  );

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumbContainer}
        >
          <Link className={classes.activeBreadcrumb} to="/myaccount/overview">
            Overview
          </Link>
          <Typography className={classes.disabledBreadcrumb}>
            Add Payment Method and Turn On Auto Renew
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h2" className={classes.myAccountSubTitle}>
          Add Payment Method and Turn On Auto Renew
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Provide the new details here.
        </Typography>
      </Grid>
      <Grid container className={classes.cardContainer}>
        {!updating && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={6}>
                <Typography
                  variant="h3"
                  className={classes.myAccountThirdTitle}
                >
                  New Card Details
                </Typography>
                {updateError && (
                  <>
                    {updateError !== "undefined" && (
                      <Alert
                        className={classes.validationError}
                        severity="error"
                        variant="filled"
                      >
                        {updateError}
                      </Alert>
                    )}
                  </>
                )}
                {cardInputError && (
                  <>
                    {cardInputError !== "undefined" && (
                      <Alert
                        className={classes.validationError}
                        severity="error"
                        variant="filled"
                      >
                        {cardInputError}
                      </Alert>
                    )}
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginBottom: "15px" }}
                >
                  <label
                    className={classes.cardElementLabel}
                    htmlFor="cardNumber"
                  >
                    Card Number
                  </label>
                  <CardNumberElement
                    id="cardNumber"
                    className={classes.inputFieldTight}
                    onFocus={clearErrors}
                    options={ELEMENT_OPTIONS}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  ></div>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    className={classes.midPaymentCreds}
                  >
                    <label
                      className={classes.cardElementLabel}
                      htmlFor="expiry"
                    >
                      Card Expiration
                    </label>
                    <CardExpiryElement
                      id="expiry"
                      className={`${classes.inputField} ${classes.bottomPaymentCreds}`}
                      onFocus={clearErrors}
                      options={ELEMENT_OPTIONS}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    className={classes.midPaymentCreds}
                  >
                    <label className={classes.cardElementLabel} htmlFor="cvc">
                      CVC
                    </label>
                    <CardCvcElement
                      id="cvc"
                      className={classes.inputField}
                      onFocus={clearErrors}
                      options={ELEMENT_OPTIONS}
                    />
                  </Grid>
                </Grid>

                <Button
                  onClick={onSubmitUpdatePaymentMethod}
                  className={classes.paymentButton}
                >
                  Add Payment Method
                </Button>
                <Divider className={classes.footerDivider} />
                <Typography
                  variant="body1"
                  className={classes.bodyTextSml}
                ></Typography>
              </Grid>
            </Grid>
          </>
        )}
        {updating && (
          <Box m={5} align="center">
            <CircularProgress variant="indeterminate"></CircularProgress>
            <Typography variant="body2" className={classes.bodyTextSml}>
              <br />
              Adding Payment Method. Please do not refresh your browser.
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
