import React, { useCallback, useEffect, useMemo, useRef } from "react";
import videojs from "video.js";
import "videojs-http-source-selector";
import "videojs-contrib-quality-levels";
import "./OtherPlayer.scss";
import "videojs-vtt-thumbnails";
import "videojs-mux";
import throttle from "lodash/throttle";
import { PlayerProps } from "./types";
import { MUX_DATA_ENV_KEY } from "../../../env";

require("@silvermine/videojs-chromecast")(videojs);
require("@silvermine/videojs-airplay")(videojs);

const muxRgx = /^https:\/\/stream\.mux\.com\/([a-zA-Z0-9_-]{3,}).m3u8/i;

export const OtherPlayer = ({
  video: videoProp,
  onStart: onStartProp,
  onProgress: onProgressProp,
  type,
  userId
}: PlayerProps) => {
  const [started, setStarted] = React.useState(false);
  const videoRef = useRef(null);
  const innerPlayerRef = useRef<videojs.Player>();
  const { id, sources, thumbnail, progress } = videoProp;
  const { hlsSource, vttSource } = useMemo(() => {
    const hlsSource = sources?.find(
      source => source.type.toLowerCase() === "application/x-mpegurl"
    );

    if (hlsSource?.src) {
      let vttSource: string | undefined = undefined;
      if (muxRgx.test(hlsSource.src)) {
        const hlsSourceMuxMatch = muxRgx.exec(hlsSource.src);
        console.log("mux match", hlsSourceMuxMatch);
        if (hlsSourceMuxMatch && hlsSourceMuxMatch.length > 1) {
          vttSource = `https://image.mux.com/${hlsSourceMuxMatch[1]}/storyboard.vtt`;
        }
      }

      return { hlsSource, vttSource };
    }

    return { hlsSource: undefined, vttSource: undefined };
  }, [sources]);

  const onLoaded = useCallback(() => {
    if (!innerPlayerRef.current || !progress) return;

    innerPlayerRef.current.currentTime(parseFloat(String(progress)));
  }, [innerPlayerRef, progress]);

  const onStart = useCallback(() => {
    if (!id || !onStartProp || started) {
      return;
    }

    setStarted(true);
    onStartProp();
  }, [id, onStartProp, started]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onProgress = useCallback(
    throttle(
      (ref: any) => {
        if (!onProgressProp || !ref?.target?.player) return;

        let currentTime = ref?.target?.player?.currentTime?.();

        if (currentTime) {
          onProgressProp(Math.floor(currentTime));
          return;
        }
      },
      2000,
      { leading: true, trailing: true }
    ),
    [started, onProgressProp]
  );

  useEffect(() => {
    if (videoRef.current && hlsSource) {
      const video = videoRef.current;

      // @ts-ignore
      innerPlayerRef.current = videojs(video, {
        autoplay: false,
        controls: true,
        fluid: true,
        preload: "auto",
        userActions: {
          hotkeys: true
        },
        controlBar: {
          pictureInPictureToggle: true,
          playbackRateMenuButton: false,
          subtitlesButton: true
        },
        plugins: {
          httpSourceSelector: {
            default: "auto"
          },
          mux: {
            debug: false,
            data: {
              env_key: MUX_DATA_ENV_KEY,
              viewer_user_id: userId,
              video_id: videoProp.id,
              video_title: videoProp.name,
              player_software_version: "7.18.1",
              player_name: "video.js",
              video_duration:
                type === "master"
                  ? (videoProp.masterLengthSecs || 0) * 1000
                  : (videoProp.previewLengthSecs || 0) * 1000,
              video_stream_type: "on-demand",
              viewer_device_category: "web"
            }
          }
        },
        poster: thumbnail,
        sources: [{ src: hlsSource.src, type: hlsSource.type }],
        techOrder: ["chromecast", "html5"]
      });

      // @ts-ignore
      innerPlayerRef.current.chromecast();
      // @ts-ignore
      innerPlayerRef.current.airPlay();

      if (vttSource) {
        // @ts-ignore
        innerPlayerRef.current?.vttThumbnails({
          src: vttSource
        });
      }

      innerPlayerRef.current.on("play", () => {
        innerPlayerRef.current?.bigPlayButton.hide();
      });

      innerPlayerRef.current.on("pause", () => {
        innerPlayerRef.current?.bigPlayButton.show();
      });

      innerPlayerRef.current?.on("timeupdate", onProgress);
      innerPlayerRef.current?.on("loadstart", onLoaded);
      innerPlayerRef.current?.on("play", onStart);
    }

    return () => {
      if (innerPlayerRef.current) {
        // @ts-ignore
        innerPlayerRef.current.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, hlsSource, thumbnail, vttSource]);

  return (
    <div className="player-container">
      <video ref={videoRef} className="video video-js vjs-16-9" />
    </div>
  );
};
