import React, { useContext, useEffect } from "react";
import { OverviewContext } from "../../store/OverviewContext";
import { AccountContext } from "../../store/AccountContext";
import { Redirect, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import SignUpForm from "./SignUp/SignUpForm";
import { APPLICATION_SOURCE } from "@booyaltd/core";
import {
  MEMBER_STATUS_STANDARD,
  MEMBER_STATUS_TRIAL
} from "../../api/entities/UserProfile";
import DefaultLayout from "../../layouts/DefaultLayout";
import BackdropLoader from "../common/BackdropLoader";
import {
  getAnalytics,
  logEvent as logEventFirebase,
  setUserProperties
} from "firebase/analytics";
import { firebaseApp } from "../../store/LoggerContext";
import TrialsDisabled from "../FreeTrial/TrialsDisabled";

const TryGeneric = () => {
  const { overview } = useContext(OverviewContext);
  const { loggedIn, userProfile } = useContext(AccountContext);
  const location = useLocation();

  // purposely only run on mount so we dont redirect during sign up
  useEffect(() => {
    logEventFirebase(getAnalytics(firebaseApp), "seen_landing_page_2");
    setUserProperties(getAnalytics(firebaseApp), {
      seen_landing_page_2: "1"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (overview && !overview.doors?.open) {
    return <Redirect to="/" />;
  }

  if (overview && !overview.doors?.trialSMSEnabled) {
    return <TrialsDisabled />;
  }

  return (
    <DefaultLayout showTry={false} showSignIn={false}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          FarmFLiX.tv | Buy FarmFLiX! Not sure? Take a Free 72H Trial
        </title>
        <meta
          name="description"
          content="Buy FarmFLiX! Not sure? Take a Free 72H Trial"
        />
      </Helmet>

      {loggedIn &&
      (userProfile?.memberStatus === MEMBER_STATUS_STANDARD ||
        userProfile?.memberStatus === MEMBER_STATUS_TRIAL) ? (
        <Redirect to="/videos" />
      ) : null}

      <SignUpForm
        source={APPLICATION_SOURCE.PUBLIC}
        page={location.pathname}
        title="Join FarmFLiX"
        background={true}
        route="buy"
      />
      {!overview && <BackdropLoader />}
    </DefaultLayout>
  );
};

export default TryGeneric;
