import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Grid,
  CircularProgress
} from "@material-ui/core";
//@ts-ignore
import { Player } from "../common/player";
import { PlayerContext } from "../../store/PlayerContext";
const useStyles = makeStyles(theme => ({
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavyLighter,
    padding: theme.spacing(5, 0, 6, 0),
    "@media (min-width:600px)": {
      padding: theme.spacing(8, 0, 10, 0)
    }
  },
  title: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    }
  },
  titleLarge: {
    letterSpacing: "-2px",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "5.4rem",
      lineHeight: "5rem"
    }
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "80%"
    },
    "@media (min-width:960px)": {}
  },
  secondaryButton: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.white,
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    border: "2px solid #fff",
    marginLeft: 0,
    marginRight: 0,
    "&:hover": {
      border: "2px solid #99ca3c",
      // @ts-ignore
      color: theme.palette.background.ffGreen
    },
    "@media (min-width:600px)": {
      width: "80%"
    }
  },
  videoContainer: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    // border: "8px solid #fafafa",
    width: "100%",
    "@media (min-width:600px)": {
      margin: "0 auto",
      padding: theme.spacing(4)
    }
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%"
  },
  player: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    "& video": {
      "&:focus": {
        outline: "none"
      }
    }
  },
  videoPlayerWrapper: {
    position: "relative",
    margin: "20px 0"
  },
  videoPlayer: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    "& video": {
      "&:focus": {
        outline: "none"
      }
    }
  },
  playIcon: {
    fontSize: "6rem",
    "@media (min-width:600px)": {
      fontSize: "7rem"
    },
    "@media (min-width:960px)": {
      fontSize: "8rem"
    }
  }
}));

const Video = () => {
  const classes = useStyles();

  // Slug of Mammy Challenge Video
  const videoId = "free-trial-compilation"; // <-- New slug required

  const {
    loadPreviewVideo,
    currentPreviewVideo,
    clearCurrentPreviewVideo
  } = useContext(PlayerContext);

  useEffect(() => {
    return () => {
      clearCurrentPreviewVideo();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (videoId) {
      loadPreviewVideo(videoId, "slug");

      console.log(currentPreviewVideo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="md" fixed>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.title}>
                We Produce <span>Real World</span>
              </Typography>
              <Typography variant="h2" className={classes.titleLarge}>
                Agricultural Content
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.videoPlayerWrapper}
            >
              {currentPreviewVideo ? (
                <Player
                  userId={undefined}
                  video={currentPreviewVideo}
                  type="preview"
                />
              ) : (
                <CircularProgress color="primary" />
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Video;
