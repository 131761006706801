import React, { useContext, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Avatar,
  Tooltip,
  Toolbar,
  Popover,
  Box,
  Drawer,
  Divider,
  Badge,
  ListItemText,
  ListItem,
  Typography,
  List,
  IconButton,
  Hidden
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import logo from "./nav-logo.svg";
import { AccountContext } from "../../store/AccountContext";
import { useHistory } from "react-router";

import NotificationsIcon from "@material-ui/icons/Notifications";
import { OverviewContext } from "../../store/OverviewContext";

import SearchModalIconButton from "../Search/trigger/SearchModalIconButton";
import { MEMBER_STATUS_STANDARD, MEMBER_STATUS_TRIAL } from "@booyaltd/core";

import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  logo: {
    width: "130px",
    height: "auto",
    padding: "10px 0"
  },
  iconContainer: {
    padding: "0px 10px"
  },
  dropdownMenu: {
    backgroundColor: "#171717"
  },
  buttonIcon: {
    fontSize: "1.6rem",
    borderRadius: "50%",
    margin: "0",
    color: theme.palette.common.lightgray,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem"
    }
  },
  menuIcon: {
    fontSize: "2rem",
    color: theme.palette.common.lightgray,
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  buttonIconLarge: {
    fontSize: "2rem",
    borderRadius: "50%",
    margin: "0",
    color: theme.palette.common.lightgray,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  profileIconContainer: {
    fontSize: "2.8rem",
    padding: "0 0 0 10px",
    color: theme.palette.common.lightgray,
    cursor: "pointer"
  },
  profileButtonIcon: {
    fontSize: "2rem",
    backgroundColor: "#3e3e3e",
    padding: "6px",
    color: theme.palette.background.ffGreen,
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#444444"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.gray, 0.55),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.gray, 0.85)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchButtonIcon: {
    paddingTop: "0.05em",
    fontSize: "2rem",
    borderRadius: "50%",
    color: theme.palette.common.lightgray,
    cursor: "pointer",
    margin: theme.spacing(0.4, 0),
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  notificationIconContainer: {
    margin: theme.spacing(0.5, 2, 0, 2),
    padding: theme.spacing(0, 1.5)
  },
  notificationButtonIcon: {
    fontSize: "1.8rem",
    borderRadius: "50%",
    color: theme.palette.common.lightgray,
    cursor: "pointer",
    margin: theme.spacing(0.4, 0),
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  notificationBadge: {
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "5px",
      right: "5px"
    }
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  profileName: {
    fontSize: "1rem",
    fontWeight: "400"
  },
  profileMenu: {
    "& li": {
      fontSize: "0.9rem",
      fontWeight: "400"
    }
  },
  mobileAccountIcon: {
    color: theme.palette.common.lightgray,
    fontSize: "1.8rem"
  },
  sideNav: {
    width: "100%",
    backgroundColor: theme.palette.background.dark,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "400px",
      minWidth: "300px"
    }
  },
  mobileNavCloseIcon: {
    color: theme.palette.common.lightgray,
    fontSize: "2rem"
  },
  mobileIconButton: {
    padding: "0"
  },
  mobileProfileButtonIcon: {
    fontSize: "3.2rem",
    paddingRight: "10px",
    color: "#4b4b4b"
  },
  mobileProfileName: {
    fontSize: "1.4rem",
    fontWeight: 500,
    color: theme.palette.common.gray,
    lineHeight: "1.6rem"
  },
  mobileNavListItem: {
    color: theme.palette.common.lightgray,
    textDecoration: "none",
    fontSize: "1.4rem",
    fontWeight: 600,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  mobileNavListItemSub: {
    color: theme.palette.common.lightgray,
    textDecoration: "none",
    fontWeight: 400,
    "&:hover": {
      color: theme.palette.background.ffGreen,
      cursor: "pointer"
    }
  },
  mobileNavListDivider: {
    height: "2px",
    marginLeft: theme.spacing(2)
  },
  mobileNotificationBadge: {
    color: "#fff",
    width: "20px",
    height: "19px",
    fontSize: "0.75rem",
    display: "table-caption",
    textAlign: "center",
    margin: "0 2px",
    borderRadius: "50%",
    backgroundColor: "#c53737"
  },
  notificationsContainer: {
    backgroundColor: "rgba(10,10,10,0.8)",
    "& .MuiPopover-paper": {
      backgroundColor: "#383838",
      borderRadius: 0,
      marginTop: "10px",
      [theme.breakpoints.up("sm")]: {
        marginTop: "0px"
      }
    }
  },
  notificationsList: {
    backgroundColor: "#383838",
    borderRadius: "0px",
    // maxWidth: "100%",
    paddingTop: "0px",
    paddingBottom: "0px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "300px"
    },
    "& p": {
      lineHeight: "1.1rem",
      width: "88%"
    },
    "& a": {
      textDecoration: "none !important",
      "& li": {
        color: "#e9e9e9",
        "&:hover": {
          textDecoration: "none",
          backgroundColor: "#444444"
        }
      }
    }
  },
  notificationWrapper: {
    borderBottom: "1px solid #181818",
    padding: theme.spacing(1, 2),
    position: "relative"
  },
  notificationTitle: {
    fontSize: "1.1rem",
    lineHeight: "1.3rem",
    fontWeight: 500,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  notificationDesc: {
    fontSize: "0.9rem",
    lineHeight: "1rem",
    marginBottom: theme.spacing(1),
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "block",
    whiteSpace: "nowrap",
    color: "#a9a9a9"
  },
  notificationDate: {
    fontSize: "0.8rem",
    color: "#a9a9a9",
    marginBottom: theme.spacing(0),
    "@media (min-width:600px)": {
      marginBottom: theme.spacing(1)
    }
  },
  notificationUnreadIcon: {
    position: "absolute",
    top: "24px",
    right: "20px",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.ffGreen
  },
  noNotificationsIcon: {
    paddingTop: "0.1em",
    height: "1.8em",
    width: "1.8em"
  },
  notificationsViewAll: {
    textAlign: "center",
    textTransform: "uppercase",
    borderBottom: "1px solid #181818",
    color: "#a9a9a9",
    textDecoration: "none",
    "& span": {
      fontWeight: "600",
      fontSize: "0.8rem"
    },
    "&:hover": {
      "& span": {
        color: theme.palette.background.ffGreen
      }
    }
  },
  notificationsFooter: {
    "& a": {
      color: "#e9e9e9",
      textDecoration: "underline !important",
      "&:hover": {
        color: theme.palette.background.ffGreen
      }
    }
  },
  avatarIcon: {
    backgroundColor: theme.palette.background.ffGreen,
    color: theme.palette.background.ffNavy,
    fontWeight: 500
  },
  warningIcon: {
    color: "#f3c621",
    width: "1.2em",
    height: "1.2em",
    position: "absolute",
    top: "0",
    left: "-5px"
  },
  heavyWarningIcon: {
    color: "#ff841b",
    width: "1.2em",
    height: "1.2em",
    position: "absolute",
    top: "0",
    left: "-5px"
  },
  warningIconInline: {
    color: "#f3c621",
    width: "1.2em",
    height: "1.2em",
    position: "absolute",
    top: "6px",
    marginLeft: "6px"
  },
  heavyWarningIconInline: {
    color: "#ff841b",
    width: "1.2em",
    height: "1.2em",
    position: "absolute",
    top: "6px",
    marginLeft: "6px"
  }
}));

export default function HeaderInteral() {
  const { userProfile, logout } = useContext(AccountContext);
  const {
    overview,
    unreadNotificationCount,
    notificationsRead,
    isNewNotification
  } = useContext(OverviewContext);
  const history = useHistory();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = async () => {
    await logout();
    history.push("/login");
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const handleOpenNotificationsPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotificationsPopover = () => {
    setAnchorEl(null);
    return notificationsRead();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        p={1}
        m={1}
        css={{ height: 100 }}
      >
        {userProfile && overview && (
          <Box style={{ width: "100%" }}>
            <List>
              <ListItem>
                <PersonRoundedIcon
                  className={classes.mobileProfileButtonIcon}
                />
                <Typography className={classes.mobileProfileName}>
                  Hi {userProfile.firstName}!
                </Typography>
              </ListItem>
            </List>
            <Divider className={classes.mobileNavListDivider} />
            <List>
              {userProfile.memberStatus === "standard" && (
                <>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/videos"
                          className={classes.mobileNavListItemSub}
                        >
                          Dashboard
                        </Link>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/myaccount/overview"
                          className={classes.mobileNavListItemSub}
                        >
                          My Account
                          {userProfile.subscriptionPaymentRetryCount > 0 && (
                            <WarningRoundedIcon
                              className={
                                userProfile.subscriptionPaymentRetryCount === 1
                                  ? classes.warningIconInline
                                  : classes.heavyWarningIconInline
                              }
                            />
                          )}
                        </Link>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/favourites"
                          className={classes.mobileNavListItemSub}
                        >
                          My Favourites
                        </Link>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/watch-history"
                          className={classes.mobileNavListItemSub}
                        >
                          Watch History
                        </Link>
                      }
                    />
                  </ListItem>
                  {/* Release once App Issues Resolved */}
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/inbox"
                          className={classes.mobileNavListItemSub}
                        >
                          Notifications
                          {overview &&
                            overview.notifications &&
                            overview.notifications.length > 0 &&
                            unreadNotificationCount > 0 && (
                              <Badge
                                badgeContent={unreadNotificationCount}
                                color="error"
                                style={{ top: "-2px", right: "-18px" }}
                              ></Badge>
                            )}
                        </Link>
                      }
                    />
                  </ListItem>
                </>
              )}

              {userProfile.memberStatus === "trial" && (
                <>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/myaccount/overview"
                          className={classes.mobileNavListItem}
                          style={{ color: "#99ca3c" }}
                        >
                          Join Now
                        </Link>
                      }
                    />
                  </ListItem>
                  <Divider className={classes.mobileNavListDivider} />
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/videos"
                          className={classes.mobileNavListItemSub}
                        >
                          Dashboard
                        </Link>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/myaccount/overview"
                          className={classes.mobileNavListItemSub}
                        >
                          My Account{" "}
                        </Link>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/favourites"
                          className={classes.mobileNavListItemSub}
                        >
                          My Favourites
                        </Link>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/watch-history"
                          className={classes.mobileNavListItemSub}
                        >
                          Watch History
                        </Link>
                      }
                    />
                  </ListItem>
                  {/* Release once App Issues Resolved */}
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/inbox"
                          className={classes.mobileNavListItemSub}
                        >
                          Notifications
                          {overview &&
                            overview.notifications &&
                            overview.notifications.length > 0 &&
                            unreadNotificationCount > 0 && (
                              <Badge
                                badgeContent={unreadNotificationCount}
                                color="error"
                                style={{ top: "-2px", right: "-18px" }}
                              ></Badge>
                            )}
                        </Link>
                      }
                    />
                  </ListItem>
                </>
              )}
              {((userProfile.memberStatus === "standard-expired" &&
                !userProfile.productAutoRenewing) ||
                userProfile.memberStatus === "trial-expired") && (
                <>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Link
                          to="/myaccount/overview"
                          className={classes.mobileNavListItem}
                          style={{ color: "#99ca3c" }}
                        >
                          Join Now
                        </Link>
                      }
                    />
                  </ListItem>
                  <Divider className={classes.mobileNavListDivider} />
                </>
              )}

              {userProfile.memberStatus === "locked" &&
                overview.doors.open === true && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Link
                            to="/myaccount/overview"
                            className={classes.mobileNavListItem}
                            style={{ color: "#99ca3c" }}
                          >
                            Join Now
                          </Link>
                        }
                      />
                    </ListItem>
                    <Divider className={classes.mobileNavListDivider} />
                  </>
                )}

              {userProfile.memberStatus === "standard-incomplete" &&
                overview.doors.open === true && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Link
                            to="/myaccount/overview"
                            className={classes.mobileNavListItem}
                            style={{ color: "#99ca3c" }}
                          >
                            Join Now
                          </Link>
                        }
                      />
                    </ListItem>
                    <Divider className={classes.mobileNavListDivider} />
                  </>
                )}

              {!(
                userProfile.memberStatus === MEMBER_STATUS_STANDARD ||
                userProfile.memberStatus === MEMBER_STATUS_TRIAL
              ) && (
                <ListItem>
                  <ListItemText
                    primary={
                      <Link
                        to="/myaccount/overview"
                        className={classes.mobileNavListItemSub}
                      >
                        My Account{" "}
                      </Link>
                    }
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemText
                  primary={
                    <a
                      href="https://shop.farmflix.tv"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.mobileNavListItemSub}
                    >
                      The Shop
                    </a>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Link to="/help" className={classes.mobileNavListItemSub}>
                      Help
                    </Link>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Link
                      to="/usemycode"
                      className={classes.mobileNavListItemSub}
                    >
                      Redeem Giftcard
                    </Link>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Link
                      to="/contact"
                      className={classes.mobileNavListItemSub}
                    >
                      Contact Us
                    </Link>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      onClick={handleLogout}
                      className={classes.mobileNavListItemSub}
                    >
                      Logout
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Box>
        )}
        <div className={classes.grow} />
        <Box p={1}>
          <CloseIcon
            className={classes.mobileNavCloseIcon}
            onClick={toggleDrawer("right", true)}
          />
        </Box>
      </Box>
    </div>
  );

  if (!userProfile) return null;

  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <Link to="/videos">
          <img src={logo} className={classes.logo} alt="FarmFLiX Logo" />
        </Link>
        <div className={classes.grow} />

        {/* Notifications List Popover */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseNotificationsPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transitionDuration={{ enter: 250, exit: 0 }}
          className={classes.notificationsContainer}
        >
          <List className={classes.notificationsList}>
            {overview &&
              overview.notifications &&
              overview.notifications.length === 0 && (
                <>
                  <ListItem
                    className={classes.notificationWrapper}
                    style={{ textAlign: "center" }}
                  >
                    <ListItemText>
                      <NotificationsIcon
                        className={classes.noNotificationsIcon}
                      />
                      <Typography className={classes.notificationTitle}>
                        No New Notifications
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </>
              )}

            {overview &&
              overview.notifications &&
              overview.notifications.length > 0 && (
                <>
                  {overview.notifications.map(notification => (
                    <React.Fragment key={notification.id}>
                      {/* No Link URL and No Video ID attached */}
                      {!notification.linkUrl && !notification.videoReferenceId && (
                        <Link to={"/inbox"}>
                          <ListItem className={classes.notificationWrapper}>
                            <ListItemText>
                              <Typography className={classes.notificationTitle}>
                                {notification.title}
                              </Typography>
                              <Typography
                                className={classes.notificationDesc}
                                variant="body1"
                              >
                                {notification.message}
                              </Typography>
                            </ListItemText>
                            {isNewNotification(notification) && (
                              <div
                                className={classes.notificationUnreadIcon}
                              ></div>
                            )}
                          </ListItem>
                        </Link>
                      )}
                      {/* External Link - No Video ID */}
                      {notification.linkUrl && !notification.videoReferenceId && (
                        <a
                          href={notification.linkUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ListItem className={classes.notificationWrapper}>
                            <ListItemText>
                              <Typography className={classes.notificationTitle}>
                                {notification.title}
                              </Typography>
                              <Typography
                                className={classes.notificationDesc}
                                variant="body1"
                              >
                                {notification.message}
                              </Typography>
                            </ListItemText>
                            {isNewNotification(notification) && (
                              <div
                                className={classes.notificationUnreadIcon}
                              ></div>
                            )}
                          </ListItem>
                        </a>
                      )}
                      {/* Video ID - No External Link */}
                      {notification.videoReferenceId && !notification.linkUrl && (
                        <Link
                          to={
                            "/videos/" +
                            notification.tag +
                            "/" +
                            notification.videoReferenceId
                          }
                        >
                          <ListItem className={classes.notificationWrapper}>
                            <ListItemText>
                              <Typography className={classes.notificationTitle}>
                                {notification.title}
                              </Typography>
                              <Typography
                                className={classes.notificationDesc}
                                variant="body1"
                              >
                                {notification.message}
                              </Typography>
                            </ListItemText>
                            {isNewNotification(notification) && (
                              <div
                                className={classes.notificationUnreadIcon}
                              ></div>
                            )}
                          </ListItem>
                        </Link>
                      )}
                    </React.Fragment>
                  ))}
                  <Link to="/inbox">
                    <ListItem className={classes.notificationsViewAll}>
                      <ListItemText>View All Notifications</ListItemText>
                    </ListItem>
                  </Link>
                </>
              )}
            <ListItem className={classes.notificationsFooter}>
              <ListItemText>
                <Typography variant="body2">
                  You can{" "}
                  <a
                    href={"https://en-gb.facebook.com/farmflix/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    follow us on Facebook
                  </a>{" "}
                  to receive the latest FarmFLiX News.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Popover>

        {/* Tablet / Desktop */}
        <Hidden xsDown>
          {(userProfile.memberStatus === "standard" ||
            userProfile.memberStatus === "trial") && (
            <>
              <SearchModalIconButton />
              {overview &&
                overview.notifications &&
                overview.notifications.length === 0 && (
                  <Tooltip title="Notifications">
                    <IconButton
                      className={classes.notificationIconContainer}
                      onClick={handleOpenNotificationsPopover}
                    >
                      <NotificationsIcon
                        className={classes.notificationButtonIcon}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              {overview &&
                overview.notifications &&
                overview.notifications.length > 0 && (
                  <>
                    <Tooltip title="Notifications">
                      <IconButton
                        className={classes.notificationIconContainer}
                        onClick={handleOpenNotificationsPopover}
                      >
                        {unreadNotificationCount > 0 && (
                          <Badge
                            badgeContent={unreadNotificationCount}
                            color="error"
                            className={classes.notificationBadge}
                          >
                            <NotificationsIcon
                              className={classes.notificationButtonIcon}
                            />
                          </Badge>
                        )}
                        {unreadNotificationCount === 0 && (
                          <NotificationsIcon
                            className={classes.notificationButtonIcon}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </>
                )}
            </>
          )}

          <Tooltip title="Open Menu">
            <IconButton
              name="openMenu"
              className={classes.profileIconContainer}
              onClick={toggleDrawer("right", true)}
              disableRipple={true}
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                badgeContent={
                  <>
                    {userProfile.subscriptionPaymentRetryCount > 0 && (
                      <WarningRoundedIcon
                        className={
                          userProfile.subscriptionPaymentRetryCount === 1
                            ? classes.warningIcon
                            : classes.heavyWarningIcon
                        }
                      />
                    )}
                  </>
                }
              >
                <Avatar className={classes.avatarIcon}>
                  {userProfile.firstName.charAt(0).toLocaleUpperCase()}
                </Avatar>
              </Badge>
            </IconButton>
          </Tooltip>
        </Hidden>

        {/* Mobile */}
        <Hidden smUp>
          {(userProfile.memberStatus === "standard" ||
            userProfile.memberStatus === "trial") && (
            <>
              <SearchModalIconButton />
              {overview &&
                overview.notifications &&
                overview.notifications.length === 0 && (
                  <>
                    <IconButton
                      className={classes.iconContainer}
                      onClick={handleOpenNotificationsPopover}
                    >
                      <NotificationsIcon className={classes.buttonIcon} />
                    </IconButton>
                  </>
                )}

              {overview &&
                overview.notifications &&
                overview.notifications.length > 0 && (
                  <Tooltip title="Notifications">
                    <IconButton
                      className={classes.iconContainer}
                      onClick={handleOpenNotificationsPopover}
                    >
                      {unreadNotificationCount > 0 && (
                        <Badge
                          badgeContent={unreadNotificationCount}
                          color="error"
                          className={classes.notificationBadge}
                        >
                          <NotificationsIcon className={classes.buttonIcon} />
                        </Badge>
                      )}
                      {unreadNotificationCount === 0 && (
                        <NotificationsIcon
                          className={classes.notificationButtonIcon}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
            </>
          )}

          <Tooltip title="Open Menu">
            <IconButton
              name="openMenu"
              className={classes.iconContainer}
              style={{ paddingRight: "0" }}
              onClick={toggleDrawer("right", true)}
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                badgeContent={
                  <>
                    {userProfile.subscriptionPaymentRetryCount > 0 && (
                      <WarningRoundedIcon
                        className={
                          userProfile.subscriptionPaymentRetryCount === 1
                            ? classes.warningIcon
                            : classes.heavyWarningIcon
                        }
                      />
                    )}
                  </>
                }
              >
                <Avatar className={classes.avatarIcon}>
                  {userProfile.firstName.charAt(0).toLocaleUpperCase()}
                </Avatar>
              </Badge>
            </IconButton>
          </Tooltip>
        </Hidden>
      </Toolbar>

      <Drawer
        variant="temporary"
        anchor="right"
        classes={{ paper: classes.mobileNav }}
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        {sideList("right")}
      </Drawer>

      <Drawer
        variant="temporary"
        anchor="right"
        classes={{ paper: classes.sideNav }}
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        {sideList("right")}
      </Drawer>
    </AppBar>
  );
}
