import React from "react";
import { IconButton, makeStyles, Theme } from "@material-ui/core";
import { VideoSearchResult } from "@booyaltd/core";
import { Link } from "react-router-dom";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    fontSize: 24
  },
  episodeLink: {
    textDecoration: "none"
  },
  playIconButton: {
    fontSize: "2.2rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        // @ts-ignore
        color: theme.palette.background.ffGreen
      }
    }
  }
}));

type VideoPlayButtonProps = {
  videoSlug: VideoSearchResult["slug"];
};

const VideoPlayButton = ({ videoSlug }: VideoPlayButtonProps) => {
  const classes = useStyles();

  return (
    <Link to={"/video-details/" + videoSlug} className={classes.episodeLink}>
      <IconButton aria-label="Play Episode" className={classes.playIconButton}>
        <PlayArrowIcon className={classes.iconButton} />
      </IconButton>
    </Link>
  );
};

export default VideoPlayButton;
