import { ValidationSchema } from "./MemberSurveyContext";

export const initialValues: ValidationSchema = {
  userId: {
    value: "",
    error: "",
    googleFormId: "entry.370934662"
  },
  age: {
    value: "",
    error: "",
    helperText: "Please enter your age",
    googleFormId: "entry.1371653064"
  },
  gender: {
    value: "Male",
    error: "",
    googleFormId: "entry.1707824774"
  },
  growUpOnFarm: {
    value: "Yes",
    error: "",
    googleFormId: "entry.1520488085"
  },
  connectionToFarming1: {
    value: false,
    label: "Active Farm Owner (F/T)",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming2: {
    value: false,
    label: "Active Farm Owner (P/T)",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming3: {
    value: false,
    label: "Farm Manager (F/T)",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming4: {
    value: false,
    label: "Farm Manager (P/T)",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming5: {
    value: false,
    label: "Farm Worker (F/T)",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming6: {
    value: false,
    label: "Farm Worker (P/T)",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming7: {
    value: false,
    label: "Machinery Contractor (Owner)",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming8: {
    value: false,
    label: "Machinery Contractor (Driver)",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming9: {
    value: false,
    label: "Machinery Operator (F/T)",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming10: {
    value: false,
    label: "Machinery Operator (P/T)",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming11: {
    value: false,
    label: "Farm Service Supplier",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming12: {
    value: false,
    label: "Farm Product Supplier",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming13: {
    value: false,
    label: "Farm Enthusiast",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarming14: {
    value: false,
    label: "Other",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.135689313"
  },
  connectionToFarmingOther: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter your connection to farming",
    googleFormId: "entry.135689313",
    googleFormIdOtherResponse: "entry.135689313.other_option_response"
  },
  connectionToFarmingFarmService: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter the service you supply",
    googleFormId: "entry.1908240176"
  },
  connectionToFarmingFarmServiceRole: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter the role you play",
    googleFormId: "entry.130710594"
  },
  connectionToFarmingFarmProduct: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter the product you supply",
    googleFormId: "entry.1256450025"
  },
  connectionToFarmingFarmProductRole: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter the role you play",
    googleFormId: "entry.1389499153"
  },
  connectionToFarmingFarmEnthusiast: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter the role you play",
    googleFormId: "entry.395790"
  },
  regionGrowUp: {
    value: "Northern Ireland",
    error: "",
    googleFormId: "entry.2137415797"
  },
  regionGrowUpOther: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter where you grew up",
    googleFormId: "entry.2137415797",
    googleFormIdOtherResponse: "entry.2137415797.other_option_response"
  },
  regionLivingIn: {
    value: "Northern Ireland",
    error: "",
    googleFormId: "entry.1670874882"
  },
  regionLivingInOther: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter where you currently live",
    googleFormId: "entry.1670874882",
    googleFormIdOtherResponse: "entry.1670874882.other_option_response"
  },
  job: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter your current job",
    googleFormId: "entry.1965040790"
  },
  checkEmails: {
    value: "3 - Daily",
    error: "",
    googleFormId: "entry.1227091312"
  },
  emailsDelivery: {
    value: "Inbox",
    error: "",
    googleFormId: "entry.76231605"
  },
  smsTrust: {
    value: "3 - Read them",
    error: "",
    googleFormId: "entry.448047864"
  },
  useWhatsapp: {
    value: "Yes",
    error: "",
    googleFormId: "entry.408952817"
  },
  platformUsageFacebook: {
    value: false,
    label: "Facebook",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.221600736"
  },
  platformUsageYouTube: {
    value: false,
    label: "YouTube",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.221600736"
  },
  platformUsageInstagram: {
    value: false,
    label: "Instagram",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.221600736"
  },
  platformUsageTiktok: {
    value: false,
    label: "TikTok",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.221600736"
  },
  platformUsageSnapchat: {
    value: false,
    label: "Snapchat",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.221600736"
  },
  platformUsageOther: {
    value: false,
    label: "Other",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.221600736"
  },
  platformUsageOtherReason: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    // helperText: "Please enter a platform",
    googleFormId: "entry.221600736",
    googleFormIdOtherResponse: "entry.221600736.other_option_response"
  },
  favouriteFacebook: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 150,
    helperText: "Please enter your favourite Facebook profiles",
    googleFormId: "entry.2023950276"
  },
  favouriteYouTube: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 150,
    helperText: "Please enter your favourite YouTube channels",
    googleFormId: "entry.1758208248"
  },
  favouriteInstagram: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 150,
    helperText: "Please enter your favourite Instagram profiles",
    googleFormId: "entry.2138837894"
  },
  favouriteTiktok: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 150,
    helperText: "Please enter your favourite TikTok profiles",
    googleFormId: "entry.2109378494"
  },
  favouriteSnapchat: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 150,
    helperText: "Please enter what characters you follow on Snapchat",
    googleFormId: "entry.1242943156"
  },
  discoverFarmFLiX: {
    value: "Google Search",
    error: "",
    googleFormId: "entry.1758152987"
  },
  discoverFarmFLiXOther: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 150,
    helperText: "Please enter where you discovered FarmFLiX",
    googleFormId: "entry.1758152987",
    googleFormIdOtherResponse: "entry.1758152987.other_option_response"
  },
  joinMembership: {
    value: "",
    error: "",
    googleFormId: "entry.1542902054"
  },
  waitedMembership: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 150,
    helperText: "Please enter why you waited to join",
    googleFormId: "entry.1726204661"
  },
  reasonForJoining1: {
    value: false,
    label: "To watch a specific episode",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1259900857"
  },
  reasonForJoining2: {
    value: false,
    label: "Didn’t want to miss the lock deadline",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1259900857"
  },
  reasonForJoining3: {
    value: false,
    label: "Needed some pandemic entertainment",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1259900857"
  },
  reasonForJoining4: {
    value: false,
    label: "I followed the FarmFLiX Youtube channel and wanted to support you",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1259900857"
  },
  reasonForJoining5: {
    value: false,
    label: "So many ad’s that I had to see what the fuss was about",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1259900857"
  },
  reasonForJoining6: {
    value: false,
    label: "Someone I know told me I would enjoy it",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1259900857"
  },
  reasonForJoining7: {
    value: false,
    label: "Other",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1259900857"
  },
  reasonForJoiningOther: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter your reason for joining",
    googleFormId: "entry.1259900857",
    googleFormIdOtherResponse: "entry.1259900857.other_option_response"
  },
  useFreeTrial: {
    value: "Yes",
    error: "",
    googleFormId: "entry.2000218329"
  },
  firstLoginLayout: {
    value: "Simple",
    error: "",
    googleFormId: "entry.1835268631"
  },
  firstLoginLayoutOther: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter your thoughts on the layout",
    googleFormId: "entry.1835268631",
    googleFormIdOtherResponse: "entry.1835268631.other_option_response"
  },
  watchBehaviour: {
    value: "",
    error: "",
    googleFormId: "entry.2142775167"
  },
  firstChannel: {
    value: "",
    error: "",
    validate: "select",
    googleFormId: "entry.639427423"
  },
  secondChannel: {
    value: "",
    error: "",
    validate: "select",
    googleFormId: "entry.93369282"
  },
  thirdChannel: {
    value: "",
    error: "",
    validate: "select",
    googleFormId: "entry.1882832994"
  },
  skipAgLife: {
    value: false,
    label: "AgLife",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1541218199"
  },
  skipStraight6: {
    value: false,
    label: "Straight6",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1541218199"
  },
  skipStockyards: {
    value: false,
    label: "Stockyards",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1541218199"
  },
  skipWhy: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 250,
    helperText: "Why do you skip episodes from these channels?",
    googleFormId: "entry.1328177689"
  },
  enjoyingTopic1: {
    value: false,
    label: "Pit silage",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.633058403"
  },
  enjoyingTopic2: {
    value: false,
    label: "Manure",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.633058403"
  },
  enjoyingTopic3: {
    value: false,
    label: "Livestock Farming",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.633058403"
  },
  enjoyingTopic4: {
    value: false,
    label: "Landwork",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.633058403"
  },
  enjoyingTopic5: {
    value: false,
    label: "Harvest",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.633058403"
  },
  enjoyingTopic6: {
    value: false,
    label: "Other",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.633058403"
  },
  enjoyingTopicOther: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter your reason for joining",
    googleFormId: "entry.633058403",
    googleFormIdOtherResponse: "entry.633058403.other_option_response"
  },
  idealMixWeek1: {
    value: "",
    error: "",
    googleFormId: "entry.616957572"
  },
  idealMixWeek2: {
    value: "",
    error: "",
    googleFormId: "entry.2022259750"
  },
  idealMixWeek3: {
    value: "",
    error: "",
    googleFormId: "entry.983904147"
  },
  idealMixWeek4: {
    value: "",
    error: "",
    googleFormId: "entry.912649319"
  },
  missTheLoft: {
    value: "Yes",
    error: "",
    googleFormId: "entry.546260562"
  },
  watchRoutine: {
    value: "Weekly",
    error: "",
    googleFormId: "entry.1081116042"
  },
  episodeLength: {
    value: "Less than 20 mins",
    error: "",
    googleFormId: "entry.1258454231"
  },
  breakEpisodes: {
    value: "Yes",
    error: "",
    googleFormId: "entry.554236318"
  },
  resumePlayback: {
    value: "Yes",
    error: "",
    googleFormId: "entry.2112659622"
  },
  resumePlaybackWhy: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Why doesn't resume playback work well?",
    googleFormId: "entry.97493057",
    googleFormIdOtherResponse: "entry.97493057.other_option_response"
  },
  reWatchOldEpisodes: {
    value: "Yes",
    error: "",
    googleFormId: "entry.822262504"
  },
  episodeRegion1: {
    value: false,
    label: "Northern Ireland",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.218091712"
  },
  episodeRegion2: {
    value: false,
    label: "Republic of Ireland",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.218091712"
  },
  episodeRegion3: {
    value: false,
    label: "England",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.218091712"
  },
  episodeRegion4: {
    value: false,
    label: "Scotland",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.218091712"
  },
  episodeRegion5: {
    value: false,
    label: "Wales",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.218091712"
  },
  episodeRegion6: {
    value: false,
    label: "USA",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.218091712"
  },
  episodeRegionWhy: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Why do you like the epiosdes from this region?",
    googleFormId: "entry.206328473",
    googleFormIdOtherResponse: "entry.206328473.other_option_response"
  },
  travelOverseas: {
    value: "",
    error: "",
    googleFormId: "entry.858140110"
  },
  unbiasEdit: {
    value: "",
    error: "",
    googleFormId: "entry.1888325690"
  },
  unbiasEditWhy: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Why is this important?",
    googleFormId: "entry.115280308"
  },
  documentFarming: {
    value: "",
    error: "",
    googleFormId: "entry.1923661311"
  },
  documentFarmingWhy: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Why is this important?",
    googleFormId: "entry.2042646754"
  },
  favouriteThingFarmFLiX: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "What is your favourite thing about FarmFLiX?",
    googleFormId: "entry.2453565"
  },
  frustratingThingFarmFLiX: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "What is the most frustrating thing about FarmFLiX?",
    googleFormId: "entry.1514024607"
  },
  convinceFarmFLiX: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "How would you convince them?",
    googleFormId: "entry.1903088364"
  },
  membersFarmFLiX: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "How many members does FarmFLiX have?",
    googleFormId: "entry.521040000"
  },
  editDuration: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "How long do you think it takes to edit an episode?",
    googleFormId: "entry.698905171"
  },
  employeesFarmFLiX: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "How many employees does FarmFLiX have?",
    googleFormId: "entry.1071712023"
  },
  teamJohn: {
    value: false,
    label: "John",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.309747960"
  },
  teamBrian: {
    value: false,
    label: "Brian",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.309747960"
  },
  teamAlan: {
    value: false,
    label: "Alan",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.309747960"
  },
  teamWillis: {
    value: false,
    label: "Willis",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.309747960"
  },
  teamConor: {
    value: false,
    label: "Conor",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.309747960"
  },
  teamKevin: {
    value: false,
    label: "Kevin",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.309747960"
  },
  teamWhy: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Why do you prefer these team members?",
    googleFormId: "entry.1247732655"
  },
  subtitles: {
    value: "Yes",
    error: "",
    googleFormId: "entry.699859422"
  },
  appInstalled: {
    value: "",
    error: "",
    googleFormId: "entry.1863836216"
  },
  appReliable: {
    value: "",
    error: "",
    googleFormId: "entry.691551256"
  },
  appEasyToUse: {
    value: "",
    error: "",
    googleFormId: "entry.927863288"
  },
  improveAppSuggestions: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Have you any suggestions to improve the App?",
    googleFormId: "entry.2111524218"
  },
  appCasting: {
    value: "",
    error: "",
    googleFormId: "entry.1250328940"
  },
  appCastingReliable: {
    value: "",
    error: "",
    googleFormId: "entry.1772676743"
  },
  appCastingEasyToUse: {
    value: "",
    error: "",
    googleFormId: "entry.1660481944"
  },
  triedTvApp: {
    value: "",
    error: "",
    googleFormId: "entry.2065355984"
  },
  triedTvAppReliable: {
    value: "",
    error: "",
    googleFormId: "entry.2016450184"
  },
  triedTvAppEasyToUse: {
    value: "",
    error: "",
    googleFormId: "entry.181383124"
  },
  improveTvAppSuggestions: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Have you any suggestions to improve the TV App?",
    googleFormId: "entry.260866534"
  },
  usedSearch: {
    value: "",
    error: "",
    googleFormId: "entry.489186215"
  },
  usedSearchResults: {
    value: "",
    error: "",
    googleFormId: "entry.724003812"
  },
  improveSearchSuggestions: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Have you any suggestions to improve the search engine?",
    googleFormId: "entry.1322619614"
  },
  useFavourites: {
    value: "",
    error: "",
    googleFormId: "entry.159493698"
  },
  episodeFeature: {
    value: "",
    error: "",
    googleFormId: "entry.1273636736"
  },
  episodeFeatureShare: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "What would you share with us on camera?",
    googleFormId: "entry.259294357"
  },
  followedYouTube: {
    value: "",
    error: "",
    googleFormId: "entry.1303470826"
  },
  followBeforeAfter: {
    value: "",
    error: "",
    googleFormId: "entry.530394582"
  },
  moreAvailableFarmFLiX: {
    value: "",
    error: "",
    googleFormId: "entry.1719052405"
  },
  youtubeContentStyle1: {
    value: false,
    label: "Behind the Scenes",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1217669726"
  },
  youtubeContentStyle2: {
    value: false,
    label: "Clydes Farm",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1217669726"
  },
  youtubeContentStyle3: {
    value: false,
    label: "John's Tractor Work",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1217669726"
  },
  youtubeContentStyle4: {
    value: false,
    label: "Everything",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.1217669726"
  },
  youtubeThought: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "What do you think?",
    googleFormId: "entry.1863287626"
  },
  youtubeDoNext: {
    value: "Make more of it for the public to see",
    error: "",
    googleFormId: "entry.1759485067"
  },
  youtubeDoNextOther: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "What should we do next?",
    googleFormId: "entry.1759485067",
    googleFormIdOtherResponse: "entry.1759485067.other_option_response"
  },
  watchOnThePull: {
    value: "",
    error: "",
    googleFormId: "entry.303485707"
  },
  onThePullVideoThoughts: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "What did you think?",
    googleFormId: "entry.1830839397"
  },
  runOnThePullAgain: {
    value: "",
    error: "",
    googleFormId: "entry.1961748626"
  },
  wantAnotherOnThePull: {
    value: "",
    error: "",
    googleFormId: "entry.882941641"
  },
  enterYourTractor: {
    value: "",
    error: "",
    googleFormId: "entry.1401034178"
  },
  wearAgriMerch: {
    value: "",
    error: "",
    googleFormId: "entry.713811074"
  },
  wearMerchBrands: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Which brands do you wear?",
    googleFormId: "entry.379500629"
  },
  seenAgLifeMerch: {
    value: "",
    error: "",
    googleFormId: "entry.1116064542"
  },
  boughtGiftedAgLifeMerch: {
    value: "",
    error: "",
    googleFormId: "entry.1556106087"
  },
  wearAgLifeMerch: {
    value: "",
    error: "",
    googleFormId: "entry.843198339"
  },
  onlineComm1: {
    value: false,
    label: "Raising awareness for your own agri service, product or business",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.412273959"
  },
  onlineComm2: {
    value: false,
    label: "Raising awareness for agri related events that are happening",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.412273959"
  },
  onlineComm3: {
    value: false,
    label: "Recruiting full-time staff",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.412273959"
  },
  onlineComm4: {
    value: false,
    label:
      "Adding to my local support network e.g. by finding temporary help at busy times",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.412273959"
  },
  onlineComm5: {
    value: false,
    label: "Posting my own media content for the other members to see",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.412273959"
  },
  onlineComm6: {
    value: false,
    label: "Being able to interact with like-minded people",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.412273959"
  },
  onlineComm7: {
    value: false,
    label:
      "Asking questions to people more experienced than me when I’m stuck on a problem",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.412273959"
  },
  onlineComm8: {
    value: false,
    label: "None of those – the world doesn’t need this!",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.412273959"
  },
  onlineComm9: {
    value: false,
    label: "Other",
    error: "",
    // required: true,
    validate: "checkbox",
    googleFormId: "entry.412273959"
  },
  onlineCommOther: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 2000,
    helperText: "Please enter an aspect which would make you participate",
    googleFormId: "entry.412273959",
    googleFormIdOtherResponse: "entry.412273959.other_option_response"
  },
  partOfOnlineComm: {
    value: "",
    error: "",
    googleFormId: "entry.375500289"
  },
  finalComments: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 20000,
    helperText: "Please enter comments you may have",
    googleFormId: "entry.1369536319"
  },
  permissionToCall: {
    value: "Yes",
    error: "",
    googleFormId: "entry.1834511672"
  },
  userContactPhone: {
    value: "",
    error: "",
    // required: true,
    // validate: "text",
    // minLength: 0,
    // maxLength: 20000,
    helperText: "Please enter your phone number",
    googleFormId: "entry.1226658527"
  }
};
