import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { CatalogOverviewResponse } from "@booyaltd/core";

const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    "@media (min-width:600px)": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  },
  channelInnerContainer: {
    position: "relative",
    textAlign: "center",
    borderRadius: "10px",
    padding: theme.spacing(0),
    margin: 0,
    background:
      "linear-gradient(180deg, rgba(44,44,44,1) 100%, rgba(44,44,44,1) 100%)",
    height: "auto",
    "&:hover": {
      cursor: "pointer"
    }
  },
  channelInnerContainerHover: {
    position: "relative",
    textAlign: "center",
    borderRadius: "10px",
    padding: theme.spacing(0),
    margin: 0,
    background:
      "linear-gradient(180deg, rgba(23,23,23,0) 0%, rgba(23,23,23,0) 0%)",
    "&:hover": {
      cursor: "pointer"
    }
  },
  channelLogo: {
    alignSelf: "center",
    width: "80%",
    padding: 0,
    margin: 0
  }
}));

const Channels = ({ channels }: Pick<CatalogOverviewResponse, "channels">) => {
  const classes = useStyles();

  const [isChannelHovering, setIsChannelHovering] = useState<string>();

  const handleChannelHover = (i: string) => {
    setIsChannelHovering(i);
  };

  const handleChannelHoverClose = () => {
    setIsChannelHovering(undefined);
  };

  return (
    <div className={classes.background}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4">Channels</Typography>
          </Grid>
          {channels.map(channel => (
            <Grid item xs={6} sm={3} md={3} key={channel.id}>
              <Link to={"/channel/" + channel.tag.toLowerCase()}>
                <Box
                  className={
                    isChannelHovering === channel.id
                      ? classes.channelInnerContainerHover
                      : classes.channelInnerContainer
                  }
                  style={{ backgroundColor: "#" + channel.colorPrimary }}
                  onMouseOver={e => handleChannelHover(channel.id)}
                  onMouseLeave={handleChannelHoverClose}
                >
                  <img
                    src={channel.logoTransparentImageUrl || undefined}
                    alt={channel.name}
                    className={classes.channelLogo}
                  />
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Channels;
