import React, { useContext, useCallback } from "react";
import { AccountContext } from "../../store/AccountContext";
import {
  Grid,
  Typography,
  Button,
  Box,
  Divider,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { useToasts } from "react-toast-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Field, Form } from "react-final-form";
import { isPasswordValid } from "../../utils/validators";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  bodyText: {
    color: theme.palette.background.ffNavy
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    "& span": {
      fontWeight: 500
    }
  },
  formContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  inputField: {
    "& input": {
      padding: "12px",
      border: "1px solid #a5a5a5",
      backgroundColor: "#f7f7f7",
      margin: "4px 0 10px 0",
      fontSize: "16px",
      borderRadius: "5px"
    },
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem",
      fontWeight: "500",
      lineHeight: "1rem"
    },
    "& label": {
      color: "#656565",
      fontSize: "1.3rem",
      fontWeight: "500"
    }
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "5% 0 2% 0"
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    color: theme.palette.background.ffNavy,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  },
  submit: {
    margin: "10px 0",
    width: "100%",
    "@media (min-width:600px)": {
      marginTop: 0,
      width: "auto"
    }
  }
}));

export default function ChangePassword() {
  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();

  // Account Context
  const { updating, userProfile, update, validateLogin } = useContext(
    AccountContext
  );

  const onSubmit = useCallback(
    async ({ existingPassword, password }) => {
      try {
        const updatePassword = await update({ existingPassword, password });
        if (updatePassword) {
          validateLogin();
          addToast("Password successfully updated.", {
            appearance: "success"
          });
        } else {
          addToast("Password not updated. Please try again.", {
            appearance: "error"
          });
        }
      } catch (e) {
        addToast(e.toString(), {
          appearance: "error"
        });
      }
    },
    [validateLogin, addToast, update]
  );

  const validate = ({ password, confirm_password }) => {
    const errors = {};

    if (!password || password.length <= 0) {
      errors.password = "Password is required";
    }

    if (password && password.length > 0) {
      const passwordValidationError = isPasswordValid(password);

      if (typeof passwordValidationError === "string") {
        errors.password = passwordValidationError;
      }
    }

    if (!errors.password) {
      if (!confirm_password || confirm_password <= 0) {
        errors.confirm_password = "Password confirmation is required";
      } else if (confirm_password !== password) {
        errors.confirm_password = "Password confirmation does not match";
      }
    }

    return errors;
  };

  const addEmailAddress = useCallback(() => {
    history.push("/myaccount/change-email");
  }, [history]);

  return (
    <>
      {userProfile && (
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.breadcrumbContainer}
            >
              <Link
                className={classes.activeBreadcrumb}
                to="/myaccount/overview"
              >
                Overview
              </Link>
              <Typography className={classes.disabledBreadcrumb}>
                Change Password
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2" className={classes.myAccountSubTitle}>
              Change Password
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              Provide your current and new password below.
            </Typography>
          </Grid>
          <Grid container className={classes.formContainer}>
            {!updating && (
              <>
                <Grid item xs={12} sm={8} md={6}>
                  {!userProfile.email && (
                    <>
                      <Alert severity="error" variant="filled">
                        Before adding a password you will need to add an email
                        address to your account.
                      </Alert>
                      <br />
                      <Button variant="outlined" onClick={addEmailAddress}>
                        Add Email
                      </Button>
                    </>
                  )}
                  {userProfile.email && (
                    <Form onSubmit={onSubmit} validate={validate}>
                      {props => (
                        <form onSubmit={props.handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <Field name="existingPassword">
                                {({ input, meta }) => (
                                  <TextField
                                    fullWidth
                                    name="existingPassword"
                                    label="Current Password"
                                    {...input}
                                    type="password"
                                    placeholder="Existing password"
                                    className={classes.inputField}
                                    error={meta.error && meta.touched}
                                    helperText={
                                      meta.error && meta.touched
                                        ? meta.error
                                        : undefined
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                )}
                              </Field>
                              Cant remember your current password?{" "}
                              <a href="/forgotpassword">Click Here</a>.
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Field name="password">
                                {({ input, meta }) => (
                                  <TextField
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    {...input}
                                    type="password"
                                    placeholder="Password"
                                    className={classes.inputField}
                                    error={meta.error && meta.touched}
                                    helperText={
                                      meta.error && meta.touched
                                        ? meta.error
                                        : undefined
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Field name="confirm_password">
                                {({ input, meta }) => (
                                  <TextField
                                    fullWidth
                                    name="confirm_password"
                                    label="Confirm Password"
                                    {...input}
                                    type="password"
                                    className={classes.inputField}
                                    placeholder="Confirm Password"
                                    error={meta.error && meta.touched}
                                    helperText={
                                      meta.error && meta.touched
                                        ? meta.error
                                        : undefined
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Button
                                size="medium"
                                className={classes.submit}
                                type="submit"
                              >
                                Update Password
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    </Form>
                  )}
                  <Divider className={classes.footerDivider} />
                  <Typography variant="body1" className={classes.bodyTextSml}>
                    <span>Tip</span> - If you forget your password in the
                    future, you can always reset it with Forgot Password.
                  </Typography>
                </Grid>
              </>
            )}
            {updating && (
              <Box m={5} align="center">
                <CircularProgress variant="indeterminate"></CircularProgress>
                <Typography variant="body2" className={classes.bodyTextSml}>
                  <br />
                  Updating your Password. Please do not refresh your browser.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
