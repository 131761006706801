import React, { useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MemberSurveyContext } from "../../store/MemberSurveyContext";
import {
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormGroup,
  Radio,
  TextField,
  Button,
  Checkbox,
  Typography,
  Divider
} from "@material-ui/core";
import team_john from "./images/team_john.png";
import team_brian from "./images/team_brian.png";
import team_alan from "./images/team_alan.png";
import team_willis from "./images/team_willis.png";
import team_conor from "./images/team_conor.png";
import team_kevin from "./images/team_kevin.png";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    // @ts-ignore
  },
  stepHeading: {
    letterSpacing: "-2px",
    fontSize: "2rem",
    lineHeight: "2.2rem",
    color: theme.palette.background.ffNavyLighter,
    fontWeight: 600,
    // textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2.6rem"
    }
  },
  divider: {
    height: "1px",
    margin: "6px 0 12px 0",
    backgroundColor: "#b7b7b7"
  },
  formStep: {
    marginTop: "24px"
  },
  formQuestion: {
    marginBottom: "36px",
    "& .MuiFormControl-root": {
      width: "100%"
    },
    "& .MuiFormLabel-root": {
      fontSize: "1.1rem",
      lineHeight: "1.2rem",
      marginBottom: "18px"
    },
    "& .MuiTypography-body1": {
      color: "#3d3d3d"
    }
  },
  formGroup: {
    "& .MuiFormControl-root label": {
      marginBottom: "6px"
    },
    "& .MuiTypography-body1": {
      marginBottom: "6px"
    }
  },
  buttonContainer: {
    margin: "24px 0"
  },
  stepButton: {
    border: "none",
    "&:hover": {
      border: "none",
      backgroundColor: "#99ca3c"
    }
  },
  stepButtonBack: {
    backgroundColor: "#fff",
    border: "2px solid #171717",
    "&:hover": {
      backgroundColor: "#fafafa",
      border: "2px solid #171717"
    }
  },
  teamImage: {
    width: "50%",
    height: "auto",
    border: "1px solid #111",
    "@media (min-width:600px)": {
      width: "200px",
      height: "auto"
    },
    "@media (min-width:960px)": {}
  }
}));

export default function FourthStep() {
  const classes = useStyles();

  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant
  } = useContext(MemberSurveyContext);
  const {
    travelOverseas,
    unbiasEdit,
    unbiasEditWhy,
    documentFarming,
    documentFarmingWhy,
    favouriteThingFarmFLiX,
    frustratingThingFarmFLiX,
    convinceFarmFLiX,
    membersFarmFLiX,
    editDuration,
    employeesFarmFLiX,
    teamJohn,
    teamBrian,
    teamAlan,
    teamWillis,
    teamConor,
    teamKevin,
    teamWhy,
    subtitles
  } = formValues;

  const isError = useCallback(
    () =>
      Object.keys({}).some(
        name =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues]
  );

  return (
    <>
      <Grid container spacing={2} className={classes.formStep}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.stepHeading}>
            Your Thoughts
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        {/* Travel overseas again for episodes */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              How much do you want us to travel overseas again for episodes? Eg
              USA / NZ / Australia / Europe
            </FormLabel>
            <TextField
              variant={variant}
              fullWidth
              select
              SelectProps={{
                native: true
              }}
              name="travelOverseas"
              value={travelOverseas.value}
              onChange={handleChange}
              error={!!travelOverseas.error}
              helperText={travelOverseas.error}
              required={travelOverseas.required}
            >
              <option value="">Please select...</option>
              <option value="1">1 - Not Important</option>
              <option value="2">2</option>
              <option value="3">3 - I have no opinion</option>
              <option value="4">4</option>
              <option value="5">5 - Very Important</option>
            </TextField>
          </FormControl>
        </Grid>

        {/* Unbias Edits */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              If manufacturers supported our cost of production they would have
              weight to make sure we only put nice things in our edits - How
              important is it to you that we continue to have our edits
              completely unbiased i.e we are include the good, the bad and the
              ugly?
            </FormLabel>
            <TextField
              variant={variant}
              fullWidth
              select
              SelectProps={{
                native: true
              }}
              name="unbiasEdit"
              value={unbiasEdit.value}
              onChange={handleChange}
              error={!!unbiasEdit.error}
              helperText={unbiasEdit.error}
              required={unbiasEdit.required}
            >
              <option value="">Please select...</option>
              <option value="1">1 - Not Important</option>
              <option value="2">2</option>
              <option value="3">3 - I have no opinion</option>
              <option value="4">4</option>
              <option value="5">5 - Very Important</option>
            </TextField>
          </FormControl>
          <FormControl style={{ marginTop: "24px" }}>
            <FormLabel id="demo-radio-buttons-group-label">Why?</FormLabel>
            <TextField
              multiline={true}
              variant={variant}
              rows={10}
              fullWidth
              name="unbiasEditWhy"
              placeholder=""
              value={unbiasEditWhy.value}
              onChange={handleChange}
              error={!!unbiasEditWhy.error}
              helperText={unbiasEditWhy.error}
              required={unbiasEditWhy.required}
            />
          </FormControl>
        </Grid>

        {/* Document Farming */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Considering agri videos are much more available now than they used
              to be (eg like the DVD era) - How important is it to you that
              FarmFLiX continues to document farming?
            </FormLabel>
            <TextField
              variant={variant}
              fullWidth
              select
              SelectProps={{
                native: true
              }}
              name="documentFarming"
              value={documentFarming.value}
              onChange={handleChange}
              error={!!documentFarming.error}
              helperText={documentFarming.error}
              required={documentFarming.required}
            >
              <option value="">Please select...</option>
              <option value="1">1 - Not Important</option>
              <option value="2">2</option>
              <option value="3">3 - I have no opinion</option>
              <option value="4">4</option>
              <option value="5">5 - Very Important</option>
            </TextField>
          </FormControl>
          <FormControl style={{ marginTop: "24px" }}>
            <FormLabel id="demo-radio-buttons-group-label">Why?</FormLabel>
            <TextField
              multiline={true}
              variant={variant}
              rows={10}
              fullWidth
              name="documentFarmingWhy"
              placeholder=""
              value={documentFarmingWhy.value}
              onChange={handleChange}
              error={!!documentFarmingWhy.error}
              helperText={documentFarmingWhy.error}
              required={documentFarmingWhy.required}
            />
          </FormControl>
        </Grid>

        {/* Favourite thing about FarmFLiX */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              What is your favourite thing about FarmFLiX?
            </FormLabel>
            <TextField
              multiline={true}
              variant={variant}
              rows={10}
              fullWidth
              name="favouriteThingFarmFLiX"
              placeholder=""
              value={favouriteThingFarmFLiX.value}
              onChange={handleChange}
              error={!!favouriteThingFarmFLiX.error}
              helperText={favouriteThingFarmFLiX.error}
              required={favouriteThingFarmFLiX.required}
            />
          </FormControl>
        </Grid>

        {/* Fustrating thing about FarmFLiX */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              What is the most frustrating thing about FarmFLiX?
            </FormLabel>
            <TextField
              multiline={true}
              variant={variant}
              rows={10}
              fullWidth
              name="frustratingThingFarmFLiX"
              placeholder=""
              value={frustratingThingFarmFLiX.value}
              onChange={handleChange}
              error={!!frustratingThingFarmFLiX.error}
              helperText={frustratingThingFarmFLiX.error}
              required={frustratingThingFarmFLiX.required}
            />
          </FormControl>
        </Grid>

        {/* Convince Friend*/}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              If you had to explain to a friend in one sentence - why does
              FarmFLiX membership bring something extra beyond all the farming
              content available on YouTube?
            </FormLabel>
            <TextField
              multiline={true}
              variant={variant}
              rows={10}
              fullWidth
              name="convinceFarmFLiX"
              placeholder=""
              value={convinceFarmFLiX.value}
              onChange={handleChange}
              error={!!convinceFarmFLiX.error}
              helperText={convinceFarmFLiX.error}
              required={convinceFarmFLiX.required}
            />
          </FormControl>
        </Grid>

        {/* If you had to gusess*/}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              If you had to guess, how many members does FarmFLiX have?
            </FormLabel>
            <TextField
              variant={variant}
              fullWidth
              name="membersFarmFLiX"
              placeholder=""
              value={membersFarmFLiX.value}
              onChange={handleChange}
              error={!!membersFarmFLiX.error}
              helperText={membersFarmFLiX.error}
              required={membersFarmFLiX.required}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              If you had to guess, how long does it take to edit a 25 minute
              FarmFLiX episode?
            </FormLabel>
            <TextField
              variant={variant}
              fullWidth
              name="editDuration"
              placeholder=""
              value={editDuration.value}
              onChange={handleChange}
              error={!!editDuration.error}
              helperText={editDuration.error}
              required={editDuration.required}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              If you had to guess, how many people are in our team?
            </FormLabel>
            <TextField
              variant={variant}
              fullWidth
              name="employeesFarmFLiX"
              placeholder=""
              value={employeesFarmFLiX.value}
              onChange={handleChange}
              error={!!employeesFarmFLiX.error}
              helperText={employeesFarmFLiX.error}
              required={employeesFarmFLiX.required}
            />
          </FormControl>
        </Grid>

        {/* What region do you like to watch episodes from?
         */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Who from the FarmFLiX screen team do you enjoy in the episode
              conversations? (Select as many as you want)
            </FormLabel>
            <FormGroup>
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="teamJohn"
                      onChange={handleChange}
                      value={teamJohn.value}
                      checked={teamJohn.value}
                    />
                  }
                  // label="John"
                  label={
                    <>
                      <img
                        src={team_john}
                        className={classes.teamImage}
                        alt={"John"}
                      />
                      <Typography variant="body1" className={classes.bodyText}>
                        <b>John</b>
                      </Typography>
                      <br />
                    </>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="teamBrian"
                      onChange={handleChange}
                      checked={teamBrian.value}
                      value={teamBrian.value}
                    />
                  }
                  label={
                    <>
                      <img
                        src={team_brian}
                        className={classes.teamImage}
                        alt={"Brian"}
                      />
                      <Typography variant="body1" className={classes.bodyText}>
                        <b>Brian</b>
                      </Typography>
                      <br />
                    </>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="teamAlan"
                      onChange={handleChange}
                      checked={teamAlan.value}
                      value={teamAlan.value}
                    />
                  }
                  label={
                    <>
                      <img
                        src={team_alan}
                        className={classes.teamImage}
                        alt={"Alan"}
                      />
                      <Typography variant="body1" className={classes.bodyText}>
                        <b>Alan</b>
                      </Typography>
                      <br />
                    </>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="teamWillis"
                      onChange={handleChange}
                      checked={teamWillis.value}
                      value={teamWillis.value}
                    />
                  }
                  label={
                    <>
                      <img
                        src={team_willis}
                        className={classes.teamImage}
                        alt={"Willis"}
                      />
                      <Typography variant="body1" className={classes.bodyText}>
                        <b>Willis</b>
                      </Typography>
                      <br />
                    </>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="teamConor"
                      onChange={handleChange}
                      checked={teamConor.value}
                      value={teamConor.value}
                    />
                  }
                  label={
                    <>
                      <img
                        src={team_conor}
                        className={classes.teamImage}
                        alt={"Conor"}
                      />
                      <Typography variant="body1" className={classes.bodyText}>
                        <b>Conor</b>
                      </Typography>
                      <br />
                    </>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="teamKevin"
                      onChange={handleChange}
                      checked={teamKevin.value}
                      value={teamKevin.value}
                    />
                  }
                  label={
                    <>
                      <img
                        src={team_kevin}
                        className={classes.teamImage}
                        alt={"Kevin"}
                      />
                      <Typography variant="body1" className={classes.bodyText}>
                        <b>Kevin</b>
                      </Typography>
                      <br />
                    </>
                  }
                />
              </>
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginTop: "24px" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              Why do you prefer these members of the team?
            </FormLabel>
            <TextField
              multiline={true}
              variant={variant}
              rows={5}
              fullWidth
              name="teamWhy"
              placeholder=""
              value={teamWhy.value}
              onChange={handleChange}
              error={!!teamWhy.error}
              helperText={teamWhy.error}
              required={teamWhy.required}
            />
          </FormControl>
        </Grid>

        {/*  Do you wish we had subtitles available on all of the episodes? */}
        <Grid item xs={12} sm={12} className={classes.formQuestion}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Do you wish we had subtitles available on all of the episodes?
            </FormLabel>
            <RadioGroup
              onChange={handleChange}
              name="subtitles"
              className={classes.formGroup}
            >
              <FormControlLabel
                value="Yes"
                checked={subtitles.value === "Yes"}
                control={<Radio />}
                label="Yes"
                required={true}
              />
              <FormControlLabel
                value="No"
                checked={subtitles.value === "No"}
                control={<Radio />}
                label="No"
                required={true}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.buttonContainer}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 3
          }}
        >
          <Button onClick={handleBack} className={classes.stepButtonBack}>
            Back
          </Button>
          <Button
            className={classes.stepButton}
            variant="contained"
            disabled={isError()}
            color="primary"
            onClick={!isError() ? handleNext : () => null}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </>
  );
}
