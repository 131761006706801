import React, { useState, useContext } from "react";
import { AccountContext } from "./../../store/AccountContext";
import { Grid, Typography, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import usePromise from "react-use-promise";
import MembershipProcess from "./MembershipProcess";
import { useToasts } from "react-toast-notifications";

// Styles
const useStyles = makeStyles(theme => ({
  background: {
    flexGrow: 1,
    backgroundColor: "#e8e8e8"
  },
  myAccountContainer: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },
  myAccountGridContainer: {
    padding: theme.spacing(2, 0)
  },
  myAccountTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "2.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  myAccountSubTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.4rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.4rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.6rem",
      lineHeight: "1.8rem"
    }
  },
  myAccountThirdTitle: {
    color: theme.palette.background.ffNavy,
    fontSize: "1.2rem",
    textAlign: "left",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem"
    }
  },
  bodyText: {
    color: theme.palette.background.ffNavy,
    marginBottom: theme.spacing(2),
    "& span": {
      fontWeight: 500
    }
  },
  bodyTextSml: {
    color: theme.palette.background.ffNavy,
    fontSize: "0.9rem",
    "& span": {
      fontWeight: 500
    }
  },
  formContainer: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(3)
  },
  footerDivider: {
    height: "1px",
    backgroundColor: "#3c3b3b",
    margin: "5% 0 2% 0"
  },
  breadcrumbContainer: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  activeBreadcrumb: {
    color: theme.palette.background.ffNavy,
    "&:hover": {
      color: theme.palette.background.ffGreen
    }
  },
  disabledBreadcrumb: {
    color: "#777777"
  },
  submit: {
    width: "100%",
    margin: theme.spacing(0, 0, 2, 0),
    "@media (min-width:600px)": {
      width: "auto"
    }
  }
}));

export default function AutoRenewOn() {
  const classes = useStyles();
  const { addToast } = useToasts();

  // Account Context
  const {
    handleResumeMembership,
    userProfile,
    getMembershipDetails,
    updating
  } = useContext(AccountContext);

  // State
  // eslint-disable-next-line
  const [reloadTrigger, setReloadTrigger] = useState(Date.now());
  const [membershipDetails, , state] = usePromise(getMembershipDetails, [
    reloadTrigger
  ]);

  const loading = state === "pending";

  // const reload = () => setReloadTrigger(Date.now());

  /*
    Are they an exisiting Stripe Customer?

    Yes
      No current subscription
        Select membership
        Show payment method on file or add a new payment method

      Current subscription in cancelled state
        Resume subscription

    No
      Add new payment method - if currently on a giftcard, this needs to pause the sub until the giftcard has expired
  */

  const handleTurnOnAutoRenew = async () => {
    try {
      const resumeMembership = handleResumeMembership();
      if (resumeMembership) {
        addToast("Auto Renew Enabled.", {
          appearance: "success"
        });
      } else {
        addToast("Error - there was a problem", {
          appearance: "error"
        });
      }
    } catch (e) {
      addToast(e.toString(), {
        appearance: "error"
      });
    }
  };

  function CurrencySymbol({ currency }) {
    switch (currency) {
      case "gbp":
        return "\u00A3";
      case "eur":
        return "\u20AC";
      case "usd":
        return "\u0024";
      default:
        return "\u00A3";
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumbContainer}
        >
          <Link className={classes.activeBreadcrumb} to="/myaccount/overview">
            Overview
          </Link>
          <Typography className={classes.disabledBreadcrumb}>
            Turn On Auto Renew
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h2" className={classes.myAccountSubTitle}>
          Turn On Auto Renew
        </Typography>
      </Grid>
      <Grid container className={classes.formContainer}>
        {!loading && (
          <>
            {/* If they have Stripe Details */}
            {membershipDetails && (
              <>
                {!updating && (
                  <>
                    {/* If user is standard and productAutoRenewing is on, all is good */}
                    {userProfile.memberStatus === "standard" &&
                      userProfile.productAutoRenewing && (
                        <>
                          <Grid item xs={12} sm={12}>
                            <Typography
                              variant="body1"
                              className={classes.bodyText}
                            >
                              Auto Renew has been successfully enabled on your
                              account.
                            </Typography>
                            <Link
                              to="/myaccount/overview"
                              style={{ textDecoration: "none" }}
                            >
                              <Button className={classes.submit}>
                                Go to Account Overview
                              </Button>
                            </Link>
                          </Grid>
                        </>
                      )}

                    {/* If user is standard and productAutoRenewing is off, get them onboard again */}
                    {userProfile.memberStatus === "standard" &&
                      !userProfile.productAutoRenewing &&
                      membershipDetails.creditCard && (
                        <>
                          <Grid item xs={12} sm={12}>
                            <Typography
                              variant="h3"
                              className={classes.myAccountThirdTitle}
                            >
                              Changed your mind?
                            </Typography>
                            <Typography
                              variant="body1"
                              className={classes.bodyText}
                            >
                              You recently disabled Auto Renew on your FarmFLiX
                              Membership.
                            </Typography>
                            <Typography
                              variant="body1"
                              className={classes.bodyText}
                            >
                              By enabling Auto Renew again, you&apos;ll be
                              charged{" "}
                              <span>
                                <CurrencySymbol
                                  currency={membershipDetails.currency}
                                />
                                {membershipDetails.cost}
                              </span>{" "}
                              automatically on&nbsp;
                              <span>
                                {userProfile.subscriptionExpiryDate &&
                                  new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit"
                                  }).format(
                                    new Date(userProfile.subscriptionExpiryDate)
                                  )}
                              </span>
                              .{" "}
                            </Typography>
                            <Button
                              onClick={handleTurnOnAutoRenew}
                              className={classes.submit}
                            >
                              Turn On Auto Renew
                            </Button>
                          </Grid>
                        </>
                      )}

                    {userProfile.memberStatus === "standard" &&
                      !userProfile.productAutoRenewing &&
                      !membershipDetails.creditCard && (
                        <Grid item xs={12} sm={12} md={12}>
                          <MembershipProcess />
                        </Grid>
                      )}

                    {/* If user is not standard, send them into the membership process */}
                    {userProfile.memberStatus !== "standard" && (
                      <Grid item xs={12} sm={12} md={12}>
                        <MembershipProcess />
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
            {/* No Stripe Details */}
            {!membershipDetails && (
              <>
                <MembershipProcess />
              </>
            )}
            {updating && (
              <Box m={5} align="center">
                <CircularProgress variant="indeterminate"></CircularProgress>
                <Typography variant="body2" className={classes.bodyTextSml}>
                  <br />
                  Updating... Please do not refresh your browser.
                </Typography>
              </Box>
            )}
          </>
        )}
        {loading && (
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <Box m={5} align="center">
                <CircularProgress variant="indeterminate"></CircularProgress>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
