import React, { useContext, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Grid, Button, Box } from "@material-ui/core";
import LoggedInLayout from "../layouts/LoggedInLayout";
import { OverviewContext } from "../store/OverviewContext";
import { PlayerContext } from "../store/PlayerContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import VideoRow from "./Video/VideoRow";

const useStyles = makeStyles(theme => ({
  backgroundSection: {
    height: "300px",
    backgroundColor: theme.palette.background.dark,
    position: "relative",
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    "@media (min-width:600px)": {
      height: "450px"
    },
    "@media (min-width:800px)": {
      height: "550px"
    }
  },
  backgroundSectionBottomGradient: {
    background:
      "linear-gradient(180deg, rgba(23,23,23,0.6) 4%, rgba(23,23,23,1) 100%)",
    height: "300px",
    "@media (min-width:600px)": {
      height: "450px",
      background:
        "linear-gradient(180deg, rgba(23,23,23,0.5) 20%, rgba(23,23,23,1) 100%)"
    },
    "@media (min-width:800px)": {
      height: "550px",
      background:
        "linear-gradient(180deg, rgba(23,23,23,0) 20%, rgba(23,23,23,1) 100%)"
    }
  },
  detailsOverlay: {
    position: "absolute",
    bottom: "40px",
    width: "100%",
    "@media (min-width:600px)": {},
    "@media (min-width:960px)": {
      bottom: "20px"
    }
  },
  channelLogoWrapper: {
    display: "flex",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
    "@media (min-width:600px)": {
      padding: theme.spacing(1.5),
      width: "100px",
      height: "100px"
    },
    "@media (min-width:960px)": {
      padding: theme.spacing(2),
      width: "140px",
      height: "140px"
    }
  },
  channelLogo: {
    width: "100%"
  },
  channelEpisodeListBackground: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    "@media (min-width:960px)": {
      backgroundColor: theme.palette.background.dark,
      marginTop: theme.spacing(4)
    }
  },
  channelEpisodeListContainer: {
    backgroundColor: theme.palette.background.ffNavy,
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(0),
    "@media (min-width:960px)": {
      backgroundColor: theme.palette.background.ffNavyLighter,
      paddingTop: theme.spacing(4)
    }
  },
  channelDescription: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    width: "100%",
    fontSize: "1.1rem",
    textShadow: "-2px 2px 2px #171717",
    "@media (min-width:600px)": {
      marginTop: theme.spacing(2),
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
      width: "100%"
    },
    "@media (min-width:960px)": {
      marginTop: theme.spacing(2),
      fontSize: "1.6rem",
      width: "80%"
    }
  },
  channelEpisodeCount: {
    fontWeight: 500,
    marginTop: theme.spacing(0.5),
    fontSize: "1rem",
    color: "#bbb",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem"
    }
  },
  channelEpisodesTitle: {
    fontSize: "1.4rem",
    marginBottom: theme.spacing(2)
  },
  episodeWrapper: {
    marginBottom: theme.spacing(2)
  },
  episodeThumbnailContainer: {
    position: "relative",
    marginBottom: 0,
    paddingBottom: 0
  },
  episodeThumbnail: {
    width: "100%"
  },
  episodeThumbnailHover: {
    minHeight: "100px",
    opacity: "0.3",
    width: "100%",
    transition: "0.3s",
    backgroundSize: "cover"
  },
  episodeTitle: {
    fontWeight: 500,
    fontSize: "1.3rem",
    marginTop: 0,
    "@media (min-width:600px)": {
      marginTop: theme.spacing(1)
    }
  },
  episodeMetaDetailsContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  episodeDetailsIcon: {
    display: "inline",
    color: theme.palette.background.ffGreen,
    marginRight: "4px",
    fontSize: "1.1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.2rem"
    }
  },
  episodeDetailsText: {
    display: "inline",
    color: theme.palette.common.darkgray,
    marginRight: theme.spacing(2),
    fontSize: "0.9rem",
    lineHeight: "1.2rem"
  },
  episodeDescription: {
    color: theme.palette.common.darkgray,
    fontSize: "0.9rem",
    borderBottom: "1px solid #292929",
    paddingBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      borderBottom: "none"
    }
  },
  favouriteIconButton: {
    fontSize: "1.8rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        color: "#ff5050"
      }
    }
  },
  favouritedIconButton: {
    fontSize: "1.8rem",
    backgroundColor: "#555555",
    color: "#ff5050",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1)
  },
  playIconButton: {
    fontSize: "2.2rem",
    backgroundColor: "#555555",
    color: "#fff",
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#585858",
      "& svg": {
        color: theme.palette.background.ffGreen
      }
    }
  },
  iconButton: {
    width: "2rem",
    height: "2rem"
  },
  "@global": {
    "@keyframes fadeIn": {
      from: {
        transform: "translate3d(0,15px,0)",
        opacity: "0.1"
      },
      to: {
        transform: "translate3d(0,0,0)",
        opacity: "1"
      }
    }
  },
  episodeHoverDetailsActive: {
    animation: "fadeIn .3s ease-in-out",
    position: "absolute",
    bottom: "15px",
    right: "5px"
  }
}));

const ChannelOverview = props => {
  const classes = useStyles();
  const channelId = props.match.params.id;

  const {
    resetError,
    resetVideoSearch,
    clearCurrentVideo,
    updateTagVideoSearch,
    triggerNextPage,
    videos
  } = useContext(PlayerContext);

  const { overview } = useContext(OverviewContext);

  useEffect(() => {
    return () => {
      clearCurrentVideo();
      resetError();
      resetVideoSearch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const channel = useMemo(
    () => overview?.channels?.find(i => i.tag === channelId),
    [overview, channelId]
  );

  useEffect(() => {
    if (channel) {
      const {
        tag,
        defaultVideoSort,
        includePreviewVideos,
        total: initialTotal,
        videos: initialVideos
      } = channel;

      updateTagVideoSearch(
        tag,
        includePreviewVideos,
        defaultVideoSort,
        initialVideos,
        initialTotal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const handleTrigger = () => {
    triggerNextPage();
  };

  if (!channel) {
    return (
      <LoggedInLayout>
        <div className={classes.background} style={{ textAlign: "center" }}>
          <CircularProgress color="primary" />
        </div>
      </LoggedInLayout>
    );
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          FarmFLiX.tv |{" "}
          {overview && channel ? channel.name : "Unknown playlist"}
        </title>
        <style type="text/css"></style>
      </Helmet>
      <LoggedInLayout>
        {overview && channel && videos && (
          <>
            {/* New Top Background  */}
            <div
              className={classes.backgroundSection}
              style={{
                backgroundImage: `url(${channel.bannerImageUrl})`
              }}
            >
              <div className={classes.backgroundSectionBottomGradient}>
                <Container>
                  <Grid container>
                    <Grid item xs={8} sm={8} className={classes.detailsOverlay}>
                      <Box
                        className={classes.channelLogoWrapper}
                        style={{
                          backgroundColor: "#" + channel.colorPrimary
                        }}
                      >
                        <Box>
                          <img
                            src={channel.logoTransparentImageUrl}
                            alt="Channel Logo"
                            className={classes.channelLogo}
                          />
                        </Box>
                      </Box>
                      <Box>
                        {channel.description.length > 0 && (
                          <Typography
                            className={classes.channelDescription}
                            variant="body1"
                          >
                            {channel.description}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
            <div className={classes.channelEpisodeListBackground}>
              <Container
                maxWidth="lg"
                className={classes.channelEpisodeListContainer}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    {videos.map((video, idx) => (
                      <VideoRow
                        key={video.id}
                        idx={idx}
                        video={video}
                        listType={"channel"}
                        channels={overview.channels}
                      />
                    ))}
                  </Grid>
                </Grid>
                {videos.length < channel.total && (
                  <Grid item xs={12} sm={12} align="center">
                    <Button
                      size="large"
                      className={classes.loadMore}
                      onClick={handleTrigger}
                    >
                      Load More
                    </Button>
                  </Grid>
                )}
              </Container>
            </div>
          </>
        )}
      </LoggedInLayout>
    </div>
  );
};

export default ChannelOverview;
