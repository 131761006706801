import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "react-phone-input-2/lib/material.css";
import { Helmet } from "react-helmet";
import DefaultLayout from "../layouts/DefaultLayout";
import EmailSignIn from "./SignIn/EmailSignIn";
import MobileSignIn from "./SignIn/MobileSignIn";

// Styles
const useStyles = makeStyles(theme => ({
  signInContainer: {
    width: "100%",
    padding: 0,
    margin: 0,
    "@media (min-width:600px)": {
      margin: "0 auto"
    }
  },
  background: {
    position: "relative",
    backgroundImage: "none",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "scroll",
    width: "100%",
    height: "auto",
    display: "flex",
    flexGrow: 1,
    boxShadow: "none",
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    "@media (min-width:600px)": {
      backgroundImage: `url(https://farmflix-images-test.s3-eu-west-1.amazonaws.com/usemycode_bg.jpg)`,
      boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.6)"
    }
  },
  paper: {
    borderRadius: "0px",
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(0),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundColor: "none",
    "@media (min-width:600px)": {
      backgroundColor: "rgba(23,23,23,0.9)",
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6)
    }
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(1.5, 0, 2)
  },
  secSubmit: {
    width: "100%",
    margin: theme.spacing(1.5, 0, 2),
    borderColor: "#e9e9e9",
    color: "#e9e9e9",
    "@media (min-width:600px)": {
      width: "auto",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    "&:hover": {
      borderColor: "#fff",
      color: "#fff"
    }
  },
  formHeader: {
    color: theme.palette.common.white,
    fontSize: "2.2rem",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: "600",
    "@media (min-width:600px)": {
      fontSize: "2.2rem",
      lineHeight: "2.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "2.6rem",
      lineHeight: "2.8rem"
    }
  },
  formSubHeader: {
    color: theme.palette.common.white,
    fontSize: "1.4rem",
    fontWeight: "500",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
      lineHeight: "1.4rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.4rem",
      lineHeight: "1.6rem"
    }
  },
  thirdHeader: {
    color: theme.palette.background.ffGreen,
    fontSize: "1.6rem",
    textAlign: "center",
    fontWeight: "500",
    marginBottom: theme.spacing(2),
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "2rem"
    }
  },
  divider: {
    width: "50%",
    margin: "24px 25% 24px 25%",
    height: "1px",
    backgroundColor: "#444"
  },
  bodyText: {
    color: theme.palette.common.white,
    marginBottom: "10px",
    textAlign: "center"
  },
  bodySml: {
    fontSize: "0.7rem",
    marginTop: theme.spacing(2),
    color: theme.palette.common.white
  },
  phoneInput: {
    width: "inherit !important"
  },
  center: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  validationError: {
    marginBottom: theme.spacing(2)
  },
  backdrop: {
    zIndex: 100000000,
    color: "#fff"
  },
  forgotPassword: {
    color: theme.palette.background.ffGreen,
    fontWeight: 400
  },
  button: {
    margin: 0
  },
  secButton: {
    margin: theme.spacing(1.5, 0, 2),
    borderColor: "#e9e9e9",
    color: "#e9e9e9",
    "@media (min-width:600px)": {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    },
    "&:hover": {
      borderColor: "#fff",
      color: "#fff"
    }
  },
  formField: {
    "& label": {
      color: theme.palette.common.white
    },
    "& input": {
      backgroundColor: "#444",
      color: "#fff",
      borderColor: "#444"
    }
  },
  verificationCodeContainer: {
    width: "100% !important",
    fontWeight: "600",
    "& input": {
      height: "56px !important",
      width: "15.6% !important",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderTopLeftRadius: "0px !important",
      borderBottomLeftRadius: "0px !important",
      borderTopRightRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
      borderBottom: 0,
      backgroundColor: "#444",
      margin: "0 0.5%",
      fontSize: "2rem",
      fontWeight: 500,
      color: "#fff",
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
      "&:focus": {
        border: "1px solid #99ca3c",
        caretColor: "#fff",
        "&:last-child": {
          borderRight: "1px solid #99ca3c"
        }
      },
      "&:last-child": {
        borderRight: "none"
      }
    }
  },
  submittedMobileNumber: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    textAlign: "center",
    fontSize: "1.4rem",
    color: theme.palette.common.white
  }
}));

const SignIn = props => {
  const classes = useStyles();

  const [showEmailPasswordSignIn, setShowEmailPasswordSignIn] = useState(true);
  const [showMobileSignIn, setShowMobileSignIn] = useState(false);

  const showEmailPasswordSignInForm = () => {
    setShowMobileSignIn(false);
    setShowEmailPasswordSignIn(true);
  };

  const showMobileSignInForm = () => {
    setShowEmailPasswordSignIn(false);
    setShowMobileSignIn(true);
  };

  return (
    <DefaultLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Sign In Now</title>
        <meta
          name="description"
          content="Sign In Now to view the Latest FarmFLiX Content."
        />
      </Helmet>
      <div className={classes.background}>
        <Container maxWidth="xs" className={classes.signInContainer}>
          <Grid container className={classes.paper} spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                component="h1"
                variant="h1"
                className={classes.formHeader}
              >
                Log In
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              {showEmailPasswordSignIn ? (
                <EmailSignIn toggleLoginMethod={showMobileSignInForm} />
              ) : showMobileSignIn ? (
                <MobileSignIn toggleLoginMethod={showEmailPasswordSignInForm} />
              ) : null}
            </Grid>
          </Grid>
        </Container>
      </div>
    </DefaultLayout>
  );
};

export default SignIn;
