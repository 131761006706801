import React, { useContext } from "react";
import { PropsWithChildren } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { OnboardingContext } from "../../../../store/OnboardingContext";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  parent: {
    margin: "0 12px"
  },
  errorAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formSubHeader: {
    fontSize: "1.6rem",
    fontWeight: 500,
    marginTop: "24px",
    marginBottom: "12px",
    color: "#eee",
    textAlign: "center",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
      lineHeight: "1.8rem",
      marginBottom: theme.spacing(2)
    },
    "@media (min-width:960px)": {
      fontSize: "1.8rem",
      lineHeight: "1.8rem"
    }
  }
}));

const StepContainer = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();

  return <Box className={classes.parent}>{children}</Box>;
};

const Title = ({ title }: { title: string }) => {
  const classes = useStyles();

  return (
    <Typography variant="h2" className={classes.formSubHeader}>
      {title}
    </Typography>
  );
};

const Body = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();
  const { error, resetError } = useContext(OnboardingContext);

  return (
    <Box>
      {error && (
        <>
          <Alert
            severity="error"
            variant="filled"
            className={classes.errorAlert}
            onClose={() => resetError()}
          >
            {error}
          </Alert>
        </>
      )}
      {children}
    </Box>
  );
};

const Actions = ({ children }: PropsWithChildren<{}>) => <Box>{children}</Box>;

StepContainer.Title = Title;
StepContainer.Body = Body;
StepContainer.Actions = Actions;
export default StepContainer;
