import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Header from "./Header/Header";
import HeaderInternal from "./Header/HeaderInternal";
import Top from "./Home/Top";
import WhatIs from "./Home/WhatIs";
import Previews from "./Home/Previews";
import App from "./Home/App";
import FAQs from "./Home/FAQs";
import Testimonials from "./Home/Testimonials";
import Upsells from "./Home/Upsells";
import Footer from "./Footer/Footer";
import { AccountContext } from "../store/AccountContext";
import { OverviewContext } from "../store/OverviewContext";
import { Redirect } from "react-router";
import Careers from "./Banners/Careers";
import DoorsOpeningCountdown from "./Banners/DoorsOpeningCountdown";
import PriceRise from "./Banners/PriceRise";
import { TryBuyCta } from "./Home/TryBuyCta";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy
  }
}));

const Home = () => {
  const classes = useStyles();
  const { loggedIn } = useContext(AccountContext);
  const { overview } = useContext(OverviewContext);

  return (
    <div className={classes.container}>
      {loggedIn ? <Redirect to="/videos" /> : null}
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | Agricultural Video Platform</title>
        <meta
          name="description"
          content="The world's first online video platform dedicated to delivering fresh agricultural weekly videos for the farming community."
        />
      </Helmet>
      {loggedIn && <HeaderInternal />}
      {!loggedIn && <Header />}
      {overview && !overview.doors?.open && (
        <DoorsOpeningCountdown
          title={"Membership Doors open on Black Friday"}
          subTitle={"Are you ready for some action?"}
          countdownDate={"2022-11-26T00:00:00.000Z"}
          disableButton={true}
        />
      )}
      {overview &&
        overview.doors?.open &&
        overview.doors?.annualUpgradeOpen && (
          <PriceRise
            disableButton
            title="Beat the Price Rise"
            subTitle="Lock in the current price before they increase on 5th December 2022"
            showCountdown
          />
        )}
      <Top />
      <WhatIs />
      {overview && <TryBuyCta overview={overview} />}

      <Previews />
      <App />
      <Testimonials />
      <Upsells />
      {overview && overview.doors?.careersOpen && <Careers fullWidth={true} />}
      <FAQs />
      <Footer />
    </div>
  );
};

export default Home;
