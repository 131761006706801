import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Typography, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { OverviewContext } from "../../store/OverviewContext";
import TryButton from "../Onboarding/TryButton";
import backgroundImg from "../../assets/banner_2022.jpg";

const useStyles = makeStyles(theme => ({
  "@global": {
    "@keyframes slide": {
      "0%": {
        transform: "translate3d(0, 0, 0)"
      },
      "100%": {
        transform: "translate3d(-1920px, 0, 0)" /* The image width */
      }
    }
  },
  container: {
    "@media (min-width:1280px)": {
      padding: 0
    }
  },
  background: {
    // @ts-ignore
    backgroundColor: theme.palette.background.ffNavy,
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "auto",
    display: "flex",
    height: "70vh",
    boxShadow: "inset 4000px 0 0 0 rgba(8, 5, 5, 0.8)",
    // @ts-ignore
    paddingBottom: theme.spacing(0),
    position: "relative",
    "@media (min-width:600px)": {
      backgroundSize: "cover",
      height: "80vh"
    },
    "@media (min-width:769px)": {
      height: "80vh"
    },
    "@media (min-width:960px)": {
      backgroundSize: "cover",
      height: "80vh"
    },
    "@media (min-width:1280px)": {
      boxShadow: "none",
      backgroundSize: "contain",
      height: "80vh",
      maxHeight: "880px"
    }
  },
  gradient: {
    background: "none",
    height: "80vh",
    "@media (min-width:600px)": {
      height: "80vh"
    },
    "@media (min-width:769px)": {
      height: "80vh"
    },
    "@media (min-width:960px)": {
      height: "80vh"
    },
    "@media (min-width:1280px)": {
      boxShadow: "none",
      maxHeight: "880px",
      background:
        "linear-gradient(95deg, rgba(23,23,23,1) 9%, rgba(23,23,23,1) 22%, rgba(23,23,23,0.08307072829131656) 60%, rgba(23,23,23,0.665703781512605) 77%, rgba(23,23,23,1) 85%)",
      height: "80vh",
      marginLeft: "-24px",
      marginRight: "-24px"
    }
  },
  centerBox: {
    position: "relative",
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: theme.spacing(0),
    "@media (min-width:600px)": {
      height: "80vh",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:769px)": {
      height: "80vh",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:960px)": {
      height: "80vh",
      alignItems: "flex-start",
      marginTop: theme.spacing(0)
    },
    "@media (min-width:1280px)": {
      height: "80vh",
      maxHeight: "880px"
    }
  },
  logo: {
    width: "40%",
    "@media (min-width:600px)": {
      width: "200px"
    },
    "@media (min-width:960px)": {}
  },
  title: {
    marginTop: theme.spacing(0),
    letterSpacing: "-2px",
    fontSize: "4.4rem",
    lineHeight: "3.6rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      fontSize: "2.2rem",
      lineHeight: "2.6rem"
    },
    "@media (min-width:600px)": {
      fontSize: "5.4rem",
      lineHeight: "4.6rem",
      "& span": {
        fontSize: "2.2rem",
        lineHeight: "2.4rem"
      }
    },
    "@media (min-width:960px)": {
      fontSize: "5.8rem",
      lineHeight: "5rem",
      "& span": {
        fontSize: "2.6rem",
        lineHeight: "2.8rem"
      }
    },
    "@media (min-width:1100px)": {
      fontSize: "6.4rem",
      lineHeight: "5rem",
      "& span": {
        fontSize: "3rem",
        lineHeight: "3.2rem"
      }
    }
  },
  subTitle: {
    //@ts-ignore
    color: theme.palette.background.ffGreen,
    marginTop: theme.spacing(2),
    letterSpacing: "-2px",
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    fontWeight: 600,
    textShadow: "-2px 2px 2px #171717",
    "& span": {
      fontSize: "3rem",
      lineHeight: "3.2rem"
    },
    "@media (min-width:600px)": {
      fontSize: "2.4rem",
      lineHeight: "2rem"
    },
    "@media (min-width:960px)": {}
  },
  ctaButtonContainer: {
    width: "100%",
    textAlign: "center",
    "@media (min-width:600px)": {
      width: "300px"
    },
    "@media (min-width:960px)": {
      width: "300px"
    }
  },
  button: {
    marginTop: theme.spacing(4),
    width: "100%",
    fontSize: "1.2rem",
    padding: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    "@media (min-width:600px)": {
      width: "300px"
    },
    "@media (min-width:960px)": {
      width: "300px"
    }
  },
  buttonSubText: {
    fontSize: "0.9rem",
    textAlign: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    "@media (min-width:600px)": {
      width: "300px",
      textAlign: "center",
      marginLeft: 0
    },
    "@media (min-width:960px)": {}
  },
  noUnderline: {
    textDecoration: "none"
  },
  buyButton: {
    textDecoration: "underline",
    textDecorationThickness: "2px",
    boxShadow: "none",
    backgroundColor: "transparent",
    border: "none",
    color: theme.palette.common.white,
    transition: "none",
    margin: theme.spacing(0, 0, 0.5, 0),
    width: "100%",
    "@media (min-width:600px)": {
      width: "300px"
    },
    "@media (min-width:960px)": {
      width: "300px"
    },
    fontSize: "1.2rem",
    padding: theme.spacing(1, 6, 2, 6),
    "&:hover": {
      border: "none",
      backgroundColor: "transparent",
      textDecoration: "underline",
      textDecorationThickness: "2px",
      transition: "none",
      boxShadow: "none",
      //@ts-ignore
      color: theme.palette.background.ffGreen
    }
  }
}));

const Top = () => {
  const classes = useStyles();
  const { overview } = useContext(OverviewContext);

  return (
    <>
      <div className={classes.background}>
        <Container maxWidth="lg" fixed className={classes.container}>
          <div className={classes.gradient}>
            <Grid container>
              <Grid item xs={12} md={12} className={classes.centerBox}>
                <Box>
                  <Box>
                    <Typography variant="h1" className={classes.title}>
                      <span>Awesome</span>
                      <br />
                      Farming
                      <br />
                      Videos
                    </Typography>
                    <Typography variant="h2" className={classes.subTitle}>
                      Watch Anytime. Watch Anywhere
                    </Typography>
                    {/*
                      Doors Open & Trials Open - show Free Trial
                      Doors Closed & Trials Open - show Free Trial
                      Doors Open & Trials Closed - show Join Now
                      Doors Closed & Trials Closed - show Free Trial (notify me status)
                    */}
                    {overview && (
                      <>
                        {overview.doors?.trialsOpen && overview.doors?.open && (
                          <>
                            <Box className={classes.ctaButtonContainer}>
                              <TryButton
                                label="Take Free Trial"
                                size="large"
                                className={classes.button}
                              />
                              <Typography
                                variant="body1"
                                className={classes.buttonSubText}
                              >
                                No Payment Required for Free Trial
                              </Typography>
                              {/*<Typography variant="body2">*/}
                              {/*  <b>or</b>*/}
                              {/*</Typography>*/}
                              {/*<Link to="/buy">*/}
                              {/*  <Button*/}
                              {/*    disableRipple*/}
                              {/*    size="large"*/}
                              {/*    variant="contained"*/}
                              {/*    className={classes.buyButton}*/}
                              {/*  >*/}
                              {/*    Buy Now*/}
                              {/*  </Button>*/}
                              {/*</Link>*/}
                            </Box>
                          </>
                        )}

                        {overview.doors?.trialsOpen && !overview.doors?.open && (
                          <>
                            <TryButton
                              label="Take Free Trial"
                              size="large"
                              className={classes.button}
                            />
                            <Typography
                              variant="body1"
                              className={classes.buttonSubText}
                            >
                              No Payment Required for Free Trial
                            </Typography>
                          </>
                        )}

                        {overview.doors?.open && !overview.doors?.trialsOpen && (
                          <>
                            <Link to="/buy" style={{ textDecoration: "none" }}>
                              <Button
                                disableRipple
                                size="large"
                                className={classes.button}
                              >
                                Join Now
                              </Button>
                            </Link>
                            <Typography
                              variant="body1"
                              className={classes.buttonSubText}
                            >
                              <b>Don&apos;t Miss Out!</b>
                              <br />
                              Last chance to join this season!
                            </Typography>
                          </>
                        )}

                        {!overview.doors?.trialsOpen && !overview.doors?.open && (
                          <>
                            <TryButton
                              label="Take Free Trial"
                              size="large"
                              className={classes.button}
                            />
                            <Typography
                              variant="body1"
                              className={classes.buttonSubText}
                            >
                              No Payment Required for Free Trial
                            </Typography>
                          </>
                        )}

                        <Link to="/app">
                          <Button
                            disableRipple
                            size="large"
                            variant="contained"
                            className={classes.buyButton}
                          >
                            Install the app
                          </Button>
                        </Link>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Top;
