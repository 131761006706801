import React, { useContext, useEffect } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import HeaderInternal from "./Header/HeaderInternal";
import { AccountContext } from "../store/AccountContext";
import AboutHeader from "./About/Header";
import AboutMidSection from "./About/MidSection";
import AboutQuote from "./About/Quote";
import AboutFundedBy from "./About/FundedBy";

import { Helmet } from "react-helmet";

const About = props => {
  const { loggedIn } = useContext(AccountContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FarmFLiX.tv | About</title>
        <meta
          name="description"
          content="FarmFLiX is an online video platform dedicated to delivering the best agricultural videos, using the latest technologies, to the farming community."
        />
      </Helmet>
      <>
        {loggedIn && <HeaderInternal />}
        {!loggedIn && <Header />}
      </>
      <AboutHeader />
      <AboutMidSection />
      <AboutQuote />
      <AboutFundedBy />
      <Footer />
    </div>
  );
};

export default About;
